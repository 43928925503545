import { NodeEntityModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  BuildTreeFromNodes = '[STORE | ENTITY | INDEX] Build Tree From Nodes',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Build Tree From Nodes
 */
export class BuildTreeFromNodes {
  public static readonly type = ActionTypes.BuildTreeFromNodes;

  public constructor(public entityQueryResults: NodeEntityModels.NodeEntityQueryResult[]) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>