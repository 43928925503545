import { v4 as uuid } from 'uuid';
import { StateToken } from '@ngxs/store';
import * as FlatModels from '../../internal';

/**
 * History Snapshot Item Modifier Type
 */
export enum HistorySnapshotItemModifierType {
  ADD,
  UPDATE,
  REMOVE,
}

/**
 * History Snapshot Item
 */
export class HistorySnapshotItem {
  /**
   * Members
   */
  protected _id: string;
  protected _type: HistorySnapshotItemModifierType;
  protected _stateToken: StateToken<any>;
  protected _user: FlatModels.UserEntityModels.User | null;
  protected _entityId: string;
  protected _previousSnapshotId: string | null;
  protected _followingSnapshotId: string | null;

  /**
   * Constructor
   *
   * @param stateToken: StateToken<any>
   * @param type: HistorySnapshotItemModifierType
   * @param entityId: string
   * @param previousSnapshotId: string | null
   * @param followingSnapshotId: string | null
   * @param user: User | null
   */
  public constructor(stateToken: StateToken<any>,
                     type: HistorySnapshotItemModifierType,
                     entityId: string,
                     previousSnapshotId: string | null,
                     followingSnapshotId: string | null,
                     user: FlatModels.UserEntityModels.User | null) {
    this._id = uuid();
    this._type = type;
    this._stateToken = stateToken;
    this._user = user;
    this._entityId = entityId;
    this._previousSnapshotId = previousSnapshotId;
    this._followingSnapshotId = followingSnapshotId;
  }

  /**
   * Get Id
   */
  public get id(): string {
    return this._id;
  }

  /**
   * Get Type
   */
  public get type(): HistorySnapshotItemModifierType {
    return this._type;
  }

  /**
   * Get State Token
   */
  public get stateToken(): StateToken<any> {
    return this._stateToken;
  }

  /**
   * Get User
   */
  public get user(): FlatModels.UserEntityModels.User | null {
    return this._user;
  }

  /**
   * Get Entity Id
   */
  public get entityId(): string {
    return this._entityId;
  }

  /**
   * Get Previous Snapshot Id
   */
  public get previousSnapshotId(): string | null {
    return this._previousSnapshotId;
  }

  /**
   * Get Following Snapshot Id
   */
  public get followingSnapshotId(): string | null {
    return this._followingSnapshotId;
  }
}

/**
 * History Snapshot
 */
export interface HistorySnapshot {
  id: string;
  locked : boolean;
  saved: boolean;
  snapshotItems: HistorySnapshotItem[];
}

/**
 * Save Snapshot Item
 */
export interface SaveSnapshotItem {
  stateToken: StateToken<any>;
  entity: any;
}
