import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { UserEntityModels } from '@medsurf/flat-models';
import { AlertControlActions, AuthControlActions } from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';

/**
 * Auth Control State Model
 */
export interface AuthControlStateModel {
  token: string | null;
  tokenLifetime: number | null;
  expiresIn: number | null;
  isUpdating: boolean;
  user: UserEntityModels.User | null;
  isAdmin: boolean;
}

/**
 * Auth Control State
 */
@State<AuthControlStateModel>({
  name: 'authControl',
  defaults: {
    token: null,
    tokenLifetime: null,
    expiresIn: null,
    isUpdating: false,
    user: null,
    isAdmin: false
  }
})
@Injectable()
export class AuthControlState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   */
  public constructor(protected store: Store,
                     protected messageControlService: MessageControlService) {
  }

  //<editor-fold desc="Selectors">

  /**
   * Selector token$
   *
   * @param state: AuthControlStateModel
   */
  @Selector([AuthControlState])
  public static token$(state: AuthControlStateModel): string | null {
    return state.token;
  }

  /**
   * Selector expiresIn$
   *
   * @param state: AuthControlStateModel
   */
  @Selector([AuthControlState])
  public static expiresIn$(state: AuthControlStateModel): number | null {
    return state.expiresIn;
  }

  /**
   * Selector tokenLifeTime$
   *
   * @param state: AuthControlStateModel
   */
  @Selector([AuthControlState])
  public static tokenLifeTime$(state: AuthControlStateModel): number | null {
    return state.tokenLifetime;
  }

  /**
   * Selector isUpdating$
   *
   * @param state: AuthControlStateModel
   */
  @Selector([AuthControlState])
  public static isUpdating$(state: AuthControlStateModel): boolean {
    return state.isUpdating;
  }

  /**
   * Selector user$
   *
   * @param state: AuthControlStateModel
   */
  @Selector([AuthControlState])
  public static user$(state: AuthControlStateModel): UserEntityModels.User | null {
    return state.user;
  }

  /**
   * Selector isAuthenticated$
   *
   * @param token: string | null
   */
  @Selector([AuthControlState.token$])
  public static isAuthenticated$(token: string | null): boolean {
    return !!token;
  }

  /**
   * Selector isExpired$$
   *
   * @param expiresIn: number | null
   * @param tokenLifetime: number | null
   */
  @Selector([
    AuthControlState.expiresIn$,
    AuthControlState.tokenLifeTime$
  ])
  public static isExpired$(expiresIn: number | null, tokenLifetime: number | null): boolean {
    return ((expiresIn || 0) - (tokenLifetime || 0)) < Math.floor(new Date().getTime() / 1000);
  }

  /**
   * Selector isAdmin$
   *
   * @param user: UserEntityModels.User | null
   */
  @Selector([AuthControlState.user$])
  public static isAdmin$(user: UserEntityModels.User | null): boolean {
    return user && user?.role === UserEntityModels.Role.ADMIN || false;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Login
   *
   * @param state: StateContext<AuthControlStateModel>
   * @param username: AuthControlActions.Login
   * @param password: AuthControlActions.Login
   */
  @Action(AuthControlActions.Login)
  public login(state: StateContext<AuthControlStateModel>,
               {username, password}: AuthControlActions.Login) {
    return this.messageControlService.sendMessage(
      this.store.selectSnapshot(AuthControlState.token$),
      new AuthControlActions.LoginRequest(username, password)
    );
  }

  /**
   * Login Success
   *
   * @param patchState: StateContext<AuthControlStateModel>
   * @param token: AuthControlActions.LoginSuccess
   * @param tokenLifetime: AuthControlActions.LoginSuccess
   * @param expiresIn: AuthControlActions.LoginSuccess
   * @param user: AuthControlActions.LoginSuccess
   */
  @Action(AuthControlActions.LoginSuccess)
  public loginSuccess({patchState}: StateContext<AuthControlStateModel>,
                      {token, tokenLifetime, expiresIn, user}: AuthControlActions.LoginSuccess) {
    this.store.dispatch(new AlertControlActions.ClearAlerts());
    patchState({
      token,
      tokenLifetime,
      expiresIn,
      user
    });
  }

  /**
   * Logout
   */
  @Action(AuthControlActions.Logout)
  public logout() {
    return this.messageControlService.sendMessage(
      this.store.selectSnapshot(AuthControlState.token$),
      new AuthControlActions.LogoutRequest()
    );
  }

  /**
   * Logout Success
   *
   * @param patchState: StateContext<AuthControlStateModel>
   */
  @Action(AuthControlActions.LogoutSuccess)
  public logoutSuccess({patchState}: StateContext<AuthControlStateModel>) {
    patchState({
      token: null,
      tokenLifetime: null,
      expiresIn: null,
      user: null,
    });
  }

  /**
   * Me
   */
  @Action(AuthControlActions.Me)
  public me() {
    return this.messageControlService.sendMessage(
      this.store.selectSnapshot(AuthControlState.token$),
      new AuthControlActions.MeRequest()
    );
  }

  /**
   * Me Success
   *
   * @param patchState: StateContext<AuthControlStateModel>
   * @param user: AuthControlActions.MeSuccess
   */
  @Action(AuthControlActions.MeSuccess)
  public meSuccess({patchState}: StateContext<AuthControlStateModel>,
                   {user}: AuthControlActions.MeSuccess) {
    patchState({
      user
    });
  }

  /**
   * Update Token
   *
   * @param patchState: StateContext<AuthControlStateModel>
   */
  @Action(AuthControlActions.UpdateToken)
  public updateToken({patchState}: StateContext<AuthControlStateModel>) {
    const token = this.store.selectSnapshot(AuthControlState.token$);
    const isExpired = this.store.selectSnapshot(AuthControlState.isExpired$);

    if (token && isExpired) {
      if (!this.store.selectSnapshot(AuthControlState.isUpdating$)) {
        patchState({
          isUpdating: true
        });
        return this.messageControlService.sendMessage(
          token,
          new AuthControlActions.UpdateTokenRequest()
        );
      }
    }
    return undefined;
  }

  /**
   * Update Token Success
   *
   * @param patchState: StateContext<AuthControlStateModel>
   * @param token: AuthControlActions.UpdateTokenSuccess
   * @param expiresIn: AuthControlActions.UpdateTokenSuccess
   */
  @Action(AuthControlActions.UpdateTokenSuccess)
  public updateTokenSuccess({patchState}: StateContext<AuthControlStateModel>,
                            {token, expiresIn}: AuthControlActions.UpdateTokenSuccess) {
    patchState({
      token,
      expiresIn,
      isUpdating: false
    });
  }

  /**
   * Clear Session
   * 
   * @param 
   */
  @Action(AuthControlActions.ClearSession)
  public clearSession({patchState}: StateContext<AuthControlStateModel>) {
    patchState({
      token: null,
      tokenLifetime: null,
      expiresIn: null,
      isUpdating: false,
      user: null,
      isAdmin: false
    });
  }

  //</editor-fold>
}
