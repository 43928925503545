import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NodeEntityModels } from '@medsurf/flat-models';
import { AppSharedFacade, NavigationControlFacade, NodeSharedFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  /**
   * Inputs
   */
  @Input() public showCurrentSubject: boolean;
  @Output() chapterSelected = new EventEmitter<NodeEntityModels.ChapterNode>();
  @Output() blockSelected = new EventEmitter<NodeEntityModels.BlockNode>();
  @Output() specialPageSelected = new EventEmitter<string>();

  /**
   * Constructor
   *
   * @param navigationControlFacade: NavigationControlFacade
   * @param appSharedFacade: AppSharedFacade
   * @param nodeSharedFacade: NodeSharedFacade
   */
  constructor(public navigationControlFacade: NavigationControlFacade,
              public appSharedFacade: AppSharedFacade,
              public nodeSharedFacade: NodeSharedFacade) {
  }
}
