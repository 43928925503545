<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- SubSubjects -->
<ng-template #subjects_template let-subjectNode="subjectNode" let-subPage="subPage" let-parentLocked="parentLocked">
  <div *ngIf="subPage && !subjectNode.hidden" class="list-group-container {{ subPage.themeColor }}">
    <a [routerLink]="['/' , node.route, subjectNode.route]"
       class="list-group-item">
      <span class="list-group-item-text">{{ subPage.title }}</span>
      <span class="restriction fa fa-lock" *ngIf="subjectNode.restricted && (nodeSharedFacade.isNodeLocked$(subjectNode, parentLocked) | async)"></span>
      <span class="restriction fa fa-lock-open" *ngIf="subjectNode.restricted && (nodeSharedFacade.isNodeLocked$(subjectNode, parentLocked) | async) === false"></span>
    </a>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="(appSharedFacade.page$(node.page) | async) as page; else spinner">
  <div class="card" [class.shadow-sm]="!showSubjects" [class.with-subjects]="showSubjects">
    <div class="card-header pb-0" id="headingOne">
      <h1 *ngIf="mainHeading" class="mb-0 d-flex justify-content-between">
        <span class="text-black page-title">{{ page.title }}</span>
        <span class="restriction fa fa-lock" *ngIf="node.restricted && locked"></span>
        <span class="restriction fa fa-lock-open" *ngIf="node.restricted && !locked"></span>
      </h1>
      <h2 *ngIf="!mainHeading" class="mb-0 d-flex justify-content-between">
        <a class="text-black page-title" [routerLink]="['/' , node.route]">{{ page.title }}</a>
        <span class="restriction fa fa-lock" *ngIf="node.restricted && locked"></span>
        <span class="restriction fa fa-lock-open" *ngIf="node.restricted && !locked"></span>
      </h2>
    </div>
    <div class="card-body">
      <div [innerHTML]="page.description | safeHtml"></div>
      <ng-container *ngIf="node.restricted">
        <p class="text-secondary">
          {{ 'restricted_content_message' | translate }}
        </p>
        <ng-container *ngIf="locked">
          <button href="#" class="mdsf-btn mdsf-btn-primary card-action" [routerLink]="['/' , 'login']" [queryParams]="{r: '/' + node.route}" translate="sign_in"></button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="showSubjects && (!node.restricted || !locked)">
        <ng-container *ngIf="(nodeSharedFacade.nodeByIds$(node.children) | async) | sortBy_shared:'asc':'position' as subjectNodes">
          <ng-container *ngIf="subjectNodes !== undefined; else spinner"></ng-container>
          <ng-container *ngFor="let subjectNode of subjectNodes">
            <ng-container *ngTemplateOutlet="subjects_template;context:{
              subjectNode: subjectNode,
              subPage: (appSharedFacade.page$(subjectNode.page) | async),
              parentLocked: locked}">
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <button *ngIf="!showSubjects && !locked" href="#" class="mdsf-btn mdsf-btn-primary card-action" [routerLink]="['/' , node.route]" translate="open"></button>
    </div>
  </div>
</ng-container>
