import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { AuthError, UserError, Logout, AddAlert } from '@medsurf/actions';

/**
 * Error state model
 */
export class ErrorStateModel {
}

/**
 * Error State
 */
@State<ErrorStateModel>({
  name: 'error',
  defaults: {}
})
@Injectable()
export class ErrorState {

  /**
   * AuthError action
   * @param setState: StateContext<{}>
   * @param dispatch: StateContext<{}>
   * @param message: string
   * @param errorCode: number
   */
  @Action(AuthError)
  public authError({setState, dispatch}: StateContext<{}>, {message, errorCode}: AuthError): void {
    dispatch(new AddAlert({message, code: errorCode}))
    dispatch(new Logout());
  }

  /**
   * UserError action
   * @param setState: StateContext<{}>
   * @param dispatch: StateContext<{}>
   * @param message: string
   * @param errorCode: number
   */
  @Action(UserError)
  public reportError({setState, dispatch}: StateContext<{}>, {message, errorCode}: UserError): void {
    dispatch(new AddAlert({message: message, code: errorCode}))
  }
}
