import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { QuestionEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import { PageEntityState } from '../../internal';

/**
 * Question Entity Token
 */
export const QUESTION_ENTITY_TOKEN = new StateToken<QuestionEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.QUESTION);

/**
 * Question Entity State Model
 */
export type QuestionEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.QuestionEntityModels.QuestionEntityType>;

/**
 * Question Entity State
 */
@State<QuestionEntityStateModel>({
  name: QUESTION_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class QuestionEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: QuestionEntityStateModel
   */
  @Selector([QuestionEntityState])
  public static typedEntities$(state: QuestionEntityStateModel): { [id: string]: FlatModels.QuestionEntityModels.QuestionEntityType } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.QuestionEntityModels.QuestionEntityType }
   */
  @Selector([QuestionEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.QuestionEntityModels.QuestionEntityType }): FlatModels.QuestionEntityModels.QuestionEntityType[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.QuestionEntityModels.QuestionEntityType }
   */
  @Selector([QuestionEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.QuestionEntityModels.QuestionEntityType }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.QuestionEntityModels.QuestionEntityType[]
   */
  @Selector([QuestionEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.QuestionEntityModels.QuestionEntityType[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  /**
   * Selector currentSelectedSlideQuestions$
   *
   * @param entities: FlatModels.QuestionEntityModels.QuestionEntityType[]
   * @param currentSelectedSlidePage: FlatModels.SlideEntityModels.Slide | null
   */
  @Selector([
    QuestionEntityState.entities$,
    PageEntityState.currentSelectedSlidePage$
  ])
  public static currentSelectedSlideQuestions$(entities: FlatModels.QuestionEntityModels.QuestionEntityType[],
                                               currentSelectedSlidePage: FlatModels.PageEntityModels.Slide | null) {
    return currentSelectedSlidePage && currentSelectedSlidePage.questions && Array.isArray(entities) && entities.filter(e => (currentSelectedSlidePage.questions as unknown as string[]).includes(e.id)) || [];
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<QuestionEntityStateModel>
   * @param patchState: StateContext<QuestionEntityStateModel>
   * @param entityQueryResult: QuestionEntityActions.GetEntitySuccess
   */
  @Action(QuestionEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<QuestionEntityStateModel>,
                          {entityQueryResult}: QuestionEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<QuestionEntityStateModel>
   * @param patchState: StateContext<QuestionEntityStateModel>
   * @param entityQueryResults: QuestionEntityActions.GetEntitiesSuccess
   */
  @Action(QuestionEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<QuestionEntityStateModel>,
                            {entityQueryResults}: QuestionEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<QuestionEntityStateModel>
   * @param patchState: StateContext<QuestionEntityStateModel>
   * @param entity: QuestionEntityActions.AddEntity
   */
  @Action(QuestionEntityActions.AddEntity)
  @Undoable(QuestionEntityActions.AddEntity, QUESTION_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<QuestionEntityStateModel>,
                   {entity}: QuestionEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<QuestionEntityStateModel>
   * @param patchState: StateContext<QuestionEntityStateModel>
   * @param entity: QuestionEntityActions.UpdateEntity
   */
  @Action(QuestionEntityActions.UpdateEntity)
  @Undoable(QuestionEntityActions.UpdateEntity, QUESTION_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<QuestionEntityStateModel>,
                      {entity}: QuestionEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<QuestionEntityStateModel>
   * @param patchState: StateContext<QuestionEntityStateModel>
   * @param entity: QuestionEntityActions.RemoveEntity
   */
  @Action(QuestionEntityActions.RemoveEntity)
  @Undoable(QuestionEntityActions.RemoveEntity, QUESTION_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<QuestionEntityStateModel>,
                      {entity}: QuestionEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
