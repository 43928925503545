<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Block -->
<ng-template #block_template let-chapter="chapter" let-block="block" let-page="page" let-currentAvailableBlockSlides="currentAvailableBlockSlides" let-selectedTrainings="selectedTrainings">
  <label class="mdsf-quiz-list-group-item" [class.hidden]="!chapter.expanded" *ngIf="!block.hidden && currentAvailableBlockSlides && currentAvailableBlockSlides.length !== 0">
    <input class="checkbox"
           type="checkbox"
           [checked]="isChecked(currentAvailableBlockSlides, selectedTrainings)"
           (change)="trainingViewerFacade.requestToggleTrainingSelection(currentAvailableBlockSlides, $event)">
    <span class="mr-auto">{{ page.title }}</span>
    <span class="ml-4 flex-shrink-0">
      {{ currentAvailableBlockSlides.length }} {{ currentAvailableBlockSlides.length === 1 ? ("slide" | translate) : ("slides" | translate) }}
    </span>
  </label>
</ng-template>

<!-- Chapter -->
<ng-template #chapter_template let-chapter="chapter" let-page="page" let-currentAvailableChapterSlides="currentAvailableChapterSlides" let-selectedTrainings="selectedTrainings">
  <div class="mdsf-quiz-list-group" *ngIf="!chapter.hidden && currentAvailableChapterSlides && currentAvailableChapterSlides.length !== 0">
    <label class="mdsf-quiz-list-group-header">
      <input type="checkbox"
             *ngIf="{checked: isChecked(currentAvailableChapterSlides, selectedTrainings), indeterminate: isIndeterminate(currentAvailableChapterSlides, selectedTrainings)} as data"
             [checked]="data.checked"
             [indeterminate]="data.indeterminate && !data.checked"
             (change)="trainingViewerFacade.requestToggleTrainingSelection(currentAvailableChapterSlides, $event)">
      <h1 class="mr-4">{{ page.title }}</h1>
      <span class="ml-auto flex-shrink-0">{{ currentAvailableChapterSlides.length }} {{ currentAvailableChapterSlides.length === 1 ? ("slide" | translate) : ("slides" | translate) }}</span>
      <button class="mdsf-quiz-list-group-expand" (click)="toggleExpanded(chapter, $event)">
        <i class="fa" [class.fa-chevron-down]="!chapter.expanded" [class.fa-chevron-up]="chapter.expanded" aria-hidden="true"></i>
      </button>
    </label>
    <ng-container *ngFor="let block of (nodeSharedFacade.nodeByIds$(chapter.children) | async | sortBy_shared:'asc':'position')">
      <ng-container *ngIf="(trainingViewerFacade.currentAvailableSlidesByParentId$(block.id) | async) as currentAvailableSlides">
        <ng-container *ngTemplateOutlet="block_template;context:{
                      chapter: chapter,
                      block: block,
                      page: (appSharedFacade.page$(block.page) | async),
                      currentAvailableBlockSlides: currentAvailableSlides,
                      selectedTrainings: selectedTrainings}">
        </ng-container>
      </ng-container>
      <!-- block -->
    </ng-container>
  </div>
</ng-template>

<!-- Content -->
<div class="mdsf-quiz-container" *ngIf="(nodeSharedFacade.currentSelectedChapterPage$ | async) as page; else spinner">
  <div id="quiz-content" *ngIf="(trainingViewerFacade.selectedSlides$ | async) as selectedTrainings; else spinner">
    <div class="scrollable editor-input">
      <section [innerHTML]="page.description | safeHtml"></section>
    </div>
    <br/><br/>
    <div id="form-container">
      <div class="mdsf-quiz-list">
        <label class="mdsf-quiz-list-header" *ngIf="(trainingViewerFacade.currentAvailableSlides$ | async) as currentAvailableSlides">
          <input type="checkbox"
                 id="check-all-quiz"
                 value="all"
                 *ngIf="{checked: isChecked(currentAvailableSlides, selectedTrainings), indeterminate: isIndeterminate(currentAvailableSlides, selectedTrainings)} as data"
                 [checked]="data.checked"
                 [indeterminate]="data.indeterminate && !data.checked"
                 (change)="trainingViewerFacade.requestToggleTrainingSelection(currentAvailableSlides, $event)">
          <span translate="select_all" for="check-all-quiz"></span>
        </label>
        <ng-container *ngIf="(nodeSharedFacade.currentSelectedChapterNodes$ | async | sortBy_shared:'asc':'position') as chapters; else spinner">
          <ng-container *ngFor="let chapter of chapters">
            <ng-container *ngIf="(trainingViewerFacade.currentAvailableSlidesByChapterId$(chapter.id) | async) as currentAvailableSlides; else spinner">
              <ng-container *ngTemplateOutlet="chapter_template;context:{
                            chapter: chapter,
                            page: (appSharedFacade.page$(chapter.page) | async),
                            currentAvailableChapterSlides: currentAvailableSlides,
                            selectedTrainings: selectedTrainings}">
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <br/>
    <div class="btn-group d-flex justify-content-end">
      <button class="mdsf-btn mdsf-btn-primary"
              [disabled]="selectedTrainings.length < 1"
              (click)="trainingViewerFacade.requestStartTraining()"
              translate="start_quiz"></button>
    </div>
  </div>
</div>
