import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { NodeControlActions } from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';
import { AuthControlState, NavigationControlState, NodeEntityState } from '../../internal';
import { Navigate } from '@ngxs/router-plugin';

/**
 * Node Control Token
 */
export const NODE_CONTROL_TOKEN = new StateToken<NodeControlStateModel>('nodeControl');

/**
 * Node Control State Model
 */
export interface NodeControlStateModel {
  rootNodesLoaded: boolean;
}

/**
 * Node Control State
 */
@State<NodeControlStateModel>({
  name: NODE_CONTROL_TOKEN,
  defaults: {
    rootNodesLoaded: false,
  }
})
@Injectable()
export class NodeControlState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   */
  public constructor(public store: Store,
                     public messageControlService: MessageControlService) {
  }

  //<editor-fold desc="Actions">

  @Action(NodeControlActions.GetRootNodesSuccess)
  public getRootNodesSuccess({patchState}: StateContext<NodeControlStateModel>) {
    patchState({
      rootNodesLoaded: true
    });
  }

  /**
   * Get Node By Id
   *
   * @param state: StateContext<NodeControlStateModel>
   * @param id: NodeControlActions.GetNodeById
   */
  @Action(NodeControlActions.GetNodeById)
  public getNodeById({getState}: StateContext<NodeControlStateModel>,
                     {id}: NodeControlActions.GetNodeById) {
    if (id === null) {
      if (getState().rootNodesLoaded) {
        return;
      } else {
        this.store.dispatch(new NodeControlActions.GetRootNodesSuccess());
      }
    } else {
      const node = this.store.selectSnapshot(NodeEntityState.nodeById$(id));
      if (node) {
        return;
      }
    }
    return this.messageControlService.sendMessage(
      this.store.selectSnapshot(AuthControlState.token$),
      new NodeControlActions.GetNodeByIdRequest(id)
    );
  }

  /**
   * Get Nodes By Parent Id
   *
   * @param state: StateContext<NodeControlStateModel>
   * @param parentId: NodeControlActions.GetNodesByParentId
   */
  @Action(NodeControlActions.GetNodesByParentId)
  public getNodesByParentId({getState}: StateContext<NodeControlStateModel>,
                            {parentId}: NodeControlActions.GetNodesByParentId) {
    if (parentId === null) {
      if (getState().rootNodesLoaded) {
        return;
      } else {
        this.store.dispatch(new NodeControlActions.GetRootNodesSuccess());
      }
    } else {
      const parentNode = this.store.selectSnapshot(NodeEntityState.nodeById$(parentId));
      if (parentNode) {
        const childNodes = this.store.selectSnapshot(NodeEntityState.nodesByParentId$(parentId));
        if (parentNode.children?.length === childNodes.length) {
          return;
        }
      }
    }
    return this.messageControlService.sendMessage(
      this.store.selectSnapshot(AuthControlState.token$),
      new NodeControlActions.GetNodesByParentIdRequest(parentId)
    );
  }

  /**
   * Get Nodes By Current Route
   */
  @Action(NodeControlActions.GetNodesByCurrentRoute)
  public getNodesByCurrentRoute() {
    const currentSelectedNode = this.store.selectSnapshot(NodeEntityState.currentSelectedNode$);
    if (!currentSelectedNode) {
      const route = this.store.selectSnapshot(NavigationControlState.currentUrl$);
      return this.messageControlService.sendMessage(
        this.store.selectSnapshot(AuthControlState.token$),
        new NodeControlActions.GetNodesByRouteRequest(route)
      );
    } else {
      return this.store.dispatch(new NodeControlActions.GetNodesByParentId(currentSelectedNode.id));
    }
  }

  /**
   * Get Route By Id
   */
  @Action(NodeControlActions.GetRouteById)
  public getRouteById({getState}: StateContext<NodeControlStateModel>,
                      {id}: NodeControlActions.GetRouteById) {
    return this.messageControlService.sendMessage(
      this.store.selectSnapshot(AuthControlState.token$),
      new NodeControlActions.GetRouteByIdRequest(id)
    );
  }

  /**
   * Get Route By Id Success
   */
  @Action(NodeControlActions.GetRouteByIdSuccess)
  public getRouteByIdSuccess({getState}: StateContext<NodeControlStateModel>,
                      {route}: NodeControlActions.GetRouteByIdSuccess) {
    this.store.dispatch(new Navigate([route], undefined, { replaceUrl: true}))
  }

  //</editor-fold>
}
