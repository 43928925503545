import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Actions, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseChainGuard implements CanActivate {
  /**
   * Constructor
   * @param router: Router
   * @param store: Store
   * @param actions$: Actions
   */
  constructor(protected router: Router,
              protected store: Store,
              protected actions$: Actions) {}

  /**
   * Can activate
   * @param route: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return Promise.resolve(false);
  }
}
