import paper from 'paper';
import * as Flatmodels from '@medsurf/flat-models';
import { Marker } from './marker/marker';
import { Keymap } from './marker/keymap';
import { Freeform } from './freeform/freeform';

/**
 * Canvas Container
 */
export class Container {
  /**
   * Members
   */
  private _elements: any[];
  private _selection: any[];
  private _dirty: boolean;
  private _minZoom: any;
  private _offset: { x: number; y: number };
  private _selftestLabel: any;
  private width: number;
  private height: number;
  private _isMicroscopeContainer = true;
  private _groups: Flatmodels.GroupEntityModels.Group[];

  /**
   * Constructor
   *
   * @param _localPaper
   * @param _format
   */
  public constructor(private _localPaper,
                     private _format) {
    this._elements = [];
    this._selection = [];
    this._dirty = true;
    this._minZoom = null;
    this._offset = {x: 0, y: 0};
    this._selftestLabel = null;
  }

  /**
   * Getter scale
   */
  public get scale() {
    if (this._isMicroscopeContainer) {
      return this.getElementById('image').scale;
    }
  }

  /**
   * Getter offset
   */
  public get offset() {
    return this._offset;
  }

  /**
   * Getter elements
   */
  public get elements(): any[] {
    return this._elements;
  }

  /**
   * Getter selection
   */
  public get selection() {
    return this._selection;
  }

  /**
   * Getter markers
   */
  public get markers() {
    return this._elements.filter(element => {
      return element instanceof Marker || element instanceof Freeform || element instanceof Keymap;
    });
  }

  /**
   * Getter minZoom
   */
  public get minZoom(): number {
    return this._minZoom;
  }

  /**
   * Setter groups
   *
   * @param groups: Group
   */
  set groups(groups: Flatmodels.GroupEntityModels.Group[]) {
    this._groups = groups;
  }

  /**
   * Round
   *
   * @param value
   * @returns {number}
   */
  public static round(value: number): number {
    return Math.round(value);
  }

  /**
   * Add element to container
   *
   * @param element
   */
  public addElement(element) {
    this._elements.push(element);
    this._dirty = true;
  }

  /**
   * Remove element
   *
   * @param element
   */
  public removeElement(element) {
    for (let i = this._elements.length; i--;) {
      if (this._elements[i] === element) {
        if (this._elements[i] instanceof paper.PointText) {
          this._elements[i].remove();
          this._elements.splice(i, 1);
          this._dirty = true;
          break;
        } else {
          if (this._localPaper) {
            this._elements[i].cleanUp();
          }
          this._elements.splice(i, 1);
          this._dirty = true;
          break;
        }
      }
    }
  }

  /**
   * Resize container
   */
  public resize() {
    const width = this.getViewportSize().width,
      height = this.getViewportSize().height;
    for (const element of this._elements) {
      element.resize(width, height);
      element.arrange();
    }
  }

  /**
   * Call element arrange function
   */
  public arrange() {
    for (const element of this._elements) {
      element.arrange();
      if (element instanceof Marker) {
        element.element.bringToFront();
      }
    }
    for (const element of this._elements) {
      if (element instanceof Marker && element.useSymbol) {
        element.element.bringToFront();
      }
    }
    this._dirty = true;
  }

  /**
   * Update selected marker radius
   */
  public updateMarkerSizes() {
    for (const element of this._elements) {
      if (element instanceof Marker && element.state === 'selected') {
        for (const selftest of element.selftests) {
          if (selftest.selected && selftest.radius !== selftest._selectedRadius) {
            selftest.setSelectedRadius();
            selftest.selected = false;
          }
        }
        element.state = 'unselected';
      }
    }
  }

  /**
   * Update canvas
   */
  public update() {
    if (this._dirty) {
      this._localPaper.view.draw();
      this._dirty = false;
    }
  }

  /**
   * Show markers
   *
   * @param markers
   */
  public showMarkers(markers) {
    for (const element of this._elements) {
      if (element instanceof Marker || element instanceof Freeform || element instanceof Keymap) {
        element.state = markers ? 'visible' : 'hidden';
      }
    }
    this._dirty = true;
  }

  /**
   * Show selftest
   *
   * @param selftest
   */
  public showSelftest(selftest) {
    for (const element of this._elements) {
      const ignore = element?._model?.annotation?.selfTest?.ignore || false;
      if ((element instanceof Marker || element instanceof Freeform || element instanceof Keymap )) {
        if (ignore) {
          element.state = selftest ? 'visible' : 'hidden';
        } else {
          if (selftest && element instanceof Marker) {
            element.setStandardSelftestRadius();
          }
          element.state = selftest ? 'unselected' : 'hidden';
        }
      }
    }
    this._dirty = true;
  }

  /**
   * Set canvas size
   *
   * @param width: number
   * @param height: number
   */
  public setCanvasSize(width: number,
                       height: number) {
    this.width = width;
    this.height = height;
  }

  /**
   * Set min zoom
   *
   * @param value: number
   */
  public setMinZoom(value: number) {
    this._minZoom = value;
  }

  /**
   * Select element
   *
   * @param item
   * @param add
   */
  public select(item,
                add) {
    if (!item) {
      return;
    }
    if (!add) {
      this.deselectAll();
    }
    this._selection.push(item);
    item.select();
    this._dirty = true;
    this.arrange();
    this.update();
  }

  /**
   * unselect all elements
   */
  public deselectAll() {
    for (const element of this._elements) {
      if (element instanceof Marker || element instanceof Keymap || element instanceof Freeform) {
        element.unselect();
      }
    }
    this._selection = [];
    this._dirty = true;
    this.arrange();
    this.update();
  }

  /**
   * Get viewport size
   */
  public getViewportSize() {
    return this._localPaper.view.viewSize;
  }

  /**
   * Get canvas size
   */
  public getCanvasSize() {
    return {
      width: this.width,
      height: this.height
    };
  }

  /**
   * Get canvas dimensions
   */
  public getCanvasDimensions() {
    if (!this._isMicroscopeContainer) {
      const element = this.getElementById('image');
      return {
        width: this._format.canvas.width * element.scale,
        height: this._format.canvas.height * element.scale
      };
    }
  }

  /**
   * Get element by id
   *
   * @param id: string
   */
  public getElementById(id: string) {
    return this._elements.filter(element => element.id === id)[0];
  }

  /**
   * Get group by id
   *
   * @param id: string
   */
  public getGroupById(id: string): Flatmodels.GroupEntityModels.Group {
    return this._groups?.filter((element: Flatmodels.GroupEntityModels.Group) => element.id === id).shift();
  }


  /**
   * Get relative coords
   *
   * @param x: number
   * @param y: number
   * @param offset: { x: number, y: number }
   * @param scale: number
   */
  public getRelativeCoords(x: number,
                           y: number,
                           offset: { x: number, y: number },
                           scale: number) {
    if (this._isMicroscopeContainer) {
      /** Offset fix */
      if (offset) {
        // Add scaled offset
        if (offset.x && offset.x !== 0) {
          x += offset.x * this._format.canvas.width / scale || 1;
        }
        if (offset.y && offset.y !== 0) {
          y += offset.y * this._format.canvas.height / scale || 1;
        }

        // Multiply by scaled canvas width
        x /= this._format.canvas.width / scale || 1;
        y /= this._format.canvas.height / scale || 1;
        return {x, y};
      } else {
        return {x: x / this.getCanvasSize().width, y: y / this.getCanvasSize().height};
      }
    } else {
      return {
        x: (1 / (this.getCanvasDimensions().width * this.scale) * (x - this.offset.x)),
        y: (1 / (this.getCanvasDimensions().height * this.scale) * (y - this.offset.y))
      };
    }
    /* TODO remove
    return this._isMicroscopeContainer ?
      {x: x / this.getCanvasSize().width, y: y / this.getCanvasSize().height} :
      {
        x: (1 / (this.getCanvasDimensions().width * this.scale) * (x - this.offset.x)),
        y: (1 / (this.getCanvasDimensions().height * this.scale) * (y - this.offset.y))
      };
    */
  }

  /**
   * Get relative delta
   *
   * @param x: number
   * @param y: number
   */
  public getRelativeDelta(x: number,
                          y: number) {
    return this._isMicroscopeContainer ?
      {x, y} :
      {
        x: 1 / (this.getCanvasDimensions().width * this.scale) * x,
        y: 1 / (this.getCanvasDimensions().height * this.scale) * y
      };
  }

  /**
   * Get absolute delta
   *
   * @param x: number
   * @param y: number
   */
  public getAbsoluteDelta(x: number,
                   y: number) {
    return this._isMicroscopeContainer ?
      {x: x * this.getCanvasSize().width, y: y * this.getCanvasSize().height} :
      {
        x: 1 / (this.getCanvasDimensions().width * this.scale) * x,
        y: 1 / (this.getCanvasDimensions().height * this.scale) * y
      };
  }

  /**
   * Get absolute coords
   *
   * @param x: number
   * @param y: number
   * @param offset: {x: number, y: number}
   * @param scale: number
   */
  public getAbsoluteCoords(x: number,
                           y: number,
                           offset: { x: number, y: number },
                           scale: number) {
    if (this._isMicroscopeContainer) {
      return {
        x: Container.round(x * this.getCanvasSize().width),
        y: Container.round(y * this.getCanvasSize().height)
      };
    } else {
      return {
        x: Container.round((x * this.getCanvasDimensions().width) + this.offset.x),
        y: Container.round((y * this.getCanvasDimensions().height) + this.offset.y)
      };
    }
  }

  /**
   * Get Stroke Width
   *
   * @param zoom: number
   */
  public getStrokeWidth(zoom: number) {
    if (this._isMicroscopeContainer) {
      return Math.round((this.getCanvasSize().width / zoom) * (0.02 / 12));
    }
    return null;
  }

  /**
   * Get absolute coords marker tool
   *
   * @param x: number
   * @param y: number
   */
  public getAbsoluteCoordsMarkerTool(x: number,
                                     y: number) {
    return this._isMicroscopeContainer ? {x: x * this.getCanvasSize().width, y: y * this.getCanvasSize().height} : {
      x: Container.round((x * this.getCanvasDimensions().width) + this.offset.x),
      y: Container.round((y * this.getCanvasDimensions().height) + this.offset.y)
    };
  }

  /**
   * Destroy elements
   */
  public destroyElements() {
    for (let i = this._elements.length; i--;) {
      let element = this._elements[i];
      if (element instanceof paper.PointText) {
        element.remove();
        this._elements.splice(i, 1);
        element = null;
      }
      if (this._localPaper && element) {
        element.cleanUp();
      }
      this._elements.splice(i, 1);
      element = null;
    }
  }

  /**
   * Clean up
   */
  public cleanUp() {
    for (let i = this._elements.length; i--;) {
      let element = this._elements[i];
      if (element instanceof paper.PointText) {
        element.remove();
      } else {
        if (this._localPaper) {
          element.cleanUp();
        }
      }
      element = null;
    }
    this._elements = [];
  }
}
