import { AlertControlModels } from "@medsurf/flat-models";

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  AddAlert = '[STORE | CONTROL | ALERT] Add Alert',
  DismissAlert = '[STORE | CONTROL | ALERT] Dismiss Alert',
  ClearAlerts = '[STORE | CONTROL | ALERT] Clear All Alerts',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Add Alert
 */
export class AddAlert {
  public static readonly type = ActionTypes.AddAlert;

  public constructor(public options: AlertControlModels.AlertOptions) {
  }
}

/**
 * Dismiss Alert
 */
export class DismissAlert {
  public static readonly type = ActionTypes.DismissAlert;

  public constructor(public alert: AlertControlModels.Alert) {
  }
}

/**
 * Clear All Alerts
 */
export class ClearAlerts {
  public static readonly type = ActionTypes.ClearAlerts;
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
