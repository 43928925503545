import { Component } from '@angular/core';
import { MenuViewerFacade, SlideViewerFacade } from '@medsurf/flat-facades';
import { MediaControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-slide-main',
  templateUrl: './slide-main.component.html',
  styleUrls: ['./slide-main.component.scss']
})
export class SlideMainComponent {
  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   * @param mediaControlService: MediaControlService
   */
  constructor(public slideViewerFacade: SlideViewerFacade,
              public menuViewerFacade: MenuViewerFacade,
              public mediaControlService: MediaControlService) {
  }
}
