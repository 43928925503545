import { User } from "@medsurf/models";
import { IsEmail, IsNotEmpty, Length, Matches } from "class-validator";
import { BaseValidator } from "./base.validator";

export class LoginValidator extends BaseValidator implements User {
    @IsNotEmpty({ message: 'email_is_required' })
    email?: string;

    @IsNotEmpty({ message: 'password_is_required' })
    password?: string;
}

export class ResetPasswordValidator extends BaseValidator implements User {
    @IsEmail({}, { message: 'email_must_be_email' })
    email?: string;
}

export class SetNewPasswordValidator extends BaseValidator implements User {
    @Length(8, 254, { message: 'password_min_length' })
    @Matches(/[a-z]/, { message: 'password_requires_lowercase' })
    @Matches(/[A-Z]/, { message: 'password_requires_uppercase' })
    @Matches(/[0-9]/, { message: 'password_requires_number' })
    password?: string;
}
