// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetMetadata = '[STORE | CONTROL | NODE] Get Metadata',
  /**
   * Request
   */
  GetMetadataRequest = '[REQUEST | CONTROL | NODE] Get Metadata Request',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Metadata
 */
export class GetMetadata {
  public static readonly type = ActionTypes.GetMetadata;
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Metadata Request
 */
export class GetMetadataRequest {
  public static readonly type = ActionTypes.GetMetadataRequest;
}

// </editor-fold>
