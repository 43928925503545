import Openseadragon from 'openseadragon';

type ZipZoomifyIndex = { [key: string]: [number, number] }
type ZipZoomifyOptions = {
    width: number;
    height: number;
    tilesUrl: string;
}

export class ZipZoomify extends (Openseadragon as any).ZoomifyTileSource {
    private index: ZipZoomifyIndex;

    constructor(options: ZipZoomifyOptions) {
        super(options);
        this.ready = false;
        this.getImageInfo(this.tilesUrl + '.index');
    }

    async getImageInfo(url) {
        try {
            const response = await fetch(url);
            let blob = await response.blob();

            // Check if file is gzip compressed and decompress if necessary
            const magicBytes = new Uint8Array(await blob.slice(0, 2).arrayBuffer());
            if (magicBytes[0] === 0x1f && magicBytes[1] === 0x8b) {
                // @ts-expect-error DecompressionStream not in current ts version
                const ds = new DecompressionStream("gzip");
                // @ts-expect-error ts uses wrong Readable stream (nodejs)
                const decompressedStream = blob.stream().pipeThrough(ds);
                blob = await new Response(decompressedStream).blob();
            }
            const data = await blob.text();

            // parse index file ("0-0-0 85 28842" => "[level]-[x]-[y] [relative byte offset] [length]"
            let offset = 0;
            this.index = (data.split('\n')).reduce((map, e, i) => {
                const parts = e.split(' ');
                const relativeOffset = Number(parts[1]);
                const length = Number(parts[2]);
                offset += relativeOffset;
                map[parts[0]] = [offset, length]
                offset += length
                return map;
            }, {})
            this.ready = true;
            this.raiseEvent('ready', { tileSource: this });
        } catch (err) {
            this.raiseEvent('open-failed', {
                message: err,
                source: url
            });
        }
    }

    supports(data, url) {
        return (data.type && "zipzoomifytileservice" == data.type);
    }

    getTileAjaxHeaders(level, x, y) {
        const part = this.index[`${level}-${x}-${y}`];
        if (part) {
            return {
                'Range': `bytes=${part[0]}-${part[0] + part[1]}`,
            }
        }
    }

    getTileUrl(level, x, y) {
        const part = this.index[`${level}-${x}-${y}`];
        if (part) {
            return this.tilesUrl;
        } else {
            return null;
        }
    }
}