import { Component, Input } from '@angular/core';

@Component({
  selector: 'medsurf-text-format',
  templateUrl: './text-format.component.html',
  styleUrls: ['./text-format.component.scss']
})
export class TextFormatComponent {
  @Input() public text: string;
}
