import paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { End } from '../marker/pointer/end/end';

export class Line {
  /**
   * Members
   */
  private _element: any;
  private _segments: any[];
  private _endType: any;
  private _startType: any;
  private _start: End;
  private _end: End;

  /**
   * Constructor
   *
   * @param _model
   * @param _localPaper
   * @param _format
   * @param _marker
   */
  public constructor(private _model: ImageViewerModels.FreeformModel,
                     private _localPaper,
                     private _format,
                     public _marker) {
    this._element = null;
    this._segments = [];
    this._endType = this._model.freeFormStyle.endType;
    this._startType = this._model.freeFormStyle.startType;

    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    const start = new paper.Point(this._marker.container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    ));
    const end = new paper.Point(this._marker.container.getAbsoluteCoords(
      this._model.source.position.x + this._model.freeFormStyle.width,
      this._model.source.position.y + this._model.freeFormStyle.height,
      this._marker._imageOffset,
      this._marker._imageScale
    ));

    this._segments.push(start);
    this._segments.push(end);

    this._element = new paper.Path(this._segments);

    this._element.strokeColor = this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor;
    this._element.strokeWidth = this._model.freeFormStyle.strokeWidth ||
      this._marker.container.getStrokeWidth(this._model.source.zoom) ||
      this._format.strokeWidth;
    this._element.dashArray = this._model.freeFormStyle.dash ?
      this._model.freeFormStyle.dash
        .split(',')
        .map((dash) => <any>dash *
          (this._marker && this._marker._model.freeFormStyle && this._marker._model.freeFormStyle.strokeWidth ?
              this._marker._model.freeFormStyle.strokeWidth :
              1
          )) :
      [1, 0];
    this._element._model = this._model;

    this._element.freeform = this._marker;
    this._marker._element.addChild(this._element);

    this._start = this.drawEnding(start, this._startType, this._model.freeFormStyle.startRadius, 1);
    this._end = this.drawEnding(end, this._endType, this._model.freeFormStyle.endRadius, 0);
  }

  /**
   * Draw Ending
   *
   * @param position
   * @param type
   * @param radius
   */
  public drawEnding(position, type, radius, index) {
    this._format.strokeColor = this._element.strokeColor;
    if (radius) {
      this._format.circleRadius = radius;
    }
    const ending = new End(type, this._format, position, this._marker);

    if (type === 'dot' || type === 'arrow') {
      ending.element.element.firstChild.freeform = this._marker;
      this._marker._element.addChild(ending.element.element.firstChild);
    } else {
      ending.element.element.freeform = this._marker;
      this._marker._element.addChild(ending.element.element);
    }

    if (type === 'arrow') {
      ending.element.path.rotation = 90 + ending.element.angle(
        this._element.segments[index].point.x,
        this._element.segments[index].point.y,
        this._element.segments[1 - index].point.x,
        this._element.segments[1 - index].point.y
      );
    } else if (type === 'circle') {
      const intersections = this._element.segments[index].path.getIntersections(ending.element.element);
      if (intersections.length) {
        this._element.segments[1 - index].point = intersections[0].point;
      }
      ending.element.element.strokeWidth = this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth;
    }

    return ending;
  }

  /**
   * Clean Up
   */
  public cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }
}
