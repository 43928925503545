import { Alert, AlertOptions } from "@medsurf/models";

// <editor-fold desc="Action Types">

/**
 * Ws action types
 */
export enum AlertActionTypes {
  /**
   * Store
   */
  AddAlert = '[STORE | ALERT ] Add alert',
  DismissAlert = '[STORE | ALERT] Dismiss alert',
  ClearAlerts = '[STORE | ALERT] Clear all alerts',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Add alert
 */
export class AddAlert {
  public static readonly type = AlertActionTypes.AddAlert;

  public constructor(public options: AlertOptions) {
  }
}
/**
 * Dismiss alert
 */
export class DismissAlert {
  public static readonly type = AlertActionTypes.DismissAlert;

  public constructor(public alert: Alert) {
  }
}

/**
 * Clear all alerts
 */
export class ClearAlerts {
  public static readonly type = AlertActionTypes.ClearAlerts;

  public constructor() {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
