import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { UserEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * User Entity Token
 */
export const USER_ENTITIY_TOKEN = new StateToken<UserEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.USER);

/**
 * User Entity State Model
 */
export type UserEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.UserEntityModels.User>;

/**
 * User Entity State
 */
@State<UserEntityStateModel>({
  name: USER_ENTITIY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class UserEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: UserEntityStateModel
   */
  @Selector([UserEntityState])
  public static typedEntities$(state: UserEntityStateModel): { [id: string]: FlatModels.UserEntityModels.User } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.UserEntityModels.User }
   */
  @Selector([UserEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.UserEntityModels.User }): FlatModels.UserEntityModels.User[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.UserEntityModels.User }
   */
  @Selector([UserEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.UserEntityModels.User }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.UserEntityModels.User[]
   */
  @Selector([UserEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.UserEntityModels.User[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<UserEntityStateModel>
   * @param patchState: StateContext<UserEntityStateModel>
   * @param entityQueryResult: UserEntityActions.GetEntitySuccess
   */
  @Action(UserEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<UserEntityStateModel>,
                          {entityQueryResult}: UserEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<UserEntityStateModel>
   * @param patchState: StateContext<UserEntityStateModel>
   * @param entityQueryResults: UserEntityActions.GetEntitiesSuccess
   */
  @Action(UserEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<UserEntityStateModel>,
                            {entityQueryResults}: UserEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<UserEntityStateModel>
   * @param patchState: StateContext<UserEntityStateModel>
   * @param entity: UserEntityActions.AddEntity
   */
  @Action(UserEntityActions.AddEntity)
  @Undoable(UserEntityActions.AddEntity, USER_ENTITIY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<UserEntityStateModel>,
                   {entity}: UserEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<UserEntityStateModel>
   * @param patchState: StateContext<UserEntityStateModel>
   * @param entity: UserEntityActions.UpdateEntity
   */
  @Action(UserEntityActions.UpdateEntity)
  @Undoable(UserEntityActions.UpdateEntity, USER_ENTITIY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<UserEntityStateModel>,
                      {entity}: UserEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<UserEntityStateModel>
   * @param patchState: StateContext<UserEntityStateModel>
   * @param entity: UserEntityActions.RemoveEntity
   */
  @Action(UserEntityActions.RemoveEntity)
  @Undoable(UserEntityActions.RemoveEntity, USER_ENTITIY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<UserEntityStateModel>,
                      {entity}: UserEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
