import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Settings, SETTINGS } from './settings.model';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  private version = 'v1';

  constructor(private http: HttpClient, @Inject(SETTINGS)  private settings: Settings) {}

  public fetch(url: string, params?: HttpParams) {
    return this.http.get<any>(this.getAbsoluteUrl(url), { params })
      .pipe(
        tap(() => null, (error) => {
          console.log(error);
        }),
        map((response) => {
          if (response.status === 'ok') {
            return response.result;
          }
          throw Error('Fetch was not successful');
        }))
        .toPromise();
  }

  public post(url: string, data: any) {
    return this.http.post<any>(this.getAbsoluteUrl(url), data)
      .pipe(
        tap(() => null, (error) => {
          console.log(error);
        }),
        map((response) => response.result))
      .toPromise();
  }

  public update(url: string, data: any) {
    return this.http.put<any>(this.getAbsoluteUrl(url) + '/' + data._id, data)
      .pipe(
        tap(() => null, (error) => {
          console.log(error);
        }),
        map((response) => response.result))
      .toPromise();
  }

  public delete(url: string, data: any) {
    return this.http.delete<any>(this.getAbsoluteUrl(url) + '/' + data._id)
      .pipe(
        tap(() => null, (error) => {
          console.log(error);
        }),
        map((response) => response.result))
      .toPromise();
  }

  public getAbsoluteUrl(url: string): string {
    return this.settings.endpointUrl + this.version + url;
  }
}
