import { v4 as uuid } from 'uuid';
import paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { Label } from '../marker/label/label';

export class Path {
  /**
   * Members
   */
  private _element: any;
  private _path: any;
  private _center: any;

  /**
   * Constructor
   *
   * @param _format
   * @param _marker
   * @param _model
   * @param _localPaper
   * @param path
   */
  public constructor(private _format,
                     private _marker,
                     private _model: ImageViewerModels.FreeformModel,
                     private _localPaper,
                     private path) {
    this._element = null;
    this._path = path;
    this._center = null;
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this.draw();
    if (this._element.freeform._labelVisible) {
      this.drawLabel();
    }
  }

  /**
   * Draw
   */
  public draw() {
    if (this._element) {
      this.cleanUp();
    }

    this._element = this.getElement();

    this._element.freeform = this._marker;

    this._center = new paper.Point(
      this._marker.container.getAbsoluteCoords(
        this._model.source.position.x,
        this._model.source.position.y,
        this._marker._imageOffset,
        this._marker._imageScale
      ));

    this._element.strokeColor = this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor;
    this._element.strokeWidth = this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth;
    this._element.fillColor = this._model.freeFormStyle.background ?
      (this._model.freeFormStyle.backgroundInBorderColor ?
        this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor :
        this._model.freeFormStyle.color || this._format.border.fillColor) :
      undefined;
    if (this._element.fillColor) {
      this._element.fillColor.alpha = this._model.freeFormStyle.opacity;
    }
    this._element.dashArray = this._model.freeFormStyle.dash ?
      this._model.freeFormStyle.dash
        .split(',')
        .map((dash) => <any>dash *
          (this._marker && this._marker._model.freeFormStyle && this._marker._model.freeFormStyle.strokeWidth ?
              this._marker._model.freeFormStyle.strokeWidth :
              1
          )) :
      [1, 0];

    let rotationPoint;
    if (this._path === 'arrow' || this._path === 'triangle') {
      rotationPoint = this._center;
    }

    this._element.rotate(this._model.freeFormStyle.angle || 0, rotationPoint || null);
    this._element.skew(this._model.freeFormStyle.skewAngle || 0);
    this._marker._element.addChild(this._element);
  }

  /**
   * Get Element
   */
  public getElement() {
    const rectangle = new paper.Rectangle(
      new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.source.position.x - 0.5 * this._model.freeFormStyle.width,
        this._model.source.position.y - 0.5 * this._model.freeFormStyle.height,
        this._marker._imageOffset,
        this._marker._imageScale
      )),
      new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.source.position.x + 0.5 * this._model.freeFormStyle.width,
        this._model.source.position.y + 0.5 * this._model.freeFormStyle.height,
        this._marker._imageOffset,
        this._marker._imageScale
      ))
    );

    switch (this._path) {
      case 'rectangle':
        return new paper.Path.Rectangle(rectangle);
      case 'ellipse':
        return new paper.Path.Ellipse(rectangle);
      case 'arrow':
        // eslint-disable-next-line no-case-declarations
        const arrowPath = new paper.Path(this.getArrowSegments());
        arrowPath.closed = true;
        return arrowPath;
      case 'triangle':
        // eslint-disable-next-line no-case-declarations
        const trianglePath = new paper.Path(this.getTriangleSegments());
        trianglePath.closed = true;
        return trianglePath;
    }
  }

  /**
   * Draw Label
   */
  public drawLabel() {
    if (!(this._model.label && this._model.label.text)) {
      return;
    }

    const label = new Label({id: uuid(), text: this._model.label.text}, this._format, this._marker);
    const position: any = {};
    ({x: position.x, y: position.y} = this._center);
    if (this._path === 'arrow' || this._path === 'triangle') {
      const angle = this._model.freeFormStyle.angle || 0;
      // When angle range is in [90, 270], arrow points right
      const arrowPointsRight = angle > 90 && angle < 270;
      const arrowPointsDown = angle === 270;
      const arrowPointsUp = angle === 90;

      if (arrowPointsDown || arrowPointsUp) {
        position.y -= (arrowPointsUp ? -1 : 1) * (this._element.bounds.height + 1.2 * this._format.fontSize);
        position.x -= 0.5 * label.element.bounds.width;
      } else {
        const width = new paper.Point(
          this._marker.container.getAbsoluteCoords(
            this._model.source.position.x + this._model.freeFormStyle.width,
            1, this._marker._imageOffset,
            this._marker._imageScale))
          .x - position.x;
        position.y += (this._format.fontSize + width) * Math.sin(angle * Math.PI / 180);
        position.x += (this._format.fontSize + width) * Math.cos(angle * Math.PI / 180) +
          0.5 * label.element.bounds.width - (arrowPointsRight ? label.element.bounds.width : 0);
      }
    } else {
      position.y += 0.5 * this._element.bounds.height + 1.5 * this._format.fontSize;
    }
    label.element.position = new paper.Point(position.x, position.y);
    this._marker._element.addChild(label.element);
  }

  /**
   * Get Arrow Segments
   */
  public getArrowSegments() {
    const arrowHeadLengthRatio = 127 / 186;
    const arrowHeadWidthRation = 96 / 186;
    const arrowHeadDepthRatio = 16 / 186;
    const lineRatio = 30 / 186;

    const segments = [];

    const position = new paper.Point(this._marker.container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    ));
    const dimension = new paper.Point(this._marker.container.getAbsoluteCoords(
      this._model.freeFormStyle.width,
      this._model.freeFormStyle.height,
      this._marker._imageOffset,
      this._marker._imageScale
    ));

    // 1
    segments.push(new paper.Point(
      position.x,
      position.y
    ));

    // 2
    segments.push(new paper.Point(
      position.x + arrowHeadLengthRatio * dimension.x,
      position.y + 0.5 * (arrowHeadWidthRation - 1) * dimension.y
    ));

    // 3
    segments.push(new paper.Point(
      position.x + arrowHeadLengthRatio * dimension.x - arrowHeadDepthRatio * dimension.x,
      position.y - 0.5 * dimension.y * lineRatio
    ));

    // 4
    segments.push(new paper.Point(
      position.x + dimension.x,
      position.y - 0.5 * dimension.y * lineRatio
    ));

    // 5
    segments.push(new paper.Point(
      position.x + dimension.x,
      position.y + 0.5 * dimension.y * lineRatio
    ));

    // 6
    segments.push(new paper.Point(
      position.x + arrowHeadLengthRatio * dimension.x - arrowHeadDepthRatio * dimension.x,
      position.y + 0.5 * dimension.y * lineRatio
    ));

    // 7
    segments.push(new paper.Point(
      position.x + arrowHeadLengthRatio * dimension.x,
      position.y + 0.5 * (1 - arrowHeadWidthRation) * dimension.y
    ));

    return segments;
  }

  /**
   * Get Triangle Segments
   */
  public getTriangleSegments() {
    const triangleHeadWidthRatio = 96 / 186;
    const triangleHeadDepthRatio = 16 / 186;

    const segments = [];

    const position = new paper.Point(this._marker.container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    ));
    const dimension = new paper.Point(this._marker.container.getAbsoluteCoords(
      this._model.freeFormStyle.width,
      this._model.freeFormStyle.height,
      this._marker._imageOffset,
      this._marker._imageScale
    ));

    // 1
    segments.push(new paper.Point(
      position.x,
      position.y
    ));

    // 2
    segments.push(new paper.Point(
      position.x + dimension.x,
      position.y + 0.5 * (triangleHeadWidthRatio - 1) * dimension.y
    ));

    // 3
    segments.push(new paper.Point(
      position.x + dimension.x - triangleHeadDepthRatio * dimension.x,
      position.y
    ));

    // 4
    segments.push(new paper.Point(
      position.x + dimension.x,
      position.y + 0.5 * (1 - triangleHeadWidthRatio) * dimension.y
    ));

    return segments;
  }

  /**
   * Getter element
   */
  public get element() {
    return this._element;
  }

  /**
   * Set Scale
   */
  public setScale() {
    this.element.strokeWidth = this._format.strokeWidth;
  }

  /**
   * Clean Up
   */
  public cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }
}
