'use strict';
import { Circle } from './circle';
import { Dot } from './dot';
import { Arrow } from './symbol/arrow';

export class End {
  /**
   * Members
   */
  private _element: any;

  /**
   * Constructor
   *
   * @param _endType
   * @param _format
   * @param _target
   * @param _marker
   */
  public constructor(private _endType,
                     private _format,
                     private _target,
                     private _marker) {
    this._element = null;
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    switch (this._endType) {
      case 'circle':
        this._element = new Circle(this._format, this._target, this._marker);
        break;
      case 'arrow':
        this._element = new Arrow(this._format, this._target, this._marker);
        break;
      case undefined:
      case 'none':
        this._element = new Dot(this._format, this._target, this._marker);
        this._element.element.visible = false;
        break;
      default:
      case 'dot':
        this._element = new Dot(this._format, this._target, this._marker);
        break;
    }
  }

  /**
   * Set Scale
   *
   * @param scale: number
   */
  public setScale(scale: number) {
    if (this._endType === 'none') {
      return;
    }
    this._element.setScale(scale);
  }

  /**
   * Getter element
   */
  public get element() {
    return this._element;
  }

  /**
   * Clean Up
   */
  public cleanUp() {
    if (this._element) {
      this._element.cleanUp();
    }
  }
}
