import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MetadataEntityModels } from '@medsurf/flat-models';
import {
  FeedbackControlService,
  FLAT_SETTINGS,
  MatomoControlService,
  MediaControlService,
  ModalControlService,
  NavigationControlService,
  SettingControlModel
} from '@medsurf/flat-services';
import { AppSharedFacade, MenuViewerFacade, NavigationControlFacade } from '@medsurf/flat-facades';
import { MetadataControlActions } from '@medsurf/flat-actions';

@Component({
  selector: 'medsurf-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  private _destroyed$ = new Subject<boolean>();
  private metadata: MetadataEntityModels.Metadata = null;
  public isLoader: boolean;

  /**
   * Constructor
   *
   * @param router: Router
   * @param store: Store
   * @param actions$: Actions
   * @param meta: Meta
   * @param translate: TranslateService
   * @param mediaControlService: MediaControlService
   * @param feedbackControlService: FeedbackControlService
   * @param modalControlService: ModalControlService
   * @param navigationControlService: NavigationControlService
   * @param appSharedFacade: AppSharedFacade
   * @param menuViewerFacade: MenuViewerFacade
   * @param navigationControlFacade: NavigationControlFacade
   * @param navigationService: NavigationService
   * @param matomoControlService: MatomoControlService
   * @param settings: SettingControlModel
   */
  constructor(public router: Router,
              public store: Store,
              public actions$: Actions,
              private meta: Meta,
              public translate: TranslateService,
              public mediaControlService: MediaControlService,
              public feedbackControlService: FeedbackControlService,
              public modalControlService: ModalControlService,
              public navigationControlService: NavigationControlService,
              public appSharedFacade: AppSharedFacade,
              public menuViewerFacade: MenuViewerFacade,
              public navigationControlFacade: NavigationControlFacade,
              public matomoControlService: MatomoControlService,
              @Inject(FLAT_SETTINGS) private settings: SettingControlModel
  ) {
    this.isLoader = true;

    translate.setDefaultLang('de');
    translate.use('de');
  }

  ngOnInit(): void {
    this.meta.updateTag({ property: 'og:locale', content: 'de_CH' });

    /* TODO meta ???
    const order = [...NavigationControlState.order].reverse();
    this.currentPath$.pipe(filter(path => !!path), takeUntil(this._destroyed$))
      .subscribe((path: NavigationControlModels.PathModel) => {
 
      const nodes: Node[] = order.map(key => {
        return path[key];
      }).filter(node => !!node);

      const currentNode = nodes[0] || null;
      const parentNode = nodes[1] || null;
      const type = (nodes?.[0] as any)?.page?.type;

      const metaTags = {
        title: (currentNode as any)?.page?.title || this.metadata?.title || '',
        type: [PageEntityModels.PageType.SLIDE, PageEntityModels.PageType.TRAINING].includes(type) ? 'article' : 'website',
        media: this.metadata?.carouselMedia,
        description: this.metadata?.description,
        keywords: this.metadata?.keywords
      };

      const span = document.createElement('span');
      if ([PageEntityModels.PageType.SLIDE, PageEntityModels.PageType.TRAINING].includes(type)) {
        span.innerHTML = ((currentNode as any)?.page as SlideEntityModels.Slide)?.body || '';
      } else {
        span.innerHTML = ((currentNode as any)?.page as PageEntityModels.Folder)?.description || '';
      }
      const description = span.querySelector('p, span')?.textContent?.trim();
      if (description) {
        metaTags.description = description;
      }

      if ([PageEntityModels.PageType.SLIDE, PageEntityModels.PageType.TRAINING].includes(type)) {
        metaTags.media = ((currentNode as any)?.page as SlideEntityModels.Slide)?.thumbnailMedia
          || metaTags.media;
      } else {
        metaTags.media = ((currentNode as any)?.page as PageEntityModels.Folder)?.media
          || ((parentNode as any)?.page as PageEntityModels.Folder)?.media || metaTags.media;
      }

      this.meta.updateTag({property: 'og:title', content: metaTags.title });
      this.meta.updateTag({property: 'og:type', content: metaTags.type });
      this.meta.updateTag({property: 'og:description', content: metaTags.description });
      if (metaTags.media) {
        this.meta.updateTag({
          property: 'og:image', content: this.mediaControlService.getUrl(metaTags.media, 'thumbnail')
            || this.mediaControlService.getUrl(metaTags.media)
        });
      } else {
        this.meta.removeTag("property = 'og:image");
      }

      this.meta.updateTag({name: 'keywords', content: metaTags.keywords});
      this.meta.updateTag({property: 'description', content: metaTags.description});
    });
    */

    this.store.dispatch(new MetadataControlActions.GetMetadata());
    this.appSharedFacade.metadata$.pipe(takeUntil(this._destroyed$)).subscribe((metadata) => {
      this.metadata = metadata;
    });

    this.router.events.pipe(takeUntil(this._destroyed$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isLoader = false;
        this.meta.updateTag({ property: 'og:url', content: window.location.href });

        setTimeout(() => {
          this.matomoControlService.trackPageView(val.url, document.title);
        }, 300);
      }
    });
  }

  /**
   * Ng On Destroy
   */
  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
