import { CopyrightPosition, Grid, Media, MediaType, Node, Slide } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Media action types
 */
export enum MediaActionTypes {
  /**
   * Store
   */
  SetSearchQuery = '[STORE | MEDIA] Set search query',
  SetTypeFilter = '[STORE | MEDIA] Set type filter',
  SetUploadedByMe = '[STORE | MEDIA] Set uploaded by me',
  SetUsedInFilter = '[STORE | MEDIA] Set used in filter',
  SetSelectedMedia = '[STORE | MEDIA] Set selected media',
  SetPage = '[STORE | MEDIA] Set page',
  SetOrder = '[STORE | MEDIA] Set order',
  SetOrderBy = '[STORE | MEDIA] Set order attribute',
  GetMedia = '[STORE | MEDIA] Get Media',
  GetMediaSuccess = '[STORE | MEDIA] Get Media success',
  UpdateMedia = '[STORE | MEDIA] Update Media',
  UpdateMediaSuccess = '[STORE | MEDIA] Update Media Success',
  DeleteMedia = '[STORE | MEDIA] Delete Media',
  DeleteMediaSuccess = '[STORE | MEDIA] Delete Media Success',
  AddWatermark = '[STORE | MEDIA] Add Watermark',
  AddWatermarkSuccess = '[STORE | MEDIA] Add Watermark Success',
  GenerateSlidePDF = '[STORE | MEDIA] Generate slide PDF',
  GenerateSlidePDFSuccess = '[STORE | MEDIA] Generate slide PDF Success',
  GenerateChapterPDF = '[STORE | MEDIA] Generate chapter PDF',
  GenerateChapterPDFSuccess = '[STORE | MEDIA] Generate chapter PDF Success',
  PostDeepzoomSuccess = '[STORE | MEDIA] Post deepzoom Success',
  /**
   * Request
   */
  GetMediaRequest = '[REQUEST | MEDIA] Get Media Request',
  UpdateMediaRequest = '[REQUEST | MEDIA] Update Media Request ',
  DeleteMediaRequest = '[REQUEST | MEDIA] Delete Media Request ',
  AddWatermarkRequest = '[REQUEST | MEDIA] Add Watermark Request',
  GenerateSlidePDFRequest = '[REQUEST | MEDIA] Generate slide PDF Request',
  GenerateChapterPDFRequest = '[REQUEST | MEDIA] Generate chapter PDF Request',
  PostDeepzoomRequest = '[REQUEST | MEDIA] Post deepzoom Request'
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Set search string
 */
export class SetSearchQuery {
  public static readonly type = MediaActionTypes.SetSearchQuery;

  public constructor(public searchQuery: string) {
  }
}

/**
 * Set type filter
 */
export class SetTypeFilter {
  public static readonly type = MediaActionTypes.SetTypeFilter;

  public constructor(public typeFilter: MediaType[]) {
  }
}

/**
 * Set uploaded by me
 */
export class SetUploadedByMe {
  public static readonly type = MediaActionTypes.SetUploadedByMe;

  public constructor(public uploadedByMe: boolean) {
  }
}

/**
 * Set used in filter
 */
export class SetUsedInFilter {
  public static readonly type = MediaActionTypes.SetUsedInFilter;

  public constructor(public usedInFilter: Node[]) {
  }
}

/**
 * Set selected media
 */
export class SetSelectedMedia {
  public static readonly type = MediaActionTypes.SetSelectedMedia;

  public constructor(public selectedMedia: Media[], public initalValue = false) {
  }
}

/**
 * Set page
 */
export class SetPage {
  public static readonly type = MediaActionTypes.SetPage;

  public constructor(public page: number) {
  }
}

/**
 * Set order
 */
export class SetOrder {
  public static readonly type = MediaActionTypes.SetOrder;

  public constructor(public order: 'ASC' | 'DESC' | '') {
  }
}

/**
 * Set order
 */
export class SetOrderBy {
  public static readonly type = MediaActionTypes.SetOrderBy;

  public constructor(public orderBy: string) {
  }
}

/**
 * Get Media
 */
export class GetMedia {
  public static readonly type = MediaActionTypes.GetMedia;

  public constructor() {
  }
}

/**
 * Get Media Success
 */
export class GetMediaSuccess {
  public static readonly type = MediaActionTypes.GetMediaSuccess;

  public constructor(
    public results: Media[],
    public total: number,
    public perPage: number
  ) {
  }
}

/**
 * Update Media
 */
export class UpdateMedia {
  public static readonly type = MediaActionTypes.UpdateMedia;

  public constructor(public media: Media) {
  }
}

/**
 * Update Media Success
 */
export class UpdateMediaSuccess {
  public static readonly type = MediaActionTypes.UpdateMediaSuccess;

  public constructor(public media: Media) {
  }
}

/**
 * Delete Media
 */
export class DeleteMedia {
  public static readonly type = MediaActionTypes.DeleteMedia;

  public constructor(public media: Media[]) {
  }
}

/**
 * Delete Media Success
 */
export class DeleteMediaSuccess {
  public static readonly type = MediaActionTypes.DeleteMediaSuccess;

  public constructor(public mediaIds: string[]) {
  }
}

/**
 * Add Watermark
 */
export class AddWatermark {
  public static readonly type = MediaActionTypes.AddWatermark;

  public constructor(
    public media: Media,
    public position: CopyrightPosition
  ) {
  }
}

/**
 * Add Watermark Success
 */
export class AddWatermarkSuccess {
  public static readonly type = MediaActionTypes.AddWatermarkSuccess;

  public constructor(public media: Media) {
  }
}

/**
 * Generate slide PDF
 */
export class GenerateSlidePDF {
  public static readonly type = MediaActionTypes.GenerateSlidePDF;

  public constructor(
    public slide: Slide,
    public forAuthor: boolean
  ) {
  }
}

/**
 * Generate slide PDF Success
 */
export class GenerateSlidePDFSuccess {
  public static readonly type = MediaActionTypes.GenerateSlidePDFSuccess;

  public constructor(public media: Media, public forAuthor: boolean) {
  }
}

/**
 * Generate chapter PDF
 */
export class GenerateChapterPDF {
  public static readonly type = MediaActionTypes.GenerateChapterPDF;

  public constructor(
    public chapter: Node,
    public forceGeneration: boolean,
    public forAuthor: boolean,
  ) {
  }
}

/**
 * Generate chapter PDF Success
 */
export class GenerateChapterPDFSuccess {
  public static readonly type = MediaActionTypes.GenerateChapterPDFSuccess;

  public constructor(public media: Media, public forAuthor: boolean) {
  }
}

/**
 * Post deepzoom Success
 */
export class PostDeepzoomSuccess {
  public static readonly type = MediaActionTypes.PostDeepzoomSuccess;

  public constructor(public media: Media) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Media Request
 */
export class GetMediaRequest {
  public static readonly type = MediaActionTypes.GetMediaRequest;

  public constructor(
    public searchQuery: string,
    public typeFilter: MediaType[],
    public uploadedByMe: boolean,
    public usedInFilter: Node[],
    public selectedMedia: Media[],
    public page: number,
    public order: 'ASC' | 'DESC' | '',
    public orderBy: string
  ) {
  }
}

/**
 * Update Media Request
 */
export class UpdateMediaRequest {
  public static readonly type = MediaActionTypes.UpdateMediaRequest;

  public constructor(public media: Media) {
  }
}

/**
 * Delete Media Request
 */
export class DeleteMediaRequest {
  public static readonly type = MediaActionTypes.DeleteMediaRequest;

  public constructor(public media: Media[]) {
  }
}

/**
 * Add Watermark Request
 */
export class AddWatermarkRequest {
  public static readonly type = MediaActionTypes.AddWatermarkRequest;

  public constructor(
    public id: string,
    public lines: string[],
    public position: CopyrightPosition,
    public backgroundColor?: string,
    public fontSize?: string
  ) {
  }
}

/**
 * Update slide PDF request
 */
export class GenerateSlidePDFRequest {
  public static readonly type = MediaActionTypes.GenerateSlidePDFRequest;

  public constructor(
    public slide: Slide,
    public forAuthor: boolean
  ) {
  }
}

/**
 * Update chapter PDF request
 */
export class GenerateChapterPDFRequest {
  public static readonly type = MediaActionTypes.GenerateChapterPDFRequest;

  public constructor(
    public chapter: Node,
    public forceGeneration: boolean, 
    public forAuthor: boolean
  ) {
  }
}

/**
 * Post deepzoom request
 */
export class PostDeepzoomRequest {
  public static readonly type = MediaActionTypes.PostDeepzoomRequest;

  public constructor(public s3FolderKey: string) {
  }
}

// </editor-fold>
