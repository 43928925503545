// <editor-fold desc="Action Types">

import { SlideViewerModels } from '@medsurf/flat-models';

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetSlideEntitySuccess = '[STORE | CONTROL | SLIDE] Get Slide Entity Success',
  GetSlideEntitiesSuccess = '[STORE | CONTROL | SLIDE] Get Slide Entities Success',
  GetSlideDataByPageId = '[STORE | CONTROL | SLIDE] Get Slide Data By Page Id',
  /**
   * Request
   */
  GetSlideDataByPageIdRequest = '[REQUEST | CONTROL | SLIDE] Get Slide Data By Page Id Request'
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Slide Entity Success
 */
export class GetSlideEntitySuccess {
  public static readonly type = ActionTypes.GetSlideEntitySuccess;

  public constructor(public entityQueryResult: SlideViewerModels.SlideViewerPageQueryResult) {
  }
}

/**
 * Get Slide Entities Success
 */
export class GetSlideEntitiesSuccess {
  public static readonly type = ActionTypes.GetSlideEntitiesSuccess;

  public constructor(public entityQueryResults: SlideViewerModels.SlideViewerPageQueryResult[]) {
  }
}

/**
 * Get Slide Data By Page Id
 */
export class GetSlideDataByPageId {
  public static readonly type = ActionTypes.GetSlideDataByPageId;

  public constructor(public pageId: string) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Slide Data By Page Id Request
 */
export class GetSlideDataByPageIdRequest {
  public static readonly type = ActionTypes.GetSlideDataByPageIdRequest;

  public constructor(public pageId: string) {
  }
}

// </editor-fold>
