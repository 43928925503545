import { SlideNode } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Search action types
 */
export enum SearchActionTypes {
  /**
   * Store
   */
  SetSearchString = '[STORE | SEARCH] Set search string',
  ResetSearch = '[STORE | SEARCH] Reset Search',
  Search = '[STORE | SEARCH] Search',
  SearchSuccess = '[STORE | SEARCH] Search success',
  GetSuggestions = '[STORE | SEARCH] Get suggestions',
  GetSuggestionsSuccess = '[STORE | SEARCH] Get suggestions success',
  /**
   * Request
   */
  SearchRequest = '[REQUEST | SEARCH] Search',
  GetSuggestionsRequest = '[REQUEST | SEARCH] Get suggestions',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Set search string
 */
export class SetSearchString {
  public static readonly type = SearchActionTypes.SetSearchString;

  public constructor(public searchString: string) {
  }
}

/**
 * Reset search
 */
export class ResetSearch {
  public static readonly type = SearchActionTypes.ResetSearch;

  public constructor() {
  }
}

/**
 * Search
 */
export class Search {
  public static readonly type = SearchActionTypes.Search;

  public constructor() {
  }
}

/**
 * Search Success
 */
export class SearchSuccess {
  public static readonly type = SearchActionTypes.SearchSuccess;

  public constructor(public resultSlides: SlideNode[], public resultMarkers: SlideNode[]) {
  }
}

/**
 * Get Suggestions
 */
export class GetSuggesstions {
  public static readonly type = SearchActionTypes.GetSuggestions;

  public constructor() {
  }
}

/**
 * Search Success
 */
export class GetSuggestionsSuccess {
  public static readonly type = SearchActionTypes.GetSuggestionsSuccess;

  public constructor(public suggestions: string[]) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Search Request
 */
export class SearchRequest {
  public static readonly type = SearchActionTypes.SearchRequest;

  public constructor(public searchString: string) {
  }
}

/**
 * Get Suggestions Request
 */
export class GetSuggesstionsRequest {
  public static readonly type = SearchActionTypes.GetSuggestionsRequest;

  public constructor(public searchString: string) {
  }
}

// </editor-fold>
