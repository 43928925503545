import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { AnnotationMode, DisplayMode, ImageMode } from '@medsurf/models';
import { SetAnnotationMode, SetDisplayMode, ToggleShowHeader, ToggleShowModality, ToggleShowBody, ToggleShowQuiz,
  ToggleShowInteractiveArea, SetImageMode } from '@medsurf/actions';

/**
 * Index state model
 */
export interface MenuStateModel {
  displayMode: DisplayMode;
  annotationMode: AnnotationMode;
  imageMode: ImageMode;
  showHeader: boolean;
  showBody: boolean;
  showQuiz: boolean;
  showInteractiveArea: boolean;
  showModality: boolean;
}

/**
 * Index State
 */
@State<MenuStateModel>({
  name: 'menu',
  defaults: {
    displayMode: DisplayMode.FULL,
    annotationMode: AnnotationMode.ANNOTATION,
    imageMode: ImageMode.IMAGE,
    showHeader: true,
    showBody: true,
    showQuiz: true,
    showInteractiveArea: true,
    showModality: true
  }
})
@Injectable()
export class MenuState {
  @Selector()
  public static displayMode(state: MenuStateModel): DisplayMode {
    return state.displayMode;
  }

  @Selector()
  public static annotationMode(state: MenuStateModel): AnnotationMode {
    return state.annotationMode;
  }

  @Selector()
  public static imageMode(state: MenuStateModel): ImageMode {
    return state.imageMode;
  }

  @Selector()
  public static showHeader(state: MenuStateModel): boolean {
    return state.showHeader;
  }

  @Selector()
  public static showBody(state: MenuStateModel): boolean {
    return state.showBody;
  }

  @Selector()
  public static showQuiz(state: MenuStateModel): boolean {
    return state.showQuiz;
  }

  @Selector()
  public static showInteractiveArea(state: MenuStateModel): boolean {
    return state.showInteractiveArea;
  }

  @Selector()
  public static showText(state: MenuStateModel): boolean {
    return state.displayMode === DisplayMode.FULL || state.displayMode === DisplayMode.TEXT;
  }

  @Selector()
  public static showImage(state: MenuStateModel): boolean {
    return state.displayMode === DisplayMode.FULL || state.displayMode === DisplayMode.IMAGE;
  }

  @Selector()
  public static showModality(state: MenuStateModel): boolean {
    return state.showModality;
  }


  @Action(SetDisplayMode)
  public setDisplayMode({getState, setState}: StateContext<MenuStateModel>, {displayMode}: SetDisplayMode) {
    setState(
      patch({
        displayMode
      }));
  }

  @Action(SetAnnotationMode)
  public setAnnotationMode({getState, setState}: StateContext<MenuStateModel>, {annotationMode}: SetAnnotationMode) {
    setState(
      patch({
        annotationMode
      }));
  }

  @Action(SetImageMode)
  public setImageMode({setState}: StateContext<MenuStateModel>, {imageMode}: SetImageMode) {
    setState(
      patch({
        imageMode
      }));
  }

  @Action(ToggleShowHeader)
  public toggleShowHeader({setState}: StateContext<MenuStateModel>) {
    setState(
      patch({
        showHeader: existing => !existing
      }));
  }

  @Action(ToggleShowBody)
  public toggleShowBody({setState}: StateContext<MenuStateModel>) {
    setState(
      patch({
        showBody: existing => !existing
      }));
  }

  @Action(ToggleShowQuiz)
  public toggleShowQuiz({setState}: StateContext<MenuStateModel>) {
    setState(
      patch({
        showQuiz: existing => !existing
      }));
  }

  @Action(ToggleShowInteractiveArea)
  public toggleShowInteractiveArea({setState}: StateContext<MenuStateModel>) {
    setState(
      patch({
        showInteractiveArea: existing => !existing
      }));
  }

  @Action(ToggleShowModality)
  public toggleShowModality({setState}: StateContext<MenuStateModel>) {
    setState(
      patch({
        showModality: existing => !existing
      }));
  }
}
