import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { AddRootNode, GetChildNodes, GetChildNodesSuccess, GetNodeForUrlFail, GetNodeForUrlSuccess, IndexChanged,
  PathModel } from '@medsurf/actions';
import { Folder, Grid, Node, Page, PageType, Role, Slide, User } from '@medsurf/models';
import { getRouteString } from '@medsurf/helpers';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router
  ) {}

  public newNode(depth: number, parent: Node, title: string, isTraining?: boolean) {
    const newItem: Node = {
      id: uuid(),
      children: [],
      page: null,
      shares: [],
    };
    switch (depth) {
      case 0:
      case 1:
        newItem.page = {
          description: '',
          type: PageType.FOLDER
        } as Folder;
        break;
      case 2:
        newItem.page = {
          type: PageType.GRID
        } as Grid;
        break;
      case 3:
        newItem.page = {
          type: isTraining ? PageType.TRAINING : PageType.SLIDE,
          layers: [{
            images: [{
              annotations: [],
              pois: [],
              background: {},
              dimensions: {}
            }]
          }
          ],
        } as Slide;
        break;
    }
    newItem.page.title = title;
    this.addChildToNode(parent, newItem);
    return newItem;
  }

  public prepareIndexMap(nodes: Node[]) {
    const map: Map<string, Node> = new Map();
    function buildMap(nds: Node[]) {
      nds.forEach((node) => {
        map.set(node.id, node);
        if (node.children?.length > 0) {
          buildMap(node.children);
        }
      });
    }
    buildMap(nodes);
    return map;
  }

  public addChildToNode(parent: Node, child: Node) {
    if (parent) {
      this.actions$.pipe(ofActionSuccessful(GetChildNodesSuccess), take(1)).subscribe(() => {
        parent.children.push(child);
        this.childrenMoved(parent);
      });
      this.store.dispatch(new GetChildNodes(parent, true, true));
    } else {
      this.store.dispatch(new AddRootNode(child));
    }
  }

  public findNode(id: string, nodes: Node[]): Node {
    if (nodes) {
      let foundNode = nodes.find(node => node.id === id);
      if (foundNode) {
        return foundNode;
      }
      for (const node of nodes) {
        foundNode = this.findNode(id, node.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  }

  public replacePage(page: Page, nodes: Node[]) {
    for (const node of nodes) {
      if (node.page.id === page.id) {
        node.page = page;
      }
      if (node.children && node.children.length > 0) {
        this.replacePage(page, node.children);
      }
    }
  }

  public replaceNode(newNode: Node, nodes: Node[]) {
    for (const node of nodes) {
      if (node.id === newNode.id && !node.deleted) {
        newNode.children = node.children;
        nodes[nodes.indexOf(node)] = newNode;
      }
      if (node.children && node.children.length > 0) {
        this.replaceNode(newNode, node.children);
      }
    }
  }

  public async findNodeForUrl(url: string, queryParams: Params, index: Node[], loadTrainings?: boolean, loadRestrictedAndHidden?: boolean): Promise<PathModel> {
    const urlSegments = url.split('/');
    const path: Node[] = [];
    let nodes = index;
    let currentNode: Node = null;
    let needsRedirect = false;
    while (urlSegments.length > 0) {
      const currentSegment = urlSegments.shift();
      if (currentSegment) {
        currentNode = nodes?.find(node => (node?.route === currentSegment || node?.route === getRouteString(currentSegment)) && !node.deleted);
        if (currentNode) {
          if (currentNode.route !== currentSegment) {
            needsRedirect = true;
          }
          nodes = currentNode.children;
          path.push(currentNode);
          if (!nodes) {
            if (currentNode.hasChildren) {
              if (currentNode.page.type === PageType.QUIZ_RUN) {
                this.store.dispatch(new GetChildNodes(currentNode, true, loadRestrictedAndHidden, queryParams));
              } else {
                this.store.dispatch(new GetChildNodes(currentNode, loadTrainings, loadRestrictedAndHidden));
              }
              return;
            }
          }
        } else {
          this.store.dispatch(new GetNodeForUrlFail(this.arrayToPath(path)));
          return;
        }
      }
    }
    if (needsRedirect) {
      const redirectUrl = path.map(n => n.route).join('/');
      this.router.navigateByUrl(redirectUrl, {
        replaceUrl: true
      });
    } else {
      this.store.dispatch(new GetNodeForUrlSuccess(this.arrayToPath(path)));
    }
  }

  private arrayToPath(array: Node[]) {
    return {
      eLearning: array[0],
      subject: array[1],
      chapter: array[2],
      block: array[3],
      slide: array[4]
    };
  }

  public childrenMoved(parent: Node) {
    const dirtyNodes = [];
    for (const idx in parent.children) {
      if (!parent.children.hasOwnProperty(idx)) {
        return;
      }
      let dirty = false;
      const child = parent.children[idx];
      if (child.position !== Number(idx)) {
        child.position = Number(idx);
        dirty = true;
      }
      if (!child.route) {
        child.route = getRouteString(child.page.title);
        let count = 1;
        while (parent.children.findIndex(other => other.route === child.route) !== Number(idx)) {
          child.route = getRouteString(child.page.title + '-' + count++);
        }
        dirty = true;
      }
      if (parent.id && child.parentId !== parent.id) {
        child.parentId = parent.id;
        dirty = true;
      }
      if (dirty) {
        dirtyNodes.push(child);
      }
    }
    this.store.dispatch(new IndexChanged(dirtyNodes));
  }

  public editLocked(node: Node, map: Map<string, Node>, user: User) {
    if (!user) {
      return true;
    }

    if (user.role === Role.ADMIN) {
      return false;
    }

    let editLocked = true;
    let n = node;
    while (n) {
      if (n.shares.some((share) => share.id === user.id)) {
        editLocked = false;
        break;
      }
      n = n.parentId ? map.get(n.parentId) : null;
    }
    return editLocked;
  }

  public isNodeLocked(node: Node, map: Map<string, Node>, user: User) {
    let locked = false;
    let n = node;
    while (n) {
      if (n.restricted) {
        if (user && (user.role === Role.ADMIN || n.shares.some((share) => share.id === user.id))) {
          locked = false;
          break;
        } else {
          locked = true;
          break;
        }
      }
      n = n.parentId ? map.get(n.parentId) : null;
    }

    return locked;
  }
}
