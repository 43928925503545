import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FooterLinkViewerModels } from '@medsurf/flat-models';
import { PageSharedFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent {
  /**
   * TODO move to route data
   * Members
   */
  public footerLinks: FooterLinkViewerModels.FooterLink[] = [
    {title: 'DATENSCHUTZERKLÄRUNG', url: 'privacy'},
    {title: 'IMPRESSUM', url: 'impressum'}
  ];

  /**
   * Constructor
   *
   * @param pageSharedFacade: PageSharedFacade
   * @param titleService: Title
   */
  constructor(public pageSharedFacade: PageSharedFacade,
              public titleService: Title) {
  }

  /**
   * Set Title
   *
   * @param title: string
   */
  public setTitle(title: string) {
    this.titleService.setTitle(title);
    return true;
  }
}
