import { Injectable } from '@angular/core';
import { BaseChainBaseGuard } from '../../internal';

@Injectable({
  providedIn: 'root'
})
export class RestrictedViewerGuard extends BaseChainBaseGuard {
  /**
   * Can Activate
   */
  public override async canActivate(): Promise<boolean> {
    // Check Route
    /* TODO
    if (this.baseChainGuardFacade.getLocked()) {
      const queryParams: Params = {};
      if (next.url.length > 0) {
        queryParams['r'] = `/${next.url.map(s => s.path).join('/')}`;
      }
      await this.router.navigate(['login'], {queryParams});
      return Promise.resolve(false);
    } else {
      return Promise.resolve(true);
    }
    */

    // Return TODO remove
    return Promise.resolve(true);
  }
}
