export function getRouteString(value: string): string {
  if (!value) {
    return '';
  }
  return value
    .toString()                   // Cast to string (optional)
    .toLowerCase()
    .replace('ä', 'ae')
    .replace('ö', 'oe')
    .replace('ü', 'ue')
    .replace('ß', 'ss')
    .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .replace( /[\u0300-\u036f]/g, '' )
    .trim()                       // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-')         // Replace spaces with -
    .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
    .replace(/\_/g,'-')           // Replace _ with -
    .replace(/\-\-+/g, '-')       // Replace multiple - with single -
    .slice(0, 100)
    .replace(/\-$/g, ''); 
}


export async function onFontLoad(family: string, opts: { style?: string; weight?: string; } = {}, callback: { (): void }) {
  const ttl = 3000;
  const start = Date.now();
  let loaded = false;
  while(!loaded && start + ttl > Date.now()) {
      const fonts = await document.fonts.load(`${opts?.style || 'normal'} ${opts?.weight || 'normal'} 100px "${family}"`);
      if (fonts.length > 0) {
          loaded = true;
      } else {
          await new Promise((resolve) => { 
              setTimeout(() => {
                  resolve(true);
              }, 25);
          })
      }
  }
  if (loaded) {
      callback();
  }
}