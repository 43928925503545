import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Actions, Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class OrderedChainGuard implements CanActivate {
  /**
   * Members
   */
  private next: ActivatedRouteSnapshot;
  private state: RouterStateSnapshot;

  /**
   * Constructor
   * @param router: Router
   * @param store: Store
   * @param actions$: Actions
   */
  constructor(private router: Router,
              private store: Store,
              private actions$: Actions) {}

  /**
   * Check if ws is established
   * @param next: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   */
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Promise<boolean> {
    this.next = next;
    this.state = state;

    return new Promise<boolean>(async (resolve) => {
      if (!this.next.data) {
        resolve(true);
        return;
      }

      if (!this.next.data.orderedGuards || !this.next.data.orderedGuards.length) {
        resolve(true);
        return;
      }

      resolve(await this.executeGuards());
    });
  }

  /**
   * Execute the guards sent in the route data
   * @param guardIndex: number
   */
  private executeGuards(guardIndex: number = 0): Promise<boolean> {
    return this.activateGuard(this.next.data.orderedGuards[guardIndex])
      .then(() => {
        if (guardIndex < this.next.data.orderedGuards.length - 1) {
          return this.executeGuards(guardIndex + 1);
        } else {
          return Promise.resolve(true);
        }
      })
      .catch(() => {
        return Promise.reject(false);
      });
  }

  /**
   * Create an instance of the guard and fire canActivate method returning a promise
   * @param guardClass: any
   */
  private activateGuard(guardClass: any): Promise<boolean> {
    const guard = new guardClass(this.router, this.store, this.actions$);
    return guard.canActivate(this.next, this.state);
  }
}
