import { BaseChainGuard } from './baseChain.guard';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ofActionDispatched } from '@ngxs/store';
import { AuthState } from '@medsurf/state';
import { Me, MeSuccess } from '@medsurf/actions';

@Injectable({
  providedIn: 'root'
})
export class UserGuard extends BaseChainGuard {
  /**
   * Check if user is loaded
   * @param next: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   */
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Promise<boolean> {
    if (this.store.selectSnapshot(AuthState.user) === null) {
      return new Promise<boolean>((resolve) => {
        this.actions$.pipe(ofActionDispatched(MeSuccess)).subscribe(() => {
          setTimeout(() => {
            resolve(true);
          }, 200);
        });
        this.store.dispatch(new Me());
      });
    } else {
      return Promise.resolve(true);
    }
  }
}
