import { v4 as uuid } from 'uuid';

/**
 * Alert Type
 */
export enum AlertType {
  ERROR = 'Error',
  WARNING = 'Warning',
  SUCCESS = 'Success',
  INFO = 'Information'
}

/**
 * Alert Options
 */
export interface AlertOptions {
  message?: string;
  params?: object;
  type?: AlertType;
  code?: number;
  dismissable?: boolean;
  duration?: number;
}

/**
 * Alert
 */
export interface Alert {
  id: string;
  timestamp: Date;
  message: string;
  params: object;
  type: AlertType;
  code: number;
  dismissable?: boolean;
  duration?: number;
  dismissed?: boolean;
}

/**
 * Default Alert
 */
export class DefaultAlert implements Alert {
  /**
   * Members
   */
  public id: string;
  public timestamp: Date;
  public message: string;
  public params: object;
  public type: AlertType;
  public code: number;
  public dismissable: boolean;
  public duration: number;
  public dismissed: boolean;

  /**
   * Constructor
   *
   * @param options: AlertOptions
   */
  public constructor(options: AlertOptions) {
    this.id = uuid();
    this.timestamp = new Date();
    this.message = options.message || 'Unknown Error';
    this.params = options.params || {};
    this.type = options.type || AlertType.ERROR;
    this.code = options.code || 400;
    this.dismissable = options.dismissable || true;
    this.duration = options.duration || 0;
    this.dismissed = false;
  }
}
