import { Component, Input } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { AppSharedFacade, NodeSharedFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-listnav',
  templateUrl: './listnav.component.html',
  styleUrls: ['./listnav.component.scss']
})
export class ListnavComponent {
  /**
   * Inputs
   */
  @Input() public node: FlatModels.NodeEntityModels.NodeEntityType;
  @Input() public page: FlatModels.PageEntityModels.PageEntityType;
  @Input() public showSubjects: boolean | undefined;
  @Input() public mainHeading: boolean;
  @Input() public locked: boolean;

  /**
   * Constructor
   *
   * @param appSharedFacade: AppSharedFacade
   * @param nodeSharedFacade: NodeSharedFacade
   */
  constructor(public appSharedFacade: AppSharedFacade,
              public nodeSharedFacade: NodeSharedFacade) {
  }
}
