import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { StainEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * Stain Entity Token
 */
export const STAIN_ENTITY_TOKEN = new StateToken<StainEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.STAIN);

/**
 * Stain Entity State Model
 */
export type StainEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.StainEntityModels.Stain>;

/**
 * Stain Entity State
 */
@State<StainEntityStateModel>({
  name: STAIN_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class StainEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: StainEntityStateModel
   */
  @Selector([StainEntityState])
  public static typedEntities$(state: StainEntityStateModel): { [id: string]: FlatModels.StainEntityModels.Stain } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.StainEntityModels.Stain }
   */
  @Selector([StainEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.StainEntityModels.Stain }): FlatModels.StainEntityModels.Stain[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.StainEntityModels.Stain }
   */
  @Selector([StainEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.StainEntityModels.Stain }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.StainEntityModels.Stain[]
   */
  @Selector([StainEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.StainEntityModels.Stain[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<StainEntityStateModel>
   * @param patchState: StateContext<StainEntityStateModel>
   * @param entityQueryResult: StainEntityActions.GetEntitySuccess
   */
  @Action(StainEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<StainEntityStateModel>,
                          {entityQueryResult}: StainEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<StainEntityStateModel>
   * @param patchState: StateContext<StainEntityStateModel>
   * @param entityQueryResults: StainEntityActions.GetEntitiesSuccess
   */
  @Action(StainEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<StainEntityStateModel>,
                            {entityQueryResults}: StainEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<StainEntityStateModel>
   * @param patchState: StateContext<StainEntityStateModel>
   * @param entity: StainEntityActions.AddEntity
   */
  @Action(StainEntityActions.AddEntity)
  @Undoable(StainEntityActions.AddEntity, STAIN_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<StainEntityStateModel>,
                   {entity}: StainEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<StainEntityStateModel>
   * @param patchState: StateContext<StainEntityStateModel>
   * @param entity: StainEntityActions.UpdateEntity
   */
  @Action(StainEntityActions.UpdateEntity)
  @Undoable(StainEntityActions.UpdateEntity, STAIN_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<StainEntityStateModel>,
                      {entity}: StainEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<StainEntityStateModel>
   * @param patchState: StateContext<StainEntityStateModel>
   * @param entity: StainEntityActions.RemoveEntity
   */
  @Action(StainEntityActions.RemoveEntity)
  @Undoable(StainEntityActions.RemoveEntity, STAIN_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<StainEntityStateModel>,
                      {entity}: StainEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
