import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Can Component Deactivate
 */
export interface CanComponentDeactivate {
  canDeactivate: (url: string) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateViewerGuard implements CanDeactivate<CanComponentDeactivate> {
  /**
   * Can Deactivate
   *
   * @param component: CanComponentDeactivate
   * @param currentRoute: ActivatedRouteSnapshot
   * @param currentState: RouterStateSnapshot
   * @param nextState: RouterStateSnapshot
   */
  canDeactivate(component: CanComponentDeactivate,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return component?.canDeactivate ? component.canDeactivate(nextState.url) : true;
  }
}
