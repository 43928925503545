import { Component } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { BaseQuestionComponent } from '../base-question.component';

/**
 * TODO's:
 * - Matomo Track will only trigger when we check the form -> moved to state
 */
@Component({
  selector: 'medsurf-quiz-indication-question',
  templateUrl: './indication-question.component.html',
  styleUrls: ['./indication-question.component.scss']
})
export class IndicationQuestionComponent extends BaseQuestionComponent<FlatModels.QuestionEntityModels.IndicationQuestion, FlatModels.TrainingViewerModels.IndicationQuestionResult> {
  /**
   * Is Correct
   */
  public isCorrect(): boolean {
    if (this.trainingResult.answer === null) {
      return false;
    }
    return this.question.solution.toString() === this.trainingResult.answer.toString();
  }

  /**
   * Pass Required
   */
  public passRequired(): boolean {
    if (this.question.required) {
      return Number.isInteger(this.trainingResult.answer);
    }
    return true;
  }

  /**
   * On Click
   *
   * @param value: number
   */
  public onClick(value: number) {
    this.trainingResult.answer = value;
    this.trainingViewerFacade.requestUpdateSlideResult(this.trainingResult);
  }
}
