import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { GetNodeRoute, GetNodeRouteSuccess } from '@medsurf/actions';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(
    protected router: Router,
    protected store: Store,
    protected actions$: Actions
  ) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {

    const id = next.paramMap.get('id');
    return new Promise((resolve, reject) => {
      this.actions$.pipe(ofActionDispatched(GetNodeRouteSuccess)).subscribe(({path}) => {
        this.router.navigateByUrl(path);
        resolve(true);
      });
      this.store.dispatch(new GetNodeRoute(id));
    });
  }
}
