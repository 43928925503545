// <editor-fold desc="Action Types">
export namespace SharedHistory {
  /**
   * History action types
   */
  export enum ActionTypes {
    /**
     * Store
     */
    Init = '[STORE | SHAREDHISTORY] Init',
    Overwrite = '[STORE | SHAREDHISTORY] Overwrite',
    Changed = '[STORE | SHAREDHISTORY] Changed',
    Undo = '[STORE | SHAREDHISTORY] Undo',
    Redo = '[STORE | SHAREDHISTORY] Redo'
    /**
     * Request
     */
  }


// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

  /**
   * History changed
   */
   export class Init {
    public static readonly type = ActionTypes.Init;

    public constructor(public snapshot: any) {
    }
  }

  /**
   * History changed
   */
  export class Overwrite {
    public static readonly type = ActionTypes.Overwrite;

    public constructor(public snapshot: any) {
    }
  }


  /**
   * History changed
   */
  export class Changed {
    public static readonly type = ActionTypes.Changed;

    public constructor(public snapshot: any) {
    }
  }

  /**
   * Undo
   */
  export class Undo {
    public static readonly type = ActionTypes.Undo;

    public constructor(public id: string) {
    }
  }

  /**
   * Redo
   */
  export class Redo {
    public static readonly type = ActionTypes.Redo;

    public constructor(public id: string) {
    }
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
