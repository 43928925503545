import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FooterLinkViewerModels } from '@medsurf/flat-models';
import { AppSharedFacade, NodeSharedFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-elearnings',
  templateUrl: './elearnings.component.html',
  styleUrls: ['./elearnings.component.scss']
})
export class ElearningsComponent {
  /**
   * TODO move to route data
   * Members
   */
  public footerLinks: FooterLinkViewerModels.FooterLink[] = [
    {title: 'DATENSCHUTZERKLÄRUNG', url: 'privacy'},
    {title: 'IMPRESSUM', url: 'impressum'}
  ];

  /**
   * Constructor
   *
   * @param appSharedFacade: AppSharedFacade
   * @param nodeSharedFacade: NodeSharedFacade
   * @param titleService: Title
   */
  constructor(public appSharedFacade: AppSharedFacade,
              public nodeSharedFacade: NodeSharedFacade,
              public titleService: Title) {
  }

  /**
   * Set Title
   *
   * @param title: string
   */
  public setTitle(title: string) {
    this.titleService.setTitle(title);
    return true;
  }
}
