'use strict';
import Paper from 'paper';
import { Point as OSDPoint, TiledImage, Viewer } from 'openseadragon';

export class Overlay {
  /**
   * Members
   */
  private _containerWidth: number;
  private _containerHeight: number;
  private readonly _canvasDiv: HTMLDivElement;
  private readonly _canvas: HTMLCanvasElement;
  private _localPaper: Paper.PaperScope;
  private _mainLayer: Paper.Layer;

  /**
   * Constructor
   *
   * @param _viewer: Viewer
   */
  constructor(private _viewer: Viewer) {
    this._containerWidth = 0;
    this._containerHeight = 0;

    this._canvasDiv = document.createElement('div');
    this._canvasDiv.style.position = 'absolute';
    this._canvasDiv.style.left = '0';
    this._canvasDiv.style.top = '0';
    this._canvasDiv.style.width = '100%';
    this._canvasDiv.style.height = '100%';
    this._viewer.canvas.appendChild(this._canvasDiv);

    this._canvas = document.createElement('canvas');
    this._canvas.setAttribute('id', 'virtualm-overlay');
    this._canvasDiv.appendChild(this._canvas);
    this.resize();

    this._localPaper = new Paper.PaperScope();

    this._localPaper.setup(this._canvas);

    this._mainLayer = this._localPaper.project.activeLayer;

    this._localPaper.view.autoUpdate = false;

    const handler = () => {
      this.resize();
      this.resizeCanvas();
    };

    this._viewer.addHandler('update-viewport', handler);
    this._viewer.addHandler('open', handler);

    this._viewer.addHandler('canvas-drag', () => this._canvasDiv.style.cursor = 'move');
    this._viewer.addHandler('canvas-drag-end', () => this._canvasDiv.style.cursor = 'default');

    this.resize();
  }

  /**
   * Resize
   */
  public resize() {
    if (this._viewer.container) {
      if (this._containerWidth !== this._viewer.container.clientWidth) {
        this._containerWidth = this._viewer.container.clientWidth;
        this._canvasDiv.setAttribute('width', this._containerWidth.toString());
        this._canvas.setAttribute('width', this._containerWidth.toString());
      }
      if (this._containerHeight !== this._viewer.container.clientHeight) {
        this._containerHeight = this._viewer.container.clientHeight;
        this._canvasDiv.setAttribute('height', this._containerHeight.toString());
        this._canvas.setAttribute('height', this._containerHeight.toString());
      }
    }
  }

  /**
   * Resize Canvas
   */
  public resizeCanvas() {
    this._canvasDiv.setAttribute('width', this._containerWidth.toString());
    this._canvas.setAttribute('width', this._containerWidth.toString());
    this._canvasDiv.setAttribute('height', this._containerHeight.toString());
    this._canvas.setAttribute('height', this._containerHeight.toString());

    this._localPaper.view.viewSize = new Paper.Size(this._containerWidth, this._containerHeight);
    const viewportZoom: number = this._viewer.viewport.getZoom(true);
    const image1: TiledImage = this._viewer.world.getItemAt(0);
    this._localPaper.view.zoom = image1 ? image1.viewportToImageZoom(viewportZoom) : 1;
    const image = this._viewer.world._items.find((item) => item.getOpacity() > 0.1);
    this._localPaper.view.zoom = image ? image.viewportToImageZoom(viewportZoom) : 1;
    const center: OSDPoint = image?.viewportToImageCoordinates(this._viewer.viewport.getCenter(true));
    this._localPaper.view.center = new Paper.Point(center?.x, center?.y);

    this._localPaper.view.update();
  }

  /**
   * Getter Canvas
   */
  public get canvas(): HTMLCanvasElement {
    return this._canvas;
  }

  /**
   * Getter localPaper
   */
  public get localPaper(): Paper.PaperScope {
    return this._localPaper;
  }

  /**
   * Getter mainLayer
   */
  public get mainLayer(): Paper.Layer {
    return this._mainLayer;
  }
}
