import { Injectable } from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MessageService } from '@medsurf/services';
import { SlideNode } from '@medsurf/models';
import { GetSuggesstions, GetSuggestionsSuccess, ResetSearch, Search, SearchSuccess, SetSearchString,
  GetSuggesstionsRequest, SearchRequest } from '@medsurf/actions';
import { AuthState } from './auth.state';

/**
 * Search state model
 */
export class SearchStateModel {
  searchString: string;
  suggestions: string[];
  resultSlides: SlideNode[];
  resultMarkers: SlideNode[];
}

/**
 * Search state
 */
@State<SearchStateModel>({
  name: 'search',
  defaults: {
    searchString: '',
    suggestions: null,
    resultSlides: null,
    resultMarkers: null
  }
})
@Injectable()
export class SearchState {
  /**
   * Constructor
   * @param store: Store
   * @param messageService: MessageService
   */
  constructor(public store: Store,
              public messageService: MessageService) {
  }

  /**
   * Selector searchString
   * @param state: SearchStateModel
   */
  @Selector()
  public static searchString(state: SearchStateModel): string {
    return state.searchString;
  }

  /**
   * Selector suggestions
   * @param state: SearchStateModel
   */
  @Selector()
  public static suggestions(state: SearchStateModel): string[] {
    return state.suggestions;
  }

  /**
   * Selector resultSlides
   * @param state: SearchStateModel
   */
  @Selector()
  public static resultSlides(state: SearchStateModel): SlideNode[] {
    return state.resultSlides;
  }

  /**
   * Selector resultMarkers
   * @param state: SearchStateModel
   */
  @Selector()
  public static resultMarkers(state: SearchStateModel): SlideNode[] {
    return state.resultMarkers;
  }

  /**
   * set search string
   * @param setState: StateContext<SearchStateModel>
   * @param searchString: String
   */
  @Action(SetSearchString)
  public setSearchString({setState}: StateContext<SearchStateModel>, {searchString}: SetSearchString): void {
    setState(
      patch({
        searchString
      }));
  }

  /**
   * resetSearch
   * @param setState: StateContext<SearchStateModel>
   */
  @Action(ResetSearch)
  public resetSearch({setState}: StateContext<SearchStateModel>): void {
    setState(
      patch({
        searchString: '',
        suggestions: null,
        resultSlides: null,
        resultMarkers: null
      }));
  }

  /**
   * Search
   * @param getState: StateContext<SearchStateModel>
   * @param dispatch: StateContext<SearchStateModel>
   */
  @Action(Search)
  public search({getState, dispatch}: StateContext<SearchStateModel>) {
    if (getState().searchString.length > 0) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new SearchRequest(getState().searchString));
    } else {
      dispatch(new SearchSuccess(null, null));
    }
  }

  /**
   * Search Sucess
   * @param setState: StateContext<SearchStateModel>
   * @param resultSlides: SlideNode[]
   * @param resultMarkers: SlideNode[]
   */
  @Action(SearchSuccess)
  public searchSuccess({setState}: StateContext<SearchStateModel>, {resultSlides, resultMarkers}: SearchSuccess): void {
    setState(
      patch({
        resultSlides,
        resultMarkers
      }));
  }

  /**
   * Get Suggestions
   * @param getState: StateContext<SearchStateModel>
   */
  @Action(GetSuggesstions)
  public getSuggestions({getState}: StateContext<SearchStateModel>) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetSuggesstionsRequest(getState().searchString));
  }

  /**
   * Get Suggestions Success
   * @param setState: StateContext<SearchStateModel>
   * @param suggestions: string[]
   */
  @Action(GetSuggestionsSuccess)
  public getSuggestionsSuccess({setState}: StateContext<SearchStateModel>, {suggestions}: GetSuggestionsSuccess): void {
    setState(
      patch({
        suggestions,
      }));
  }
}
