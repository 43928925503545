<div class="mdsf-slide h-100"
     *ngIf="media">
  <div class="slide-image-container slide-audio-container h-100 w-100 p-3">
    <ng-container *ngIf="!errorState">
      <audio [src]="mediaControlService.getUrl(media)"
             id="audio-{{ media.id }}"
             (click)="localToggle()"
             (timeupdate)="updateProgress()"
             preload="auto"
             #audioElement>
    </audio>
      <div class="audio-controls-container">
        <div class="button"
             (click)="localToggle()">
          <span class="fa fa-fw fa-play"
                *ngIf="!isPlaying"></span>
          <span class="fa fa-fw fa-pause"
                *ngIf="isPlaying"></span>
        </div>
        <div class="mdsf-progress"
             (mousedown)="localSeekBarMousedown($event)">
          <div class="mdsf-progress-wrap"
               #progressWrap>
            <div class="mdsf-progress-bar"
                [style.transform]="'scaleX(' + progress + ')'">
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
