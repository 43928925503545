import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BaseGuardChainFacade } from '@medsurf/flat-facades';

@Injectable({
  providedIn: 'root'
})
export class RedirectControlGuard implements CanActivate {
  /**
   * Constructor
   *
   * @param router: Router
   * @param baseGuardChainFacade: BaseGuardChainFacade
   */
  constructor(public router: Router,
              public baseGuardChainFacade: BaseGuardChainFacade) {
  }

  /**
   * Can Activate
   *
   * @param next: ActivatedRouteSnapshot
   */
  public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    const id = next.paramMap.get('id');

    /* TODO
    return new Promise((resolve) => {
      this.actions$.pipe(ofActionDispatched(GetNodeRouteSuccess)).subscribe(({path}) => {
        this.router.navigateByUrl(path);
        resolve(true);
      });
      this.store.dispatch(new GetNodeRoute(id));
    });
    */

    // Return TODO remove
    return Promise.resolve(true)
  }
}
