<!-- Suggestions -->
<ng-template #suggestion let-r="result">
  <a>
    <ngb-highlight [innerHTML]="r" class="suggestion"></ngb-highlight>
  </a>
</ng-template>

<!-- Spinner -->
<ng-template #spinner>
  <medsurf-spinner-spinner class="text-black"></medsurf-spinner-spinner>
</ng-template>

<!-- No Results -->
<ng-template #noResults>
  <h3 class="grid-header">{{ 'no-results' | translate}}</h3>
</ng-template>

<!-- Results -->
<ng-template #results>
  <ng-container *ngIf="((searchControlFacade.markerSearchResultNodes$ | async) || []).length > 0 || ((searchControlFacade.slideSearchResultNodes$ | async) || []).length > 0; else noResults">
    <ng-container *ngTemplateOutlet="resultGrid;context:{
          results: (searchControlFacade.markerSearchResultNodes$ | async),
          name: 'markers'}">
    </ng-container>
    <ng-container *ngTemplateOutlet="resultGrid;context:{
          results: (searchControlFacade.slideSearchResultNodes$ | async),
          name: 'slides'}">
    </ng-container>
  </ng-container>
</ng-template>

<!-- Results Grid -->
<ng-template #resultGrid let-results="results" let-name="name">
  <ng-container *ngIf="results.length > 0">
    <h3 class="grid-header">{{name | translate}}</h3>
    <medsurf-modal-grid [slides]="results"
                        class="thumbnail-container scrollable">
    </medsurf-modal-grid>
  </ng-container>
</ng-template>

<!-- Content -->
<div *ngIf="modalControlService.showModal"
     class="mdsf-modal"
     id="modal">
  <nav [class.fixed]="modalControlService.showModal"
       class="mdsf-modal-navbar"
       id="mdsfModalNavbar">
    <form (ngSubmit)="search()">
      <input #searchInput
             (selectItem)="onSelect($event)"
             [(ngModel)]="needle"
             [focusFirst]="false"
             [ngbTypeahead]="searchTypeAhead"
             [resultTemplate]="suggestion"
             [inputFormatter]="formatter"
             autocomplete="off"
             class="search typeahead"
             id="search"
             name="search"
             placeholder="{{ 'search_after' | translate }}"
             title="search"
             type="search">
    </form>
    <button (click)="modalControlService.closeModal()"
          class="icon icon_close closebtn light"
          title="{{ 'close_modal' | translate }}">
    </button>
    <button class="btn closebtn"
            (click)="modalControlService.closeModal()"
            title="{{ 'close_modal' | translate }}">
      <i class="fa fa-fw fa-close"></i>
    </button>
  </nav>

  <section class="mdsf-modal-content">
    <ng-container *ngIf="!isSearching; else spinner">
      <div *ngIf="(searchControlFacade.slideSearchResultNodes$ | async) === null && (searchControlFacade.markerSearchResultNodes$ | async) === null; else results"
           class="mdsf-fast-access">
      </div>
    </ng-container>
  </section>
</div>
