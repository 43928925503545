import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BaseGuardChainFacade } from '@medsurf/flat-facades';

@Injectable({
  providedIn: 'root'
})
export class OrderedChainControlGuard implements CanActivate {
  /**
   * Constructor
   *
   * @param router: Router
   * @param baseGuardChainFacade: BaseGuardChainFacade
   */
  constructor(public router: Router,
              public baseGuardChainFacade: BaseGuardChainFacade) {}

  /**
   * Can Activate
   *
   * @param next: ActivatedRouteSnapshot
   */
  public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    // Execute All Guards In A Chain
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<boolean>( async (resolve) => {
      if (!next.data) {
        resolve(true);
        return;
      }

      // Get Guards
      const orderedGuards = next.data['orderedGuards'];

      // Check for Guards
      if (!orderedGuards || !orderedGuards.length) {
        resolve(true);
        return;
      }

      // Execute Guards
      resolve(await this.executeGuards(orderedGuards, next));
    });
  }

  /**
   * Execute the guards sent in the route data
   *
   * @param orderedGuards: any
   * @param next: ActivatedRouteSnapshot
   * @param guardIndex: number
   */
  private executeGuards(orderedGuards: any, next: ActivatedRouteSnapshot, guardIndex = 0): Promise<boolean> {
    // Chain Guards
    return this.activateGuard(orderedGuards[guardIndex], next)
      .then(() => {
        if (guardIndex < orderedGuards.length - 1) {
          return this.executeGuards(orderedGuards, next, guardIndex + 1);
        } else {
          return Promise.resolve(true);
        }
      })
      .catch(() => {
        return Promise.reject(false);
      });
  }

  /**
   * Create an instance of the guard and fire canActivate method returning a promise
   *
   * @param guardClass: any
   * @param next: ActivatedRouteSnapshot
   */
  private activateGuard(guardClass: any, next: ActivatedRouteSnapshot): Promise<boolean> {
    const guard = new guardClass(this.router, this.baseGuardChainFacade);
    return guard.canActivate(next);
  }
}
