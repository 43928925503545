import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MessageService } from '@medsurf/services';
import { User } from '@medsurf/models';
import { CreateNewUser, DeleteUser, GetUserById, GetUsers, GetUsersSuccess, GetUserSuccess, UpdateUser, AddUser, ResetPassword,
  SetNewPassword, ValidateResetToken, DeleteUserRequest, GetUserByIdRequest, GetUsersRequest, UpdateUserRequest, AddUserRequest,
  ResetPasswordRequest, SetNewPasswordRequest, ValidateResetTokenRequest } from '@medsurf/actions';
import { AuthState } from './auth.state';

/**
 * User state model
 */

export interface UserStateModel {
  user: User;
  users: User[];
}

/**
 * Index State
 */
@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
    users: [],
  }
})
@Injectable()
export class UserState {
  /**
   * Constructor
   * @param store: Store
   * @param messageService: MessageService
   */
  public constructor(protected store: Store,
                     protected messageService: MessageService) {
  }

  /**
   * Selector users
   * @param state: UserStateModel
   */
  @Selector()
  public static users(state: UserStateModel): User[] {
    return state.users;
  }

  /**
   * Selector user
   * @param state: UserStateModel
   */
  @Selector()
  public static user(state: UserStateModel): User {
    return state.user;
  }

  /**
   * Get get users
   */
  @Action(GetUsers)
  public getUsers({}: StateContext<UserStateModel>) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetUsersRequest());
  }

  /**
   * Get users success
   * @param getState: StateContext<UserStateModel>
   * @param setState: StateContext<UserStateModel>
   * @param users: User[]
   */
  @Action(GetUsersSuccess)
  public getUsersSuccess({getState, setState}: StateContext<UserStateModel>, {users}: GetUsersSuccess): void {
    setState(
      patch({
        users,
      })
    );
  }

  /**
   * Get user by id
   * @param id: string
   */
  @Action(GetUserById)
  public getUserById({}: StateContext<UserStateModel>, {id}: GetUserById) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetUserByIdRequest(id));
  }

  /**
   * Get user success
   * @param getState: StateContext<UserStateModel>
   * @param setState: StateContext<UserStateModel>
   * @param user: User
   */
  @Action(GetUserSuccess)
  public getUserSuccess({getState, setState}: StateContext<UserStateModel>, {user}: GetUserSuccess): void {
    setState(
      patch({
        user,
      })
    );
  }

  /**
   * Create new User
   * @param setState: StateContext<PageStateModel>
   */
  @Action(CreateNewUser)
  public createNewUser({setState}: StateContext<UserStateModel>): void {
    setState(
      patch({
        user: {} as User,
      })
    );
  }

  /**
   * Save user
   * @param setState: StateContext<PageStateModel>
   */
  @Action(UpdateUser)
  public userUser({dispatch, getState}: StateContext<UserStateModel>) {
    const state = getState();
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new UpdateUserRequest(state.user));
  }

  /**
   * Save new user
   * @param setState: StateContext<PageStateModel>
   */
   @Action(AddUser)
   public saveNewUser({dispatch, getState}: StateContext<UserStateModel>) {
     const state = getState();
     return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new AddUserRequest(state.user));
   }

  /**
   * Delete user
   * @param setState: StateContext<PageStateModel>
   * @param dispatch: StateContext<PageStateModel>
   */
  @Action(DeleteUser)
  public async deleteUser({dispatch, getState}: StateContext<UserStateModel>) {
    const state = getState();
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new DeleteUserRequest(state.user.id));
  }

  /**
   * Reset password
   * @param state: StateContext<AuthStateModel>
   * @param email: string
   */
  @Action(ResetPassword)
  public resetPassword({getState}: StateContext<UserStateModel>, {email}: ResetPassword) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new ResetPasswordRequest(email));
  }

  /**
   * Set new password
   * @param userId: string
   * @param token: string
   * @param newPassword: string
   * @param newPasswordConfirm: string
   */
  @Action(SetNewPassword)
  public setNewPassword({getState}: StateContext<UserStateModel>, {userId, token, password}: SetNewPassword) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new SetNewPasswordRequest(userId, token, password));
  }

  /**
   * Set new password
   * @param userId: string
   * @param token: string
   * @param newPassword: string
   * @param newPasswordConfirm: string
   */
  @Action(ValidateResetToken)
  public validateResetToken({getState}: StateContext<UserStateModel>, {id, token}: ValidateResetToken) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new ValidateResetTokenRequest(id, token));
  }
}
