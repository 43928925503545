'use strict';
import { v4 as uuid } from 'uuid';
import paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { MarkerElement } from '../markerElement';

/**
 * Keymap Selftest
 */
export class KeymapSelftest extends MarkerElement<ImageViewerModels.KeymapSelftestModel, undefined> {
  /**
   * Members
   */
  private _labels: any[];
  private _selection: any;
  private _cell: any;
  private _index: any;
  private _label: paper.PointText;
  private _rectangle: paper.Path.Rectangle;

  /**
   * Constructor
   *
   * @param model
   * @param _format
   * @param localPaper
   * @param _mainLayer
   * @param container
   * @param _key
   * @param _imageScale
   * @param _imageOffset
   */
  public constructor(model,
                     private _format,
                     public localPaper,
                     private _mainLayer,
                     public container,
                     private _key,
                     public _imageScale = 1,
                     public _imageOffset) {
    super(model, undefined);
    this._labels = [];
    this._selection = null;
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    if (!this._element) {
      this._element = new this.localPaper.Group();
      this._mainLayer.addChild(this._element);
    }
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    this._element._children = [];
    if (!this._model._model.label) {
      return;
    }

    if (!this._model._model.annotation.selfTest) {
      this._model._model.annotation.selfTest = {
        x: this._model._model.source.position.x,
        y: this._model._model.source.position.y,
      };
    }

    if (!this._model._model.annotation.selfTest.x || this._model._model.annotation.selfTest.x === 0 || this._model._model.annotation.selfTest.x === null) {
      this._model._model.annotation.selfTest.x = this._model._model.source.position.x;
    }

    if (!this._model._model.annotation.selfTest.y || this._model._model.annotation.selfTest.y === 0 || this._model._model.annotation.selfTest.y === null) {
      this._model._model.annotation.selfTest.y = this._model._model.source.position.y;
    }

    const source = this.container.getAbsoluteCoords(
      this._model._model.annotation.selfTest.x,
      this._model._model.annotation.selfTest.y,
      this._imageOffset,
      this._imageScale
    );

    const rowHeight = [], columnWidth = [];
    columnWidth[-1] = 0;
    rowHeight[-1] = 0;

    const digitModel = this.getDigitModel();
    const digitsCount = this.countIndexDigits();

    const column = this._model._model.columns[0];
    const label = column[0];

    const rowId = column.labels.indexOf(label),
      indexContent = ' '.repeat(digitsCount - this._key.index.toString().length) + this._key.index;
    rowHeight[rowId] = rowHeight[rowId] ? rowHeight[rowId] : 0;

    this._cell = new this.localPaper.Group();
    this._index = new this.localPaper.Group();

    for (let position = 0; position < digitsCount; position++) {
      const digit = this.drawDigit(source, position, digitModel, indexContent);
      this._index.addChild(digit);
    }

    this._label = new paper.PointText(new paper.Point(
      source.x + (this._model._model.keymapStyle.indexWidth / this._imageScale) + (digitsCount - 1) * digitModel.width,
      source.y)
    );

    this._label.fontFamily = this._format.fontFamily;
    this._label.fontWeight = this._format.fontWeight;
    this._label.fontSize = (this._model._model.keymapStyle.fontSize || this._format.fontSize) / this._imageScale;
    this._label.fillColor = this._model._model.keymapStyle.color || this._format.color;
    this._label.content = this._key.text;
    this._label['keymap'] = this;
    this._index['keymap'] = this;

    this._cell.addChild(this._index);
    this._cell.addChild(this._label);

    this._element.addChild(this._cell);

    columnWidth[0] = Math.max(this._cell.bounds.width, columnWidth[0]);
    rowHeight[rowId] = Math.max(this._cell.bounds.height, rowHeight[rowId]);

    this.drawRectangle(columnWidth, rowHeight);
    this._element.reverseChildren();
  }

  /**
   * Draw Digit
   *
   * @param source
   * @param position
   * @param digitModel
   * @param indexContent
   */
  public drawDigit(source,
                   position,
                   digitModel,
                   indexContent) {
    const digit = new paper.PointText(new paper.Point(source.x + position * digitModel.width, source.y));
    digit.fontFamily = this._format.fontFamily;
    digit.fontWeight = this._format.fontWeight;
    digit.fontSize = (this._model._model.keymapStyle.fontSize || this._format.fontSize) / this._imageScale;
    digit.fillColor = this._model._model.keymapStyle.color || this._format.color;
    digit.content = indexContent[position];
    digit.translate(new paper.Point((digitModel.width - digit.bounds.width) / 2, 0));
    return digit;
  }

  /**
   * Draw Rectangle
   *
   * @param columnWidth
   * @param rowHeight
   */
  public drawRectangle(columnWidth,
                       rowHeight) {
    const {width, height} = this._element.bounds;

    const source = this.container.getAbsoluteCoords(
      this._model._model.annotation.selfTest.x,
      this._model._model.annotation.selfTest.y,
      this._imageOffset,
      this._imageScale);

    source.x = source.x - (this._model._model.keymapStyle.columnDistance / this._imageScale / 2);
    source.y = source.y - (this._model._model.keymapStyle.rowDistance / this._imageScale / 2) - <number>this._label.fontSize;

    const rectangle = new paper.Rectangle(
      new paper.Point(source.x, source.y),
      new paper.Size(
        width + (this._model._model.keymapStyle.columnDistance / this._imageScale),
        height + (this._model._model.keymapStyle.rowDistance / this._imageScale)
      )
    );
    this._rectangle = new paper.Path.Rectangle(rectangle);

    this._rectangle.fillColor = this._model._model.keymapStyle.background ? this._format.border.fillColor : undefined;
    if (this._rectangle.fillColor) {
      this._rectangle.fillColor.alpha = this._model._model.keymapStyle.opacity;
    }

    this._rectangle.strokeColor = this._format.border.strokeColor;
    this._rectangle.strokeWidth = this._model._model.keymapStyle.border ? this._format.border.strokeWidth : 0;

    if (this._isSelected) {
      this._rectangle.fillColor = this._format.selection.color;
      this._rectangle.fillColor.alpha = this._format.selection.opacity;
      this._rectangle.strokeColor = this._format.selection.color;
      this._rectangle.strokeWidth = this._format.selection.strokeWidth;
    }
    this._rectangle['keymap'] = this;

    this._element.addChild(this._rectangle);
  }

  /**
   * Get Digit Model
   */
  public getDigitModel() {
    const index = new paper.PointText(new paper.Point(0, 0));
    index.fontFamily = this._format.fontFamily;
    index.fontWeight = this._format.fontWeight;
    index.fontSize = (this._model._model.keymapStyle.fontSize || this._format.fontSize) / this._imageScale;
    index.content = '0';
    index.remove();
    return {width: index.bounds.width, height: index.bounds.height};
  }

  /**
   * Count Index Digits
   */
  public countIndexDigits() {
    let max = 0;
    this._model._model.columns.forEach(currentColumn => {
      let columnMax = 0;
      currentColumn.labels.forEach(currentCell => {
        const index = Number.parseFloat(currentCell.index);
        // tslint:disable-next-line:no-bitwise
        if (index >>> 0 === Number.parseFloat(currentCell.index)) {
          columnMax = Math.max(Math.log10(index === 0 ? 1 : index), columnMax);
        } else {
          columnMax = Math.max(currentCell.index.length - 1, columnMax);
        }
      });
      max = Math.max(columnMax, max);
    });

    return Math.floor(max) + 1;
  }

  /**
   * Arrange
   */
  public arrange() {
    this.draw();
  }

  /**
   * Clean up
   */
  public cleanup() {
    this.element.removeChildren();
    super.cleanUp();
  }

  /**
   * Get Default Label
   * When no label is present in the keymap model, the image can not be drawn
   *
   * @returns {[null]}
   */
  public getDefaultLabel() {
    return [{
      'id': uuid(),
      'labels': [{
        'index': 1,
        'text': 'Legende 1',
        'pivot': 'bottomCenter'
      }]
    }];
  }
}
