<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<div #sidebarContainer class="sidebar-container" 
     *ngIf="(menuViewerFacade.showFullscreen$ | async) === false">

  <!-- scrollTop: scroll container switches from #sidebarContainer to ::host under certain condition, so handle both cases (alternatively change .scss) -->
  <ng-container *ngIf="(slideViewerFacade.currentSelectedSlidePage$ | async | scrollTop: sidebarContainer:elRef.nativeElement) as slide; else spinner">
    <!-- Title -->
    <h1 class="sidebar-header"
        *ngIf="slide.header">
      {{ slide.header }}
    </h1>

    <!-- Text -->
    <medsurf-text *ngIf="slide.body"
                  [text]="slide.body"
                  [isSlideDescription]="true"
                  class="mb-4">
    </medsurf-text>

    <!-- Interactive Area / Poi -->
    <ng-container *ngIf="(slideViewerFacade.currentSelectedSlideImage$ | async) as image">
      <medsurf-viewer-interactive-area-list></medsurf-viewer-interactive-area-list>
      <medsurf-viewer-poi-list></medsurf-viewer-poi-list>
    </ng-container>

    <!-- Question -->
    <medsurf-questions></medsurf-questions>

    <!-- Copyright -->
    <medsurf-copyright></medsurf-copyright>
  </ng-container>
</div>
