import { Injectable } from '@angular/core';
import { Keymap, KeymapColumn, KeymapLabel } from '@medsurf/models';

@Injectable({
  providedIn: 'root'
})
export class KeymapService {

  constructor() { }

  addColumn(keymap: Keymap) {
    const column: KeymapColumn = {
        order: keymap.columns.length,
        labels: []
      };
    keymap.columns.push(column);
    keymap.dirty = true;
    return column;
  }

  removeColumn(keymap: Keymap) {
    keymap.dirty = true;
    return keymap.columns.pop();
  }

  addKey(columnId, keymap: Keymap) {
    const index = this.keyCount(keymap) + 1;
    const order = this.order(columnId, keymap);
    const key: KeymapLabel = {
      order: order,
      index: '' + index,
      text: 'Legende ' + index,
      pivot: 'bottomCenter',
    };
    keymap.columns[columnId].labels.push(key);
    keymap.dirty = true;
    return key;
  }

  removeKey(columnId, keyId, keymap: Keymap) {
    keymap.dirty = true;
    return keymap.columns[columnId].labels.splice(keyId, 1);
  }

  order(columnId, keymap: Keymap) {
    return keymap.columns[columnId].labels.length;
  }

  keyCount(keymap: Keymap) {
    return keymap.columns.reduce((acc, element) => acc + element.labels.length, 0);
  }
}
