/**
 * Author Display Mode
 */
export enum AuthorDisplayMode {
  FULL = 'FULL',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE'
}

/**
 * Author Display Menu
 */
export enum AuthorDisplayMenu {
  SLIDE,
  LAYER,
  IMAGE,
  INTERACTIVE_MAPPING,
  POI_MAPPING,
  TEXT_MAPPING,
  LAYER_GROUP,
  POSITION_POINT,
  LINE,
  FILL_COLLECTION,
  LEGEND_COLLECTION,
  ARROW_PRIMITIVE,
  BACKGROUND_RECTANGLE_PRIMITIVE,
  ELLIPSE_PRIMITIVE,
  RECTANGLE_PRIMITIVE,
  TEXT_PRIMITIVE,
  LEGEND_COLUMN,
  LEGEND_ROW
}
