import Paper from 'paper';

export class Circle {
  /**
   * Members
   */
  private _element: any;

  /**
   * Constructor
   *
   * @param _format
   * @param _target
   * @param _marker
   */
  public constructor(private _format,
                     private _target,
                     private _marker) {
    this._element = null;
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this.draw();
  }

  /**
   * Draw
   */
  draw() {
    const factor = this._marker && this._marker._model.style && this._marker._model.style.strokeWidth ?
      this._marker._model.style.strokeWidth / this._marker._imageScale :
      this._format.strokeWidth / this._marker._imageScale;
    this._element = new Paper.Path.Circle(new Paper.Point(this._target.x, this._target.y), factor * this._format.circleRadius);
    this._element.fillColor = this._format.strokeColor;
    this._element.fillColor.alpha = 0.1;
    this._element.strokeColor = (this._marker && this._marker._model.style && this._marker._model.style.color) ?
      this._marker._model.style.color :
      this._format.strokeColor;
    this._element.strokeWidth = factor * this._format.strokeWidth;
  }

  /**
   * Getter element
   */
  public get element() {
    return this._element;
  }

  /**
   * Set Scale
   */
  public setScale() {
    const factor = (this._marker && this._marker._model.source && this._marker._model.source.zoom ?
      this._marker.container.getStrokeWidth(this._marker._model.source.zoom) || this._format.strokeWidth :
      this._format.strokeWidth)
      / this._marker._imageScale;
    this.element.strokeWidth = factor * this._format.strokeWidth;
  }

  /**
   * Clean Up
   */
  cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }
}
