import { LayerEntityModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetEntitySuccess = '[STORE | ENTITY | SLIDE_LAYER] Get Entity Success',
  GetEntitiesSuccess = '[STORE | ENTITY | SLIDE_LAYER] Get Entities Success',
  AddEntity = '[STORE | ENTITY | SLIDE_LAYER] Add Entity',
  UpdateEntity = '[STORE | ENTITY | SLIDE_LAYER] Update Entity',
  RemoveEntity = '[STORE | ENTITY | SLIDE_LAYER] Remove Entity',
  SetLayerIndex = '[STORE | ENTITY | SLIDE_LAYER] Set Layer Index',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Entity Success
 */
export class GetEntitySuccess {
  public static readonly type = ActionTypes.GetEntitySuccess;

  public constructor(public entityQueryResult: LayerEntityModels.LayerEntityQueryResult) {
  }
}

/**
 * Get Entities Success
 */
export class GetEntitiesSuccess {
  public static readonly type = ActionTypes.GetEntitiesSuccess;

  public constructor(public entityQueryResults: LayerEntityModels.LayerEntityQueryResult[]) {
  }
}

/**
 * Add Entity
 */
export class AddEntity {
  public static readonly type = ActionTypes.AddEntity;

  public constructor(public entity: LayerEntityModels.Layer) {
  }
}

/**
 * Update Entity
 */
export class UpdateEntity {
  public static readonly type = ActionTypes.UpdateEntity;

  public constructor(public entity: LayerEntityModels.Layer) {
  }
}

/**
 * Remove Entity
 */
export class RemoveEntity {
  public static readonly type = ActionTypes.RemoveEntity;

  public constructor(public entity: LayerEntityModels.Layer) {
  }
}

/**
 * Set Layer Index
 */
export class SetLayerIndex {
  public static readonly type = ActionTypes.SetLayerIndex;

  public constructor(public layerIndex: number) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>