import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AlertControlModels } from '@medsurf/flat-models';
import { AlertControlActions } from '@medsurf/flat-actions';
import { AlertControlState } from '@medsurf/flat-states';

/**
 * Alert Control Facade
 */
@Injectable()
export class AlertControlFacade {
  //<editor-fold desc="Selectors">

  public alerts$ = this.store.select(AlertControlState.alerts$);

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(public store: Store) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Dismiss Alert
   *
   * @param alert: AlertControlModels.Alert
   */
  public requestDismissAlert(alert: AlertControlModels.Alert) {
    return this.store.dispatch(new AlertControlActions.DismissAlert(alert));
  }

  //</editor-fold>
}
