import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { Alert, AlertType, AlertOptions } from '@medsurf/models';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

    constructor() {}

    public createAlert(options: AlertOptions): Alert {
        const defaultAlert: Alert = {
            id: uuid(),
            timestamp: new Date(),
            message: 'Unknown Error',
            params: options.params || {},
            type: AlertType.ERROR,
            code: 400,
            dismissable: true,
            duration: 0,
            dismissed: false
        };
        return Object.assign(defaultAlert, options);
    }

    public getAlertClassName(alertType: AlertType): string {
        switch (alertType) {
          case AlertType.ERROR:
            return 'alert-danger';
          case AlertType.WARNING:
            return 'alert-warning';
          case AlertType.INFO:
            return 'alert-info';
          case AlertType.SUCCESS:
            return 'alert-success';
          default:
            return 'alert-danger';
        }
      } 
}
