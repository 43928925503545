import { Group, PointOfInterest } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Slide view action types
 */
export enum SlideViewActionTypes {
  /**
   * Store
   */
  TogglePrintMode = '[STORE | SLIDEVIEW] Toggle print mode',
  ToggleFullscreen = '[STORE | SLIDEVIEW] Toggle fullscreen',
  ToggleVirtualPointer = '[STORE | SLIDEVIEW] Toggle virtual pointer',
  ToggleNavigator = '[STORE | SLIDEVIEW] Toggle navigator',
  SetNavigator = '[STORE | SLIDEVIEW] Set show navigator',
  TogglePoiPanel = '[STORE | SLIDEVIEW] Toggle POI panel',
  ToggleMarkers = '[STORE | SLIDEVIEW] Toggle markers',
  SetShowMarkers = '[STORE | SLIDEVIEW] Set show markers',
  ToggleSelftest = '[STORE | SLIDEVIEW] Toggle selftest',
  ToggleQuizFeedback = '[STORE | SLIDEVIEW] Toggle quiz feedback',
  ToggleQuizSolution = '[STORE | SLIDEVIEW] Toggle quiz solution',
  ToggleQuizExplanation = '[STORE | SLIDEVIEW] Toggle quiz explanation',
  ToggleCaseHistory = '[STORE | SLIDEVIEW] Toggle case history',
  SetMarkerLink = '[STORE | SLIDEVIEW] Set marker link',
  SetContainer = '[STORE | SLIDEVIEW] Set container',
  SetSelectedPOI = '[STORE | SLIDEVIEW] Set selected Point of Interest',
  SetSelectedGroup = '[STORE | SLIDEVIEW] Set selected group',
  SetSelectedVirtualGroup = '[STORE | SLIDEVIEW] Set selected virtual group',
  SetMarker = '[STORE | SLIDEVIEW] Set marker'
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

export interface MarkerLink {
  type: string;
  id: string;
}

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Toggle print mode
 */
export class TogglePrintMode {
  public static readonly type = SlideViewActionTypes.TogglePrintMode;

  public constructor() {
  }
}

/**
 * Toggle fullscreen
 */
export class ToggleFullscreen {
  public static readonly type = SlideViewActionTypes.ToggleFullscreen;

  public constructor() {
  }
}

/**
 * Toggle virtual pointer
 */
export class ToggleVirtualPointer {
  public static readonly type = SlideViewActionTypes.ToggleVirtualPointer;

  public constructor() {
  }
}

/**
 * Toggle navigator
 */
export class ToggleNavigator {
  public static readonly type = SlideViewActionTypes.ToggleNavigator;
}

/**
 * Set navigator
 */
export class SetNavigator {
  public static readonly type = SlideViewActionTypes.SetNavigator;

  public constructor(public showNavigator: boolean) {
  }
}

/**
 * Toggle poi panel
 */
export class TogglePoiPanel {
  public static readonly type = SlideViewActionTypes.TogglePoiPanel;

  public constructor() {
  }
}

/**
 * Toggle markers
 */
export class ToggleMarkers {
  public static readonly type = SlideViewActionTypes.ToggleMarkers;

  public constructor() {
  }
}

/**
 * Set showMarkers
 */
export class SetShowMarkers {
  public static readonly type = SlideViewActionTypes.SetShowMarkers;

  public constructor(public showMarkers: boolean) {
  }
}

/**
 * Toggle selftest
 */
export class ToggleSelftest {
  public static readonly type = SlideViewActionTypes.ToggleSelftest;

  public constructor() {
  }
}

/**
 * Toggle quiz feedback
 */
export class ToggleQuizFeedback {
  public static readonly type = SlideViewActionTypes.ToggleQuizFeedback;

  public constructor() {
  }
}

/**
 * Toggle quiz solution
 */
export class ToggleQuizSolution {
  public static readonly type = SlideViewActionTypes.ToggleQuizSolution;

  public constructor() {
  }
}

/**
 * Toggle quiz explanation
 */
export class ToggleQuizExplanation {
  public static readonly type = SlideViewActionTypes.ToggleQuizExplanation;

  public constructor() {
  }
}

/**
 * Toggle case history
 */
export class ToggleCaseHistory {
  public static readonly type = SlideViewActionTypes.ToggleCaseHistory;

  public constructor() {
  }
}

/**
 * Set marker link
 */
export class SetMarkerLink {
  public static readonly type = SlideViewActionTypes.SetMarkerLink;

  public constructor(public markerLink: MarkerLink) {
  }
}

/**
 * Set container
 */
export class SetContainer {
  public static readonly type = SlideViewActionTypes.SetContainer;

  public constructor(public container: any) {
  }
}

/**
 * Set selected poi
 */
export class SetSelectedPOI {
  public static readonly type = SlideViewActionTypes.SetSelectedPOI;

  public constructor(public selectedPOI: PointOfInterest) {
  }
}

/**
 * Set selected group
 */
export class SetSelectedGroup {
  public static readonly type = SlideViewActionTypes.SetSelectedGroup;

  public constructor(public selectedGroup: Group) {
  }
}

/**
 * Set selected interactive area
 */
export class SetSelectedVirtualGroup {
  public static readonly type = SlideViewActionTypes.SetSelectedVirtualGroup;

  public constructor(public group: Group) {
  }
}


/**
 * Set marker
 */
export class SetMarker {
  public static readonly type = SlideViewActionTypes.SetMarker;

  public constructor(public marker: any) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
