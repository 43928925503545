import { BaseChainGuard } from './baseChain.guard';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ofActionDispatched } from '@ngxs/store';
import { Disconnect } from '@medsurf/actions';
import { WsState } from '@medsurf/state';
import { WebSocketDisconnected } from '@ngxs/websocket-plugin';

@Injectable({
  providedIn: 'root'
})
export class DisconnectWsGuard extends BaseChainGuard {
  /**
   * Check if ws connection is closed
   * @param next: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   */
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Promise<boolean> {
    if (this.store.selectSnapshot(WsState.isConnected)) {
      return new Promise<boolean>((resolve) => {
        this.actions$.pipe(ofActionDispatched(WebSocketDisconnected)).subscribe(() => {
          setTimeout(() => {
            resolve(true);
          }, 200);
        });
        this.store.dispatch(new Disconnect());
      });
    } else {
      return Promise.resolve(true);
    }
  }
}
