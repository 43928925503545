<!-- Chapter Text -->
<ng-template #chapter_text_template>
  <medsurf-chapter-text></medsurf-chapter-text>
</ng-template>

<!-- Content -->
<div class="mdsf-container js-modal-content-container">
  <main class="mdsf-chapter-container">
    <aside class="sidebar">
      <medsurf-sidebar [showCurrentSubject]="false" class="d-none d-md-block"></medsurf-sidebar>
    </aside>
    <article class="article">
      <!-- Vocabulary -->
      <medsurf-vocabulary *ngIf="(nodeSharedFacade.currentSelectedChapterPageType$ | async) === pageType.VOCABULARY"
                          [vocabulary]="(nodeSharedFacade.currentSelectedChapterPage$ | async)"></medsurf-vocabulary>

      <!-- Quiz Start -->
      <medsurf-quiz-start *ngIf="(nodeSharedFacade.currentSelectedChapterPageType$ | async) === pageType.QUIZ_START && (nodeSharedFacade.isTrainingResult$ | async) === false"></medsurf-quiz-start>

      <!-- Quiz End -->
      <medsurf-quiz-end *ngIf="(nodeSharedFacade.isTrainingResult$ | async)"></medsurf-quiz-end>

      <!-- Other -->
      <ng-container *ngIf="(nodeSharedFacade.currentSelectedChapterPageType$ | async) !== pageType.VOCABULARY
        && (nodeSharedFacade.currentSelectedChapterPageType$ | async) !== pageType.QUIZ_START
        && (nodeSharedFacade.isTrainingResult$ | async) === false">
        <ng-container *ngIf="(nodeSharedFacade.currentSelectedBlockPage$ | async) as blockPage; else chapter_text_template">
          <medsurf-grid *ngIf="blockPage.type === 'Grid'"></medsurf-grid>
          <medsurf-case-start *ngIf="blockPage.type === 'Case'"></medsurf-case-start>
        </ng-container>
      </ng-container>
    </article>
  </main>

  <!-- Footer -->
  <medsurf-footer class="d-none d-md-inline"
                  [links]="footerLinks"
                  align="left">
  </medsurf-footer>
</div>
