import { Component } from '@angular/core';
import { AppSharedFacade } from '@medsurf/flat-facades';
import { MediaControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  /**
   * Constructor
   *
   * @param appSharedFacade: AppSharedFacade
   * @param mediaControlService: MediaControlService
   */
  constructor(public appSharedFacade: AppSharedFacade,
              public mediaControlService: MediaControlService) {
  }
}
