import { Inject, Injectable } from '@angular/core';
import { Media, MediaAccess, MediaType, Node } from '@medsurf/models';
import { SETTINGS, Settings } from './settings.model';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(
    @Inject(SETTINGS) private settings: Settings
  ) {}

  public getUrl(media: Media, fileType: 'file' | 'originalFile' | 'thumbnail' | 'tileFolder' | 'poster' = 'file', forceApi: boolean = false): string {
    const url = media?.[fileType];
    let hash = '';
    if (fileType !== 'tileFolder') {
      if (media.checksum) {
        hash = '?' + media.checksum;
        if (media.copyrights?.length !== 0) {
          hash += '&' + new Date(media.editedAt).getTime();
        }
      }
      if (media.type === MediaType.VIDEO && (fileType === 'poster' || fileType === 'thumbnail') ) {
        hash = '?' + new Date(media.editedAt).getTime();
      }
    }
    if (!url) {
      return '';
    }
    return (media.access === MediaAccess.PRIVATE || forceApi) && fileType !== 'tileFolder' ?
      this.settings.endpointUrl + 'v1/file/' + url + hash :
      this.settings.s3endpointUrl + this.settings.s3Bucket + '/' + url + hash;
  }

  public getRelatedNodes(media: Media): Node[] {
    const nodeIndex = {};
    const relatedNodes = [];
    if (media.mediaInFolders) {
      media.mediaInFolders.forEach((folder: any) => {
        folder.nodes.forEach((node) => {
          node.page = folder;
          if (!nodeIndex[node.id]) {
            relatedNodes.push(node);
            nodeIndex[node.id] = true;
          }
        });
      });
    }
    if (media.mediaInImages) {
      media.mediaInImages.forEach((image) => {
        if (image.layer?.slide) {
          (image.layer.slide as any).nodes.forEach((node) => {
            node.page = image.layer.slide;
            if (!nodeIndex[node.id]) {
              relatedNodes.push(node);
              nodeIndex[node.id] = true;
            }
          });
        }
      });
    }
    if (media.mediaInSlideThumbs) {
      media.mediaInSlideThumbs.forEach((slide: any) => {
        slide.nodes.forEach((node) => {
          node.page = slide;
          if (!nodeIndex[node.id]) {
            relatedNodes.push(node);
            nodeIndex[node.id] = true;
          }
        });
      });
    }
    return relatedNodes;
  } 

  public isVideo(media: Media): boolean {
    return media?.type === MediaType.VIDEO;
  }

  public isAudio(media: Media): boolean {
    return media?.type === MediaType.AUDIO;
  }

  public isDocument(media: Media): boolean {
    return media?.type === MediaType.DOCUMENT;
  }

  public isDeepzoom(media: Media): boolean {
    return media?.type === MediaType.DEEPZOOM;
  }

  public isImage(media: Media): boolean {
    return media?.type === MediaType.IMAGE;
  }

  public getMediaTypeIcon(media: Media): string {
    switch (media?.type) {
      case MediaType.DOCUMENT:
        return 'fa-file';
      case MediaType.AUDIO:
        return 'fa-volume-up';
      case MediaType.IMAGE:
        return 'fa-image';
      case MediaType.DEEPZOOM:
        return 'fa-th';
      case MediaType.VIDEO:
        return 'fa-video';
      default:
        return 'fa-text';
    }
  }

  public getMediaTypeLabel(media: Media): string {
    switch (media?.type) {
      case MediaType.DOCUMENT:
        return 'file';
      case MediaType.AUDIO:
        return 'audio';
      case MediaType.IMAGE:
        return 'image';
      case MediaType.DEEPZOOM:
        return 'deepzoom';
      case MediaType.VIDEO:
        return 'video';
      default:
        return 'text';
    }
  }
}
