import { Annotation } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Annotation action types
 */
export enum AnnotationActionTypes {
  /**
   * Store
   */
  AddAnnotation = '[STORE | ANNOTATION] Add Annotation',
  AddAnnotationSuccess = '[STORE | ANNOTATION] Add Annotation Success'
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Add Annotation
 */
export class AddAnnotation {
  public static readonly type = AnnotationActionTypes.AddAnnotation;

  public constructor(public annotation: Annotation) {
  }
}

/**
 * Add Annotation success
 */
export class AddAnnotationSuccess {
  public static readonly type = AnnotationActionTypes.AddAnnotationSuccess;

  public constructor(public annotation: Annotation) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
