// <editor-fold desc="Action Types">

/**
 * Api action types
 */
export enum ApiActionTypes {
  /**
   * Store
   */
  GetStatusSuccess = '[STORE | API] Get Status Success',
  GetVersionSuccess = '[STORE | API] Get Version Success',
  PostFeedbackSuccess = '[STORE | API] Post Feedback Success',
  /**
   * Request
   */
  GetStatusRequest = '[REQUEST | API] Get Status',
  GetVersionRequest = '[REQUEST | API] Get Version',
  PostFeedbackRequest = '[REQUEST | API] Post Feedback',
}

// </editor-fold>

// <editor-fold desc="Models">

export enum Codes {
  OK = 0,
  UNKNOWN = 1,
  WARNING = 2,
  ERROR = 3
}

export interface Check {
  name: string;
  description: string;
  group?: string;
  parent?: string;
  result: Codes;
  value: string | number;
  time?: string;
  count?: number;
  visual?: 'bar' | 'line' | 'simple';
}

export interface Meta {
  host: string;
  website: string;
  ttl?: number;
  result: Codes;
  tags?: string[];
  time?: string;
  notifications?: {
    email?: string[];
    slack?: { [key: string]: string };
  }
}

export interface StatusModel {
  checks: Check[];
  meta: Meta;
}

export interface S3File {
  Key: string;
  LastModified: Date;
  Size: number;
}

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Status Success
 */
export class GetStatusSuccess {
  public static readonly type = ApiActionTypes.GetStatusSuccess;

  public constructor(public document: StatusModel) {
  }
}

/**
 * Get Version Success
 */
export class GetVersionSuccess {
  public static readonly type = ApiActionTypes.GetVersionSuccess;

  public constructor(public version: any) {
  }
}

/**
 * Post Feedback Success
 */
export class PostFeedbackSuccess {
  public static readonly type = ApiActionTypes.PostFeedbackSuccess;

  public constructor() {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Status Request
 */
export class GetStatusRequest {
  public static readonly type = ApiActionTypes.GetStatusRequest;

  public constructor() {
  }
}

/**
 * Get Version Request
 */
export class GetVersionRequest {
  public static readonly type = ApiActionTypes.GetVersionRequest;

  public constructor() {
  }
}

/**
 * Post Feedback Request
 */
export class PostFeedbackRequest {
  public static readonly type = ApiActionTypes.PostFeedbackRequest;

  public constructor(public email: string, public location: string, public message: string, public name: string) {
  }
}

// </editor-fold>
