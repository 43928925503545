<div class="h-100 w-100"
     #imageElement>
</div>
<div class="mdsf-microscope-btn-group"
     *ngIf="(menuViewerFacade.showPrint$ | async) === false"
     #btnGroup>
  <button id="zoom-in"
          class="mdsf-btn mdsf-btn-small zoom-in"
          title="{{ 'zoom_in' | translate }}"
          (click)="zoomIn()">
    <i class="fa fa-plus"></i>
  </button>
  <button id="toggle-navigator"
          class="mdsf-btn mdsf-btn-small"
          title="{{ 'toggle_navigator' | translate }}"
          (click)="toggleNavigator()">
    <i class="fa fa-eye-slash"></i>
  </button>
  <button id="zoom-out"
          class="mdsf-btn mdsf-btn-small zoom-out"
          title="{{ 'zoom_out' | translate }}"
          (click)="zoomOut()">
    <i class="fa fa-minus"></i>
  </button>
</div>
<div class="mdsf-microscope-loader"
     *ngIf="showSpinner">
  <div class="spinner-border"></div>
</div>
