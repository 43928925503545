<div *ngIf="showFeedbackModal"
     class="mdsf-feedback">

  <div class="mdsf-feedback-navbar">
    <button class="closebtn" title="{{ 'close_modal' | translate }}" (click)="close()">
      <i class="fa fa-fw fa-close"></i>
    </button>
  </div>

  <h1 class="feedback-header align-middle d-flex justify-content-center text-white text-uppercase mt-4">Feedback</h1>
  <div [class.fixed]="showFeedbackModal"
       class="mdsf-feedback-content align-middle d-flex justify-content-center">
    <form (ngSubmit)="onSubmit()" #feedbackForm="ngForm">
      <div class="form-group">
        <input #name="ngModel"
               [(ngModel)]="model.name"
               id="name"
               name="name"
               type="text"
               class="form-control search"
               placeholder="{{ 'feedback_name_placeholder' | translate }}"
               required>
        <div [hidden]="name.valid || name.pristine"
             class="alert alert-danger">
          {{ 'name_required' | translate }}
        </div>
      </div>
      <div class="form-group">
        <input #email="ngModel"
               [(ngModel)]="model.email"
               [email]="true"
               name="email"
               type="email"
               class="form-control search"
               placeholder="{{ 'feedback_email_placeholder' | translate }}"
               required>
        <div [hidden]="email.valid || email.pristine"
             class="alert alert-danger">
          {{ 'mail_required' | translate }}
        </div>
      </div>
      <div class="form-group">
        <textarea #message="ngModel"
                  [(ngModel)]="model.message"
                  name="message"
                  class="form-control search"
                  rows="5"
                  placeholder="{{ 'feedback_message_placeholder' | translate }}"
                  required>
        </textarea>
        <div [hidden]="message.valid || message.pristine"
             class="alert alert-danger">
          {{ 'message_required' | translate }}
        </div>
      </div>
      <div class="form-group">
        <input type="submit" class="form-control btn" *ngIf="!submitted" [disabled]="!feedbackForm.form.valid || isSubmitting" value="{{ 'send_feedback' | translate}}"/>
        <div [hidden]="!errorMessage"
             class="alert alert-danger">
          {{ errorMessage | translate }}
        </div>
        <div [hidden]="!successMessage"
             class="alert alert-success">
          {{ successMessage | translate }}
        </div>
      </div>
    </form>
  </div>
</div>
