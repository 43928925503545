import { Base } from '../base/base.entity.model';
import * as FlatModels from '../../internal';

/**
 * Link Entity Query Result
 */
export type LinkEntityQueryResult = FlatModels.EntityControlModels.EntityQueryResult<Link>;

/**
 * Link Type
 */
export enum LinkType {
  SLIDE = 'Slide',
  SITE = 'Site',
}

/**
 * Link
 */
export interface Link extends Base {
  type?: LinkType;
  link?: string;
}
