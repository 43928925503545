import { AnnotationMode, DisplayMode, ImageMode } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Menu action types
 */
export enum MenuActionTypes {
  /**
   * Store
   */
  SetDisplayMode = '[STORE | MENU] Set Display Mode',
  SetAnnotationMode = '[STORE | MENU] Set Annotation Mode',
  SetImageMode = '[STORE | MENU] Set Image Mode',
  ToggleShowHeader = '[STORE | MENU] Toggle Show Header',
  ToggleShowBody = '[STORE | MENU] Toggle Show Body',
  ToggleShowQuiz = '[STORE | MENU] Toggle Show Quiz',
  ToggleShowInteractiveArea = '[STORE | MENU] Toggle Show Interactive Area',
  ToggleShowModality = '[STORE | MENU] Toggle Show Modality'
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

export class SetDisplayMode {
  public static readonly type = MenuActionTypes.SetDisplayMode;

  public constructor(public displayMode: DisplayMode) {
  }
}

export class SetAnnotationMode {
  public static readonly type = MenuActionTypes.SetAnnotationMode;

  public constructor(public annotationMode: AnnotationMode) {
  }
}

export class SetImageMode {
  public static readonly type = MenuActionTypes.SetImageMode;

  public constructor(public imageMode: ImageMode) {
  }
}

export class ToggleShowHeader {
  public static readonly type = MenuActionTypes.ToggleShowHeader;

  public constructor() {
  }
}

export class ToggleShowBody {
  public static readonly type = MenuActionTypes.ToggleShowBody;

  public constructor() {
  }
}

export class ToggleShowQuiz {
  public static readonly type = MenuActionTypes.ToggleShowQuiz;

  public constructor() {
  }
}

export class ToggleShowInteractiveArea {
  public static readonly type = MenuActionTypes.ToggleShowInteractiveArea;

  public constructor() {
  }
}

export class ToggleShowModality {
  public static readonly type = MenuActionTypes.ToggleShowModality;

  public constructor() {
  }
}


// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
