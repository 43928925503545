import { Component } from '@angular/core';


@Component({
  selector: 'medsurf-control-redirect',
  templateUrl: './redirect.control.component.html',
  styleUrls: ['./redirect.control.component.css']
})
export class RedirectControlComponent{

}
