import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { MenuViewerFacade, PageSharedFacade, SlideViewerFacade } from '@medsurf/flat-facades';
import { MenuViewerModels } from "@medsurf/flat-models";

@Injectable({
  providedIn: "root"
})
export class PageSharedResolver implements Resolve<void> {
  /**
   * Constructor
   *
   * @param pageSharedFacade: PageSharedFacade
   */
  constructor(protected pageSharedFacade: PageSharedFacade, protected slideViewerFacade: SlideViewerFacade, protected menuViewerFacade: MenuViewerFacade) {
  }

  /**
   * Resolve
   */
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('idSlug');

    if(route.queryParams?.['pdf']){
      this.menuViewerFacade.requestSetPrintView(route.queryParams?.['pdf'] === 'author' ? MenuViewerModels.ViewerPrintMode.PRINT_AUTHOR : MenuViewerModels.ViewerPrintMode.PRINT_USER);
    }

    if(id) {
      this.pageSharedFacade.requestPageById(id);
      return this.slideViewerFacade.requestGetSlideDataByPageId(id);
    } else {
      return this.pageSharedFacade.requestPageByCurrentRoute();  
    }
  }
}