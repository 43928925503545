import Paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { Element } from '../../element';

/**
 * Selftest
 */
export class Selftest extends Element<ImageViewerModels.ElementModel> {
  /**
   * Members
   */
  private _showMarker: boolean;
  private _standardRadius: number;
  private _selectedRadius: number;
  private _selected: boolean;

  /**
   * Constructor
   *
   * @param model
   * @param _format
   * @param _target
   * @param _marker
   */
  public constructor(model,
                     private _format,
                     private _target,
                     private _marker) {
    super(model);
    this._showMarker = false;
    const factor =
      (this._marker && this._marker._model.source && this._marker._model.source.zoom && this._marker.container._isDeepZoom ?
      this._marker.container.getStrokeWidth(this._marker._model.source.zoom) || this._format.strokeWidth :
      this._format.strokeWidth)
      / this._marker._imageScale;
    this._standardRadius = 2 * factor * this._format.strokeWidth;
    this._selectedRadius = factor * this._format.strokeWidth;
    this._selected = false;
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const marker = this._marker, self = this;

    this._element = this.draw(this._target.x, this._target.y, this._standardRadius);
    this.element.self = self;

    /**
     * OnClick handler
     */
    this.element.onClick = event => {
      self._toggleMarker();

      let index, found = false;
      for (const selftest of marker._selftests) {
        if (selftest.element._id === event.target._id) {
          selftest.selected = true;
          index = marker._selftests.indexOf(selftest);
          marker.showSelectedMarker(index);
          found = true;
        }
      }
      if (!found) {
        marker.state = 'selected';
      }
      if (!self._toggleMarker) {
        marker.state = 'unselected';
      }
    };

    /**
     * onMouseLeave handler
     */
    this.element.onMouseLeave = () => {
      document.body.style.cursor = 'default';
    };

    /**
     * onMouseLeave handler
     */
    this.element.onMouseEnter = () => {
      document.body.style.cursor = 'pointer';
    };
  }

  /**
   * Draw
   *
   * @param x: number
   * @param y: number
   * @param radius: number
   */
  public draw(x: number,
              y: number,
              radius: number) {
    const center = new Paper.Point(x, y);

    const clickArea = new Paper.Path.Circle(center, (this._isMobile() ? 3 : 2) * radius);
    clickArea.fillColor = this._format.fillColor;
    clickArea.fillColor.alpha = 0.0001;
    clickArea['marker'] = this._marker;

    const selftest = new Paper.Path.Circle(center, radius);
    selftest.strokeColor = new Paper.Color('#FFFFFF');
    selftest.strokeWidth = this._format.strokeWidth;
    selftest.fillColor = this._format.fillColor;

    return new Paper.Group({
      children: [
        selftest, clickArea
      ]
    });
  }

  /**
   * Is Mobile
   *
   * @protected
   */
  protected _isMobile() {
    return ((window.innerWidth <= 1024 && window.innerHeight <= 1366) ||
      (window.innerWidth <= 1366 && window.innerHeight <= 1024));
  }

  /**
   * Set Standard Radius
   */
  public setStandardRadius() {
    this._setRadius(this._standardRadius);
  }

  /**
   * Set Selected Radius
   */
  public setSelectedRadius() {
    this._setRadius(this._selectedRadius);
  }

  /**
   * Set Radius
   *
   * @param radius
   * @protected
   */
  protected _setRadius(radius) {
    if (this.radius !== radius) {
      this.element.firstChild.scale(radius / this.radius);
    }
  }

  /**
   * Getter radius
   */
  public get radius() {
    return this.element.firstChild.bounds.width / 2;
  }

  /**
   * Clean Up
   */
  public cleanUp() {
    if (this.element) {
      this.element.remove();
      this._element = null;
    }
  }

  /**
   * Toggle Marker
   *
   * @protected
   */
  protected _toggleMarker() {
    this._showMarker = !this._showMarker;
  }

  /**
   * Set Position
   *
   * @param x: number
   * @param y: number
   */
  public setPosition(x: number,
                     y: number) {
    this.element.position = new Paper.Point(x, y);
  }

  /**
   * Arrange
   */
  public arrange() {
    return;
  }

  /**
   * Resize
   *
   * @param w: number
   * @param h: number
   */
  public resize(w: number,
                h: number) {
    return;
  }
}
