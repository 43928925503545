import { Injectable } from '@angular/core';
import { Actions, ofActionCompleted, ofActionDispatched, Store } from '@ngxs/store';
import { AuthControlActions } from '@medsurf/flat-actions';
import { AuthControlState } from '@medsurf/flat-states';

/**
 * Auth Control Facade
 */
@Injectable()
export class AuthControlFacade {
  //<editor-fold desc="Selectors">

  public isAuthenticated$ = this.store.select(AuthControlState.isAuthenticated$);
  public user$ = this.store.select(AuthControlState.user$);

  //</editor-fold>

  //<editor-fold desc="Snapshot Selectors">

  public snapshot_isAuthenticated = () => this.store.selectSnapshot(AuthControlState.isAuthenticated$);

  //</editor-fold>

  //<editor-fold desc="Actions">

  public ofActionCompletedLoginSuccess = this.actions$.pipe(ofActionCompleted(AuthControlActions.LoginSuccess));
  public ofActionDispatchedLoginInvalid = this.actions$.pipe(ofActionDispatched(AuthControlActions.LoginInvalid));

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   * @param actions$: Actions
   */
  constructor(public store: Store,
              public actions$: Actions) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Login
   *
   * @param email: string
   * @param password: string
   */
  public requestLogin(email: string, password: string) {
    return this.store.dispatch(new AuthControlActions.Login(email, password));
  }

  /**
   * Request Logout
   */
  public requestLogout() {
    return this.store.dispatch(new AuthControlActions.Logout());
  }

  //</editor-fold>
}
