<div class="alert-container">
  <ng-container *ngIf="(alertControlFacade.alerts$ | async) as alerts">
    <ng-container *ngFor="let alert of alerts">
      <div [class]="'alert ' + getAlertClassName(alert.type)" role="alert" *ngIf="!alert.dismissed">
        <span [innerHTML]="alert.message | translate:alert.params | safeHtml"></span>
        <button *ngIf="alert.dismissable" type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="dismiss(alert)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>