import { Component } from '@angular/core';
import { SlideViewerFacade, TrainingViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-slide-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param trainingViewerFacade: TrainingViewerFacade
   */
  constructor(public slideViewerFacade: SlideViewerFacade,
              public trainingViewerFacade: TrainingViewerFacade) {
  }

  /* TODO How to -> move to footer ??? or handle in store
  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.param = params.id;
    });

    this.path$.pipe(takeUntil(this.destroyed$), filter(path => !!path))
      .subscribe((path: PathModel) => {
        if (path.block && this.param?.length > 0) {
          path = cloneDeep(path);
          path.block.children = path.block.children.filter((slide: SlideNode) => {
            return this.param.map((id: string) => Number.parseInt(id, 10)).includes(slide.page.slideId);
          });
        }
        this.footerService.setPath(path);
      });
  }
  */
}
