<article class="d-flex h-100 w-100" *ngIf="{ 
  sidebar: (slideViewerFacade.currentSelectedSlideSidebarSize$ | async), 
  sidebarFull: (slideViewerFacade.currentSelectedSlideSidebarFullSize$ | async), 
  main: (slideViewerFacade.currentSelectedSlideMainSize$ | async),
  mainFull: (slideViewerFacade.currentSelectedSlideMainFullSize$ | async)
} as size">

  <medsurf-slide-sidebar class="flex-grow-{{size.sidebar}}"
                         *ngIf="(menuViewerFacade.showFullscreen$ | async) === false && size.mainFull === false">
  </medsurf-slide-sidebar>

  <div class="main-content h-100 flex-grow-{{size.main}}"
       *ngIf="size.sidebarFull === false && (slideViewerFacade.currentSelectedSlideMedia$ | async) as media">

    <!-- Video -->
    <medsurf-video class="h-100"
                   *ngIf="mediaControlService.isVideo(media)"
                   [media]="media">
    </medsurf-video>

    <!-- Audio -->
    <medsurf-audio class="h-100"
                   *ngIf="mediaControlService.isAudio(media)"
                   [media]="media">
    </medsurf-audio>

    <!-- Image -->
    <!-- -->
    <medsurf-image class="h-100"
                   *ngIf="mediaControlService.isDeepzoom(media) || mediaControlService.isImage(media)">
    </medsurf-image>
    <!-- -->

    <!-- Sequence Control -->
    <medsurf-sequence-control *ngIf="(slideViewerFacade.currentSelectedSlideHasSequenceControl$ | async) || (slideViewerFacade.currentSelectedSlideLayers$ | async)?.length > 1"></medsurf-sequence-control>

    <!-- Shortcuts -->
    <medsurf-shortcuts *ngIf="mediaControlService.isDeepzoom(media) || mediaControlService.isImage(media)"></medsurf-shortcuts>
  </div>
</article>
