import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { NavigationControlModels } from '@medsurf/flat-models';
import { NavigationControlActions } from '@medsurf/flat-actions';

/**
 * Navigation Control State Model
 */
export interface NavigationControlStateModel {
  slugs: NavigationControlModels.PathModel | null;
  currentURL: string;
  queryParams: Params | null;
  loadTrainings: boolean;
  loadRestrictedAndHidden: boolean;
  showSearch: boolean;
  showClose: boolean;
  isPrinting: boolean;
}

/**
 * Navigation Control State
 */
@State<NavigationControlStateModel>({
  name: 'navigationControl',
  defaults: {
    slugs: null,
    currentURL: '',
    queryParams: null,
    loadTrainings: false,
    loadRestrictedAndHidden: false,
    showSearch: true,
    showClose: false,
    isPrinting: false,
  }
})
@Injectable()
export class NavigationControlState {
  /**
   * Static
   * TODO remove
   */
  public static order = ['eLearning', 'subject', 'chapter', 'block', 'slide'];

  /**
   * Constructor
   *
   * @param store: Store
   */
  public constructor(protected store: Store) {
  }

  //<editor-fold desc="Selectors">

  /**
   * Selector slugs$
   *
   * @param state: NavigationStateModel
   */
  @Selector([NavigationControlState])
  public static slugs$(state: NavigationControlStateModel): NavigationControlModels.PathModel | null {
    return state.slugs;
  }

  //<editor-fold desc="Page">

  /**
   * Selector pageSlug$
   *
   * @param slugs: NavigationControlModels.PathModel | null
   */
  @Selector([NavigationControlState.slugs$])
  public static pageSlug$(slugs: NavigationControlModels.PathModel | null) {
    return slugs
      && slugs.type === NavigationControlModels.PathModelType.PAGE
      ? (slugs as NavigationControlModels.PagePathModel).page || null
      : null;
  }

  //</editor-fold>

  //<editor-fold desc="Node">

  /**
   * Selector idSlug$
   *
   * @param slugs: NavigationControlModels.PathModel | null
   */
  @Selector([NavigationControlState.slugs$])
  public static idSlug$(slugs: NavigationControlModels.PathModel | null) {
    return slugs
      && slugs.type === NavigationControlModels.PathModelType.SLIDE_ID
      ? (slugs as NavigationControlModels.SlideIdPathModel).id || null
      : null;
  }

  //</editor-fold>

  //<editor-fold desc="Slide">

  /**
   * Selector elearningSlug$
   *
   * @param slugs: NavigationControlModels.PathModel | null
   */
  @Selector([NavigationControlState.slugs$])
  public static elearningSlug$(slugs: NavigationControlModels.PathModel | null) {
    return slugs && ([NavigationControlModels.PathModelType.SLIDE, NavigationControlModels.PathModelType.TRAINING].includes(slugs.type))
      ? (slugs as NavigationControlModels.SlidePathModel).eLearning || null
      : null;
  }

  /**
   * Selector subjectSlug$
   *
   * @param slugs: NavigationControlModels.PathModel | null
   */
  @Selector([NavigationControlState.slugs$])
  public static subjectSlug$(slugs: NavigationControlModels.PathModel | null) {
    return slugs && ([NavigationControlModels.PathModelType.SLIDE, NavigationControlModels.PathModelType.TRAINING].includes(slugs.type))
      ? (slugs as NavigationControlModels.SlidePathModel).subject || null
      : null;
  }

  /**
   * Selector chapterSlug$
   *
   * @param slugs: NavigationControlModels.PathModel | null
   */
  @Selector([NavigationControlState.slugs$])
  public static chapterSlug$(slugs: NavigationControlModels.PathModel | null) {
    return slugs && ([NavigationControlModels.PathModelType.SLIDE, NavigationControlModels.PathModelType.TRAINING].includes(slugs.type))
      ? (slugs as NavigationControlModels.SlidePathModel).chapter || null
      : null;
  }

  /**
   * Selector blockSlug$
   *
   * @param slugs: NavigationControlModels.PathModel | null
   */
  @Selector([NavigationControlState.slugs$])
  public static blockSlug$(slugs: NavigationControlModels.PathModel | null) {
    return slugs && ([NavigationControlModels.PathModelType.SLIDE, NavigationControlModels.PathModelType.TRAINING].includes(slugs.type))
      ? (slugs as NavigationControlModels.SlidePathModel).block || null
      : null;
  }

  /**
   * Selector slideSlug$
   *
   * @param slugs: NavigationControlModels.SlugModel | null
   */
  @Selector([NavigationControlState.slugs$])
  public static slideSlug$(slugs: NavigationControlModels.PathModel | null) {
    return slugs && ([NavigationControlModels.PathModelType.SLIDE, NavigationControlModels.PathModelType.TRAINING].includes(slugs.type))
      ? (slugs as NavigationControlModels.SlidePathModel).slide || null
      : null;
  }

  /**
   * Selector slideIdSlug$
   *
   * @param slugs: NavigationControlModels.SlugModel | null
   */
  @Selector([NavigationControlState.slugs$])
  public static slideIdSlug$(slugs: NavigationControlModels.PathModel | null) {
    return slugs && ([NavigationControlModels.PathModelType.SLIDE_ID].includes(slugs.type))
      ? (slugs as NavigationControlModels.SlideIdPathModel).id || null
      : null;
  }

  /**
   * Selector elearningUrl$
   *
   * @param elearningSlug: string | null
   */
  @Selector([NavigationControlState.elearningSlug$])
  public static elearningUrl$(elearningSlug: string | null) {
    return elearningSlug || null;
  }

  /**
   * Selector subjectUrl$
   *
   * @param elearningUrl: string | null
   * @param subjectSlug: string | null
   */
  @Selector([
    NavigationControlState.elearningUrl$,
    NavigationControlState.subjectSlug$
  ])
  public static subjectUrl$(elearningUrl: string | null,
                            subjectSlug: string | null) {
    return elearningUrl && subjectSlug ? (elearningUrl + '/' + subjectSlug) || null : null;
  }

  /**
   * Selector chapterUrl$
   *
   * @param subjectUrl: string | null
   * @param chapterSlug: string | null
   */
  @Selector([
    NavigationControlState.subjectUrl$,
    NavigationControlState.chapterSlug$
  ])
  public static chapterUrl$(subjectUrl: string | null,
                            chapterSlug: string | null) {
    return subjectUrl && chapterSlug ? (subjectUrl + '/' + chapterSlug) || null : null;
  }

  /**
   * Selector blockUrl$
   *
   * @param chapterUrl: string | null
   * @param blockSlug: string | null
   */
  @Selector([
    NavigationControlState.chapterUrl$,
    NavigationControlState.blockSlug$
  ])
  public static blockUrl$(chapterUrl: string | null,
                          blockSlug: string | null) {
    return chapterUrl && blockSlug ? (chapterUrl + '/' + blockSlug) || null : null;
  }

  /**
   * Selector slideUrl$
   *
   * @param blockUrl: string | null
   * @param slideSlug: string | null
   */
  @Selector([
    NavigationControlState.blockUrl$,
    NavigationControlState.slideSlug$
  ])
  public static slideUrl$(blockUrl: string | null,
                          slideSlug: string | null) {
    return blockUrl && slideSlug ? (blockUrl + '/' + slideSlug) || null : null;
  }

  //</editor-fold>

  /**
   * Selector currentUrl$
   *
   * @param state: NavigationControlState
   */
  @Selector([NavigationControlState])
  public static currentUrl$(state: NavigationControlStateModel): string {
    return state.currentURL;
  }

  /**
   * Selector queryParams$
   *
   * @param state: NavigationControlStateModel
   */
  @Selector([NavigationControlState])
  public static queryParams$(state: NavigationControlStateModel): Params | null {
    return state.queryParams;
  }

  /**
   * Selector showSearch$
   *
   * @param state: NavigationControlStateModel
   */
  @Selector([NavigationControlState])
  public static showSearch$(state: NavigationControlStateModel): boolean {
    return state.showSearch;
  }

  /**
   * Selector showClose$
   *
   * @param state: NavigationControlStateModel
   */
  @Selector([NavigationControlState])
  public static showClose$(state: NavigationControlStateModel): boolean {
    return state.showClose;
  }

  /**
   * Selector isPrinting$
   *
   * @param state: NavigationControlStateModel
   */
  @Selector([NavigationControlState])
  public static isPrinting$(state: NavigationControlStateModel): boolean {
    return state.isPrinting;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Set Activated Route
   *
   * @param patchState: StateContext<NavigationControlStateModel>
   * @param activatedRoute: NavigationControlActions.SetActivatedRoute
   */
  @Action(NavigationControlActions.SetActivatedRoute)
  public setActivatedRoute({patchState}: StateContext<NavigationControlStateModel>,
                           {activatedRoute}: NavigationControlActions.SetActivatedRoute) {
    let slugNames: string[];
    let slugs: NavigationControlModels.PathModel;
    let currentURL = activatedRoute.url.map(segment => segment.path).join('/');
    switch (activatedRoute.data['pathModelType'] || NavigationControlModels.PathModelType.DEFAULT) {
      case NavigationControlModels.PathModelType.PAGE:
        slugNames = ['pageSlug'];
        slugs = new NavigationControlModels.PagePathModel();
        break;
      case NavigationControlModels.PathModelType.SLIDE_ID:
        slugNames = ['idSlug'];
        slugs = new NavigationControlModels.SlideIdPathModel();
        break;
      case NavigationControlModels.PathModelType.SLIDE:
        slugNames = ['eLearningSlug', 'subjectSlug', 'chapterSlug', 'blockSlug', 'slideSlug'];
        slugs = new NavigationControlModels.SlidePathModel();
        break;
      case NavigationControlModels.PathModelType.TRAINING:
        slugNames = ['eLearningSlug', 'subjectSlug', 'chapterSlug', 'blockSlug', 'slideSlug'];
        slugs = new NavigationControlModels.TrainingPathModel();
        // Exclude quiz from loading path
        currentURL = activatedRoute.url.map(segment => segment.path).filter((v, i) => i != 0).join('/');
        break;
      default:
        slugNames = [];
        slugs = new NavigationControlModels.DefaultPathModel();
        break;
    }

    for (const slug of slugNames) {
      const value = activatedRoute.paramMap.get(slug);
      if (value) {
        (slugs as any)[slug.replace('Slug', '')] = activatedRoute.paramMap.get(slug);
      }
    }

    const queryParams = activatedRoute.queryParams;

    patchState({
      slugs,
      currentURL,
      queryParams
    });
  }

  //</editor-fold>
}
