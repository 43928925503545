import paper from 'paper';

export class Line {
  private _element: any;

  constructor(private _format, private _source, private _target, private _marker) {
    this._element = null;
    this._init();
  }

  _init() {
    this.draw();
  }

  draw() {
    if (this._element) {
      this.cleanUp();
    }
    this._element = new paper.Path();
    this._element.add(new paper.Point(this._source.x, this._source.y));
    this._element.add(new paper.Point(this._target.x, this._target.y));
    this._element.strokeColor = this._format.strokeColor;
    this._element.strokeWidth = (this._marker && this._marker._model.source
      && this._marker._model.source.zoom
      && this._marker._imageScale === 1 ?
      this._marker.container.getStrokeWidth(this._marker._model.source.zoom) || this._format.strokeWidth :
      this._format.strokeWidth)
      / this._marker._imageScale;
    this._element.strokeCap = 'round';
  }

  get element() {
    return this._element;
  }

  setScale() {
    this._element.strokeWidth = (this._marker && this._marker._model.source
      && this._marker._model.source.zoom
      && this._marker._imageScale === 1 ?
      this._marker.container.getStrokeWidth(this._marker._model.source.zoom) || this._format.strokeWidth :
      this._format.strokeWidth)
      / this._marker._imageScale;
  }

  cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }
}
