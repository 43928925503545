import { Base } from '../base/base.entity.model';

/**
 * Entity Query Types
 */
export enum EntityQueryTypes {
  // Entities
  ANNOTATION = 'annotationEntity',
  ANNOTATION_GROUP = 'annotationGroupEntity',
  ANNOTATION_LABEL = 'annotationLabelEntity',
  ANNOTATION_SOURCE = 'annotationSourceEntity',
  ANNOTATION_TARGET = 'annotationTargetEntity',
  CHOICE = 'choiceEntity',
  COPYRIGHT = 'copyrightEntity',
  FREE_FORM_STYLE = 'freeFormStyleEntity',
  IMAGE = 'imageEntity',
  KEYMAP_COLUMN = 'keymapColumnEntity',
  KEYMAP_LABEL = 'keymapLabelEntity',
  KEYMAP_STYLE = 'keymapStyleEntity',
  LAYER = 'layerEntity',
  MEDIA = 'mediaEntity',
  METADATA = 'metadataEntity',
  MODALITY = 'modalityEntity',
  NODE = 'nodeEntity',
  ROOT_NODE = 'nodeEntity',
  NOTE = 'noteEntity',
  PAGE = 'pageEntity',
  POINT_OF_INTEREST = 'pointOfInterestEntity',
  QUESTION = 'questionEntity',
  STAIN = 'stainEntity',
  USER = 'userEntity',
  VOCABULARY_GROUP = 'vocabularyGroupEntity',
  VOCABULARY_WORD = 'vocabularyWordEntity',
  // Special
  SLIDE_VIEWER_PAGE = 'slideViewerPage',
  TRAINING_VIEWER_SLIDE = 'trainingViewerSlide',
  TRAINING_VIEWER_BLOCK = 'trainingViewerBlock',
  FIND_SLIDES = 'findSlides',
  FIND_MARKERS = 'findMarkers',
}

/**
 * Entity Query
 */
export interface EntityQuery {
  type: EntityQueryTypes;
  id: string;
}

/**
 * Entity Query Result
 */
export interface EntityQueryResult<T extends Base> {
  type: EntityQueryTypes;
  id: string;
  entity: T;
}
