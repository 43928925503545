import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { SharedHistory } from '@medsurf/actions';

export interface HistoryItem {
  data: any;
  cb: any;
  id: string;
}

export interface PartialHistoryStateModel {
  history: HistoryItem[];
  position: number;
}

/**
 * History state model
 */
export interface SharedHistoryStateModel {
  histories: {
    [key: string]: PartialHistoryStateModel
  };
}

// @dynamic
/**
 * History State
 */
// @ts-ignore
@State<SharedHistoryStateModel>({
  name: 'sharedhistory',
  defaults: {
    histories: {}
  }
})

@Injectable()
export class SharedHistoryState {
  /**
   * Selector canUndo
   */
  static canUndo(id: string) {
    return createSelector([this], (state: SharedHistoryStateModel): boolean => {
      return state.histories[id]?.position > 0 || false;
    });
  }

  /**
   * Selector canRedo
   */
   static canRedo(id: string) {
    return createSelector([this], (state: SharedHistoryStateModel): boolean => {
      const partialHistory = state.histories[id];
      return partialHistory?.position < (partialHistory?.history?.length - 1) || false;
    });
  }

  /**
   * History init
   * @param getState: StateContext<HistoryStateModel>
   * @param setState: StateContext<HistoryStateModel>
   */
  @Action(SharedHistory.Init)
  public historyInit({getState, setState}: StateContext<SharedHistoryStateModel>, { snapshot }) {
    const partialHistory: PartialHistoryStateModel = { history: [cloneDeep(snapshot)], position: 0 };
    const histories = Object.assign({}, getState().histories)
    histories[snapshot.id] = Object.assign({}, partialHistory);

    setState({ histories: histories });
  }

  /**
   * History overwrite
   * @param getState: StateContext<HistoryStateModel>
   * @param setState: StateContext<HistoryStateModel>
   */
  @Action(SharedHistory.Overwrite)
  public historyOverwrite({getState, setState}: StateContext<SharedHistoryStateModel>, { snapshot }) {
    const histories = Object.assign({}, getState().histories);

    const partialHistory = histories[snapshot.id] || { history: [], position: 0 };
    partialHistory.history[partialHistory.position] = cloneDeep(snapshot);
    histories[snapshot.id] = Object.assign({}, partialHistory);

    setState({
      histories: histories
    });
  }

  /**
   * History changed
   * @param getState: StateContext<HistoryStateModel>
   * @param setState: StateContext<HistoryStateModel>
   */
  @Action(SharedHistory.Changed)
  public historyChanged({getState, setState}: StateContext<SharedHistoryStateModel>, { snapshot }): void {
    const histories = Object.assign({}, getState().histories);

    const partialHistory = histories[snapshot.id] || { history: [], position: 0 };
    partialHistory.history = partialHistory.history.slice(0, partialHistory.position + 1);
    partialHistory.history.push(cloneDeep(snapshot));
    partialHistory.position++;
    
    histories[snapshot.id] = Object.assign({}, partialHistory);

    setState({
      histories: histories
    });
  }

  /**
   * Undo
   * @param getState: StateContext<HistoryStateModel<T, U>>
   * @param setState: StateContext<HistoryStateModel<T, U>>
   */
  @Action(SharedHistory.Undo)
  public undo({getState, setState, dispatch}: StateContext<SharedHistoryStateModel>, {id}): void {
    const histories = Object.assign({}, getState().histories);
    const partialHistory = histories[id];
    if (partialHistory.position > 0) {
      partialHistory.position--;
      const entry = partialHistory.history[partialHistory.position];
      dispatch(new entry.cb(cloneDeep(entry.data)));
    }

    histories[id] = Object.assign({}, partialHistory);
    setState({
      histories: histories
    })
  }

  /**
   * Redo
   * @param getState: StateContext<HistoryStateModel<T, U>>
   * @param setState: StateContext<HistoryStateModel<T, U>>
   */
  @Action(SharedHistory.Redo)
  public redo({getState, setState, dispatch}: StateContext<SharedHistoryStateModel>, { id }): void {
    const histories = Object.assign({}, getState().histories);
    const partialHistory = histories[id];
    if (partialHistory.position < partialHistory.history.length - 1) {
      partialHistory.position++;
      const entry = partialHistory.history[partialHistory.position];
      dispatch(new entry.cb(cloneDeep(entry.data)));
    }

    histories[id] = Object.assign({}, partialHistory);
    setState({
      histories: histories
    })   
  }
}
