import { BaseChainGuard } from './baseChain.guard';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { SetActivatedRoute } from '@medsurf/actions';
import { AuthState } from '@medsurf/state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends BaseChainGuard {
  /**
   * Check if user is logged in
   * @param next: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   */
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
        this.store.dispatch(new SetActivatedRoute(next));
        resolve(true);
      } else {
        const queryParams: Params = {};
        if (next.url.length > 0) {
          queryParams.r = `/${next.url.map(s => s.path).join('/')}`;
        }
        await this.router.navigate(['login'], {queryParams});
        resolve(false);
      }
    });
  }
}
