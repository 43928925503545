import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import * as FlatActions from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';
import * as FlatStates from '../../internal';

/**
 * Slide Control Token
 */
export const SLIDE_CONTROL_TOKEN = new StateToken<SlideControlStateModel>('slideControl');

/**
 * Slide Control State Model
 */
export interface SlideControlStateModel {
  // Loaded Slides
  availableSlides: Set<string>;
}

/**
 * Slide Control State
 */
@State<SlideControlStateModel>({
  name: SLIDE_CONTROL_TOKEN,
  defaults: {
    availableSlides: new Set<string>(),
  }
})
@Injectable()
export class SlideControlState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   */
  public constructor(public store: Store,
                     public messageControlService: MessageControlService) {
  }

  //<editor-fold desc="Actions">

  /**
   * Get Slide Entity Success
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param patchState: StateContext<TrainingViewerStateModel>
   * @param entityQueryResult: FlatActions.SlideControlActions.GetSlideEntitySuccess
   */
  @Action(FlatActions.SlideControlActions.GetSlideEntitySuccess)
  public getSlideEntitySuccess({getState, patchState}: StateContext<SlideControlStateModel>,
                               {entityQueryResult}: FlatActions.SlideControlActions.GetSlideEntitySuccess) {
    const availableSlides = getState().availableSlides;
    if (!availableSlides.has(entityQueryResult.id)) {
      availableSlides.add(entityQueryResult.id);
      // TODO change to addItem
      patchState({
        availableSlides
      });
    }
  }

  /**
   * Get Slide Entities Success
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param patchState: StateContext<TrainingViewerStateModel>
   * @param entityQueryResults: FlatActions.SlideControlActions.GetSlideEntitiesSuccess
   */
  @Action(FlatActions.SlideControlActions.GetSlideEntitiesSuccess)
  public getSlideEntitiesSuccess({getState, patchState}: StateContext<SlideControlStateModel>,
                                 {entityQueryResults}: FlatActions.SlideControlActions.GetSlideEntitiesSuccess) {
    const availableSlides = getState().availableSlides;
    let hasChanges = false;
    for (const entityQueryResult of entityQueryResults) {
      if (!availableSlides.has(entityQueryResult.id)) {
        // TODO change to addItem
        availableSlides.add(entityQueryResult.id);
        hasChanges = true;
      }
    }
    if (hasChanges) {
      patchState({
        availableSlides
      });
    }
  }

  /**
   * Get Slide Data By Page Id
   *
   * @param state: StateContext<SlideControlStateModel>
   * @param pageId: FlatActions.SlideControlActions.GetSlideDataByPageId
   */
  @Action(FlatActions.SlideControlActions.GetSlideDataByPageId)
  public getSlideDataByPageId({getState}: StateContext<SlideControlStateModel>,
                              {pageId}: FlatActions.SlideControlActions.GetSlideDataByPageId) {
    if (pageId) {
      const availableSlides = getState().availableSlides;
      if (!availableSlides.has(pageId)) {
        return this.messageControlService.sendMessage(
          this.store.selectSnapshot(FlatStates.AuthControlState.token$),
          new FlatActions.SlideControlActions.GetSlideDataByPageIdRequest(pageId)
        );
      }
    }
    return;
  }

  //</editor-fold>
}
