import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SlideViewerFacade } from '@medsurf/flat-facades';
import { FeedbackControlService } from '@medsurf/flat-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'medsurf-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  private _destroyed$ = new Subject<boolean>();

  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param feedbackControlService: FeedbackControlService
   * @param route: ActivatedRoute
   */
  constructor(public slideViewerFacade: SlideViewerFacade,
              public feedbackControlService: FeedbackControlService,
              public route: ActivatedRoute) {
  }

  /**
   * Ng On Init
   */
  public ngOnInit() {
    // Load Next Slide Data
    this.slideViewerFacade.nextSlideNode$.pipe(takeUntil(this._destroyed$)).subscribe((slide) => {
      if (slide && slide.page) {
        this.slideViewerFacade.requestGetSlideDataByPageId(slide.page as unknown as string);
      }
    });
    // Load Previous Slide Data
    this.slideViewerFacade.previousSlideNode$.pipe(takeUntil(this._destroyed$)).subscribe((slide) => {
      if (slide && slide.page) {
        this.slideViewerFacade.requestGetSlideDataByPageId(slide.page as unknown as string);
      }
    });
  }

  /**
   * Ng On Destroy
   */
  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
