import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsComponent } from './errors.component';
import { MedsurfViewerCommonModule } from '../common/medsurf-viewer-common.module';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorsRoutingModule } from './errors-routing.module';

@NgModule({
  declarations: [ErrorsComponent],
  imports: [
    CommonModule,
    MedsurfViewerCommonModule,
    TranslateModule.forChild(),
    ErrorsRoutingModule,
  ]
})
export class ErrorsModule { }
