import { HttpClient } from '@angular/common/http';
import Paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { Container } from '../container';
import { Sprite } from './sprite';

/**
 * Virtual pointer class
 */
export class VirtualPointer extends Sprite<ImageViewerModels.VirtualPointerModel> {
  /**
   * Members
   */
  private _isMovable: boolean;

  /**
   * Constructor
   *
   * @param http: HttpClient
   * @param localPaper: Paper.PaperScope
   * @param mainLayer: Paper.Layer
   * @param container: Container
   */
  public constructor(http: HttpClient,
                     localPaper: Paper.PaperScope,
                     mainLayer: Paper.Layer,
                     container: Container) {
    const model = {
      offset: {
        x: (container.getCanvasSize().width / 2),
        y: (container.getCanvasSize().height / 2)
      },
      scale: 1
    };

    if (model.scale <= 0.1) {
      model.scale = 0.1;
    }

    super(http, model, localPaper, mainLayer, container);
    this._isMovable = true;
  }

  /**
   * Set file to load
   */
  public loadFile(): void {
    this.doLoadFile('/assets/images/virtualPointer.png');
  }

  /**
   * Arrange
   */
  public arrange(): void {
    super.arrange();
    if (this._image) {
      this._image.setScaling(1 / this._image.view.zoom * this.model.scale);
    }
  }

  /**
   * Setter Movable
   *
   * @param id
   */
  public set isMovable(id: boolean) {
    this._isMovable = id;
  }

  /**
   * Getter Movable
   *
   * @returns {boolean}
   */
  public get isMovable(): boolean {
    return this._isMovable;
  }
}
