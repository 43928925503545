import { Component, Input } from '@angular/core';
import { Annotation, PointOfInterest } from '@medsurf/models';

@Component({
  selector: 'medsurf-poi-list',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss']
})
export class PoiComponent  {
  @Input() pois: PointOfInterest[] = [];
  @Input() annotationsMap = new Map<string, Annotation>();

  public expandedPoi: PointOfInterest | null = null;

  toggleExpandedPoi(poi: PointOfInterest) {
    if (this.expandedPoi && poi?.id === this.expandedPoi?.id) {
      this.expandedPoi = null;
    } else {
      this.expandedPoi = poi;
    }
  }
}
