import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AlertControlModels } from '@medsurf/flat-models';
import { AlertControlActions } from '@medsurf/flat-actions';

/**
 * Alert Control State Model
 */
export interface AlertControlStateModel {
  alerts: AlertControlModels.Alert[];
}

/**
 * Alert Control State
 */
@State<AlertControlStateModel>({
  name: 'alertControl',
  defaults: {
    alerts: []
  }
})
@Injectable()
export class AlertControlState {
  //<editor-fold desc="Selectors">

  /**
   * Selector alerts$
   *
   * @param state: AlertControlStateModel
   */
  @Selector([AlertControlState])
  public static alerts$(state: AlertControlStateModel): AlertControlModels.Alert[] {
    return state.alerts;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Add Alert
   *
   * @param getState: StateContext<AlertControlStateModel>
   * @param patchState: StateContext<AlertControlStateModel>
   * @param dispatch: StateContext<AlertControlStateModel>
   * @param options: AlertControlActions.AddAlert
   */
  @Action(AlertControlActions.AddAlert)
  public addAlert({getState, patchState, dispatch}: StateContext<AlertControlStateModel>,
                  {options}: AlertControlActions.AddAlert): void {
    const state = getState();

    const alerts = state.alerts.slice();
    const alert = new AlertControlModels.DefaultAlert(options);
    alerts.unshift(alert);

    if (alert.duration) {
      setTimeout(() => {
        dispatch(new AlertControlActions.DismissAlert(alert));
      }, alert.duration * 1000);
    }

    patchState({
      alerts
    });
  }

  /**
   * Dismiss Alert
   *
   * @param getState: StateContext<AlertControlStateModel>
   * @param patchState: StateContext<AlertControlStateModel>
   * @param alert: AlertControlActions.DismissAlert
   */
  @Action(AlertControlActions.DismissAlert)
  public dismissAlert({getState, patchState}: StateContext<AlertControlStateModel>,
                      {alert}: AlertControlActions.DismissAlert): void {
    const state = getState();

    const alerts = state.alerts.slice();
    const selectedItem = alerts.find((item) => item.id === alert.id);
    if (selectedItem) {
      selectedItem.dismissed = true;
    }

    patchState({
      alerts
    });
  }

  /**
   * Clear Alerts
   *
   * @param getState: StateContext<AlertControlStateModel>
   * @param patchState: StateContext<AlertControlStateModel>
   */
  @Action(AlertControlActions.ClearAlerts)
  public clearAlerts({getState, patchState}: StateContext<AlertControlStateModel>): void {
    const state = getState();

    const alerts = state.alerts.map((item) => {
      item.dismissed = true;
      return item;
    });

    patchState({
      alerts
    });
  }

  //</editor-fold>
}
