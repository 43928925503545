import { EventEmitter } from 'events';
import { ImageViewerModels } from '@medsurf/flat-models';


/**
 * Element
 */
export abstract class Element<T extends ImageViewerModels.ElementModel> extends EventEmitter {
  /**
   * Members
   */
  protected _element: any;
  protected _model: T;
  protected _state: any;
  protected _id: string;
  protected _isSelected: boolean;

  /**
   * Constructor
   *
   * @param model: T
   */
  protected constructor(model: T) {
    super();
    this._model = model;
    this._element = null;
    this._state = undefined;
    this.id = this.model.id;
  }

  /**
   * Set Position
   *
   * @param x: number
   * @param y: number
   */
  public setPosition(x: number,
                     y: number) {
    this._element.position.x = x;
    this._element.position.y = y;
  }

  /**
   * Clean Up
   */
  public cleanUp() {
    if (this._element) {
      this._element.remove();
    }
  }

  /**
   * Select
   */
  public select() {
    this._isSelected = true;
    this.draw();
  }

  /**
   * Unselect
   */
  public unselect() {
    this._isSelected = false;
    this.arrange();
  }

  /**
   * Resize
   *
   * @param w: number
   * @param h: number
   */
  public abstract resize(w: number,
                         h: number);

  /**
   * Arrange
   */
  public abstract arrange();

  /**
   * Draw
   *
   * @param a
   * @param b
   * @param c
   * @protected
   */
  protected abstract draw(a?, b?, c?);

  /**
   * Setter state
   *
   * @param state: string
   */
  public set state(state: string) {
    this._state = state;
    switch (state) {
      case 'visible':
        this._element.visible = true;
        this._element.opacity = 1;
        break;
      case 'opaque':
        this._element.visible = true;
        this._element.opacity = 0; // should be 0.3, bug paperjs has issues with opacity
        break;
      case 'unselected':
        this._element.visible = false;
        this._element.opacity = 1;
        break;
      case 'selected':
        this._element.visible = false;
        this._element.opacity = 1;
        break;
      case 'hidden':
        this._element.visible = false;
        this._element.opacity = 1;
        break;

      default:
        break;
    }
  }

  /**
   * Getter state
   */
  public get state(): string {
    return this._state;
  }

  /**
   * Setter id
   *
   * @param id: string
   */
  public set id(id: string) {
    this._id = id;
  }

  /**
   * Getter id
   */
  public get id(): string {
    return this._id;
  }

  /**
   * Getter model
   */
  public get model(): T {
    return this._model;
  }

  /**
   * Getter element
   */
  public get element(): any {
    return this._element;
  }
}

