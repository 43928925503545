import { UserEntityModels } from '@medsurf/flat-models';
import { ValidationError } from 'class-validator';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  Login = '[STORE | CONTROL | AUTH] Login',
  LoginSuccess = '[STORE | CONTROL | AUTH] Login Success',
  LoginInvalid = '[STORE | CONTROL | AUTH] Login Invalid',
  Logout = '[STORE | CONTROL | AUTH] Logout',
  LogoutSuccess = '[STORE | CONTROL | AUTH] Logout Success',
  Me = '[STORE | CONTROL | AUTH] Me',
  MeSuccess = '[STORE | CONTROL | AUTH] Me Success',
  UpdateToken = '[STORE | CONTROL | AUTH] Update Token',
  UpdateTokenSuccess = '[STORE | CONTROL | AUTH] Update Token Success',
  ClearSession = '[STORE | CONTROL | AUTH] Clear Session',
  /**
   * Request
   */
  LoginRequest = '[REQUEST | CONTROL | AUTH] Login Request',
  LogoutRequest = '[REQUEST | CONTROL | AUTH] Logout Request',
  MeRequest = '[REQUEST | CONTROL | AUTH] Me Request',
  UpdateTokenRequest = '[REQUEST | CONTROL | AUTH] Update Token Request'
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Login
 */
export class Login {
  public static readonly type = ActionTypes.Login;

  public constructor(public username: string,
                     public password: string) {
  }
}

/**
 * Login Success
 */
export class LoginSuccess {
  public static readonly type = ActionTypes.LoginSuccess;

  public constructor(public token: string,
                     public tokenLifetime: number,
                     public expiresIn: number,
                     public user: UserEntityModels.User) {
  }
}

/**
 * Login Invalid
 */
export class LoginInvalid {
  public static readonly type = ActionTypes.LoginInvalid;

  public constructor(public errors: ValidationError[]) {
  }
}

/**
 * Logout
 */
export class Logout {
  public static readonly type = ActionTypes.Logout;
}

/**
 * Logout Success
 */
export class LogoutSuccess {
  public static readonly type = ActionTypes.LogoutSuccess;
}

/**
 * Me
 */
export class Me {
  public static readonly type = ActionTypes.Me;
}

/**
 * Me Success
 */
export class MeSuccess {
  public static readonly type = ActionTypes.MeSuccess;

  public constructor(public user: UserEntityModels.User) {
  }
}

/**
 * Update Token
 */
export class UpdateToken {
  public static readonly type = ActionTypes.UpdateToken;
}

/**
 * Update Token Success
 */
export class UpdateTokenSuccess {
  public static readonly type = ActionTypes.UpdateTokenSuccess;

  public constructor(public token: string,
                     public expiresIn: number) {
  }
}

/**
 * Clear Session
 */
export class ClearSession {
  public static readonly type = ActionTypes.ClearSession;
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Login Request
 */
export class LoginRequest {
  public static readonly type = ActionTypes.LoginRequest;

  public constructor(public username: string,
                     public password: string) {
  }
}

/**
 * Logout Request
 */
export class LogoutRequest {
  public static readonly type = ActionTypes.LogoutRequest;
}

/**
 * Me Request
 */
export class MeRequest {
  public static readonly type = ActionTypes.MeRequest;
}

/**
 * Update Token Request
 */
export class UpdateTokenRequest {
  public static readonly type = ActionTypes.UpdateTokenRequest;
}

// </editor-fold>
