<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Chapter Copyrights -->
<ng-template #chatper_copyright_template let-copyright="copyright">
  <ng-container *ngIf="copyright">
    <span>{{ copyright.text }}</span><br>
  </ng-container>
</ng-template>

<!-- Chapter -->
<ng-template #chapter_template let-media="media">
  <img [src]="mediaControlService.getUrl(media)"
       [alt]="media.altText" />
  <figcaption *ngIf="media.copyrights.length > 0">
    <ng-container *ngFor="let copyright of media.copyrights">
      <ng-container *ngTemplateOutlet="chatper_copyright_template;context:{
                    copyright: (appSharedFacade.copyright$(copyright) | async)}">
      </ng-container>
    </ng-container>
  </figcaption>
</ng-template>

<!-- Content -->
<div class="mdsf-text scrollable editor-input"
     *ngIf="(nodeSharedFacade.currentSelectedNode$ | async) as node; else spinner">
  <ng-container *ngIf="(appSharedFacade.page$(node.page) | async) as page; else spinner">
    <div class="text-container"
         #textElement>
      <medsurf-text-format [text]="formatDescription(page.description, (appSharedFacade.themeColor$ | async))"></medsurf-text-format>
    </div>
    <figure *ngIf="page && (appSharedFacade.media$(page.media) | async) as media"
            class="image d-none d-md-block">
      <ng-container *ngTemplateOutlet="chapter_template;context:{media: media}"></ng-container>
    </figure>
  </ng-container>
</div>

