import { Base } from './base.model';
import { Slide } from './slide.model';

export enum QuestionType {
  SINGLE_CHOICE = 'SingleChoiceQuestion',
  LONG_LIST = 'LongListQuestion',
  MAPPING = 'MappingQuestion',
  KPRIME = 'KprimeQuestion',
  TRUE_FALSE = 'TrueFalseQuestion',
  FREE_TEXT = 'FreeTextQuestion',
  INDICATION = 'IndicationQuestion',
  MULTIPLE_CHOICE = 'MultipleChoiceQuestion',
}

export enum NumberingType {
  NUMBER = '1',
  LETTER = 'A',
  ROMAN = 'I',
  NONE = 'None'
}

export interface Training extends Slide {
  url?: string;
  answeredRight?: boolean;
  answered?: boolean;
  toggleSolution?: number;
}

export interface Question extends Base {
  training?: Training;
  choices?: Choice[];
  order?: number;
  content?: string;
  placeholder?: string;
  explanation?: string;
  required?: boolean;
  randomised?: boolean;
  numberingType?: NumberingType;
  type?: QuestionType;
}

export interface SingleChoiceQuestion extends Question {
  solution?: number;
}

export interface LongListQuestion extends Question {
  solution?: number;
}

export interface MappingQuestion extends Question {
  solutions?: string[];
}

export interface KprimeQuestion extends Question {
  solutions?: string[];
}

export interface TrueFalseQuestion extends Question {
  solutions?: string[];
}

export interface FreeTextQuestion extends Question {
  solutionText?: string;
}

export interface IndicationQuestion extends Question {
  solution?: number;
}

export interface MultipleChoiceQuestion extends Question {
  solutions?: string[];
}

export interface Choice extends Base {
  question?: Question;
  value?: string;
  label?: string;
  order?: number;
}
