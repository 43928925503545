import { FeedbackViewerModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action types
 */
export enum ActionTypes {
  /**
   * Store
   */
  SendFeedback = '[STORE | VIEWER | FEEDBACK] Send Feedback',
  SendFeedbackSuccess = '[STORE | VIEWER | FEEDBACK] Send Feedback Success',
  /**
   * Request
   */
  SendFeedbackRequest = '[REQUEST | VIEWER | FEEDBACK] Send Feedback Request',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Send Feedback
 */
export class SendFeedback {
  public static readonly type = ActionTypes.SendFeedback;

  public constructor(public feedback: FeedbackViewerModels.FeedbackModel) {
  }
}

/**
 * Send Feedback Success
 */
export class SendFeedbackSuccess {
  public static readonly type = ActionTypes.SendFeedbackSuccess;
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Send Feedback Request
 */
export class SendFeedbackRequest {
  public static readonly type = ActionTypes.SendFeedbackRequest;

  public constructor(public feedback: FeedbackViewerModels.FeedbackModel) {
  }
}

// </editor-fold>
