<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<div class="mdsf-container js-modal-content-container"
     *ngIf="(appSharedFacade.metadata$ | async) as metadata; else spinner">
  <div class="mdsf-elearning-container align-items-center scrollable m-0 p-0 w-100 flex-grow-1">
    <medsurf-carousel *ngIf="setTitle(metadata.title)"></medsurf-carousel>
    <div class="mdsf-elearnings"
         *ngIf="(nodeSharedFacade.elearnings$ | async) | sortBy_shared:'asc':'position' as elearnings">
      <div *ngIf="elearnings !== undefined; else spinner"></div>
      <ng-container *ngFor="let node of elearnings">
        <medsurf-listnav *ngIf="!node.hidden"
                         [node]="node"
                         [locked]="(nodeSharedFacade.isNodeLocked$(node, false) | async)"
                         [showSubjects]="metadata.carouselShowSubjects">
        </medsurf-listnav>
      </ng-container>
    </div>
  </div>
  <medsurf-footer class="d-none d-md-inline"
                  [links]="footerLinks"
                  align="center">
  </medsurf-footer>
</div>
