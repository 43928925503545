import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SlideMainComponent } from './main/slide-main.component';
import { SlideComponent } from './slide.component';

const routes: Routes = [
  {
    path: '',
    component: SlideComponent,
    children: [
      { path: '', component: SlideMainComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SlideRoutingModule {
}
