// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  Connect = '[STORE | CONTROL | WS] Connect',
  Disconnect = '[STORE | CONTROL | WS] Disconnect',
  Ping = '[STORE | CONTROL | WS] Ping',
  Pong = '[STORE | CONTROL | WS] Pong',
  SetIsConnected = '[STORE | CONTROL | WS] Set IsConnected',
  SetIsExpired = '[STORE | CONTROL | WS] Set isExpired'
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Connect
 */
export class Connect {
  public static readonly type = ActionTypes.Connect;
}

/**
 * Disconnect
 */
export class Disconnect {
  public static readonly type = ActionTypes.Disconnect;
}

/**
 * Ping
 */
export class Ping {
  public static readonly type = ActionTypes.Ping;
}

/**
 * Pong
 */
export class Pong {
  public static readonly type = ActionTypes.Pong;

  public constructor(public apiVersion: string) {
  }
}

/**
 * Set IsConnected
 */
export class SetIsConnected {
  public static readonly type = ActionTypes.SetIsConnected;

  public constructor(public isConnected: boolean) {
  }
}

/**
 * Set IsExpired
 */
export class SetIsExpired {
  public static readonly type = ActionTypes.SetIsExpired;

  public constructor(public isExpired: boolean) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
