<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="question && question.solutions !== undefined && trainingResult && trainingResult.answer !== undefined; else spinner">
  <ng-container *ngIf="question.solutions !== null && trainingResult.answer !== null && question.solutions.length === trainingResult.answer.length">
    <!-- Header -->
    <medsurf-quiz-question-header [index]="(trainingViewerFacade.selectedQuestionIndex$(question.id) | async) || index"
                                  [checkedAndCorrect]="trainingResult.showFeedback && isCorrect()"
                                  [questionType]="question.type">
    </medsurf-quiz-question-header>

    <!-- Question Form -->
    <form name="form"
          class="mdsf-quiz-form">
      <!-- Question Text -->
      <medsurf-text [text]="question.content"></medsurf-text>

      <!-- TODO sort needed?: | sortBy_shared:'asc':'order' -->
      <ng-container *ngIf="(trainingViewerFacade.choicesByIds$(question.choices) | async) as choices; else spinner">
        <!-- Choices -->
        <div class="mb-4" *ngIf="(menuViewerFacade.showPrintForUser$ | async) === false">
          <div class="mb-0 border-0 mt-0"
               *ngFor="let solution of question.solutions; let j = index">
            <div class="choice w-100 p-2"
                 [class.choice-error]="trainingResult.showFeedback && solution !== trainingResult.answer[j]"
                 [class.choice-correct]="trainingResult.showFeedback && solution === trainingResult.answer[j]"
                 [class.choice-not-answered]="showRequired && passItemRequired(j)">
              <label class="mt-2 mr-2 align-middle text-black">{{ j + 1 }}.</label>
              <div class="flex-grow-1">
                <!-- User Answer -->
                <select class="form-control custom-select" *ngIf="(menuViewerFacade.showPrintForAuthor$ | async)">
                  <option [innerHTML]="getChoiceBySolution(choices, solution).label | stripHtml"></option>
                </select>
                <select class="form-control custom-select"
                        *ngIf="(menuViewerFacade.showPrintForAuthor$ | async) === false"
                        [(ngModel)]="trainingResult.answer[j]"
                        [name]="'item' + question.order + 'set' + j"
                        [class.not-answered]="showRequired && passItemRequired(j)"
                        [required]="question.required"
                        [disabled]="trainingResult.showFeedback"
                        (change)="onChange()">
                  <option value="" disabled selected hidden>{{ question.placeholder }}</option>
                  <option *ngFor="let choice of choices; let k = index"
                          [value]="choice.order" [innerHTML]="choice.label | stripHtml">
                  </option>
                </select>

                <!-- Correct Answer -->
                <div class="correct-answer mt-2"
                     *ngIf="trainingResult.showFeedback && solution !== trainingResult.answer[j] && getChoiceBySolution(choices, solution) as choiceSolution">
                  <strong>{{ 'correct_answer' | translate }}:</strong> {{ choiceSolution.label | stripHtml }}
                </div>
              </div>

              <!-- Feedback -->
              <div class="form-control-feedback mt-2 ml-2"
                   *ngIf="trainingResult.showFeedback"
                   [class.invisible]="trainingResult.showFeedback === false || trainingResult.answer[j] === ''">
                <i class="fa fa-fw fa-check solution-correct"
                   *ngIf="solution === trainingResult.answer[j]"></i>
                <i class="fa fa-fw fa-times solution-error"
                   *ngIf="solution !== trainingResult.answer[j]"></i>
              </div>
            </div>
          </div>
        </div>

        <!-- Choices User Print Mode -->
        <div class="mb-4"
             *ngIf="(menuViewerFacade.showPrintForUser$ | async)">
          <div class="mb-0 border-0 mt-0"
               *ngFor="let choice of choices">
            <div class="choice w-100 p-2">
              <div class="print-radiobox"></div>
              <div class="print-label">{{ choice.label | stripHtml }}</div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Explanation -->
      <div class="form-control-explanation mt-2 "
           *ngIf="question.explanation && (trainingResult.showExplanation || (menuViewerFacade.showPrintForAuthor$ | async))">
        <strong>{{ 'explanation' | translate }}</strong>
        <medsurf-text [text]="question.explanation"></medsurf-text>
      </div>

      <!-- Footer -->
      <medsurf-quiz-question-footer #footer
                                    class="mt-2"
                                    *ngIf="(menuViewerFacade.showPrint$ | async) === false && (question.solutions || []).length > 0"
                                    [question]="question"
                                    [trainingResult]="trainingResult"
                                    [isCorrect]="isCorrect"
                                    [passRequired]="passRequired"
                                    [(showRequired)]="showRequired"
                                    [ngStyle]="{'order': 100}">
      </medsurf-quiz-question-footer>
    </form>
  </ng-container>
</ng-container>
