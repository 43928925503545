/**
 * Path Model Type
 */
export enum PathModelType {
  DEFAULT,
  PAGE,
  SLIDE_ID,
  SLIDE,
  TRAINING,
}

/**
 * Path Model
 */
export type PathModel = DefaultPathModel | PagePathModel | SlideIdPathModel | SlidePathModel | TrainingPathModel;

/**
 * Default Path Model
 */
export class DefaultPathModel {
  /**
   * Members
   */
  public readonly type: PathModelType = PathModelType.DEFAULT;
}

/**
 * Page Path Model
 */
export class PagePathModel extends DefaultPathModel {
  /**
   * Members
   */
  public override readonly type = PathModelType.PAGE;
  public page: string | null = null;
}

/**
 * Slide Id Path Model
 */
export class SlideIdPathModel extends DefaultPathModel {
  /**
   * Members
   */
  public override readonly type = PathModelType.SLIDE_ID;
  public id: string | null = null;
}

/**
 * Slide Path Model
 */
export class SlidePathModel extends DefaultPathModel {
  /**
   * Members
   */
  public override readonly type = PathModelType.SLIDE;
  public eLearning: string | null = null;
  public subject: string | null = null;
  public chapter: string | null = null;
  public block: string | null = null;
  public slide: string | null = null;
}

/**
 * Training Path Model
 */
export class TrainingPathModel extends DefaultPathModel {
  /**
   * Members
   */
  public override readonly type = PathModelType.TRAINING;
  public eLearning: string | null = null;
  public subject: string | null = null;
  public chapter: string | null = null;
  public block: string | null = null;
  public slide: string | null = null;
}
