import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { MedsurfViewerCommonModule } from '../common/medsurf-viewer-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginRoutingModule } from './login-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ClassValidatorFormBuilderModule } from 'ngx-reactive-form-class-validator';

@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClassValidatorFormBuilderModule.forRoot(),
    MedsurfViewerCommonModule,
    TranslateModule.forChild(),
    LoginRoutingModule
  ]
})
export class LoginModule {
}
