import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { FeedbackViewerActions } from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';
import { AuthControlState } from '../../internal';

/**
 * Feedback Viewer State Model
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeedbackViewerStateModel {
}

/**
 * Feedback Viewer State
 */
@State<FeedbackViewerStateModel>({
  name: 'feedbackViewer',
  defaults: {}
})
@Injectable()
export class FeedbackViewerState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   */
  public constructor(protected store: Store,
                     protected messageControlService: MessageControlService) {
  }

  //<editor-fold desc="Actions">

  /**
   * Send Feedback
   *
   * @param state: StateContext<FeedbackViewerState>
   * @param feedback: FeedbackViewerActions.SendFeedback
   */
  @Action(FeedbackViewerActions.SendFeedback)
  public sendFeedback(state: StateContext<FeedbackViewerState>,
                      {feedback}: FeedbackViewerActions.SendFeedback) {
    return this.messageControlService.sendMessage(
      this.store.selectSnapshot(AuthControlState.token$),
      new FeedbackViewerActions.SendFeedbackRequest(feedback)
    );
  }

  //</editor-fold>
}
