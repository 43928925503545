import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { ErrorControlModels } from '@medsurf/flat-models';
import { AlertControlActions, ErrorControlActions } from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';
import { AuthControlState } from '../../internal';

/**
 * Error Control State Model
 */
export interface ErrorControlStateModel {
  errors: ErrorControlModels.MedsurfError[]
}

/**
 * Error Control State
 */
@State<ErrorControlStateModel>({
  name: 'errorControl',
  defaults: {
    errors: []
  }
})
@Injectable()
export class ErrorControlState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   */
  public constructor(protected store: Store,
                     protected messageControlService: MessageControlService) {
  }

  //<editor-fold desc="Actions">

  /**
   * Submit User Error
   *
   * @param state: StateContext<ErrorControlStateModel>
   * @param error: ErrorControlActions.SubmitUserError
   */
  @Action(ErrorControlActions.SubmitUserError)
  public submitUserError(state: StateContext<ErrorControlStateModel>,
                         {error}: ErrorControlActions.SubmitUserError) {
    return this.messageControlService.sendMessage(
      this.store.selectSnapshot(AuthControlState.token$),
      new ErrorControlActions.SubmitUserErrorRequest(Object.assign({}, error, {message: error.message, stack: error.stack}))
    );
  }

  /**
   * Submit User Error Success
   *
   * @param dispatch: StateContext<ErrorControlStateModel>
   * @param message: ErrorControlActions.SubmitUserErrorSuccess
   */
  @Action(ErrorControlActions.SubmitUserErrorSuccess)
  public submitUserErrorSuccess({dispatch}: StateContext<ErrorControlStateModel>,
                                {message}: ErrorControlActions.SubmitUserErrorSuccess) {
    // TODO: Prevent user to see the real error: dispatch(new AlertControlActions.AddAlert({ message }));
    dispatch(new AlertControlActions.AddAlert({ message: 'Es wurde ein Fehler festgestellt. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.' }));
  }

  //</editor-fold>
}
