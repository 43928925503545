import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { animations } from './slide.animations';
import * as FlatModels from '@medsurf/flat-models';
import { MenuViewerFacade, NodeSharedFacade, SlideViewerFacade, TrainingViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
  animations: animations
})
export class SlideComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  private _destroyed$ = new Subject<boolean>();
  public slide: FlatModels.NodeEntityModels.SlideNode;
  public position: number;

  /**
   * Constructor
   *
   * @param nodeSharedFacade: NodeSharedFacade
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   * @param trainingViewerFacade: TrainingViewerFacade
   */
  constructor(public nodeSharedFacade: NodeSharedFacade,
              public slideViewerFacade: SlideViewerFacade,
              public menuViewerFacade: MenuViewerFacade,
              public trainingViewerFacade: TrainingViewerFacade) {
  }

  /**
   * Can Deactivate
   *
   * @param url
   */
  /* TODO this * /
  canDeactivate(url) {
    const route = decodeURI(url.replace(/.*\//, ''));
    const slideNode = this.slideNodes.find(sn => sn.route === route);
    this.position = slideNode?.position || 0;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 100);
    });
  }
  /**/

  /**
   * Ng On Init
   */
  ngOnInit(): void {
    // Load Slide Data By Page
    this.slideViewerFacade.currentSelectedSlideNode$.pipe(takeUntil(this._destroyed$)).subscribe((slide) => {
      this.slide = slide;
      if (slide) {
        this.slideViewerFacade.requestGetSlideDataByPageId(slide.page as unknown as string);
        this.slideViewerFacade.requestSetLayerIndex(0);
      }
      // Set default image index only after slide change
      this.slideViewerFacade.defaultIndex$.pipe(filter(i => i >= 0), take(1)).subscribe((index) => {
        this.slideViewerFacade.requestSetImageIndex(index);
      });
    });

    // Fix image index when larger than valid images max index
    this.slideViewerFacade.images$.pipe(takeUntil(this._destroyed$)).subscribe((images) => {
      const imageIndex = this.slideViewerFacade.snapshot_imageIndex();
      if (images.length > 0 && imageIndex > images.length - 1) {
        this.slideViewerFacade.requestSetImageIndex(images.length - 1);
      }
    })

  }

  /**
   * Ng On Destroy
   */
  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
