import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlMatchResult } from '@angular/router';
import { SlideComponent } from './slide/slide.component';
import { ChaptersComponent } from './chapters/chapters.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { ErrorsComponent } from './errors/errors.component';
import { PagesComponent } from './pages/pages.component';
import { LoginComponent } from './login/login.component';
import { ElearningsComponent } from './elearnings/elearnings.component';
import { NavigationControlModels } from '@medsurf/flat-models';
import * as FlatResolver from '@medsurf/flat-resolvers';
import * as FlatGuards from '@medsurf/flat-guards';
import { RedirectControlComponent } from '@medsurf/flat-components';

/**
 * Navigation Matcher
 * @param url
 */
const navigationMatcher = (url: UrlSegment[]): UrlMatchResult => {
  if (url.length <= 4) {
    const emptyUrlSegment = new UrlSegment(null, {});
    return {
      consumed: url,
      posParams: {
        eLearningSlug: url[0] || emptyUrlSegment,
        subjectSlug: url[1] || emptyUrlSegment,
        chapterSlug: url[2] || emptyUrlSegment,
        blockSlug: url[3] || emptyUrlSegment,
      }
    };
  }
  return null;
};

/**
 * App Routes
 */
const appRoutes: Routes = [
  {
    path: '',
    component: ElearningsComponent,
    canActivate: [FlatGuards.NavigationControlGuard, FlatGuards.OrderedChainControlGuard],
    data: {
      orderedGuards: [FlatGuards.UserViewerGuard],
      // TODO
      footerLinks: [
        {title: 'DATENSCHUTZERKLÄRUNG', url: 'privacy'},
        {title: 'IMPRESSUM', url: 'impressum'}
      ],
    },
    resolve: {
      elearnings: FlatResolver.ElearningSharedResolver,
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [FlatGuards.NavigationControlGuard],
  },
  {
    path: '404',
    component: ErrorsComponent,
    canActivate: [FlatGuards.NavigationControlGuard],
    data: {
      errorType: 404
    }
  },
  {
    path: '401',
    component: ErrorsComponent,
    canActivate: [FlatGuards.NavigationControlGuard],
    data: {
      errorType: 401
    }
  },
  {
    path: 'seite/:pageSlug',
    component: PagesComponent,
    canActivate: [FlatGuards.NavigationControlGuard],
    data: {
      pathModelType: NavigationControlModels.PathModelType.PAGE,
      // TODO
      footerLinks: [
        {title: 'DATENSCHUTZERKLÄRUNG', url: 'privacy'},
        {title: 'IMPRESSUM', url: 'impressum'}
      ],
    },
    resolve: {
      nodes: FlatResolver.PageSharedResolver,
    },
  },
  {
    path: 'node/:idSlug',
    component: RedirectControlComponent,
    canActivate: [FlatGuards.NavigationControlGuard],
    data: {
      pathModelType: NavigationControlModels.PathModelType.SLIDE_ID,
      // TODO
      footerLinks: [
        {title: 'DATENSCHUTZERKLÄRUNG', url: 'privacy'},
        {title: 'IMPRESSUM', url: 'impressum'}
      ],
    },
    resolve: {
      nodes: FlatResolver.RedirectSharedResolver
    },
  },
  {
    path: 'slide/:idSlug',
    component: SlideComponent,
    canActivate: [FlatGuards.NavigationControlGuard, FlatGuards.RedirectControlGuard],
    data: {
      pathModelType: NavigationControlModels.PathModelType.SLIDE_ID,
      // TODO
      footerLinks: [
        {title: 'DATENSCHUTZERKLÄRUNG', url: 'privacy'},
        {title: 'IMPRESSUM', url: 'impressum'}
      ],
    },
    resolve: {
      nodes: FlatResolver.PageSharedResolver
    },
  },
  {
    path: ':eLearningSlug',
    component: SubjectsComponent,
    canActivate: [FlatGuards.NavigationControlGuard, FlatGuards.OrderedChainControlGuard],
    data: {
      pathModelType: NavigationControlModels.PathModelType.SLIDE,
      orderedGuards: [FlatGuards.UserViewerGuard],
      // TODO
      footerLinks: [
        {title: 'DATENSCHUTZERKLÄRUNG', url: 'privacy'},
        {title: 'IMPRESSUM', url: 'impressum'}
      ],
    },
    resolve: {
      nodes: FlatResolver.NodeSharedResolver
    },
  },
  {
    matcher: navigationMatcher,
    component: ChaptersComponent,
    canActivate: [FlatGuards.NavigationControlGuard, FlatGuards.OrderedChainControlGuard],
    data: {
      pathModelType: NavigationControlModels.PathModelType.SLIDE,
      orderedGuards: [FlatGuards.UserViewerGuard, FlatGuards.RestrictedViewerGuard]
    },
    resolve: {
      nodes: FlatResolver.NodeSharedResolver
    },
  },
  {
    // TODO deserialize old quiz route with new route
    path: 'quiz/:eLearningSlug/:subjectSlug/:chapterSlug/:blockSlug/:slideSlug',
    component: SlideComponent,
    canActivate: [FlatGuards.NavigationControlGuard, FlatGuards.OrderedChainControlGuard],
    canDeactivate: [FlatGuards.CanDeactivateViewerGuard],
    data: {
      pathModelType: NavigationControlModels.PathModelType.TRAINING,
      orderedGuards: [FlatGuards.UserViewerGuard, FlatGuards.RestrictedViewerGuard]
    },
    resolve: {
      slide: FlatResolver.NodeSharedResolver
    },
  },
  // TODO deserialize old quiz route -> load data and redirect to current new system
  {
    path: ':eLearningSlug/:subjectSlug/:chapterSlug/:blockSlug/:slideSlug',
    component: SlideComponent,
    canActivate: [FlatGuards.NavigationControlGuard, FlatGuards.OrderedChainControlGuard],
    canDeactivate: [FlatGuards.CanDeactivateViewerGuard],
    data: {
      pathModelType: NavigationControlModels.PathModelType.SLIDE,
      orderedGuards: [FlatGuards.UserViewerGuard, FlatGuards.RestrictedViewerGuard]
    },
    resolve: {
      slide: FlatResolver.NodeSharedResolver
    },
  },
  {
    path: '**',
    component: ErrorsComponent,
    canActivate: [FlatGuards.NavigationControlGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
