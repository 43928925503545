import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ConnectWebSocket, DisconnectWebSocket, SendWebSocketMessage } from '@ngxs/websocket-plugin';
import { Connect, Disconnect, SetIsConnected, SetIsExpired, Ping, Pong } from '@medsurf/actions';

/**
 * Ws state model
 */
export interface WsStateModel {
  isConnected: boolean;
  isExpired: boolean;
  apiVersion: string;
}

/**
 * Ws State
 */
@State<WsStateModel>({
  name: 'ws',
  defaults: {
    isConnected: false,
    isExpired: true,
    apiVersion: null,
  }
})
@Injectable()
export class WsState {
  constructor(protected store: Store) {
    store.dispatch(new SetIsConnected(false));
  }

  /**
   * Selector isConnected
   * @param state: WsStateModel
   */
  @Selector()
  public static isConnected(state: WsStateModel): boolean {
    return state.isConnected;
  }

  /**
   * Selector isExpired
   * @param state: WsStateModel
   */
  @Selector()
  public static isExpired(state: WsStateModel): boolean {
    return state.isExpired;
  }

  /**
   * Selector apiVersion
   * @param state: WsStateModel
   */
  @Selector()
  public static apiVersion(state: WsStateModel): string {
    return state.apiVersion;
  }

  /**
   * Connect ws
   * @param dispatch: StateContext<WsStateModel>
   */
  @Action(Connect)
  public wsConnect({dispatch}: StateContext<WsStateModel>) {
    dispatch(new ConnectWebSocket());
  }

  /**
   * Disconnect ws
   * @param dispatch: StateContext<WsStateModel>
   */
  @Action(Disconnect)
  public wsDisconnect({dispatch}: StateContext<WsStateModel>) {
    dispatch(new DisconnectWebSocket());
  }

  /**
   * Ping
   * @param dispatch: StateContext<WsStateModel>
   */
  @Action(Ping)
  public ping({dispatch}: StateContext<WsStateModel>) {
    const ping: any = new Ping();
    const data = Object.assign({type: ping.constructor.type}, ping);
    this.store.dispatch(new SendWebSocketMessage(data));
  }

  /**
   * Pong
   * @param dispatch: StateContext<WsStateModel>
   * @param apiVersion: Version number of API
   */
  @Action(Pong)
  public pong({setState, getState}: StateContext<WsStateModel>, {apiVersion}: Pong) {
    if (getState().apiVersion === apiVersion) {
      return;
    }
    setState(
      patch({
        apiVersion
      }));
  }

  /**
   * Set isConnected
   * @param setState: StateContext<WsStateModel>
   * @param isConnected: WsSetIsConnected
   */
  @Action(SetIsConnected)
  public wsSetIsConnected({setState}: StateContext<WsStateModel>, {isConnected}: SetIsConnected) {
    setState(
      patch({
        isConnected
      }));
  }

  /**
   * Set isExpired
   * @param setState: StateContext<WsStateModel>
   * @param isExpired: WsSetIsExpired
   */
  @Action(SetIsExpired)
  public wsSetIsExpired({setState}: StateContext<WsStateModel>, {isExpired}: SetIsExpired) {
    setState(
      patch({
        isExpired
      }));
  }
}
