import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ClassValidatorFormBuilderService, ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator';
import { AuthControlFacade } from '@medsurf/flat-facades';
import { LoginValidator } from '@medsurf/validators';

@Component({
  selector: 'medsurf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  private _destroyed$ = new Subject<boolean>();
  private queryParams: Params;
  public loginForm: ClassValidatorFormGroup;
  public isSubmitted = false;

  /**
   * Constructor
   *
   * @param titleService: Title
   * @param router: Router
   * @param authControlFacade: AuthControlFacade
   * @param fb: ClassValidatorFormBuilderService
   */
  constructor(public titleService: Title,
              public router: Router,
              public authControlFacade: AuthControlFacade,
              public fb: ClassValidatorFormBuilderService) {
    this.router.events
      .pipe(filter((event: ActivationStart) => !!event.snapshot), takeUntil(this._destroyed$), map(event => event.snapshot))
      .subscribe((event: any) => {
        this.queryParams = event.queryParams;
      });
  }

  /**
   * NgOnInit
   */
  public async ngOnInit(): Promise<void> {
    this.loginForm = this.fb.group(LoginValidator, {
      email: [],
      password: []
    });

    if (this.authControlFacade.snapshot_isAuthenticated()) {
      await this.navigateToQueryUrl();
    }

    this.authControlFacade.ofActionCompletedLoginSuccess.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.navigateToQueryUrl();
      this.isSubmitted = false;
    });

    this.authControlFacade.ofActionDispatchedLoginInvalid.pipe(takeUntil(this._destroyed$)).subscribe((action) => {
      this.loginForm.get('password').setValue('');
      action.errors.forEach((err) => {
        this.loginForm.get(err.property).setErrors(err.constraints);
      });
      this.isSubmitted = false;
    })
  }

  /**
   * Set Title
   *
   * @param title: string
   */
  public setTitle(title: string) {
    this.titleService.setTitle(title);
    return true;
  }

  /**
   * Getter f
   */
  public get f() {
    return this.loginForm.controls;
  }

  /**
   * Navigate To Query URl
   *
   * @private
   */
  private async navigateToQueryUrl() {
    const url = this.queryParams.r ? this.queryParams.r : '/';
    await this.router.navigate([url]);
    this.loginForm.reset();
  }

  /**
   * On Submit
   */
  public async onSubmit(): Promise<void> {
    if (this.isSubmitted || this.loginForm.invalid) {
      return;
    }
    this.isSubmitted = true;

    const { email, password } = this.loginForm.value;
    this.authControlFacade.requestLogin(email, password);
  }

  /**
   * Ng On Destroy
   */
  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
