import { TextExport, Node } from '@medsurf/models';

export enum TextExportActionTypes {
  /**
   * Store
   */

  GetTextExport = '[STORE | TEXTEXPORT] Get Text export for node',
  GetTextExportSuccess = '[STORE | TEXTEXPORT] Get Text export for Node Success',
  /**
   * Request
   */
  GetTextExportRequest = '[REQUEST | TEXTEXPORT] Get Text export for Node'
}

/**
 * GetText export per Node
 */
export class GetTextExport {
  public static readonly type = TextExportActionTypes.GetTextExport;

  public constructor(public node: Node) {
  }
}

/**
 * GetText export per Node
 */
export class GetTextExportSuccess {
  public static readonly type = TextExportActionTypes.GetTextExportSuccess;

  public constructor(public node: Node, public textExports: TextExport[]) {
  }
}

/**
 * GetText export per Node Request
 */
export class GetTextExportRequest {
  public static readonly type = TextExportActionTypes.GetTextExportRequest;

  public constructor(public node: Node) {
  }
}
