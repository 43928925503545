<!-- Content -->
<div class="d-flex"
     role="group"
     *ngIf="question && trainingResult && (trainingViewerFacade.currentSelectedSlidePage$ | async) as trainingPage">

  <!-- Show Answer -->
  <button type="button"
          class="mdsf-btn m-2 mdsf-btn-primary"
          role="button"
          *ngIf="trainingResult.showFeedback === false && trainingPage.slideId"
          (click)="setShowFeedback(trainingPage)"
          translate="check_answer">
  </button>
  <button type="button"
          class="mdsf-btn m-2"
          role="button"
          disabled
          *ngIf="trainingResult.showFeedback">
    {{ 'check_answer_done' | translate }} <i class="fa fa-fw fa-times"></i>
  </button>

  <!-- Show Explanation -->
  <ng-container *ngIf="question.explanation && trainingResult.showFeedback">
    <button type="button"
            class="mdsf-btn m-2 ml-3 mdsf-btn-primary"
            role="button"
            *ngIf="trainingResult.showExplanation === false"
            (click)="toggleShowExplanation()"
            translate="show_explanation">
    </button>
    <button type="button"
            class="mdsf-btn m-2 ml-3"
            role="button"
            *ngIf="trainingResult.showExplanation"
            (click)="toggleShowExplanation()">
      {{ 'show_explanation_done' | translate }}  <i class="fa fa-fw fa-times"></i>
    </button>
  </ng-container>

  <!-- Question Required -->
  <p class="d-flex justify-content-center align-items-center m-2 ml-3 question-required"
     *ngIf="showRequired && trainingResult.showFeedback === false">
    <i class="fa fa-exclamation-triangle mr-2"></i>
    {{ 'question_required' | translate }}
  </p>
</div>
