export type WindowWithConfig = Window & {
  config?: {
    s3Host?: string,
    s3Port?: string,
    s3Secure?: boolean,
    s3Bucket?: string;
    s3endpointUrl?: string;
    endpointUrl?: string;
    authorUrl?: string;
    sentryDsn?: string;
    sentryEnvironment?: string;
    matomoProjectId?: string;
    matomoEndpointUrl?: string;
  }
}
  
const config = (window as WindowWithConfig)?.config;
config.s3endpointUrl = (config?.s3Secure ? 'https' : 'http') + '://' + config?.s3Host + ':' + config?.s3Port + '/';
config.endpointUrl += config.endpointUrl?.endsWith('/') ? '' : '/';
config.authorUrl += config.authorUrl?.endsWith('/') ? '' : '/';

export { config };