import { Page } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Page action types
 */
export enum PageActionTypes {
  /**
   * Store
   */
  GetPageByTitle = '[STORE | PAGE] Get page by title',
  GetPageSuccess = '[STORE | PAGE] Get page success',
  /**
   * Request
   */
  GetPageByTitleRequest = '[REQUEST | PAGE] Get page by title',
  GetPageByIdRequest = '[REQUEST | PAGE] Get page by id',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get page by title
 */
export class GetPageByTitle {
  public static readonly type = PageActionTypes.GetPageByTitle;

  public constructor(public title: string) {
  }
}


/**
 * Get page success
 */
export class GetPageSuccess {
  public static readonly type = PageActionTypes.GetPageSuccess;

  public constructor(public page: Page) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get page by name Request
 */
export class GetPageByTitleRequest {
  public static readonly type = PageActionTypes.GetPageByTitleRequest;

  public constructor(public title: string) {
  }
}

/**
 * Get page by id Request
 */
export class GetPageByIdRequest {
  public static readonly type = PageActionTypes.GetPageByIdRequest;

  public constructor(public id: string) {
  }
}

// </editor-fold>
