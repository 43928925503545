<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="{pois: (slideViewerFacade.currentSelectedSlidePois$ | async) | sortBy_shared:'asc':'order', selectedPoi: (slideViewerFacade.currentSelectedSlidePoi$ | async)} as data; else spinner">
  <ng-container *ngIf="data.pois.length > 0">
    <h3 class="pois-title my-3">{{"pois" | translate}}</h3>
    <div class="poi-list mt-2 mb-4">
      <ng-container *ngFor="let poi of data.pois">
        <div class="card collapsable" *ngIf="{ active: data.selectedPoi === poi || (menuViewerFacade.showPrint$ | async) } as collapsable"
             [class.active]="collapsable.active">
          <!-- Header -->
          <div class="card-header"
               (click)="slideViewerFacade.requestSetPoiId(poi.id)">
            <h4 class="card-title mb-0"
                [ngClass]="[collapsable.active ? (slideViewerFacade.themeColor$ | async) : ''] ">
              {{ poi.name }}
            </h4>
          </div>
          <!-- Body -->
          <ng-container *ngIf="collapsable.active">
            <ng-container *ngIf="{notes: (slideViewerFacade.notesByIds(poi.notes) | async) | sortBy_shared:'asc':'order', annotationToPointOfInterests: poi.annotationToPointOfInterest | sortBy_shared:'asc':'order'} as noteData; else spinner">
              <div class="card-body" *ngIf="noteData.notes.length > 0 || noteData.annotationToPointOfInterests.length > 0">
                <!-- Notes -->
                <div class="poi-notes"
                     *ngIf="noteData.notes.length > 0">
                  <div class="poi-note"
                       *ngFor="let note of noteData.notes">
                    <p class="mb-1 note-name"
                       *ngIf="note.name">{{ note.name }}
                    </p>
                    <medsurf-text class="note-text" [text]="note.text"></medsurf-text>
                  </div>
                </div>
                <!-- Annotations -->
                <div class="poi-annotations mb-0"
                     *ngIf="noteData.annotationToPointOfInterests.length > 0">
                  <ng-container *ngFor="let annotationToPointOfInterest of noteData.annotationToPointOfInterests">
                    <button *ngIf="(slideViewerFacade.annotation(annotationToPointOfInterest.annotation.id) | async) as annotation"
                            class="annotation-link btn btn-link"
                            [ngClass]="(slideViewerFacade.themeColor$ | async)"
                            (click)="slideViewerFacade.requestSetAnnotationId(annotation.id)">
                      <i class="fa fa-fw fa-location-dot"></i>
                      <span *ngIf="(slideViewerFacade.annotationLabel(annotation.label) | async) as label">
                        {{ label.text }}
                      </span>
                    </button>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
