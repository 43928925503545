import paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { Element } from './element';
import { Marker } from './marker/marker';
import { Freeform } from './freeform/freeform';

/**
 * Marker Element
 */
export class MarkerElement<T extends ImageViewerModels.ElementModel, M extends Marker | Freeform> extends Element<T> {
  /**
   * Constructor
   *
   * @param model
   * @param _marker
   */
  constructor(model: T,
              protected _marker: M) {
    super(model);
  }

  /**
   * Round Corners
   *
   * @param path
   * @param radius
   * @param excludes
   * @protected
   */
  protected _roundCorners(path, radius, excludes?) {
    const segments = path.segments.slice(0);
    path.removeSegments();
    for (let i = 0, l = segments.length; i < l; i++) {
      const curPoint = segments[i].point,
        nextPoint = segments[i + 1 === l ? 0 : i + 1].point,
        prevPoint = segments[i - 1 < 0 ? segments.length - 1 : i - 1].point,
        nextDelta = curPoint.subtract(nextPoint),
        prevDelta = curPoint.subtract(prevPoint);
      nextDelta.length = radius;
      prevDelta.length = radius;
      if (excludes && excludes.indexOf(i) === -1) {
        path.add(
          new paper.Segment(
            curPoint.subtract(prevDelta),
            null,
            prevDelta.divide(2)
          )
        );
        path.add(
          new paper.Segment(
            curPoint.subtract(nextDelta),
            nextDelta.divide(2),
            null
          )
        );
      } else {
        path.add(curPoint);
      }
    }
    path.closed = true;
    return path;
  }

  /**
   * Resize
   *
   * @param w: number
   * @param h: numebr
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public resize(w: number, h: number) {
  }

  /**
   * Arrange
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public arrange() {
  }

  /**
   * Draw
   *
   * @param a
   * @param b
   * @param c
   * @protected
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected draw(a?: any, b?: any, c?: any) {
  }

  /**
   * Getter marker
   */
  public get marker(): M {
    return this._marker;
  }
}
