import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Settings, SETTINGS } from './settings.model';
import { Store } from '@ngxs/store';
import { WsState } from '@medsurf/state';
import { SendWebSocketMessage } from '@ngxs/websocket-plugin';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UpdateToken, AuthActionTypes, ErrorActionTypes, Logout, AddAlert } from '@medsurf/actions';
import { Router } from '@angular/router';
import { AlertType } from '@medsurf/models';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
  /**
   * Constructor
   * @param store: Store
   * @param http: HttpClient
   * @param settings: Settings
   */
  constructor(private store: Store,
              private http: HttpClient,
              private router: Router,
              @Inject(SETTINGS) private settings: Settings) {
  }

  /**
   * Send message
   * @param token: string | undefined
   * @param data: any
   */
  public sendMessage(token: string | undefined, data: any) {
    // Ensure type is present
    data = Object.assign({type: data.constructor.type}, data);

    // Add token when available
    if (token) {
      data = Object.assign({token}, data);
    }

    // Switch between ws and http request
    if (this.store.selectSnapshot(WsState.isConnected)) {
      this.sendWsMessage(data);
    } else {
      return this.sendHttpMessage(data).pipe(
        tap((result: any) => {
          setTimeout(() => {
            this.store.dispatch(result);
            if ([AuthActionTypes.LoginSuccess, AuthActionTypes.LogoutSuccess, AuthActionTypes.MeSuccess, AuthActionTypes.UpdateTokenSuccess,
              ErrorActionTypes.AuthError, ErrorActionTypes.UserError].indexOf(result.type) === -1) {
              // Update current token -> increase ttl
              this.store.dispatch(new UpdateToken());
            }
          });
        }),
        catchError((error: HttpErrorResponse) => {
          if (error?.status === 401) {
            this.store.dispatch(new Logout());
          } else if (error?.status === 404) {
            this.router.navigate(['404'])
          } else {
            const message = error?.error?.name === 'MedsurfError' ? error.error.errorCode : error.message;
            this.store.dispatch(new AddAlert({
              code: error.status,
              message,
              dismissable: true,
              type: AlertType.ERROR
            }))
          }
          return '';
        })
      );
    }
  }

  /**
   * Send ws message
   * @param data: any
   */
  protected sendWsMessage(data: any): void {
    this.store.dispatch(new SendWebSocketMessage(data));
    if ([AuthActionTypes.LoginRequest, AuthActionTypes.LogoutRequest, AuthActionTypes.MeRequest, AuthActionTypes.UpdateTokenRequest,
      ErrorActionTypes.AuthError, ErrorActionTypes.UserError].indexOf(data.type) === -1) {
      // Update current token -> increase ttl
      this.store.dispatch(new UpdateToken());
    }
  }

  /**
   * Send http message
   * @param data: any
   */
  protected sendHttpMessage(data: any): Observable<any> {
    return this.http.post<any>(this.settings.endpointUrl + 'v1/httpws', data);
  }
}
