import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NavigationControlFacade, SlideViewerFacade, TrainingViewerFacade } from '@medsurf/flat-facades';
import { FeedbackControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-training-slider',
  templateUrl: './training-slider.component.html',
  styleUrls: ['./training-slider.component.scss']
})
export class TrainingSliderComponent implements OnDestroy {
  /**
   * Members
   */
  private _destroyed$ = new Subject<boolean>();

  /**
   * Constructor
   *
   * @param trainingViewerFacade: TrainingViewerFacade
   * @param slideViewerFacade: SlideViewerFacade
   * @param feedbackControlService: FeedbackControlService
   * @param route: ActivatedRoute
   */
  constructor(public trainingViewerFacade: TrainingViewerFacade,
              public slideViewerFacade: SlideViewerFacade,
              public feedbackControlService: FeedbackControlService,
              public navigationControlFacade: NavigationControlFacade,
              public route: ActivatedRoute) {
    this.trainingViewerFacade.requestTrainingNodesBySelectedSlides();
    // Check If There Are Any Slide Results Set; Otherwise Return to The Start
    this.trainingViewerFacade.isTrainingSetupCorrectly$.pipe(takeUntil(this._destroyed$)).subscribe((isTrainingSetupCorrectly) => {
      if (isTrainingSetupCorrectly === false) {
        this.trainingViewerFacade.trainingSetupIncorrectlyUrl$.pipe(take(1)).subscribe(url => this.navigationControlFacade.requestNavigate([url]));
      }
    })
    // Load Next Slide Data
    this.trainingViewerFacade.nextSlideNode$.pipe(takeUntil(this._destroyed$)).subscribe((slide) => {
      if (slide && slide.page) {
        this.slideViewerFacade.requestGetSlideDataByPageId(slide.page as unknown as string);
      }
    });
    // Load Previous Slide Data
    this.trainingViewerFacade.previousSlideNode$.pipe(takeUntil(this._destroyed$)).subscribe((slide) => {
      if (slide && slide.page) {
        this.slideViewerFacade.requestGetSlideDataByPageId(slide.page as unknown as string);
      }
    });
  }

  /**
   * Ng On Destroy
   */
  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
