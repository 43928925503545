import { Component } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { BaseQuestionComponent } from '../base-question.component';

/**
 * TODO's:
 * - Matomo Track will only trigger when we check the form -> moved to state
 */
@Component({
  selector: 'medsurf-quiz-k-prim-question',
  templateUrl: './k-prim-question.component.html',
  styleUrls: ['./k-prim-question.component.scss']
})
export class KPrimQuestionComponent extends BaseQuestionComponent<FlatModels.QuestionEntityModels.KprimeQuestion, FlatModels.TrainingViewerModels.KPrimQuestionResult> {
  /**
   * Is Correct
   */
  public isCorrect(): boolean {
    return this.question.solutions.join(':') === this.trainingResult.answer.join(':');
  }

  /**
   * Pass Required
   */
  public passRequired(): boolean {
    if (this.question.required) {
      return this.trainingResult.answer.every(a => a !== '');
    }
    return true;
  }

  /**
   * Pass Item Required
   *
   * @param index: number
   */
  public passItemRequired(index: number): boolean {
    if (this.question.required) {
      return this.trainingResult.answer[index] === '';
    }
    return true;
  }

  /**
   * On Click
   *
   * @param index: number
   * @param value: string
   */
  public onClick(index: number, value: string) {
    if (!this.trainingResult.showFeedback) {
      this.trainingResult.answer[index] = value;
      this.trainingViewerFacade.requestUpdateSlideResult(this.trainingResult);
    }
  }
}
