import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { NodeEntityActions, TreeControlActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import * as FlatStates from '../../internal';

/**
 * Node Entity Token
 */
export const NODE_ENTITY_TOKEN = new StateToken<NodeEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.NODE);

/**
 * Node Entity State Model
 */
export type NodeEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.NodeEntityModels.NodeEntityType>;

/**
 * Node Entity State
 */
@State<NodeEntityStateModel>({
  name: NODE_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class NodeEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: NodeEntityStateModel
   */
  @Selector([NodeEntityState])
  public static typedEntities$(state: NodeEntityStateModel): { [id: string]: FlatModels.NodeEntityModels.NodeEntityType } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.NodeEntityModels.NodeEntityType }
   */
  @Selector([NodeEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.NodeEntityModels.NodeEntityType }): FlatModels.NodeEntityModels.NodeEntityType[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.NodeEntityModels.NodeEntityType }
   */
  @Selector([NodeEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.NodeEntityModels.NodeEntityType }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   */
  @Selector([NodeEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.NodeEntityModels.NodeEntityType[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  /**
   * Lazy Selector entitiesByParentId$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   */
  @Selector([NodeEntityState.entities$])
  public static entitiesByParentId$(entities: FlatModels.NodeEntityModels.NodeEntityType[]) {
    return (parentId: string | null) => {
      return entities ? entities.filter(e => e.parentId === parentId) : [];
    };
  }

  /**
   * Selector isTraining$
   *
   * @param slugs: NavigationControlModels.PathModel | null
   */
  @Selector([FlatStates.NavigationControlState.slugs$])
  public static isTraining$(slugs: FlatModels.NavigationControlModels.PathModel | null) {
    return slugs && slugs.type === FlatModels.NavigationControlModels.PathModelType.TRAINING;
  }

  /**
   * Selector isTrainingResult$
   *
   * @param subjectSlug: string | null
   */
  @Selector([FlatStates.NavigationControlState.blockSlug$])
  public static isTrainingResult$(subjectSlug: string | null) {
    return subjectSlug && subjectSlug === 'results' || false;
  }


  /**
   * Selector currentSelectedElearningNode$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentElearningTree: FlatModels.TreeControlModels.TreeElement | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentElearningTree$
  ])
  public static currentSelectedElearningNode$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                              currentElearningTree: FlatModels.TreeControlModels.TreeElement | null) {
    return currentElearningTree && Array.isArray(entities) && entities.find(e => e.id === currentElearningTree.id) || null;
  }

  /**
   * Selector currentSelectedSubjectNodes$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentElearningTree: FlatModels.TreeControlModels.TreeElement | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentElearningTree$
  ])
  public static currentSelectedSubjectNodes$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                             currentElearningTree: FlatModels.TreeControlModels.TreeElement | null) {
    return currentElearningTree && Array.isArray(entities) && entities.filter(e => e.parentId === currentElearningTree.id) || [];
  }

  /**
   * Selector currentSelectedSubjectNode$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentSubjectTree: FlatModels.TreeControlModels.TreeElement | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentSubjectTree$
  ])
  public static currentSelectedSubjectNode$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                            currentSubjectTree: FlatModels.TreeControlModels.TreeElement | null) {
    return currentSubjectTree && Array.isArray(entities) && entities.find(e => e.id === currentSubjectTree.id) || null;
  }

  /**
   * Selector currentSelectedChapterNodes$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentSubjectTree: FlatModels.TreeControlModels.TreeElement | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentSubjectTree$
  ])
  public static currentSelectedChapterNodes$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                             currentSubjectTree: FlatModels.TreeControlModels.TreeElement | null) {
    return currentSubjectTree && Array.isArray(entities) && entities.filter(e => e.parentId === currentSubjectTree.id) || [];
  }

  /**
   * Selector currentSelectedChapterNode$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentChapterTree: FlatModels.TreeControlModels.TreeElement | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentChapterTree$
  ])
  public static currentSelectedChapterNode$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                            currentChapterTree: FlatModels.TreeControlModels.TreeElement | null) {
    return currentChapterTree && Array.isArray(entities) && entities.find(e => e.id === currentChapterTree.id) || null;
  }

  /**
   * Selector currentSelectedBlockNodes$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentChapterTree: FlatModels.TreeControlModels.TreeElement | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentChapterTree$
  ])
  public static currentSelectedBlockNodes$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                           currentChapterTree: FlatModels.TreeControlModels.TreeElement | null) {
    return currentChapterTree && Array.isArray(entities) && entities.filter(e => e.parentId === currentChapterTree.id) || [];
  }

  /**
   * Selector currentSelectedBlockNode$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentBlockTree: FlatModels.TreeControlModels.TreeElement | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentBlockTree$
  ])
  public static currentSelectedBlockNode$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                          currentBlockTree: FlatModels.TreeControlModels.TreeElement | null) {
    return currentBlockTree && Array.isArray(entities) && entities.find(e => e.id === currentBlockTree.id) || null;
  }

  /**
   * Selector currentSelectedSlideNodes$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentBlockTree: FlatModels.TreeControlModels.TreeElement | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentBlockTree$
  ])
  public static currentSelectedSlideNodes$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                           currentBlockTree: FlatModels.TreeControlModels.TreeElement | null) {
    return currentBlockTree && Array.isArray(entities) && entities.filter(e => e.parentId === currentBlockTree.id) || [];
  }

  /**
   * Selector currentSelectedSlideNode$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentSlideTree: FlatModels.TreeControlModels.TreeElement | null
   * @param slideIdSlug: string | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentSlideTree$,
    FlatStates.NavigationControlState.slideIdSlug$,
  ])
  public static currentSelectedSlideNode$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                          currentSlideTree: FlatModels.TreeControlModels.TreeElement | null,
                                          slideIdSlug: string | null) {
    if (Array.isArray(entities)) {
      if (currentSlideTree) {
        return entities.find(e => e.id === currentSlideTree.id) || null
      } else if (slideIdSlug) {
        return entities.find(e => e.id === slideIdSlug) || null
      }
    }
    return null;
  }

  /**
   * Selector currentSelectedNode$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param tree: FlatModels.TreeControlModels.TreeElement | null
   */
  @Selector([
    NodeEntityState.entities$,
    FlatStates.TreeControlState.currentSelectedTree$
  ])
  public static currentSelectedNode$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                     tree: FlatModels.TreeControlModels.TreeElement | null) {
    return tree && Array.isArray(entities) && entities.find(e => e.id === tree.id) || null;
  }

  /**
   * Selector currentSelectedNodes$
   *
   * @param elearningNode: FlatModels.NodeEntityModels.NodeEntityType
   * @param subjectNode: FlatModels.NodeEntityModels.NodeEntityType
   * @param chapterNode: FlatModels.NodeEntityModels.NodeEntityType
   * @param blockNode: FlatModels.NodeEntityModels.NodeEntityType
   * @param slideNode: FlatModels.NodeEntityModels.NodeEntityType
   */
  @Selector([
    NodeEntityState.currentSelectedElearningNode$,
    NodeEntityState.currentSelectedSubjectNode$,
    NodeEntityState.currentSelectedChapterNode$,
    NodeEntityState.currentSelectedBlockNode$,
    NodeEntityState.currentSelectedSlideNode$,
  ])
  public static currentSelectedNodes$(
    elearningNode: FlatModels.NodeEntityModels.NodeEntityType,
    subjectNode: FlatModels.NodeEntityModels.NodeEntityType,
    chapterNode: FlatModels.NodeEntityModels.NodeEntityType,
    blockNode: FlatModels.NodeEntityModels.NodeEntityType,
    slideNode: FlatModels.NodeEntityModels.NodeEntityType
  ) {
    const nodes: FlatModels.NodeEntityModels.NodeEntityType[] = [];
    for (const node of [elearningNode, subjectNode, chapterNode, blockNode, slideNode]) {
      if (!node) {
        break;
      }
      nodes.push(node);
    }
    return nodes;
  }

  /**
   * Selector currentSelectedCaseStartNode$
   *
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param block: FlatModels.NodeEntityModels.NodeEntityType | null
   */
  @Selector([
    NodeEntityState.entities$,
    NodeEntityState.currentSelectedBlockNode$
  ])
  public static currentSelectedCaseStartNode$(entities: FlatModels.NodeEntityModels.NodeEntityType[],
                                              block: FlatModels.NodeEntityModels.NodeEntityType | null) {
    return block && block.children && block.children.length > 0 && Array.isArray(entities)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      && entities.find(e => e.id === block.children[0] as unknown as string) || null;
  }

  /**
   * Selector currentSelectedSettings$
   *
   * @param nodes: FlatModels.NodeEntityModels.NodeEntityType[]
   */
  @Selector([
    NodeEntityState.currentSelectedNodes$
  ])
  public static currentSelectedSettings$(
    nodes: FlatModels.NodeEntityModels.NodeEntityType[]
  ) {
    return nodes.reduce((settings, node) => {
      return Object.assign({}, settings, node?.settings || {})
    }, FlatModels.NodeEntityModels.populateNodeSettings({}));
  }

  /**
   * Selector currentSelectedCaseStartUrl$
   *
   * @param caseStartNode: FlatModels.NodeEntityModels.NodeEntityType | null
   * @param blockUrl: string | null
   */
  @Selector([
    NodeEntityState.currentSelectedCaseStartNode$,
    FlatStates.NavigationControlState.blockUrl$
  ])
  public static currentSelectedCaseStartUrl$(caseStartNode: FlatModels.NodeEntityModels.NodeEntityType | null,
                                             blockUrl: string | null) {
    return caseStartNode && blockUrl && blockUrl + '/' + caseStartNode.route || null;
  }

  /**
   * Dynamic Selector nodeById$
   *
   * @param id: string | null
   */
  public static nodeById$(id: string | null) {
    return createSelector([NodeEntityState.entities$], (entities: FlatModels.NodeEntityModels.NodeEntityType[]) => {
      return entities ? entities.find(e => e.id === id) : null;
    });
  }

  /**
   * Dynamic Selector nodesByParentId$
   *
   * @param parentId: string | null
   */
  public static nodesByParentId$(parentId: string | null) {
    return createSelector([NodeEntityState.entities$], (entities: FlatModels.NodeEntityModels.NodeEntityType[]) => {
      return entities ? entities.filter(e => e.parentId === parentId) : [];
    });
  }

  /**
   * Dynamic Selector isNodeLocked$
   * @param user
   */
  @Selector([FlatStates.AuthControlState.user$])
  public static isNodeLocked$(user: FlatModels.UserEntityModels.User) {
    return (node: FlatModels.NodeEntityModels.Node, parentRestricted: boolean) => {
      if (node.restricted) {
        return !(user && ((user.role === FlatModels.UserEntityModels.Role.ADMIN) || (node.shares && node.shares.some((id) => id as unknown as string  === user.id))));
      }
      return parentRestricted;
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<NodeEntityStateModel>
   * @param patchState: StateContext<NodeEntityStateModel>
   * @param dispatch: StateContext<NodeEntityStateModel>
   * @param entityQueryResult: NodeEntityActions.GetEntitySuccess
   */
  @Action(NodeEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState, dispatch}: StateContext<NodeEntityStateModel>,
                          {entityQueryResult}: NodeEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
    dispatch(new TreeControlActions.BuildTreeFromNodes([entityQueryResult]));
  }

  /**
   * Get Root Entity Success
   *
   * @param getState: StateContext<NodeEntityStateModel>
   * @param patchState: StateContext<NodeEntityStateModel>
   * @param dispatch: StateContext<NodeEntityStateModel>
   * @param entityQueryResult: NodeEntityActions.GetRootEntitySuccess
   */
  @Action(NodeEntityActions.GetRootEntitySuccess)
  public getRootEntitySuccess({getState, patchState, dispatch}: StateContext<NodeEntityStateModel>,
                              {entityQueryResult}: NodeEntityActions.GetRootEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
    dispatch(new TreeControlActions.BuildTreeFromNodes([entityQueryResult]));
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<NodeEntityStateModel>
   * @param patchState: StateContext<NodeEntityStateModel>
   * @param dispatch: StateContext<NodeEntityStateModel>
   * @param entityQueryResults: NodeEntityActions.GetEntitiesSuccess
   */
  @Action(NodeEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState, dispatch}: StateContext<NodeEntityStateModel>,
                            {entityQueryResults}: NodeEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
    dispatch(new TreeControlActions.BuildTreeFromNodes(entityQueryResults));
  }

  /**
   * Get Root Entities Success
   *
   * @param getState: StateContext<NodeEntityStateModel>
   * @param patchState: StateContext<NodeEntityStateModel>
   * @param dispatch: StateContext<NodeEntityStateModel>
   * @param entityQueryResults: NodeEntityActions.GetEntitiesSuccess
   */
  @Action(NodeEntityActions.GetRootEntitiesSuccess)
  public getRootEntitiesSuccess({getState, patchState, dispatch}: StateContext<NodeEntityStateModel>,
                                {entityQueryResults}: NodeEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
    dispatch(new TreeControlActions.BuildTreeFromNodes(entityQueryResults));
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<NodeEntityStateModel>
   * @param patchState: StateContext<NodeEntityStateModel>
   * @param entity: NodeEntityActions.AddEntity
   */
  @Action(NodeEntityActions.AddEntity)
  @Undoable(NodeEntityActions.AddEntity, NODE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<NodeEntityStateModel>,
                   {entity}: NodeEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<NodeEntityStateModel>
   * @param patchState: StateContext<NodeEntityStateModel>
   * @param entity: NodeEntityActions.UpdateEntity
   */
  @Action(NodeEntityActions.UpdateEntity)
  @Undoable(NodeEntityActions.UpdateEntity, NODE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<NodeEntityStateModel>,
                      {entity}: NodeEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<NodeEntityStateModel>
   * @param patchState: StateContext<NodeEntityStateModel>
   * @param entity: NodeEntityActions.RemoveEntity
   */
  @Action(NodeEntityActions.RemoveEntity)
  @Undoable(NodeEntityActions.RemoveEntity, NODE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<NodeEntityStateModel>,
                      {entity}: NodeEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
