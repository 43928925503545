import { GroupEntityModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetEntitySuccess = '[STORE | ENTITY | ANNOTATION_GROUP] Get Entity Success',
  GetEntitiesSuccess = '[STORE | ENTITY | ANNOTATION_GROUP] Get Entities Success',
  AddEntity = '[STORE | ENTITY | ANNOTATION_GROUP] Add Entity',
  UpdateEntity = '[STORE | ENTITY | ANNOTATION_GROUP] Update Entity',
  RemoveEntity = '[STORE | ENTITY | ANNOTATION_GROUP] Remove Entity',
  DebounceSetGroupId = '[STORE | ENTITY | ANNOTATION_GROUP] Debounce Set Group Id',
  SetGroupId = '[STORE | ENTITY | ANNOTATION_GROUP] Set Group Id',
  DebounceSetAreaGroupId = '[STORE | ENTITY | ANNOTATION_GROUP] Debounce Set Area Group Id',
  SetAreaGroupId = '[STORE | ENTITY | ANNOTATION_GROUP] Set Area Group Id'
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Entity Success
 */
export class GetEntitySuccess {
  public static readonly type = ActionTypes.GetEntitySuccess;

  public constructor(public entityQueryResult: GroupEntityModels.GroupEntityQueryResult) {
  }
}

/**
 * Get Entities Success
 */
export class GetEntitiesSuccess {
  public static readonly type = ActionTypes.GetEntitiesSuccess;

  public constructor(public entityQueryResults: GroupEntityModels.GroupEntityQueryResult[]) {
  }
}

/**
 * Add Entity
 */
export class AddEntity {
  public static readonly type = ActionTypes.AddEntity;

  public constructor(public entity: GroupEntityModels.Group) {
  }
}

/**
 * Update Entity
 */
export class UpdateEntity {
  public static readonly type = ActionTypes.UpdateEntity;

  public constructor(public entity: GroupEntityModels.Group) {
  }
}

/**
 * Remove Entity
 */
export class RemoveEntity {
  public static readonly type = ActionTypes.RemoveEntity;

  public constructor(public entity: GroupEntityModels.Group) {
  }
}

/**
 * Debounce Set GroupId
 */
export class DebounceSetGroupId {
  public static readonly type = ActionTypes.DebounceSetGroupId;

  public constructor(public groupId: string | null) {
  }
}

/**
 * Set GroupId
 */
export class SetGroupId {
  public static readonly type = ActionTypes.SetGroupId;

  public constructor(public groupId: string | null) {
  }
}

/**
 * Debounce Set AreaGroupId
 */
export class DebounceSetAreaGroupId {
  public static readonly type = ActionTypes.DebounceSetAreaGroupId;

  public constructor(public areaGroupId: string | null) {
  }
}

/**
 * Set AreaGroupId
 */
export class SetAreaGroupId {
  public static readonly type = ActionTypes.SetAreaGroupId;

  public constructor(public areaGroupId: string | null) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>