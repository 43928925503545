import { Marker } from './marker';

/**
 * Text
 */
export class Text extends Marker {
  /**
   * Constructor
   *
   * @param model
   * @param format
   * @param localPaper
   * @param mainLayer
   * @param canvasDimensions
   * @param imageScale
   * @param imageOffset
   */
  constructor (model,
               format,
               localPaper,
               mainLayer,
               canvasDimensions,
               imageScale?,
               imageOffset?) {
    super(model, format, localPaper, mainLayer, canvasDimensions, imageScale, imageOffset);
  }
}
