// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetApiVersion = '[STORE | CONTROL | GENERAL] Get Api Version',
  GetApiVersionSuccess = '[STORE | CONTROL | GENERAL] Get Api Version Success',

  /**
   * Request
   */
  GetApiVersionRequest = '[REQUEST | CONTROL | GENERAL] Get Api Version'
}
  
// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Api Version
 */
export class GetApiVersion {
  public static readonly type = ActionTypes.GetApiVersion;
}

/**
 * Get Api Version Success
 */
export class GetApiVersionSuccess {
  public static readonly type = ActionTypes.GetApiVersionSuccess;

  public constructor(public version: string) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Api Version Request
 */
export class GetApiVersionRequest {
  public static readonly type = ActionTypes.GetApiVersionRequest;
}

// </editor-fold>
