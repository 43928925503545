import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { EntityControlModels } from '@medsurf/flat-models';
import * as EntityActions from '@medsurf/flat-actions';

/**
 * Entity Control Token
 */
export const ENTITY_CONTROL_TOKEN = new StateToken<EntityControlStateModel>('entityControl');

/**
 * Entity Control State Model
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EntityControlStateModel {
}

/**
 * Entity Mappings
 */
export const entityMappings = new Map<string, any>([
  // Entities
  [EntityControlModels.EntityQueryTypes.ANNOTATION, EntityActions.AnnotationEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.ANNOTATION_GROUP, EntityActions.AnnotationGroupEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.ANNOTATION_LABEL, EntityActions.AnnotationLabelEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.ANNOTATION_SOURCE, EntityActions.AnnotationSourceEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.ANNOTATION_TARGET, EntityActions.AnnotationTargetEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.CHOICE, EntityActions.ChoiceEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.COPYRIGHT, EntityActions.CopyrightEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.FREE_FORM_STYLE, EntityActions.FreeFormStyleEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.IMAGE, EntityActions.ImageEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.KEYMAP_COLUMN, EntityActions.KeymapColumnEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.KEYMAP_LABEL, EntityActions.KeymapLabelEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.KEYMAP_STYLE, EntityActions.KeymapStyleEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.LAYER, EntityActions.SlideLayerEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.MEDIA, EntityActions.MediaEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.METADATA, EntityActions.MetadataEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.MODALITY, EntityActions.ModalityEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.NODE, EntityActions.NodeEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.ROOT_NODE, EntityActions.NodeEntityActions.GetRootEntitySuccess],
  [EntityControlModels.EntityQueryTypes.NOTE, EntityActions.NoteEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.PAGE, EntityActions.PageEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.POINT_OF_INTEREST, EntityActions.PointOfInterestEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.QUESTION, EntityActions.QuestionEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.STAIN, EntityActions.StainEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.USER, EntityActions.UserEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.VOCABULARY_GROUP, EntityActions.VocabularyGroupEntityActions.GetEntitySuccess],
  [EntityControlModels.EntityQueryTypes.VOCABULARY_WORD, EntityActions.VocabularyWordEntityActions.GetEntitySuccess],
  // Special
  [EntityControlModels.EntityQueryTypes.SLIDE_VIEWER_PAGE, EntityActions.SlideControlActions.GetSlideEntitySuccess],
  [EntityControlModels.EntityQueryTypes.TRAINING_VIEWER_SLIDE, EntityActions.TrainingViewerActions.GetSlideEntitySuccess],
  [EntityControlModels.EntityQueryTypes.TRAINING_VIEWER_BLOCK, EntityActions.TrainingViewerActions.GetBlockEntitySuccess],
  [EntityControlModels.EntityQueryTypes.FIND_SLIDES, EntityActions.SearchControlActions.FindSlideSuccess],
  [EntityControlModels.EntityQueryTypes.FIND_MARKERS, EntityActions.SearchControlActions.FindMarkerSuccess],
]);

/**
 * Entities Mappings
 */
export const entitiesMappings = new Map<string, any>([
  // Entities
  [EntityControlModels.EntityQueryTypes.ANNOTATION, EntityActions.AnnotationEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.ANNOTATION_GROUP, EntityActions.AnnotationGroupEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.ANNOTATION_LABEL, EntityActions.AnnotationLabelEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.ANNOTATION_SOURCE, EntityActions.AnnotationSourceEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.ANNOTATION_TARGET, EntityActions.AnnotationTargetEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.CHOICE, EntityActions.ChoiceEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.COPYRIGHT, EntityActions.CopyrightEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.FREE_FORM_STYLE, EntityActions.FreeFormStyleEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.IMAGE, EntityActions.ImageEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.KEYMAP_COLUMN, EntityActions.KeymapColumnEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.KEYMAP_LABEL, EntityActions.KeymapLabelEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.KEYMAP_STYLE, EntityActions.KeymapStyleEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.LAYER, EntityActions.SlideLayerEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.MEDIA, EntityActions.MediaEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.METADATA, EntityActions.MetadataEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.MODALITY, EntityActions.ModalityEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.NODE, EntityActions.NodeEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.ROOT_NODE, EntityActions.NodeEntityActions.GetRootEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.NOTE, EntityActions.NoteEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.PAGE, EntityActions.PageEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.POINT_OF_INTEREST, EntityActions.PointOfInterestEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.QUESTION, EntityActions.QuestionEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.STAIN, EntityActions.StainEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.USER, EntityActions.UserEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.VOCABULARY_GROUP, EntityActions.VocabularyGroupEntityActions.GetEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.VOCABULARY_WORD, EntityActions.VocabularyWordEntityActions.GetEntitiesSuccess],
  // Special
  [EntityControlModels.EntityQueryTypes.SLIDE_VIEWER_PAGE, EntityActions.SlideControlActions.GetSlideEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.TRAINING_VIEWER_SLIDE, EntityActions.TrainingViewerActions.GetSlideEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.TRAINING_VIEWER_BLOCK, EntityActions.TrainingViewerActions.GetBlockEntitiesSuccess],
  [EntityControlModels.EntityQueryTypes.FIND_SLIDES, EntityActions.SearchControlActions.FindSlidesSuccess],
  [EntityControlModels.EntityQueryTypes.FIND_MARKERS, EntityActions.SearchControlActions.FindMarkersSuccess],
]);

/**
 * Entity Control State
 */
@State<EntityControlStateModel>({
  name: ENTITY_CONTROL_TOKEN,
  defaults: {}
})
@Injectable()
export class EntityControlState {
  /**
   * Constructor
   *
   * @param store: Store
   */
  public constructor(protected store: Store) {
  }

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param state: StateContext<EntityControlStateModel>
   * @param entityQueryResult: EntityControlModels.EntityQueryResult<any>
   */
  @Action(EntityActions.EntityControlActions.GetEntitySuccess)
  public getEntitySuccess(state: StateContext<EntityControlStateModel>,
                          {entityQueryResult}: EntityActions.EntityControlActions.GetEntitySuccess) {
    if (entityMappings.has(entityQueryResult.type)) {
      const entityMapping = (entityMappings.get(entityQueryResult.type));
      this.store.dispatch(new entityMapping(entityQueryResult));
    }
  }

  /**
   * Get Entities Success
   *
   * @param state: StateContext<EntityControlStateModel>
   * @param entityQueryResults: EntityControlModels.EntityQueryResult<any>[]
   */
  @Action(EntityActions.EntityControlActions.GetEntitiesSuccess)
  public getEntitiesSuccess(state: StateContext<EntityControlStateModel>,
                            {entityQueryResults}: EntityActions.EntityControlActions.GetEntitiesSuccess) {
    // Format the results
    const entityTypesMap = new Map<string, [EntityControlModels.EntityQueryResult<any>]>();
    for (const entityQueryResult of entityQueryResults) {
      if (entityTypesMap.has(entityQueryResult.type)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        entityTypesMap.set(entityQueryResult.type, [...entityTypesMap.get(entityQueryResult.type), entityQueryResult]);
      } else {
        entityTypesMap.set(entityQueryResult.type, [entityQueryResult]);
      }
    }

    // Iterate through results
    for (const [type, entityQueryResults] of entityTypesMap) {
      const entityMapping = (entitiesMappings.get(type));
      this.store.dispatch(new entityMapping(entityQueryResults));
    }
  }
  //</editor-fold>
}
