import { Component } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { BaseQuestionComponent } from '../base-question.component';

/**
 * TODO's:
 * - Matomo Track will only trigger when we check the form -> moved to state
 */
@Component({
  selector: 'medsurf-quiz-mapping-question',
  templateUrl: './mapping-question.component.html',
  styleUrls: ['./mapping-question.component.scss']
})
export class MappingQuestionComponent extends BaseQuestionComponent<FlatModels.QuestionEntityModels.MappingQuestion, FlatModels.TrainingViewerModels.MappingQuestionResult> {
  /**
   * Is Correct
   */
  public isCorrect(): boolean {
    return this.question.solutions.join(':') === this.trainingResult.answer.join(':');
  }

  /**
   * Pass Required
   */
  public passRequired(): boolean {
    if (this.question.required) {
      return this.trainingResult.answer.every(a => a !== '');
    }
    return true;
  }

  /**
   * Pass Item Required
   *
   * @param index: number
   */
  public passItemRequired(index: number): boolean {
    if (this.question.required) {
      return this.trainingResult.answer[index] === '';
    }
    return true;
  }

  /**
   * Get Choice By Solution
   *
   * @param choices: FlatModels.ChoiceEntityModels.Choice[]
   * @param solution: string
   */
  public getChoiceBySolution(choices: FlatModels.ChoiceEntityModels.Choice[],
                             solution: string): FlatModels.ChoiceEntityModels.Choice {
    return choices.find(c => c.order.toString() === solution);
  }

  /**
   * On Change
   */
  public onChange() {
    this.trainingViewerFacade.requestUpdateSlideResult(this.trainingResult);
  }
}
