import { Component, Input } from '@angular/core';
import { MediaEntityModels } from '@medsurf/flat-models';
import { MediaControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-shared-image-loader',
  templateUrl: './image-loader.shared.component.html',
  styleUrls: ['./image-loader.shared.component.scss']
})
export class ImageLoaderSharedComponent {
  /**
   * Inputs
   */
  @Input() media: MediaEntityModels.Media | undefined = undefined;
  @Input() alt = "image";
  @Input() class = "mdsf-carousel-image w-100 h-100";

  /**
   * Members
   */
  public isLoading: boolean;

  /**
   * Constructor
   *
   * @param mediaControlService: MediaControlService
   */
  public constructor(public mediaControlService: MediaControlService) {
    this.isLoading = true;
  }

  /**
   * Hide Loader
   */
  public hideLoader() {
    this.isLoading = false;
  }
}
