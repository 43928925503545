import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { AnnotationLabelEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * Annotation Label Entity Token
 */
export const ANNOTATION_LABEL_ENTITY_TOKEN = new StateToken<AnnotationLabelEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.ANNOTATION_LABEL);

/**
 * Annotation Label Entity State Model
 */
export type AnnotationLabelEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.AnnotationLabelEntityModels.AnnotationLabel>;

/**
 * Annotation Label Entity State
 */
@State<AnnotationLabelEntityStateModel>({
  name: ANNOTATION_LABEL_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class AnnotationLabelEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: AnnotationLabelEntityStateModel
   */
  @Selector([AnnotationLabelEntityState])
  public static typedEntities$(state: AnnotationLabelEntityStateModel): { [id: string]: FlatModels.AnnotationLabelEntityModels.AnnotationLabel } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.AnnotationLabelEntityModels.AnnotationLabel }
   */
  @Selector([AnnotationLabelEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.AnnotationLabelEntityModels.AnnotationLabel }): FlatModels.AnnotationLabelEntityModels.AnnotationLabel[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.AnnotationLabelEntityModels.AnnotationLabel }
   */
  @Selector([AnnotationLabelEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.AnnotationLabelEntityModels.AnnotationLabel }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.AnnotationLabelEntityModels.AnnotationLabel[]
   */
  @Selector([AnnotationLabelEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.AnnotationLabelEntityModels.AnnotationLabel[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<AnnotationLabelEntityStateModel>
   * @param patchState: StateContext<AnnotationLabelEntityStateModel>
   * @param entityQueryResult: AnnotationLabelEntityActions.GetEntitySuccess
   */
  @Action(AnnotationLabelEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<AnnotationLabelEntityStateModel>,
                          {entityQueryResult}: AnnotationLabelEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<AnnotationLabelEntityStateModel>
   * @param patchState: StateContext<AnnotationLabelEntityStateModel>
   * @param entityQueryResults: AnnotationLabelEntityActions.GetEntitiesSuccess
   */
  @Action(AnnotationLabelEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<AnnotationLabelEntityStateModel>,
                            {entityQueryResults}: AnnotationLabelEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<AnnotationLabelEntityStateModel>
   * @param patchState: StateContext<AnnotationLabelEntityStateModel>
   * @param entity: AnnotationLabelEntityActions.AddEntity
   */
  @Action(AnnotationLabelEntityActions.AddEntity)
  @Undoable(AnnotationLabelEntityActions.AddEntity, ANNOTATION_LABEL_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<AnnotationLabelEntityStateModel>,
                   {entity}: AnnotationLabelEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<AnnotationLabelEntityStateModel>
   * @param patchState: StateContext<AnnotationLabelEntityStateModel>
   * @param entity: AnnotationLabelEntityActions.UpdateEntity
   */
  @Action(AnnotationLabelEntityActions.UpdateEntity)
  @Undoable(AnnotationLabelEntityActions.UpdateEntity, ANNOTATION_LABEL_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<AnnotationLabelEntityStateModel>,
                      {entity}: AnnotationLabelEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<AnnotationLabelEntityStateModel>
   * @param patchState: StateContext<AnnotationLabelEntityStateModel>
   * @param entity: AnnotationLabelEntityActions.RemoveEntity
   */
  @Action(AnnotationLabelEntityActions.RemoveEntity)
  @Undoable(AnnotationLabelEntityActions.RemoveEntity, ANNOTATION_LABEL_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<AnnotationLabelEntityStateModel>,
                      {entity}: AnnotationLabelEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
