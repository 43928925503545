<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="isLoading">
  <ng-container *ngTemplateOutlet="spinner"></ng-container>
</ng-container>

<img [src]="mediaControlService.getUrl(media)"
     [alt]="alt"
     [ngClass]="class"
     [ngStyle]="{visibility: isLoading ? 'hidden' : ''}"
     (load)="hideLoader()"
     (error)="hideLoader()"/>