import * as FlatModels from '../../internal';

/**
 * User Entity Query Result
 */
export type UserEntityQueryResult = FlatModels.EntityControlModels.EntityQueryResult<User>;

/**
 * Role
 */
export enum Role {
  ADMIN = 'admin',
  EDITOR = 'editor',
  AUTHOR = 'author',
  VIEWER = 'viewer'
}

/**
 * User
 */
export interface User {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  lastLogin?: Date;
  password?: string;
  password_confirm?: string;
  resetPasswordToken?: string;
  resetPasswordExpires?: Date;
  role?: Role;
}
