import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { PageControlActions } from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';
import { AuthControlState, NavigationControlState, PageEntityState, } from '../../internal';

/**
 * Page Control Token
 */
export const PAGE_CONTROL_TOKEN = new StateToken<PageControlStateModel>('pageControl');

/**
 * Page Control State Model
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PageControlStateModel {
}

/**
 * Page Control State
 */
@State<PageControlStateModel>({
  name: PAGE_CONTROL_TOKEN,
  defaults: {
  }
})
@Injectable()
export class PageControlState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   */
  public constructor(public store: Store,
                     public messageControlService: MessageControlService) {
  }

  //<editor-fold desc="Actions">

  /**
   * Get Page By Current Route
   */
  @Action(PageControlActions.GetPageByCurrentRoute)
  public getPageByCurrentRoute() {
    const currentSelectedPagePage = this.store.selectSnapshot(PageEntityState.currentSelectedPagePage$);
    if (!currentSelectedPagePage) {
      const pageSlug = this.store.selectSnapshot(NavigationControlState.pageSlug$);
      if (!pageSlug) {
        throw new Error('no_page_slug');
      }
      return this.messageControlService.sendMessage(
        this.store.selectSnapshot(AuthControlState.token$),
        new PageControlActions.GetPageByTitleRequest(pageSlug)
      );
    }
    return;
  }

    /**
   * Get Page By Id
   */
    @Action(PageControlActions.GetPageById)
    public getPageById({}: StateContext<PageControlStateModel>, {id}: PageControlActions.GetPageById ) {
      const currentSelectedPagePage = this.store.selectSnapshot(PageEntityState.currentSelectedPagePage$);

      if (!currentSelectedPagePage) {
        return this.messageControlService.sendMessage(
          this.store.selectSnapshot(AuthControlState.token$),
          new PageControlActions.GetPageByIdRequest(id)
        );
      }
      return;
    }

  //</editor-fold>
}
