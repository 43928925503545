import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MessageService } from '@medsurf/services';
import { Media, MediaType, Node } from '@medsurf/models';
import { AuthState } from './auth.state';
import { GetMedia, GetMediaSuccess, GetMediaRequest, SetSearchQuery, SetTypeFilter, SetPage, SetOrder, SetOrderBy,
  DeleteMediaSuccess, DeleteMedia, DeleteMediaRequest, UpdateMedia, UpdateMediaSuccess, UpdateMediaRequest, AddWatermark,
  AddWatermarkRequest, SetSelectedMedia, SetUploadedByMe, SetUsedInFilter, GenerateSlidePDF, GenerateSlidePDFRequest,
  GenerateChapterPDF, GenerateChapterPDFRequest, AddWatermarkSuccess } from '@medsurf/actions';

/**
 * Media state model
 */
export interface MediaStateModel {
  searchQuery: string;
  typeFilter: MediaType[];
  uploadedByMe: boolean;
  usedInFilter: Node[];
  selectedMedia: Media[];
  initialMedia: Media[];
  orderBy: string;
  order: 'ASC' | 'DESC' | '';
  page: number;
  total: number;
  perPage: number;
  results: Media[];
}

/**
 * Media state
 */
@State<MediaStateModel>({
  name: 'media',
  defaults: {
    searchQuery: '',
    typeFilter: [],
    uploadedByMe: false,
    usedInFilter: [],
    selectedMedia: [],
    initialMedia: [],
    orderBy: '',
    order: '',
    page: 1,
    total: 0,
    perPage: 0,
    results: []
  }
})
@Injectable()
export class MediaState {
  /**
   * Constructor
   * @param store: Store
   * @param messageService: MessageService
   */
  constructor(public store: Store,
              public messageService: MessageService) {
  }

  /**
   * Selector searchString
   * @param state: MediaStateModel
   */
  @Selector()
  public static searchQuery(state: MediaStateModel): string {
    return state.searchQuery;
  }


  /**
   * Selector typeFilter
   * @param state: MediaStateModel
   */
  @Selector()
  public static typeFilter(state: MediaStateModel): MediaType[] {
    return state.typeFilter;
  }

  /**
   * Selector uploadedByMe
   * @param state: MediaStateModel
   */
  @Selector()
  public static uploadedByMe(state: MediaStateModel): boolean {
    return state.uploadedByMe;
  }

  /**
   * Selector usedInFilter
   * @param state: MediaStateModel
   */
   @Selector()
   public static usedInFilter(state: MediaStateModel): Node[] {
     return state.usedInFilter;
   }

  /**
   * Selector selectedMedia
   * @param state: MediaStateModel
   */
  @Selector()
  public static selectedMedia(state: MediaStateModel): Media[] {
    return state.selectedMedia;
  }

  /**
   * Selector order by
   * @param state: MediaStateModel
   */
  @Selector()
  public static orderBy(state: MediaStateModel): string {
    return state.orderBy;
  }

  /**
   * Selector order
   * @param state: MediaStateModel
   */
  @Selector()
  public static order(state: MediaStateModel): string {
    return state.order;
  }

  /**
   * Selector page
   * @param state: MediaStateModel
   */
  @Selector()
  public static page(state: MediaStateModel): number {
    return state.page;
  }

  /**
   * Selector total number of results
   * @param state: MediaStateModel
   */
  @Selector()
  public static total(state: MediaStateModel): number {
    return state.total;
  }

  /**
   * Selector total number of results
   * @param state: MediaStateModel
   */
  @Selector()
  public static perPage(state: MediaStateModel): number {
    return state.perPage;
  }

  /**
   * Selector results
   * @param state: MediaStateModel
   */
  @Selector()
  public static results(state: MediaStateModel): Media[] {
    return state.results;
  }


  /**
   * Get Media
   * @param getState: StateContext<MediaStateModel>
   * @param dispatch: StateContext<MediaStateModel>
   */
  @Action(GetMedia)
  public getMedia({getState}: StateContext<MediaStateModel>) {
    const {searchQuery, typeFilter, usedInFilter, uploadedByMe, initialMedia, page, order, orderBy} = getState();
    return this.messageService.sendMessage(
      this.store.selectSnapshot(AuthState.token),
      new GetMediaRequest(searchQuery, typeFilter, uploadedByMe, usedInFilter, initialMedia, page, order, orderBy)
    );
  }

  /**
   * Get media Sucess
   * @param setState: StateContext<MediaStateModel>
   * @param results: Media[]
   */
  @Action(GetMediaSuccess)
  public getMediaSuccess({setState}: StateContext<MediaStateModel>, {results, total, perPage}: GetMediaSuccess): void {
    setState(
      patch({
        results,
        total,
        perPage
      })
    );
  }

  /**
   * Delete Media
   * @param getState: StateContext<MediaStateModel>
   * @param dispatch: StateContext<MediaStateModel>
   */
  @Action(DeleteMedia)
  public deleteMedia({getState}: StateContext<MediaStateModel>, {media}: DeleteMedia) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new DeleteMediaRequest(media));
  }

  /**
   * Get media Sucess
   * @param setState: StateContext<MediaStateModel>
   * @param results: Media[]
   */
  @Action(DeleteMediaSuccess)
  public deleteMediaSuccess({getState, setState}: StateContext<MediaStateModel>, {mediaIds}: DeleteMediaSuccess): void {
    const {results} = getState();
    const updatedResults = results.filter((item) => {
      return !mediaIds.includes(item.id);
    });
    setState(
      patch({
        results: updatedResults
      })
    );
  }

  /**
   * Update Media
   * @param getState: StateContext<MediaStateModel>
   * @param dispatch: StateContext<MediaStateModel>
   */
  @Action(UpdateMedia)
  public updateMedia({}: StateContext<MediaStateModel>, {media}: UpdateMedia) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new UpdateMediaRequest(media));
  }

  /**
   * Get media Sucess
   * @param setState: StateContext<MediaStateModel>
   * @param results: Media[]
   */
  @Action(UpdateMediaSuccess)
  public updateMediaSuccess({getState, setState}: StateContext<MediaStateModel>, {media}: UpdateMediaSuccess): void {
    const {results} = getState();
    const updatedResults = results.map((item) => {
      return item.id === media.id ? Object.assign(item, media) : item;
    });
    setState(
      patch({
        results: updatedResults
      })
    );
  }

  /**
   * Update slide PDF
   * @param getState: StateContext<MediaStateModel>
   * @param dispatch: StateContext<MediaStateModel>
   */
  @Action(GenerateSlidePDF)
  public generateSlidePDF({}: StateContext<MediaStateModel>, {slide, forAuthor}: GenerateSlidePDF) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GenerateSlidePDFRequest(slide, forAuthor));
  }

  /**
   * Update chapter PDF
   * @param getState: StateContext<MediaStateModel>
   * @param dispatch: StateContext<MediaStateModel>
   */
  @Action(GenerateChapterPDF)
  public generateChapterPDF({}: StateContext<MediaStateModel>, {chapter, forceGeneration, forAuthor}: GenerateChapterPDF) {
     return this.messageService.sendMessage(
       this.store.selectSnapshot(AuthState.token),
       new GenerateChapterPDFRequest(chapter, forceGeneration, forAuthor)
     );
  }

  /**
   * set search query
   * @param setState: StateContext<MediaStateModel>
   * @param searchQuery: String
   */
  @Action(SetSearchQuery)
  public setSearchQuery({setState}: StateContext<MediaStateModel>, {searchQuery}: SetSearchQuery): void {
    setState(
      patch({
        searchQuery
      }));
  }

  /**
   * set type filter
   * @param setState: StateContext<MediaStateModel>
   * @param typeFilter: String
   */
  @Action(SetTypeFilter)
  public setTypeFilter({setState}: StateContext<MediaStateModel>, {typeFilter}: SetTypeFilter): void {
    setState(
      patch({
        typeFilter
      }));
  }

  /**
   * set uploaded by me
   * @param setState: StateContext<MediaStateModel>
   * @param uploadedByMe: boolean
   */
  @Action(SetUploadedByMe)
  public setUploadedByMe({setState}: StateContext<MediaStateModel>, {uploadedByMe}: SetUploadedByMe): void {
    setState(
      patch({
        uploadedByMe
      }));
  }

  /**
   * set used in filter
   * @param setState: StateContext<MediaStateModel>
   * @param usedInFilter: boolean
   */
  @Action(SetUsedInFilter)
  public setUsedInFilter({setState}: StateContext<MediaStateModel>, {usedInFilter}: SetUsedInFilter): void {
    setState(
      patch({
        usedInFilter
      }));
   }

  /**
   * set selected media
   * @param setState: StateContext<MediaStateModel>
   * @param selectedMedia: Media[]
   */
  @Action(SetSelectedMedia)
  public setSelectedMedia({setState}: StateContext<MediaStateModel>, {selectedMedia, initalValue}: SetSelectedMedia): void {
    setState(
      patch({
        selectedMedia
      }));
    if (initalValue) {
      setState(
        patch({
          initialMedia: selectedMedia
        }));
    }
  }

  /**
   * set search query
   * @param setState: StateContext<MediaStateModel>
   * @param searchQuery: String
   */
  @Action(SetPage)
  public setPage({setState}: StateContext<MediaStateModel>, {page}: SetPage): void {
    setState(
      patch({
        page
      }));
  }

  /**
   * set order attribute
   * @param setState: StateContext<MediaStateModel>
   * @param orderBy: String
   */
  @Action(SetOrderBy)
  public setOrderBy({setState}: StateContext<MediaStateModel>, {orderBy}: SetOrderBy): void {
    setState(
      patch({
        orderBy
      }));
  }

  /**
   * set order attribute
   * @param setState: StateContext<MediaStateModel>
   * @param order: String
   */
  @Action(SetOrder)
  public setOrder({setState}: StateContext<MediaStateModel>, {order}: SetOrder): void {
    setState(
      patch({
        order
      }));
  }

  /**
   * Add Watermark
   */
  @Action(AddWatermark)
  public addWatermark({getState, dispatch}: StateContext<MediaStateModel>, {media, position}: AddWatermark) {
    if (!media?.copyrights || !media?.id) {
      return;
    }
    const lines = media.copyrights.map((copyright) => copyright.text);
    const filtredLines = lines.filter(line => !!line) as string[];
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new AddWatermarkRequest(media.id, filtredLines, position));
  }

  /**
   * Add Watermark Success
   */
  @Action(AddWatermarkSuccess)
  public addWatermarkSuccess({setState, getState}: StateContext<MediaStateModel>, {media}: AddWatermarkSuccess) {
    const { results } = getState()
    const index = results.findIndex(m => m.id === media.id);
    if (index !== -1) {
      results[index] = media;
      setState(
        patch({
          results: [...results]
        }));
    }
  }
}
