import { Component } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { BaseQuestionComponent } from '../base-question.component';

/**
 * TODO's:
 * - Matomo Track will only trigger when we check the form -> moved to state
 */
@Component({
  selector: 'medsurf-quiz-single-choice-question',
  templateUrl: './single-choice-question.component.html',
  styleUrls: ['./single-choice-question.component.scss']
})
export class SingleChoiceQuestionComponent extends BaseQuestionComponent<FlatModels.QuestionEntityModels.SingleChoiceQuestion, FlatModels.TrainingViewerModels.SingleChoiceQuestionResult> {
  /**
   * Is Correct
   */
  public isCorrect(): boolean {
    if (this.trainingResult.answer === null) {
      return false;
    }
    return this.question.solution.toString() === this.trainingResult.answer.toString();
  }

  /**
   * Pass Required
   */
  public passRequired(): boolean {
    if (this.question.required) {
      return Number.isInteger(this.trainingResult.answer);
    }
    return true;
  }

  /**
   * Get Choice By Solution
   *
   * @param choices: FlatModels.ChoiceEntityModels.Choice[]
   * @param solution: number
   */
  public getChoiceBySolution(choices: FlatModels.ChoiceEntityModels.Choice[],
                             solution: number): FlatModels.ChoiceEntityModels.Choice {
    return choices.find(c => c.order === solution);
  }

  /**
   * On Click
   *
   * @param choice: FlatModels.ChoiceEntityModels.Choice
   */
  public onClick(choice: FlatModels.ChoiceEntityModels.Choice) {
    if (!this.trainingResult.showFeedback) {
      this.trainingResult.answer = choice.order;
      this.trainingViewerFacade.requestUpdateSlideResult(this.trainingResult);
    }
  }

  /**
   * On Blur
   *
   * @param value: string
   */
  public onBlur(value: string) {
    this.trainingResult.answer = parseInt(value);
    this.trainingViewerFacade.requestUpdateSlideResult(this.trainingResult);
  }
}
