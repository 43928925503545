import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalControlService {
  /**
   * Members
   */
  private _onOpenModal = new EventEmitter();
  private _showModal = false;

  /**
   * Open Modal
   */
  public openModal() {
    this._showModal = true;
    this._onOpenModal.emit();
  }

  /**
   * Close Modal
   */
  public closeModal() {
    this._showModal = false;
  }

  /**
   * Show Modal
   */
  public get showModal() {
    return this._showModal;
  }

  /**
   * On Open Modal
   */
  public get onOpenModal(): EventEmitter<any> {
    return this._onOpenModal;
  }
}