import { Component, Input } from '@angular/core';
import { Group } from '@medsurf/models';

@Component({
  selector: 'medsurf-interactive-area-list',
  templateUrl: './interactive-area.component.html',
  styleUrls: ['./interactive-area.component.scss']
})
export class InteractiveAreaComponent {

  @Input() groups: Group[] = [];

  public expandedGroup: Group | null = null;

  public toggleExpandedGroup(group: Group) {
    if (this.expandedGroup && group?.id === this.expandedGroup?.id) {
      this.expandedGroup = null;
    } else {
      this.expandedGroup = group;
    }
  }
}
