import { v4 as uuid } from 'uuid';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { ErrorControlModels, HistoryControlModels } from '@medsurf/flat-models';
import { HistoryControlActions } from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';
import { AuthControlState } from '../../internal';

/**
 * History Control Token
 */
export const HISTORY_CONTROL_TOKEN = new StateToken<HistoryControlStateModel>('historyControl');

/**
 * History Control State Model
 */
export interface HistoryControlStateModel {
  past: HistoryControlModels.HistorySnapshot[];
  present: HistoryControlModels.HistorySnapshot;
  future: HistoryControlModels.HistorySnapshot[];
}

/**
 * Default History Snapshot
 */
export class DefaultHistorySnapshot implements HistoryControlModels.HistorySnapshot {
    public id: string;
    public locked: boolean;
    public saved: boolean;
    public snapshotItems: HistoryControlModels.HistorySnapshotItem[];

    public constructor() {
      this.id = uuid();
      this.locked = false;
      this.saved = false;
      this.snapshotItems = [];
    }
}

/**
 * History Control State
 */
@State<HistoryControlStateModel>({
  name: HISTORY_CONTROL_TOKEN,
  defaults: {
    past: [],
    present: new DefaultHistorySnapshot(),
    future: [],
  }
})
@Injectable()
export class HistoryControlState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   */
  public constructor(protected store: Store,
                     protected messageControlService: MessageControlService) {
  }

  //<editor-fold desc="Selectors">

  /**
   * Selector pastSnapshot$
   *
   * @param state: HistoryControlStateModel
   */
  @Selector([HistoryControlState])
  public static pastSnapshot$(state: HistoryControlStateModel): HistoryControlModels.HistorySnapshot[] {
    return state.past;
  }

  /**
   * Selector pastSnapshotItems$
   *
   * @param pastSnapshots: HistoryControlModels.HistorySnapshot[]
   */
  @Selector([HistoryControlState.pastSnapshot$])
  public static pastSnapshotItems$(pastSnapshots: HistoryControlModels.HistorySnapshot[]): HistoryControlModels.HistorySnapshotItem[] {
    return pastSnapshots.map((p) => p.snapshotItems).flat();
  }

  /**
   * Selector canUndo$
   *
   * @param pastSnapshots: HistoryControlModels.HistorySnapshot[]
   */
  @Selector([HistoryControlState.pastSnapshot$])
  public static canUndo$(pastSnapshots: HistoryControlModels.HistorySnapshot[]): boolean {
    return pastSnapshots.length > 0;
  }

  /**
   * Selector futureSnapshot$
   *
   * @param state: HistoryControlStateModel
   */
  @Selector([HistoryControlState])
  public static futureSnapshot$(state: HistoryControlStateModel): HistoryControlModels.HistorySnapshot[] {
    return state.future;
  }

  /**
   * Selector futureSnapshotItems$
   *
   * @param futureSnapshots: HistoryControlModels.HistorySnapshot[]
   */
  @Selector([HistoryControlState.futureSnapshot$])
  public static futureSnapshotItems$(futureSnapshots: HistoryControlModels.HistorySnapshot[]): HistoryControlModels.HistorySnapshotItem[] {
    return futureSnapshots.map((p) => p.snapshotItems).flat();
  }

  /**
   * Selector canRedo$
   *
   * @param futureSnapshot: HistoryControlModels.HistorySnapshot[]
   */
  @Selector([HistoryControlState.futureSnapshot$])
  public static canRedo$(futureSnapshot: HistoryControlModels.HistorySnapshot[]): boolean {
    return futureSnapshot.length > 0;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Finish Snapshot
   *
   * @param getState: StateContext<HistoryControlStateModel>
   * @param patchState: StateContext<HistoryControlStateModel>
   */
  @Action(HistoryControlActions.FinishSnapshot)
  public finishSnapshot({getState, patchState}: StateContext<HistoryControlStateModel>) {
    // Get state
    const state = getState();

    // Check for snapshot items
    if (state.present.snapshotItems.length > 0) {
      // Set to locked
      state.present.locked = true;

      // Save Changes
      patchState({
        past: [...state.past, state.present],
        present: new DefaultHistorySnapshot(),
      });
    } else {
      throw new ErrorControlModels.MedsurfError(
        'empty_snapshot',
        ErrorControlModels.ErrorLevel.warning,
        ErrorControlModels.ErrorInstance.AUTHOR,
        "STATES.HistoryControlState.finishSnapshot"
      );
    }
  }

  /**
   * Save Snapshots
   *
   * @param getState: StateContext<HistoryControlStateModel>
   * @param dispatch: StateContext<HistoryControlStateModel>
   */
  @Action(HistoryControlActions.SaveSnapshots)
  public saveSnapshots({getState, dispatch}: StateContext<HistoryControlStateModel>) {
    // Finish current snapshot
    dispatch(new HistoryControlActions.FinishSnapshot()).subscribe(() => {
      // Get state
      const state = getState();

      // Get the modified entities
      const modifiedEntities: HistoryControlModels.SaveSnapshotItem[] = [];
      state.past.forEach((snapshot: HistoryControlModels.HistorySnapshot) => {
        snapshot.snapshotItems.forEach((si) => {
          if (modifiedEntities.findIndex((me) => me.stateToken === si.stateToken && me.entity.id === si.entityId) === -1) {
            // Get entity
            const entityState = this.store.selectSnapshot(si.stateToken);
            const entity = entityState.entities[si.entityId];
            if (!entity) {
              throw new ErrorControlModels.MedsurfError(
                "no_entity_found",
                ErrorControlModels.ErrorLevel.error,
                ErrorControlModels.ErrorInstance.AUTHOR,
                "STATE.HistoryControlState.saveSnapshots"
              );
            }

            // Add entity to modified entities
            modifiedEntities.push({
              stateToken: si.stateToken,
              entity
            });
          }
        });
      });

      // Save entities
      this.messageControlService.sendMessage(
        this.store.selectSnapshot(AuthControlState.token$),
        new HistoryControlActions.SaveSnapshotsRequest(modifiedEntities)
      );
    });
  }
  //</editor-fold>
}
