import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medsurf-quiz-question-header',
  templateUrl: './question-header.component.html',
  styleUrls: ['./question-header.component.scss']
})
export class QuestionHeaderComponent {
  /**
   * Inputs
   */
  @Input() public index: number | null = null;
  @Input() public checkedAndCorrect: boolean;
  @Input() public questionType: string;

  /**
   * Constructor
   *
   * @param translate: TranslateService
   */
  public constructor(public translate: TranslateService) {
  }
}
