import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, map, take } from 'rxjs/operators';
import { filter } from 'rxjs/internal/operators/filter';
import { NavigationControlFacade, NodeSharedFacade, TrainingViewerFacade } from '@medsurf/flat-facades';
import { Observable } from 'rxjs';

@Component({
  selector: 'medsurf-case-start',
  templateUrl: './case-start.component.html',
  styleUrls: ['./case-start.component.scss']
})
export class CaseStartComponent {
  /**
   * Constructor
   *
   * @param trainingViewerFacade: TrainingViewerFacade
   * @param nodeSharedFacade: NodeSharedFacade
   * @param navigationControlFacade: NavigationControlFacade
   * @param router: Router
   */
  constructor(public trainingViewerFacade: TrainingViewerFacade,
              public nodeSharedFacade: NodeSharedFacade,
              public navigationControlFacade: NavigationControlFacade,
              public router: Router) {
    this.trainingViewerFacade.requestReset();
  }

  public redirectToCase(): Observable<boolean> {
    return this.nodeSharedFacade.currentSelectedCaseStartUrl$.pipe(
      filter((caseStartUrl) => caseStartUrl !== null),
      debounceTime(10),
      take(1),
      map(caseStartUrl => {
        if (caseStartUrl) {
          this.trainingViewerFacade.requestStartCase();
          return true;
        }
        return false;
      })
    );
  }
}
