import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { NodeService } from '@medsurf/services';
import { IndexState } from './index.state';
import { SlideNode, PageType, Slide, NodeSettings, populateNodeSettings, Node, User, Role, MediaType } from '@medsurf/models';
import { SlugModel, PathModel, Breadcrumb, GetNodeForUrl, GetNodeForUrlFail, GetNodeForUrlSuccess, SetActivatedRoute,
  SetLoadParameters, GetRootNodes, GetSlideSuccess, OverwriteCurrentPath } from '@medsurf/actions';
import { AuthState } from './auth.state';

/**
 * Navigation state model
 */
export interface NavigationStateModel {
  slugs: SlugModel;
  currentPath: PathModel;
  currentURL: string;
  queryParams: Params
  loadTrainings: boolean;
  loadRestrictedAndHidden: boolean;
}

/**
 * Navigation State
 */
@State<NavigationStateModel>({
  name: 'navigation',
  defaults: {
    slugs: null,
    currentPath: null,
    currentURL: '',
    queryParams: null,
    loadTrainings: false,
    loadRestrictedAndHidden: false
  }
})
@Injectable()
export class NavigationState {
  /**
   * Constructor
   */
  public constructor(private nodeService: NodeService,
                     private store: Store) {
  }

  public static order = ['eLearning', 'subject', 'chapter', 'block', 'slide'];

  /**
   * Selector currentPath
   * @param state: NavigationStateModel
   */
  @Selector()
  public static currentPath(state: NavigationStateModel): PathModel {
    return state.currentPath;
  }

  /**
   * Selector currentURL
   * @param state: NavigationStateModel
   */
  @Selector()
  public static currentURL(state: NavigationStateModel): string {
    return state.currentURL;
  }

  /**
   * Selector themeColor
   * @param state: NavigationStateModel
   */
  @Selector()
  public static themeColor(state: NavigationStateModel): string {
    return state.currentPath?.subject?.page?.themeColor || 'black';
  }

  /**
   * Selector title
   * @param state: NavigationStateModel
   */
  @Selector()
  public static title(state: NavigationStateModel): string {
    return state.currentPath?.slide?.page?.title ||
      state.currentPath?.eLearning?.page?.title;
  }

  /**
   * Selector locked
   * @param state: NavigationStateModel
   */
  @Selector([AuthState.user])
  public static locked(state: NavigationStateModel, user: User): boolean {

    let restricted: boolean = false;
    let shares: User[] = [];

    for (const level of this.order) {
      const n: Node = state.currentPath[level];
      if (!n) {
        break;
      }
      if (n.restricted) {
        restricted = true;
        shares = n.shares;
      }
    }

    if (restricted) {
      if (user && (user.role === Role.ADMIN || shares.some((share) => share.id === user.id))) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  /**
   * Selector settings
   * @param state: NavigationStateModel
   */
  @Selector()
  public static settings(state: NavigationStateModel): NodeSettings {
    const settings: NodeSettings = {};

    this.order.forEach((level) => {
      const nodeSettings = state.currentPath[level]?.settings || {};
      Object.assign(settings, nodeSettings);
    });
    return populateNodeSettings(settings);
  }

  /**
   * Selector settings
   * @param state: NavigationStateModel
   */
  @Selector()
  public static settingsInherited(state: NavigationStateModel): NodeSettings {

    const inheritedSettings: NodeSettings = {};
    let nodeSettings: NodeSettings = {};

    this.order.forEach((level) => {
      const levelSettings = state.currentPath[level]?.settings || {};
      if (state.currentPath[level]) {
        Object.assign(inheritedSettings, nodeSettings);
        nodeSettings = levelSettings;
      }
    });

    return inheritedSettings;
  }

  /**
   * Selector show print button
   * @param state: NavigationStateModel
   */
  @Selector()
  public static showPrintButton(state: NavigationStateModel): boolean {
    if (!state.currentPath.block?.children) {
      return false;
    }
    return state.currentPath.block.children
      .filter((node: SlideNode) => node.page.type === PageType.SLIDE)
      .some((node: SlideNode): boolean => (node.page as Slide).mediaType !== MediaType.DEEPZOOM);
  }

  /**
   * Selector breadcrumbs
   * @param state: NavigationStateModel
   */
  @Selector()
  public static breadcrumbs(state: NavigationStateModel): any[] {
    const breadcrumbs: Breadcrumb[] = [];
    const slugs = [];
    for (const slugKey in state.slugs) {
      if (state.slugs.hasOwnProperty(slugKey)) {
        slugs.push(state.slugs[slugKey]);
        const params = Object.assign([], slugs);
        params.unshift('/');

        breadcrumbs.push({
          title: state.currentPath[slugKey]?.page?.title || state.slugs[slugKey],
          route: slugKey,
          params
        });
      }
    }
    return breadcrumbs.slice(0, 4);
  }

  /**
   * Selector slugs
   * @param state: NavigationStateModel
   */
  @Selector()
  public static slugs(state: NavigationStateModel): SlugModel {
    return state.slugs;
  }

  @Action(SetActivatedRoute)
  public setActivatedRoute(context: StateContext<NavigationStateModel>, {activatedRoute}: SetActivatedRoute) {
    const slugs: SlugModel = {};
    const slugNames = ['eLearningSlug', 'subjectSlug', 'chapterSlug', 'blockSlug', 'slideSlug'];
    for (const slug of slugNames) {
      if (activatedRoute.paramMap.get(slug)) {
        slugs[slug.replace('Slug', '')] = activatedRoute.paramMap.get(slug);
      }
    }

    const currentURL = activatedRoute.url.map(segment => segment.path).join('/');
    const queryParams = activatedRoute.queryParams;

    context.setState(
      patch({
        slugs,
        currentURL,
        queryParams
      }));
  }

  @Action(SetLoadParameters)
  public setLoadParameters(context: StateContext<NavigationStateModel>, {loadTrainings, loadRestrictedAndHidden}: SetLoadParameters) {
    context.setState(
      patch({
        loadTrainings,
        loadRestrictedAndHidden
      }));
  }

  /**
   * Get Node for Url
   * @param dispatch: StateContext<IndexStateModel>
   * @param url: string
   * @param nodeName: keyof PathModel
   */
  @Action(GetNodeForUrl)
  public async getNodeForUrl({getState, dispatch}: StateContext<NavigationStateModel>, {url}: GetNodeForUrl) {
    const state = getState();
    const index = this.store.selectSnapshot(IndexState.index);
    if (index.length > 0) {
      await this.nodeService.findNodeForUrl(
        url ?? state.currentURL,
        state.queryParams, index,
        state.loadTrainings,
        state.loadRestrictedAndHidden
      );
    } else {
      dispatch(new GetRootNodes());
    }
  }

  /**
   * Get Node for Url
   * @param dispatch: StateContext<IndexStateModel>
   * @param url: string
   * @param nodeName: keyof PathModel
   */
  @Action(OverwriteCurrentPath)
  public async overwriteCurrentPath({getState, setState, dispatch}: StateContext<NavigationStateModel>, {
    slugName,
    node
  }: OverwriteCurrentPath) {
    const {currentPath} = getState();
    if (!currentPath) {
      return;
    }
    currentPath[slugName] = node;
    setState(
      patch({
        currentPath
      }));
  }

  /**
   * Add child Nodes success
   * @param getState: StateContext<IndexStateModel>
   * @param setState: StateContext<IndexStateModel>
   * @param node: Node[]
   * @param url: string
   * @param nodeName: keyof PathModel
   */
  @Action(GetNodeForUrlSuccess)
  public getNodeForUrlSuccess({dispatch, getState, setState}: StateContext<NavigationStateModel>, {path}: GetNodeForUrlSuccess): void {
    setState(
      patch({
        currentPath: path,
      }));
    if (path?.slide) {
      if ([PageType.SLIDE, PageType.TRAINING].includes(path.slide.page.type)) {
        dispatch(new GetSlideSuccess(path.slide.page));
      }
    }
  }

  @Action(GetNodeForUrlFail)
  public getNodeForUrlFail({getState, setState}: StateContext<NavigationStateModel>, {path}: GetNodeForUrlFail): void {
    setState(
      patch({
        currentPath: path,
      }));
  }
}
