import { map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { MetadataControlActions } from '@medsurf/flat-actions';
import * as FlatStates from '@medsurf/flat-states';

/**
 * App Shared Facade
 */
@Injectable()
export class AppSharedFacade {
  //<editor-fold desc="Selectors">

  public user$ = this.store.select(FlatStates.AuthControlState.user$);
  public metadata$ = this.store.select(FlatStates.MetadataEntityState.metadata);
  public themeColor$ = this.store.select(FlatStates.PageEntityState.themeColor$);
  public currentSelectedSlideTitle$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlideTitle$);

  //</editor-fold>

  //<editor-fold desc="Lazy Selectors">

  public page$ = (id: any) => this.store.select(FlatStates.PageEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));
  public tree$ = (id: any) => this.store.select(FlatStates.TreeControlState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));
  public media$ = (id: any) => this.store.select(FlatStates.MediaEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));
  public copyright$ = (id: any) => this.store.select(FlatStates.CopyrightEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(private store: Store) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Metadata
   */
  public requestMetadata() {
    return this.store.dispatch(new MetadataControlActions.GetMetadata());
  }

  //</editor-fold>
}
