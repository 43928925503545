import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MessageService, NodeService } from '@medsurf/services';
import { Slide } from '@medsurf/models';
import { GetDetachedSlides, GetDetachedSlidesSuccess, GetDetachedSlidesRequest, DeleteDetachedSlides,
  DeleteDetachedSlidesSuccess, DeleteDetachedSlidesRequest, AssignSlideIds, AssignSlideIdsRequest, AssignSlideIdsSuccess } from '@medsurf/actions';
import { AuthState } from './auth.state';

/**
 * Page state model
 */
export interface DetachedStateModel {
  slides: Slide[];
}

// @dynamic
/**
 * Page state
 */
// @ts-ignore
@State<DetachedStateModel>({
  name: 'detached',
  defaults: {
    slides: [],
  }
})
@Injectable()
export class DetachedState {
  /**
   * Constructor
   * @param nodeService: NodeService
   * @param store: Store
   * @param messageService: MessageService
   */
  public constructor(
    public nodeService: NodeService,
    public store: Store,
    public messageService: MessageService
  ) {}

  /**
   * Selector pages
   */
   @Selector()
   public static slides(state: DetachedStateModel): Slide[] {
     return state.slides;
   }

  // <editor-fold desc="Actions">

  // <editor-fold desc="Get Detached Slides">

  /**
   * Get detached slides
   */
  @Action(GetDetachedSlides)
  public getDetachedSlides({}: StateContext<DetachedStateModel>, {}: GetDetachedSlides) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetDetachedSlidesRequest());
  }
 
  /**
   * Get detached slides success
   * @param setState: StateContext<DetachedStateModel>
   * @param slides: Slide[]
   */
  @Action(GetDetachedSlidesSuccess)
  public getDetachedSlidesSuccess({setState}: StateContext<DetachedStateModel>, {slides}: GetDetachedSlidesSuccess): void {
    setState(
      patch({
        slides: slides
      })
    );
  }

  /**
   * Delete detached slides
   * @param slides: Slide[]
   */
  @Action(DeleteDetachedSlides)
  public deleteDetachedSlides({}: StateContext<DetachedStateModel>, {slides}: DeleteDetachedSlides) {
    slides.forEach((slide) => slide.deleted = true);
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new DeleteDetachedSlidesRequest(slides));
  }
  
  /**
   * Delete detached slides success
   * @param setState: StateContext<DetachedStateModel>
   * @param getState: StateContext<DetachedStateModel>
   */
  @Action(DeleteDetachedSlidesSuccess)
  public deleteDetachedSlidesSuccess({setState, getState}: StateContext<DetachedStateModel>, {}: DeleteDetachedSlidesSuccess): void {
    const { slides } = getState();
    setState(
      patch({
        slides: slides.filter((slide) => !slide.deleted)
      })
    );
  }

  /**
   * Assign Slide Ids
   * @param slides: Slide[]
   */
  @Action(AssignSlideIds)
  public assignSlideIds({}: StateContext<DetachedStateModel>, {slides}: AssignSlideIds) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new AssignSlideIdsRequest(slides));
  }
   
  /**
   * Delete detached slides success
   * @param setState: StateContext<DetachedStateModel>
   * @param getState: StateContext<DetachedStateModel>
   */
  @Action(AssignSlideIdsSuccess)
  public ({setState, getState}: StateContext<DetachedStateModel>, {slides}: AssignSlideIdsSuccess): void {
    const state = getState();
    const newSlides = state.slides.map((slide) => {
      return slides.find(s => slide.id === s.id) || slide;
    });
    setState(
      patch({
        slides: newSlides
      })
    );
  }

  // </editor-fold>

  // <editor-fold desc="Helper">

  // </editor-fold>
}
