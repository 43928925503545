import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MenuAuthorModels } from '@medsurf/flat-models';
import { MenuAuthorActions } from '@medsurf/flat-actions';

/**
 * Menu Author State Model
 */
export interface MenuAuthorStateModel {
  authorDisplayMode: MenuAuthorModels.AuthorDisplayMode;
  authorDisplayMenu: MenuAuthorModels.AuthorDisplayMenu;
  showHeader: boolean;
  showBody: boolean;
  showQuiz: boolean;
  showInteractiveArea: boolean;
  showResourceType: boolean;
  showModality: boolean;
  showStain: boolean;
  showCompoundNumber: boolean;
  showCopyright: boolean;
  showChapterSection: boolean;
  showEditSection: boolean;
  showLayerSection: boolean;
}

/**
 * Menu Author State
 */
@State<MenuAuthorStateModel>({
  name: 'menuAuthor',
  defaults: {
    authorDisplayMode: MenuAuthorModels.AuthorDisplayMode.FULL,
    authorDisplayMenu: MenuAuthorModels.AuthorDisplayMenu.SLIDE,
    showHeader: true,
    showBody: true,
    showQuiz: true,
    showInteractiveArea: true,
    showResourceType: true,
    showModality: true,
    showStain: false,
    showCompoundNumber: false,
    showCopyright: true,
    showChapterSection: true,
    showEditSection: true,
    showLayerSection: true
  }
})
@Injectable()
export class MenuAuthorState {
  //<editor-fold desc="Selectors">

  /**
   * Selector displayMode$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static displayMode$(state: MenuAuthorStateModel): MenuAuthorModels.AuthorDisplayMode {
    return state.authorDisplayMode;
  }

  /**
   * Selector displayMenu$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static displayMenu$(state: MenuAuthorStateModel): MenuAuthorModels.AuthorDisplayMenu {
    return state.authorDisplayMenu;
  }

  /**
   * Selector showHeader$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showHeader$(state: MenuAuthorStateModel): boolean {
    return state.showHeader;
  }

  /**
   * Selector showBody$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showBody$(state: MenuAuthorStateModel): boolean {
    return state.showBody;
  }

  /**
   * Selector showQuiz$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showQuiz$(state: MenuAuthorStateModel): boolean {
    return state.showQuiz;
  }

  /**
   * Selector showInteractiveArea$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showInteractiveArea$(state: MenuAuthorStateModel): boolean {
    return state.showInteractiveArea;
  }

  /**
   * Selector showText$
   *
   * @param displayMode: MenuAuthorModels.AuthorDisplayMode
   */
  @Selector([MenuAuthorState.displayMode$])
  public static showText$(displayMode: MenuAuthorModels.AuthorDisplayMode): boolean {
    return displayMode === MenuAuthorModels.AuthorDisplayMode.FULL
      || displayMode === MenuAuthorModels.AuthorDisplayMode.TEXT;
  }

  /**
   * Selector showImage$
   *
   * @param displayMode: MenuAuthorModels.AuthorDisplayMode
   */
  @Selector([MenuAuthorState.displayMode$])
  public static showImage$(displayMode: MenuAuthorModels.AuthorDisplayMode): boolean {
    return displayMode === MenuAuthorModels.AuthorDisplayMode.FULL
      || displayMode === MenuAuthorModels.AuthorDisplayMode.IMAGE;
  }

  /**
   * Selector showResourceType$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showResourceType$(state: MenuAuthorStateModel): boolean {
    return state.showResourceType;
  }

  /**
   * Selector showModality$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showModality$(state: MenuAuthorStateModel): boolean {
    return state.showModality;
  }

  /**
   * Selector showStain$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showStain$(state: MenuAuthorStateModel): boolean {
    return state.showStain;
  }

  /**
   * Selector showCompoundNumber$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showCompoundNumber$(state: MenuAuthorStateModel): boolean {
    return state.showCompoundNumber;
  }

  /**
   * Selector showCopyright$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showCopyright$(state: MenuAuthorStateModel): boolean {
    return state.showCopyright;
  }

  /**
   * Selector showChapterSection$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showChapterSection$(state: MenuAuthorStateModel): boolean {
    return state.showChapterSection;
  }

  /**
   * Selector showEditSection$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showEditSection$(state: MenuAuthorStateModel): boolean {
    return state.showEditSection;
  }

  /**
   * Selector showLayerSection$
   *
   * @param state: MenuAuthorStateModel
   */
  @Selector([MenuAuthorState])
  public static showLayerSection$(state: MenuAuthorStateModel): boolean {
    return state.showLayerSection;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Set Author Display Mode
   *
   * @param patchState: StateContext<MenuAuthorStateModel>
   * @param authorDisplayMode: MenuAuthorActions.SetAuthorDisplayMode
   */
  @Action(MenuAuthorActions.SetAuthorDisplayMode)
  public setAuthorDisplayMode({patchState}: StateContext<MenuAuthorStateModel>,
                              {authorDisplayMode}: MenuAuthorActions.SetAuthorDisplayMode) {
    patchState({
      authorDisplayMode
    });
  }

  /**
   * Set Author Display Menu
   *
   * @param patchState: StateContext<MenuAuthorStateModel>
   * @param authorDisplayMenu: MenuAuthorActions.SetAuthorDisplayMenu
   */
  @Action(MenuAuthorActions.SetAuthorDisplayMenu)
  public setAuthorDisplayMenu({patchState}: StateContext<MenuAuthorStateModel>,
                              {authorDisplayMenu}: MenuAuthorActions.SetAuthorDisplayMenu) {
    patchState({
      authorDisplayMenu
    });
  }

  /**
   * Toggle Show Header
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   */
  @Action(MenuAuthorActions.ToggleShowHeader)
  public toggleShowHeader({setState}: StateContext<MenuAuthorStateModel>) {
    setState(
      patch({
        showHeader: existing => !existing
      })
    );
  }

  /**
   * Toggle Show Body
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   */
  @Action(MenuAuthorActions.ToggleShowBody)
  public toggleShowBody({setState}: StateContext<MenuAuthorStateModel>) {
    setState(
      patch({
        showBody: existing => !existing
      })
    );
  }

  /**
   * Toggle Show Quiz
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   */
  @Action(MenuAuthorActions.ToggleShowQuiz)
  public toggleShowQuiz({setState}: StateContext<MenuAuthorStateModel>) {
    setState(
      patch({
        showQuiz: existing => !existing
      })
    );
  }

  /**
   * Toggle Show Interactive Area
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   */
  @Action(MenuAuthorActions.ToggleShowInteractiveArea)
  public toggleShowInteractiveArea({setState}: StateContext<MenuAuthorStateModel>) {
    setState(
      patch({
        showInteractiveArea: existing => !existing
      })
    );
  }

  /**
   * Toggle Show Resource Type
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   */
  @Action(MenuAuthorActions.ToggleShowResourceType)
  public toggleShowResourceType({setState}: StateContext<MenuAuthorStateModel>) {
    setState(
      patch({
        showResourceType: existing => !existing
      })
    );
  }

  /**
   * Toggle Show Modality
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   */
  @Action(MenuAuthorActions.ToggleShowModality)
  public toggleShowModality({setState}: StateContext<MenuAuthorStateModel>) {
    setState(
      patch({
        showModality: existing => !existing
      })
    );
  }

  /**
   * Toggle Show Stain
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   */
  @Action(MenuAuthorActions.ToggleShowStain)
  public toggleShowStain({setState}: StateContext<MenuAuthorStateModel>) {
    setState(
      patch({
        showStain: existing => !existing
      })
    );
  }

  /**
   * Toggle Show Compound Number
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   */
  @Action(MenuAuthorActions.ToggleShowCompoundNumber)
  public toggleShowCompoundNumber({setState}: StateContext<MenuAuthorStateModel>) {
    setState(
      patch({
        showCompoundNumber: existing => !existing
      })
    );
  }

  /**
   * Toggle Show Copyright
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   */
  @Action(MenuAuthorActions.ToggleShowCopyright)
  public toggleShowCopyright({setState}: StateContext<MenuAuthorStateModel>) {
    setState(
      patch({
        showCopyright: existing => !existing
      })
    );
  }

  /**
   * Toggle Chapter Section
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   * @param override: MenuAuthorActions.ToggleChapterSection
   */
  @Action(MenuAuthorActions.ToggleChapterSection)
  public toggleChapterSection({setState}: StateContext<MenuAuthorStateModel>,
                              {override}: MenuAuthorActions.ToggleChapterSection) {
    setState(
      patch({
        showChapterSection: existing => override ? override : !existing
      })
    );
  }

  /**
   * Toggle Edit Section
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   * @param override: MenuAuthorActions.ToggleEditSection
   */
  @Action(MenuAuthorActions.ToggleEditSection)
  public toggleEditSection({setState}: StateContext<MenuAuthorStateModel>,
                           {override}: MenuAuthorActions.ToggleEditSection) {
    setState(
      patch({
        showEditSection: existing => override ? override : !existing
      })
    );
  }

  /**
   * Toggle Layer Section
   *
   * @param setState: StateContext<MenuAuthorStateModel>
   * @param override: MenuAuthorActions.ToggleLayerSection
   */
  @Action(MenuAuthorActions.ToggleLayerSection)
  public toggleLayerSection({setState}: StateContext<MenuAuthorStateModel>,
                            {override}: MenuAuthorActions.ToggleLayerSection) {
    setState(
      patch({
        showLayerSection: existing => override ? override : !existing
      })
    );
  }

  //</editor-fold>
}
