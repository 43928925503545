<div class="shortcut-tooltip" [class.open]="isOpen">
    <div class="title">{{ 'keyboard_shortcuts' | translate }}</div>
    <div class="table-container">
        <table>
            <tr>
                <td><span class="key key-number">0</span></td>
                <td><span class="description">{{'zoom_to_fit' | translate}}</span></td>
            </tr>
            <tr>
                <td>
                    <span class="key">Scroll</span><br>
                    <span class="key">+</span><span class="key">-</span>
                </td>
                <td><span class="description">{{'zoom_in' | translate }}</span></td>
            </tr>
            <tr>
                <td>
                    <span class="key">Z</span><br>
                </td>
                <td><span class="description">{{'toggle_virtual_pointer' | translate }}</span></td>
            </tr>
        </table>
        <table>
            <tr>
                <td>
                    <span class="key">←</span><span class="key">↓</span><span class="key">↑</span><span class="key">→</span><br>
                    <!-- <span class="key">a</span><span class="key">s</span><span class="key">w</span><span class="key">d</span> -->
                </td>
                <td><span class="description">{{'move_view' | translate}}</span></td>
            </tr>
            <tr>
                <td>
                    <span class="key">Alt/Option</span>+<span class="key">Scroll</span><br>
                    <span class="key">Alt/Option</span>+<span class="key">↓</span><span class="key">↑</span><br>
                    <span class="key">PgUp</span><span class="key">PgDown</span><br>
                    <!-- <span class="key">k</span><span class="key">j</span><br> -->
                </td>
                <td><span class="description">{{'move_sequence' | translate}}</span></td>
            </tr>
        </table>
    </div>
</div>

<button class="mdsf-btn mdsf-btn-small help" 
    (mouseenter)="onMouseenter()" (mouseleave)="onMouseleave()"
    (mousedown)="onMousedown()">
    <i class="fa fa-question"></i>
</button>