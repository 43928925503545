import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { NodeSharedFacade } from '@medsurf/flat-facades';

@Injectable({
  providedIn: "root"
})
export class ElearningSharedResolver implements Resolve<void> {
  /**
   * Constructor
   *
   * @param nodeSharedFacade: NodeSharedFacade
   */
  constructor(protected nodeSharedFacade: NodeSharedFacade) {
  }

  /**
   * Resolve
   */
  resolve() {
    return this.nodeSharedFacade.requestElearnings();
  }
}