import { Base } from '../base/base.entity.model';
import * as FlatModels from '../../internal';

/**
 * Annotation Entity Type
 */
export type AnnotationEntityType = Annotation | Keymap | Marker;

/**
 * Annotation Entity Query Result
 */
export type AnnotationEntityQueryResult = FlatModels.EntityControlModels.EntityQueryResult<AnnotationEntityType>;

/**
 * Annotation Type
 */
export enum AnnotationType {
  MARKER = 'Marker',
  FREE_FORM = 'FreeForm',
  TEXT = 'Text',
  KEYMAP = 'Keymap'
}

/**
 * Tip Type
 */
export enum TipType {
  DOT = 'dot',
  ARROW = 'arrow',
  CIRCLE = 'circle',
  NONE = 'none'
}

/**
 * Shape
 */
export enum Shape {
  FREE = 'free',
  RECTANGLE = 'rectangle',
  ELLIPSE = 'ellipse',
  ARROW = 'arrow',
  INTERACTIVE_AREA = 'interactiveArea',
  LINE = 'line',
  TRIANGLE = 'triangle'
}

/**
 * Self Test
 */
export interface SelfTest {
  ignore?: boolean;
  x?: number;
  y?: number;
}

/**
 * Annotation
 */
export interface Annotation extends Base {
  label?: FlatModels.AnnotationLabelEntityModels.AnnotationLabel;
  source?: FlatModels.AnnotationSourceEntityModels.AnnotationSource;
  targets?: FlatModels.AnnotationTargetEntityModels.AnnotationTarget[];
  poi?: FlatModels.PointOfInterestEntityModels.PointOfInterest;
  fork?: FlatModels.PointHelperModels.Point;
  groups?: FlatModels.GroupEntityModels.Group[];
  type?: AnnotationType;
  selfTest?: SelfTest;
  image?: FlatModels.ImageEntityModels.Image;
  link?: FlatModels.LinkEntityModels.Link;

  state?: string;
  _labelVisible?: boolean;
}

/**
 * Marker
 */
export interface Marker extends Annotation {
  targets?: FlatModels.AnnotationTargetEntityModels.AnnotationTarget[];
}

/**
 * Free Form
 */
export interface FreeForm extends Annotation {
  shape?: Shape;
  path?: FlatModels.PointHelperModels.Point[];
  freeFormStyle?: FlatModels.FreeFormStyleEntityModels.FreeFormStyle;
  target?: FlatModels.AnnotationTargetEntityModels.AnnotationTarget;

  // Not yet in database
  linkedSlideId?: string;
  linkedSite?: any;
}

/**
 * Keymap
 */
export interface Keymap extends Annotation {
  keymapStyle?: FlatModels.KeymapStyleEntityModels.KeymapStyle;
  columns?: FlatModels.KeymapColumnEntityModels.KeymapColumn[];
}
