import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertControlActions, AuthControlActions } from '@medsurf/flat-actions';
import { SendWebSocketMessage } from '@ngxs/websocket-plugin';
import { AlertControlModels, ErrorControlModels } from '@medsurf/flat-models';

export const FLAT_SETTINGS = new InjectionToken<SettingControlModel>('flat_settings');

export interface SettingControlModel {
  s3endpointUrl?: string;
  s3Bucket?: string;
  endpointUrl?: string;
  endpointUrlPrint?: string;
  websocketUrl?: string;
  authorUrl?: string;
  viewerUrl?: string;
  version?: string;
  timestamp?: string;
  revision?: string;
  branch?: string;
}

@Injectable({
    providedIn: 'root',
})
export class MessageControlService {
  /**
   * Constructor
   * @param store: Store
   * @param http: HttpClient
   * @param settings: SettingControlModel
   */
  constructor(private store: Store,
              private http: HttpClient,
              @Inject(FLAT_SETTINGS) private settings: SettingControlModel) {
  }

  /**
   * Send message
   * @param token: string | null
   * @param data: any
   * @param isConnected: boolean
   */
  public sendMessage(token: string | null, data: any, isConnected = false) {
    // Ensure type is present
    data = Object.assign({type: data.constructor.type}, data);

    // Add token when available
    if (token) {
      data = Object.assign({token}, data);
    }

    // Switch between ws and http request
    if (isConnected) {
      this.sendWsMessage(data);
      return undefined;
    } else {
      return this.sendHttpMessage(data).pipe(
        tap((result: any) => {
          setTimeout(() => {
            this.store.dispatch(result);
            if ([AuthControlActions.ActionTypes.LoginSuccess, AuthControlActions.ActionTypes.LogoutSuccess,
              AuthControlActions.ActionTypes.MeSuccess, AuthControlActions.ActionTypes.UpdateTokenSuccess,
              /* TODO ErrorActionTypes.AuthError, ErrorActionTypes.UserError*/].indexOf(result.type) === -1) {
              // Update current token -> increase ttl
              this.store.dispatch(new AuthControlActions.UpdateToken());
            }
          });
        })
      ).subscribe({
        error: (res) => {
          if (res.error.name === 'MedsurfError') {
            const error = res.error as ErrorControlModels.MedsurfError;

            if (error.options?.show) {
              this.store.dispatch(new AlertControlActions.AddAlert({ 
                message: error.message,
                type: AlertControlModels.AlertType.ERROR,
                dismissable: true
              }))
            }
            
            if (error.options?.logout) {
              this.store.dispatch(new AuthControlActions.ClearSession());
            }
            
            if (error.options?.redirectToLogin) {
              // use window location instead of router.snapshot since the router is on reload not always ready yet
              const url = window.location.pathname; 
              return this.store.dispatch(new Navigate(['/login'], { r: url }, { replaceUrl: true }))
            }

            if (error.options?.redirectToErrorPage) {
              return this.store.dispatch(new Navigate([error.options?.httpCode || 404]))
            }
          }

          this.store.dispatch(new AlertControlActions.AddAlert({ 
            message: res.error,
            type: AlertControlModels.AlertType.ERROR,
            dismissable: true
          }))
          return;
        }
      });
    }
  }

  /**
   * Send ws message
   * @param data: any
   */
  protected sendWsMessage(data: any): void {
    this.store.dispatch(new SendWebSocketMessage(data));
    if ([AuthControlActions.ActionTypes.LoginRequest, AuthControlActions.ActionTypes.LogoutRequest,
      AuthControlActions.ActionTypes.MeRequest, AuthControlActions.ActionTypes.UpdateTokenRequest,
      /* TODO ErrorActionTypes.AuthError, ErrorActionTypes.UserError*/].indexOf(data.type) === -1) {
      // Update current token -> increase ttl
      this.store.dispatch(new AuthControlActions.UpdateToken());
    }
  }

  /**
   * Send http message
   * @param data: any
   */
  protected sendHttpMessage(data: any): Observable<any> {
    return this.http.post<any>(this.settings.endpointUrl + 'v2/httpws', data);
  }
}
