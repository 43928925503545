import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { VocabularyGroupEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * Vocabulary Group Entity Token
 */
export const VOCABULARY_GROUP_ENTITY_TOKEN = new StateToken<VocabularyGroupEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.VOCABULARY_GROUP);

/**
 * Vocabulary Group Entity State Model
 */
export type VocabularyGroupEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.VocabularyGroupEntityModels.VocabularyGroup>;

/**
 * Vocabulary Group Entity State
 */
@State<VocabularyGroupEntityStateModel>({
  name: VOCABULARY_GROUP_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class VocabularyGroupEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: VocabularyGroupEntityStateModel
   */
  @Selector([VocabularyGroupEntityState])
  public static typedEntities$(state: VocabularyGroupEntityStateModel): { [id: string]: FlatModels.VocabularyGroupEntityModels.VocabularyGroup } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.VocabularyGroupEntityModels.VocabularyGroup }
   */
  @Selector([VocabularyGroupEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.VocabularyGroupEntityModels.VocabularyGroup }): FlatModels.VocabularyGroupEntityModels.VocabularyGroup[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.VocabularyGroupEntityModels.VocabularyGroup }
   */
  @Selector([VocabularyGroupEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.VocabularyGroupEntityModels.VocabularyGroup }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.VocabularyGroupEntityModels.VocabularyGroup[]
   */
  @Selector([VocabularyGroupEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.VocabularyGroupEntityModels.VocabularyGroup[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<VocabularyGroupEntityStateModel>
   * @param patchState: StateContext<VocabularyGroupEntityStateModel>
   * @param entityQueryResult: VocabularyGroupEntityActions.GetEntitySuccess
   */
  @Action(VocabularyGroupEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<VocabularyGroupEntityStateModel>,
                          {entityQueryResult}: VocabularyGroupEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<VocabularyGroupEntityStateModel>
   * @param patchState: StateContext<VocabularyGroupEntityStateModel>
   * @param entityQueryResults: VocabularyGroupEntityActions.GetEntitiesSuccess
   */
  @Action(VocabularyGroupEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<VocabularyGroupEntityStateModel>,
                            {entityQueryResults}: VocabularyGroupEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<VocabularyGroupEntityStateModel>
   * @param patchState: StateContext<VocabularyGroupEntityStateModel>
   * @param entity: VocabularyGroupEntityActions.AddEntity
   */
  @Action(VocabularyGroupEntityActions.AddEntity)
  @Undoable(VocabularyGroupEntityActions.AddEntity, VOCABULARY_GROUP_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<VocabularyGroupEntityStateModel>,
                   {entity}: VocabularyGroupEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<VocabularyGroupEntityStateModel>
   * @param patchState: StateContext<VocabularyGroupEntityStateModel>
   * @param entity: VocabularyGroupEntityActions.UpdateEntity
   */
  @Action(VocabularyGroupEntityActions.UpdateEntity)
  @Undoable(VocabularyGroupEntityActions.UpdateEntity, VOCABULARY_GROUP_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<VocabularyGroupEntityStateModel>,
                      {entity}: VocabularyGroupEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<VocabularyGroupEntityStateModel>
   * @param patchState: StateContext<VocabularyGroupEntityStateModel>
   * @param entity: VocabularyGroupEntityActions.RemoveEntity
   */
  @Action(VocabularyGroupEntityActions.RemoveEntity)
  @Undoable(VocabularyGroupEntityActions.RemoveEntity, VOCABULARY_GROUP_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<VocabularyGroupEntityStateModel>,
                      {entity}: VocabularyGroupEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
