<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="{selectedSlides: (trainingViewerFacade.selectedSlides$ | async), selectedSlideNodes: (trainingViewerFacade.selectedSlideNodes$ | async), selectedSlideIndex: (trainingViewerFacade.selectedSlideIndex$ | async), slideResultsAvailable: (trainingViewerFacade.slideSelectionAvailable$ | async)} as trainingData; else spinner">
  <div class="mdsf-slide-control">
    <!-- Position -->
    <span class="position">
      <input class="mdsf-slide-control_input"
             type="number"
             min="1"
             [max]="trainingData.selectedSlides.length"
             [value]="trainingData.selectedSlideIndex + 1"
             (change)="trainingViewerFacade.requestGoToSlideByIndex($event.target, trainingData.selectedSlideIndex, trainingData.selectedSlides, trainingData.selectedSlideNodes, trainingData.slideResultsAvailable, route.snapshot.queryParams)">
      / {{ trainingData.selectedSlides.length }}
    </span>

    <!-- Progess Bar -->
    <div class="mdsf-progress">
      <ng-container *ngFor="let selectedSlide of trainingData.selectedSlides; let i = index">
        <ng-container *ngIf="(trainingViewerFacade.node$(selectedSlide) | async) as slide">
          <ng-container *ngIf="(trainingViewerFacade.page$(slide.page) | async) as page">
            <div class="mdsf-progress-point"
                 *ngIf="page !== null"
                 [class.current]="trainingData.selectedSlideIndex === i"
                 [class.past]="trainingData.selectedSlideIndex > i"
                 [class.available]="trainingData.slideResultsAvailable[page.id] === true"
                 [routerLink]="(trainingViewerFacade.slideUrlByNode$(slide) | async)"
                 [queryParams]="route.snapshot.queryParams"
                 placement="top"
                 [ngbTooltip]="(i + 1) + ': ' + page.title">
              <div class="mdsf-progress-point-marker"></div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <div class="mdsf-progress-wrap">
        <div class="mdsf-progress-bar"
             [class.transition-easeout]="true"
             [style.transform]="'scaleX(' + (trainingViewerFacade.selectedSlidePercentage$ | async) + ')'">
        </div>
      </div>
    </div>

    <!-- Previous -->
    <button class="button mdsf-btn"
        type="button"
        role="button"
        *ngIf="(trainingViewerFacade.previousSlideUrl$ | async) as previousUrl"
        [routerLink]="['/' + previousUrl]"
        [queryParams]="route.snapshot.queryParams">
      <span class="fa fa-chevron-left"></span>
    </button>

    <!-- Next -->
    <button class="button mdsf-btn next-btn"
        type="button"
        role="button"
        *ngIf="{nextSlideNode: (trainingViewerFacade.nextSlideNode$ | async), nextUrl: (trainingViewerFacade.nextSlideUrl$ | async)} as nextData"
        [disabled]="nextData.nextSlideNode && trainingData.slideResultsAvailable[nextData.nextSlideNode.page] === false"
        [routerLink]="['/' + nextData.nextUrl]"
        [queryParams]="route.snapshot.queryParams">
      <span class="fa fa-chevron-right"></span>
    </button>
  </div>
  <div class="mdsf-slide-button-group d-none d-md-inline-block">
    <div class="button mdsf-btn" (click)="feedbackControlService.openFeedbackModal()">
      <a>FEEDBACK</a>
    </div>
  </div>
</ng-container>
