import { User } from "@medsurf/models";
import { IsEmail, IsEnum, IsNotEmpty, IsOptional, Length, Matches, ValidateIf } from "class-validator";
import { BaseValidator } from "./base.validator";

export class UpdateUserValidator extends BaseValidator implements User {
  @IsEmail({}, { message: 'email_must_be_email' })
  email?: string;

  @Length(8, 254, { message: 'password_min_length' })
  @Matches(/[a-z]/, { message: 'password_requires_lowercase' })
  @Matches(/[A-Z]/, { message: 'password_requires_uppercase' })
  @Matches(/[0-9]/, { message: 'password_requires_number' })
  @IsOptional()
  @ValidateIf(o => o.password !== '')
  password?: string;

  @IsNotEmpty({ message: 'role_must_be_one_of_options' })
  @IsOptional()
  role?: any;
}
