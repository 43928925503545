import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Group, PointOfInterest } from '@medsurf/models';
import { MarkerLink, ToggleFullscreen, ToggleNavigator, TogglePoiPanel, TogglePrintMode, ToggleMarkers, ToggleSelftest,
  ToggleVirtualPointer, SetMarkerLink, SetContainer, SetSelectedPOI, SetSelectedGroup,
  SetShowMarkers, SetMarker, SetNavigator, ToggleQuizExplanation, ToggleQuizSolution, ToggleQuizFeedback, ToggleCaseHistory, SetSelectedVirtualGroup } from '@medsurf/actions';

/**
 * SlideView state model
 */
export interface SlideViewStateModel {
  printMode: boolean;
  showFullscreenButton: boolean;
  showFullscreen: boolean;
  showVirtualPointerButton: boolean;
  showVirtualPointer: boolean;
  showNavigator: boolean;
  showPoiPanel: boolean;
  showMarkers: boolean;
  showSelftest: boolean;
  showQuizFeedback: boolean;
  showQuizSolution: boolean;
  showQuizExplanation: boolean;
  showCaseHistory: boolean;
  container?: any;
  markerLink?: MarkerLink;
  marker?: any;
  selectedPOI?: PointOfInterest;
  selectedGroup?: Group;
  selectedVirtualGroup?: Group;
}

/**
 * SlideView State
 */
@State<SlideViewStateModel>({
  name: 'slideView',
  defaults: {
    printMode: false,
    showFullscreenButton: false,
    showFullscreen: false,
    showVirtualPointerButton: false,
    showVirtualPointer: false,
    showNavigator: true,
    showPoiPanel: false,
    showMarkers: true,
    showSelftest: false,
    showQuizFeedback: false,
    showQuizSolution: false,
    showQuizExplanation: false,
    showCaseHistory: false,
  }
})
@Injectable()
export class SlideViewState {

  @Selector()
  public static printMode(state: SlideViewStateModel): boolean {
    return state.printMode;
  }

  @Selector()
  public static selectedVirtualGroup(state: SlideViewStateModel) {
    return state.selectedVirtualGroup;
  }

  @Selector()
  public static showFullscreenButton(state: SlideViewStateModel): boolean {
    return state.showFullscreenButton;
  }

  @Selector()
  public static showFullscreen(state: SlideViewStateModel): boolean {
    return state.showFullscreen;
  }

  @Selector()
  public static showVirtualPointerButton(state: SlideViewStateModel): boolean {
    return state.showVirtualPointerButton;
  }

  @Selector()
  public static showVirtualPointer(state: SlideViewStateModel): boolean {
    return state.showVirtualPointer;
  }

  @Selector()
  public static showNavigator(state: SlideViewStateModel): boolean {
    return state.showNavigator;
  }

  @Selector()
  public static showPoiPanel(state: SlideViewStateModel): boolean {
    return state.showPoiPanel;
  }

  @Selector()
  public static showMarkers(state: SlideViewStateModel): boolean {
    return state.showMarkers;
  }

  @Selector()
  public static showSelftest(state: SlideViewStateModel): boolean {
    return state.showSelftest;
  }

  @Selector()
  public static showQuizFeedback(state: SlideViewStateModel): boolean {
    return state.showQuizFeedback;
  }

  @Selector()
  public static showQuizSolution(state: SlideViewStateModel): boolean {
    return state.showQuizSolution;
  }

  @Selector()
  public static showQuizExplanation(state: SlideViewStateModel): boolean {
    return state.showQuizExplanation;
  }

  @Selector()
  public static showCaseHistory(state: SlideViewStateModel): boolean {
    return state.showCaseHistory;
  }

  @Selector()
  public static markerLink(state: SlideViewStateModel): MarkerLink {
    return state.markerLink;
  }

  @Selector()
  public static container(state: SlideViewStateModel): any {
    return state.container;
  }

  @Selector()
  public static selectedPOI(state: SlideViewStateModel): PointOfInterest {
    return state.selectedPOI;
  }

  @Selector()
  public static selectedMarker(state: SlideViewStateModel): any {
    return state.marker;
  }

  @Selector()
  public static selectedGroup(state: SlideViewStateModel): Group {
    return state.selectedGroup;
  }

  @Action(TogglePrintMode)
  public togglePrintMode({setState}: StateContext<SlideViewStateModel>) {
    setState(patch<SlideViewStateModel>({printMode: existing => !existing}));

  }

  @Action(ToggleFullscreen)
  public toggleFullscreen({setState}: StateContext<SlideViewStateModel>) {
    setState(patch<SlideViewStateModel>({showFullscreen: existing => !existing}));

  }

  @Action(ToggleVirtualPointer)
  public toggleVirtualPointer({setState}: StateContext<SlideViewStateModel>) {
    setState(patch<SlideViewStateModel>({showVirtualPointer: existing => !existing}));
  }

  @Action(ToggleNavigator)
  public toggleNavigator({setState}: StateContext<SlideViewStateModel>) {
    setState(patch<SlideViewStateModel>({showNavigator: existing => !existing}));
  }

  @Action(SetNavigator)
  public setNavigator({setState}: StateContext<SlideViewStateModel>, {showNavigator}: SetNavigator) {
    setState(patch<SlideViewStateModel>({showNavigator}));
  }

  @Action(TogglePoiPanel)
  public togglePoiPanel({setState}: StateContext<SlideViewStateModel>) {
    setState(patch<SlideViewStateModel>({showPoiPanel: existing => !existing}));
  }

  @Action(ToggleMarkers)
  public toggleShowMarkers({getState, setState}: StateContext<SlideViewStateModel>) {
    const showMarkers = !SlideViewState.showMarkers(getState());
    setState(patch<SlideViewStateModel>({
      showMarkers,
      showSelftest: existing => (existing && showMarkers) ? false : existing,
    }));
  }

  @Action(SetShowMarkers)
  public setShowMarkers({getState, setState}: StateContext<SlideViewStateModel>, {showMarkers}: SetShowMarkers) {
    setState(patch<SlideViewStateModel>({
      showMarkers,
      showSelftest: existing => (existing && showMarkers) ? false : existing,
    }));
  }

  @Action(ToggleSelftest)
  public toggleShowSelftest({getState, setState}: StateContext<SlideViewStateModel>) {
    const showSelftest = !SlideViewState.showSelftest(getState());
    setState(patch<SlideViewStateModel>({
      showSelftest,
      showMarkers: existing => (existing && showSelftest) ? false : existing,
    }));
  }

  @Action(ToggleQuizFeedback)
  public toggleQuizFeedback({setState}: StateContext<SlideViewStateModel>) {
    setState(patch<SlideViewStateModel>({showQuizFeedback: existing => !existing}));
  }

  @Action(ToggleQuizSolution)
  public toggleQuizSolution({setState}: StateContext<SlideViewStateModel>) {
    setState(patch<SlideViewStateModel>({showQuizSolution: existing => !existing}));
  }

  @Action(ToggleQuizExplanation)
  public toggleQuizExplanation({setState}: StateContext<SlideViewStateModel>) {
    setState(patch<SlideViewStateModel>({showQuizExplanation: existing => !existing}));
  }

  @Action(ToggleCaseHistory)
  public toggleCaseHistory({setState}: StateContext<SlideViewStateModel>) {
    setState(patch<SlideViewStateModel>({showCaseHistory: existing => !existing}));
  }

  @Action(SetContainer)
  public setContainer({setState}: StateContext<SlideViewStateModel>, {container}: SetContainer) {
    setState(patch<SlideViewStateModel>({container}));
  }

  @Action(SetMarkerLink)
  public setMarkerLink({setState}: StateContext<SlideViewStateModel>, {markerLink}: SetMarkerLink) {
    setState(patch<SlideViewStateModel>({markerLink}));
  }

  @Action(SetSelectedPOI)
  public setSelectedPOI({setState}: StateContext<SlideViewStateModel>, {selectedPOI}: SetSelectedPOI) {
    setState(patch<SlideViewStateModel>({selectedPOI}));
  }

  @Action(SetSelectedGroup)
  public setSelectedGroup({setState}: StateContext<SlideViewStateModel>, {selectedGroup}: SetSelectedGroup) {
    setState(patch<SlideViewStateModel>({selectedGroup}));
  }

  @Action(SetSelectedVirtualGroup)
  public setSelectedVirtualGroup({setState}: StateContext<SlideViewStateModel>, {group}: SetSelectedVirtualGroup) {
    setState(patch<SlideViewStateModel>({selectedVirtualGroup: group}));
  }

  @Action(SetMarker)
  public setMarker({setState}: StateContext<SlideViewStateModel>, {marker}: SetMarker) {
    setState(patch<SlideViewStateModel>({marker}));
  }
}
