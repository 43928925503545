import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { filter } from 'rxjs/internal/operators/filter';
import { tap } from 'rxjs/internal/operators/tap';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead';
import { NodeEntityModels } from '@medsurf/flat-models';
import { MatomoControlService, ModalControlService } from '@medsurf/flat-services';
import { SearchControlFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  /**
   * View Children
   */
  @ViewChild('searchInput') public searchElement: ElementRef<HTMLInputElement>;
  @ViewChild(NgbTypeahead) public typeaheadElement: NgbTypeahead;

  /**
   * Members
   */
  private _destroyed$ = new Subject<boolean>();
  private dismissedPopup = false;
  public minLength = 2;
  public needle = '';
  public isSearching = false;
  public formatter = (x: { name: string }) => x.name;

  /**
   * Constructor
   *
   * @param searchControlFacade: SearchControlFacade
   * @param modalControlService: ModalControlService
   * @param matomoControlService: MatomoControlService
   */
  constructor(public searchControlFacade: SearchControlFacade,
              public modalControlService: ModalControlService,
              public matomoControlService: MatomoControlService) {
  }

  /**
   * Ng On Init
   */
  ngOnInit() {
    this.modalControlService.onOpenModal.subscribe(() => {
      // this.store.dispatch(new ResetSearch());
      setTimeout(() => {
        this.searchElement.nativeElement.value = this.needle;
        this.searchElement.nativeElement.focus();
        this.searchElement.nativeElement.select();
      });
    });

    this.searchControlFacade.ofActionDispatchedSearchSuccess.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.isSearching = false;
      this.searchControlFacade.searchResultCount$.pipe(tap(countResult => {
        this.matomoControlService.trackSearch(this.needle, countResult);
      }));
    });
  }

  /**
   * Ng On Destroy
   */
  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  /**
   * This event covers the the search box (bubbled to the input field)
   * It closes the modal on escape and stops propagation
   * This way the key buttons in the slide controller are not triggered
   *
   * @param {object } event - KeyDown event object
   * @return void
   */
  @HostListener('keydown', ['$event'])
  private handleKeysSearchBox(event: KeyboardEvent): void {
    if ('Escape' === event.key) {
      this.modalControlService.closeModal();
    }
    event.stopPropagation();
  }

  /**
   * This event covers the rest of the modal (needs to be on document in order to catch the event)
   * It closes the modal on escape
   *
   * @param {object } event - KeyDown event object
   * @return void
   */
  @HostListener('document:keydown', ['$event'])
  private handleKeysModal(event: KeyboardEvent): void {
    if ('Escape' === event.key) {
      this.modalControlService.closeModal();
    }
  }

  /**
   * Is executed, when an element in the typeahead list is selected
   *
   * If the typeahead suggestion matches a single slide, the corresponding slide will be displayed,
   * otherwise a grid is shown
   *
   * @param {NgbTypeaheadSelectItemEvent} event - Model of the selected typeahead entry
   * @return void
   */
  public onSelect(event: NgbTypeaheadSelectItemEvent): void {
    const searchString = event.item;
    this.searchControlFacade.requestSetSearchString(searchString);
    this.search();
  }

  /**
   * Is fired when enter key pressed
   * Fetches all models of slides which fit to the search string (marker and slide text)
   *
   * @return void
   */
  public search(): void {
    this.typeaheadElement.dismissPopup();
    this.dismissedPopup = true;
    this.isSearching = true;
    this.searchControlFacade.requestFindSlidesAndMarkers();
  }

  /**
   * Change state to the selected block
   *
   * @param {ElearningNode} elearning - Selected elearning object
   * @param {SubjectNode} subject - Selected subject object
   * @param {ChapterNode} chapter - Selected chapter object
   * @param {BlockNode} block - Selected block object
   * @return void
   */
  public changeState(elearning: NodeEntityModels.ElearningNode,
                     subject: NodeEntityModels.SubjectNode,
                     chapter: NodeEntityModels.ChapterNode,
                     block?: NodeEntityModels.BlockNode): string[] {
    if (block) {
      return [
        elearning.route,
        subject.route,
        chapter.route,
        block.route
      ];
    }
    return [
      elearning.route,
      subject.route,
      chapter.route
    ];
  }

  /**
   * Search Type Ahead
   *
   * @param {Observable<string>} searchText
   */
  public searchTypeAhead = (searchText: Observable<string>): Observable<any[]> => {
    return searchText.pipe(
      tap((term: string) => {
        this.searchControlFacade.requestSetSearchString(term);
        this.dismissedPopup = false;
      }),
      filter((term: string) => term.length > this.minLength),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(() => {
        if (this.dismissedPopup) {
          this.dismissedPopup = false;
          return of([]);
        } else {
          this.searchControlFacade.requestFindSuggestions();
          return this.searchControlFacade.suggestions$;
        }
      })
    );
  }
}
