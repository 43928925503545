import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ConnectWebSocket, DisconnectWebSocket, SendWebSocketMessage } from '@ngxs/websocket-plugin';
import { WsControlActions } from '@medsurf/flat-actions';

/**
 * Ws Control State Model
 */
export interface WsControlStateModel {
  isConnected: boolean;
  isExpired: boolean;
  apiVersion: string | null;
}

/**
 * Ws Control State
 */
@State<WsControlStateModel>({
  name: 'wsControl',
  defaults: {
    isConnected: false,
    isExpired: true,
    apiVersion: null,
  }
})
@Injectable()
export class WsControlState {
  /**
   * Constructor
   *
   * @param store: Store
   */
  public constructor(protected store: Store) {
    store.dispatch(new WsControlActions.SetIsConnected(false));
  }

  //<editor-fold desc="Selectors">

  /**
   * Selector isConnected$
   *
   * @param state: WsControlStateModel
   */
  @Selector([WsControlState])
  public static isConnected$(state: WsControlStateModel): boolean {
    return state.isConnected;
  }

  /**
   * Selector isExpired$
   *
   * @param state: WsControlStateModel
   */
  @Selector([WsControlState])
  public static isExpired$(state: WsControlStateModel): boolean {
    return state.isExpired;
  }

  /**
   * Selector apiVersion$
   *
   * @param state: WsControlStateModel
   */
  @Selector([WsControlState])
  public static apiVersion$(state: WsControlStateModel): string | null {
    return state.apiVersion;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Connect Ws
   *
   * @param dispatch: StateContext<WsControlStateModel>
   */
  @Action(WsControlActions.Connect)
  public wsConnect({dispatch}: StateContext<WsControlStateModel>) {
    dispatch(new ConnectWebSocket());
  }

  /**
   * Disconnect Ws
   *
   * @param dispatch: StateContext<WsControlStateModel>
   */
  @Action(WsControlActions.Disconnect)
  public wsDisconnect({dispatch}: StateContext<WsControlStateModel>) {
    dispatch(new DisconnectWebSocket());
  }

  /**
   * Ping
   *
   * @param dispatch: StateContext<WsControlStateModel>
   */
  @Action(WsControlActions.Ping)
  public ping({dispatch}: StateContext<WsControlStateModel>) {
    const ping: any = new WsControlActions.Ping();
    const data = Object.assign({type: ping.constructor.type}, ping);
    dispatch(new SendWebSocketMessage(data));
  }

  /**
   * Pong
   *
   * @param patchState: StateContext<WsControlStateModel>
   * @param apiVersion: WsControlActions.Pong
   */
  @Action(WsControlActions.Pong)
  public pong({patchState}: StateContext<WsControlStateModel>,
              {apiVersion}: WsControlActions.Pong) {
    if (this.store.selectSnapshot(WsControlState.apiVersion$) === apiVersion) {
      return;
    }
    patchState({
      apiVersion
    });
  }

  /**
   * Set isConnected$
   *
   * @param patchState: StateContext<WsControlStateModel>
   * @param isConnected: WsControlActions.SetIsConnected
   */
  @Action(WsControlActions.SetIsConnected)
  public wsSetIsConnected({patchState}: StateContext<WsControlStateModel>,
                          {isConnected}: WsControlActions.SetIsConnected) {
    patchState({
      isConnected
    });
  }

  /**
   * Set isExpired$
   *
   * @param patchState: StateContext<WsControlStateModel>
   * @param isExpired: WsControlActions.SetIsExpired
   */
  @Action(WsControlActions.SetIsExpired)
  public wsSetIsExpired({patchState}: StateContext<WsControlStateModel>,
                        {isExpired}: WsControlActions.SetIsExpired) {
    patchState({
      isExpired
    });
  }

  //</editor-fold>
}
