import { Component, ElementRef, ViewChild } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { BaseQuestionComponent } from '../base-question.component';

/**
 * TODO's:
 * - Matomo Track will only trigger when we check the form -> moved to state
 */
@Component({
  selector: 'medsurf-quiz-freetext-question',
  templateUrl: './freetext-question.component.html',
  styleUrls: ['./freetext-question.component.scss']
})
export class FreetextQuestionComponent extends BaseQuestionComponent<FlatModels.QuestionEntityModels.FreeTextQuestion, FlatModels.TrainingViewerModels.FreetextQuestionResult> {
  /**
   * View Children
   */
  @ViewChild('textarea', {static: false}) textarea: ElementRef<HTMLInputElement>;

  /**
   * Is Correct
   */
  public isCorrect(): boolean {
    return this.trainingResult.answer || false;
  }

  /**
   * Pass Required
   */
  public passRequired(): boolean {
    if (this.question.required) {
      if (this.trainingResult.text === undefined || this.trainingResult.text === null || this.trainingResult.text === '') {
        return false;
      }
    }
    return true;
  }

  /**
   * Resize Textarea
   */
  public resizeTextarea() {
    const el = this.textarea.nativeElement;
    if (el) {
      el.setAttribute('rows', '1');
      const style = getComputedStyle(el);
      const pTop = Number(style.paddingTop.replace(/[^0-9]/gi, ''));
      const pBot = Number(style.paddingBottom.replace(/[^0-9]/gi, ''));
      const lineHeight = Number(style.lineHeight.replace(/[^0-9]/gi, ''));
      const rows = Math.ceil((el.scrollHeight - pTop - pBot) / lineHeight);
      el.setAttribute('rows', Math.max(3, rows).toString());
    }
  }


  /**
   * On Change
   */
  public onChange() {
    this.trainingViewerFacade.requestUpdateSlideResult(this.trainingResult);
  }


  /**
   * Evaluate Question
   *
   * @param answer: boolean
   */
  public evaluateQuestion(answer: boolean) {
    this.trainingResult.answer = answer;
    this.trainingViewerFacade.requestUpdateSlideResult(this.trainingResult);
  }

}
