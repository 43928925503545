import { Folder } from './folder.model';
import { Base } from './base.model';
import { Grid } from './grid.model';
import { Image } from './image.model';
import { Slide } from './slide.model';
import { Metadata } from './metadata.model';
import { User } from './user.model';

export enum MediaType {
  DOCUMENT = 'MediaDocument',
  IMAGE = 'MediaImage',
  DEEPZOOM = 'MediaDeepzoom',
  VIDEO = 'MediaVideo',
  AUDIO = 'MediaAudio'
}

export interface Media extends Base {
  name?: string;
  originalFile?: string;
  file?: string;
  thumbnail?: string;
  type?: MediaType;
  altText?: string;
  mime?: string;
  size?: number;
  hidden?: boolean;
  access?: MediaAccess;
  checksum?: string;
  uploadedBy?: User;
  uploadedAt?: Date;
  createdAt?: Date;
  editedAt?: Date;
  copyrights?: Copyright[];
  stain?: Stain;
  compoundNumber?: string;
  mediaInFolders?: Folder[];
  mediaInImages?: Image[];
  mediaInMetadata?: Metadata;
  mediaInSlideThumbs?: Slide[];
  mediaInGridPdf?: Grid;
  mediaInSlidePdf?: Slide;
  authorNote?: string;
}

export interface MediaDocument extends Media {
}

export interface MediaAudio extends Media {
  duration?: number;
}

export interface MediaVideo extends Media {
  duration?: number;
  dimensions?: Dimensions;
  poster?: string;
}


export interface MediaImage extends Media {
  dimensions?: Dimensions;
}

export interface MediaDeepzoom extends Media {
  dimensions?: Dimensions;
  tileFolder?: string;
}


export enum MediaAccess {
  PUBLIC = 'public',
  PRIVATE = 'private'
}


export interface Dimensions {
  width?: number;
  height?: number;
  numTiles?: number;
  tileSize?: number;
  pixelPerMillimeter?: number;
  depth?: string;
  maxZoom?: number;
}

export enum CopyrightPosition {
  TOPLEFT = 'top_left',
  TOPRIGHT = 'top_right',
  BOTRIGHT = 'bot_right',
  BOTLEFT = 'bot_left'
}

export interface Copyright extends Base {
  text?: string;
}

export interface Stain extends Base {
  name?: string;
  shortcut?: string;
}
