import { Component } from '@angular/core';
import { MenuViewerFacade, SlideViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-viewer-interactive-area-list',
  templateUrl: './interactive-area.component.html',
  styleUrls: ['./interactive-area.component.scss']
})
export class InteractiveAreaComponent {

  /**
   * Members
   */
  public expandedGroupId = '';

  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   */
  constructor(public slideViewerFacade: SlideViewerFacade,
              public menuViewerFacade: MenuViewerFacade) {
  }

  public toggleGroup(groupId: string | null, showSelftest: boolean) {
    if (groupId === this.expandedGroupId) {
      this.expandedGroupId = '';
    } else {
      this.expandedGroupId = groupId;
    }
    if (showSelftest) {
      this.slideViewerFacade.requestSetAreaGroupId(groupId);
    }
  }

  public setAreaGroupId(groupId: string | null, showSelftest: boolean) {
    if (!showSelftest) {
      this.slideViewerFacade.requestSetAreaGroupId(groupId);
    }
  }
}
