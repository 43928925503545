import { Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppSharedFacade, NodeSharedFacade } from '@medsurf/flat-facades';
import { MediaControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-chapter-text',
  templateUrl: './chapter-text.component.html',
  styleUrls: ['./chapter-text.component.scss']
})
export class ChapterTextComponent {
  /**
   * View Children
   */
  @ViewChild('textElement', {static: false}) public textElement: ElementRef;

  /**
   * Constructor
   *
   * @param appSharedFacade: AppSharedFacade
   * @param nodeSharedFacade: NodeSharedFacade
   * @param mediaControlService: MediaControlService
   */
  constructor(public appSharedFacade: AppSharedFacade,
              public nodeSharedFacade: NodeSharedFacade,
              public mediaControlService: MediaControlService) {
  }

  /**
   * Format Description
   *
   * @param text: string
   * @param themeColor: string
   */
  public formatDescription(text: string, themeColor: string) {
    let content = '';
    if (text) {
      content += text;
      this.addLinkOnClickHandler(themeColor);
    }
    return content;
  }

  /**
   * Add Link On Click Handler
   *
   * @param themeColor: string
   */
  public addLinkOnClickHandler(themeColor: string) {
    if (this.textElement) {
      const links = Array.from(this.textElement.nativeElement.getElementsByTagName('a'));
      /* TODO Old
      links.filter((link: HTMLAnchorElement) => link.className === 'internal' || link.className === 'external')
        .forEach((link: HTMLAnchorElement) => {
          const isInternal = link.className === 'internal';
          link.className += ` ${themeColor}`;
          link.href = isInternal ?
            ['folien', link.pathname.replace(/(\/slides\/|\/folien\/)/, '')].join('/') :
            link.href;
          link.target = '_blank';

        });
      */
      links.filter((link: HTMLAnchorElement) => link.className === 'internal' || link.className === 'external')
        .forEach((link: HTMLAnchorElement) => {
          link.className += ` ${themeColor}`;
        });
      links.filter((link: HTMLAnchorElement) => link.className === '')
        .forEach((link: HTMLAnchorElement) => {
          link.className += ` ${themeColor}`;
        });

      links.filter((link: HTMLAnchorElement) => link.className === 'pdf')
        .forEach((link: HTMLAnchorElement) => {
          link.className += ` ${themeColor}`;
          link.href = `${environment.config.s3endpointUrl}medsurf/public/${link.href.replace('http://', '')}`;
        });
    }
  }
}
