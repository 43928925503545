<ng-container *ngIf="(matomoService.hasConsent$ | async) === false">
    <div class="cookie-consent card shadow-lg">
        <div class="card-body">
            <div [innerHTML]=" 'cookie_consent_message' | translate | safeHtml "></div>
            <div class="d-flex justify-content-end">
                <button type="button"
                        class="mdsf-btn mdsf-btn-primary"
                        (click)="consent()">
                    {{ 'accept' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-container>