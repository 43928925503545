import { EntityControlModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetEntitySuccess = '[STORE | CONTROL | ENTITY] Get Entity Success',
  GetEntitiesSuccess = '[STORE | CONTROL | ENTITY] Get Entities Success',
  /**
   * Request
   */
  GetEntityRequest = '[REQUEST | CONTROL | ENTITY] Get Entity Request',
  // GetEntitiesRequest = '[REQUEST | CONTROL | ENTITY] Get Entities',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Entity Success
 */
export class GetEntitySuccess {
  public static readonly type = ActionTypes.GetEntitySuccess;

  public constructor(public entityQueryResult: EntityControlModels.EntityQueryResult<any>) {
  }
}

/**
 * Get Entities Success
 */
export class GetEntitiesSuccess {
  public static readonly type = ActionTypes.GetEntitiesSuccess;

  public constructor(public entityQueryResults: EntityControlModels.EntityQueryResult<any>[]) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Entity Request
 */
export class GetEntityRequest {
  public static readonly type = ActionTypes.GetEntityRequest;

  public constructor(public entityQuery: EntityControlModels.EntityQuery) {
  }
}

/**
 * Get Entities Request
 */
/*
export class GetEntitiesRequest {
  public static readonly type = ActionTypes.GetEntitiesRequest;

  public constructor(public entityQueries: EntityControlModels.EntityQuery[]) {
  }
}
*/

// </editor-fold>
