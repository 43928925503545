import { SubjectNode, Training } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Training action types
 */
export enum TrainingActionTypes {
  /**
   * Store
   */
  GetRootTrainingNode = '[STORE | TRAINING] Get Root Training Node',
  GetRootTrainingNodeSuccess = '[STORE | TRAINING] Get Root Training Node Success',
  GetTrainings = '[STORE | TRAINING] Get Trainings',
  GetTrainingsSuccess = '[STORE | TRAINING] Get Trainings Success',
  /**
   * Request
   */
  GetRootTrainingNodeRequest = '[REQUEST | TRAINING] Get Root Training Node',
  GetTrainingsRequest = '[REQUEST | TRAINING] Get Trainings',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Root Training Node
 */
export class GetRootTrainingNode {
  public static readonly type = TrainingActionTypes.GetRootTrainingNode;

  public constructor() {
  }
}

/**
 * Get Root Node Success
 */
export class GetRootTrainingNodeSuccess {
  public static readonly type = TrainingActionTypes.GetRootTrainingNodeSuccess;

  public constructor(public rootTrainingNode: SubjectNode) {
  }
}

/**
 * Get Trainings
 */
export class GetTrainings {
  public static readonly type = TrainingActionTypes.GetTrainings;

  public constructor(public trainingIds: string[]) {
  }
}

/**
 * Get Trainings Success
 */
export class GetTrainingsSuccess {
  public static readonly type = TrainingActionTypes.GetTrainingsSuccess;

  public constructor(public trainings: Training[]) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Root Training Node Request
 */
export class GetRootTrainingNodeRequest {
  public static readonly type = TrainingActionTypes.GetRootTrainingNodeRequest;

  public constructor(public id: string) {
  }
}

/**
 * Get Trainings Request
 */
export class GetTrainingsRequest {
  public static readonly type = TrainingActionTypes.GetTrainingsRequest;

  public constructor(public trainingIds: string[]) {
  }
}

// </editor-fold>
