// <editor-fold desc="Action Types">

import { Release } from "@medsurf/models";

/**
 * Api action types
 */
export enum ReleaseNotesActionTypes {
  /**
   * Store
   */
  GetReleaseNotes = '[STORE | API] Get Release Notes',
  GetReleaseNotesSuccess = '[STORE | API] Get Release Notes Success',
  /**
   * Request
   */
  GetReleaseNotesRequest = '[REQUEST | API] Get Release Notes'
}
  
// </editor-fold>


// <editor-fold desc="Store">

/**
 * Get Release Notes
 */
export class GetReleaseNotes {
  public static readonly type = ReleaseNotesActionTypes.GetReleaseNotes;

  public constructor(public version: string) {
  }
}
  
/**
 * Post Feedback Success
 */
export class GetReleaseNotesSuccess {
  public static readonly type = ReleaseNotesActionTypes.GetReleaseNotesSuccess;

  public constructor(public releases: Release[]) {
  }
}
  
// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Release Notes
 */
export class GetReleaseNotesRequest {
  public static readonly type = ReleaseNotesActionTypes.GetReleaseNotesRequest;

  public constructor(public version: string) {
  }
}

// </editor-fold>
