import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { NodeSharedFacade } from '@medsurf/flat-facades';

@Injectable({
  providedIn: "root"
})
export class RedirectSharedResolver implements Resolve<void> {
  /**
   * Constructor
   *
   * @param nodeSharedFacade: NodeSharedFacade
   */
  constructor(protected nodeSharedFacade: NodeSharedFacade) {
  }

  /**
   * Resolve
   */
  resolve(route: ActivatedRouteSnapshot) {
    const idSlug = route.paramMap.get('idSlug');
    if (idSlug) {
        this.nodeSharedFacade.requestRouteById(idSlug);
    }
  }
}