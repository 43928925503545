import { Component, Input, ViewChild } from '@angular/core';
import { QuestionFooterComponent } from '../question-footer/question-footer.component';
import * as FlatModels from '@medsurf/flat-models';
import { MenuViewerFacade, TrainingViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-quiz-base-question',
  template: ``,
  styles: []
})
export abstract class BaseQuestionComponent<TQuestion extends FlatModels.QuestionEntityModels.Question, TResult extends FlatModels.TrainingViewerModels.TrainingResultType> {
  /**
   * Inputs
   */
  @Input() public index: number;
  @Input() public question: TQuestion;
  @Input() public trainingResult: TResult;

  /**
   * Child Views
   */
  @ViewChild('footer', {static: false}) public footer: QuestionFooterComponent;

  /**
   * Members
   */
  public showRequired = false;

  /**
   * Constructor
   *
   * @param trainingViewerFacade: TrainingViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   */
  public constructor(public trainingViewerFacade: TrainingViewerFacade,
                     public menuViewerFacade: MenuViewerFacade) {
  }

  /**
   * Is Correct
   */
  public abstract isCorrect(): boolean;

  /**
   * Pass Required
   */
  public abstract passRequired(): boolean;
}
