import { ImageEntityModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetEntitySuccess = '[STORE | ENTITY | IMAGE] Get Entity Success',
  GetEntitiesSuccess = '[STORE | ENTITY | IMAGE] Get Entities Success',
  AddEntity = '[STORE | ENTITY | IMAGE] Add Entity',
  UpdateEntity = '[STORE | ENTITY | IMAGE] Update Entity',
  RemoveEntity = '[STORE | ENTITY | IMAGE] Remove Entity',
  SetImageIndex = '[STORE | ENTITY | IMAGE] Set Image Index',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Entity Success
 */
export class GetEntitySuccess {
  public static readonly type = ActionTypes.GetEntitySuccess;

  public constructor(public entityQueryResult: ImageEntityModels.ImageEntityQueryResult) {
  }
}

/**
 * Get Entities Success
 */
export class GetEntitiesSuccess {
  public static readonly type = ActionTypes.GetEntitiesSuccess;

  public constructor(public entityQueryResults: ImageEntityModels.ImageEntityQueryResult[]) {
  }
}

/**
 * Add Entity
 */
export class AddEntity {
  public static readonly type = ActionTypes.AddEntity;

  public constructor(public entity: ImageEntityModels.Image) {
  }
}

/**
 * Update Entity
 */
export class UpdateEntity {
  public static readonly type = ActionTypes.UpdateEntity;

  public constructor(public entity: ImageEntityModels.Image) {
  }
}

/**
 * Remove Entity
 */
export class RemoveEntity {
  public static readonly type = ActionTypes.RemoveEntity;

  public constructor(public entity: ImageEntityModels.Image) {
  }
}

/**
 * Set Image Index
 */
export class SetImageIndex {
  public static readonly type = ActionTypes.SetImageIndex;

  public constructor(public imageIndex: number) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>