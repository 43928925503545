<div class="list-group">
  <div *ngFor="let poi of pois"
       class="list-group-item list-group-item-action flex-column align-items-start cursor-pointer">
    <div class="d-flex w-100 justify-content-between"
         (click)="toggleExpandedPoi(poi)">
      <h5>
        {{ poi.name}}
      </h5>
    </div>

    <div *ngIf="expandedPoi?.id === poi.id">
      <div class="notes mt-1" *ngIf="poi.notes && poi.notes.length > 0">
        <div *ngFor="let note of poi.notes">
          <div>
            <strong class="note-name">{{ note.name }}</strong>
          </div>
          <small class="note-text">{{ note.text }}</small>
        </div>
      </div>
      <div *ngIf="poi.annotations && poi.annotations.length > 0" class="annotations mt-1">
        <div>
          <strong>{{ 'annotations' | translate }}</strong>
        </div>
        <div *ngFor="let annotation of poi.annotations">
          <div *ngIf="annotationsMap.get(annotation.id) as full">
            <span class="annotation-link">
              <small>{{ full.label?.text }}</small>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
