import { v4 as uuid } from 'uuid';
import * as FlatModels from '@medsurf/flat-models';
import { Element } from '../element';
import { Format } from '../../../../../common/models/format';
import { Label } from './label/label';
import { Line } from './pointer/line';
import { Pointer } from './pointer/pointer';
import { Selftest } from './selftest/selftest';
import { Keymap } from './keymap';
import { KeymapSelftest } from './keymapSelftest';

/**
 * Marker
 */
export class Marker extends Element<FlatModels.ImageViewerModels.MarkerModel> {
  /**
   * Members
   */
  private _selftests: any[];
  private _labels: any[];
  private _pointers: any[];
  private _labelOffset: number;
  private _labelOffsetY: number;
  private _forkLine: any;
  private _isText: any;

  /**
   * Constructor
   *
   * @param model: FlatModels.ImageViewerModels.MarkerModel
   * @param _format: Format
   * @param localPaper
   * @param _mainLayer
   * @param container
   * @param _imageScale
   * @param _imageOffset
   */
  public constructor(model: FlatModels.ImageViewerModels.MarkerModel,
                     private _format: Format,
                     public localPaper,
                     private _mainLayer,
                     public container,
                     public _imageScale = 1,
                     public _imageOffset?) {
    super(model);
    this._selftests = [];
    this._labels = [];
    this._pointers = [];
    this._labelOffset = 5;
    this._labelOffsetY = 20;
    this._forkLine = null;
    this._isText = null;
    if (this.model.annotation && this.model.source) {
      this._init();
    }
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this._element = new this.localPaper.Group();
    this._mainLayer.addChild(this._element);
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    this.cleanUp();
    if (this.hasPointer()) {
      this.drawPointers();
      this.drawForkLine();
    }
    this.drawLabels();
    this.drawSelftest();
    this.arrange();
  }

  /**
   * Draw Labels
   */
  public drawLabels() {
    if (!this.model.label) { return; }
    if (!this.model.label.text) { return; }
    const label = new Label({id: uuid(), text: this.model.label.text}, this._format, this);
    this._element.addChild(label.element);
    this._labels.push(label);
  }

  /**
   * Draw Pointers
   */
  public drawPointers() {
    for (const target of this.model.targets) {
      let source = this.container.getAbsoluteCoords(
        this.model.source.position.x,
        this.model.source.position.y,
        this._imageOffset,
        this._imageScale
      );
      if (this.model.annotation.fork.x && this.model.annotation.fork.y) {
        source = this.container.getAbsoluteCoords(
          this.model.annotation.fork.x,
          this.model.annotation.fork.y,
          this._imageOffset,
          this._imageScale
        );
      }
      const pointer = new Pointer(
        (target.endType) ? (target.endType) : 'dot',
        this._format,
        source,
        this.container.getAbsoluteCoords(target.position.x, target.position.y, this._imageOffset, this._imageScale),
        this
      );
      this._element.addChild(pointer.end.element.element);
      this._element.addChild(pointer.line.element);
      this._pointers.push(pointer);
    }
  }

  /**
   * Draw Fork Line
   */
  public drawForkLine() {
    if (this.model.annotation.fork.x && this.model.annotation.fork.y) {
      if (this._forkLine) {
        this.removeForkLine();
      }
      const source = this.container.getAbsoluteCoords(
        this.model.source.position.x,
        this.model.source.position.y,
        this._imageOffset,
        this._imageScale
      );
      const target = this.container.getAbsoluteCoords(
        this.model.annotation.fork.x,
        this.model.annotation.fork.y,
        this._imageOffset,
        this._imageScale
      );
      this._forkLine = new Line(this._format, source, target, this);
      this._forkLine.element.marker = this;
      this._element.addChild(this._forkLine.element);
    }
  }

  /**
   * Draw Selftest
   */
  public drawSelftest() {
    if (
        // eslint-disable-next-line no-prototype-builtins
      this.model.annotation.hasOwnProperty('selftest') &&
        // eslint-disable-next-line no-prototype-builtins
      this.model.annotation.selfTest.hasOwnProperty('ignore') &&
      this.model.annotation.selfTest.ignore) {
      return;
    }
    let targets: FlatModels.AnnotationTargetEntityModels.AnnotationTarget[] = this.model.targets;
    this._isText = !(targets && targets.length > 0) ||
      !(targets[0].position.x && targets[0].position.y) ||
      !(targets[0].endType) ||
      (targets[0].position.x === this.model.source.position.x && targets[0].position.y === this.model.source.position.y);
    targets = this._isText && this.model.label.text ? [{
      id: uuid(),
      position: {
        x: this.model.source.position.x,
        y: this.model.source.position.y
      }
    }] : targets;
    for (let target of targets) {
      target = this.container.getAbsoluteCoords(target.position.x, target.position.y, this._imageOffset, this._imageScale);
      const selftest = new Selftest(this.model, this._format, target, this);
      this._element.addChild(selftest.element);
      selftest.element.visible = false;
      this._selftests.push(selftest);
    }
  }

  /**
   * Arrange
   */
  public arrange() {
    const zoom = this.localPaper.view.zoom;
    const coords = this.container.getAbsoluteCoords(
      this.model.source.position.x,
      this.model.source.position.y,
      this._imageOffset,
      this._imageScale
    );
    this.scale(zoom);
    if (this._labels.length > 0) {
      this._labels[0].setPosition(coords.x, coords.y);
      if (this._isText) {
        const x = this._labels[0]._element.bounds.x + this._labels[0]._element.bounds.width / 2;
        const y = this._labels[0]._element.bounds.y + this._labels[0]._element.bounds.height / 2;
        this._selftests[0].setPosition(x, y);
      }
    }
    this.state = this._state;
  }

  /**
   * Scale
   *
   * @param zoom: number
   */
  public scale(zoom: number) {
    if (this._labels.length > 0) {
      this._labels[0].setScale(zoom);
    }
    for (const pointer of this._pointers) {
      pointer.setScale(zoom);
    }
  }

  /**
   * Is In View Port
   *
   * @param rect
   */
  public isInViewPort(rect) {
    const viewRect = this.localPaper.view.bounds;
    return (rect.x + rect.width) < (viewRect.x + viewRect.width)
      && (rect.x) > (viewRect.x)
      && (rect.y) > (viewRect.y)
      && (rect.y + rect.height) < (viewRect.y + viewRect.height);
  }

  /**
   * Set Position
   *
   * @param x: number
   * @param y: number
   */
  public setPosition(x: number,
                     y: number) {
    this._element.position.x = x;
    this._element.position.y = y;
  }

  /**
   * Set Standard Selftest Radius
   */
  public setStandardSelftestRadius() {
    for (const selftest of this._selftests) {
      selftest.setStandardRadius();
    }
  }

  /**
   * Resize
   *
   * @param w: number
   * @param h: number
   */
  public resize(w: number,
                h: number) {
    this.cleanUp();
    this.draw();
  }

  /**
   * @example
   *   // state: visible/default
   *   this.setVisibilitySettings(
   *     {labels: true,
   *     pointers: true,
   *     selftests: false,
   *     tooltips: false
   *     }
   *
   * @param isVisible Object
   * @this Marker
   * @return void
   */
  public setVisibilitySettings(isVisible) {
    for (const label of this._labels) {
      label._element.visible = isVisible.labels;
    }
    for (const pointer of this._pointers) {
      // eslint-disable-next-line no-prototype-builtins
      if (isVisible.hasOwnProperty('index')) {
        pointer._line._element.visible = (this._pointers.indexOf(pointer) === isVisible.index);
        pointer._end._element._element.visible = pointer._end._endType === 'none' ?
          false :
          (this._pointers.indexOf(pointer) === isVisible.index);
      } else {
        pointer._line._element.visible = isVisible.pointers;
        pointer._end._element._element.visible = pointer._end._endType === 'none' ? false : isVisible.pointers;
      }
    }
    if (this._forkLine) {
      this._forkLine.element.visible = isVisible.pointers;
    }
    for (const selftest of this._selftests) {
      // eslint-disable-next-line no-prototype-builtins
      if (isVisible.hasOwnProperty('index')) {
        if (this._selftests.indexOf(selftest) === isVisible.index) {
          selftest._element.visible = isVisible.selftests;
        }
      } else {
        selftest._element.visible = isVisible.selftests;
      }
    }
    if (isVisible.selftests === false) {
      // remove old label
      if (this.container._selftestLabel) {
        this.container.removeElement(this.container._selftestLabel);
      }

      // draw new label if index is set
      if (isVisible.index !== undefined) {
        // Get keymap element
        const keymaps = this.container._elements.filter((element) => {
          return element instanceof Keymap;
        });

        if (keymaps.length > 0) {
          const key = keymaps
            .map((keymap) => keymap.model.columns).pop()
            .map((column) => column.labels)
            .reduce((a, b) => a.concat(b))
            .filter((value) => String(value.index) === String(this.model.label.text)).pop();

          if (key) {
            // setup keymap selftest
            this.container._selftestLabel = new KeymapSelftest(
              keymaps[0],
              this._format,
              this.localPaper,
              this._mainLayer,
              this.container,
              key,
              this._imageScale,
              this._imageOffset
            );
            this.container._selftestLabel.id = 'marker_selftest';
            this.container.addElement(this.container._selftestLabel);
            this.container._selftestLabel._element.bringToFront();
          }
        }
      }
    }
  }

  /**
   * Show Selected Marker
   *
   * @param index: number
   */
  public showSelectedMarker(index: number) {
    this._state = 'selected';
    this.setVisibilitySettings({labels: true, pointers: true, selftests: false, index: index});
  }

  /**
   * Getter useSymbol
   */
  public get useSymbol() {
    if (this._labels.length > 0) {
      return this._labels[0].useSymbol;
    }
    return false;
  }

  /**
   * Getter pointers
   */
  public get pointers() {
    return (this._pointers) ? this._pointers : null;
  }

  /**
   * Getter labels
   */
  public get labels() {
    return (this._labels) ? this._labels : null;
  }

  /**
   * Getter selftest
   */
  public get selftests() {
    return this._selftests ? this._selftests : null;
  }

  /**
   * Setter state
   *
   * There are four different states for each Marker object:
   *
   *  - default/visible: Toggle Marker is active, Toggle Selftest is not active
   *                     Pointers and Labels are visible, Selftests and Tooltips are hidden
   *  - hidden:          Toggle Marker is not active
   *                     All subelements/children are hidden
   *  - unselected:      Toggle Marker and Toggle Selftest are active
   *                     One of two states in selftest mode. Current marker has not been selected yet.
   *                     Only Selftest circles are visible
   *  - selected:        Toggle Marker and Toggle Selftest are active
   *                     One of two states in selftest mode. Current marker is selected.
   *                     Selftest circles and Tooltips are visible
   *
   * @param {string} state - State of this Marker object
   * @this Marker
   * @returns void
   */
  public set state(state) {
    let isVisible = {labels: true, pointers: true, selftests: false, tooltips: false};
    this._state = state;
    switch (state) {
      case 'hidden':
        isVisible = {labels: false, pointers: false, selftests: false, tooltips: false};
        this._element.opacity = 1;
        break;
      case 'unselected':
        isVisible = {labels: false, pointers: false, selftests: true, tooltips: false};
        this._element.opacity = 1;
        break;
      case 'selected':
        isVisible = {labels: false, pointers: false, selftests: true, tooltips: true};
        this._element.opacity = 1;
        break;
      case 'opaque':
        isVisible = {labels: false, pointers: false, selftests: true, tooltips: false};
        this._element.opacity = 0; // should be 0.3, bug paperjs has issues with opacity
        break;
      default:
        this._state = 'visible';
        this._element.opacity = 1;
        break;
    }
    this.setVisibilitySettings(isVisible);
  }

  /**
   * Remove Label
   */
  public removeLabels() {
    for (let label of this._labels) {
      label.cleanUp();
      label = null;
    }
    this._labels = [];
  }

  /**
   * Remove Pointers
   */
  public removePointers() {
    for (let pointer of this._pointers) {
      pointer.cleanUp();
      pointer = null;
    }
    this._pointers = [];
  }

  /**
   * Remove Selftests
   */
  public removeSelftests() {
    for (let selftest of this._selftests) {
      selftest.cleanUp();
      selftest = null;
    }
    this._selftests = [];
  }

  /**
   * Remove Fork Line
   */
  public removeForkLine() {
    if (this._forkLine) {
      this._forkLine.cleanUp();
      this._forkLine = null;
    }
  }

  /**
   * Has Pointer
   */
  public hasPointer() {
    let hasPointer = false;
    if (this._model.targets && this._model.targets.length > 0) {
      hasPointer = true;
      if (this._model.targets.length === 1) {
        if (this._model.targets[0].position.x === this._model.source.position.x &&
          this._model.targets[0].position.y === this._model.source.position.y) {
          hasPointer = false;
        }
      }
    }
    return hasPointer;
  }

  /**
   * Cleanup
   */
  public cleanUp() {
    this.removeLabels();
    this.removePointers();
    this.removeSelftests();
    this.removeForkLine();
  }
}
