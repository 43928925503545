import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { PointOfInterestEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import { ImageEntityState } from '../../internal';
import { patch } from '@ngxs/store/operators';

/**
 * Point Of Interest Entity Token
 */
export const POINT_OF_INTEREST_ENTITY_TOKEN = new StateToken<PointOfInterestEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.POINT_OF_INTEREST);

/**
 Point Of Interest Entity State Model
 */
export interface PointOfInterestEntityStateModel extends FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.PointOfInterestEntityModels.PointOfInterest> {
  poiId: string | null;
}

/**
 * Point Of Interest Entity State
 */
@State<PointOfInterestEntityStateModel>({
  name: POINT_OF_INTEREST_ENTITY_TOKEN,
  defaults: {
    poiId: null,
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class PointOfInterestEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector poiId$
   *
   * @param state: PointOfInterestEntityStateModel
   */
  @Selector([PointOfInterestEntityState])
  public static poiId$(state: PointOfInterestEntityStateModel): string | null {
    return state.poiId;
  }

  /**
   * Selector typedEntities$
   *
   * @param state: PointOfInterestEntityStateModel
   */
  @Selector([PointOfInterestEntityState])
  public static typedEntities$(state: PointOfInterestEntityStateModel): { [id: string]: FlatModels.PointOfInterestEntityModels.PointOfInterest } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.PointOfInterestEntityModels.PointOfInterest }
   */
  @Selector([PointOfInterestEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.PointOfInterestEntityModels.PointOfInterest }): FlatModels.PointOfInterestEntityModels.PointOfInterest[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.PointOfInterestEntityModels.PointOfInterest }
   */
  @Selector([PointOfInterestEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.PointOfInterestEntityModels.PointOfInterest }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.PointOfInterestEntityModels.PointOfInterest[]
   */
  @Selector([PointOfInterestEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.PointOfInterestEntityModels.PointOfInterest[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  /**
   * Selector currentSelectedSlidePois$
   *
   * @param entities: FlatModels.PointOfInterestEntityModels.PointOfInterest[]
   * @param currentSelectedSlideImage: FlatModels.ImageEntityModels.Image | null
   */
  @Selector([
    PointOfInterestEntityState.entities$,
    ImageEntityState.currentSelectedSlideImage$
  ])
  public static currentSelectedSlidePois$(entities: FlatModels.PointOfInterestEntityModels.PointOfInterest[],
                                          currentSelectedSlideImage: FlatModels.ImageEntityModels.Image | null) {
    return currentSelectedSlideImage && currentSelectedSlideImage.pois && Array.isArray(entities) && entities.filter(e => (currentSelectedSlideImage.pois as unknown as string[]).includes(e.id)) || [];
  }

  /**
   * Selector currentSelectedSlidePoi$
   *
   * @param currentSelectedSlidePois: FlatModels.PointOfInterestEntityModels.PointOfInterest[]
   * @param poiId: string | null
   */
  @Selector([
    PointOfInterestEntityState.currentSelectedSlidePois$,
    PointOfInterestEntityState.poiId$
  ])
  public static currentSelectedSlidePoi$(currentSelectedSlidePois: FlatModels.PointOfInterestEntityModels.PointOfInterest[],
                                         poiId: string | null) {
    return poiId !== null && Array.isArray(currentSelectedSlidePois) ? currentSelectedSlidePois.find(sp => sp.id === poiId) || null : null;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<PointOfInterestEntityStateModel>
   * @param patchState: StateContext<PointOfInterestEntityStateModel>
   * @param entityQueryResult: PointOfInterestEntityActions.GetEntitySuccess
   */
  @Action(PointOfInterestEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<PointOfInterestEntityStateModel>,
                          {entityQueryResult}: PointOfInterestEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<PointOfInterestEntityStateModel>
   * @param patchState: StateContext<PointOfInterestEntityStateModel>
   * @param entityQueryResults: PointOfInterestEntityActions.GetEntitiesSuccess
   */
  @Action(PointOfInterestEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<PointOfInterestEntityStateModel>,
                            {entityQueryResults}: PointOfInterestEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<PointOfInterestEntityStateModel>
   * @param patchState: StateContext<PointOfInterestEntityStateModel>
   * @param entity: PointOfInterestEntityActions.AddEntity
   */
  @Action(PointOfInterestEntityActions.AddEntity)
  @Undoable(PointOfInterestEntityActions.AddEntity, POINT_OF_INTEREST_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<PointOfInterestEntityStateModel>,
                   {entity}: PointOfInterestEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<PointOfInterestEntityStateModel>
   * @param patchState: StateContext<PointOfInterestEntityStateModel>
   * @param entity: PointOfInterestEntityActions.UpdateEntity
   */
  @Action(PointOfInterestEntityActions.UpdateEntity)
  @Undoable(PointOfInterestEntityActions.UpdateEntity, POINT_OF_INTEREST_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<PointOfInterestEntityStateModel>,
                      {entity}: PointOfInterestEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<PointOfInterestEntityStateModel>
   * @param patchState: StateContext<PointOfInterestEntityStateModel>
   * @param entity: PointOfInterestEntityActions.RemoveEntity
   */
  @Action(PointOfInterestEntityActions.RemoveEntity)
  @Undoable(PointOfInterestEntityActions.RemoveEntity, POINT_OF_INTEREST_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<PointOfInterestEntityStateModel>,
                      {entity}: PointOfInterestEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  /**
   * Set PoiId
   *
   * @param setState: StateContext<PointOfInterestEntityStateModel>
   * @param poiId: PointOfInterestEntityActions.SetPoiId
   */
  @Action(PointOfInterestEntityActions.SetPoiId)
  public setPoiId({setState}: StateContext<PointOfInterestEntityStateModel>,
                  {poiId}: PointOfInterestEntityActions.SetPoiId) {
    setState(
      patch({
        poiId: existing => (existing === poiId) ? null : poiId
      })
    );
  }

  //</editor-fold>
}
