import { HistoryControlModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  FinishSnapshot = '[STORE | CONTROL | HISTORY] Finish Snapshot',
  UndoSnapshot = '[STORE | CONTROL | HISTORY] Undo Snapshot',
  RedoSnapshot = '[STORE | CONTROL | HISTORY] Redo Snapshot',
  SaveSnapshots = '[STORE | CONTROL | HISTORY] Save Snapshots',
  SaveSnapshotsSuccess = '[STORE | CONTROL | HISTORY] Save Snapshots Success',
  SaveSnapshotsFailure = '[STORE | CONTROL | HISTORY] Save Snapshots Failure',
  /**
   * Request
   */
  SaveSnapshotsRequest = '[REQUEST | CONTROL | HISTORY] Save Snapshots Request',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Finish Snapshot
 */
export class FinishSnapshot {
  public static readonly type = ActionTypes.FinishSnapshot;
}

/**
 * Undo Snapshot
 */
export class UndoSnapshot {
  public static readonly type = ActionTypes.UndoSnapshot;
}

/**
 * Redo Snapshot
 */
export class RedoSnapshot {
  public static readonly type = ActionTypes.RedoSnapshot;
}

/**
 * Save Snapshots
 */
export class SaveSnapshots {
  public static readonly type = ActionTypes.SaveSnapshots;
}

/**
 * Save Snapshots Success
 */
export class SaveSnapshotsSuccess {
  public static readonly type = ActionTypes.SaveSnapshotsSuccess;
}

/**
 * Save Snapshots Failure
 */
export class SaveSnapshotsFailure {
  public static readonly type = ActionTypes.SaveSnapshotsFailure;
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Save Snapshots Request
 */
export class SaveSnapshotsRequest {
  public static readonly type = ActionTypes.SaveSnapshotsRequest;

  public constructor(public saveSnapshotItems: HistoryControlModels.SaveSnapshotItem[]) {
  }
}

// </editor-fold>
