<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="(slideViewerFacade.currentSelectedSlidePage$ | async) as page ">
  <ng-container *ngIf="page; else spinner">
    <ng-container *ngIf="(menuViewerFacade.showPrint$ | async) === false">
      <medsurf-slide-header [page]="page"></medsurf-slide-header>
      <div class="w-100 h-100"
           [@routeAnimations]="position">
        <!--<router-outlet></router-outlet>-->
        <medsurf-slide-main></medsurf-slide-main>
      </div>
      <medsurf-slide-footer></medsurf-slide-footer>
    </ng-container>
    <ng-container *ngIf="(menuViewerFacade.showPrint$ | async)">
      <medsurf-print></medsurf-print>
    </ng-container>
  </ng-container>
</ng-container>
