import { User } from './user.model';
import { Base } from './base.model';
import { VocabularyGroup } from './vocabulary.model';


export interface Page extends Base {
  title?: string;
  type?: PageType;
  editedBy?: User;
  editedAt?: Date;
  authorNote?: string;
}

export enum PageType {
  FOLDER = 'Folder',
  GRID = 'Grid',
  CASE = 'Case',
  SLIDE = 'Slide',
  TRAINING = 'Training',
  QUIZ_START = 'QuizStart',
  QUIZ_RUN = 'QuizRun',
  VOCABULARY = 'Vocabulary',
}

export interface QuizStart extends Page {
}

export interface Vocabulary extends Page {
  description?: string;
  vocabularyGroups: VocabularyGroup[];
}

