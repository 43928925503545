<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="question && trainingResult && trainingResult.answer !== undefined; else spinner">
  <!-- Header -->
  <medsurf-quiz-question-header [index]="(trainingViewerFacade.selectedQuestionIndex$(question.id) | async) || index"
                                [checkedAndCorrect]="trainingResult.showFeedback && isCorrect()"
                                [questionType]="question.type">
  </medsurf-quiz-question-header>

  <!-- Question Form -->
  <form name="form"
        class="mdsf-quiz-form">
    <!-- Question Text -->
    <medsurf-text [text]="question.content"></medsurf-text>

    <!-- Choices -->
    <div class="choice"
         [class.choice-error]="trainingResult.showFeedback && trainingResult.answer !== '' && question.solution !== trainingResult.answer"
         [class.choice-correct]="trainingResult.showFeedback && trainingResult.answer !== ''&& question.solution === trainingResult.answer"
         [class.no-hover]="trainingResult.showFeedback">
      <div ngbRadioGroup
           class="btn-group-toggle m-1"
           data-toggle="buttons">
        <label ngbButtonLabel
               class="radio-custom mr-2 "
               [class.radio-custom-black]="trainingResult.answer === 1 || (question.solution === 1 && (menuViewerFacade.showPrintForAuthor$ | async))"
               [class.radio-custom-error]="trainingResult.showFeedback && trainingResult.answer === 1 && question.solution !== trainingResult.answer">
          <input ngbButton
                 type="radio"
                 name="choices{{question.order}}"
                 [value]="1"
                 (click)="onClick(1)"
                 [checked]="trainingResult.answer === 1">
          <i class="fa fa-check mr-2"></i> <b>{{ 'yes' | translate}}</b>
          <i *ngIf="trainingResult.showFeedback && trainingResult.answer === 1 && question.solution !== trainingResult.answer" class="fal fa-times cross"></i>
        </label>
        <label ngbButtonLabel
               class="radio-custom"
               [class.radio-custom-black]="trainingResult.answer === 0 || (question.solution === 0 && (menuViewerFacade.showPrintForAuthor$ | async))"
               [class.radio-custom-error]="trainingResult.showFeedback && trainingResult.answer === 0 && question.solution !== trainingResult.answer">
          <input ngbButton
                 type="radio"
                 name="choices{{question.order}}"
                 [value]="0"
                 (click)="onClick(0)"
                 [checked]="trainingResult.answer === 0">
          <i class="fa fa-times mr-2"></i> <b>{{ 'no' | translate}}</b>
          <i *ngIf="trainingResult.showFeedback && trainingResult.answer === 0 && question.solution !== trainingResult.answer" class="fal fa-times cross"></i>
        </label>
      </div>
    </div>

    <!-- Explanation -->
    <div class="form-control-explanation"
         *ngIf="question.explanation && (trainingResult.showExplanation || (menuViewerFacade.showPrintForAuthor$ | async))">
      <strong>{{ 'explanation' | translate }}:</strong>
      <medsurf-text [text]="question.explanation"></medsurf-text>
    </div>

    <!-- Footer -->
    <medsurf-quiz-question-footer #footer
                                  class="mt-2"
                                  *ngIf="(menuViewerFacade.showPrint$ | async) === false && question.solution !== null"
                                  [question]="question"
                                  [trainingResult]="trainingResult"
                                  [isCorrect]="isCorrect"
                                  [passRequired]="passRequired"
                                  [(showRequired)]="showRequired"
                                  [ngStyle]="{'order': 100}">
    </medsurf-quiz-question-footer>

  </form>
</ng-container>
