<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="question && trainingResult && trainingResult.answer !== undefined; else spinner">
  <!-- Header -->
  <medsurf-quiz-question-header [index]="(trainingViewerFacade.selectedQuestionIndex$(question.id) | async) || index"
                                [checkedAndCorrect]="trainingResult.showFeedback && isCorrect()"
                                [questionType]="question.type">
  </medsurf-quiz-question-header>

  <!-- Question Form -->
  <form name="form"
        class="mdsf-quiz-form"
        #container>
    <!-- Question Text -->
    <medsurf-text [text]="question.content"></medsurf-text>

    <!-- Free Text -->
    <div class="freetext-grid">
      <div class="input-group">
        <textarea #textarea
                  *ngIf="(menuViewerFacade.showPrintForAuthor$ | async) === false"
                  rows="3"
                  name="freeTextQuestion{{question.order}}"
                  class="form-control textarea-answers"
                  [(ngModel)]="trainingResult.text"
                  [ngModelOptions]="{ updateOn: 'blur' }"
                  (ngModelChange)="onChange()"
                  (input)="resizeTextarea()"
                  [readOnly]="trainingResult.showFeedback">
        </textarea>
        <textarea #textarea
                  *ngIf="(menuViewerFacade.showPrintForAuthor$ | async)"
                  rows="3"
                  name="freeTextQuestion{{question.order}}"
                  class="form-control textarea-answers"
                  [value]="question.solutionText | stripHtml"
                  (change)="onChange()"
                  (input)="resizeTextarea()"
                  [readOnly]="trainingResult.showFeedback">
        </textarea>
      </div>

      <div class="answer-group" *ngIf="trainingResult.showFeedback">

        <!-- Solution Text -->
        <div class="" *ngIf="question.solutionText && trainingResult.showFeedback">
          <strong>{{ 'Solution' | translate }}</strong>
          <medsurf-text [text]="question.solutionText"></medsurf-text>
        </div>

        <!-- Explanation -->
        <div *ngIf="question.explanation && trainingResult.showExplanation">
          <strong>{{ 'explanation' | translate }}</strong>
          <medsurf-text [text]="question.explanation"></medsurf-text>
        </div>

        <!-- Self Evaluation -->
        <ng-container *ngIf="trainingResult.showFeedback">
          <strong>{{ 'self_declaration' | translate }}</strong>
          <p>{{ 'self_declaration_text' | translate}}</p>
          <div class="d-flex">
            <button type="button"
                    class="mdsf-btn mdsf-btn-correct d-flex mr-2"
                    role="button"
                    (click)="evaluateQuestion(true)"
                    [class.mdsf-btn-correct-selected]="this.trainingResult.answer === true">
              <i class="fa fa-fw fa-check"></i> {{ 'correct' | translate }}
            </button>
            <button type="button"
                    class="mdsf-btn mdsf-btn-error d-flex"
                    role="button"
                    (click)="evaluateQuestion(false)"
                    [class.mdsf-btn-error-selected]="this.trainingResult.answer === false">
              <i class="fa fa-fw fa-times"></i> {{ 'wrong' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
      <medsurf-quiz-question-footer #footer
                                    class="mt-2"
                                    *ngIf="(menuViewerFacade.showPrint$ | async) === false && question.solutionText !== null"
                                    [question]="question"
                                    [trainingResult]="trainingResult"
                                    [isCorrect]="isCorrect"
                                    [passRequired]="passRequired"
                                    [(showRequired)]="showRequired"
                                    [ngStyle]="{'order': 100}">
      </medsurf-quiz-question-footer>
    </div>

    <!-- Print Explanation -->
    <div class="mt-3" *ngIf="question.explanation && (menuViewerFacade.showPrintForAuthor$ | async)">
      <strong>{{ 'explanation' | translate }}</strong>
      <medsurf-text [text]="question.explanation"></medsurf-text>
    </div>
  </form>
</ng-container>
