import { ActivatedRouteSnapshot } from '@angular/router';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  SetActivatedRoute = '[STORE | CONTROL | NAVIGATION] Set Activated Route',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Set Activated Route
 */
export class SetActivatedRoute {
  public static readonly type = ActionTypes.SetActivatedRoute;

  public constructor(public activatedRoute: ActivatedRouteSnapshot) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
