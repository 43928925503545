import { Component } from "@angular/core";

@Component({
  selector: 'medsurf-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class ShortcutsComponent {
  public isOpen = false;

  public onMousedown() {
    this.isOpen = !this.isOpen;
  }

  public onMouseenter() {
    setTimeout(() => {
      this.isOpen = true;
    })
  }

  public onMouseleave() {
    setTimeout(() => {
      this.isOpen = false;
    })
  }
}
