import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NavbarComponent } from './navbar/navbar.component';
import { ModalComponent } from './modal/modal.component';
import { FooterComponent } from './footer/footer.component';
import { NoticeComponent } from './notice/notice.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { RouterModule } from '@angular/router';
import { ListnavComponent } from './listnav/listnav.component';
import { ModalGridComponent } from './modal/grid/modal-grid.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TextFormatComponent } from './text-format/text-format.component';
import { MedsurfLibModule } from '@medsurf/module';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { HighlightViewerPipe, LogPipe, SortBySharedPipe, ScrollTopPipe } from '@medsurf/flat-pipes';
import {
  AlertControlComponent,
  ImageLoaderSharedComponent,
  ReconnectControlComponent,
  SpinnerControlComponent
} from '@medsurf/flat-components';

@NgModule({
  declarations: [
    NavbarComponent,
    ModalComponent,
    FooterComponent,
    NoticeComponent,
    FeedbackComponent,
    ListnavComponent,
    ModalGridComponent,
    SidebarComponent,
    TextFormatComponent,
    CookieConsentComponent,
    // Flat Components
    AlertControlComponent,
    ReconnectControlComponent,
    SpinnerControlComponent,
    ImageLoaderSharedComponent,
    // Flat Pipes
    HighlightViewerPipe,
    LogPipe,
    ScrollTopPipe,
    SortBySharedPipe,
  ],
  imports: [
    MedsurfLibModule,
    CommonModule,
    NgbModule,
    TranslateModule.forChild(),
    FormsModule,
    RouterModule,
  ],
  exports: [
    NavbarComponent,
    ModalComponent,
    FooterComponent,
    NoticeComponent,
    FeedbackComponent,
    ListnavComponent,
    SidebarComponent,
    TextFormatComponent,
    CookieConsentComponent,
    // Flat Components
    AlertControlComponent,
    ReconnectControlComponent,
    SpinnerControlComponent,
    // Flat Pipes
    LogPipe,
    ScrollTopPipe,
    SortBySharedPipe,
    ImageLoaderSharedComponent,
  ]
})
export class MedsurfViewerCommonModule {
}
