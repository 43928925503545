import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { MenuViewerFacade, NavigationControlFacade, SlideViewerFacade } from '@medsurf/flat-facades';
import { v4 } from 'uuid';
import { Store } from '@ngxs/store';
import { combineLatest, map, ReplaySubject } from 'rxjs';

@Component({
  selector: 'medsurf-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {
  /**
   * Inputs
   */
  @Input() public set text(v) { this.text$.next(v) };
  public readonly text$ = new ReplaySubject<string>(1);
  @Input() public isSlideDescription: boolean;

  /**
   * View Children
   */
  @ViewChild('textElement', {static: false}) public textElement: ElementRef;

  /**
   * Members
   */
  private uuidRegExp = '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}';
  public test$ = combineLatest([
    this.text$.pipe(map(text => this.parseInteractiveMedia(text))),
    this.slideViewerFacade.currentActiveEvents$,
    this.slideViewerFacade.currentSelectedSlideGroups$,
    this.slideViewerFacade.themeColor$
  ]).pipe(map(([text, events, groups, themeColor]) => {
    const preparedText = this.prepareLinks(text, themeColor, groups, events);
    return preparedText
  }))

  /**
   * Constructor
   *
   * @param navigationControlFacade: NavigationControlFacade
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   */
  constructor(public navigationControlFacade: NavigationControlFacade,
              public slideViewerFacade: SlideViewerFacade,
              public menuViewerFacade: MenuViewerFacade,
              public store: Store) {
  }

  public prepareLinks(
    text: string, 
    themeColor: string, 
    groups: FlatModels.GroupEntityModels.Group[], 
    events: FlatModels.MediaInteractionViewerModels.MediaInteractionEvent[]
  ) {
    const el = document.createElement('div');
    el.innerHTML = text;

    const actionElements = Array.from(el.querySelectorAll('[data-mia-action]'));
    actionElements.forEach((el: HTMLElement) => {
      el.style.cursor = 'pointer';
    })

    events?.forEach(event => {
      if (event.type === 'text-highlight') {
        const highlight: HTMLElement = el.querySelector(`[data-mia-id="${event.id}"]`);
        if (!highlight) return;
        
        if (event.classes || event.styles) {
          if (event.classes) highlight.className += ` ${event.classes}`
          if (event.styles) highlight.style.cssText += event.styles;
        } else {
          highlight.classList.add(themeColor);
        }
        highlight.classList.add('text-highlight');
        return;
      }
    })

    const links = Array.from(el.getElementsByTagName('a'));
    links.forEach((link) => {
      if (link.classList.contains('internal') || link.classList.contains('external')) {
        link.classList.add(themeColor);
        link.target = link.target ?? '_blank';

      } else if (link.className === '') {
        link.classList.add(themeColor);
        link.target = link.target ?? '_blank';

      } else if (link.classList.contains('marker')) {
        let updateColor = false;
        let href: string = link.href.replace(/^(http|https):\/\/([a-zA-Z0-9:.]*\/)+/, '');
        if (!href) {
          updateColor = true;
          href = link.id;
          if (!href) {
            return;
          }
        }
        if (link.innerText.trim() === '<') {
          link.innerHTML = '<i class="fa fa-location-dot"></i>';
        }

        // Layer
        let layerId = null;
        if (href.includes('layer')) {
          const layerRegex = new RegExp('layer-([0-9]+)', 'g');
          const layerResult = layerRegex.exec(href);
          if (layerResult && layerResult.length === 2) {
            layerId = layerResult[1];
          }
        }
        // Sequence
        let sequenceId = null;
        if (href.includes('sequence')) {
          const sequenceRegex = new RegExp('sequence-([0-9]+)', 'g');
          const sequenceResult = sequenceRegex.exec(href);
          if (sequenceResult && sequenceResult.length === 2) {
            sequenceId = sequenceResult[1];
          }
        }
        // Marker
        const markerRegex = new RegExp(`(group|annotation)-(${this.uuidRegExp})`);
        const markerResult = markerRegex.exec(href);
        if (markerResult && markerResult.length === 3) {
          href = (markerResult[0].includes('group') || markerResult[1].includes('annotation')) ?
            markerResult[0] :
            `annotation-${markerResult[2]}`;
        }

        const [type, ...idParts] = href.split('-');
        const id = idParts.join('-');

        if (type && id) {
          const group = groups.find(g => g.id === id);
          link.style.color = group && group.color ? group.color : themeColor;
          if (!updateColor) {
            link.id = href;
            link.type = 'group';
            link.removeAttribute('href');
            link.dataset.groupId = id;
            link.dataset.layerId = layerId;
            link.dataset.sequenceId = sequenceId;
            link.style.cursor = 'pointer';
          }
        }
      }
    })
    return el.innerHTML;
  }

  public onLinkClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const el = (target.dataset.miaAction ? target : target.closest('[data-mia-action]')) as HTMLLinkElement | undefined;
    if (el) {
      const action = el.dataset.miaAction;
      
      if (action === 'jump') {
        const to = parseFloat(el.dataset.miaTo);
        return this.slideViewerFacade.requestJumpToTime(to);
      }
    }
  }

  public onLinkOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const el = (target.classList.contains('marker') ? target : target.closest('.marker')) as HTMLLinkElement | undefined;
    if (el) {
      this.slideViewerFacade.requestSetGroupId(null);
    }
  }

  public onLinkOver(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const el = (target.classList.contains('marker') ? target : target.closest('.marker')) as HTMLLinkElement | undefined;
    if (el) {
      const groupId = el.dataset.groupId;
      const layerId = el.dataset.layerId;
      const sequenceId = el.dataset.sequenceId;
      if (groupId) this.slideViewerFacade.requestSetGroupId(groupId);
      if (layerId) this.slideViewerFacade.requestSetLayerIndex(Number.parseInt(layerId, 10));
      if (sequenceId) this.slideViewerFacade.requestSetImageIndex(Number.parseInt(sequenceId, 10));
    }
  }

  public parseInteractiveMedia(text: string) {
    const el = document.createElement('div');
    el.innerHTML = text;
    if (el) {
      const events: FlatModels.MediaInteractionViewerModels.MediaInteractionEvent[] = [];

      const mediaInteractionEventElements = Array.from(el.querySelectorAll('[data-mia-event]'));
      mediaInteractionEventElements.forEach((el: HTMLElement) => {
        const type = el.dataset.miaEvent;
        const id = v4();
        el.dataset.miaId = id;

        if (type === 'text-highlight') {
          const from = parseFloat(el.dataset.miaFrom);
          const to = parseFloat(el.dataset.miaTo);
          const styles = el.dataset.miaStyles;
          const classes = el.dataset.miaClasses;
          return events.push({
            type,
            id,
            from,
            to,
            styles,
            classes
          })
        }
        if (type === 'modal') {
          const from = parseFloat(el.dataset.miaFrom);
          const to = parseFloat(el.dataset.miaTo);
          const title = el.dataset.miaTitle;
          el.style.display = 'none';
          return events.push({
            type,
            id,
            from,
            to,
            title,
            content: el.innerHTML
          })
        }
      });
      this.slideViewerFacade.requestSetEvents(events);
    }
    return el.innerHTML;
  }
}