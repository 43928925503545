import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { FreeForm, ImagePosition, Keymap, Marker, Shape } from '@medsurf/models';
import { Annotation, AnnotationTarget, AnnotationType, TipType } from '@medsurf/models';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AnnotationService {

  constructor() { }

  public createAnnotation(text?: string) {
    const annotation: Annotation = {
      id: uuid(),
      source: {
        position: {
          x: 0.5,
          y: 0.5
        }
      },
      fork: {
        x: undefined,
        y: undefined
      },
      label: {
        text: text || 'Neuer Marker',
        pivot: 'bottomCenter',
        background: {
          opacity: 0
        }
      },
      selfTest: {
        ignore: false
      },
      targets: [],
      type: AnnotationType.MARKER,
      dirty: true
    };

    return annotation;
  }

  public createMarker(endType: TipType, text?: string) {
    const marker: Marker = this.createAnnotation(text);
    this.addTarget(marker, endType);

    return marker;
  }

  public createFreeForm(shape?: Shape, text?: string) {
    const freeForm: FreeForm = Object.assign(this.createAnnotation(text), {
      freeFormStyle: {
        height: 0.1,
        width: 0.1,
        smoothFactor: 0.3,
        border: true,
      },
      target: {
        endType: undefined
      },
      path: [],
      type: AnnotationType.FREE_FORM,
      shape
    });

    return freeForm;
  }

  public createKeymap() {
    const keymap: Keymap = Object.assign(this.createAnnotation(), {
      columns: [{
        order: 0,
        labels: [{
          order: 0,
          index: '1',
          text: 'Legende 1',
          pivot: 'bottomCenter'
        }, {
          order: 1,
          index: '2',
          text: 'Legende 2',
          pivot: 'bottomCenter'
        }]
      }],
      keymapStyle: {
        background: null,
        backgroundColor: null,
        columnDistance: null,
        fontSize: null,
        indexWidth: null,
        opacity: null,
        rowDistance: null
      },
      type: AnnotationType.KEYMAP
    });

    keymap.columns.map(column => column.labels.map(row => {
      row.annotation = this.createMarker(TipType.DOT, row.index);
    }));

    return keymap;
  }

  public adjustPosition(annotation: Annotation, position: ImagePosition, isVM: boolean) {
    const prevSourcePosition = {
      x: annotation.source?.position?.x || 0, 
      y: annotation.source?.position?.y || 0
    };
    const prevZoom = annotation.source?.zoom || 1;
    const scale = isVM ? position.zoom / prevZoom : 1;
    const normScale = position.width / 800;

    const aspectRatio = position.width / position.height;
    const viewportWidth = 1 / position.zoom;

    const freeformWidth = viewportWidth * 0.1;
    const freeformHeight = freeformWidth * aspectRatio;


    const prevFontSize = annotation.label?.fontSize || (16 * normScale);
    const prevStrokeWidth = (annotation as FreeForm).freeFormStyle?.strokeWidth || (2 * normScale);
    const fontSize = Math.ceil(prevFontSize / scale); 
    const strokeWidth = Math.ceil(prevStrokeWidth / scale);

    const sourcePosition = {
      x: position.x + freeformWidth / 2, 
      y: position.y - freeformHeight / 2
    };
    
    if (!annotation.source) annotation.source = {};
    annotation.source.position = sourcePosition;
    annotation.source.zoom = isVM ? position.zoom : 1;

    
    if (annotation.fork?.x && annotation.fork?.y) {
      const offset = {
        x: (annotation.fork.x - prevSourcePosition.x) / scale,
        y: (annotation.fork.y - prevSourcePosition.y) / scale
      }
      annotation.fork.x = sourcePosition.x + offset.x;
      annotation.fork.y = sourcePosition.y + offset.y;
    }

    if (Array.isArray(annotation?.targets)) {
      annotation.targets.forEach(target => {
        const offset = {
          x: ((target.position?.x || 0) - prevSourcePosition.x) / scale,
          y: ((target.position?.y || 0) - prevSourcePosition.y) / scale
        }
        if (offset.x === 0 && offset.y === 0) {
          offset.y = 0.15 * aspectRatio / scale;
          offset.x = -0.025 / scale;
        }
        target.position = {
          x: sourcePosition.x + offset.x,
          y: sourcePosition.y + offset.y
        }
      })
    }

    if (annotation.type === AnnotationType.FREE_FORM) {
      const freeform = (annotation as FreeForm);
      if (!freeform.freeFormStyle) freeform.freeFormStyle = {};
      freeform.freeFormStyle.strokeWidth = isVM ? strokeWidth : 2;
      freeform.freeFormStyle.width = isVM ? freeformWidth : 0.1;
      freeform.freeFormStyle.height = isVM ? freeformHeight : 0.1;
      freeform.path?.forEach(point => {
        const offset = {
          x: ((point.x || 0) - prevSourcePosition.x) / scale,
          y: ((point.y || 0) - prevSourcePosition.y) / scale
        }
        point.x = sourcePosition.x + offset.x
        point.y = sourcePosition.y + offset.y;
      })
    }

    if (!annotation.label) annotation.label = {};
    annotation.label.fontSize = isVM ? fontSize : 12;
  }

  public addTarget(annotation: Annotation, endType?: TipType, zoom?: number) {
    if (!endType) {
      endType = TipType.DOT;
    }
    if (!annotation.targets) {
      annotation.targets = [];
    }
    let target: AnnotationTarget;
    const lastTarget = annotation.targets[annotation.targets.length - 1];
    const sourcePosition = { x: annotation.source?.position?.x || 0.5, y: annotation.source?.position?.y || 0.5 };

    if (lastTarget) {
      const targetPosition = { x: lastTarget.position?.x || 0.5, y: lastTarget.position?.y || 0.5 };
      const rotationAngle = -10 / 180 * Math.PI;
      const delta = { x: targetPosition.x - sourcePosition.x, y: targetPosition.y - sourcePosition.y };
      const rotatedDelta = {
        x: delta.x * Math.cos(rotationAngle) - delta.y * Math.sin(rotationAngle),
        y: delta.x * Math.sin(rotationAngle) + delta.y * Math.cos(rotationAngle)
      }
      target = cloneDeep(lastTarget);
      target.id = undefined;
      target.position = {
        x: sourcePosition.x + rotatedDelta.x,
        y: sourcePosition.y + rotatedDelta.y
      }
    } else {
      const offset = zoom ? { x: -0.025 / zoom, y: 0.15 / zoom } : { x: -0.025, y: 0.15 };
      target = {
        endType,
        position: {
          x: annotation.source?.position?.x ? annotation.source?.position?.x + offset.x : 0.5,
          y: annotation.source?.position?.y ? annotation.source?.position?.y + offset.y : 0.5,
        }
      };
    }
    annotation.targets.push(target);
    annotation.dirty = true;
    this.validate(annotation);
  }

  public removeTarget(annotation: Annotation) {
    if (annotation.targets.length > 0) {
      annotation.targets.pop();
    }
    this.validate(annotation);
  }

  private validate(annotation: Annotation) {
    if (annotation.targets && annotation.targets.length === 0) {
      annotation.label.pivot = 'bottomCenter';
    }
  }
}
