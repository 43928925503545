/**
 * Viewer Display mode
 */
export enum ViewerDisplayMode {
  NORMAL = 'NORMAL',
  FULLSCREEN = 'FULLSCREEN',
  PRINT = 'PRINT'
}

/**
 * Viewer Print mode
 */
export enum ViewerPrintMode {
  PRINT_AUTHOR = 'PRINT_AUTHOR',
  PRINT_USER = 'PRINT_USER'
}
