import { Line } from './line';
import { End } from './end/end';

export class Pointer {
  /**
   * Members
   */
  private _end: any;
  private _line: any;

  /**
   * Constructor
   *
   * @param _endType
   * @param _format
   * @param _source
   * @param _target
   * @param _marker
   */
  public constructor(private _endType,
                     private _format,
                     private _source,
                     private _target,
                     private _marker) {
    this._end = null;
    this._line = null;
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init () {
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    if (this._end && this._line) {
      this.cleanUp();
    }
    this._end = new End(this._endType, this._format, this._target, this._marker);
    this._line = new Line(this._format, this._source, this._target, this._marker);
    this.arrange();
  }

  /**
   * Arrange
   */
  public arrange() {
    if (this._endType === 'circle') {
      const intersections = this._line.element.segments[0].path.getIntersections(this._end.element.element);
      if (intersections.length) {
        this._line.element.segments[1].point = intersections[0].point;
      }
    } else if (this._endType === 'arrow') {
      this._end.element.path.rotation = 90 + this._end.element.angle(
        this._line.element.segments[0].point.x,
        this._line.element.segments[0].point.y,
        this._line.element.segments[1].point.x,
        this._line.element.segments[1].point.y
      );
    }
  }

  /**
   * Set Scale
   * @param scale
   */
  public setScale(scale) {
    this._line.setScale(scale);
    this._end.setScale(scale);
    this.arrange();
  }

  /**
   * Getter end
   */
  public get end() {
    return this._end;
  }

  /**
   * Getter line
   */
  public get line() {
    return this._line;
  }

  /**
   * Clean Up
   */
  public cleanUp() {
    if (this._end) {
      this._end.cleanUp();
    }
    if (this._line) {
      this._line.cleanUp();
    }
  }
}
