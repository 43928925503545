// <editor-fold desc="Action Types">

/**
 * Store error action types
 */
export enum ErrorActionTypes {
  /**
   * Store
   */
  AuthError = '[STORE | ERROR] Auth Error',
  UserError = '[STORE | ERROR] User Error',
  /**
   * Request
   */
}


// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Auth Error
 */
export class AuthError {
  public static readonly type = ErrorActionTypes.AuthError;

  public constructor(public message: string, public errorCode?: number) {
  }
}

/**
 * User Error
 */
export class UserError {
  public static readonly type = ErrorActionTypes.UserError;

  public constructor(public message: string, public errorCode?: number) {}
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
