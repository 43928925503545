import { ImageViewerModels } from '@medsurf/flat-models';
import { Element } from '../element';
import { Line } from './line';
import { Path } from './path';
import { Free } from './free';
import { InteractiveArea } from './interactiveArea';

/**
 * Freeform
 */
export class Freeform extends Element<ImageViewerModels.FreeformModel> {
  /**
   * Members
   */
  private _shape: Path | Free | Line | InteractiveArea;
  public _labelVisible = true;

  /**
   * Constructor
   *
   * @param model: : ImageViewerModels.FreeformModel
   * @param _format
   * @param localPaper
   * @param _mainLayer
   * @param container
   * @param _imageScale
   * @param _imageOffset
   */
  public constructor(model: ImageViewerModels.FreeformModel,
                     private _format,
                     public localPaper,
                     private _mainLayer,
                     public container?,
                     public _imageScale = 1,
                     public _imageOffset?) {
    super(model);
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    if (!this._element) {
      this._element = new this.localPaper.Group();
      this._mainLayer.addChild(this._element);
    }
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    this._element.removeChildren();
    this._element.freeform = this;
    switch (this.model.annotation.shape) {
      case 'rectangle':
        this._shape = new Path(this._format, this, this.model, this.localPaper, 'rectangle');
        break;
      case 'ellipse':
        this._shape = new Path(this._format, this, this.model, this.localPaper, 'ellipse');
        break;
      case 'free':
        this._shape = new Free(this._format, this, this.model);
        break;
      case 'arrow':
        this._shape = new Path(this._format, this, this.model, this.localPaper, 'arrow');
        break;
      case 'triangle':
        this._shape = new Path(this._format, this, this.model, this.localPaper, 'triangle');
        break;
      case 'interactiveArea':
        this._shape = new InteractiveArea(this.model, this.localPaper, this._format, this);
        break;
      default:
        this._shape = new Line(this.model, this.localPaper, this._format, this);
        break;
    }
  }

  /**
   * Arrange
   */
  public arrange() {
    this.draw();
  }

  /**
   * Setter state
   *
   * @param state: string
   */
  public set state(state: string) {
    this._state = state;
    switch (state) {
      case 'visible':
        this._labelVisible = true;
        this._element.visible = true;
        this._element.opacity = 1;
        break;
      case 'opaque':
        this._labelVisible = false;
        this._element.visible = true;
        this._element.opacity = 0; // should be 0.3, bug paperjs has issues with opacity
        break;
      case 'unselected':
        this._labelVisible = false;
        this._element.visible = true;
        this._element.opacity = 1;
        break;
      case 'selected':
        this._labelVisible = false;
        this._element.visible = true;
        this._element.opacity = 1;
        break;
      case 'hidden':
        this._labelVisible = true;
        this._element.visible = false;
        this._element.opacity = 1;
        break;

      default:
        break;
    }
    this.draw();
  }

  /**
   * Resize
   *
   * @param w: number
   * @param h: number
   */
  public resize(w: number, h: number) {
    return;
  }
}
