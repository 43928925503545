import { User } from '@medsurf/models';
import { ValidationError } from 'class-validator';

// <editor-fold desc="Action Types">

/**
 * Auth action types
 */
export enum AuthActionTypes {
  /**
   * Store
   */
  Login = '[STORE | AUTH] Login',
  LoginSuccess = '[STORE | AUTH] Login Success',
  LoginInvalid = '[STORE | AUTH] Login Invalid',
  Logout = '[STORE | AUTH] Logout',
  LogoutSuccess = '[STORE | AUTH] Logout Success',
  Me = '[STORE | AUTH] Me',
  MeSuccess = '[STORE | AUTH] Me Success',
  UpdateToken = '[STORE | AUTH] Update Token',
  UpdateTokenSuccess = '[STORE | AUTH] Update Token Success',
  /**
   * Request
   */
  LoginRequest = '[REQUEST | AUTH] Login',
  LogoutRequest = '[REQUEST | AUTH] Logout',
  MeRequest = '[REQUEST | AUTH] Me',
  UpdateTokenRequest = '[REQUEST | AUTH] Update Token'
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Login
 */
export class Login {
  public static readonly type = AuthActionTypes.Login;

  public constructor(public username: string, public password: string) {
  }
}

/**
 * Login Success
 */
export class LoginSuccess {
  public static readonly type = AuthActionTypes.LoginSuccess;

  public constructor(public token: string, public tokenLifetime: number, public expiresIn: number, public user: User) {
  }
}

/**
 * Login Invalid
 */
 export class LoginInvalid {
  public static readonly type = AuthActionTypes.LoginInvalid;

  public constructor(public errors: ValidationError[]) {
  }
}

/**
 * Logout
 */
export class Logout {
  public static readonly type = AuthActionTypes.Logout;

  public constructor() {
  }
}

/**
 * Logout Success
 */
export class LogoutSuccess {
  public static readonly type = AuthActionTypes.LogoutSuccess;

  public constructor() {
  }
}

/**
 * Me
 */
export class Me {
  public static readonly type = AuthActionTypes.Me;

  public constructor() {
  }
}

/**
 * Me Success
 */
export class MeSuccess {
  public static readonly type = AuthActionTypes.MeSuccess;

  public constructor(public user: User) {
  }
}

/**
 * Update Token
 */
export class UpdateToken {
  public static readonly type = AuthActionTypes.UpdateToken;

  public constructor() {
  }
}

/**
 * Update Token Success
 */
export class UpdateTokenSuccess {
  public static readonly type = AuthActionTypes.UpdateTokenSuccess;

  public constructor(public token: string, public expiresIn: number) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Login Request
 */
export class LoginRequest {
  public static readonly type = AuthActionTypes.LoginRequest;

  public constructor(public username: string, public password: string) {
  }
}

/**
 * Logout Request
 */
export class LogoutRequest {
  public static readonly type = AuthActionTypes.LogoutRequest;

  public constructor() {
  }
}

/**
 * Me Request
 */
export class MeRequest {
  public static readonly type = AuthActionTypes.MeRequest;

  public constructor() {
  }
}

/**
 * Update Token Request
 */
export class UpdateTokenRequest {
  public static readonly type = AuthActionTypes.UpdateTokenRequest;

  public constructor() {
  }
}

// </editor-fold>
