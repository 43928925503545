import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as FlatModels from '@medsurf/flat-models';
import { MenuViewerActions } from '@medsurf/flat-actions';
import * as FlatStates from '../../internal';

/**
 * Menu Viewer State Model
 */
export interface MenuViewerStateModel {
  viewerDisplayMode: FlatModels.MenuViewerModels.ViewerDisplayMode;
  viewerPrintMode: FlatModels.MenuViewerModels.ViewerPrintMode;
  showVirtualPointer: boolean;
  showNavigator: boolean;
  showMarkers: boolean;
  showSelftest: boolean;
  showCaseHistory: boolean;
}

/**
 * Menu Viewer State
 */
@State<MenuViewerStateModel>({
  name: 'menuViewer',
  defaults: {
    viewerDisplayMode: FlatModels.MenuViewerModels.ViewerDisplayMode.NORMAL,
    viewerPrintMode: FlatModels.MenuViewerModels.ViewerPrintMode.PRINT_AUTHOR,
    showVirtualPointer: false,
    showNavigator: true,
    showMarkers: true,
    showSelftest: false,
    showCaseHistory: false,
  }
})
@Injectable()
export class MenuViewerState {

  //<editor-fold desc="Selectors">

  /**
   * Selector viewerDisplayMode$
   *
   * @param state: MenuViewerStateModel
   */
  @Selector([MenuViewerState])
  public static viewerDisplayMode$(state: MenuViewerStateModel): FlatModels.MenuViewerModels.ViewerDisplayMode {
    return state.viewerDisplayMode;
  }

  /**
   * Selector viewerPrintMode$
   *
   * @param state: MenuViewerStateModel
   */
  @Selector([MenuViewerState])
  public static viewerPrintMode$(state: MenuViewerStateModel): FlatModels.MenuViewerModels.ViewerPrintMode {
    return state.viewerPrintMode;
  }

  /**
   * Selector showPrint$
   *
   * @param viewerDisplayMode: MenuViewerModels.ViewerDisplayMode
   */
  @Selector([MenuViewerState.viewerDisplayMode$])
  public static showPrint$(viewerDisplayMode: FlatModels.MenuViewerModels.ViewerDisplayMode): boolean {
    return viewerDisplayMode === FlatModels.MenuViewerModels.ViewerDisplayMode.PRINT;
  }

  /**
   * Selector showPrintForAuthor$
   *
   * @param viewerDisplayMode: MenuViewerModels.ViewerDisplayMode
   */
  @Selector([MenuViewerState.viewerDisplayMode$, MenuViewerState.viewerPrintMode$])
  public static showPrintForAuthor$(viewerDisplayMode: FlatModels.MenuViewerModels.ViewerDisplayMode, viewerPrintMode: FlatModels.MenuViewerModels.ViewerPrintMode): boolean {
    return viewerDisplayMode === FlatModels.MenuViewerModels.ViewerDisplayMode.PRINT && viewerPrintMode === FlatModels.MenuViewerModels.ViewerPrintMode.PRINT_AUTHOR;
  }

  /**
   * Selector showPrintForUser$
   *
   * @param viewerDisplayMode: MenuViewerModels.ViewerDisplayMode
   */
  @Selector([MenuViewerState.viewerDisplayMode$, MenuViewerState.viewerPrintMode$])
  public static showPrintForUser$(viewerDisplayMode: FlatModels.MenuViewerModels.ViewerDisplayMode, viewerPrintMode: FlatModels.MenuViewerModels.ViewerPrintMode): boolean {
    return viewerDisplayMode === FlatModels.MenuViewerModels.ViewerDisplayMode.PRINT && viewerPrintMode === FlatModels.MenuViewerModels.ViewerPrintMode.PRINT_USER;
  }

  /**
   * Selector showFullscreen$
   *
   * @param viewerDisplayMode: MenuViewerModels.ViewerDisplayMode
   */
  @Selector([MenuViewerState.viewerDisplayMode$])
  public static showFullscreen$(viewerDisplayMode: FlatModels.MenuViewerModels.ViewerDisplayMode): boolean {
    return viewerDisplayMode === FlatModels.MenuViewerModels.ViewerDisplayMode.FULLSCREEN;
  }

  /**
   * Selector showVirtualPointer$
   *
   * @param state: MenuViewerStateModel
   */
  @Selector([MenuViewerState])
  public static showVirtualPointer$(state: MenuViewerStateModel): boolean {
    return state.showVirtualPointer;
  }

  /**
   * Selector showNavigator$
   *
   * @param state: MenuViewerStateModel
   */
  @Selector([MenuViewerState])
  public static showNavigator$(state: MenuViewerStateModel): boolean {
    return state.showNavigator;
  }

  /**
   * Selector showMarkers$
   *
   * @param state: MenuViewerStateModel
   */
  @Selector([MenuViewerState])
  public static showMarkers$(state: MenuViewerStateModel): boolean {
    return state.showMarkers;
  }

  /**
   * Selector showSelftest$
   *
   * @param state: MenuViewerStateModel
   */
  @Selector([MenuViewerState])
  public static showSelftest$(state: MenuViewerStateModel): boolean {
    return state.showSelftest;
  }

  /**
   * Selector showCaseHistory$
   *
   * @param state: MenuViewerStateModel
   */
  @Selector([MenuViewerState])
  public static showCaseHistory$(state: MenuViewerStateModel): boolean {
    return state.showCaseHistory;
  }

  /**
   * Selector currentSlideTitle$
   *
   * @param allCurrentImages: FlatModels.ImageEntityModels.Image[]
   * @param currentSelectedSlidePage: FlatModels.SlideEntityModels.Slide | null
   * @param mediaEntities: FlatModels.MediaEntityModels.Media[]
   * @param stainEntities: FlatModels.StainEntityModels.Stain[]
   * @param modalityEntities: FlatModels.ModalityEntityModels.Modality[]
   */
  @Selector([
    FlatStates.ImageEntityState.allCurrentSelectedSlideImages$,
    FlatStates.PageEntityState.currentSelectedSlidePage$,
    FlatStates.MediaEntityState.entities$,
    FlatStates.StainEntityState.entities$,
    FlatStates.ModalityEntityState.entities$
  ])
  public static currentSlideTitle$(allCurrentImages: FlatModels.ImageEntityModels.Image[],
                                   currentSelectedSlidePage: FlatModels.PageEntityModels.Slide | null,
                                   mediaEntities: FlatModels.MediaEntityModels.Media[],
                                   stainEntities: FlatModels.StainEntityModels.Stain[],
                                   modalityEntities: FlatModels.ModalityEntityModels.Modality[]) {
    if (!currentSelectedSlidePage) {
      return '';
    }

    let tmp_compoundNumber: string | undefined = undefined;
    let tmp_stain: FlatModels.StainEntityModels.Stain | undefined = undefined;

    if (allCurrentImages) {
      loop1:
        for (const image of allCurrentImages) {
          const media = mediaEntities.find(m => m.id === image.media as unknown as string);
          if (media) {
            if (media.compoundNumber && !tmp_compoundNumber) {
              tmp_compoundNumber = media.compoundNumber;
            }
            const stain = stainEntities.find(s => s.id === media.stain as unknown as string);
            if (stain && !tmp_stain) {
              tmp_stain = stain;
            }
          }
          if (tmp_compoundNumber && tmp_stain) {
            break loop1;
          }
        }
    }

    if (currentSelectedSlidePage.type === FlatModels.PageEntityModels.PageType.TRAINING) {
      return 'Quiz';
    }
    let slideTitle = '';

    const modality = modalityEntities.find(m => m.id === currentSelectedSlidePage.modality as unknown as string);
    if (modality && modality.name) {
      slideTitle += modality.name;
    }
    if (tmp_compoundNumber) {
      slideTitle += ' ' + tmp_compoundNumber;
    }
    if (slideTitle !== '') {
      slideTitle += ': <br class="d-lg-none">';
    }
    slideTitle += currentSelectedSlidePage.title;
    if (tmp_stain) {
      slideTitle += ' - ' + tmp_stain.name;
    }
    return slideTitle;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Set Viewer Display Mode
   *
   * @param patchState: MenuViewerStateModel
   * @param viewerDisplayMode: MenuViewerActions.SetViewerDisplayMode
   */
  @Action(MenuViewerActions.SetViewerDisplayMode)
  public setViewerDisplayMode({patchState}: StateContext<MenuViewerStateModel>,
                              {viewerDisplayMode}: MenuViewerActions.SetViewerDisplayMode) {
    patchState({
      viewerDisplayMode
    });
  }

  /**
   * Set Viewer Print Mode
   *
   * @param patchState: MenuViewerStateModel
   * @param viewerPrintMode: MenuViewerActions.SetViewerPrintMode
   */
  @Action(MenuViewerActions.SetViewerPrintMode)
  public setViewerPrintMode({patchState}: StateContext<MenuViewerStateModel>,
                              {viewerPrintMode}: MenuViewerActions.SetViewerPrintMode) {
    patchState({
      viewerPrintMode
    });
  }

  /**
   * Toggle Virtual Pointer
   *
   * @param setState: StateContext<MenuViewerStateModel>
   */
  @Action(MenuViewerActions.ToggleVirtualPointer)
  public toggleVirtualPointer({setState}: StateContext<MenuViewerStateModel>) {
    setState(
      patch({
        showVirtualPointer: existing => !existing
      })
    );
  }

  /**
   * Toggle Navigator
   *
   * @param setState: StateContext<MenuViewerStateModel>
   */
  @Action(MenuViewerActions.ToggleNavigator)
  public toggleNavigator({setState}: StateContext<MenuViewerStateModel>) {
    setState(
      patch({
        showNavigator: existing => !existing
      })
    );
  }

  /**
   * Toggle Marker
   *
   * @param getState: StateContext<MenuViewerStateModel>
   * @param setState: StateContext<MenuViewerStateModel>
   * @param override: MenuViewerActions.ToggleMarker
   */
  @Action(MenuViewerActions.ToggleMarker)
  public toggleMarker({getState, setState}: StateContext<MenuViewerStateModel>,
                      {override}: MenuViewerActions.ToggleMarker) {
    const showMarkers = override !== null ? override : !MenuViewerState.showMarkers$(getState());
    setState(
      patch({
        showMarkers,
        showSelftest: existing => (existing && showMarkers) ? false : existing,
      })
    );
  }

  /**
   * Toggle Selftest
   *
   * @param getState: StateContext<MenuViewerStateModel>
   * @param setState: StateContext<MenuViewerStateModel>
   */
  @Action(MenuViewerActions.ToggleSelftest)
  public toggleSelftest({getState, setState}: StateContext<MenuViewerStateModel>) {
    const showSelftest = !MenuViewerState.showSelftest$(getState());
    setState(
      patch({
        showSelftest,
        showMarkers: existing => (existing && showSelftest) ? false : existing,
      })
    );
  }

  /**
   * Toggle Case History
   *
   * @param setState: StateContext<MenuViewerStateModel>
   */
  @Action(MenuViewerActions.ToggleCaseHistory)
  public toggleCaseHistory({setState}: StateContext<MenuViewerStateModel>) {
    setState(
      patch({
        showCaseHistory: existing => !existing
      })
    );
  }


  /**
   * Toggle Quiz Feedback
   *
   * @param setState: StateContext<MenuViewerStateModel>
   */
  @Action(MenuViewerActions.ToggleQuizFeedback)
  public toggleQuizFeedback({setState}: StateContext<MenuViewerStateModel>) {
    setState(
      patch({
        showQuizFeedback: existing => !existing
      })
    );
  }

  /**
   * Toggle Quiz Solution
   *
   * @param setState: StateContext<MenuViewerStateModel>
   */
  @Action(MenuViewerActions.ToggleQuizSolution)
  public toggleQuizSolution({setState}: StateContext<MenuViewerStateModel>) {
    setState(
      patch({
        showQuizSolution: existing => !existing
      })
    );
  }

  /**
   * Toggle Quiz Explanation
   *
   * @param setState: StateContext<MenuViewerStateModel>
   */
  @Action(MenuViewerActions.ToggleQuizExplanation)
  public toggleQuizExplanation({setState}: StateContext<MenuViewerStateModel>) {
    setState(
      patch({
        showQuizExplanation: existing => !existing
      })
    );
  }

  //</editor-fold>
}
