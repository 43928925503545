import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { GetReleaseNotes, GetReleaseNotesRequest, GetReleaseNotesSuccess } from '@medsurf/actions';
import { MessageService } from '@medsurf/services';
import { AuthState } from './auth.state';
import { Release } from '@medsurf/models';

/**
 * Release notes state model
 */
export interface ReleaseNotesStateModel {
  releases: Release[];
}

/**
 * Release notes State
 */
@State<ReleaseNotesStateModel>({
  name: 'releasenotes',
  defaults: {
    releases: []
  }
})
@Injectable()
export class ReleaseNotesState {
  /**
   * Constructor
   * @param store: Store
   * @param messageService: MessageService
   */
  public constructor(
    protected store: Store,
    protected messageService: MessageService
  ) {
  }

  /**
   * Selector releaseNotes
   * @param state: ReleaseNotesStateModel
   */
  @Selector()
  public static releases(state: ReleaseNotesStateModel): Release[] {
    return state.releases;
  }

  /**
   * Get Release Notes
   * @param getState: StateContext<GetReleaseNotes>
   * @param version: GetReleaseNotes
   */
  @Action(GetReleaseNotes)
  public getReleaseNotes({getState}: StateContext<ReleaseNotesStateModel>, {version}: GetReleaseNotes) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetReleaseNotesRequest(version));
  }

  /**
   * Get Release Notes
   * @param setState: StateContext<GetReleaseNotes>
   * @param textExports: GetReleaseNotesSuccess
   * @param releases: GetReleaseNotesSuccess
   */
  @Action(GetReleaseNotesSuccess)
  public getReleaseNotesSuccess({setState}: StateContext<ReleaseNotesStateModel>, {releases}: GetReleaseNotesSuccess) {
    setState(
        patch({
            releases
        })
    );
  }
}
