import { Base } from '../base/base.entity.model';
import * as FlatModels from '../../internal';

/**
 * Page Entity Type
 */
export type PageEntityType = Grid | InfoPage | QuizStart | Vocabulary | Slide | Training;

/**
 * Page Entity Query Result
 */
export type PageEntityQueryResult = FlatModels.EntityControlModels.EntityQueryResult<PageEntityType>;

/**
 * Page Type
 */
export enum PageType {
  FOLDER = 'Folder',
  GRID = 'Grid',
  CASE = 'Case',
  SLIDE = 'Slide',
  TRAINING = 'Training',
  QUIZ_START = 'QuizStart',
  QUIZ_RUN = 'QuizRun',
  VOCABULARY = 'Vocabulary',
}

/**
 * Slide Status
 */
export enum SlideStatus {
  EMPTY = 'empty',
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  REVIEW_NOK = 'review_not_ok',
  FINAL = 'final'
}

/**
 * Defaults
 */
export interface Defaults {
  labelColor?: string;
  pointerColor?: string;
  labelFontSize?: number;
}

/**
 * Page
 */
export interface Page extends Base {
  title?: string;
  type?: PageType;
  editedBy?: FlatModels.UserEntityModels.User;
  authorNote?: string;
}

/**
 * Folder
 */
export interface Folder extends Page {
  description?: string;
  media?: FlatModels.MediaEntityModels.Media;
  themeColor?: string;
}

/**
 * Grid
 */
export interface Grid extends Page {
  pdfMedia?: FlatModels.MediaEntityModels.Media;
  pdfMediaAuthor?: FlatModels.MediaEntityModels.Media;
}

/**
 * Info Page
 */
export interface InfoPage extends Page {
  header?: string;
  body?: string;
}

/**
 * Quiz Start
 */
export type QuizStart = Page

/**
 * Vocabulary
 */
export interface Vocabulary extends Page {
  description?: string;
  vocabularyGroups: FlatModels.VocabularyGroupEntityModels.VocabularyGroup[];
}

/**
 * Slide
 */
export interface Slide extends Page {
  slideId?: number;
  header?: string;
  body?: string;
  status?: SlideStatus;
  mediaType?: FlatModels.MediaEntityModels.MediaType;
  modality?: FlatModels.ModalityEntityModels.Modality;
  sideBarSize?: number;
  groups?: FlatModels.GroupEntityModels.Group[];
  thumbnailMedia?: FlatModels.MediaEntityModels.Media;
  pdfMedia?: FlatModels.MediaEntityModels.Media;
  pdfMediaAuthor?: FlatModels.MediaEntityModels.Media;
  layers?: FlatModels.LayerEntityModels.Layer[];
  questions?: FlatModels.QuestionEntityModels.Question[];
  defaults?: Defaults;
  editedAt?: Date;
  hidden?: boolean;
  restricted?: boolean;
}

/**
 * Training
 */
export interface Training extends Slide {
  url?: string;
  answeredRight?: boolean;
  answered?: boolean;
  toggleSolution?: number;
}
