import { map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { NodeControlActions } from '@medsurf/flat-actions';
import * as FlatStates from '@medsurf/flat-states';

/**
 * Node Shared Facade
 */
@Injectable()
export class NodeSharedFacade {
  //<editor-fold desc="Selectors">

  // Node Entity State
  public elearnings$ = this.store.select(FlatStates.NodeEntityState.entitiesByParentId$).pipe(map(filterFn => filterFn(null)));
  public isTrainingResult$ = this.store.select(FlatStates.NodeEntityState.isTrainingResult$);
  public currentSelectedElearningNode$ = this.store.select(FlatStates.NodeEntityState.currentSelectedElearningNode$);
  public currentSelectedSubjectNode$ = this.store.select(FlatStates.NodeEntityState.currentSelectedSubjectNode$);
  public currentSelectedChapterNodes$ = this.store.select(FlatStates.NodeEntityState.currentSelectedChapterNodes$);
  public currentSelectedChapterNode$ = this.store.select(FlatStates.NodeEntityState.currentSelectedChapterNode$);
  public currentSelectedBlockNode$ = this.store.select(FlatStates.NodeEntityState.currentSelectedBlockNode$);
  public currentSelectedNode$ = this.store.select(FlatStates.NodeEntityState.currentSelectedNode$);
  public currentSelectedSettings$ = this.store.select(FlatStates.NodeEntityState.currentSelectedSettings$);
  public currentSelectedCaseStartUrl$ = this.store.select(FlatStates.NodeEntityState.currentSelectedCaseStartUrl$);

  // Page Entity State
  public currentSelectedChapterPage$ = this.store.select(FlatStates.PageEntityState.currentSelectedChapterPage$);
  public currentSelectedBlockPage$ = this.store.select(FlatStates.PageEntityState.currentSelectedBlockPage$);
  public currentSelectedSlidePage$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlidePage$);
  public currentSelectedChapterPageType$ = this.store.select(FlatStates.PageEntityState.currentSelectedChapterPageType$);
  public currentSelectedSlideNodesFiltered$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlideNodesFiltered$);

  //</editor-fold>

  //<editor-fold desc="Lazy Selectors">

  public nodeByIds$ = (ids: any) => this.store.select(FlatStates.NodeEntityState.entitiesByIds$).pipe(map(filterFn => filterFn(ids as unknown as string[])));
  public isNodeLocked$ = (node: FlatModels.NodeEntityModels.Node, parentRestricted: boolean) => this.store.select(FlatStates.NodeEntityState.isNodeLocked$).pipe(map(filterFn => filterFn(node, parentRestricted)));

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(private store: Store) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Node By Id
   *
   * @param id: string
   */
  public requestNodeById(id: string) {
    return this.store.dispatch(new NodeControlActions.GetNodeById(id));
  }

  /**
   * Request Nodes By Parent Id
   *
   * @param parentId: string | null;
   */
  public requestNodesByParentId(parentId: string | null) {
    return this.store.dispatch(new NodeControlActions.GetNodesByParentId(parentId));
  }

  /**
   * Request Nodes By Current Route
   */
  public requestNodesByCurrentRoute() {
    return this.store.dispatch(new NodeControlActions.GetNodesByCurrentRoute());
  }

  /**
   * Request Elearnings
   */
  public requestElearnings() {
    return this.requestNodesByParentId(null);
  }

  /**
   * Request Route By Id
   *
   * @param id: string
   */
  public requestRouteById(id: string) {
    return this.store.dispatch(new NodeControlActions.GetRouteById(id));
  }

  //</editor-fold>

}
