<div class="mdsf-carousel p-0 m-0 w-100" *ngIf="(nodeSharedFacade.currentSelectedElearningNode$ | async) as elearningNode">
  <ng-container *ngIf="(appSharedFacade.page$(elearningNode.page) | async) as page">
    <!-- TODO IMAGE LOADER
    <medsurf-shared-image-loader *ngIf="(appSharedFacade.media$(page.media) | async) as media"
                                 [media]="media"
                                 [alt]="'Decorative Carousel Image'"
                                 [class]="'mdsf-carousel-image w-100 h-100'"></medsurf-shared-image-loader>
    -->
      <img class="mdsf-carousel-image w-100 h-100"
           *ngIf="(appSharedFacade.media$(page.media) | async) as media"
           [src]="mediaControlService.getUrl(media)"
           fill="true"
           priority
           alt="decorative Carousel image">
  </ng-container>
</div>
