import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { NodeEntityModels, SearchControlModels } from '@medsurf/flat-models';
import { SearchControlActions } from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';
import { AuthControlState, NodeEntityState } from '../../internal';

/**
 * Search Control State Model
 */
export interface SearchControlStateModel {
  searchString: string;
  suggestions: string[];
  slideSearchResults: SearchControlModels.SearchResult[];
  markerSearchResults: SearchControlModels.SearchResult[];
}

/**
 * Auth Control State
 */
@State<SearchControlStateModel>({
  name: 'searchControl',
  defaults: {
    searchString: '',
    suggestions: [],
    slideSearchResults: [],
    markerSearchResults: []
  }
})
@Injectable()
export class SearchControlState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   */
  public constructor(public store: Store,
                     public messageControlService: MessageControlService) {
  }

  //<editor-fold desc="Selectors">

  /**
   * Selector searchString$
   *
   * @param state: SearchControlStateModel
   */
  @Selector([SearchControlState])
  public static searchString$(state: SearchControlStateModel): string {
    return state.searchString;
  }

  /**
   * Selector suggestions$
   *
   * @param state: SearchControlStateModel
   */
  @Selector([SearchControlState])
  public static suggestions$(state: SearchControlStateModel): string[] {
    return state.suggestions;
  }

  /**
   * Selector slideSearchResults$
   *
   * @param state: SearchControlStateModel
   */
  @Selector([SearchControlState])
  public static slideSearchResults$(state: SearchControlStateModel): SearchControlModels.SearchResult[] {
    return state.slideSearchResults;
  }

  /**
   * Selector slideSearchResultNodes$
   *
   * @param slideSearchResults: SearchControlModels.SearchResult[]
   * @param typedEntities: { [id: string]: NodeEntityModels.NodeEntityType }
   */
  @Selector([
    SearchControlState.slideSearchResults$,
    NodeEntityState.typedEntities$
  ])
  public static slideSearchResultNodes$(slideSearchResults: SearchControlModels.SearchResult[],
                                        typedEntities: { [id: string]: NodeEntityModels.NodeEntityType }): NodeEntityModels.NodeEntityType[] {
    return slideSearchResults.map(result => {
      return typedEntities[result.id];
    });
  }

  /**
   * Selector markerSearchResults$
   *
   * @param state: SearchControlStateModel
   */
  @Selector([SearchControlState])
  public static markerSearchResults$(state: SearchControlStateModel): SearchControlModels.SearchResult[] {
    return state.markerSearchResults;
  }

  /**
   * Selector markerSearchResultNodes$
   *
   * @param markerSearchResults: : SearchControlModels.SearchResult[]
   * @param typedEntities: { [id: string]: NodeEntityModels.NodeEntityType }
   */
  @Selector([
    SearchControlState.markerSearchResults$,
    NodeEntityState.typedEntities$
  ])
  public static markerSearchResultNodes$(markerSearchResults: SearchControlModels.SearchResult[],
                                         typedEntities: { [id: string]: NodeEntityModels.NodeEntityType }): NodeEntityModels.NodeEntityType[] {
    return markerSearchResults.map(result => {
      return typedEntities[result.id];
    });
  }

  /**
   * Selector searchResultCount$
   * 
   * @param slideSearchResults
   * @param markerSearchResults
   */
  @Selector([
    SearchControlState.slideSearchResults$,
    SearchControlState.markerSearchResults$
  ])
  public static searchResultCount$(slideSearchResults: SearchControlModels.SearchResult[],
                                   markerSearchResults: SearchControlModels.SearchResult[]): number {
    return slideSearchResults.length || markerSearchResults.length || 0;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Set Search String
   *
   * @param patchState: StateContext<SearchControlStateModel>
   * @param searchString: SearchControlActions.SetSearchString
   */
  @Action(SearchControlActions.SetSearchString)
  public setSearchString({patchState}: StateContext<SearchControlStateModel>,
                         {searchString}: SearchControlActions.SetSearchString): void {
    patchState({
      searchString
    });
  }

  /**
   * Reset Search
   *
   * @param patchState: StateContext<SearchControlStateModel>
   */
  @Action(SearchControlActions.ResetSearch)
  public resetSearch({patchState}: StateContext<SearchControlStateModel>): void {
    patchState({
      searchString: '',
      suggestions: [],
      slideSearchResults: [],
      markerSearchResults: []
    });
  }

  /**
   * Find Slides And Markers
   *
   * @param getState: StateContext<SearchControlStateModel>
   * @param dispatch: StateContext<SearchControlStateModel>
   */
  @Action(SearchControlActions.FindSlidesAndMarkers)
  public findSlidesAndMarkers({getState, dispatch}: StateContext<SearchControlStateModel>) {
    if (getState().searchString.length > 0) {
      return this.messageControlService.sendMessage(
        this.store.selectSnapshot(AuthControlState.token$),
        new SearchControlActions.FindSlidesAndMarkersRequest(getState().searchString)
      );
    } else {
      dispatch(new SearchControlActions.FindSlidesSuccess([]));
      dispatch(new SearchControlActions.FindMarkersSuccess([]));
      return;
    }
  }

  /**
   * Find Slide Success
   *
   * @param patchState: StateContext<SearchControlStateModel>
   * @param dispatch: StateContext<SearchControlStateModel>
   * @param entity: SearchControlActions.FindSlideSuccess
   */
  @Action(SearchControlActions.FindSlideSuccess)
  public findSlideSuccess({patchState, dispatch}: StateContext<SearchControlStateModel>,
                          {entity}: SearchControlActions.FindSlideSuccess): void {
    patchState({
      slideSearchResults: [entity]
    });
    dispatch(new SearchControlActions.FindSuccess());
  }

  /**
   * Find Slides Success
   *
   * @param patchState: StateContext<SearchControlStateModel>
   * @param dispatch: StateContext<SearchControlStateModel>
   * @param entities: SearchControlActions.FindSlidesSuccess
   */
  @Action(SearchControlActions.FindSlidesSuccess)
  public findSlidesSuccess({patchState, dispatch}: StateContext<SearchControlStateModel>,
                           {entities}: SearchControlActions.FindSlidesSuccess): void {
    patchState({
      slideSearchResults: entities
    });
    dispatch(new SearchControlActions.FindSuccess());
  }

  /**
   * Find Marker Success
   *
   * @param patchState: StateContext<SearchControlStateModel>
   * @param dispatch: StateContext<SearchControlStateModel>
   * @param entity: SearchControlActions.FindMarkerSuccess
   */
  @Action(SearchControlActions.FindMarkerSuccess)
  public findMarkerSuccess({patchState, dispatch}: StateContext<SearchControlStateModel>,
                           {entity}: SearchControlActions.FindMarkerSuccess): void {
    patchState({
      markerSearchResults: [entity]
    });
    dispatch(new SearchControlActions.FindSuccess());
  }

  /**
   * Find Markers Success
   *
   * @param patchState: StateContext<SearchControlStateModel>
   * @param dispatch: StateContext<SearchControlStateModel>
   * @param entities: SearchControlActions.FindMarkerSuccess
   */
  @Action(SearchControlActions.FindMarkersSuccess)
  public findMarkersSuccess({patchState, dispatch}: StateContext<SearchControlStateModel>,
                            {entities}: SearchControlActions.FindMarkersSuccess): void {
    patchState({
      markerSearchResults: entities
    });
    dispatch(new SearchControlActions.FindSuccess());
  }

  /**
   * Find Suggestions
   *
   * @param getState: StateContext<SearchControlStateModel>
   */
  @Action(SearchControlActions.FindSuggestions)
  public findSuggestions({getState}: StateContext<SearchControlStateModel>) {
    return this.messageControlService.sendMessage(
      this.store.selectSnapshot(AuthControlState.token$),
      new SearchControlActions.FindSuggestionsRequest(getState().searchString)
    );
  }

  /**
   * Find Suggestions Success
   *
   * @param patchState: StateContext<SearchControlStateModel>
   * @param suggestions: SearchControlActions.FindSuggestionsSuccess
   */
  @Action(SearchControlActions.FindSuggestionsSuccess)
  public getSuggestionsSuccess({patchState}: StateContext<SearchControlStateModel>,
                               {suggestions}: SearchControlActions.FindSuggestionsSuccess): void {
    patchState({
      suggestions,
    });
  }

  //</editor-fold>
}
