<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="question && trainingResult && trainingResult.answer !== undefined; else spinner">
  <!-- Header -->
  <medsurf-quiz-question-header [index]="(trainingViewerFacade.selectedQuestionIndex$(question.id) | async) || index"
                                [checkedAndCorrect]="trainingResult.showFeedback && isCorrect()"
                                [questionType]="question.type">
  </medsurf-quiz-question-header>

  <!-- Question Form -->
  <form name="form"
        class="mdsf-quiz-form">
    <!-- Question Text -->
    <medsurf-text [text]="question.content"></medsurf-text>

    <!-- TODO sort needed?: | sortBy_shared:'asc':'order' -->
    <ng-container *ngIf="(trainingViewerFacade.choicesByIds$(question.choices) | async) as choices; else spinner">
      <!-- Choices -->
      <div class="mb-4"
           *ngIf="choices.length > 5 && (menuViewerFacade.showPrint$ | async) === false">
        <div class="mb-0 border-0 mt-0">
          <div class="choice w-100 p-2"
             [class.choice-error]="trainingResult.showFeedback && question.solution !== trainingResult.answer"
             [class.choice-correct]="trainingResult.showFeedback && question.solution === trainingResult.answer"
             [class.choice-not-answered]="showRequired && passRequired()">
            <div class="flex-grow-1">
            <!-- User Answer -->
            <select class="form-control custom-select"
                    [ngModel]="trainingResult.answer"
                    [name]="'item' + question.order + 'set'"
                    [class.not-answered]="showRequired && passRequired()"
                    [required]="question.required"
                    [disabled]="trainingResult.showFeedback"
                    (blur)="onBlur($event.target.value)">
              <option value="" disabled selected hidden>{{ question.placeholder }}</option>
              <option *ngFor="let choice of choices; let k = index"
                      [value]="choice.order" [innerHTML]="choice.label | stripHtml">
              </option>
            </select>

            <!-- Correct Answer -->
            <div class="correct-answer mt-2"
                 *ngIf="trainingResult.showFeedback && question.solution !== trainingResult.answer && getChoiceBySolution(choices, question.solution) as choiceSolution">
              <strong>{{ 'correct_answer' | translate }}:</strong> {{ choiceSolution.label | stripHtml }}
            </div>
          </div>

            <!-- Feedback -->
            <div class="form-control-feedback mt-2 ml-2"
                 *ngIf="trainingResult.showFeedback"
                 [class.invisible]="trainingResult.showFeedback === false || trainingResult.answer === ''">
              <i class="fa fa-fw fa-check solution-correct"
                 *ngIf="question.solution === trainingResult.answer"></i>
              <i class="fa fa-fw fa-times solution-error"
                 *ngIf="question.solution !== trainingResult.answer"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- Choices Print Mode -->
      <div class="mb-4"
           *ngIf="choices.length <= 5 || (menuViewerFacade.showPrint$ | async)">
        <ng-container *ngFor="let choice of choices; let j = index">
          <ng-container *ngIf="choice?.label">
            <div class="choice"
                 [ngStyle]="{'order': choice.order}"
                 [class.choice-selected]="choice.order === trainingResult.answer || (choice.order === question.solution && (menuViewerFacade.showPrintForAuthor$ | async))"
                 [class.choice-error]="trainingResult.showFeedback && choice.order === trainingResult.answer && question.solution !== trainingResult.answer"
                 [class.choice-correct]="trainingResult.showFeedback && choice.order === trainingResult.answer && question.solution === trainingResult.answer"
                 [class.no-hover]="trainingResult.showFeedback"
                 (click)="onClick(choice)">
              <button type="button"
                      class="radio-custom"
                      id="choices{{choice.order}}"
                      name="choices{{choice.order}}">
              </button>
              <label for="choices{{choice.order}}"
                     class="radio-custom-label"
                     [class.radio-custom-label-error]="trainingResult.showFeedback && choice.order === trainingResult.answer && question.solution !== trainingResult.answer"
                     [class.radio-custom-label-correct]="trainingResult.showFeedback && choice.order !== trainingResult.answer && question.solution === choice.order">
                <i *ngIf="(trainingResult.showFeedback && choice.order === trainingResult.answer && question.solution !== trainingResult.answer)"
                   class="fal fa-times cross">
                </i>
              </label>
              <div class="choice-label">
                <label class="choice-label-character">{{ trainingViewerFacade.helperListCharacter(question, j) }}.</label>
                <label class="choice-label-text">
                  <medsurf-text [text]="choice.label"></medsurf-text>
                </label>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

    </ng-container>

    <!-- Explanation -->
    <div class="form-control-explanation mt-2"
         *ngIf="question.explanation && (trainingResult.showExplanation || (menuViewerFacade.showPrintForAuthor$ | async))">
      <strong>{{ 'explanation' | translate }}</strong>
      <medsurf-text [text]="question.explanation"></medsurf-text>
    </div>

    <!-- Footer -->
    <medsurf-quiz-question-footer #footer
                                  class="mt-2"
                                  *ngIf="(menuViewerFacade.showPrint$ | async) === false && question?.solution !== null"
                                  [question]="question"
                                  [trainingResult]="trainingResult"
                                  [isCorrect]="isCorrect"
                                  [passRequired]="passRequired"
                                  [(showRequired)]="showRequired"
                                  [ngStyle]="{'order': 100}">
    </medsurf-quiz-question-footer>
  </form>
</ng-container>
