import { HttpClient } from '@angular/common/http';
import Paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { Container } from '../container';
import { Element } from '../element';

/**
 * Image class
 */
export class Sprite<T extends ImageViewerModels.SpriteModel> extends Element<T> {
  protected _image: Paper.Raster;
  private _originalImageWidth: number;
  private _originalImageHeight: number;

  /**
   * Constructor
   * @param {HttpClient} http
   * @param model
   * @param _localPaper
   * @param _mainLayer
   * @param _container
   */
  constructor(
    private http: HttpClient,
    model,
    private _localPaper: Paper.PaperScope,
    private _mainLayer: Paper.Layer,
    private _container: Container
  ) {
    super(model);
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this._element = new Paper.Group();
    this.state = 'hidden';
    this._mainLayer.addChild(this._element);
  }

  /**
   * Load image file from server
   *
   * @param name
   */
  doLoadFile(name) {
    const success = (response) => this._handleData(response);
    const error = (reason) => this._handleError(reason);

    this.http.get(name,
      {responseType: 'arraybuffer'}
    ).subscribe(success, error);
  }

  /**
   * Image file loaded from server
   *
   * @param response
   * @private
   */
  _handleData(response) {
    const uInt8Array = new Uint8Array(response);
    let i = uInt8Array.length;
    const binaryString = new Array(i);
    while (i--) {
      binaryString[i] = String.fromCharCode(uInt8Array[i]);
    }
    const data = binaryString.join('');
    const base64 = window.btoa(data);
    const imageData = 'data:image/jpeg;base64,' + base64;

    this._element.sprite = this;
    this._image = new Paper.Raster(
      imageData,
      new Paper.Point(
        this._model.offset.x,
        this._model.offset.y
      )
    );

    this._image.sprite = this;

    this._element.addChild(this._image);
    this._image.onLoad = () => {
      this.state = 'visible';
      this._originalImageWidth = this._image.width;
      this._originalImageHeight = this._image.height;
      this._image.pivot = new Paper.Point(this._image.width * -0.5, this._image.height * -0.5);
      this.arrange();
      this.emit('created');
    };
  }

  /**
   * Image load error
   *
   * @param reason
   * @private
   */
  _handleError(reason) {
    this.emit('error', reason);
  }

  /**
   * Draw -> arrange
   */
  draw() {
    this.arrange();
  }

  /**
   * Resize
   *
   * @param width
   * @param height
   */
  resize(width, height) {
    width = this._originalImageWidth;
    height = this._originalImageHeight;
  }

  /**
   * Set posititon
   */
  arrange() {
    if (this._image) {
      this._image.setPosition(this._model.offset.x, this._model.offset.y);
    }
    this.state = this._state;
  }

  /**
   * Clean up element
   */
  cleanUp() {
    this._element.removeChildren();
    super.cleanUp();
  }

  /**
   * Getter scale
   *
   * @returns {*}
   */
  get scale() {
    return this.model.scale;
  }

  /**
   * Getter pixels
   *
   * @returns {*|number}
   */
  get pixels() {
    return this._element.getPixel(1, 1);
  }
}
