import paper from 'paper';

export class Symbol {
  /**
   * Members
   */
  protected _element: paper.Group;

  /**
   * Constructor
   *
   * @param _format
   * @param _target
   * @param _marker
   */
  public constructor(protected _format,
                     protected _target,
                     protected _marker) {
    this._element = new paper.Group();
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this.draw();
  }

  /**
   * Angle
   *
   * @param cx
   * @param cy
   * @param ex
   * @param ey
   */
  public angle(cx, cy, ex, ey) {
    const dy = ey - cy;
    const dx = ex - cx;
    let theta = Math.atan2(dy, dx);
    theta *= 180 / Math.PI;
    return theta;
  }

  /**
   * Set Scale
   * @param scale
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public setScale(scale) {}

  /**
   * Getter element
   */
  public get element() {
    return this._element;
  }

  /**
   * Clean Up
   */
  cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }

  /**
   * Draw
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected draw() {}
}
