import * as FlatModels from '@medsurf/flat-models';

/**
 * Format
 */
export class Format {
  /**
   * Members
   */
  public backgroundColor: string;
  private fontColor: string;
  private fontSize: number;
  private fillColor: string;
  private strokeColor: string;
  private blendMode: any;
  private justification: any;
  private pivot: any;
  private circleRadius: number;

  /**
   * Constructor
   *
   * @param defaults: FlatModels.PageEntityModels.Defaults
   */
  constructor (defaults?: FlatModels.PageEntityModels.Defaults) {
    this.defaults();
    if (defaults) {
      if (defaults.labelColor) {
        this.fontColor = defaults.labelColor;
      }
      if (defaults.labelFontSize) {
        this.fontSize = defaults.labelFontSize;
      }
      if (defaults.pointerColor) {
        this.fillColor = defaults.pointerColor;
        this.strokeColor = defaults.pointerColor;
      }
    }
  }

  /**
   * Defaults
   */
  defaults() {
    Object.assign(this, this.getDefaults());
  }

  /**
   * Set Background Format
   *
   * @param image:
   */
  /* TODO remove
  setBackgroundFormat(image) {
    if (!image.background) { return; }
    if (image.background.color) {
      this.backgroundColor = image.background.color;
    }
    if (image.background.blendMode) {
      this.blendMode = image.background.blendMode;
    }
  }
  */

  /**
   * Set Marker Format
   *
   * @param marker: FlatModels.ImageViewerModels.ElementModels
   */
  setMarkerFormat(models: FlatModels.ImageViewerModels.ElementModels) {

    if (models.label) {
      if (models.label.fontSize) {
        this.fontSize = models.label.fontSize;
      }
      if (models.label.color) {
        this.fontColor = models.label.color;
      }
      if (models.label.align) {
        this.justification = models.label.align;
      }
      if (models.label.pivot) {
        this.pivot = models.label.pivot;
      }
    }

    if (models.annotation.type !== FlatModels.AnnotationEntityModels.AnnotationType.FREE_FORM) {
      const otherModels = (<FlatModels.ImageViewerModels.MarkerModel & FlatModels.ImageViewerModels.KeymapModel>models);
      if ((otherModels.targets || []).length > 0) {
        if (otherModels.targets[0].endType && otherModels.targets[0].endRadius) {
          this.circleRadius = otherModels.targets[0].endRadius;
        }
        if (otherModels.targets.some(target => !!target.color)) {
          this.strokeColor = otherModels.targets.find(target => !!target.color).color;
        }
      }
    }

    if (models.annotation.type === FlatModels.AnnotationEntityModels.AnnotationType.FREE_FORM) {
      const freeFromModels = (<FlatModels.ImageViewerModels.FreeformModel>models);
      if (freeFromModels.target?.endRadius) {
        this.circleRadius = freeFromModels.target.endRadius;
      }
      if (freeFromModels.target?.color) {
        this.strokeColor = freeFromModels.target.color;
      }
    }
  }

  /**
   * Get Defaults
   */
  getDefaults() {
    return {
      canvas: {
        width: 600,
        height: 600
      },
      color: '#000000',
      backgroundColor: '#fafafa',
      fontColor: '#000000',
      fontFamily: 'Figtree',
      fontWeight: '600',
      fontSize: 12,
      leading: {label: 1, tooltip: 1.2},
      strokeColor: '#000000',
      blendMode: 'normal',
      strokeWidth: 1,
      fillColor: '#000000',
      justification: 'left',
      pivot: '',
      border: {
        fillColor: '#ffffff',
        strokeColor: '#000000',
        blendMode: 'normal',
        strokeWidth: 1,
        cornerRadius: 2
      },
      circleRadius: 4,
    };
  }
}
