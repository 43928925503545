<footer class="mdsf-footer">
  <div class="container {{ alignClass }}">
    <div class="button-group">
      <div class="button cursor-pointer"
           *ngFor="let link of links"
           [routerLink]="['/', 'seite', link.url]">
        <a [routerLink]="['/', 'seite', link.url]">{{ link.title }}</a>
      </div>
      <div class="button cursor-pointer" (click)="feedbackControlService.openFeedbackModal()">
        <a>FEEDBACK</a>
      </div>
    </div>
  </div>
</footer>
