import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MessageService } from '@medsurf/services';
import { Metadata } from '@medsurf/models';
import { GetMetadata, GetMetadataSuccess, SaveMetadata, SaveMetadataSuccess, GetMetadataRequest,
  SaveMetadataRequest } from '@medsurf/actions';
import { AuthState } from './auth.state';

/**
 * Metadata state model
 */
export class MetadataStateModel {
  data: Metadata;
}

/**
 * Index State
 */
@State<MetadataStateModel>({
  name: 'metadata',
  defaults: {
    data: {}
  }
})
@Injectable()
export class MetadataState {
  /**
   * Constructor
   * @param store: Store
   * @param messageService: MessageService
   */
  constructor(protected store: Store,
              protected messageService: MessageService) {
  }

  /**
   * Selector index
   * @param state: IndexStateModel
   */
  @Selector()
  public static metadata(state: MetadataStateModel): Metadata {
    return state.data;
  }

  /**
   * Get Metadata
   * @param setState: StateContext<MetadataStateModel>
   * @param getState: StateContext<MetadataStateModel>
   * @param dispatch: StateContext<MetadataStateModel>
   */
  @Action(GetMetadata)
  public getMetadata({setState, getState, dispatch}: StateContext<MetadataStateModel>) {
    const state = getState();
    if (!state.data || (Object.entries(state.data).length === 0 && state.data.constructor === Object)) {
      setState(
        patch({
          data: {} as Metadata,
        }));
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetMetadataRequest());
    } else {
      dispatch(new GetMetadataSuccess(state.data));
    }
  }

  /**
   * Get Metadata success
   * @param setState: StateContext<MetadataStateModel>
   * @param data: GetMetadataSuccess
   */
  @Action(GetMetadataSuccess)
  public getMetadataSuccess({setState}: StateContext<MetadataStateModel>, {data}: GetMetadataSuccess): void {
    // Setup state
    setState(
      patch({
        data
      }));
  }

  /**
   * Update Metadata success
   * @param dispatch: StateContext<MetadataStateModel>
   * @param data: GetMetadataSuccess
   */
  @Action(SaveMetadata)
  public saveMetadata({dispatch}: StateContext<MetadataStateModel>, {data}: SaveMetadata) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new SaveMetadataRequest(data));
  }

  /**
   * Update Metadata success
   * @param setState: StateContext<MetadataStateModel>
   * @param data: GetMetadataSuccess
   */
  @Action(SaveMetadataSuccess)
  public saveMetadataSuccess({setState}: StateContext<MetadataStateModel>, {data}: SaveMetadataSuccess) {
    setState(
      patch({
        data
      }));
  }
}
