import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { FreeFormStyleEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * Free Form Style Entity Token
 */
export const FREE_FORM_STYLE_ENTITY_TOKEN = new StateToken<FreeFormStyleEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.FREE_FORM_STYLE);

/**
 * Free Form Style Entity State Model
 */
export type FreeFormStyleEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.FreeFormStyleEntityModels.FreeFormStyle>;

/**
 * Free Form Style Entity State
 */
@State<FreeFormStyleEntityStateModel>({
  name: FREE_FORM_STYLE_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class FreeFormStyleEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: FreeFormStyleEntityStateModel
   */
  @Selector([FreeFormStyleEntityState])
  public static typedEntities$(state: FreeFormStyleEntityStateModel): { [id: string]: FlatModels.FreeFormStyleEntityModels.FreeFormStyle } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.FreeFormStyleEntityModels.FreeFormStyle }
   */
  @Selector([FreeFormStyleEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.FreeFormStyleEntityModels.FreeFormStyle }): FlatModels.FreeFormStyleEntityModels.FreeFormStyle[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.FreeFormStyleEntityModels.FreeFormStyle }
   */
  @Selector([FreeFormStyleEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.FreeFormStyleEntityModels.FreeFormStyle }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.FreeFormStyleEntityModels.FreeFormStyle[]
   */
  @Selector([FreeFormStyleEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.FreeFormStyleEntityModels.FreeFormStyle[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<FreeFormStyleEntityStateModel>
   * @param patchState: StateContext<FreeFormStyleEntityStateModel>
   * @param entityQueryResult: FreeFormStyleEntityActions.GetEntitySuccess
   */
  @Action(FreeFormStyleEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<FreeFormStyleEntityStateModel>,
                          {entityQueryResult}: FreeFormStyleEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<FreeFormStyleEntityStateModel>
   * @param patchState: StateContext<FreeFormStyleEntityStateModel>
   * @param entityQueryResults: FreeFormStyleEntityActions.GetEntitiesSuccess
   */
  @Action(FreeFormStyleEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<FreeFormStyleEntityStateModel>,
                            {entityQueryResults}: FreeFormStyleEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<FreeFormStyleEntityStateModel>
   * @param patchState: StateContext<FreeFormStyleEntityStateModel>
   * @param entity: FreeFormStyleEntityActions.AddEntity
   */
  @Action(FreeFormStyleEntityActions.AddEntity)
  @Undoable(FreeFormStyleEntityActions.AddEntity, FREE_FORM_STYLE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<FreeFormStyleEntityStateModel>,
                   {entity}: FreeFormStyleEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<FreeFormStyleEntityStateModel>
   * @param patchState: StateContext<FreeFormStyleEntityStateModel>
   * @param entity: FreeFormStyleEntityActions.UpdateEntity
   */
  @Action(FreeFormStyleEntityActions.UpdateEntity)
  @Undoable(FreeFormStyleEntityActions.UpdateEntity, FREE_FORM_STYLE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<FreeFormStyleEntityStateModel>,
                      {entity}: FreeFormStyleEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<FreeFormStyleEntityStateModel>
   * @param patchState: StateContext<FreeFormStyleEntityStateModel>
   * @param entity: FreeFormStyleEntityActions.RemoveEntity
   */
  @Action(FreeFormStyleEntityActions.RemoveEntity)
  @Undoable(FreeFormStyleEntityActions.RemoveEntity, FREE_FORM_STYLE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<FreeFormStyleEntityStateModel>,
                      {entity}: FreeFormStyleEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
