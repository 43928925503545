import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { GetTextExport, GetTextExportRequest, GetTextExportSuccess } from '@medsurf/actions';
import { TextExport, Node } from '@medsurf/models';
import { MessageService } from '@medsurf/services';
import { AuthState } from './auth.state';

/**
 * Index state model
 */
export interface TextExportStateModel {
  node: Node;
  textExports: TextExport[];
}

/**
 * Index State
 */
@State<TextExportStateModel>({
  name: 'textexport',
  defaults: {
    node: null,
    textExports: []
  }
})
@Injectable()
export class TextExportState {
  /**
   * Constructor
   * @param store: Store
   * @param messageService: MessageService
   */
  public constructor(
    protected store: Store,
    protected messageService: MessageService
  ) {
  }

  /**
   * Selector textExports
   * @param state: IndexStateModel
   */
  @Selector()
  public static exports(state: TextExportStateModel): TextExport[] {
    return state.textExports;
  }

  /**
   * Selector textExports
   * @param state: IndexStateModel
   */
  @Selector()
  public static node(state: TextExportStateModel): Node {
    return state.node;
  }


  /**
   * Get Text Export
   * @param getState: StateContext<GetTextExport>
   * @param node: GetTextExportSuccess
   */
  @Action(GetTextExport)
  public getTextExport({getState}: StateContext<TextExportStateModel>, {node}: GetTextExport) {
    const state = getState();

    if (!node || state?.node?.id === node?.id) {
      return;
    }
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetTextExportRequest(node));
  }

  /**
   * Get Text Export
   * @param setState: StateContext<GetTextExport>
   * @param textExports: GetTextExportSuccess
   * @param node: GetTextExportSuccess
   */
  @Action(GetTextExportSuccess)
  public getTextExportSuccess({setState}: StateContext<TextExportStateModel>, {textExports, node}: GetTextExportSuccess) {
    if (!textExports?.[0]){
      node = null;
      textExports = [];
    } 
    
    setState(
      patch({
        textExports, node
      }));
  }
}
