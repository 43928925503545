import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MediaInteractionViewerModels } from '@medsurf/flat-models';
import { MediaInteractionViewerActions } from '@medsurf/flat-actions';

/**
 * Media Interaction Viewer State Model
 */
export interface MediaInteractionViewerStateModel {
    currentTime: number;
    events: MediaInteractionViewerModels.MediaInteractionEvent[];
    stepSize: number;
}

/**
 * Media Interaction Viewer State
 */
@State<MediaInteractionViewerStateModel>({
    name: 'mediaInteractionViewer',
    defaults: {
        events: [],
        currentTime: 0,
        stepSize: 0.2
    }
})
@Injectable()
export class MediaInteractionViewerState {

    //<editor-fold desc="Selectors">

    /**
     * Selector stepSize$
     *
     * @param state: MediaInteractionViewerState
     */
    @Selector([MediaInteractionViewerState])
    public static stepSize$(state: MediaInteractionViewerStateModel) {
        return state.stepSize;
    }


    /**
     * Selector currentTime$
     *
     * @param state: MediaInteractionViewerState
     */
    @Selector([MediaInteractionViewerState])
    public static currentTime$(state: MediaInteractionViewerStateModel) {
        return state.currentTime;
    }

    /**
     * Selector eventsByIdMap$
     *
     * @param state: MediaInteractionViewerState
     */
    @Selector([MediaInteractionViewerState])
    public static eventsByIdMap$(state: MediaInteractionViewerStateModel) {
        return state.events.reduce((map, event) => {
            map.set(event.id, event);
            return map;
        }, new Map<string, MediaInteractionViewerModels.MediaInteractionEvent>());
    }

    /**
     * Selector eventsByTimeMap$
     *
     * @param state: MediaInteractionViewerState
     */
    @Selector([MediaInteractionViewerState, MediaInteractionViewerState.stepSize$])
    public static eventsByTimeMap$(state: MediaInteractionViewerStateModel, stepSize: number) {
        return state.events.reduce((map, event) => {
            const start = _round(event.from, stepSize);
            const end = _round(event.to, stepSize);
            for (let i = start; i < end; i += stepSize) {
                const index = _round(i, stepSize);
                const current = map.get(index);
                if (!current) {
                    map.set(index, [event]);
                } else {
                    current.push(event);
                }
            }
            return map;
        }, new Map<number, MediaInteractionViewerModels.MediaInteractionEvent[]>())
    }

    /**
     * Selector currentActiveEvents$
     *
     * @param state: MediaInteractionViewerState
     */
    @Selector([MediaInteractionViewerState.eventsByTimeMap$, MediaInteractionViewerState.currentTime$, MediaInteractionViewerState.stepSize$])
    public static currentActiveEvents$(eventsByTimeMap: ReturnType<typeof this.eventsByTimeMap$>, currentTime: number, stepSize: number) {
        const normalizedTime = _round(currentTime, stepSize);
        return eventsByTimeMap.get(normalizedTime)
    }

    // </editor-fold>

    //<editor-fold desc="Actions">

    /**
     * Set Events
     *
     * @param patchState: MediaInteractionViewerStateModel
     * @param action: MediaInteractionViewerActions.SetEvents
     */
    @Action(MediaInteractionViewerActions.SetEvents)
    public setEvents({patchState}: StateContext<MediaInteractionViewerStateModel>,
                                { events }: MediaInteractionViewerActions.SetEvents) {
        patchState({
            events
        });
    }


    /**
     * Set Events
     *
     * @param patchState: MediaInteractionViewerStateModel
     * @param action: MediaInteractionViewerActions.SetEvents
     */
    @Action(MediaInteractionViewerActions.SetCurrentTime)
    public setCurrentTime({patchState}: StateContext<MediaInteractionViewerStateModel>,
                                { currentTime }: MediaInteractionViewerActions.SetCurrentTime) {
        patchState({
            currentTime
        });
    }

    // </editor-fold>

}

function _round(value: number, stepSize: number) {
    return +(Math.round(value / stepSize) * stepSize).toFixed(2)
}
