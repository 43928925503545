import { Copyright, Modality, Stain } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Constant action types
 */
export enum ConstantActionTypes {
  /**
   * Store
   */
  GetModalities = '[STORE | CONSTANT] Get Modalities',
  GetModalitiesSuccess = '[STORE | CONSTANT] Get Modalities Success',
  SaveModality = '[STORE | CONSTANT] Save Modality',
  SaveModalitySuccess = '[STORE | CONSTANT] Save Modality Success',
  DeleteModality = '[STORE | CONSTANT] Delete Modality',
  DeleteModalitySuccess = '[STORE | CONSTANT] Delete Modality Success',
  GetStains = '[STORE | CONSTANT] Get Stains',
  GetStainsSuccess = '[STORE | CONSTANT] Get Stains Success',
  SaveStain = '[STORE | CONSTANT] Save Stain',
  SaveStainSuccess = '[STORE | CONSTANT] Save Stain Success',
  DeleteStain = '[STORE | CONSTANT] Delete Stain',
  DeleteStainSuccess = '[STORE | CONSTANT] Delete Stain Success',
  GetCopyrights = '[STORE | CONSTANT] Get Copyrights',
  GetCopyrightsSuccess = '[STORE | CONSTANT] Get Copyrights Success',
  SaveCopyright = '[STORE | CONSTANT] Save Copyright',
  SaveCopyrightSuccess = '[STORE | CONSTANT] Save Copyright Success',
  DeleteCopyright = '[STORE | CONSTANT] Delete Copyright',
  DeleteCopyrightSuccess = '[STORE | CONSTANT] Delete Copyright Success',
  /**
   * Request
   */
  GetModalitiesRequest = '[REQUEST | CONSTANT] Get Modalities',
  SaveModalityRequest = '[REQUEST | CONSTANT] Save Modality',
  DeleteModalityRequest = '[REQUEST | CONSTANT] Delete Modality',
  GetStainsRequest = '[REQUEST | CONSTANT] Get Stains',
  SaveStainRequest = '[REQUEST | CONSTANT] Save Stain',
  DeleteStainRequest = '[REQUEST | CONSTANT] Delete Stain',
  GetCopyrightsRequest = '[REQUEST | CONSTANT] Get Copyrights',
  SaveCopyrightRequest = '[REQUEST | CONSTANT] Save Copyright',
  DeleteCopyrightRequest = '[REQUEST | CONSTANT] Delete Copyright'
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Modalities
 */
export class GetModalities {
  public static readonly type = ConstantActionTypes.GetModalities;

  public constructor() {
  }
}

/**
 * Get Modalities Success
 */
export class GetModalitiesSuccess {
  public static readonly type = ConstantActionTypes.GetModalitiesSuccess;

  public constructor(public modalities: Modality[]) {
  }
}

/**
 * Save Modality
 */
export class SaveModality {
  public static readonly type = ConstantActionTypes.SaveModality;

  public constructor(public modality: Modality) {
  }
}

/**
 * Save Modality Success
 */
export class SaveModalitySuccess {
  public static readonly type = ConstantActionTypes.SaveModalitySuccess;

  public constructor(public modality: Modality) {
  }
}

/**
 * Delete Modality
 */
export class DeleteModality {
  public static readonly type = ConstantActionTypes.DeleteModality;

  public constructor(public modality: Modality) {
  }
}

/**
 * Delete Modality Success
 */
export class DeleteModalitySuccess {
  public static readonly type = ConstantActionTypes.DeleteModalitySuccess;

  public constructor(public modality: Modality) {
  }
}

/**
 * Get Stains
 */
export class GetStains {
  public static readonly type = ConstantActionTypes.GetStains;

  public constructor() {
  }
}

/**
 * Get Stains Success
 */
export class GetStainsSuccess {
  public static readonly type = ConstantActionTypes.GetStainsSuccess;

  public constructor(public stains: Stain[]) {
  }
}

/**
 * Save Stain
 */
export class SaveStain {
  public static readonly type = ConstantActionTypes.SaveStain;

  public constructor(public stain: Stain) {
  }
}

/**
 * Save Stain Success
 */
export class SaveStainSuccess {
  public static readonly type = ConstantActionTypes.SaveStainSuccess;

  public constructor(public stain: Stain) {
  }
}

/**
 * Delete Stain
 */
export class DeleteStain {
  public static readonly type = ConstantActionTypes.DeleteStain;

  public constructor(public stain: Stain) {
  }
}

/**
 * Delete Stain Success
 */
export class DeleteStainSuccess {
  public static readonly type = ConstantActionTypes.DeleteStainSuccess;

  public constructor(public stain: Stain) {
  }
}

/**
 * Get Copyrights
 */
export class GetCopyrights {
  public static readonly type = ConstantActionTypes.GetCopyrights;

  public constructor() {
  }
}

/**
 * Get Copyrights Success
 */
export class GetCopyrightsSuccess {
  public static readonly type = ConstantActionTypes.GetCopyrightsSuccess;

  public constructor(public copyrights: Copyright[]) {
  }
}

/**
 * Save Copyright
 */
export class SaveCopyright {
  public static readonly type = ConstantActionTypes.SaveCopyright;

  public constructor(public copyright: Copyright) {
  }
}

/**
 * Save Copyright Success
 */
export class SaveCopyrightSuccess {
  public static readonly type = ConstantActionTypes.SaveCopyrightSuccess;

  public constructor(public copyright: Copyright) {
  }
}

/**
 * Delete Copyright
 */
export class DeleteCopyright {
  public static readonly type = ConstantActionTypes.DeleteCopyright;

  public constructor(public copyright: Copyright) {
  }
}

/**
 * Delete Copyright Success
 */
export class DeleteCopyrightSuccess {
  public static readonly type = ConstantActionTypes.DeleteCopyrightSuccess;

  public constructor(public copyright: Copyright) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Modalities Request
 */
export class GetModalitiesRequest {
  public static readonly type = ConstantActionTypes.GetModalitiesRequest;

  public constructor() {
  }
}

/**
 * Save Modality Request
 */
export class SaveModalityRequest {
  public static readonly type = ConstantActionTypes.SaveModalityRequest;

  public constructor(public modality: Modality) {
  }
}

/**
 * Delete Modality Request
 */
export class DeleteModalityRequest {
  public static readonly type = ConstantActionTypes.DeleteModalityRequest;

  public constructor(public modality: Modality) {
  }
}

/**
 * Get Stains Request
 */
export class GetStainsRequest {
  public static readonly type = ConstantActionTypes.GetStainsRequest;

  public constructor() {
  }
}

/**
 * Save Stain Request
 */
export class SaveStainRequest {
  public static readonly type = ConstantActionTypes.SaveStainRequest;

  public constructor(public stain: Stain) {
  }
}

/**
 * Delete Stain Request
 */
export class DeleteStainRequest {
  public static readonly type = ConstantActionTypes.DeleteStainRequest;

  public constructor(public stain: Stain) {
  }
}

/**
 * Get Copyrights Request
 */
export class GetCopyrightsRequest {
  public static readonly type = ConstantActionTypes.GetCopyrightsRequest;

  public constructor() {
  }
}

/**
 * Save CopyrightRequest
 */
export class SaveCopyrightRequest {
  public static readonly type = ConstantActionTypes.SaveCopyrightRequest;

  public constructor(public copyright: Copyright) {
  }
}

/**
 * Delete Copyright Request
 */
export class DeleteCopyrightRequest {
  public static readonly type = ConstantActionTypes.DeleteCopyrightRequest;

  public constructor(public copyright: Copyright) {
  }
}

// </editor-fold>
