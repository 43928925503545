<!-- Content -->
<div class="mdsf-container js-modal-content-container"
     *ngIf="(nodeSharedFacade.currentSelectedElearningNode$ | async) as node">
  <div class="mdsf-subject-container align-items-center scrollable m-0 p-0 w-100 flex-grow-1"
       *ngIf="(appSharedFacade.page$(node.page) | async) as page">
    <medsurf-carousel *ngIf="setTitle(page.title)"></medsurf-carousel>
    <div class="mdsf-elearning">
      <medsurf-listnav [node]="node"
                       [page]="page"
                       [locked]="(nodeSharedFacade.isNodeLocked$(node, false) | async)"
                       [mainHeading]="true"
                       [showSubjects]="true">
      </medsurf-listnav>
    </div>
  </div>
  <medsurf-footer class="d-none d-md-inline"
                  [links]="footerLinks"
                  align="center">
  </medsurf-footer>
</div>
