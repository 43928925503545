import { map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { SearchControlActions } from '@medsurf/flat-actions';
import { MediaEntityState, PageEntityState, SearchControlState } from '@medsurf/flat-states';

/**
 * Search Control Facade
 */
@Injectable()
export class SearchControlFacade {
  //<editor-fold desc="Selectors">

  public searchString$ = this.store.select(SearchControlState.searchString$);
  public suggestions$ = this.store.select(SearchControlState.suggestions$);
  public slideSearchResultNodes$ = this.store.select(SearchControlState.slideSearchResultNodes$);
  public markerSearchResultNodes$ = this.store.select(SearchControlState.markerSearchResultNodes$);
  public searchResultCount$ = this.store.select(SearchControlState.searchResultCount$);

  //</editor-fold>

  //<editor-fold desc="Lazy Selectors">

  public page$ = (id: any) => this.store.select(PageEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));
  public media$ = (id: any) => this.store.select(MediaEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));

  //</editor-fold>

  //<editor-fold desc="Actions">

  public ofActionDispatchedSearchSuccess = this.actions$.pipe(ofActionCompleted(SearchControlActions.FindSuccess));

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   * @param actions$: Actions
   */
  constructor(public store: Store,
              public actions$: Actions) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Set Search String
   *
   * @param searchString: string
   */
  public requestSetSearchString(searchString: string) {
    return this.store.dispatch(new SearchControlActions.SetSearchString(searchString));
  }

  /**
   * Request Find Slides And Markers
   */
  public requestFindSlidesAndMarkers() {
    return this.store.dispatch(new SearchControlActions.FindSlidesAndMarkers());
  }

  /**
   * Request Find Suggestions
   */
  public requestFindSuggestions() {
    return this.store.dispatch(new SearchControlActions.FindSuggestions());
  }

  //</editor-fold>
}
