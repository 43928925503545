import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MessageService, NodeService } from '@medsurf/services';
import { Page } from '@medsurf/models';
import { GetPageSuccess, GetPageByTitle, GetPageByTitleRequest, } from '@medsurf/actions';
import { AuthState } from './auth.state';

/**
 * Page state model
 */
export interface PageStateModel {
  current: Page;
}

// @dynamic
/**
 * Page state
 */
// @ts-ignore
@State<PageStateModel>({
  name: 'pages',
  defaults: {
    current: null,
  }
})
@Injectable()
export class PageState {
  /**
   * Constructor
   * @param nodeService: NodeService
   * @param store: Store
   * @param messageService: MessageService
   */
  public constructor(
    public nodeService: NodeService,
    public store: Store,
    public messageService: MessageService
  ) {}

  /**
   * Selector pages
   */
   @Selector()
   public static page(state: PageStateModel): Page {
     return state.current;
   }

  // <editor-fold desc="Actions">

  // <editor-fold desc="Get page">

  /**
   * Get page by name
   * @param dispatch: StateContext<PageStateModel>
   * @param title: string
   */
  @Action(GetPageByTitle)
  public getPageByTitle({dispatch}: StateContext<PageStateModel>, {title}: GetPageByTitle) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetPageByTitleRequest(title));
  }

  /**
   * Get page success
   * @param setState: StateContext<PageStateModel>
   * @param page: Page
   */
  @Action(GetPageSuccess)
  public getPageSuccess({setState}: StateContext<PageStateModel>, {page}: GetPageSuccess): void {
    if (page) {
      setState(
        patch({
          current: page
        })
      );
    }
  }

  // </editor-fold>

  // <editor-fold desc="Helper">

  // </editor-fold>
}
