import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { WebSocketConnected, WebSocketDisconnected, WebsocketMessageError } from '@ngxs/websocket-plugin';
import { WsControlActions } from '@medsurf/flat-actions';
import { WsControlState } from '@medsurf/flat-states';

/**
 * Ws Control Facade
 */
@Injectable()
export class WsControlFacade {
  //<editor-fold desc="Selectors">

  public isConnected$ = this.store.select(WsControlState.isConnected$);

  //</editor-fold>

  //<editor-fold desc="Actions">

  public ofActionDispatchedWebSocketConnected = this.actions$.pipe(ofActionDispatched(WebSocketConnected));
  public ofActionDispatchedWebSocketDisconnected = this.actions$.pipe(ofActionDispatched(WebSocketDisconnected));
  public ofActionDispatchedWebSocketMessageError = this.actions$.pipe(ofActionDispatched(WebsocketMessageError));

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   * @param actions$: Actions
   */
  constructor(public store: Store,
              public actions$: Actions) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Set Is Connected
   *
   * @param isConnected: boolean
   */
  public requestSetIsConnected(isConnected: boolean) {
    return this.store.dispatch(new WsControlActions.SetIsConnected(isConnected));
  }

  /**
   * Request Ping
   */
  public requestPing() {
    return this.store.dispatch(new WsControlActions.Ping());
  }

  /**
   * Request Connect
   */
  public requestConnect() {
    return this.store.dispatch(new WsControlActions.Connect());
  }

  //</editor-fold>
}
