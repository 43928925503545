import { distinctUntilChanged, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import * as FlatActions from '@medsurf/flat-actions';
import * as FlatStates from '@medsurf/flat-states';
import { isEqual } from 'lodash';

/**
 * Slide Shared Facade
 */
@Injectable()
export class SlideViewerFacade {
  //<editor-fold desc="Selectors">

  public themeColor$ = this.store.select(FlatStates.PageEntityState.themeColor$);
  public currentSelectedSlideSidebarSize$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlideSidebarSize$);
  public currentSelectedSlideMainFullSize$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlideMainFullSize$);
  public currentSelectedSlideSidebarFullSize$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlideSidebarFullSize$);
  public currentSelectedSlideMainSize$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlideMainSize$);

  public currentSelectedSlideNodes$ = this.store.select(FlatStates.NodeEntityState.currentSelectedSlideNodes$);
  public currentSelectedSlideNode$ = this.store.select(FlatStates.NodeEntityState.currentSelectedSlideNode$);
  public currentSelectedSlideNodesFiltered$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlideNodesFiltered$);
  public currentSelectedSlideIndex$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlideIndex$);
  public currentSelectedSlidePercentage$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlidePercentage$);
  public previousSlideNode$ = this.store.select(FlatStates.PageEntityState.previousSlideNode$);
  public previousSlideUrl$ = this.store.select(FlatStates.PageEntityState.previousSlideUrl$);
  public nextSlideNode$ = this.store.select(FlatStates.PageEntityState.nextSlideNode$);
  public nextSlideUrl$ = this.store.select(FlatStates.PageEntityState.nextSlideUrl$);
  public currentSelectedSlidePage$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlidePage$);
  public currentSelectedSlideLayers$ = this.store.select(FlatStates.SlideLayerEntityState.currentSelectedSlideLayers$);
  public currentSelectedSlideLayer$ = this.store.select(FlatStates.SlideLayerEntityState.currentSelectedSlideLayer$);
  public currentSelectedSlideValidImages$ = this.store.select(FlatStates.ImageEntityState.currentSelectedSlideValidImages$)
  public imageIndex$ = this.store.select(FlatStates.ImageEntityState.imageIndex$);
  public currentSelectedSlideImages$ = this.store.select(FlatStates.ImageEntityState.currentSelectedSlideImages$);
  public currentSelectedSlideImage$ = this.store.select(FlatStates.ImageEntityState.currentSelectedSlideImage$);
  public currentSelectedSlideValidMedias$ = this.store.select(FlatStates.MediaEntityState.currentSelectedSlideValidMedias$);
  public currentSelectedSlideMedia$ = this.store.select(FlatStates.MediaEntityState.currentSelectedSlideMedia$);
  public currentSelectedSlideAreaGroups$ = this.store.select(FlatStates.AnnotationGroupEntityState.currentSelectedSlideAreaGroups$);
  public currentSelectedSlideGroups$ = this.store.select(FlatStates.AnnotationGroupEntityState.currentSelectedSlideGroups$);
  public currentSelectedSlideGroup$ = this.store.select(FlatStates.AnnotationGroupEntityState.currentSelectedSlideGroup$);
  public currentSelectedSlideAreaGroup$ = this.store.select(FlatStates.AnnotationGroupEntityState.currentSelectedSlideAreaGroup$);
  public currentSelectedSlidePois$ = this.store.select(FlatStates.PointOfInterestEntityState.currentSelectedSlidePois$);
  public currentSelectedSlidePoi$ = this.store.select(FlatStates.PointOfInterestEntityState.currentSelectedSlidePoi$);
  public currentSelectedSlideAnnotation$ = this.store.select(FlatStates.AnnotationEntityState.currentSelectedSlideAnnotation$);

  public allCurrentSelectedSlideCopyrights$ = this.store.select(FlatStates.CopyrightEntityState.allCurrentSelectedSlideCopyrights$);

  public currentSelectedSlideHasSequenceControl$ = this.store.select(FlatStates.ImageEntityState.currentSelectedSlideHasSequenceControl$);
  public currentSelectedSlideImageHasAnnotations$ = this.store.select(FlatStates.ImageEntityState.currentSelectedSlideImageHasAnnotations$);

  public images$ = this.store.select(FlatStates.ImageEntityState.currentSelectedSlideValidImages$);
  public defaultIndex$ = this.store.select(FlatStates.ImageEntityState.currentSelectedImagesDefaultIndex$);
  
  public currentActiveEvents$ = this.store.select(FlatStates.MediaInteractionViewerState.currentActiveEvents$).pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
  public currentTime$ = this.store.select(FlatStates.MediaInteractionViewerState.currentTime$);

  //</editor-fold>

  //<editor-fold desc="Lazy Selectors">

  public page$ = (id: any) => this.store.select(FlatStates.PageEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));

  public annotation = (id: any) => this.store.select(FlatStates.AnnotationEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));
  public annotationLabel = (id: any) => this.store.select(FlatStates.AnnotationLabelEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));

  public notesByIds = (ids: any) => this.store.select(FlatStates.NoteEntityState.entitiesByIds$).pipe(map(filterFn => filterFn(ids as unknown as string[])));
  public slideUrlByNode$ = (slideNode: FlatModels.NodeEntityModels.SlideNode) => this.store.select(FlatStates.SlideViewerState.slideUrlByNode$).pipe(map(filterFn => filterFn(slideNode)));

  //</editor-fold>

  //<editor-fold desc="Snapshot Selectors">

  public snapshop_areaGroupId = () => this.store.selectSnapshot(FlatStates.AnnotationGroupEntityState.areaGroupId$);
  public snapshop_currentSelectedSlideGroup = () => this.store.selectSnapshot(FlatStates.AnnotationGroupEntityState.currentSelectedSlideGroup$);
  public snapshot_currentSelectedSlideAnnotation = () => this.store.selectSnapshot(FlatStates.AnnotationEntityState.currentSelectedSlideAnnotation$);
  public snapshot_imageIndex = () => this.store.selectSnapshot(FlatStates.ImageEntityState.imageIndex$);

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(private store: Store) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Get Slide Data By Page Id
   *
   * @param pageId: string
   */
  public requestGetSlideDataByPageId(pageId: string) {
    return this.store.dispatch(new FlatActions.SlideControlActions.GetSlideDataByPageId(pageId));
  }

  /**
   * Request Set Layer Index
   *
   * @param layerIndex: number
   */
  public requestSetLayerIndex(layerIndex: number) {
    const layerCount = this.store.selectSnapshot(FlatStates.SlideLayerEntityState.currentSelectedSlideLayers$).length || 0;
    if (0 <= layerIndex && layerIndex <= (layerCount - 1)) {
      return this.store.dispatch(new FlatActions.SlideLayerEntityActions.SetLayerIndex(layerIndex));
    }
    return;
  }

  /**
   * Request Set Image Index
   *
   * @param imageIndex: number
   */
  public requestSetImageIndex(imageIndex: number) {
    const imageCount = this.store.selectSnapshot(FlatStates.ImageEntityState.currentSelectedSlideValidImages$).length || 0;
    if (0 <= imageIndex && imageIndex <= (imageCount - 1)) {
      return this.store.dispatch(new FlatActions.ImageEntityActions.SetImageIndex(imageIndex));
    }
    return;
  }

  public requestSetCurrentTime(currentTime: number) {
    return this.store.dispatch(new FlatActions.MediaInteractionViewerActions.SetCurrentTime(currentTime));
  }

  public requestSetEvents(events: FlatModels.MediaInteractionViewerModels.MediaInteractionEvent[]) {
    return this.store.dispatch(new FlatActions.MediaInteractionViewerActions.SetEvents(events));
  }

  public requestJumpToTime(time: number) {
    return this.store.dispatch(new FlatActions.MediaInteractionViewerActions.JumpToTime(time));
  }

  /**
   * Request Set Group Id
   *
   * @param groupId: string | null
   */
  public requestSetGroupId(groupId: string | null) {
    return this.store.dispatch(new FlatActions.AnnotationGroupEntityActions.DebounceSetGroupId(groupId));
  }

  /**
   * Request Set Area Group Id
   *
   * @param groupAreaId: string | null
   */
  public requestSetAreaGroupId(areaGroupId: string | null) {
    return this.store.dispatch(new FlatActions.AnnotationGroupEntityActions.DebounceSetAreaGroupId(areaGroupId));
  }

  /**
   * Request Set Poi Id
   *
   * @param poiId: string
   */
  public requestSetPoiId(poiId: string) {
    return this.store.dispatch(new FlatActions.PointOfInterestEntityActions.SetPoiId(poiId));
  }

  /**
   * Request Set Annotation Id
   *
   * @param annotationId: string
   */
  public requestSetAnnotationId(annotationId: string) {
    return this.store.dispatch(new FlatActions.AnnotationEntityActions.SetAnnotationId(annotationId));
  }

  /**
   * Request Go To Slide By Index
   *
   * @param eventTarget: EventTarget
   * @param currentIndex: number
   * @param selectedSlides: string[],
   * @param selectedSlideNodes: FlatModels.NodeEntityModels.SlideNode[],
   * @param queryParams: Params
   */
  public requestGoToSlideByIndex(eventTarget: EventTarget,
                                 currentIndex: number,
                                 selectedSlides: string[],
                                 selectedSlideNodes: FlatModels.NodeEntityModels.SlideNode[],
                                 queryParams: Params) {
    // Get Index
    const target = eventTarget as HTMLInputElement;
    let index = Number.parseInt(target.value);
    if (Number.isInteger(index)) {
      index -= 1;
      if (index >= 0 && index < selectedSlides.length) {
        const selectedSlideId = selectedSlides[index];
        const selectedSlideNode = selectedSlideNodes.find(sn => sn.id === selectedSlideId);
        if (selectedSlideNode) {
          return this.store.dispatch(new FlatActions.SlideViewerActions.GoToSlideByNode(selectedSlideNode, queryParams));
        } else {
          throw new Error("no_slide_node_found");
        }
      }
    }

    // Reset Index Value
    target.value = (currentIndex + 1).toString();
    return false;
  }

  //</editor-fold>

}
