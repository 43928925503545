import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeedbackControlService {
  /**
   * Members
   */
  private _onOpenFeedbackModal = new EventEmitter();
  private _showFeedbackModal = false;

  /**
   * Open Feedback Modal
   */
  public openFeedbackModal() {
    this._showFeedbackModal = true;
    this._onOpenFeedbackModal.emit();
  }

  /**
   * Close Feedback Modal
   */
  public closeFeedbackModal() {
    this._showFeedbackModal = false;
  }

  /**
   * Show Feedback Modal
   */
  public get showFeedbackModal() {
    return this._showFeedbackModal;
  }

  /**
   * On Open Feedback Modal
   */
  public get onOpenFeedbackModal(): EventEmitter<any> {
    return this._onOpenFeedbackModal;
  }
}