import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { PageEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import { NavigationControlState, NodeEntityState, SlideControlState } from '../../internal';

/**
 * Page Entity Token
 */
export const PAGE_ENTITY_TOKEN = new StateToken<PageEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.PAGE);

/**
 * Page Entity State Model
 */
export type PageEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.PageEntityModels.PageEntityType>;

/**
 * Page Entity State
 */
@State<PageEntityStateModel>({
  name: PAGE_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class PageEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: PageEntityStateModel
   */
  @Selector([PageEntityState])
  public static typedEntities$(state: PageEntityStateModel): { [id: string]: FlatModels.PageEntityModels.PageEntityType } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }
   */
  @Selector([PageEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }): FlatModels.PageEntityModels.PageEntityType[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }
   */
  @Selector([PageEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.PageEntityModels.PageEntityType[]
   */
  @Selector([PageEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.PageEntityModels.PageEntityType[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //<editor-fold desc="Page">

  /**
   * Selector currentSelectedPagePage$
   *
   * @param entities: FlatModels.PageEntityModels.PageEntityType[]
   * @param pageSlug: string | null
   */
  @Selector([
    PageEntityState.entities$,
    NavigationControlState.pageSlug$
  ])
  public static currentSelectedPagePage$(entities: FlatModels.PageEntityModels.PageEntityType[],
                                        pageSlug: string | null) {
    return pageSlug && entities ? entities.find(e => (e.title as string).toLowerCase() === pageSlug) : null;
  }

  //</editor-fold>

  //<editor-fold desc="Slide">

  /**
   * Selector currentSelectedElearningPage$
   *
   * @param typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }
   * @param elearningNode: FlatModels.NodeEntityModels.NodeEntityType
   */
  @Selector([
    PageEntityState.typedEntities$,
    NodeEntityState.currentSelectedElearningNode$
  ])
  public static currentSelectedElearningPage$(typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType },
                                              elearningNode: FlatModels.NodeEntityModels.NodeEntityType) {
    return elearningNode && (typedEntities[elearningNode.page as unknown as string]) || null;
  }

  /**
   * Selector currentSelectedSubjectPage$
   *
   * @param typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }
   * @param subjectNode: FlatModels.NodeEntityModels.NodeEntityType
   */
  @Selector([
    PageEntityState.typedEntities$,
    NodeEntityState.currentSelectedSubjectNode$
  ])
  public static currentSelectedSubjectPage$(typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType },
                                            subjectNode: FlatModels.NodeEntityModels.NodeEntityType) {
    return subjectNode && (typedEntities[subjectNode.page as unknown as string]) || null;
  }

  /**
   * Selector currentSelectedChapterPage$
   *
   * @param typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }
   * @param chapterNode: FlatModels.NodeEntityModels.NodeEntityType
   */
  @Selector([
    PageEntityState.typedEntities$,
    NodeEntityState.currentSelectedChapterNode$
  ])
  public static currentSelectedChapterPage$(typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType },
                                            chapterNode: FlatModels.NodeEntityModels.NodeEntityType) {
    return chapterNode && (typedEntities[chapterNode.page as unknown as string]) || null;
  }

  /**
   * Selector currentSelectedChapterPageType$
   *
   * @param chapterPage: FlatModels.PageEntityModels.PageEntityType
   */
  @Selector([PageEntityState.currentSelectedChapterPage$])
  public static currentSelectedChapterPageType$(chapterPage: FlatModels.PageEntityModels.PageEntityType) {
    return chapterPage && chapterPage.type || null;
  }

  /**
   * Selector currentSelectedBlockPage$
   *
   * @param typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }
   * @param blockNode: FlatModels.NodeEntityModels.NodeEntityType
   */
  @Selector([
    PageEntityState.typedEntities$,
    NodeEntityState.currentSelectedBlockNode$
  ])
  public static currentSelectedBlockPage$(typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType },
                                          blockNode: FlatModels.NodeEntityModels.NodeEntityType) {
    return blockNode && (typedEntities[blockNode.page as unknown as string]) || null;
  }

  /**
   * Selector currentSelectedSlideTitle$
   *
   * @param elearningPage: FlatModels.PageEntityModels.PageEntityType
   * @param subjectPage: FlatModels.PageEntityModels.PageEntityType
   * @param chapterPage: FlatModels.PageEntityModels.PageEntityType
   * @param blockPage: FlatModels.PageEntityModels.PageEntityType
   * @param slidePage: FlatModels.PageEntityModels.PageEntityType
   */
  @Selector([
    PageEntityState.currentSelectedElearningPage$,
    PageEntityState.currentSelectedSubjectPage$,
    PageEntityState.currentSelectedChapterPage$,
    PageEntityState.currentSelectedBlockPage$,
    PageEntityState.currentSelectedSlidePage$
  ])
  public static currentSelectedSlideTitle$(elearningPage: FlatModels.PageEntityModels.PageEntityType,
                                           subjectPage: FlatModels.PageEntityModels.PageEntityType,
                                           chapterPage: FlatModels.PageEntityModels.PageEntityType,
                                           blockPage: FlatModels.PageEntityModels.PageEntityType,
                                           slidePage: FlatModels.PageEntityModels.PageEntityType) {
    const titleArray: string[] = [];
    if (slidePage && slidePage.title) {
        titleArray.push(slidePage.title);
    }
    if (blockPage && blockPage.title) {
      titleArray.push(blockPage.title);
    }
    if (chapterPage && chapterPage.title) {
      titleArray.push(chapterPage.title);
    }
    if (subjectPage && subjectPage.title) {
      titleArray.push(subjectPage.title);
    }
    if (elearningPage && elearningPage.title) {
      titleArray.push(elearningPage.title);
    }
    return titleArray.join(' | ');
  }

  /**
   * Selector currentSelectedSlidePage$
   *
   * @param typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }
   * @param slideNode: FlatModels.NodeEntityModels.NodeEntityType
   */
  @Selector([
    PageEntityState.typedEntities$,
    NodeEntityState.currentSelectedSlideNode$,
    NavigationControlState.idSlug$

  ])
  public static currentSelectedSlidePage$(typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType },
                                          slideNode: FlatModels.NodeEntityModels.NodeEntityType, 
                                          id: string): FlatModels.PageEntityModels.Slide {

    if (slideNode) {
      return  typedEntities[slideNode.page as unknown as string] || null;
    } 
    return typedEntities?.[id] ?? null;
  }



  /**
   * Selector currentSelectedSlideSidebarSize$
   *
   * @param currentSelectedSlidePage: FlatModels.PageEntityModels.PageEntityType
   */
  @Selector([PageEntityState.currentSelectedSlidePage$])
  public static currentSelectedSlideSidebarSize$(currentSelectedSlidePage: FlatModels.PageEntityModels.Slide) {
    return Number(currentSelectedSlidePage?.sideBarSize) ?? 3;
  }

  /**
   * Selector currentSelectedSlideMainSize$
   *
   * @param currentSelectedSlideSidebarSize: number
   */
  @Selector([PageEntityState.currentSelectedSlideSidebarSize$])
  public static currentSelectedSlideMainSize$(currentSelectedSlideSidebarSize: number) {
    return 12 - currentSelectedSlideSidebarSize;
  }

  /**
   * Selector currentSelectedSlideSidebarFullSize$
   *
   * @param currentSelectedSlideSideBarSize: number
   */
  @Selector([PageEntityState.currentSelectedSlideSidebarSize$])
  public static currentSelectedSlideSidebarFullSize$(currentSelectedSlideSidebarSize: number) {
    return currentSelectedSlideSidebarSize >= 12;
  }

  /**
   * Selector currentSelectedSlideMainFullSize$
   *
   * @param currentSelectedSlideSidebarSize: number
   */
  @Selector([PageEntityState.currentSelectedSlideSidebarSize$])
  public static currentSelectedSlideMainFullSize$(currentSelectedSlideSidebarSize: number) {
    return currentSelectedSlideSidebarSize <= 0;
  }

  //</editor-fold>

  /**
   * Selector themeColor$
   *
   * @param subjectPage: FlatModels.PageEntityModels.PageEntityType
   */
  @Selector([PageEntityState.currentSelectedSubjectPage$])
  public static themeColor$(subjectPage: FlatModels.PageEntityModels.PageEntityType) {
    return subjectPage && (subjectPage as FlatModels.PageEntityModels.Folder).themeColor || 'black';
  }

  /**
   * TODO implement better with one of the above selectors
   * Selector currentSelectedSlideNodesFiltered$
   *
   * @param typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType }
   * @param nodes: FlatModels.NodeEntityModels.NodeEntityType[]
   */
  @Selector([
    PageEntityState.typedEntities$,
    NodeEntityState.currentSelectedSlideNodes$
  ])
  public static currentSelectedSlideNodesFiltered$(typedEntities: { [id: string]: FlatModels.PageEntityModels.PageEntityType },
                                                   nodes: FlatModels.NodeEntityModels.NodeEntityType[]) {
    if (nodes) {
      const filteredNodes = nodes.filter(n => {
        const page = typedEntities[n.page as unknown as string] as FlatModels.PageEntityModels.Slide;
        return page && page.type !== FlatModels.PageEntityModels.PageType.TRAINING && !page.hidden;
      }).sort((a, b) => (a.position || 0) - (b.position || 0));
      // TODO check if we want to sort and recalculate position only, when we filter some nodes out
      for (let i = 0; i < filteredNodes.length; i++) {
        filteredNodes[i] = {...filteredNodes[i], position: i};
      }
      return filteredNodes;
    }
    return [];
  }

  /**
   * Selector currentSelectedSlideIndex$
   *
   * @param currentSelectedSlideNodesFiltered: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param currentSelectedSlideNode: FlatModels.NodeEntityModels.SlideNode
   */
  @Selector([
    PageEntityState.currentSelectedSlideNodesFiltered$,
    NodeEntityState.currentSelectedSlideNode$
  ])
  public static currentSelectedSlideIndex$(currentSelectedSlideNodesFiltered: FlatModels.NodeEntityModels.NodeEntityType[],
                                           currentSelectedSlideNode: FlatModels.NodeEntityModels.SlideNode) {
    if (currentSelectedSlideNode && Array.isArray(currentSelectedSlideNodesFiltered)) {
      const selectedSlideNode = currentSelectedSlideNodesFiltered.find(ss => ss.id === currentSelectedSlideNode.id);
      if (selectedSlideNode) {
        return Number.isInteger(selectedSlideNode.position) ? selectedSlideNode.position : null;
      }
    }
    return null;
  }

  /**
   * Selector currentSelectedSlidePercentage$
   *
   * @param currentSelectedSlideIndex: number | null
   * @param currentSelectedSlideNodesFiltered: FlatModels.NodeEntityModels.NodeEntityType[]
   */
  @Selector([
    PageEntityState.currentSelectedSlideIndex$,
    PageEntityState.currentSelectedSlideNodesFiltered$
  ])
  public static currentSelectedSlidePercentage$(currentSelectedSlideIndex: number | null,
                                                currentSelectedSlideNodesFiltered: FlatModels.NodeEntityModels.NodeEntityType[]) {
    if (currentSelectedSlideIndex !== null && Array.isArray(currentSelectedSlideNodesFiltered)) {
      const item = 1 / currentSelectedSlideNodesFiltered.length;
      return (item * (currentSelectedSlideIndex + 1)) - item / 2;
    }
    return null;
  }

  /**
   * Selector previousSlideNode$
   *
   * @param currentSelectedSlideIndex: number | null
   * @param currentSelectedSlideNodesFiltered: FlatModels.NodeEntityModels.SlideNode[]
   */
  @Selector([
    PageEntityState.currentSelectedSlideIndex$,
    PageEntityState.currentSelectedSlideNodesFiltered$
  ])
  public static previousSlideNode$(currentSelectedSlideIndex: number | null,
                                   currentSelectedSlideNodesFiltered: FlatModels.NodeEntityModels.SlideNode[]) {
    if (currentSelectedSlideIndex !== null && Number.isInteger(currentSelectedSlideIndex) && Array.isArray(currentSelectedSlideNodesFiltered)) {
      if (currentSelectedSlideIndex > 0) {
        return currentSelectedSlideNodesFiltered.find(sn => sn.position === (currentSelectedSlideIndex - 1)) || null;
      }
    }
    return null;
  }

  /**
   * Selector previousSlideUrl$
   *
   * @param previousSlideNode: FlatModels.NodeEntityModels.SlideNode | null
   * @param blockUrl: string | null
   */
  @Selector([
    PageEntityState.previousSlideNode$,
    NavigationControlState.blockUrl$
  ])
  public static previousSlideUrl$(previousSlideNode: FlatModels.NodeEntityModels.SlideNode | null,
                                  blockUrl: string | null) {
    if (blockUrl) {
      return previousSlideNode ? blockUrl + '/' + previousSlideNode.route : blockUrl;
    }
    return null;
  }

  /**
   * Selector nextSlideNode$
   *
   * @param currentSelectedSlideIndex: number | null
   * @param currentSelectedSlideNodesFiltered: FlatModels.NodeEntityModels.SlideNode[]
   */
  @Selector([
    PageEntityState.currentSelectedSlideIndex$,
    PageEntityState.currentSelectedSlideNodesFiltered$
  ])
  public static nextSlideNode$(currentSelectedSlideIndex: number | null,
                               currentSelectedSlideNodesFiltered: FlatModels.NodeEntityModels.SlideNode[]) {
    if (currentSelectedSlideIndex !== null && Number.isInteger(currentSelectedSlideIndex) && Array.isArray(currentSelectedSlideNodesFiltered)) {
      if (currentSelectedSlideIndex > -1 && currentSelectedSlideIndex <= currentSelectedSlideNodesFiltered.length) {
        return currentSelectedSlideNodesFiltered.find(sn => sn.position ===(currentSelectedSlideIndex + 1)) || null;
      }
    }
    return null;
  }

  /**
   * Selector nextSlideUrl$
   *
   * @param nextSlideNode: FlatModels.NodeEntityModels.SlideNode | null
   * @param blockUrl: string | null
   */
  @Selector([
    PageEntityState.nextSlideNode$,
    NavigationControlState.blockUrl$
  ])
  public static nextSlideUrl$(nextSlideNode: FlatModels.NodeEntityModels.SlideNode | null,
                              blockUrl: string | null) {
    if (blockUrl) {
      return nextSlideNode ? blockUrl + '/' + nextSlideNode.route : blockUrl;
    }
    return null;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<PageEntityStateModel>
   * @param patchState: StateContext<PageEntityStateModel>
   * @param entityQueryResult: PageEntityActions.GetEntitySuccess
   */
  @Action(PageEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<PageEntityStateModel>,
                          {entityQueryResult}: PageEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<PageEntityStateModel>
   * @param patchState: StateContext<PageEntityStateModel>
   * @param entityQueryResults: PageEntityActions.GetEntitiesSuccess
   */
  @Action(PageEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<PageEntityStateModel>,
                            {entityQueryResults}: PageEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<PageEntityStateModel>
   * @param patchState: StateContext<PageEntityStateModel>
   * @param entity: PageEntityActions.AddEntity
   */
  @Action(PageEntityActions.AddEntity)
  @Undoable(PageEntityActions.AddEntity, PAGE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<PageEntityStateModel>,
                   {entity}: PageEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<PageEntityStateModel>
   * @param patchState: StateContext<PageEntityStateModel>
   * @param entity: PageEntityActions.UpdateEntity
   */
  @Action(PageEntityActions.UpdateEntity)
  @Undoable(PageEntityActions.UpdateEntity, PAGE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<PageEntityStateModel>,
                      {entity}: PageEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<PageEntityStateModel>
   * @param patchState: StateContext<PageEntityStateModel>
   * @param entity: PageEntityActions.RemoveEntity
   */
  @Action(PageEntityActions.RemoveEntity)
  @Undoable(PageEntityActions.RemoveEntity, PAGE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<PageEntityStateModel>,
                      {entity}: PageEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
