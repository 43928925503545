import { Component, ElementRef } from '@angular/core';
import { MenuViewerFacade, SlideViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-slide-sidebar',
  templateUrl: './slide-sidebar.component.html',
  styleUrls: ['./slide-sidebar.component.scss']
})
export class SlideSidebarComponent {
  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   */
  public constructor(public slideViewerFacade: SlideViewerFacade, 
                    public elRef: ElementRef,
                    public menuViewerFacade: MenuViewerFacade) {
  }
}
