import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as FlatModels from '@medsurf/flat-models';
import { AppSharedFacade, AuthControlFacade, NavigationControlFacade, NodeSharedFacade } from '@medsurf/flat-facades';
import {
  FLAT_SETTINGS,
  MediaControlService,
  ModalControlService,
  NavigationControlService,
  SettingControlModel
} from '@medsurf/flat-services';
import { UserEntityModels } from '@medsurf/flat-models';
import { Role } from '@medsurf/models';

@Component({
  selector: 'medsurf-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  /**
   * Constructor
   *
   * @param appSharedFacade: AppSharedFacade
   * @param nodeSharedFacade: NodeSharedFacade
   * @param navigationControlFacade: NavigationControlFacade
   * @param authControlFacade: AuthControlFacade
   * @param modalControlService: ModalControlService
   * @param translate: TranslateService
   * @param router: Router
   * @param mediaControlService: MediaControlService
   * @param navigationControlService: NavigationControlService
   * @param titleService: Title
   * @param settings: SettingControlModel
   */
  constructor(public appSharedFacade: AppSharedFacade,
              public nodeSharedFacade: NodeSharedFacade,
              public navigationControlFacade: NavigationControlFacade,
              public authControlFacade: AuthControlFacade,
              public modalControlService: ModalControlService,
              public translate: TranslateService,
              public router: Router,
              public mediaControlService: MediaControlService,
              public navigationControlService: NavigationControlService,
              public titleService: Title,
              @Inject(FLAT_SETTINGS) public settings: SettingControlModel) {
  }

  /**
   * Set Title
   *
   * @param title: string
   */
  public setTitle(title: string) {
    this.titleService.setTitle(title);
    return true;
  }

  /**
   * Show Home
   */
  public get showHome(): boolean {
    return !this.router.isActive('', true);
  }

  /**
   * Local Title Clicked
   */
  public localTitleClicked() {
    this.navigationControlService.goSubject();
  }

  /**
   * Local Close
   */
  public localClose() {
    window.close();
  }

  /**
   * Logout
   */
  public async logout() {
    if (this.authControlFacade.snapshot_isAuthenticated()) {
      this.authControlFacade.requestLogout();
      this.navigationControlFacade.requestNavigate(['']);
    }
  }

  /**
   * Open Modal
   */
  public openModal() {
    this.modalControlService.openModal();
  }

  /**
   * Opens a new Tab to download a PDF according to chapter or slide
   *
   * @param pdfMedia: FlatModels.MediaEntityModels.MediaEntityType
   * @return void
   */
  public downloadPDF(pdfMedia: FlatModels.MediaEntityModels.MediaEntityType, pdfMediaAuthor: FlatModels.MediaEntityModels.MediaEntityType, user: UserEntityModels.User ): void {
    const media = pdfMediaAuthor && user && user?.role !== Role.VIEWER ? pdfMediaAuthor : pdfMedia;
  
    if (media) {
      const pdfUrl = this.mediaControlService.getUrl(media);
      window.open(pdfUrl, '_blank');
    }
  }

  /**
   * Edit In Author
   *
   * @param currentUrl: string
   */
  public editInAuthor(currentUrl: string) {
    const url = `${this.settings.authorUrl}${currentUrl}`;
    open(url, '_medsurf_author');
  }
}
