import { Injectable } from '@angular/core';
import { Action, State, StateToken, Store } from '@ngxs/store';
import { MetadataControlActions } from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';
import { AuthControlState, MetadataEntityState } from '../../internal';

/**
 * Metadata Control Token
 */
export const METADATA_CONTROL_TOKEN = new StateToken<MetadataControlStateModel>('metadataControl');

/**
 * Metadata Control State Model
 */
export interface MetadataControlStateModel {
  initialLoad: boolean;
}

/**
 * Metadata Control State
 */
@State<MetadataControlStateModel>({
  name: METADATA_CONTROL_TOKEN,
  defaults: {
    initialLoad: false
  }
})
@Injectable()
export class MetadataControlState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageService: MessageControlService
   */
  public constructor(public store: Store,
                     public messageService: MessageControlService) {
  }

  //<editor-fold desc="Actions">

  /**
   * Get Metadata
   */
  @Action(MetadataControlActions.GetMetadata)
  public getMetadata() {
    if (!this.store.selectSnapshot(MetadataEntityState.metadata)) {
      return this.messageService.sendMessage(
        this.store.selectSnapshot(AuthControlState.token$),
        new MetadataControlActions.GetMetadataRequest()
      );
    }
    return;
  }
  //</editor-fold>
}
