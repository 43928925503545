import { v4 as uuid } from 'uuid';
import paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { End } from '../marker/pointer/end/end';
import { Label } from '../marker/label/label';
import { Pointer } from '../marker/pointer/pointer';
import { Line } from '../marker/pointer/line';

export class InteractiveArea {
  /**
   * Members
   */
  public _selftests: any[];
  public _state: any;
  private _element: any;
  private _segments: any[];
  private _labels: any[];
  private _pointers: any[];
  private _selection: any;
  private _forkLine: any;
  private _endType: any;
  private _end: End;

  /**
   * Constructor
   *
   * @param _model: ImageViewerModels.FreeformModel
   * @param _localPaper
   * @param _format
   * @param _marker
   */
  constructor(private _model: ImageViewerModels.FreeformModel,
              private _localPaper,
              private _format,
              public _marker) {
    this._selftests = [];
    this._state = undefined;

    this._element = null;
    this._segments = [];
    this._labels = [];
    this._pointers = [];
    this._selection = null;
    this._forkLine = null;
    if (this._model.target) {
      this._endType = this._model.target.endType;
    }
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    this.cleanUp();
    this.drawPath();
    if (this._segments.length > 0) {
      if (this._element.freeform._labelVisible && (this._model.label && !this._model.label.hidden)) {
        this.drawPointers();
        this.drawForkLine();
        this.drawLabels();
      }
    }
    this.arrange();
  }

  /**
   * Draw Path
   */
  public drawPath() {
    let end;

    if (this._model.annotation.path && this._model.annotation.path.length > 1) {
      for (const pathPoint of this._model.annotation.path) {
        this._segments.push(new paper.Point(this._marker.container.getAbsoluteCoords(
          pathPoint.x,
          pathPoint.y,
          this._marker._imageOffset,
          this._marker._imageScale
        )));
      }
      end = new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.annotation.path[this._model.annotation.path.length - 1].x,
        this._model.annotation.path[this._model.annotation.path.length - 1].y,
        this._marker._imageOffset,
        this._marker._imageScale
      ));
    } else {
      return;
      /* TODO change this to return -> do also for free.ts
      this._segments.push(new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.source.x,
        this._model.source.y,
        this._marker._imageOffset,
        this._marker._imageScale
      )));
      this._segments.push( end = new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.source.x + this._model.freeFormStyle.width,
        this._model.source.y + this._model.freeFormStyle.height,
        this._marker._imageOffset,
        this._marker._imageScale
      )));
      */
    }

    this._element = new paper.Path(this._segments);

    this._element.strokeColor = this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor;
    this._element.strokeWidth = (this._model.freeFormStyle.strokeWidth || this._format.strokeWidth) / this._marker._imageScale;
    this._element.dashArray = this._model.freeFormStyle.dash ?
      this._model.freeFormStyle.dash
        .split(',')
        .map((dash) => {
          return <any>dash * (this._marker && this._marker._model.freeFormStyle && this._marker._model.freeFormStyle.strokeWidth ?
              this._marker._model.freeFormStyle.strokeWidth :
              1
          );
        }) :
      [1, 0];
    this._element._model = this._model;

    if (this._model.freeFormStyle.closePath) {
      this._element.closed = true;
      this._element.fillColor = this._model.freeFormStyle.background ?
        (this._model.freeFormStyle.backgroundInBorderColor ?
          this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor :
          this._model.freeFormStyle.color || this._format.border.fillColor) :
        undefined;
      if (this._element.fillColor) {
        this._element.fillColor.alpha = this._model.freeFormStyle.opacity;
      }
    }

    if (this._model.freeFormStyle.smooth) {
      this._element.smooth({
        type: 'geometric',
        factor: this._model.freeFormStyle.smoothFactor ?? 0
      });
    }

    this._element.freeform = this._marker;
    this._marker._element.addChild(this._element);

    if (!this._model.freeFormStyle.closePath) {
      this.drawEnd(end);
    }
  }

  /**
   * Draw End
   *
   * @param end
   */
  public drawEnd(end) {
    this._format.strokeColor = this._element.strokeColor;
    this._format.circleRadius = this._model.freeFormStyle.endRadius || 10;
    this._end = new End(this._endType, this._format, end, this);

    if (this._endType === 'dot' || this._endType === 'arrow') {
      this._end.element.element.firstChild.freeform = this._marker;
      this._marker._element.addChild(this._end.element.element.firstChild);
      this._end.element.element.radius = this._end.element.element.radius +
        (this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth);
    } else {
      this._end.element.element.freeform = this._marker;
      this._marker._element.addChild(this._end.element.element);
    }

    if (this._endType === 'arrow') {
      this._end.element.path.rotation = 90 + this._end.element.angle(
        this._element.segments[this._element.segments.length - 2].point.x,
        this._element.segments[this._element.segments.length - 2].point.y,
        this._element.segments[this._element.segments.length - 1].point.x,
        this._element.segments[this._element.segments.length - 1].point.y
      );
    } else if (this._endType === 'circle') {
      const intersections = this._element.segments[0].path.getIntersections(this._end.element.element);
      if (intersections.length) {
        this._element.segments[this._element.segments.length - 1].point = intersections[0].point;
      }
      this._end.element.element.strokeWidth = this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth;
    }
  }

  /**
   * Draw Pointers
   */
  public drawPointers() {
    let source = this._marker.container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    );
    if (this._model.annotation.fork.x && this._model.annotation.fork.y) {
      source = this._marker.container.getAbsoluteCoords(
        this._model.annotation.fork.x,
        this._model.annotation.fork.y,
        this._marker._imageOffset,
        this._marker._imageScale
      );
    }
    if (this._model.freeFormStyle.closePath && this._element.contains(source)) {
      return;
    }
    const target = this._element.getNearestPoint(new paper.Point(source));
    const pointer = new Pointer(
      (this._model.target && this._model.target.endType) ? (this._model.target.endType) : 'dot',
      this._format,
      source,
      target,
      this._marker
    );
    this._marker._element.addChild(pointer.end.element.element);
    this._marker._element.addChild(pointer.line.element);
    this._pointers.push(pointer);
  }

  /**
   * Draw Fork Line
   */
  public drawForkLine() {
    if (this._model.annotation.fork.x && this._model.annotation.fork.y) {
      if (this._forkLine) {
        this.removeForkLine();
      }
      const source = this._marker.container.getAbsoluteCoords(
        this._model.source.position.x,
        this._model.source.position.y,
        this._marker._imageOffset,
        this._marker._imageScale
      );
      const target = this._marker.container.getAbsoluteCoords(
        this._model.annotation.fork.x,
        this._model.annotation.fork.y,
        this._marker._imageOffset,
        this._marker._imageScale
      );
      this._forkLine = new Line(this._format, source, target, this._marker);
      this._forkLine.element.marker = this;
      this._marker._element.addChild(this._forkLine.element);
    }
  }

  /**
   * Draw Labels
   */
  public drawLabels() {
    if (!this._model.label) {
      return;
    }

    const label = new Label({id: uuid(), text: this._model.label.text}, this._format, this._marker);
    this._marker._element.addChild(label.element);
    this._labels.push(label);
  }

  /**
   * Getter selftest
   */
  public get selftest() {
    return this._selftests ? this._selftests : null;
  }

  /**
   * Getter element
   */
  public get element() {
    return this._element;
  }

  /**
   * Set Scale
   */
  public setScale() {
    this.element.strokeWidth = this._format.strokeWidth;
  }

  /**
   * Remove Labels
   */
  public removeLabels() {
    for (let label of this._labels) {
      label.cleanUp();
      label = null;
    }
    this._labels = [];
  }

  /**
   * Remove Pointers
   */
  public removePointers() {
    for (let pointer of this._pointers) {
      pointer.cleanUp();
      pointer = null;
    }
    this._pointers = [];
  }

  /**
   * Remove Selection
   */
  public removeSelection() {
    if (this._selection) {
      this._selection.cleanUp();
      this._selection = null;
    }
  }

  /**
   * Remove Fork Line
   */
  public removeForkLine() {
    if (this._forkLine) {
      this._forkLine.cleanUp();
      this._forkLine = null;
    }
  }

  /**
   * Clean Up
   */
  public cleanUp() {
    this.removeSelection();
    this.removeLabels();
    this.removePointers();
    this.removeForkLine();

    if (this._element) {
      this._element.remove();
      this._element = null;
      this._segments = [];
    }
  }

  /**
   * Arrange
   */
  public arrange() {
    const zoom = this._localPaper.view.zoom;
    const coords = this._marker.container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._marker._imageOffset,
      this._marker._imageScale
    );
    let forkCoords = coords;
    this.scale(zoom);
    if (this._labels.length > 0) {
      this._labels[0].setPosition(coords.x, coords.y);
    }
    if (this._forkLine) {
      this._forkLine.setScale(zoom);
    }
    if (this._marker._isSelected) {
      if (this._selection) {
        if (this._model.annotation.fork) {
          forkCoords = this._marker.container.getAbsoluteCoords(
            this._model.annotation.fork.x,
            this._model.annotation.fork.y,
            this._marker._imageOffset,
            this._marker._imageScale
          );
        }
        this._selection.setScale(zoom);
        this._selection.rectangle.position = coords;
        if (this._selection.circle) {
          this._selection.circle.position = forkCoords;
        }
        this._selection.arrange();
        this._selection.element.bringToFront();
      }
    } else {
      this.removeSelection();
    }
  }

  /**
   * Scale
   *
   * @param zoom
   */
  public scale(zoom) {
    if (this._labels.length > 0) {
      this._labels[0].setScale(zoom);
    }
    for (const pointer of this._pointers) {
      pointer.setScale(zoom);
    }
  }
}
