import { Component } from '@angular/core';
import { MenuViewerFacade, SlideViewerFacade } from '@medsurf/flat-facades';
import { MediaControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent {
  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   * @param mediaControlService: MediaControlService
   */
  constructor(public slideViewerFacade: SlideViewerFacade,
              public menuViewerFacade: MenuViewerFacade,
              public mediaControlService: MediaControlService) {
  }
}

