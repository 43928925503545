import { NodeEntityModels } from '@medsurf/flat-models';
import { Params } from '@angular/router';

// <editor-fold desc="Action Types">

/**
 * Action types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GoToSlideByNode = '[STORE | VIEWER | SLIDE] Go To Slide By Node',
  GoToSlideByIndex = '[STORE | VIEWER | SLIDE] Go To Slide By Index',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Go To Slide By Node
 */
export class GoToSlideByNode {
  public static readonly type = ActionTypes.GoToSlideByNode;

  public constructor(public slideNode: NodeEntityModels.SlideNode,
                     public queryParams: Params) {
  }
}

/**
 * Go To Slide By Index
 */
export class GoToSlideByIndex {
  public static readonly type = ActionTypes.GoToSlideByIndex;

  public constructor(public index: number) {
  }
}

// </editor-fold>
