<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Block -->
<ng-template #block_template let-block="block" let-page="page" let-tree="tree" let-slugs="slugs">
  <ng-container *ngIf="block && page && tree && slugs">
    <a [routerLink]="['/' + tree.path]"
       *ngIf="!block.hidden && block.id"
       (click)="blockSelected.emit(block)"
       class="navigation-item"
       [ngClass]="slugs.block === block.route ? 'navigation-item-selected' : ''">
      {{ page.title }}
    </a>
  </ng-container>
</ng-template>

<!-- Chapter -->
<ng-template #chapter_template let-chapter="chapter" let-page="page" let-tree="tree" let-slugs="slugs">
  <ng-container *ngIf="chapter && page && tree && slugs">
    <div [routerLink]="['/' + tree.path]"
         (click)="chapterSelected.emit(chapter)"
         class="card-header"
         [class.panel-heading-active]="slugs.chapter === chapter.route"
         *ngIf="!chapter.hidden">
      <a [routerLink]="['/' + tree.path]">
        {{ page.title }}
      </a>
    </div>
    <div class="card-body"
         [class.panel-body-active]="slugs.chapter === chapter.route"
         *ngIf="slugs.chapter === chapter.route && !chapter.hidden">
      <ng-container *ngIf="(nodeSharedFacade.nodeByIds$(chapter.children) | async) | sortBy_shared:'asc':'position' as blocks">
        <ng-container *ngIf="chapter.children.length === 0 || blocks.length > 0; else spinner"></ng-container>
        <ng-container *ngFor="let block of blocks">
          <ng-container *ngTemplateOutlet="block_template;context:{
                block: block,
                page: (appSharedFacade.page$(block.page) | async),
                tree: (appSharedFacade.tree$(block.id) | async),
                slugs: slugs}">
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- Content -->
<aside>
  <ng-container *ngIf="(appSharedFacade.themeColor$ | async) as themeColor">
    <div class="container-navigation" [ngClass]="themeColor + '-hover'">
      <div [class.card]="showCurrentSubject">
        <div class="card-header" *ngIf="showCurrentSubject && (nodeSharedFacade.currentSelectedSubjectNode$ | async) as subjectNode">
          <a [routerLink]="['/' + (navigationControlFacade.subjectUrl$ | async)]">
            <ng-container *ngIf="(appSharedFacade.page$(subjectNode.page) | async) as page">
              {{ page.title }}
            </ng-container>
          </a>
        </div>
        <div class="p-4" *ngIf="(nodeSharedFacade.currentSelectedSubjectNode$ | async)?.restricted && !!(appSharedFacade.user$ | async) === false">
          <p class="text-secondary">{{ 'restricted_content_message' | translate }}</p>
          <button
            class="mdsf-btn mdsf-btn-primary" 
            [routerLink]="['/login']" 
            [queryParams]="{r: (navigationControlFacade.currentUrl$ | async)}"
          >
            {{ 'sign_in' | translate }}
          </button>
        </div>
        <ng-container *ngIf="(nodeSharedFacade.currentSelectedChapterNodes$ | async) | sortBy_shared:'asc':'position' as chapters">
          <div class="card panel-default" *ngIf="chapters !== undefined; else spinner"></div>
          <div *ngFor="let chapter of chapters" class="card panel-default">
            <ng-container *ngTemplateOutlet="chapter_template;context:{
              chapter: chapter,
              page: (appSharedFacade.page$(chapter.page) | async),
              tree: (appSharedFacade.tree$(chapter.id) | async),
              slugs: (navigationControlFacade.slugs$ | async)}">
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</aside>
