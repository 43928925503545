import { Component } from '@angular/core';
import { TrainingViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-quiz-end',
  templateUrl: './quiz-end.component.html',
  styleUrls: ['./quiz-end.component.scss']
})
export class QuizEndComponent {
  /**
   * Constructor
   *
   * @param trainingViewerFacade: TrainingViewerFacade
   */
  constructor(public trainingViewerFacade: TrainingViewerFacade) {}

  /* TODO Quiz Result
  ngOnInit() {
    this.matomo.trackQuizEnd(this.quizService.getSlideIds(), this.correctPercentage);
  }
  */
}
