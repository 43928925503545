<!-- Content -->
<div class="mdsf-container"
     *ngIf="(pageSharedFacade.currentSelectedPagePage$ | async) as page">
  <div class="mdsf-page-container align-items-center scrollable m-0 p-2 w-100 flex-grow-1">
    <div class="page-content">
      <div class="scrollable editor-input ng-scope">
        <h1 *ngIf="setTitle(page.header)">{{ page.header }}</h1>
      </div>
      <div class="scrollable editor-input ng-scope"
           [innerHTML]="page.body"></div>
    </div>
  </div>
  <medsurf-footer class="d-none d-md-inline"
                  [links]="footerLinks"
                  align="center">
  </medsurf-footer>
</div>