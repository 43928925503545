export enum AlertType {
    ERROR = 'Error',
    WARNING = 'Warning',
    SUCCESS = 'Success',
    INFO = 'Information'
}

export interface AlertOptions {
    message?: string;
    params?: object;
    type?: AlertType;
    code?: number;
    dismissable?: boolean;
    duration?: number;
}

export interface Alert {
    id: string;
    timestamp: Date;
    message: string;
    params: object;
    type: AlertType;
    code: number;
    dismissable?: boolean;
    duration?: number;
    dismissed?: boolean;
}
