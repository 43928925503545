import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { MenuViewerModels } from '@medsurf/flat-models';
import { MenuViewerActions } from '@medsurf/flat-actions';
import * as FlatStates from '@medsurf/flat-states';

/**
 * Menu Viewer Facade
 */
@Injectable()
export class MenuViewerFacade {
  //<editor-fold desc="Selectors">
  public currentSlideTitle$ = this.store.select(FlatStates.MenuViewerState.currentSlideTitle$);

  public showFullscreen$ = this.store.select(FlatStates.MenuViewerState.showFullscreen$);
  public showPrint$ = this.store.select(FlatStates.MenuViewerState.showPrint$);
  public showPrintForAuthor$ = this.store.select(FlatStates.MenuViewerState.showPrintForAuthor$);
  public showPrintForUser$ = this.store.select(FlatStates.MenuViewerState.showPrintForUser$);

  public showVirtualPointer$ = this.store.select(FlatStates.MenuViewerState.showVirtualPointer$);
  public showMarkers$ = this.store.select(FlatStates.MenuViewerState.showMarkers$);
  public showSelftest$ = this.store.select(FlatStates.MenuViewerState.showSelftest$);
  public showCaseHistory$ = this.store.select(FlatStates.MenuViewerState.showCaseHistory$);

  //</editor-fold>

  //<editor-fold desc="Snapshot Selectors">

  public snapshot_showVirtualPointer = () => this.store.selectSnapshot(FlatStates.MenuViewerState.showVirtualPointer$);
  public snapshot_showNavigator = () => this.store.selectSnapshot(FlatStates.MenuViewerState.showNavigator$);
  public snapshot_showMarkers = () => this.store.selectSnapshot(FlatStates.MenuViewerState.showMarkers$);
  public snapshot_showSelftest = () => this.store.selectSnapshot(FlatStates.MenuViewerState.showSelftest$);

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(private store: Store) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Toggle FullScreen
   */
  public requestToggleFullscreen() {
    if (this.store.selectSnapshot(FlatStates.MenuViewerState.showFullscreen$)) {
      return this.store.dispatch(new MenuViewerActions.SetViewerDisplayMode(MenuViewerModels.ViewerDisplayMode.NORMAL));
    }
    return this.store.dispatch(new MenuViewerActions.SetViewerDisplayMode(MenuViewerModels.ViewerDisplayMode.FULLSCREEN));
  }

  /**
   * Request Set PrintView
   */
  public requestSetPrintView(mode: MenuViewerModels.ViewerPrintMode) {
    this.store.dispatch(new MenuViewerActions.SetViewerDisplayMode(MenuViewerModels.ViewerDisplayMode.PRINT))
    return this.store.dispatch(new MenuViewerActions.SetViewerPrintMode(mode));
  }
  
  /**
   * Request Toggle Virtual Pointer
   */
  public requestToggleVirtualPointer() {
    return this.store.dispatch(new MenuViewerActions.ToggleVirtualPointer());
  }

  /**
   * Request Toggle Navigator
   *
   * @param override: boolean
   */
  public requestToggleNavigator(override: boolean | null = null) {
    return this.store.dispatch(new MenuViewerActions.ToggleNavigator(override));
  }

  /**
   * Request Toggle Marker
   */
  public requestToggleMarker(override: boolean | null = null) {
    return this.store.dispatch(new MenuViewerActions.ToggleMarker(override));
  }

  /**
   * Request Toggle Selftest
   */
  public requestToggleSelftest() {
    return this.store.dispatch(new MenuViewerActions.ToggleSelftest());
  }

  /**
   * Request Toggle Case History
   */
  public requestToggleCaseHistory() {
    return this.store.dispatch(new MenuViewerActions.ToggleCaseHistory());
  }

  //</editor-fold>

}
