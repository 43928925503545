<div>
  <aside class="list-group">
    <div *ngFor="let group of groups"
         class="list-group-item list-group-item-action flex-column align-items-start ">
      <div class="d-flex w-100 justify-content-between cursor-pointer" (click)="toggleExpandedGroup(group)">
        <h5>{{ group.name }}</h5>
      </div>
      <div *ngIf="group && expandedGroup === group && group.description">
        <div class="notes mt-3">
          <p class="note-text text-transform-none mt-3" [innerHTML]="group.description | safeHtml"></p>
        </div>
      </div>
    </div>
  </aside>
</div>

