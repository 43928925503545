import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { NoteEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * Note Entity Token
 */
export const NOTE_ENTITY_TOKEN = new StateToken<NoteEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.NOTE);

/**
 * Note Entity State Model
 */
export type NoteEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.NoteEntityModels.Note>;

/**
 * Note Entity State
 */
@State<NoteEntityStateModel>({
  name: NOTE_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class NoteEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: NoteEntityStateModel
   */
  @Selector([NoteEntityState])
  public static typedEntities$(state: NoteEntityStateModel): { [id: string]: FlatModels.NoteEntityModels.Note } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.NoteEntityModels.Note }
   */
  @Selector([NoteEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.NoteEntityModels.Note }): FlatModels.NoteEntityModels.Note[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.NoteEntityModels.Note }
   */
  @Selector([NoteEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.NoteEntityModels.Note }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.NoteEntityModels.Note[]
   */
  @Selector([NoteEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.NoteEntityModels.Note[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<NoteEntityStateModel>
   * @param patchState: StateContext<NoteEntityStateModel>
   * @param entityQueryResult: NoteEntityActions.GetEntitySuccess
   */
  @Action(NoteEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<NoteEntityStateModel>,
                          {entityQueryResult}: NoteEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<NoteEntityStateModel>
   * @param patchState: StateContext<NoteEntityStateModel>
   * @param entityQueryResults: NoteEntityActions.GetEntitiesSuccess
   */
  @Action(NoteEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<NoteEntityStateModel>,
                            {entityQueryResults}: NoteEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<NoteEntityStateModel>
   * @param patchState: StateContext<NoteEntityStateModel>
   * @param entity: NoteEntityActions.AddEntity
   */
  @Action(NoteEntityActions.AddEntity)
  @Undoable(NoteEntityActions.AddEntity, NOTE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<NoteEntityStateModel>,
                   {entity}: NoteEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<NoteEntityStateModel>
   * @param patchState: StateContext<NoteEntityStateModel>
   * @param entity: NoteEntityActions.UpdateEntity
   */
  @Action(NoteEntityActions.UpdateEntity)
  @Undoable(NoteEntityActions.UpdateEntity, NOTE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<NoteEntityStateModel>,
                      {entity}: NoteEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<NoteEntityStateModel>
   * @param patchState: StateContext<NoteEntityStateModel>
   * @param entity: NoteEntityActions.RemoveEntity
   */
  @Action(NoteEntityActions.RemoveEntity)
  @Undoable(NoteEntityActions.RemoveEntity, NOTE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<NoteEntityStateModel>,
                      {entity}: NoteEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
