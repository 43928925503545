export interface User {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  lastLogin?: Date;
  password?: string;
  password_confirm?: string;
  resetPasswordToken?: string;
  resetPasswordExpires?: Date;
  role?: Role;
}

export enum Role {
  ADMIN = 'admin',
  EDITOR = 'editor',
  AUTHOR = 'author',
  VIEWER = 'viewer'
}
