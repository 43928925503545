import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { VocabularyWordEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * Vocabulary Word Entity Token
 */
export const VOCABULARY_WORD_ENTITY_TOKEN = new StateToken<VocabularyWordEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.VOCABULARY_WORD);

/**
 * Vocabulary Word Entity State Model
 */
export type VocabularyWordEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.VocabularyWordEntityModels.VocabularyWord>;

/**
 * Vocabulary Word Entity State
 */
@State<VocabularyWordEntityStateModel>({
  name: VOCABULARY_WORD_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class VocabularyWordEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: VocabularyWordEntityStateModel
   */
  @Selector([VocabularyWordEntityState])
  public static typedEntities$(state: VocabularyWordEntityStateModel): { [id: string]: FlatModels.VocabularyWordEntityModels.VocabularyWord } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.VocabularyWordEntityModels.VocabularyWord }
   */
  @Selector([VocabularyWordEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.VocabularyWordEntityModels.VocabularyWord }): FlatModels.VocabularyWordEntityModels.VocabularyWord[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.VocabularyWordEntityModels.VocabularyWord }
   */
  @Selector([VocabularyWordEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.VocabularyWordEntityModels.VocabularyWord }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.VocabularyWordEntityModels.VocabularyWord[]
   */
  @Selector([VocabularyWordEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.VocabularyWordEntityModels.VocabularyWord[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<VocabularyWordEntityStateModel>
   * @param patchState: StateContext<VocabularyWordEntityStateModel>
   * @param entityQueryResult: VocabularyWordEntityActions.GetEntitySuccess
   */
  @Action(VocabularyWordEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<VocabularyWordEntityStateModel>,
                          {entityQueryResult}: VocabularyWordEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<VocabularyWordEntityStateModel>
   * @param patchState: StateContext<VocabularyWordEntityStateModel>
   * @param entityQueryResults: VocabularyWordEntityActions.GetEntitiesSuccess
   */
  @Action(VocabularyWordEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<VocabularyWordEntityStateModel>,
                            {entityQueryResults}: VocabularyWordEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<VocabularyWordEntityStateModel>
   * @param patchState: StateContext<VocabularyWordEntityStateModel>
   * @param entity: VocabularyWordEntityActions.AddEntity
   */
  @Action(VocabularyWordEntityActions.AddEntity)
  @Undoable(VocabularyWordEntityActions.AddEntity, VOCABULARY_WORD_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<VocabularyWordEntityStateModel>,
                   {entity}: VocabularyWordEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<VocabularyWordEntityStateModel>
   * @param patchState: StateContext<VocabularyWordEntityStateModel>
   * @param entity: VocabularyWordEntityActions.UpdateEntity
   */
  @Action(VocabularyWordEntityActions.UpdateEntity)
  @Undoable(VocabularyWordEntityActions.UpdateEntity, VOCABULARY_WORD_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<VocabularyWordEntityStateModel>,
                      {entity}: VocabularyWordEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<VocabularyWordEntityStateModel>
   * @param patchState: StateContext<VocabularyWordEntityStateModel>
   * @param entity: VocabularyWordEntityActions.RemoveEntity
   */
  @Action(VocabularyWordEntityActions.RemoveEntity)
  @Undoable(VocabularyWordEntityActions.RemoveEntity, VOCABULARY_WORD_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<VocabularyWordEntityStateModel>,
                      {entity}: VocabularyWordEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
