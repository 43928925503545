import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as FlatModels from '@medsurf/flat-models';
import { AnnotationEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import * as FlatStates from '../../internal';

/**
 * Annotation Entity Token
 */
export const ANNOTATION_ENTITY_TOKEN = new StateToken<AnnotationEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.ANNOTATION);

/**
 * Annotation Entity State Model
 */
export interface AnnotationEntityStateModel extends FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.AnnotationEntityModels.AnnotationEntityType>{
  annotationId: string | null;
}

/**
 * Annotation Entity State
 */
@State<AnnotationEntityStateModel>({
  name: ANNOTATION_ENTITY_TOKEN,
  defaults: {
    annotationId: null,
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class AnnotationEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector annotationId$
   *
   * @param state: AnnotationEntityStateModel
   */
  @Selector([AnnotationEntityState])
  public static annotationId$(state: AnnotationEntityStateModel): string | null {
    return state.annotationId;
  }

  /**
   * Selector typedEntities$
   *
   * @param state: AnnotationEntityStateModel
   */
  @Selector([AnnotationEntityState])
  public static typedEntities$(state: AnnotationEntityStateModel): { [id: string]: FlatModels.AnnotationEntityModels.AnnotationEntityType } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.AnnotationEntityModels.AnnotationEntityType }
   */
  @Selector([AnnotationEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.AnnotationEntityModels.AnnotationEntityType }): FlatModels.AnnotationEntityModels.AnnotationEntityType[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.AnnotationEntityModels.AnnotationEntityType }
   */
  @Selector([AnnotationEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.AnnotationEntityModels.AnnotationEntityType }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.AnnotationEntityModels.AnnotationEntityType[]
   */
  @Selector([AnnotationEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.AnnotationEntityModels.AnnotationEntityType[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  /**
   * Selector currentSelectedSlideAnnotations$
   *
   * @param entities: FlatModels.AnnotationEntityModels.Annotation[]
   * @param currentSelectedSlideImage: FlatModels.ImageEntityModels.Image | null
   */
  @Selector([
    AnnotationEntityState.entities$,
    FlatStates.ImageEntityState.currentSelectedSlideImage$
  ])
  public static currentSelectedSlideAnnotations$(entities: FlatModels.AnnotationEntityModels.AnnotationEntityType[],
                                                 currentSelectedSlideImage: FlatModels.ImageEntityModels.Image | null) {
    return currentSelectedSlideImage && currentSelectedSlideImage.annotations && Array.isArray(entities) && entities.filter(e => (currentSelectedSlideImage.annotations as unknown as string[]).includes(e.id)) || [];
  }

  /**
   * Selector currentSelectedSlideAnnotation$
   *
   * @param currentSelectedSlideAnnotations: FlatModels.AnnotationEntityModels.Annotation[]
   * @param annotationId: string | null
   */
  @Selector([
    AnnotationEntityState.currentSelectedSlideAnnotations$,
    AnnotationEntityState.annotationId$
  ])
  public static currentSelectedSlideAnnotation$(currentSelectedSlideAnnotations: FlatModels.AnnotationEntityModels.Annotation[],
                                                annotationId: string | null) {
    return annotationId !== null && Array.isArray(currentSelectedSlideAnnotations) ? currentSelectedSlideAnnotations.find(sa => sa.id === annotationId) || null : null;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<AnnotationEntityStateModel>
   * @param patchState: StateContext<AnnotationEntityStateModel>
   * @param entityQueryResult: AnnotationEntityActions.GetEntitySuccess
   */
  @Action(AnnotationEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<AnnotationEntityStateModel>,
                          {entityQueryResult}: AnnotationEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<AnnotationEntityStateModel>
   * @param patchState: StateContext<AnnotationEntityStateModel>
   * @param entityQueryResults: AnnotationEntityActions.GetEntitiesSuccess
   */
  @Action(AnnotationEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<AnnotationEntityStateModel>,
                            {entityQueryResults}: AnnotationEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<AnnotationEntityStateModel>
   * @param patchState: StateContext<AnnotationEntityStateModel>
   * @param entity: AnnotationEntityActions.AddEntity
   */
  @Action(AnnotationEntityActions.AddEntity)
  @Undoable(AnnotationEntityActions.AddEntity, ANNOTATION_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<AnnotationEntityStateModel>,
                   {entity}: AnnotationEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<AnnotationEntityStateModel>
   * @param patchState: StateContext<AnnotationEntityStateModel>
   * @param entity: AnnotationEntityActions.UpdateEntity
   */
  @Action(AnnotationEntityActions.UpdateEntity)
  @Undoable(AnnotationEntityActions.UpdateEntity, ANNOTATION_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<AnnotationEntityStateModel>,
                      {entity}: AnnotationEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<AnnotationEntityStateModel>
   * @param patchState: StateContext<AnnotationEntityStateModel>
   * @param entity: AnnotationEntityActions.RemoveEntity
   */
  @Action(AnnotationEntityActions.RemoveEntity)
  @Undoable(AnnotationEntityActions.RemoveEntity, ANNOTATION_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<AnnotationEntityStateModel>,
                      {entity}: AnnotationEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  /**
   * Set AnnotationId
   *
   * @param setState: StateContext<AnnotationEntityStateModel>
   * @param annotationId: AnnotationEntityActions.SetAnnotationId
   */
  @Action(AnnotationEntityActions.SetAnnotationId)
  public setAnnotationId({setState}: StateContext<AnnotationEntityStateModel>,
                  {annotationId}: AnnotationEntityActions.SetAnnotationId) {
    setState(
      patch({
        annotationId: existing => (existing === annotationId) ? null : annotationId
      })
    );
  }

  //</editor-fold>
}
