import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Alert } from '@medsurf/models';
import { AddAlert, DismissAlert, ClearAlerts } from '@medsurf/actions';
import { AlertService } from '@medsurf/services';

/**
 * Index state model
 */
export interface AlertStateModel {
  alerts: Alert[];
}

/**
 * Index State
 */
@State<AlertStateModel>({
  name: 'alert',
  defaults: {
    alerts: []
  }
})
@Injectable()
export class AlertState {

  public constructor(
    private store: Store,
    private alertService: AlertService
  ) {
  }

  @Selector()
  public static alerts(state: AlertStateModel): Alert[] {
    return state.alerts;
  }

  @Action(AddAlert)
  public addAlert({getState, setState}: StateContext<AlertStateModel>, {options}: AddAlert): void {
    const state = getState();
    const alerts = state.alerts.slice();
    const alert = this.alertService.createAlert(options);
    alerts.unshift(alert);

    if (alert.duration) {
      setTimeout(() => {
        this.store.dispatch(new DismissAlert(alert));
      }, alert.duration * 1000);
    }

    setState(
      patch({
        alerts
      })
    );
  }

  @Action(DismissAlert)
  public dismissAlert({getState, setState}: StateContext<AlertStateModel>, {alert}: DismissAlert): void {
    const state = getState();
    const alerts = state.alerts.slice();
    const selectedItem = alerts.find((item) => item.id === alert.id);
    selectedItem.dismissed = true;

    setState(
      patch({
        alerts
      })
    );
  }

  @Action(ClearAlerts)
  public clearAlerts({getState, setState}: StateContext<AlertStateModel>, {}: ClearAlerts): void {
    const state = getState();
    const alerts = state.alerts.map((item) => {
      item.dismissed = true;
      return item;
    });

    setState(
      patch({
        alerts
      })
    );
  }
}
