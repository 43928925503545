import { Metadata } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Metadata action types
 */
export enum MetadataActionTypes {
  /**
   * Store
   */
  GetMetadata = '[STORE | METADATA] Get Metadata',
  GetMetadataSuccess = '[STORE | METADATA] Get Metadata Success',
  SaveMetadata = '[STORE | METADATA] Save Metadata',
  SaveMetadataSuccess = '[STORE | METADATA] Save Metadata Success',
  /**
   * Request
   */
  GetMetadataRequest = '[REQUEST | METADATA] Get Metadata',
  SaveMetadataRequest = '[REQUEST | METADATA] Save Metadata',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Metadata
 */
export class GetMetadata {
  public static readonly type = MetadataActionTypes.GetMetadata;

  public constructor() {
  }
}

/**
 * Get Metadata Sucess
 */
export class GetMetadataSuccess {
  public static readonly type = MetadataActionTypes.GetMetadataSuccess;

  public constructor(public data: Metadata) {
  }
}

/**
 * Save Metadata
 */
export class SaveMetadata {
  public static readonly type = MetadataActionTypes.SaveMetadata;

  public constructor(public data: Metadata) {
  }
}

/**
 * Save Metadata Success
 */
export class SaveMetadataSuccess {
  public static readonly type = MetadataActionTypes.SaveMetadataSuccess;

  public constructor(public data: Metadata) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Metadata Request
 */
export class GetMetadataRequest {
  public static readonly type = MetadataActionTypes.GetMetadataRequest;

  public constructor() {
  }
}

/**
 * Save Metadata Request
 */
export class SaveMetadataRequest {
  public static readonly type = MetadataActionTypes.SaveMetadataRequest;

  public constructor(public data: Metadata) {
  }
}

// </editor-fold>
