import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { MediaEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import { ImageEntityState } from '../../internal';

/**
 * Media Entity Token
 */
export const MEDIA_ENTITY_TOKEN = new StateToken<MediaEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.MEDIA);

/**
 * Media Entity State Model
 */
export type MediaEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.MediaEntityModels.MediaEntityType>;

/**
 * Media Entity State
 */
@State<MediaEntityStateModel>({
  name: MEDIA_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class MediaEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: MediaEntityStateModel
   */
  @Selector([MediaEntityState])
  public static typedEntities$(state: MediaEntityStateModel): { [id: string]: FlatModels.MediaEntityModels.MediaEntityType } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.MediaEntityModels.MediaEntityType }
   */
  @Selector([MediaEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.MediaEntityModels.MediaEntityType }): FlatModels.MediaEntityModels.MediaEntityType[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.MediaEntityModels.MediaEntityType }
   */
  @Selector([MediaEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.MediaEntityModels.MediaEntityType }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.MediaEntityModels.MediaEntityType[]
   */
  @Selector([MediaEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.MediaEntityModels.MediaEntityType[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  /**
   * Selector allCurrentSelectedSlideMedias$
   *
   * @param entities: FlatModels.MediaEntityModels.Modality[]
   * @param allCurrentSelectedSlideImages: FlatModels.ImageEntityModels.Image[]
   */
  @Selector([
    MediaEntityState.entities$,
    ImageEntityState.allCurrentSelectedSlideImages$
  ])
  public static allCurrentSelectedSlideMedias$(entities: FlatModels.MediaEntityModels.MediaEntityType[],
                                               allCurrentSelectedSlideImages: FlatModels.ImageEntityModels.Image[]) {
    if (Array.isArray(allCurrentSelectedSlideImages)) {
      const allCurrentSelectedMedias: string[] = allCurrentSelectedSlideImages.map(si => si.media as unknown as string);
      return Array.isArray(allCurrentSelectedMedias) ? entities.filter(e => allCurrentSelectedMedias.includes(e.id)) : [];
    }
    return [];
  }

  /**
   * Selector currentSelectedSlideValidMedias$
   *
   * @param entities: FlatModels.MediaEntityModels.MediaEntityType[]
   * @param currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[]
   */
  @Selector([
    MediaEntityState.entities$,
    ImageEntityState.currentSelectedSlideValidImages$
  ])
  public static currentSelectedSlideValidMedias$(entities: FlatModels.MediaEntityModels.MediaEntityType[],
                                                 currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[]) {
    if (Array.isArray(currentSelectedSlideValidImages) && Array.isArray(entities)) {
      const medias: any[] = [];
      currentSelectedSlideValidImages.forEach((image) => {
        const media = entities.find(e => e.id === image.media as unknown as string);
        medias.push(Object.assign({sequenceNumber: image.sequenceNumber}, media));
      });
      return medias.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    }
    return [];
  }

  /**
   * Selector currentSelectedSlideMedia$
   *
   * @param entities: FlatModels.ModalityEntityModels.Modality[]
   * @param currentSelectedSlideImage: FlatModels.ImageEntityModels.Image | null
   */
  @Selector([
    MediaEntityState.entities$,
    ImageEntityState.currentSelectedSlideImage$
  ])
  public static currentSelectedSlideMedia$(entities: FlatModels.MediaEntityModels.MediaEntityType[],
                                           currentSelectedSlideImage: FlatModels.ImageEntityModels.Image | null) {
    return currentSelectedSlideImage && currentSelectedSlideImage.media && Array.isArray(entities) && entities.find(e => e.id === currentSelectedSlideImage.media as unknown as string) || null;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<MediaEntityStateModel>
   * @param patchState: StateContext<MediaEntityStateModel>
   * @param entityQueryResult: MediaEntityActions.GetEntitySuccess
   */
  @Action(MediaEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<MediaEntityStateModel>,
                          {entityQueryResult}: MediaEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<MediaEntityStateModel>
   * @param patchState: StateContext<MediaEntityStateModel>
   * @param entityQueryResults: MediaEntityActions.GetEntitiesSuccess
   */
  @Action(MediaEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<MediaEntityStateModel>,
                            {entityQueryResults}: MediaEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<MediaEntityStateModel>
   * @param patchState: StateContext<MediaEntityStateModel>
   * @param entity: MediaEntityActions.AddEntity
   */
  @Action(MediaEntityActions.AddEntity)
  @Undoable(MediaEntityActions.AddEntity, MEDIA_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<MediaEntityStateModel>,
                   {entity}: MediaEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<MediaEntityStateModel>
   * @param patchState: StateContext<MediaEntityStateModel>
   * @param entity: MediaEntityActions.UpdateEntity
   */
  @Action(MediaEntityActions.UpdateEntity)
  @Undoable(MediaEntityActions.UpdateEntity, MEDIA_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<MediaEntityStateModel>,
                      {entity}: MediaEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<MediaEntityStateModel>
   * @param patchState: StateContext<MediaEntityStateModel>
   * @param entity: MediaEntityActions.RemoveEntity
   */
  @Action(MediaEntityActions.RemoveEntity)
  @Undoable(MediaEntityActions.RemoveEntity, MEDIA_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<MediaEntityStateModel>,
                      {entity}: MediaEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
