import { Base } from '../base/base.entity.model';
import * as FlatModels from '../../internal';

/**
 * Media Entity Type
 */
export type MediaEntityType = MediaDocument | MediaAudio | MediaVideo | MediaImage | MediaDeepzoom;

/**
 * Media Entity Query Result
 */
export type MediaEntityQueryResult = FlatModels.EntityControlModels.EntityQueryResult<MediaEntityType>;

/**
 * Media Type
 */
export enum MediaType {
  DOCUMENT = 'MediaDocument',
  IMAGE = 'MediaImage',
  DEEPZOOM = 'MediaDeepzoom',
  VIDEO = 'MediaVideo',
  AUDIO = 'MediaAudio'
}

/**
 * Media Access
 */
export enum MediaAccess {
  PUBLIC = 'public',
  PRIVATE = 'private'
}

/**
 * Dimensions
 */
export interface Dimensions {
  width?: number;
  height?: number;
  numTiles?: number;
  tileSize?: number;
  pixelPerMillimeter?: number;
  depth?: string;
  maxZoom?: number;
}

/**
 * Copyright Position
 */
export enum CopyrightPosition {
  TOPLEFT = 'top_left',
  TOPRIGHT = 'top_right',
  BOTRIGHT = 'bot_right',
  BOTLEFT = 'bot_left'
}

/**
 * Media
 */
export interface Media extends Base {
  name?: string;
  originalFile?: string;
  file?: string;
  thumbnail?: string;
  type?: MediaType;
  altText?: string;
  mime?: string;
  size?: number;
  hidden?: boolean;
  access?: MediaAccess;
  checksum?: string;
  uploadedBy?: FlatModels.UserEntityModels.User;
  uploadedAt?: Date;
  createdAt?: Date;
  editedAt?: Date;
  copyrights?: FlatModels.CopyrightEntityModels.Copyright[];
  stain?: FlatModels.StainEntityModels.Stain;
  compoundNumber?: string;
  mediaInFolders?: FlatModels.PageEntityModels.Folder[];
  mediaInImages?: FlatModels.ImageEntityModels.Image[];
  mediaInMetadata?: FlatModels.MetadataEntityModels.Metadata;
  mediaInSlideThumbs?: FlatModels.PageEntityModels.Slide[];
  mediaInGridPdf?: FlatModels.PageEntityModels.Grid;
  mediaInSlidePdf?: FlatModels.PageEntityModels.Slide;
  authorNote?: string;
}

/**
 * Media Document
 */
export type MediaDocument = Media

/**
 * Media Audio
 */
export interface MediaAudio extends Media {
  duration?: number;
}

/**
 * Media Video
 */
export interface MediaVideo extends Media {
  duration?: number;
  dimensions?: Dimensions;
  poster?: string;
}

/**
 * Media Image
 */
export interface MediaImage extends Media {
  dimensions?: Dimensions;
}

/**
 * Media Deepzoom
 */
export interface MediaDeepzoom extends Media {
  dimensions?: Dimensions;
  tileFolder?: string;
}
