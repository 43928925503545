import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import * as FlatModels from '@medsurf/flat-models';
import * as FlatActions from '@medsurf/flat-actions';
import * as FlatStates from '../../internal';

/**
 * Slide Viewer Token
 */
export const SLIDE_VIEWER_TOKEN = new StateToken<SlideViewerStateModel>('slideViewer');

/**
 * Slide Viewer State Model
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SlideViewerStateModel {
}

/**
 * Slide Viewer State
 */
@State<SlideViewerStateModel>({
  name: SLIDE_VIEWER_TOKEN,
  defaults: {
  }
})
@Injectable()
export class SlideViewerState {
    //<editor-fold desc="Selectors">

  /**
   * Selector slideUrlByNode$
   *
   * @param treeElements: FlatModels.TreeControlModels.TreeElement[]
   */
  @Selector([FlatStates.TreeControlState.entities$])
  public static slideUrlByNode$(treeElements: FlatModels.TreeControlModels.TreeElement[]) {
    return (slideNode: FlatModels.NodeEntityModels.SlideNode) => {
      const treeElement = treeElements.find(te => te.id === slideNode.parentId);
      if (treeElement) {
        return ['/' + treeElement.path + '/' + slideNode.route];
      } else {
        throw Error("current_parent_tree_not_defined");
      }
    }
  }

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  public constructor(public store: Store) {
  }

  //<editor-fold desc="Actions">

  /**
   * Go To Slide By Node
   *
   * @param dispatch: StateContext<TrainingViewerStateModel>
   * @param slideNode: FlatActions.SlideViewerActions.GoToSlideByNode
   */
  @Action(FlatActions.SlideViewerActions.GoToSlideByNode)
  public goToSlideByNode({dispatch}: StateContext<SlideViewerStateModel>,
                         {slideNode}: FlatActions.SlideViewerActions.GoToSlideByNode) {
    if (slideNode) {
      const treeElements = this.store.selectSnapshot(FlatStates.TreeControlState.entities$);
      const treeElement = treeElements.find(te => te.id === slideNode.parentId);
      if (treeElement) {
        // Navigate to first slide
        dispatch(new Navigate([treeElement.path + '/' + slideNode.route]));
      } else {
        throw Error("current_parent_tree_not_defined");
      }
    } else {
      throw Error("current_slide_node_not_defined");
    }
  }

  /**
   * Go To Slide By Index
   *
   * @param dispatch: StateContext<TrainingViewerStateModel>
   * @param index: TrainingViewerActions.GoToSlideByIndex
   */
  @Action(FlatActions.SlideViewerActions.GoToSlideByIndex)
  public goToSlideByIndex({dispatch}: StateContext<SlideViewerStateModel>,
                          {index}: FlatActions.SlideViewerActions.GoToSlideByIndex) {
    const selectedSlideNodes = this.store.selectSnapshot(FlatStates.NodeEntityState.currentSelectedSlideNodes$);
    if (selectedSlideNodes.length > 0) {
      const selectedSlideNode = selectedSlideNodes.find(sn => sn.position === index);
      if (selectedSlideNode) {
        const treeElements = this.store.selectSnapshot(FlatStates.TreeControlState.entities$);
        const treeElement = treeElements.find(te => te.id === selectedSlideNode.parentId);
        if (treeElement) {
          // Navigate to first slide
          dispatch(new Navigate([treeElement.path + '/' + selectedSlideNode.route]));
        } else {
          throw Error("current_parent_tree_not_defined");
        }
      } else {
        throw Error("current_slide_node_not_defined");
      }
    } else {
      throw Error("no_slides");
    }
  }

  //</editor-fold>
}
