import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { MediaControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent {
  /**
   * Members
   */
  public errorState = false;
  public progress = 0;
  public isSeeking = false;
  public isPlaying = false;

  /**
   * Inputs
   */
  @Input() public media: FlatModels.MediaEntityModels.MediaEntityType;

  /**
   * View Children
   */
  @ViewChild('progressWrap', {static: false}) public progressElementRef: ElementRef;
  @ViewChild('audioElement', {static: false}) public audioElementRef: ElementRef;

  /**
   * Constructor
   *
   * @param mediaControlService: MediaControlService
   */
  constructor(public mediaControlService: MediaControlService) {
  }

  /**
   * Local Toggle
   */
  public localToggle() {
    if (!this.audioElementRef.nativeElement && !this.audioElementRef.nativeElement.paused) {
      return;
    }
    if (this.audioElementRef.nativeElement.paused === true) {
      this.audioElementRef.nativeElement.play();
      this.isPlaying = true;
    } else {
      this.audioElementRef.nativeElement.pause();
      this.isPlaying = false;
    }
  }

  /**
   * Local Seek Bar Mousedown
   *
   * @param event: MouseEvent
   */
  public localSeekBarMousedown(event: MouseEvent) {
    this.isSeeking = true;
    this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
  }

  /**
   * Seek
   *
   * @param value: number
   * @private
   */
  private seek(value): void {
    this.audioElementRef.nativeElement.currentTime = this.audioElementRef.nativeElement.duration / value;
  }

  /**
   * Update Process
   */
  public updateProgress(): void {
    this.progress = this.audioElementRef.nativeElement ?
      (1 / this.audioElementRef.nativeElement.duration) * this.audioElementRef.nativeElement.currentTime : 0;
  }

  /**
   * Handle Mouse Move
   *
   * @param event: MouseEvent
   */
  @HostListener('window:mousemove', ['$event'])
  public handleMouseMove(event: MouseEvent) {
    if (this.isSeeking) {
      this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
    }
  }

  /**
   * Handle Mouse Up
   *
   * @param event: MouseEvent
   */
  @HostListener('window:mouseup', ['$event'])
  public handleMouseUp(event: MouseEvent) {
    if (this.isSeeking) {
      this.isSeeking = false;
      this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
    }
  }

  /**
   * On Right Click
   *
   * @param event: Event
   */
  @HostListener('contextmenu', ['$event'])
  public onRightClick(event: Event) {
    event.preventDefault();
  }

  /**
   * Handle Space Key
   */
  @HostListener('window:keydown.space')
  public handleSpaceKey() {
    this.localToggle();
  }
}
