import { Component, ElementRef, HostListener, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { SlideViewerFacade } from '@medsurf/flat-facades';
import * as FlatModels from '@medsurf/flat-models';
import { MediaControlService } from '@medsurf/flat-services';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import * as FlatActions from '@medsurf/flat-actions';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'medsurf-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnDestroy {
  /**
   * Inputs
   */
  @Input() public media: FlatModels.MediaEntityModels.MediaEntityType;

  /**
   * Members
   */
  public errorState = false;
  public progress = 0;
  public isSeeking = false;
  public isPlaying = false;
  public loop = false;
  
  public playbackSpeed = [0.25, 0.5, 1, 1.25, 1.5, 1.75, 2];
  public speed = 1;
  
  public modals = new Map<string, number>();
  public currentModalTitle = '';
  public currentModalContent = '';
  public modalRef: NgbModalRef;
  private _destroyed = new Subject<boolean>();

  /**
   * View Children
   */
  @ViewChild('progressWrap', {static: false}) public progressElementRef: ElementRef;
  @ViewChild('videoElement', {static: false}) public videoElementRef: ElementRef;
  @ViewChild('videoModal') videoModal: TemplateRef<any>;

  public adjustPlaybackSpeed(playbackSpeed: number){
    this.speed = playbackSpeed;
    this.videoElementRef.nativeElement.playbackRate = this.speed;
  }

  public toggleEndlessLoop() {
    this.loop = !this.loop;
    this.videoElementRef.nativeElement.loop = this.loop;
  }

  /**
   * Constructor
   *
   * @param mediaControlService: MediaControlService
   */
  constructor(
    public mediaControlService: MediaControlService, 
    public slideViewerFacade: SlideViewerFacade, 
    public actions$: Actions,
    public modalService: NgbModal) {

      this.actions$.pipe(
        ofActionSuccessful(FlatActions.MediaInteractionViewerActions.JumpToTime),
        takeUntil(this._destroyed)
      ).subscribe(({ time }: FlatActions.MediaInteractionViewerActions.JumpToTime) => {
        this.videoElementRef.nativeElement.currentTime = time;
        this.videoElementRef.nativeElement.play();
      })

      this.slideViewerFacade.currentActiveEvents$.pipe(takeUntil(this._destroyed)).subscribe((events) => {
        events?.forEach(event => {
          if (event.type === 'modal') {
            if (!this.modals.has(event.id)) {
              this.modals.set(event.id, event.from);
              this.videoElementRef.nativeElement.pause()
              this.modalRef = this.modalService.open(this.videoModal, {
                size: 'lg',
                scrollable: true,
                centered: true,
              })
              this.currentModalContent = event.content;
              this.currentModalTitle = event.title;

              this.modalRef.result.finally(() => {
                this.videoElementRef.nativeElement.play();
                this.currentModalContent = '';
                this.currentModalTitle = '';
              })
            }
          }
        })
      })
  }

  public closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
    }
  }

  /**
   * Local Toggle
   */
  public localToggle() {
    if (!this.videoElementRef.nativeElement && !this.videoElementRef.nativeElement.paused) {
      return;
    }
    if (this.videoElementRef.nativeElement.paused === true) {
      this.videoElementRef.nativeElement.play();
    } else {
      this.videoElementRef.nativeElement.pause();
    }
  }

  /**
   * Local Seek Bar Mousedown
   *
   * @param event: MouseEvent
   */
  public localSeekBarMousedown(event: MouseEvent) {
    this.isSeeking = true;
    this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
  }

  /**
   * Seek
   *
   * @param value: number
   * @private
   */
  private seek(value: number): void {
    this.videoElementRef.nativeElement.currentTime = this.videoElementRef.nativeElement.duration / value;
  }

  /**
   * Update Process
   */
  public updateProgress(): void {
    const currentTime = this.videoElementRef?.nativeElement?.currentTime || 0;
    const duration = this.videoElementRef?.nativeElement?.duration || 0;
    this.slideViewerFacade.requestSetCurrentTime(currentTime);
    this.progress = duration ? (1 / duration) * currentTime : 0;
    for (const [id, from] of this.modals) {
      if (currentTime < from) {
        this.modals.delete(id);
      }
    }
  }

  /**
   * Handle Mouse Move
   *
   * @param event: MouseEvent
   */
  @HostListener('window:mousemove', ['$event'])
  public handleMouseMove(event: MouseEvent) {
    if (this.isSeeking) {
      this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
    }
  }

  /**
   * Handle Mouse Up
   *
   * @param event: MouseEvent
   */
  @HostListener('window:mouseup', ['$event'])
  public handleMouseUp(event: MouseEvent) {
    if (this.isSeeking) {
      this.isSeeking = false;
      this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
    }
  }

  /**
   * On Right Click
   *
   * @param event: Event
   */
  @HostListener('contextmenu', ['$event'])
  public onRightClick(event: Event) {
    event.preventDefault();
  }

  /**
   * Handle Space Key
   */
  @HostListener('window:keydown.space')
  public handleSpaceKey() {
    this.localToggle();
  }

  public ngOnDestroy() {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
