import { Injectable } from '@angular/core';
import { getRouteString } from '@medsurf/helpers';
import { GetRootTrainingNode, GetRootTrainingNodeRequest, GetRootTrainingNodeSuccess, GetTrainings, GetTrainingsRequest,
  GetTrainingsSuccess, OverwriteCurrentPath, PathModel, SetSlide, SlugModel, SlugName } from '@medsurf/actions';
import { BlockNode, SlideNode, SubjectNode, Training } from '@medsurf/models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MessageService } from '@medsurf/services';
import { AuthState } from './auth.state';
import { NavigationState } from './navigation.state';

/**
 * Training state model
 */
export class TrainingStateModel {
  rootTrainingNode: SubjectNode;
  currentUrl: string;
  selectedTrainings: Training[];
}

/**
 * Index State
 */
@State<TrainingStateModel>({
  name: 'training',
  defaults: {
    rootTrainingNode: null,
    currentUrl: '',
    selectedTrainings: []
  }
})
@Injectable()
export class TrainingState {
  /**
   * Constructor
   * @param store: Store
   * @param messageService: MessageService
   */
  public constructor(private store: Store,
                     private messageService: MessageService) {
  }

  /**
   * Selector trainings
   * @param state: TrainingStateModel
   */
  @Selector()
  public static rootTrainingNode(state: TrainingStateModel): SubjectNode {
    return state.rootTrainingNode;
  }

  /**
   * Selector selectedTrainings
   * @param state: TrainingStateModel
   */
  @Selector()
  public static selectedTrainings(state: TrainingStateModel): Training[] {
    return state.selectedTrainings;
  }

  /**
   * Get root training node
   * @param setState: StateContext<TrainingStateModel>
   * @param id: string
   */
  @Action(GetRootTrainingNode)
  public getRootNodes({setState}: StateContext<TrainingStateModel>) {
    setState(
      patch({
        rootTrainingNode: null,
      })
    );
    const path = this.store.selectSnapshot(NavigationState.currentPath);
    if (!!path.subject) {
      const subjectId = path.subject.id;
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetRootTrainingNodeRequest(subjectId));
    }
  }

  /**
   * Get Trainings by Ids success
   * @param setState: StateContext<TrainingStateModel>
   * @param rootTrainingNode: SubjectNode
   */
  @Action(GetRootTrainingNodeSuccess)
  public getRootNodesSuccess({setState}: StateContext<TrainingStateModel>, {rootTrainingNode}: GetRootTrainingNodeSuccess): void {
    setState(
      patch({
        rootTrainingNode,
      })
    );
  }

  /**
   * Get Trainings
   * @param setState: StateContext<TrainingStateModel>
   * @param trainingIds: string[]
   */
  @Action(GetTrainings)
  public getTrainings({setState}: StateContext<TrainingStateModel>, {trainingIds}: GetTrainings) {
    setState(
      patch({
        selectedTrainings: [] as Training[],
      })
    );
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetTrainingsRequest(trainingIds));
  }

  /**
   * get Trainings success
   * @param getState: StateContext<TrainingStateModel>
   * @param setState: StateContext<TrainingStateModel>
   * @param trainings: Training[]
   */
  @Action(GetTrainingsSuccess)
  public getTrainingsSuccess({setState}: StateContext<TrainingStateModel>, {trainings}: GetTrainingsSuccess): void {
    setState(
      patch({
        selectedTrainings: trainings,
      })
    );

    const trainingNodes: SlideNode[] = trainings.map((training: Training, index: number): SlideNode => {
      return {
        page: training,
        position: index,
        route: `${getRouteString(training.title)}-${index}`
      }
    })

    const blockNode: BlockNode = {
      children: trainingNodes,
      route: 'run',
      hasChildren: true,
      position: 0,
      page: {title: 'Run'}
    };

    const path: PathModel = this.store.selectSnapshot(NavigationState.currentPath)
    if (path?.chapter) {
      const chapter = path.chapter
      chapter.children = [blockNode]
      this.store.dispatch(new OverwriteCurrentPath(SlugName.CHAPTER, chapter));
    }
    this.store.dispatch(new OverwriteCurrentPath(SlugName.BLOCK, blockNode));

    const slugs: SlugModel = this.store.selectSnapshot(NavigationState.slugs)
    if (!slugs?.slide) {
      return;
    }
    const currentTraining: SlideNode = trainingNodes.find((trainingNode: SlideNode): boolean => trainingNode.route === slugs.slide)

    if (!currentTraining) {
      return
    }
    this.store.dispatch(new OverwriteCurrentPath(SlugName.SLIDE, currentTraining));
    this.store.dispatch(new SetSlide(currentTraining.page));

  }
}
