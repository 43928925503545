import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AuthControlActions, NavigationControlActions } from '@medsurf/flat-actions';
import { AuthControlState } from '@medsurf/flat-states';

/**
 * Base Guard Chain Facade
 */
@Injectable()
export class BaseGuardChainFacade {
  //<editor-fold desc="Selectors">

  public user$ = this.store.select(AuthControlState.user$);

  //</editor-fold>

  //<editor-fold desc="Snapshot Selectors">

  public snapshot_expiresIn = () => this.store.selectSnapshot(AuthControlState.expiresIn$);
  public snapshot_user = () => this.store.selectSnapshot(AuthControlState.user$);

  //</editor-fold>

  //<editor-fold desc="Actions">

  public ofActionDispatchedMeSuccess = this.actions$.pipe(ofActionDispatched(AuthControlActions.MeSuccess));

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   * @param actions$: Actions
   */
  constructor(public store: Store,
              public actions$: Actions) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Logout
   */
  public requestLogout() {
    return this.store.dispatch(new AuthControlActions.Logout());
  }

  /**
   * Request Me
   */
  public requestMe() {
    return this.store.dispatch(new AuthControlActions.Me());
  }

  /**
   * Request Set Activated Route
   *
   * @param next: ActivatedRouteSnapshot
   */
  public requestSetActivatedRoute(next: ActivatedRouteSnapshot) {
    return this.store.dispatch(new NavigationControlActions.SetActivatedRoute(next));
  }

  //</editor-fold>
}
