<div class="mdsf-quiz-container scrollable">
  <div class="editor-input">
    <section *ngIf="{correctPercentage: trainingViewerFacade.selectedCorrectPercentageSlideResults$ | async, wrongPercentage: (trainingViewerFacade.selectedWrongPercentageSlideResults$ | async), emptyPercentage: (trainingViewerFacade.selectedEmptyPercentageSlideResults$ | async)} as trainingResultData">
      <h1 translate="your_quiz_result"></h1>
      <p translate="you_correctly_answered"
         [translateParams]="{answeredRightPercent: trainingResultData.correctPercentage}"></p>
      <div class="progress mb-2">
        <!-- Correct -->
        <div *ngIf="trainingResultData.correctPercentage"
             class="progress-bar bg-success"
             role="progressbar"
             [style.width]="trainingResultData.correctPercentage + '%'"
             [attr.aria-valuenow]="trainingResultData.correctPercentage"
             aria-valuemin="0"
             aria-valuemax="100">
          {{ trainingResultData.correctPercentage }}%
        </div>
        <!-- Wrong -->
        <div *ngIf="trainingResultData.wrongPercentage"
             class="progress-bar bg-danger"
             role="progressbar"
             [style.width]="trainingResultData.wrongPercentage + '%'"
             [attr.aria-valuenow]="trainingResultData.wrongPercentage"
             aria-valuemin="0"
             aria-valuemax="100">
          {{ trainingResultData.wrongPercentage }}%
        </div>
        <!-- Empty -->
        <div *ngIf="trainingResultData.emptyPercentage"
             class="progress-bar bg-empty"
             role="progressbar"
             [style.width]="trainingResultData.emptyPercentage + '%'"
             [attr.aria-valuenow]="trainingResultData.emptyPercentage"
             aria-valuemin="0"
             aria-valuemax="100">
          {{ trainingResultData.emptyPercentage }}%
        </div>
      </div>
      <p>
        <span class="mr-2"
              *ngIf="trainingResultData.correctPercentage">
          <span class="badge badge-success">&nbsp;</span>
          {{'correct'|translate}}
        </span>
        <span class="mr-2"
              *ngIf="trainingResultData.wrongPercentage">
          <span class="badge badge-danger">&nbsp;</span>
          {{'wrong'|translate}}
        </span>
        <span class="mr-2"
              *ngIf="trainingResultData.emptyPercentage">
          <span class="badge badge-empty">&nbsp;</span>
          {{'blank'|translate}}
        </span>
      </p>
      <div class="btn-group">
        <button class="mdsf-btn mdsf-btn-primary"
                (click)="trainingViewerFacade.requestGoToStart()"
                translate="finish_quiz">
        </button>
      </div>
    </section>
  </div>
</div>
