import {Injectable, InjectionToken} from '@angular/core';

export const SETTINGS = new InjectionToken<Settings>('settings');

export interface Settings {
  s3endpointUrl?: string;
  s3Bucket?: string;
  endpointUrl?: string;
  endpointUrlPrint?: string;
  websocketUrl?: string;
  viewerUrl?: string;
  version?: string;
  timestamp?: string;
  revision?: string;
  branch?: string;
}
