import { Component, Input } from '@angular/core';
import { FooterLinkViewerModels } from '@medsurf/flat-models';
import { FeedbackControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  /**
   * Inputs
   */
  @Input() align: string;
  @Input() links: FooterLinkViewerModels.FooterLink[];

  /**
   * Constructor
   *
   * @param feedbackControlService: FeedbackControlService
   */
  constructor(public feedbackControlService: FeedbackControlService) {
  }

  /**
   * Align Class
   */
  public get alignClass() {
    return 'container-' + this.align;
  }
}
