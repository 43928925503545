<!-- Modal Grid Entry -->
<ng-template #modalGridEntry let-page="page">
  <ng-container>
    <div class="resource-type-icon"
         [title]="mediaControlService.getMediaTypeLabel({ type: page.mediaType }) | translate">
      <span [class]="'fa ' + mediaControlService.getMediaTypeIcon({ type: page.mediaType })"></span>
    </div>
    <ng-container *ngIf="(searchControlFacade.media$(page.thumbnailMedia) | async) as thumbnailMedia; else no_thumbnail">
      <img [src]="thumbnailMedia ? mediaControlService.getUrl(thumbnailMedia, 'thumbnail') : '/assets/images/no-image.png'" />
    </ng-container>
    <ng-template #no_thumbnail>
      <img [src]="'/assets/images/no-image.png'" />
    </ng-template>
    <figcaption>
      <div class="title truncate truncate-multiline"
           [innerHTML]="page.title | highlight_viewer : (this.searchControlFacade.searchString$ | async)"
           [title]="page.title">
      </div>
      <div class="header">{{ page.header }}</div>
    </figcaption>
  </ng-container>
</ng-template>

<div class="mdsf-grid thumbnail-container scrollable" *ngIf="slides">
  <figure [routerLink]="['node', slide.id]"
          *ngFor="let slide of slides"
          (click)="modalControlService.closeModal()"
          (mouseenter)="active = slide.id"
          (mouseleave)="active = null"
          class="mdsf-thumbnail">
      <ng-container *ngTemplateOutlet="modalGridEntry;context:{
        page: (searchControlFacade.page$(slide.page) | async)}">
      </ng-container>
  </figure>
</div>
