import { ErrorControlModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  SubmitUserError = '[STORE | CONTROL | ERROR] Submit User Error',
  SubmitUserErrorSuccess = '[STORE | CONTROL | ERROR] Submit User Error Success',
  /**
   * Request
   */
  SubmitUserErrorRequest = '[REQUEST | CONTROL | ERROR] Submit User Error Request'
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Submit User Error
 */
export class SubmitUserError {
  public static readonly type = ActionTypes.SubmitUserError;

  public constructor(public error: ErrorControlModels.MedsurfError) {
  }
}

/**
 * Submit User Error Success
 */
export class SubmitUserErrorSuccess {
  public static readonly type = ActionTypes.SubmitUserErrorSuccess;

  public constructor(public message: string) {
  }
}


// </editor-fold>

// <editor-fold desc="Request">

/**
 * Submit User Error Request
 */
export class SubmitUserErrorRequest {
  public static readonly type = ActionTypes.SubmitUserErrorRequest;

  public constructor(public error: ErrorControlModels.MedsurfError) {
  }
}

// </editor-fold>
