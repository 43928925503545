import { MediaInteractionViewerModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action types
 */
export enum ActionTypes {
  SetEvents = '[STORE | VIEWER | MEDIA-INTERACTION] Set Events',
  SetCurrentTime = '[STORE | VIEWER | MEDIA-INTERACTION] Set Current Time',
  JumpToTime = '[STORE | VIEWER | MEDIA-INTERACTION] Jump To Time',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Set Events
 */
export class SetEvents {
  public static readonly type = ActionTypes.SetEvents;

  public constructor(public events: MediaInteractionViewerModels.MediaInteractionEvent[]) {
  }
}


/**
 * Set Current Time
 */
export class SetCurrentTime {
  public static readonly type = ActionTypes.SetCurrentTime;

  public constructor(public currentTime: number) {
  }
}

/**
 * Set Current Time
 */
export class JumpToTime {
  public static readonly type = ActionTypes.JumpToTime;

  public constructor(public time: number) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
