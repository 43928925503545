import { pick, cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MessageService, NodeService } from '@medsurf/services';
import { NavigationState } from './navigation.state';
import { Node } from '@medsurf/models';
import { PathModel, AddRootNode, GetChildNodes, GetChildNodesSuccess, GetRootNodes, GetRootNodesSuccess, IndexChanged,
  SetIndex, SaveIndex, SaveIndexSuccess, GetNodeForUrl, GetRootNodesRequest, GetChildNodesRequest, SaveIndexRequest,
  GetNodeRoute, GetNodeRouteRequest, SharedHistory, HandleIndexHistory, GetNodesBySlideId, GetNodesBySlideIdRequest, GetAutofillRequest, GetAutofill } from '@medsurf/actions';
import { AuthState } from './auth.state';

/**
 * Index state model
 */
export interface IndexStateModel {
  current: Node[];
  currentMap: Map<string, Node>;
  dirtyItems: Node[],
  lastUpdated: Date;
  pending: boolean;
}

/**
 * Index State
 */
@State<IndexStateModel>({
  name: 'index',
  defaults: {
    current: [],
    currentMap: new Map(),
    dirtyItems: [],
    lastUpdated: null,
    pending: false
  }
})
@Injectable()
export class IndexState {
  /**
   * Constructor
   * @param nodeService: NodeService
   * @param store: Store
   * @param messageService: MessageService
   */
  public constructor(
    protected nodeService: NodeService,
    protected store: Store,
    protected messageService: MessageService
  ) {}

  /**
   * Selector isDirty
   * @param state: IndexStateModel
   */
  @Selector()
  public static isDirty(state: IndexStateModel): boolean {
    return state.dirtyItems.length > 0;
  }

  /**
   * Selector index
   * @param state: IndexStateModel
   */
  @Selector()
  public static index(state: IndexStateModel): Node[] {
    return state.current;
  }

  /**
   * Selector index map
   * @param state: IndexStateModel
   */
  @Selector()
  public static indexMap(state: IndexStateModel): Map<string, Node> {
    return state.currentMap;
  }

  /**
   * Handle slide history changes
   * @param getState: StateContext<SlideStateModel>
   * @param getState: StateContext<SlideStateModel>
   * @param dispatch: StateContext<SlideStateModel>
   * @param stateFragment: GetSlideSuccess
   */
  @Action(HandleIndexHistory)
  public slideHandleHistory({getState, setState}: StateContext<any>, { stateFragment }) {
    setState(patch({
      ...stateFragment
    }));
  }

  /**
   * Get Root Nodes
   * @param getState: StateContext<IndexStateModel>
   * @param dispatch: StateContext<IndexStateModel>
   */
  @Action(GetRootNodes)
  public getRootNodes({setState, getState, dispatch}: StateContext<IndexStateModel>) {
    const state = getState();
    if (!state.pending) {
      if (state.current.length === 0) {
        setState(
          patch({
            dirtyItems: [] as Node[],
            pending: true as boolean
          })
        );
        return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetRootNodesRequest());
      } else {
        dispatch(new GetRootNodesSuccess(state.current, state.lastUpdated));
      }
    }
  }

  /**
   * Add page success
   * @param setState: StateContext<IndexStateModel>
   * @param dispatch: StateContext<IndexStateModel>
   * @param nodes: Node[]
   * @param lastUpdated: Date
   */
  @Action(GetRootNodesSuccess)
  public getRootNodesSuccess(
    {setState, getState, dispatch}: StateContext<IndexStateModel>, 
    {nodes, lastUpdated}: GetRootNodesSuccess
  ): void {
    const map = this.nodeService.prepareIndexMap(nodes);

    setState(
      patch({
        current: nodes,
        currentMap: map,
        lastUpdated,
        pending: false as boolean
      })
    );
    dispatch(new GetNodeForUrl());

    dispatch(new SharedHistory.Init({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleIndexHistory,
      id: 'index'
    }))
  }

  /**
   * Get Child Nodes
   * @param dispatch: StateContext<IndexStateModel>
   * @param parent: Node
   * @param loadTrainings: boolean
   * @param loadRestrictedAndHidden: boolean
   */
  @Action(GetChildNodes)
  public getChildNodes({dispatch}: StateContext<IndexStateModel>, {
    parent,
    loadTrainings,
    loadRestrictedAndHidden,
    queryParams
  }: GetChildNodes) {
    if (!parent.children) {
      return this.messageService.sendMessage(
        this.store.selectSnapshot(AuthState.token), new GetChildNodesRequest(parent, loadTrainings, loadRestrictedAndHidden, queryParams)
      );
    } else {
      dispatch(new GetChildNodesSuccess(parent, parent.children));
    }
    parent.expanded = true;
  }

  /**
   * Add child Nodes success
   * @param getState: StateContext<IndexStateModel>
   * @param dispatch: StateContext<IndexStateModel>
   * @param node: Node
   * @param children: Node[]
   */
  @Action(GetChildNodesSuccess)
  public getChildNodesSuccess({getState, setState, dispatch}: StateContext<IndexStateModel>, {node, children}: GetChildNodesSuccess): void {
    // Get state
    const state = getState();
    const parent = this.nodeService.findNode(node.id, state.current);
    parent.children = children;
    state.currentMap = this.nodeService.prepareIndexMap(state.current);
    setState(patch({
      current: state.current,
      currentMap: state.currentMap
    }))
    dispatch(new GetNodeForUrl());

    dispatch(new SharedHistory.Overwrite({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleIndexHistory,
      id: 'index'
    }))
  }

  /**
   * Index changed
   * @param getState: StateContext<IndexStateModel>
   * @param dispatch: StateContext<IndexStateModel>
   * @param dirtyNodes: IndexChanged
   */
  @Action(IndexChanged)
  public indexChanged({getState, setState, dispatch}: StateContext<IndexStateModel>, {dirtyNodes}: IndexChanged) {
    const state = getState();
    for (const dirtyNode of dirtyNodes) {
      const idx = state.dirtyItems.findIndex((node) => node.id === dirtyNode.id);
      if (idx !== -1) {
        state.dirtyItems[idx] = dirtyNode;
      } else {
        state.dirtyItems.push(dirtyNode);
      }
    }
    setState(patch({
      current: [...state.current],
      dirtyItems: [...state.dirtyItems]
    }))
    dispatch(new SharedHistory.Changed({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleIndexHistory,
      id: 'index'
    }))
  }

  /**
   * Add Root Node
   * @param getState: StateContext<IndexStateModel>
   * @param dispatch: StateContext<IndexStateModel>
   * @param rootNode: AddRootNode
   */
  @Action(AddRootNode)
  public addRootNode({getState, setState, dispatch}: StateContext<IndexStateModel>, {rootNode}: AddRootNode) {
    const state = getState();
    state.current.push(rootNode);
    state.currentMap = this.nodeService.prepareIndexMap(state.current);
    this.nodeService.childrenMoved({children: state.current});

    setState(patch({
      current: state.current,
      currentMap: state.currentMap
    }))

    dispatch(new SharedHistory.Changed({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleIndexHistory,
      id: 'index'
    }))
  }

  /**
   * Set Index
   * @param getState: StateContext<IndexStateModel>
   * @param setState: StateContext<IndexStateModel>
   * @param index: Node[]
   */
  @Action(SetIndex)
  public setIndex({getState, setState, dispatch}: StateContext<IndexStateModel>, {index}: SetIndex) {
    const state = getState();
    state.dirtyItems.push({id: 'root', children: index});
    state.currentMap = this.nodeService.prepareIndexMap(index);
    setState(patch({
      current: index,
      currentMap: state.currentMap,
      dirtyItems: state.dirtyItems
    }));

    dispatch(new SharedHistory.Init({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleIndexHistory,
      id: 'index'
    }))
  }

  /**
   * Save Index
   * @param getState: StateContext<IndexStateModel>
   * @param dispatch: StateContext<IndexStateModel>
   */
  @Action(SaveIndex)
  public saveIndex({getState, dispatch}: StateContext<IndexStateModel>) {
    // Get state
    const state = getState();
    if (state.dirtyItems.length > 0) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new SaveIndexRequest(state.dirtyItems));
    }
  }

  /**
   * Save Index Success
   * @param getState: StateContext<IndexStateModel>
   * @param setState: StateContext<IndexStateModel>
   * @param nodes: Node[]
   */
  @Action(SaveIndexSuccess)
  public saveIndexSuccess({getState, setState, dispatch}: StateContext<IndexStateModel>, {nodes}: SaveIndexSuccess): void {
    const state = getState();
    for (const node of nodes) {
      this.nodeService.replaceNode(node, state.current);
    }
    state.currentMap = this.nodeService.prepareIndexMap(state.current);
    const currentPath: PathModel = this.store.selectSnapshot(NavigationState.currentPath);
    for (const key in currentPath) {
      if (currentPath.hasOwnProperty(key)) {
        if (nodes.some(node => node.id === currentPath[key]?.id)) {
          currentPath[key] = nodes.find(node => node.id === currentPath[key].id);
        }
      }
    }
    setState(
      patch({
        current: state.current,
        currentMap: state.currentMap,
        dirtyItems: [] as Node[]
      }));
    
    dispatch(new SharedHistory.Init({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleIndexHistory,
      id: 'index'
    }))
  }

  /**
   * Get node route
   * @param nodeId: string
   */
  @Action(GetNodeRoute)
  public getNodeRoute({}: StateContext<IndexStateModel>, {nodeId}) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetNodeRouteRequest(nodeId));
  }

  /**
   * Get nodes by slide id
   * @param slideId: number
   */
  @Action(GetNodesBySlideId)
  public getNodesBySlideId({}: StateContext<IndexStateModel>, {slideId}) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetNodesBySlideIdRequest(slideId));
  }


    /**
   * Get Autofill
   * @param getState: StateContext<IndexStateModel>
   */
    @Action(GetAutofill)
    public getAutofill({getState}: StateContext<IndexStateModel>, {slideId}: GetAutofill) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetAutofillRequest(slideId));
    }

}
