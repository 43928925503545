'use strict';
import { v4 as uuid } from 'uuid';
import paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { MarkerElement } from '../markerElement';

/**
 * Keymap
 */
export class Keymap extends MarkerElement<ImageViewerModels.KeymapModel, undefined> {
  /**
   * Members
   */
  private _labels: any[];
  private _selection: any;
  private _cell: any;
  private _index: any;
  private _label: paper.PointText;
  private _rectangle: paper.Path.Rectangle;

  /**
   * Constructor
   *
   * @param model
   * @param _format
   * @param localPaper
   * @param _mainLayer
   * @param container
   * @param _imageScale
   * @param _imageOffset
   */
  public constructor(model: ImageViewerModels.KeymapModel,
                     private _format,
                     private localPaper,
                     private _mainLayer,
                     private container,
                     public _imageScale = 1,
                     public _imageOffset?) {
    super(model, undefined);
    this._labels = [];
    this._selection = null;

    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    if (!this._element) {
      this._element = new this.localPaper.Group();
      this._mainLayer.addChild(this._element);
    }
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    this._element._children = [];
    if (!this._model.columns) {
      return;
    }
    const rowHeight = [], columnWidth = [];
    const source = this.container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._imageOffset,
      this._imageScale
    );
    source.x *= this._imageScale;

    const startY = source.y;
    columnWidth[-1] = 0;
    rowHeight[-1] = 0;

    const digitModel = this.getDigitModel();
    const digitsCount = this.countIndexDigits();

    this._model.columns = this._model.columns.length > 0 ? this._model.columns : this.getDefaultLabel();

    for (const column of this._model.columns) {
      const columnId = this._model.columns.indexOf(column);
      columnWidth[columnId] = 0;
      source.y = startY;
      for (const label of column.labels) {
        const rowId = column.labels.indexOf(label),
        indexContent = ' '.repeat(digitsCount - label.index.toString().length) + label.index;
        rowHeight[rowId] = rowHeight[rowId] ? rowHeight[rowId] : 0;

        this._cell = new this.localPaper.Group();
        this._index = new this.localPaper.Group();

        for (let position = 0; position < digitsCount; position++) {
          const digit = this.drawDigit(source, position, digitModel, indexContent);
          this._index.addChild(digit);
        }

        this._label = new paper.PointText(new paper.Point(
          source.x + (this._model.keymapStyle.indexWidth / this._imageScale) + (digitsCount - 1) * digitModel.width,
          source.y)
        );

        this._label.fontFamily = this._format.fontFamily;
        this._label.fontWeight = this._format.fontWeight;
        this._label.fontSize = (this._model.keymapStyle.fontSize || this._format.fontSize) / this._imageScale;
        this._label.fillColor = this._model.keymapStyle.color || this._format.color;
        this._label.content = label.text;
        this._label['keymap'] = this;
        this._index['keymap'] = this;

        this._cell.addChild(this._index);
        this._cell.addChild(this._label);

        this._element.addChild(this._cell);

        columnWidth[columnId] = Math.max(this._cell.bounds.width, columnWidth[columnId]);
        rowHeight[rowId] = Math.max(this._cell.bounds.height, rowHeight[rowId]);
        source.y += rowHeight[rowId] + (this._model.keymapStyle.rowDistance / this._imageScale);
      }
      source.x += columnWidth[columnId] + (this._model.keymapStyle.columnDistance / this._imageScale);
    }
    this.drawRectangle(columnWidth, rowHeight);
    this._element.reverseChildren();
  }

  /**
   * Get Digit model
   */
  public getDigitModel() {
    const index = new paper.PointText(new paper.Point(0, 0)
    );
    index.fontFamily = this._format.fontFamily;
    index.fontWeight = this._format.fontWeight;
    index.fontSize = (this._model.keymapStyle.fontSize || this._format.fontSize) / this._imageScale;
    index.content = '0';
    index.remove();
    return {width: index.bounds.width, height: index.bounds.height};
  }

  /**
   * Draw Digit
   *
   * @param source
   * @param position
   * @param digitModel
   * @param indexContent
   */
  public drawDigit(source, position, digitModel, indexContent) {
    const digit = new paper.PointText(new paper.Point(source.x + position * digitModel.width, source.y));
    digit.fontFamily = this._format.fontFamily;
    digit.fontWeight = this._format.fontWeight;
    digit.fontSize = (this._model.keymapStyle.fontSize || this._format.fontSize) / this._imageScale;
    digit.fillColor = this._model.keymapStyle.color || this._format.color;
    digit.content = indexContent[position];
    digit.translate(new paper.Point((digitModel.width - digit.bounds.width) / 2, 0));
    return digit;
  }

  /**
   * Draw Rectangle
   *
   * @param columnWidth
   * @param rowHeight
   */
  public drawRectangle(columnWidth, rowHeight) {
    const width = this._element.bounds.width;
    const height = this._element.bounds.height;

    const source = this.container.getAbsoluteCoords(
      this._model.source.position.x,
      this._model.source.position.y,
      this._imageOffset,
      this._imageScale);
    source.x *= this._imageScale;

    source.x = source.x - (this._model.keymapStyle.columnDistance / this._imageScale / 2);
    source.y = source.y - (this._model.keymapStyle.rowDistance / this._imageScale / 2) - <number>this._label.fontSize;

    const rectangle = new paper.Rectangle(
      new paper.Point(source.x, source.y),
      new paper.Size(
        width + (this._model.keymapStyle.columnDistance / this._imageScale),
        height + (this._model.keymapStyle.rowDistance / this._imageScale)
      )
    );
    this._rectangle = new paper.Path.Rectangle(rectangle);

    this._rectangle.fillColor = this._model.keymapStyle.background ?
      /* TODO
      (this._model.keymapStyle.backgroundInBorderColor ?
        (this._model.keymapStyle.strokeColor || this._model.keymapStyle.backgroundColor || this._format.border.strokeColor) :
        */
        (this._model.keymapStyle.backgroundColor || this._format.border.fillColor) :
      undefined;
    if (this._rectangle.fillColor) {
      this._rectangle.fillColor.alpha = this._model.keymapStyle.opacity;
    }

    this._rectangle.strokeColor = /* TODO this._model.keymapStyle.strokeColor || */ this._model.keymapStyle.color || this._format.border.strokeColor;
    this._rectangle.strokeWidth = (this._model.keymapStyle.border ?
      /* TODO this._model.keymapStyle.strokeWidth || */this._format.border.strokeWidth :
      0)
      / this._imageScale;
    this._rectangle.dashArray = /* TODO this._model.keymapStyle.dash ?
      this._model.keymapStyle.dash
        .map((dash) => dash * (this._marker && this._marker._model.keymapStyle && this._marker._model.keymapStyle.strokeWidth ?
            this._marker._model.keymapStyle.strokeWidth :
            1
        )) : */
      [1, 0];
    this._rectangle['keymap'] = this;

    if (this._model.keymapStyle.columnSeparators) {
      this.drawColumnSeparator(columnWidth, source.x, source.y);
    }

    if (this._model.keymapStyle.rowSeparators) {
      this.drawRowSeparator(rowHeight, source.x, source.y);
    }
    this._element.addChild(this._rectangle);
  }

  /**
   * Draw Column Separator
   *
   * @param columnWidth
   * @param x
   * @param y
   */
  public drawColumnSeparator(columnWidth, x, y) {
    const height = this._element.bounds._height;
    for (let column = 0; column < columnWidth.length - 1; column++) {
      x += columnWidth[column] + (this._model.keymapStyle.columnDistance / this._imageScale);
      const line = new paper.Path.Line(
        new paper.Point(x, y),
        new paper.Point(x, y + height + (this._model.keymapStyle.rowDistance / this._imageScale))
      );
      line.strokeColor = this._format.strokeColor;
      line.strokeWidth = this._format.strokeWidth / this._imageScale;
      this._element.addChild(line);
    }
  }

  /**
   * Draw Row Separator
   *
   * @param rowHeight
   * @param x
   * @param y
   */
  public drawRowSeparator(rowHeight, x, y) {
    const width = this._element.bounds._width;
    for (let row = 0; row < rowHeight.length - 1; row++) {
      y += (this._model.keymapStyle.rowDistance / this._imageScale) + rowHeight[row];
      const line = new paper.Path.Line(
        new paper.Point(x, y),
        new paper.Point(x + width + (this._model.keymapStyle.columnDistance / this._imageScale), y)
      );
      line.strokeColor = this._format.strokeColor;
      line.strokeWidth = this._format.strokeWidth / this._imageScale;
      this._element.addChild(line);
    }
  }

  /**
   * Count Index Digits
   */
  public countIndexDigits() {
    let max = 0;
    this.model.columns.forEach(currentColumn => {
      let columnMax = 0;
      currentColumn.labels.forEach(currentCell => {
        columnMax = Math.max(currentCell.index?.length, columnMax);
      });
      max = Math.max(columnMax, max);
    });

    return max;
  }

  /**
   * Get Default Label
   */
  public getDefaultLabel() {
    return [{
      'id': uuid(),
      'lables': [{
        'index': 1,
        'text': 'Legende 1',
        'pivot': 'bottomCenter'
      }]
    }];
  }

  /**
   * Setter state
   *
   * @param state: string
   */
  public set state(state: string) {
    this._state = state;
    switch (state) {
      case 'visible':
        this._element.visible = true;
        break;
      case 'unselected':
        this._element.visible = false;
        break;
      case 'selected':
        this._element.visible = true;
        break;
      case 'hidden':
        this._element.visible = false;
        break;

      default:
        break;
    }
  }

  /**
   * Arrange
   */
  public arrange() {
    this.draw();
  }

  /**
   * Clean Up
   */
  public cleanup() {
    this.element.removeChildren();
    super.cleanUp();
  }

  /**
   * Scale
   *
   * @param zoom
   */
  public scale(zoom) {
    if (this._labels.length > 0) {
      this._labels[0].setScale(zoom);
    }
  }

  /**
   * Remove Selection
   */
  public removeSelection() {
    if (this._selection) {
      this._selection.cleanUp();
      this._selection = null;
    }
  }
}
