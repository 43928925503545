import { Base } from './base.model';
import { Page } from './page.model';
import { Folder } from './folder.model';
import { Grid } from './grid.model';
import { Slide } from './slide.model';
import { User } from './user.model';

export interface Node extends Base {
  parentId?: string;
  position?: number;
  page?: Page;
  children?: Node[];
  hasChildren?: boolean;
  route?: string;
  hidden?: boolean;
  restricted?: boolean;
  shares?: User[];
  settings?: NodeSettings;
  expanded?: boolean;
}

export interface ElearningNode extends Node {
  page?: Folder;
  children?: SubjectNode[];
  parent?: Node;
}

export interface SubjectNode extends Node {
  page?: Folder;
  children?: ChapterNode[];
  parent?: ElearningNode;
}

export interface ChapterNode extends Node {
  page?: Folder;
  children?: BlockNode[];
  parent?: SubjectNode;
}

export interface BlockNode extends Node {
  page?: Grid;
  children?: SlideNode[];
  parent?: SubjectNode;
}

export interface SlideNode extends Node {
  page?: Slide;
  children?: Node[];
  parent?: BlockNode;
}

export interface NodeSettings {
  hideHomeButton?: boolean;
  hideDownloadPdf?: boolean;
  hideAnnotations?: boolean | null;
  pageTitleColor?: string;
}

export function populateNodeSettings(settings: NodeSettings = {}) : NodeSettings {
  const defaultSettings : NodeSettings = {
    hideHomeButton: false,
    hideDownloadPdf: false,
    hideAnnotations: null,
    pageTitleColor: '#ffffff'
  }

  const populatedSettings : NodeSettings = Object.assign(defaultSettings, settings);

  return populatedSettings;
}
