import { Annotation, Slide, ImagePosition, Layer, Group, Bounds, PointOfInterest } from '@medsurf/models';

// <editor-fold desc="Action Types">

export interface GetSlideOptions {
  id?: string;
  slideId?: number;
  url?: string;
  title?: string;
  withAnnotations?: boolean;
  preventStoreUpdate?: boolean;
}

export interface LoadSlideLayerOptions {
  id?: string;
  preventStoreUpdate?: boolean;
}

/**
 * Slide action types
 */
export enum SlideActionTypes {
  /**
   * Store
   */
  HandleSlideHistory = '[STORE | SLIDE] Handle Slide History',
  SlideChanged = '[STORE | SLIDE] Slide Changed',
  GetSlide = '[STORE | SLIDE] Get Slide',
  GetSlideByUrl = '[STORE | SLIDE] Get Slide by Url',
  GetSlideSuccess = '[STORE | SLIDE] Get Slide Success',
  UpdateSlides = '[STORE | SLIDE] Update Slides',
  UpdateSlidesSuccess = '[STORE | SLIDE] Update Slides Success',
  DeleteSlide = '[STORE | SLIDE] Delete Slide',
  DeleteSlideSuccess = '[STORE | SLIDE] Delete Slide Success',
  CreateSlideFromMedia = '[STORE | SLIDE] Create Slide from Media',
  CreateSlideFromMediaSuccess = '[STORE | SLIDE] Create Slide from Media Success',
  SetSlide = '[STORE | SLIDE] Set Slide',
  GetAllSlideIds = '[STORE | SLIDE] Get all Slide Ids',
  GetAllSlideIdsSuccess = '[STORE | SLIDE] Get all Slide Ids Success',
  GetLastEditedBySlideId = '[STORE | SLIDE] Get last editedBy per Slide by Id',
  GetLastEditedBySlideIdSuccess = '[STORE | SLIDE] Get last editedBy per Slide by Id Success',

  LoadSlideAnnotation = '[STORE | SLIDE] Load Slide Annotation',
  LoadSlideAnnotationSuccess = '[STORE | SLIDE] Load Slide Annotation Success',
  LoadSlidePoi = '[STORE | SLIDE] Load Slide Poi',
  LoadSlidePoiSuccess = '[STORE | SLIDE] Load Slide Poi Success',
  LoadSlideLayer = '[STORE | SLIDE] Load Slide Layer',
  LoadSlideLayerSuccess = '[STORE | SLIDE] Load Slide Layer Success',
  LoadSlideGroup = '[STORE | SLIDE] Load Slide Group',
  LoadSlideGroupSuccess = '[STORE | SLIDE] Load Slide Group Success',
  GetNextSlideId = '[STORE | SLIDE] Get next SlideId',
  GetNextSlideIdSuccess = '[STORE | SLIDE] Get next SlideId Success',
  SetLayerNumber = '[STORE | SLIDE] Set layer number',
  SetShowOnlyAnnotationsOfImage = '[STORE | SLIDE] Set sowOnlyAnnotationsOfImage',
  SetShowOnlyPoisOfImage = '[STORE | SLIDE] Set showOnlyPoisOfImage',
  SetSequenceNumber = '[STORE | SLIDE] Set sequence number',
  SetPosition = '[STORE | SLIDE] Set position',
  SetBounds = '[STORE | SLIDE] Set bounds',
  NewMarkerAdded = '[STORE | SLIDE] New Marker added',
  AddMarker = '[STORE | SLIDE] Add Marker',
  DeleteMarker = '[STORE | SLIDE] Delete Marker',
  AddQuestion = '[STORE | SLIDE] Add Question',
  CopyMarker = '[STORE | SLIDE] Copy Marker',
  PasteMarker = '[STORE | SLIDE] Paste Marker',
  /**
   * Request
   */
  GetSlideRequest = '[REQUEST | SLIDE] Get Slide',
  UpdateSlidesRequest = '[REQUEST | SLIDE] Update Slides',
  DeleteSlideRequest = '[REQUEST | SLIDE] Delete Slide',
  CreateSlideFromMediaRequest = '[REQUEST | SLIDE] Create Slide from Media',
  GetAllSlideIdsRequest = '[REQUEST | SLIDE] Get all Slide Ids',
  LoadSlideLayerRequest = '[REQUEST | SLIDE] Load Slide Layer',
  LoadSlideGroupRequest = '[REQUEST | SLIDE] Load Slide Group',
  LoadSlideAnnotationRequest = '[REQUEST | SLIDE] Load Slide Annotation',
  LoadSlidePoiRequest = '[REQUEST | SLIDE] Load Slide Poi',
  GetNextSlideIdRequest = '[REQUEST | SLIDE] Get next SlideId',
  GetLastEditedBySlideIdRequest = '[REQUEST | SLIDE] Get last editedBy per Slide by Id',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Handle Slide History
 */
 export class HandleSlideHistory {
  public static readonly type = SlideActionTypes.HandleSlideHistory;

  public constructor(public stateFragment: any) {}
}

/**
 * Get Slide
 */
export class GetSlide {
  public static readonly type = SlideActionTypes.GetSlide;

  public constructor(public options: GetSlideOptions) {}
}

/**
 * Get Slide By Url
 */
export class GetSlideByUrl {
  public static readonly type = SlideActionTypes.GetSlideByUrl;

  public constructor() {}
}

/**
 * Get Slide Success
 */
export class GetSlideSuccess {
  public static readonly type = SlideActionTypes.GetSlideSuccess;

  public constructor(public slide: Slide, public options?: GetSlideOptions) {}
}

/**
 * Slide Changed
 */
export class SlideChanged {
  public static readonly type = SlideActionTypes.SlideChanged;

  public constructor(public slide: Slide = null) {}
}

/**
 * Update Slides
 */
export class UpdateSlides {
  public static readonly type = SlideActionTypes.UpdateSlides;

  public constructor() {}
}

/**
 * Update Slide Success
 */
export class UpdateSlidesSuccess {
  public static readonly type = SlideActionTypes.UpdateSlidesSuccess;

  public constructor(public slides: Slide[]) {}
}

/**
 * Delete Slide
 */
export class DeleteSlide {
  public static readonly type = SlideActionTypes.DeleteSlide;

  public constructor() {}
}

/**
 * Delete Slide Success
 */
export class DeleteSlideSuccess {
  public static readonly type = SlideActionTypes.DeleteSlideSuccess;

  public constructor(public slide: Slide) {}
}


/**
 * Create Slide from Media
 */
export class CreateSlideFromMedia {
  public static readonly type = SlideActionTypes.CreateSlideFromMedia;

  public constructor(public title: string, public mediaIds: string[]) {}
}

/**
 * Create Slide from Media Success
 */
export class CreateSlideFromMediaSuccess {
  public static readonly type = SlideActionTypes.CreateSlideFromMediaSuccess;

  public constructor(public slide: Slide) {}
}

/**
 * Get all Slide Ids
 */
export class GetAllSlideIds {
  public static readonly type = SlideActionTypes.GetAllSlideIds;

  public constructor() {}
}

/**
 * Get all Slide Ids Success
 */
export class GetAllSlideIdsSuccess {
  public static readonly type = SlideActionTypes.GetAllSlideIdsSuccess;

  public constructor(public slideIds: number[]) {}
}

/**
 * Get last editedBy per Slide by Id
 */
export class GetLastEditedBySlideId {
  public static readonly type = SlideActionTypes.GetLastEditedBySlideId;

  public constructor(public slideId: number) {}
}

/**
 * Get last editedBy per Slide by Id Success
 */
export class GetLastEditedBySlideIdSuccess {
  public static readonly type = SlideActionTypes.GetLastEditedBySlideIdSuccess;

  public constructor(public lastEditedBy: Date) {}
}

/**
 * Load Slide Annotation
 */
export class LoadSlideAnnotation {
  public static readonly type = SlideActionTypes.LoadSlideAnnotation;

  public constructor(public options?: LoadSlideLayerOptions) {}
}

/**
 * Load Slide Annotation Success
 */
export class LoadSlideAnnotationSuccess {
  public static readonly type = SlideActionTypes.LoadSlideAnnotationSuccess;

  public constructor(public annotations: Annotation[], public options?: LoadSlideLayerOptions) {}
}

/**
 * Load Slide Poi
 */
export class LoadSlidePoi {
  public static readonly type = SlideActionTypes.LoadSlidePoi

  public constructor(public options?: LoadSlideLayerOptions) {}
}

/**
 * Load Slide Poi Success
 */
export class LoadSlidePoiSuccess {
  public static readonly type = SlideActionTypes.LoadSlidePoiSuccess;

  public constructor(public pois: PointOfInterest[], public options?: LoadSlideLayerOptions) {}
}

/**
 * Load Slide Layer
 */
export class LoadSlideLayer {
  public static readonly type = SlideActionTypes.LoadSlideLayer;

  public constructor(public options?: LoadSlideLayerOptions) {}
}

/**
 * Load Slide Layer Success
 */
export class LoadSlideLayerSuccess {
  public static readonly type = SlideActionTypes.LoadSlideLayerSuccess;

  public constructor(public layers: Layer[], public options?: LoadSlideLayerOptions) {}
}


/**
 * Load Slide Group
 */
export class LoadSlideGroup {
  public static readonly type = SlideActionTypes.LoadSlideGroup;

  public constructor() {}
}

/**
 * Load Slide Group Success
 */
export class LoadSlideGroupSuccess {
  public static readonly type = SlideActionTypes.LoadSlideGroupSuccess;

  public constructor(public groups: Group[]) {}
}

/**
 * Get next SlideId
 */
export class GetNextSlideId {
  public static readonly type = SlideActionTypes.GetNextSlideId;

  public constructor() {}
}

/**
 * Get next SlideId Success
 */
export class GetNextSlideIdSuccess {
  public static readonly type = SlideActionTypes.GetNextSlideIdSuccess;

  public constructor(public slideId: number) {}
}

/**
 * Set Slide
 */
 export class SetSlide {
  public static readonly type = SlideActionTypes.SetSlide;

  public constructor(public slide: Slide) {}
}

/**
 * Set showOnlyAnnotationsOfImage
 */
export class SetShowOnlyAnnotationsOfImage {
  public static readonly type = SlideActionTypes.SetShowOnlyAnnotationsOfImage;

  public constructor(public showOnlyAnnotationsOfImage: boolean) {}
}

/**
 * Set showOnlyPoisOfImage
 */
export class SetShowOnlyPoisOfImage {
  public static readonly type = SlideActionTypes.SetShowOnlyPoisOfImage;

  public constructor(public showOnlyPoisOfImage: boolean) {}
}

/**
 * Set layer number
 */
export class SetLayerNumber {
  public static readonly type = SlideActionTypes.SetLayerNumber;

  public constructor(public layerNumber: number) {}
}

/**
 * Set sequence number
 */
export class SetSequenceNumber {
  public static readonly type = SlideActionTypes.SetSequenceNumber;

  public constructor(public sequenceNumber: number) {}
}

/**
 * Set position
 */
export class SetPosition {
  public static readonly type = SlideActionTypes.SetPosition;

  public constructor(public position: ImagePosition) {}
}

/**
 * Set bounds
 */
export class SetBounds {
  public static readonly type = SlideActionTypes.SetBounds;

  public constructor(public bounds: Bounds) {}
}

/**
 * New Marker Added
 */
export class NewMarkerAdded {
  public static readonly type = SlideActionTypes.NewMarkerAdded;

  public constructor(public marker: Annotation) {}
}

/**
 * Add marker
 */
export class AddMarker {
  public static readonly type = SlideActionTypes.AddMarker;

  public constructor(public marker: Annotation) {}
}

/**
 * Delete marker
 */
export class DeleteMarker {
  public static readonly type = SlideActionTypes.DeleteMarker;

  public constructor(public marker: Annotation) {}
}

/**
 * Add question
 */
export class AddQuestion {
  public static readonly type = SlideActionTypes.AddQuestion;

  public constructor() {}
}

/**
 * Add question
 */
export class CopyMarker {
  public static readonly type = SlideActionTypes.CopyMarker;
  public constructor(public marker: Annotation) {}
}

/**
 * Add question
 */
export class PasteMarker {
  public static readonly type = SlideActionTypes.PasteMarker;
  public constructor() {}
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Slide By Id Request
 */
export class GetSlideRequest {
  public static readonly type = SlideActionTypes.GetSlideRequest;

  public constructor(public options: GetSlideOptions) {}
}

/**
 * Update Slides Request
 */
 export class UpdateSlidesRequest {
  public static readonly type = SlideActionTypes.UpdateSlidesRequest;

  public constructor(public slides: Slide[]) {}
}

/**
 * Delete Slide Request
 */
export class DeleteSlideRequest {
  public static readonly type = SlideActionTypes.DeleteSlideRequest;

  public constructor(public slide: Slide) {}
}

/**
 * Create Slide from Media Request
 */
export class CreateSlideFromMediaRequest {
  public static readonly type = SlideActionTypes.CreateSlideFromMediaRequest;

  public constructor(public title: string, public mediaIds: string[]) {}
}

/**
 * Get all Slide Ids Request
 */
export class GetAllSlideIdsRequest {
  public static readonly type = SlideActionTypes.GetAllSlideIdsRequest;

  public constructor() {}
}

/**
 * Load Slide Layer Request
 */
export class LoadSlideLayerRequest {
  public static readonly type = SlideActionTypes.LoadSlideLayerRequest;

  public constructor(public id: string, public options?: LoadSlideLayerOptions) {}
}

/**
 * Load Slide Annotation Request
 */
export class LoadSlideAnnotationRequest {
  public static readonly type = SlideActionTypes.LoadSlideAnnotationRequest;

  public constructor(public id: string, public options?: LoadSlideLayerOptions) {}
}

/**
 * Load Slide Poi Request
 */
export class LoadSlidePoiRequest {
  public static readonly type = SlideActionTypes.LoadSlidePoiRequest;

  public constructor(public id: string, public options?: LoadSlideLayerOptions) {}
}

/**
 * Load Slide Group Request
 */
export class LoadSlideGroupRequest {
  public static readonly type = SlideActionTypes.LoadSlideGroupRequest;

  public constructor(public id: string) {}
}

/**
 * Get next SlideId Request
 */
export class GetNextSlideIdRequest {
  public static readonly type = SlideActionTypes.GetNextSlideIdRequest;

  public constructor() {}
}

/**
 * Get last editedBy per Slide by Id Request
 */
export class GetLastEditedBySlideIdRequest {
  public static readonly type = SlideActionTypes.GetLastEditedBySlideIdRequest;

  public constructor(public slideId: number) {}
}

// </editor-fold>
