import { Component, Input } from '@angular/core';

@Component({
  selector: 'medsurf-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent {
  /**
   * Inputs
   */
  @Input() public includes: Array<string>;

  /**
   * Members
   */
  public showNotice = false;
  public dismissed = false;

  /**
   * Dismiss Notice
   */
  public dismissNotice() {
    this.dismissed = true;
    this.showNotice = false;
  }
}
