export enum DisplayMode {
  FULL = 'FULL',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE'
}

export enum AnnotationMode {
  IMAGE = 'IMAGE',
  ANNOTATION = 'ANNOTATION',
  SELF_TEST = 'SELF_TEST'
}

export enum ImageMode {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}
