// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import OpenSeadragon from 'openseadragon';
import { ElementRef, Injectable } from '@angular/core';
import { Selector, State, StateToken, Store } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import * as FlatStates from '../../internal';
import { MediaControlService } from '@medsurf/flat-services';

/**
 * Image Viewer Token
 */
export const IMAGE_VIEWER_TOKEN = new StateToken<ImageViewerStateModel>('imageViewer');

/**
 * Image Viewer State Model
 */
export interface ImageViewerStateModel {
  defaultConfig: Partial<OpenSeadragon.Options>;
  elementRef: ElementRef | null;
}

/**
 * Image Viewer State
 */
@State<ImageViewerStateModel>({
  name: IMAGE_VIEWER_TOKEN,
  defaults: {
    defaultConfig: {
      showNavigationControl: false,
      navigatorPosition: 'ABSOLUTE',
      navigatorHeight: 105,
      navigatorWidth: 155,
      navigatorTop: 16,
      navigatorLeft: 'calc(100% - 221px)',
      navigatorAutoResize: false,
      autoHideControls: false,
      gestureSettingsMouse: {
        flickEnabled: false,
        clickToZoom: false,
        dblClickToZoom: true,
        scrollToZoom: true,
      },
      gestureSettingsPen: {
        flickEnabled: false
      },
      gestureSettingsTouch: {
        flickEnabled: false
      },
      gestureSettingsUnknown: {
        flickEnabled: false
      },
      sequenceMode: false,
      imageLoaderLimit: 10,
      showSequenceControl: false,
      preserveViewport: true,
      maxZoomPixelRatio: 1,
      animationTime: 0.7,
      panVertical: true,
      panHorizontal: true,
      showNavigator: true,
    },
    elementRef: null,
  }
})
@Injectable()
export class ImageViewerState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param mediaControlService: MediaControlService
   */
  public constructor(public store: Store,
                     public mediaControlService: MediaControlService) {
  }

  //<editor-fold desc="Selectors">

  /**
   * Selector defaultConfig$
   *
   * @param state: ImageViewerStateModel
   */
  @Selector([ImageViewerState])
  public static defaultConfig$(state: ImageViewerStateModel) {
    return state.defaultConfig;
  }

  /**
   * Selector ElementRef
   *
   * @param state: ImageViewerStateModel
   */
  @Selector([ImageViewerState])
  public static elementRef$(state: ImageViewerStateModel) {
    return state.elementRef;
  }

  /**
   * Selector elementConfig$
   *
   * @param elementRef: ElementRef
   */
  @Selector([ImageViewerState.elementRef$])
  public static elementConfig$(elementRef: ElementRef) {
    return elementRef !== null ? {element: elementRef.nativeElement} : null;
  }

  /**
   * Selector navigatorBackground$
   *
   * @param currentSelectedSlideImage: FlatModels.ImageEntityModels.Image
   */
  @Selector([FlatStates.ImageEntityState.currentSelectedSlideImage$])
  public static imageConfig$(currentSelectedSlideImage: FlatModels.ImageEntityModels.Image) {
    // Navigator Background Color
    const navigatorBackgroundColor = currentSelectedSlideImage && currentSelectedSlideImage.background && currentSelectedSlideImage.background.color || '';

    // Return Image Config
    return {
      navigatorBackground: navigatorBackgroundColor.startsWith('#') ? navigatorBackgroundColor : '#ffffff',
    };
  }

  /**
   * Dynamic Selector config$
   *
   * @param defaultConfig: Partial<OpenSeadragon.Options>
   */
  @Selector([ImageViewerState.defaultConfig$])
  public static config$(defaultConfig: Partial<OpenSeadragon.Options>) {
    if (defaultConfig !== null) {
      return Object.assign({}, defaultConfig) || null;
    }
    return null;
  }

  /**
   * Selector annotations$
   *
   * @param currentSelectedSlideAnnotations: FlatModels.AnnotationEntityModels.Annotation[]
   * @param annotationLabels: FlatModels.AnnotationLabelEntityModels.AnnotationLabel[]
   * @param annotationSources: FlatModels.AnnotationSourceEntityModels.AnnotationSource[]
   * @param annotationTargets: FlatModels.AnnotationTargetEntityModels.AnnotationTarget[]
   * @param freeformStyles: FlatModels.FreeFormStyleEntityModels.FreeFormStyle[]
   * @param keymapStyles: FlatModels.KeymapStyleEntityModels.KeymapStyle[]
   * @param keymapColumns: FlatModels.KeymapColumnEntityModels.KeymapColumn[]
   * @param keymapLabels: FlatModels.KeymapLabelEntityModels.KeymapLabel[]
   */
  @Selector([
    FlatStates.AnnotationEntityState.currentSelectedSlideAnnotations$,
    FlatStates.AnnotationLabelEntityState.entities$,
    FlatStates.AnnotationSourceEntityState.entities$,
    FlatStates.AnnotationTargetEntityState.entities$,
    FlatStates.FreeFormStyleEntityState.entities$,
    FlatStates.KeymapStyleEntityState.entities$,
    FlatStates.KeymapColumnEntityState.entities$,
    FlatStates.KeymapLabelEntityState.entities$,
  ])
  public static annotations$(currentSelectedSlideAnnotations: FlatModels.AnnotationEntityModels.AnnotationEntityType[],
                             annotationLabels: FlatModels.AnnotationLabelEntityModels.AnnotationLabel[],
                             annotationSources: FlatModels.AnnotationSourceEntityModels.AnnotationSource[],
                             annotationTargets: FlatModels.AnnotationTargetEntityModels.AnnotationTarget[],
                             freeformStyles: FlatModels.FreeFormStyleEntityModels.FreeFormStyle[],
                             keymapStyles: FlatModels.KeymapStyleEntityModels.KeymapStyle[],
                             keymapColumns: FlatModels.KeymapColumnEntityModels.KeymapColumn[],
                             keymapLabels: FlatModels.KeymapLabelEntityModels.KeymapLabel[]): FlatModels.ImageViewerModels.ElementModels[] {
    if (Array.isArray(currentSelectedSlideAnnotations) && Array.isArray(annotationLabels)
      && Array.isArray(annotationSources) && Array.isArray(annotationTargets) && Array.isArray(freeformStyles)) {
      return currentSelectedSlideAnnotations.map(a => {
        switch (a.type) {
          case FlatModels.AnnotationEntityModels.AnnotationType.MARKER:
            return {
              id: a.id,
              annotation: a,
              label: annotationLabels.find(al => al.id === a.label as unknown as string) || null,
              source: annotationSources.find(as => as.id === a.source as unknown as string) || null,
              targets: annotationTargets.filter(at => (a.targets as unknown as string[]).includes(at.id)) || [],
            };
          case FlatModels.AnnotationEntityModels.AnnotationType.FREE_FORM:
            return {
              id: a.id,
              annotation: a,
              label: annotationLabels.find(al => al.id === a.label as unknown as string) || null,
              source: annotationSources.find(as => as.id === a.source as unknown as string) || null,
              target: annotationTargets.find(at => at.id === (a as FlatModels.AnnotationEntityModels.FreeForm).target as unknown as string) || null,
              freeFormStyle: freeformStyles.find(ffs => ffs.id === (a as FlatModels.AnnotationEntityModels.FreeForm).freeFormStyle as unknown as string) || null,
            };
          case FlatModels.AnnotationEntityModels.AnnotationType.KEYMAP:
            return {
              id: a.id,
              annotation: a,
              label: annotationLabels.find(al => al.id === a.label as unknown as string) || null,
              source: annotationSources.find(as => as.id === a.source as unknown as string) || null,
              targets: annotationTargets.filter(at => (a.targets as unknown as string[]).includes(at.id)) || [],
              keymapStyle: keymapStyles.find(kms => kms.id === (a as FlatModels.AnnotationEntityModels.Keymap).keymapStyle as unknown as string) || null,
              columns: keymapColumns.filter(kmc => ((a as FlatModels.AnnotationEntityModels.Keymap).columns as unknown as string[]).includes(kmc.id)).map(kmc => {
                return {
                  id: kmc.id,
                  keymap: kmc.keymap,
                  labels: keymapLabels.filter(kml => (kmc.labels as unknown as string[]).includes(kml.id)).sort((a, b) => (a.order || 0) - (b.order || 0)) || [],
                  order: kmc.order
                }
              }).sort((a, b) => (a.order || 0) - (b.order || 0)) || [],
            };
          /*
          case FlatModels.AnnotationEntityModels.AnnotationType.TEXT:
            return {};
          */
          default:
            throw Error("not_implemented");
        }
      });
    }
    return [];
  }

  //</editor-fold>
}
