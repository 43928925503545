import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(html: string): SafeHtml {
    // Default allow-lists/block-lists: https://github.com/cure53/DOMPurify/wiki/Default-TAGs-ATTRIBUTEs-allow-list-&-blocklist
    const clean: string = DOMPurify.sanitize(html, {ADD_ATTR: ['target']});
    return this.sanitizer.bypassSecurityTrustHtml(clean);
  }

}
