import { KeymapStyleEntityModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetEntitySuccess = '[STORE | ENTITY | KEYMAP_STYLE] Get Entity Success',
  GetEntitiesSuccess = '[STORE | ENTITY | KEYMAP_STYLE] Get Entities Success',
  AddEntity = '[STORE | ENTITY | KEYMAP_STYLE] Add Entity',
  UpdateEntity = '[STORE | ENTITY | KEYMAP_STYLE] Update Entity',
  RemoveEntity = '[STORE | ENTITY | KEYMAP_STYLE] Remove Entity',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Entity Success
 */
export class GetEntitySuccess {
  public static readonly type = ActionTypes.GetEntitySuccess;

  public constructor(public entityQueryResult: KeymapStyleEntityModels.KeymapStyleEntityQueryResult) {
  }
}

/**
 * Get Entities Success
 */
export class GetEntitiesSuccess {
  public static readonly type = ActionTypes.GetEntitiesSuccess;

  public constructor(public entityQueryResults: KeymapStyleEntityModels.KeymapStyleEntityQueryResult[]) {
  }
}

/**
 * Add Entity
 */
export class AddEntity {
  public static readonly type = ActionTypes.AddEntity;

  public constructor(public entity: KeymapStyleEntityModels.KeymapStyle) {
  }
}

/**
 * Update Entity
 */
export class UpdateEntity {
  public static readonly type = ActionTypes.UpdateEntity;

  public constructor(public entity: KeymapStyleEntityModels.KeymapStyle) {
  }
}

/**
 * Remove Entity
 */
export class RemoveEntity {
  public static readonly type = ActionTypes.RemoveEntity;

  public constructor(public entity: KeymapStyleEntityModels.KeymapStyle) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>