import * as FlatModels from '@medsurf/flat-models';
import { Params } from '@angular/router';

// <editor-fold desc="Action Types">

/**
 * Action types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetTrainingNodesByCurrentSubject = '[STORE | VIEWER | TRAINING] Get Training Nodes By Current Subject',
  GetTrainingNodesBySelectedSlides = '[STORE | VIEWER | TRAINING] Get Training Nodes By Selected Slides',
  GetSlideEntitySuccess = '[STORE | VIEWER | TRAINING] Get Slide Entity Success',
  GetSlideEntitiesSuccess = '[STORE | VIEWER | TRAINING] Get Slide Entities Success',
  GetBlockEntitySuccess = '[STORE | VIEWER | TRAINING] Get Block Entity Success',
  GetBlockEntitiesSuccess = '[STORE | VIEWER | TRAINING] Get Block Entities Success',
  ToggleTrainingSelection = '[STORE | VIEWER | TRAINING] Toggle Training Selection',
  Reset = '[STORE | VIEWER | TRAINING] Reset',
  SetupTraining = '[STORE | VIEWER | TRAINING] Setup Training',
  SetupCase = '[STORE | VIEWER | TRAINING] Setup Case',
  Start = '[STORE | VIEWER | TRAINING] Start',
  UpdateSlideResult = '[STORE | VIEWER | TRAINING] Update Slide Result',
  SetShowFeedback = '[STORE | VIEWER | TRAINING] Set Show Feedback',
  ToggleShowExplanation = '[STORE | VIEWER | TRAINING] Toggle Show Explanation',
  GoToStart = '[STORE | VIEWER | TRAINING] Go To Start',
  GoToSlideByNode = '[STORE | VIEWER | TRAINING] Go To Slide By Node',
  TrackQuizAnswer = '[STORE | VIEWER | TRAINING] Track Quiz Answer',
  /**
   * Request
   */
  GetTrainingNodesBySubjectIdRequest = '[REQUEST | VIEWER | TRAINING] Get Training Nodes By SubjectId Request',
  GetTrainingNodesByIdsRequest = '[REQUEST | VIEWER | TRAINING] Get Training Nodes By Ids Request',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Training Nodes By Current Subject
 */
export class GetTrainingNodesByCurrentSubject {
  public static readonly type = ActionTypes.GetTrainingNodesByCurrentSubject;
}

/**
 * Get Training Nodes By Selected Slides
 */
export class GetTrainingNodesBySelectedSlides {
  public static readonly type = ActionTypes.GetTrainingNodesBySelectedSlides;
}

/**
 * Get Slide Entity Success
 */
export class GetSlideEntitySuccess {
  public static readonly type = ActionTypes.GetSlideEntitySuccess;

  public constructor(public entityQueryResult: FlatModels.TrainingViewerModels.TrainingViewerSlideQueryResult) {
  }
}

/**
 * Get Slide Entities Success
 */
export class GetSlideEntitiesSuccess {
  public static readonly type = ActionTypes.GetSlideEntitiesSuccess;

  public constructor(public entityQueryResults: FlatModels.TrainingViewerModels.TrainingViewerSlideQueryResult[]) {
  }
}

/**
 * Get Block Entity Success
 */
export class GetBlockEntitySuccess {
  public static readonly type = ActionTypes.GetBlockEntitySuccess;

  public constructor(public entityQueryResult: FlatModels.TrainingViewerModels.TrainingViewerBlockQueryResult) {
  }
}

/**
 * Get Block Entities Success
 */
export class GetBlockEntitiesSuccess {
  public static readonly type = ActionTypes.GetBlockEntitiesSuccess;

  public constructor(public entityQueryResults: FlatModels.TrainingViewerModels.TrainingViewerBlockQueryResult[]) {
  }
}

/**
 * Toggle Training Selection
 */
export class ToggleTrainingSelection {
  public static readonly type = ActionTypes.ToggleTrainingSelection;

  public constructor(public slides: FlatModels.TrainingViewerModels.AvailableSlide[],
                     public override: boolean) {
  }
}

/**
 * Reset
 */
export class Reset {
  public static readonly type = ActionTypes.Reset;
}

/**
 * Setup Training
 */
export class SetupTraining {
  public static readonly type = ActionTypes.SetupTraining;
}

/**
 * Setup Case
 */
export class SetupCase {
  public static readonly type = ActionTypes.SetupCase;
}

/**
 * Start
 */
export class Start {
  public static readonly type = ActionTypes.Start;
}

/**
 * Update Slide Result
 */
export class UpdateSlideResult {
  public static readonly type = ActionTypes.UpdateSlideResult;

  public constructor(public slideResult: FlatModels.TrainingViewerModels.TrainingResultType) {
  }
}

/**
 * Set Show Feedback
 */
export class SetShowFeedback {
  public static readonly type = ActionTypes.SetShowFeedback;

  public constructor(public slideResult: FlatModels.TrainingViewerModels.TrainingResultType,
                     public question: FlatModels.QuestionEntityModels.QuestionEntityType) {
  }
}

/**
 * Toggle Show Explanation
 */
export class ToggleShowExplanation {
  public static readonly type = ActionTypes.ToggleShowExplanation;

  public constructor(public slideResult: FlatModels.TrainingViewerModels.TrainingResultType) {
  }
}

/**
 * Go To Start
 */
export class GoToStart {
  public static readonly type = ActionTypes.GoToStart;
}

/**
 * Go To Slide By Node
 */
export class GoToSlideByNode {
  public static readonly type = ActionTypes.GoToSlideByNode;

  public constructor(public slideNode: FlatModels.NodeEntityModels.SlideNode,
                     public queryParams: Params) {
  }
}

/**
 * Track Quiz Answer
 */
export class TrackQuizAnswer {
  public static readonly type = ActionTypes.TrackQuizAnswer;

  public constructor(public slideId: string,
                     public question: FlatModels.QuestionEntityModels.QuestionEntityType,
                     public isCorrect: boolean) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Training Nodes By SubjectId Request
 */
export class GetTrainingNodesBySubjectIdRequest {
  public static readonly type = ActionTypes.GetTrainingNodesBySubjectIdRequest;

  public constructor(public subjectId: string) {
  }
}

/**
 * Get Training Nodes By Ids Request
 */
export class GetTrainingNodesByIdsRequest {
  public static readonly type = ActionTypes.GetTrainingNodesByIdsRequest;

  public constructor(public ids: string[]) {
  }
}

// </editor-fold>
