import { MenuAuthorModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action types
 */
export enum ActionTypes {
  /**
   * Store
   */
  SetAuthorDisplayMode = '[STORE | AUTHOR | MENU] Set Author Display Mode',
  SetAuthorDisplayMenu = '[STORE | AUTHOR | MENU] Set Author Display Menu',
  ToggleShowHeader = '[STORE | AUTHOR | MENU] Toggle Show Header',
  ToggleShowBody = '[STORE | AUTHOR | MENU] Toggle Show Body',
  ToggleShowQuiz = '[STORE | AUTHOR | MENU] Toggle Show Quiz',
  ToggleShowInteractiveArea = '[STORE | AUTHOR | MENU] Toggle Show Interactive Area',
  ToggleShowResourceType = '[STORE | AUTHOR | MENU] Toggle Show Resource Type',
  ToggleShowModality = '[STORE | AUTHOR | MENU] Toggle Show Modality',
  ToggleShowStain = '[STORE | AUTHOR | MENU] Toggle Show Stain',
  ToggleShowCompoundNumber = '[STORE | AUTHOR | MENU] Toggle Compound Number',
  ToggleShowCopyright = '[STORE | AUTHOR | MENU] Toggle Copyright',
  ToggleChapterSection = '[STORE | AUTHOR | MENU] Toggle Chapter Section',
  ToggleEditSection = '[STORE | AUTHOR | MENU] Toggle Edit Section',
  ToggleLayerSection = '[STORE | AUTHOR | MENU] Toggle Layer Section',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Set Author Display Mode
 */
export class SetAuthorDisplayMode {
  public static readonly type = ActionTypes.SetAuthorDisplayMode;

  public constructor(public authorDisplayMode: MenuAuthorModels.AuthorDisplayMode) {
  }
}

/**
 * Set Author Display Menu
 */
export class SetAuthorDisplayMenu {
  public static readonly type = ActionTypes.SetAuthorDisplayMenu;

  public constructor(public authorDisplayMenu: MenuAuthorModels.AuthorDisplayMenu) {
  }
}

/**
 * Toggle Show Header
 */
export class ToggleShowHeader {
  public static readonly type = ActionTypes.ToggleShowHeader;
}

/**
 * Toggle Show Body
 */
export class ToggleShowBody {
  public static readonly type = ActionTypes.ToggleShowBody;
}

/**
 * Toggle Show Quiz
 */
export class ToggleShowQuiz {
  public static readonly type = ActionTypes.ToggleShowQuiz;
}

/**
 * Toggle Show Interactive Area
 */
export class ToggleShowInteractiveArea {
  public static readonly type = ActionTypes.ToggleShowInteractiveArea;
}

/**
 * Toggle Show Resource Type
 */
export class ToggleShowResourceType {
  public static readonly type = ActionTypes.ToggleShowResourceType;
}

/**
 * Toggle Show Modality
 */
export class ToggleShowModality {
  public static readonly type = ActionTypes.ToggleShowModality;
}

/**
 * Toggle Show Stain
 */
export class ToggleShowStain {
  public static readonly type = ActionTypes.ToggleShowStain;
}

/**
 * Toggle Show Compound Number
 */
export class ToggleShowCompoundNumber {
  public static readonly type = ActionTypes.ToggleShowCompoundNumber;
}

/**
 * Toggle Show Copyright
 */
export class ToggleShowCopyright {
  public static readonly type = ActionTypes.ToggleShowCopyright;
}

/**
 * Toggle Chapter Section
 */
export class ToggleChapterSection {
  public static readonly type = ActionTypes.ToggleChapterSection;

  public constructor(public override: boolean | undefined = undefined) {
  }
}

/**
 * Toggle Edit Section
 */
export class ToggleEditSection {
  public static readonly type = ActionTypes.ToggleEditSection;

  public constructor(public override: boolean | undefined = undefined) {
  }
}

/**
 * Toggle Layer Section
 */
export class ToggleLayerSection {
  public static readonly type = ActionTypes.ToggleLayerSection;

  public constructor(public override: boolean | undefined = undefined) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
