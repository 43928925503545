import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { ChoiceEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * Choice Entity Token
 */
export const CHOICE_ENTITY_TOKEN = new StateToken<ChoiceEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.CHOICE);

/**
 * Choice Entity State Model
 */
export type ChoiceEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.ChoiceEntityModels.Choice>;

/**
 * Choice Entity State
 */
@State<ChoiceEntityStateModel>({
  name: CHOICE_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class ChoiceEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: ChoiceEntityStateModel
   */
  @Selector([ChoiceEntityState])
  public static typedEntities$(state: ChoiceEntityStateModel): { [id: string]: FlatModels.ChoiceEntityModels.Choice } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.ChoiceEntityModels.Choice }
   */
  @Selector([ChoiceEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.ChoiceEntityModels.Choice }): FlatModels.ChoiceEntityModels.Choice[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.ChoiceEntityModels.Choice }
   */
  @Selector([ChoiceEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.ChoiceEntityModels.Choice }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.ChoiceEntityModels.Choice[]
   */
  @Selector([ChoiceEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.ChoiceEntityModels.Choice[]) {
    return (ids: string[]) => {
      const foundEntities = entities ? entities.filter(e => ids.includes(e.id)) : [];
      foundEntities.sort((a, b) => (a?.order || 0) - (b?.order || 0));
      return foundEntities;
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<ChoiceEntityStateModel>
   * @param patchState: StateContext<ChoiceEntityStateModel>
   * @param entityQueryResult: ChoiceEntityActions.GetEntitySuccess
   */
  @Action(ChoiceEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<ChoiceEntityStateModel>,
                          {entityQueryResult}: ChoiceEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<ChoiceEntityStateModel>
   * @param patchState: StateContext<ChoiceEntityStateModel>
   * @param entityQueryResults: ChoiceEntityActions.GetEntitiesSuccess
   */
  @Action(ChoiceEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<ChoiceEntityStateModel>,
                            {entityQueryResults}: ChoiceEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<ChoiceEntityStateModel>
   * @param patchState: StateContext<ChoiceEntityStateModel>
   * @param entity: ChoiceEntityActions.AddEntity
   */
  @Action(ChoiceEntityActions.AddEntity)
  @Undoable(ChoiceEntityActions.AddEntity, CHOICE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<ChoiceEntityStateModel>,
                   {entity}: ChoiceEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<ChoiceEntityStateModel>
   * @param patchState: StateContext<ChoiceEntityStateModel>
   * @param entity: ChoiceEntityActions.UpdateEntity
   */
  @Action(ChoiceEntityActions.UpdateEntity)
  @Undoable(ChoiceEntityActions.UpdateEntity, CHOICE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<ChoiceEntityStateModel>,
                      {entity}: ChoiceEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<ChoiceEntityStateModel>
   * @param patchState: StateContext<ChoiceEntityStateModel>
   * @param entity: ChoiceEntityActions.RemoveEntity
   */
  @Action(ChoiceEntityActions.RemoveEntity)
  @Undoable(ChoiceEntityActions.RemoveEntity, CHOICE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<ChoiceEntityStateModel>,
                      {entity}: ChoiceEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
