import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { CopyrightEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import { MediaEntityState } from '../../internal';

/**
 * Copyright Entity Token
 */
export const COPYRIGHT_ENTITY_TOKEN = new StateToken<CopyrightEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.COPYRIGHT);

/**
 * Copyright Entity State Model
 */
export type CopyrightEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.CopyrightEntityModels.Copyright>;

/**
 * Copyright Entity State
 */
@State<CopyrightEntityStateModel>({
  name: COPYRIGHT_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class CopyrightEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: CopyrightEntityStateModel
   */
  @Selector([CopyrightEntityState])
  public static typedEntities$(state: CopyrightEntityStateModel): { [id: string]: FlatModels.CopyrightEntityModels.Copyright } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.CopyrightEntityModels.Copyright }
   */
  @Selector([CopyrightEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.CopyrightEntityModels.Copyright }): FlatModels.CopyrightEntityModels.Copyright[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.CopyrightEntityModels.Copyright }
   */
  @Selector([CopyrightEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.CopyrightEntityModels.Copyright }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.CopyrightEntityModels.Copyright[]
   */
  @Selector([CopyrightEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.CopyrightEntityModels.Copyright[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  /**
   * Selector allCurrentSelectedSlideCopyrights$
   * @param entities: FlatModels.CopyrightEntityModels.Copyright[]
   * @param allCurrentSelectedSlideMedias: FlatModels.MediaEntityModels.Media[]
   */
  @Selector([
    CopyrightEntityState.entities$,
    MediaEntityState.allCurrentSelectedSlideMedias$
  ])
  public static allCurrentSelectedSlideCopyrights$(entities: FlatModels.CopyrightEntityModels.Copyright[],
                                                   allCurrentSelectedSlideMedias: FlatModels.MediaEntityModels.Media[]) {
    if (Array.isArray(allCurrentSelectedSlideMedias)) {
      const allCurrentSelectedCopyrights: string[] = allCurrentSelectedSlideMedias.map(sm => sm.copyrights || []).reduce((acc, val) => acc.concat(val), []) as unknown as string[] || [];
      return Array.isArray(allCurrentSelectedCopyrights) ? entities.filter(e => allCurrentSelectedCopyrights.includes(e.id)) : [];
    }
    return [];
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<CopyrightEntityStateModel>
   * @param patchState: StateContext<CopyrightEntityStateModel>
   * @param entityQueryResult: CopyrightEntityActions.GetEntitySuccess
   */
  @Action(CopyrightEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<CopyrightEntityStateModel>,
                          {entityQueryResult}: CopyrightEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<CopyrightEntityStateModel>
   * @param patchState: StateContext<CopyrightEntityStateModel>
   * @param entityQueryResults: CopyrightEntityActions.GetEntitiesSuccess
   */
  @Action(CopyrightEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<CopyrightEntityStateModel>,
                            {entityQueryResults}: CopyrightEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<CopyrightEntityStateModel>
   * @param patchState: StateContext<CopyrightEntityStateModel>
   * @param entity: CopyrightEntityActions.AddEntity
   */
  @Action(CopyrightEntityActions.AddEntity)
  @Undoable(CopyrightEntityActions.AddEntity, COPYRIGHT_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<CopyrightEntityStateModel>,
                   {entity}: CopyrightEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<CopyrightEntityStateModel>
   * @param patchState: StateContext<CopyrightEntityStateModel>
   * @param entity: CopyrightEntityActions.UpdateEntity
   */
  @Action(CopyrightEntityActions.UpdateEntity)
  @Undoable(CopyrightEntityActions.UpdateEntity, COPYRIGHT_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<CopyrightEntityStateModel>,
                      {entity}: CopyrightEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<CopyrightEntityStateModel>
   * @param patchState: StateContext<CopyrightEntityStateModel>
   * @param entity: CopyrightEntityActions.RemoveEntity
   */
  @Action(CopyrightEntityActions.RemoveEntity)
  @Undoable(CopyrightEntityActions.RemoveEntity, COPYRIGHT_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<CopyrightEntityStateModel>,
                      {entity}: CopyrightEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
