import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent {
  /**
   * Constructor
   *
   * @param titleService: Title
   * @param navigationControlService: NavigationControlService
   */
  constructor(public titleService: Title,
              public navigationControlService: NavigationControlService) {
  }

  /**
   * Set Title
   *
   * @param title: string
   */
  public setTitle(title: string) {
    this.titleService.setTitle(title);
    return true;
  }

  /**
   * Go To Home
   */
  goToHomepage() {
    this.navigationControlService.goHome();
  }
}
