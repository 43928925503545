import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MedsurfLibModule } from '@medsurf/module';
import { VocabularyComponent } from './main/vocabulary/vocabulary.component';
import { ChaptersComponent } from './chapters.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GridComponent } from './main/grid/grid.component';
import { QuizStartComponent } from './main/quiz-start/quiz-start.component';
import { MedsurfViewerCommonModule } from '../common/medsurf-viewer-common.module';
import { ChapterTextComponent } from './main/chapter-text/chapter-text.component';
import { QuizEndComponent } from './main/quiz-end/quiz-end.component';
import { ChaptersRoutingModule } from './chapters-routing.module';
import { CaseStartComponent } from './main/case-start/case-start.component';

@NgModule({
  declarations: [
    VocabularyComponent,
    GridComponent,
    ChaptersComponent,
    QuizStartComponent,
    ChapterTextComponent,
    QuizEndComponent,
    CaseStartComponent
  ],
  imports: [
    CommonModule,
    MedsurfViewerCommonModule,
    RouterModule,
    ChaptersRoutingModule,
    MedsurfLibModule,
    TranslateModule,
    NgOptimizedImage,
  ],
  exports: [
    ChaptersComponent
  ]
})
export class ChaptersModule {
}
