import { clamp } from 'lodash';
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { MenuViewerFacade, SlideViewerFacade } from '@medsurf/flat-facades';

let startY = 0;

/**
 * Sequence Item
 */
interface SequenceItem {
  id: string;
  sequenceNumber: number
  active: boolean;
  angle: number;
  title: string;
  color: string;
  hidden: boolean;
}

/**
 * Sequence Direction
 */
interface SequenceDirection {
  color: string;
  active: boolean;
}

/**
 * Sequence Control
 */
interface SequenceControl {
  up: SequenceDirection;
  down: SequenceDirection;
}

@Component({
  selector: 'medsurf-sequence-control',
  templateUrl: './sequenceControl.component.html',
  styleUrls: ['./sequenceControl.component.scss']
})
export class SequenceControlComponent implements AfterViewInit {
  /**
   * View Children
   */
  @ViewChild('sequenceList', {static: false}) public sequenceListElement: ElementRef<HTMLElement>;

  /**
   * Members
   */
  public sequenceListHeight = 200;

  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   */
  constructor(public slideViewerFacade: SlideViewerFacade,
              public menuViewerFacade: MenuViewerFacade) {
  }

  /**
   * Ng After View Init
   */
  ngAfterViewInit(): void {
    this.handleResize();
  }

  /**
   * Tracks items by their id
   * @param i index
   * @param item item to track
   */
  public trackBy(i, item) {
    return item.id;
  }

  /**
   * Get Sequence Control
   *
   * @param sequenceItems: SequenceItem[]
   */
  public getSequenceControl(sequenceItems: SequenceItem[]): SequenceControl {
    if (!sequenceItems) {
      return null;
    }
    return {
      up: {
        active: sequenceItems.some(item => item.hidden && item.angle > 0), // imageIndex !== 0,
        color: sequenceItems.reduce((c, item) => item.hidden && item.angle > 0 && item.color ? item.color : c, '')
      },
      down: {
        active: sequenceItems.some(item => item.hidden && item.angle < 0), // imageIndex !== this.sequenceItems.length - 1,
        color: sequenceItems.reduceRight((c, item) => item.hidden && item.angle < 0 && item.color ? item.color : c, '')
      }
    }
  }

  /**
   * Get Sequence Items
   *
   * @param images: FlatModels.ImageEntityModels.Image[]
   * @param medias: FlatModels.MediaEntityModels.MediaImage[]
   * @param themeColor: string
   * @param imageIndex: number
   */
  public getSequenceItems(images: FlatModels.ImageEntityModels.Image[],
                          medias: FlatModels.MediaEntityModels.MediaImage[],
                          themeColor: string,
                          imageIndex: number): SequenceItem[] {
    if (!images || !Number.isInteger(imageIndex)) {
      return [];
    }
    const hasDepth = medias.every((media) => !!media?.dimensions?.depth?.trim());
    return images.map((image, index) => {
      const media = medias.find(m => m.id === image.media as unknown as string);
      const active = imageIndex === index;
      const angleStep = 200 / this.sequenceListHeight * 6;
      const angle = clamp((imageIndex - index) * angleStep, -60, 60);
      const depth = media?.dimensions?.depth;
      let title;
      if (hasDepth) {
        const pads = String(images.length).length + 1;
        title = `${String(depth).padStart(pads, ' ')}`;
      } else {
        const pads = String(images.length).length;
        title = `${String(index + 1).padStart(pads, ' ')}/${images.length}`;
      }
      const annotated = image.annotations?.length > 0 || image.pois?.length > 0;
      const color = annotated ? themeColor : '';
      const hidden = Math.abs(angle) === 60;

      return {
        id: image.id,
        sequenceNumber: image.sequenceNumber,
        active,
        angle,
        title,
        color,
        hidden
      };
    })
  }

  /**
   * Get Current Title
   *
   * @param sequenceItems: SequenceItem[]
   */
  public getCurrentTitle(sequenceItems: SequenceItem[]) {
    const item = sequenceItems.find(i => i.active);
    return item?.title || '';
  }

  /**
   * On wheel
   *
   * @param event: WheelEvent
   * @param imageIndex: number
   */
  public onWheel(event: WheelEvent,
                 imageIndex: number) {
    const delta = Math.sign(event.deltaY);
    this.slideViewerFacade.requestSetImageIndex(imageIndex + delta);
  }

  /**
   * On Touch Start
   *
   * @param event: TouchEvent
   */
  public onTouchStart(event: TouchEvent) {
    if (event.targetTouches.length === 1) {
      startY = event.targetTouches[0].clientY;
    } 
  }

  /**
   * Handle Touch Move
   *
   * @param event: TouchEvent
   * @param imageIndex: number
   */
  handleTouchMove(event: TouchEvent,
                  imageIndex: number) {
    if (startY) {
      const delta = startY - event.targetTouches[0].clientY;
      if (Math.abs(delta) > 25) {
        startY = event.targetTouches[0].clientY;
        this.slideViewerFacade.requestSetImageIndex(imageIndex + Math.sign(delta));
      }
    }
  }

  /**
   * Handle Touch End
   */
  @HostListener('window:touchend')
  handleTouchEnd() {
    if (startY) {
      startY = 0;
    }
  }

  /**
   * Handle Resize
   */
  @HostListener('window:resize')
  handleResize() {
    if (
      this.sequenceListElement?.nativeElement &&
      this.sequenceListElement?.nativeElement?.clientHeight !== this.sequenceListHeight
    ) {
      this.sequenceListHeight = this.sequenceListElement?.nativeElement?.clientHeight;
    }
  }
}
