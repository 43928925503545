import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { TrainingViewerFacade } from '@medsurf/flat-facades';

/**
 * TODO's:
 * - Buttons can be simplified to only one
 */
@Component({
  selector: 'medsurf-quiz-question-footer',
  templateUrl: './question-footer.component.html',
  styleUrls: ['./question-footer.component.scss']
})
export class QuestionFooterComponent {
  /**
   * Inputs
   */
  @Input() question: FlatModels.QuestionEntityModels.QuestionEntityType;
  @Input() trainingResult: FlatModels.TrainingViewerModels.TrainingResultType;
  @Input() showRequired: boolean;
  @Input() isCorrect: () => boolean;
  @Input() passRequired: () => boolean;

  /**
   * Outputs
   */
  @Output() showRequiredChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Constructor
   *
   * @param trainingViewerFacade: TrainingViewerFacade
   */
  public constructor(public trainingViewerFacade: TrainingViewerFacade) {
  }

  /**
   * Set Show Feedback
   *
   * @param trainingPage: FlatModels.PageEntityModels.Training | FlatModels.PageEntityModels.Slide
   */
  public setShowFeedback(trainingPage: FlatModels.PageEntityModels.Training | FlatModels.PageEntityModels.Slide) {
    if (this.passRequired()) {
      this.trainingViewerFacade.requestSetShowFeedback(this.trainingResult, trainingPage.slideId.toString(), this.question, this.isCorrect())
    } else {
      this.showRequired = true;
      this.showRequiredChange.emit(this.showRequired);
    }
  }

  /**
   * Toggle Show Explanation
   */
  public toggleShowExplanation() {
    this.trainingViewerFacade.requestToggleShowExplanation(this.trainingResult);
  }
}
