import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { KeymapStyleEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * Keymap Style Entity Token
 */
export const KEYMAP_STYLE_ENTITY_TOKEN = new StateToken<KeymapStyleEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.KEYMAP_STYLE);

/**
 * Keymap Style Entity State Model
 */
export type KeymapStyleEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.KeymapStyleEntityModels.KeymapStyle>;

/**
 * Keymap Style Entity State
 */
@State<KeymapStyleEntityStateModel>({
  name: KEYMAP_STYLE_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class KeymapStyleEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: KeymapStyleEntityStateModel
   */
  @Selector([KeymapStyleEntityState])
  public static typedEntities$(state: KeymapStyleEntityStateModel): { [id: string]: FlatModels.KeymapStyleEntityModels.KeymapStyle } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.KeymapStyleEntityModels.KeymapStyle }
   */
  @Selector([KeymapStyleEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.KeymapStyleEntityModels.KeymapStyle }): FlatModels.KeymapStyleEntityModels.KeymapStyle[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.KeymapStyleEntityModels.KeymapStyle }
   */
  @Selector([KeymapStyleEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.KeymapStyleEntityModels.KeymapStyle }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.KeymapStyleEntityModels.KeymapStyle[]
   */
  @Selector([KeymapStyleEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.KeymapStyleEntityModels.KeymapStyle[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<KeymapStyleEntityStateModel>
   * @param patchState: StateContext<KeymapStyleEntityStateModel>
   * @param entityQueryResult: KeymapStyleEntityActions.GetEntitySuccess
   */
  @Action(KeymapStyleEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<KeymapStyleEntityStateModel>,
                          {entityQueryResult}: KeymapStyleEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<KeymapStyleEntityStateModel>
   * @param patchState: StateContext<KeymapStyleEntityStateModel>
   * @param entityQueryResults: KeymapStyleEntityActions.GetEntitiesSuccess
   */
  @Action(KeymapStyleEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<KeymapStyleEntityStateModel>,
                            {entityQueryResults}: KeymapStyleEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<KeymapStyleEntityStateModel>
   * @param patchState: StateContext<KeymapStyleEntityStateModel>
   * @param entity: KeymapStyleEntityActions.AddEntity
   */
  @Action(KeymapStyleEntityActions.AddEntity)
  @Undoable(KeymapStyleEntityActions.AddEntity, KEYMAP_STYLE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<KeymapStyleEntityStateModel>,
                   {entity}: KeymapStyleEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<KeymapStyleEntityStateModel>
   * @param patchState: StateContext<KeymapStyleEntityStateModel>
   * @param entity: KeymapStyleEntityActions.UpdateEntity
   */
  @Action(KeymapStyleEntityActions.UpdateEntity)
  @Undoable(KeymapStyleEntityActions.UpdateEntity, KEYMAP_STYLE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<KeymapStyleEntityStateModel>,
                      {entity}: KeymapStyleEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<KeymapStyleEntityStateModel>
   * @param patchState: StateContext<KeymapStyleEntityStateModel>
   * @param entity: KeymapStyleEntityActions.RemoveEntity
   */
  @Action(KeymapStyleEntityActions.RemoveEntity)
  @Undoable(KeymapStyleEntityActions.RemoveEntity, KEYMAP_STYLE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<KeymapStyleEntityStateModel>,
                      {entity}: KeymapStyleEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }
  //</editor-fold>
}
