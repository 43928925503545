<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="{selectedSlides: (slideViewerFacade.currentSelectedSlideNodesFiltered$ | async) | sortBy_shared:'asc':'position', selectedSlideNodes: (slideViewerFacade.currentSelectedSlideNodes$ | async), selectedSlideIndex: (slideViewerFacade.currentSelectedSlideIndex$ | async)} as slideData; else spinner">
  <div class="mdsf-slide-control">
    <ng-container *ngIf="slideData.selectedSlides.length > 0">
      <!-- Position -->
      <span class="position">
        <input class="mdsf-slide-control_input"
               type="number"
               min="1"
               [max]="slideData.selectedSlides.length"
               [value]="slideData.selectedSlideIndex + 1"
               (change)="slideViewerFacade.requestGoToSlideByIndex($event.target, slideData.selectedSlideIndex, slideData.selectedSlides, slideData.selectedSlideNodes, route.snapshot.queryParams)">
        / {{ slideData.selectedSlides.length }}
      </span>

      <!-- Progess Bar -->
      <div class="mdsf-progress">
        <ng-container *ngFor="let slide of slideData.selectedSlides; let i = index">
          <div *ngIf="(slideViewerFacade.page$(slide.page) | async) as page"
               class="mdsf-progress-point available"
               [class.current]="slideData.selectedSlideIndex === i"
               [class.past]="slideData.selectedSlideIndex > i"
               [routerLink]="(slideViewerFacade.slideUrlByNode$(slide) | async)"
               [queryParams]="route.snapshot.queryParams"
               placement="top"
               [ngbTooltip]="(i + 1) + ': ' + page.title">
            <div class="mdsf-progress-point-marker"></div>
          </div>
        </ng-container>
        <div class="mdsf-progress-wrap">
          <div class="mdsf-progress-bar"
            [class.transition-easeout]="true"
            [style.transform]="'scaleX(' + (slideViewerFacade.currentSelectedSlidePercentage$ | async) + ')'">
          </div>
        </div>
      </div>

      <!-- Previous -->
      <div class="button mdsf-btn"
           *ngIf="(slideViewerFacade.previousSlideUrl$ | async) as previousUrl"
           [routerLink]="['/' + previousUrl]"
           [queryParams]="route.snapshot.queryParams">
        <span class="fa fa-chevron-left"></span>
      </div>

      <!-- Next -->
      <div class="button mdsf-btn"
           *ngIf="(slideViewerFacade.nextSlideUrl$ | async) as nextUrl"
           [routerLink]="['/' + nextUrl]"
           [queryParams]="route.snapshot.queryParams">
        <span class="fa fa-chevron-right"></span>
      </div>
    </ng-container>
  </div>
  <div class="mdsf-slide-button-group d-none d-md-inline-block">
    <div class="button mdsf-btn" (click)="feedbackControlService.openFeedbackModal()">
      <a>FEEDBACK</a>
    </div>
  </div>
</ng-container>

