import { Component } from '@angular/core';
import { FooterLinkViewerModels, PageEntityModels } from '@medsurf/flat-models';
import { NavigationControlFacade, NodeSharedFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss']
})
export class ChaptersComponent {
  /**
   * TODO move to route data
   * Members
   */
  public footerLinks: FooterLinkViewerModels.FooterLink[] = [
    {title: 'DATENSCHUTZERKLÄRUNG', url: 'privacy'},
    {title: 'IMPRESSUM', url: 'impressum'}
  ];

  /**
   * View
   */
  public pageType = PageEntityModels.PageType;

  /**
   * Constructor
   *
   * @param navigationControlFacade: NavigationControlFacade
   * @param nodeSharedFacade: NodeSharedFacade
   */
  constructor(public navigationControlFacade: NavigationControlFacade,
              public nodeSharedFacade: NodeSharedFacade) {
  }
}
