import paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { MarkerElement } from '../../markerElement';
import { Marker } from '../marker';
import { Freeform } from '../../freeform/freeform';

/**
 * Label
 */
export class Label extends MarkerElement<ImageViewerModels.LabelModel, Marker | Freeform> {
  /**
   * Members
   */
  private _label: any;
  private _background: any;
  private _symbol: any;
  private _originalPoint: any;
  private _showSymbol: any;
  private _rectangle: any;

  /**
   * Constructor
   * @param _model
   * @param _format
   * @param marker
   * @param _type
   */
  public constructor(_model: ImageViewerModels.LabelModel,
                     private _format,
                     marker: Marker | Freeform,
                     private _type?) {
    super(_model, marker);
    this._element = null;
    this._label = null;
    this._background = null;
    this._symbol = null;
    this._originalPoint = null;
    this._init();
    this._showSymbol = null;
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this._element = new paper.Group();
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    this._element.removeChildren();
    this._drawLabel();
    if (this._showSymbol) {
      this._label.leading = this._format.leading.tooltip * this._label.fontSize;
      this._drawSymbol();
    }

    /**
     * Setup background object
     */
    if (
      this.marker.model.label &&
      (typeof (this.marker.model.label.background) === 'boolean' || typeof (this.marker.model.label.background) === 'undefined')
    ) {
      if (this.marker.model.label.background) {
        this.marker.model.label.background = {opacity: 0.4};
      } else {
        this.marker.model.label.background = {opacity: 0};
      }
    }

    if (this._type !== 'tooltip' && (this.marker.model.label.background.opacity !== 0 || this.marker.model.label.border)) {
      this._drawBackground();
    }
    this._label.bringToFront();
  }

  /**
   * Draw Label
   *
   * @protected
   */
  protected _drawLabel() {
    if (this._showSymbol) {
      this._label = new paper.PointText(
        new paper.Point(
          this.getZoomedValue(0),
          this.getZoomedValue((this._marker.model.label.fontSize || this._format.fontSize))
        )
      );
    } else {
      this._label = new paper.PointText(
        new paper.Point(0, 0)
      );
    }
    this._label.fillColor = this._type === 'tooltip' ? '#000000' : (this._format.fontColor || this._marker.model.label.color);
    this._label.fontFamily = this._format.fontFamily;
    this._label.fontWeight = this._format.fontWeight;
    this._label.fontSize = (this._marker.model.label.fontSize || this._format.fontSize) / this._marker._imageScale;

    this._label.leading = this._format.leading.label * this._label.fontSize;
    this._label.content = this.model.text;
    this._label.justification = (this._showSymbol) ? 'left' : this._format.justification;
    this._label.marker = this.marker;
    this._element.addChild(this._label);
  }

  /**
   * Draw Background
   *
   * @protected
   */
  protected _drawBackground() {
    const factor = /* TODO this._marker && this._marker.model.style && this._marker.model.style.strokeWidth ?
      this._marker.model.style.strokeWidth : */
      1;
    const path = new paper.Path(),
      width = this._label.bounds.width + factor * this._format.border.strokeWidth,
      height = this._label.bounds.height + factor * this._format.border.strokeWidth;
    path.add(new paper.Point(0, 0));
    path.add(new paper.Point(width, 0));
    path.add(new paper.Point(width, height));
    path.add(new paper.Point(0, height));
    this._rectangle = this._roundCorners(
      path,
      null, /*Zoom.getZoomedValue(this.marker.localPaper, this._format.border.cornerRadius)*/
    );
    this._rectangle.position.x = this._label.bounds.x + (this._label.bounds.width / 2);
    this._rectangle.position.y = this._label.bounds.y + (this._label.bounds.height / 2);
    if (this.marker.model.label.background.opacity && this.marker.model.label.background.opacity !== 0) {
      this._rectangle.fillColor = this.marker.model.label.background.opacity !== 0 ?
        /* TODO
        (this.marker.model.label.backgroundInBorderColor ?
          this.marker.model.label.strokeColor || this.marker.model.label.background.color || '#ffffff' :
         */
          this.marker.model.label.background.color || '#ffffff' :
        undefined;
      this._rectangle.fillColor.alpha = this.marker.model.label.background.opacity;
    }
    this._rectangle.strokeColor = this.marker.model.label.borderColor || this._format.fontColor;
    this._rectangle.strokeWidth = (this.marker.model.label.border) ?
      (this.marker.model.label.borderWidth || this._format.border.strokeWidth) :
      0;
    this._rectangle.dashArray = /* TODO this.marker.model.label.dash ?
      this.marker.model.label.dash
        .split(',')
        .map((dash) => dash * (this.marker && this.marker.model.label && this.marker.model.label.strokeWidth ?
            this.marker.model.label.strokeWidth :
            1
        )) :
      */
      [1, 0];
    this._element.addChild(this._rectangle);
  }

  /**
   * Draw Symbol
   *
   * @protected
   */
  protected _drawSymbol() {
    const width = this._label.bounds.width + 10,
      height = this._label.bounds.height + 5,
      // TODO remove rect = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(width, height)),
      arrowWidth = this.getArrowWidth(),
      arrowHeight = this.getArrowHeight();
    this._symbol = new paper.Path();
    this._symbol.add(new paper.Point(0, 0));
    this._symbol.add(new paper.Point(width, 0));
    this._symbol.add(new paper.Point(width, height));
    this._symbol.add(new paper.Point(((width / 2) + (arrowWidth / 2)), height));
    this._symbol.add(new paper.Point((width / 2), height + arrowHeight));
    this._symbol.add(new paper.Point(((width / 2) - (arrowWidth / 2)), height));
    this._symbol.add(new paper.Point(0, height));
    this._symbol = this._roundCorners(
      this._symbol,
      2,
      [3, 4, 5]);
    this._symbol.strokeColor = '#7C7C7C';
    this._symbol.fillColor = '#FFFFFF';
    this._symbol.strokeWidth = this.getZoomedValue(this._format.strokeWidth);
    this._symbol.closed = true;
    this._element.addChild(this._symbol);
  }

  /**
   * Calculate Font Size
   *
   * @param size: number
   */
  public calculateFontSize(size: number) {
    const minZoom = this.marker.container.minZoom * 100;
    return Math.floor(size * (100 / minZoom));
  }

  /**
   * Arrange
   */
  public arrange() {
    this.draw();
  }

  /**
   * Setter useSymbol
   *
   * @param value
   */
  public set useSymbol(value) {
    if (this._showSymbol === value) {
      return;
    }
    this._showSymbol = value;
    this.draw();
  }

  /**
   * Set Scale
   */
  public setScale() {
    this.draw();
  }

  /**
   * Get Zoomed Value$
   *
   * @param value
   */
  public getZoomedValue(value) {
    return value / this.marker.localPaper.view.zoom;
  }

  /**
   * Get Arrow Width
   */
  public getArrowWidth() {
    return 11;
  }

  /**
   * Get Arrow Height
   */
  public getArrowHeight() {
    return 5.5;
  }

  /**
   * Set Position
   *
   * @param x: number
   * @param y: number
   */
  public setPosition(x: number,
                     y: number) {
    const factor = /* this._marker && this._marker.model.style && this._marker.model.style.strokeWidth ?
      this._marker.model.style.strokeWidth : */
      1;

    this._element.pivot = this._element.bounds[this._format.pivot];
    if (!this._rectangle) {
      switch (this._format.pivot) {
        case 'bottomCenter':
          y -= factor * 5;
          break;

        case 'leftCenter':
          x += factor * (1 + 5);
          break;

        case 'topCenter':
          y += factor * 5;
          break;

        case 'rightCenter':
          x -= factor * (2 + 5);
          break;
      }
    }
    this._element.position = new paper.Point(x, y);
  }

  /**
   * Resets the position of this Label object so that the end of the arrow points to the parameter coordinates
   *
   * @param {number} x - x-coordinate of the arrows pointy end
   * @param {number} y - y-coordinate
   */
  public setArrowPosition(x, y) {
    this.element.position = new paper.Point(x, y - this.getArrowHeight() - (this._label.bounds.height / 2));
  }

  /**
   * Getter useSymbol
   */
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  public get useSymbol() {
    return this._showSymbol;
  }

  /**
   * Getter originalPoint
   */
  public get originalPoint() {
    return this._originalPoint;
  }

  /**
   * Getter label
   */
  public get label() {
    return this._label;
  }

  /**
   * Clean Up
   */
  public cleanUp() {
    this.element.removeChildren();
    super.cleanUp();
  }
}
