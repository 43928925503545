import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Alert, AlertType } from '@medsurf/models';
import { AlertState } from '@medsurf/state';
import { DismissAlert } from '@medsurf/actions';
import { AlertService } from '@medsurf/services';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medsurf-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @Select(AlertState.alerts)
  public alerts$: Observable<Alert[]>;

  constructor(
    private store: Store,
    private alertService: AlertService,
    public translate: TranslateService
  ) {}

  public dismiss(alert) {
    this.store.dispatch(new DismissAlert(alert));
  }

  public getAlertClassName(alertType: AlertType) {
    return this.alertService.getAlertClassName(alertType);
  }
}
