import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ElearningsComponent } from './elearnings.component';
import { CarouselComponent } from './carousel/carousel.component';
import { MedsurfViewerCommonModule } from '../common/medsurf-viewer-common.module';
import { RouterModule } from '@angular/router';
import { ElearningsRoutingModule } from './elearnings-routing.module';
import { MedsurfLibModule } from '@medsurf/module';

@NgModule({
  declarations: [
    ElearningsComponent,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    MedsurfLibModule,
    MedsurfViewerCommonModule,
    RouterModule,
    ElearningsRoutingModule,
    NgOptimizedImage,
  ]
})
export class ElearningsModule { }
