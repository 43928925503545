import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { UrlHelperModels } from '@medsurf/flat-models';
import { FeedbackControlService } from '../../internal';

@Injectable({
  providedIn: 'root',
})
export class NavigationControlService {
  /**
   * Members
   */
  private _showClose: any;
  private _showSearch: any;
  private _isPrinting: any;

  /**
   * Constructor
   *
   * @param router: Router
   * @param route: ActivatedRoute
   * @param feedbackControlService: FeedbackControlService
   */
  public constructor(protected router: Router,
                     protected route: ActivatedRoute,
                     private feedbackControlService: FeedbackControlService) {
  }

  /**
   * Getter Is Printing
   */
  get isPrinting(): any {
    return this._isPrinting;
  }

  /**
   * Getter Show Close
   */
  get showClose(): any {
    return this._showClose;
  }

  /**
   * Setter Show Close
   *
   * @param value: any
   */
  set showClose(value: any) {
    this._showClose = value;
  }

  /**
   * Getter Show Search
   */
  get showSearch(): any {
    return this._showSearch;
  }

  /**
   * Setter Show Search
   *
   * @param value: any
   */
  set showSearch(value: any) {
    this._showSearch = value;
  }

  /**
   * Go Back
   */
  public static goBack() {
    // if (this.backState) {
    //   this.router.navigate([this.backState.state, this.backState.param]);
    // }
    history.back();
  }

  /**
   * Get Semantic String
   *
   * @param value: any
   */
  public static getSemanticString(value: any) {
    if (!value) {
      return null;
    }
    return UrlHelperModels.Url.getSemanticString(value);
  }

  /**
   * Handle Error
   */
  public async handleError() {
    await this.router.navigate(['404']);
  }

  /**
   * Go Home
   */
  public async goHome() {
    await this.router.navigate(['']);
  }

  /**
   * Go Subject
   */
  public goSubject() {
    this.route.paramMap.pipe(
      switchMap(async (params: ParamMap) =>
        await this.router.navigate(['',
          params.get('elearningSlug'),
          params.get('subjectSlug')]))
    );
  }

  /**
   * Select Page
   *
   * @param id: string
   */
  public async selectPage(id: string) {
    switch (id) {
      case 'quiz-histologie':
        this.route.paramMap.pipe(
          switchMap(async (params: ParamMap) =>
            await this.router.navigate(['quiz-selection', {
              elearningSlug: params.get('elearningSlug'),
              subjectSlug: params.get('subjectSlug'),
              chapterSlug: params.get('chapterSlug')
            }]))
        );
        break;

      default:
        await this.router.navigate([id]);
        break;
    }
  }

  /**
   * Select Link
   *
   * @param event: any
   */
  public selectLink(event: any) {
    if (event.params.pageSlug === 'feedback') {
      this.feedbackControlService.openFeedbackModal();
    } else {
      const navigation = [event.path];
      this.router.navigate(navigation.concat(Object.values(event.params)));
    }
  }
}