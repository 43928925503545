import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { NodeSharedFacade } from '@medsurf/flat-facades';

@Injectable({
  providedIn: "root"
})
export class NodeSharedResolver implements Resolve<void> {
  /**
   * Constructor
   *
   * @param nodeSharedFacade: NodeSharedFacade
   */
  constructor(protected nodeSharedFacade: NodeSharedFacade) {
  }

  /**
   * Resolve
   */
  resolve(route: ActivatedRouteSnapshot) {
    const idSlug = route.paramMap.get('idSlug');
    if (idSlug) {
      return this.nodeSharedFacade.requestNodeById(idSlug);
    } else {
      return this.nodeSharedFacade.requestNodesByCurrentRoute();
    }
  }
}