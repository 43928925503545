import { Component, Input } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { MenuViewerFacade, SlideViewerFacade } from '@medsurf/flat-facades';
import { MediaControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-slide-header',
  templateUrl: './slide-header.component.html',
  styleUrls: ['./slide-header.component.scss']
})
export class SlideHeaderComponent {
  /**
   * Inputs
   */
  @Input() public page: FlatModels.PageEntityModels.Slide;

  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   * @param mediaControlService: MediaControlService
   */
  constructor(public slideViewerFacade: SlideViewerFacade,
              public menuViewerFacade: MenuViewerFacade,
              public mediaControlService: MediaControlService) {
  }

  /**
   * Set Theme Color
   *
   * @param value: boolean
   * @param themeColor: string
   */
  public setThemeColor(value: boolean, themeColor: string): string {
    return (value) ? themeColor : '';
  }
}
