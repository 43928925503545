import { Injectable } from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MessageService } from '@medsurf/services';
import { Role, User } from '@medsurf/models';
import { Login, LoginSuccess, Logout, LogoutSuccess, Me, MeSuccess, UpdateToken, UpdateTokenSuccess, LoginRequest,
  LogoutRequest, MeRequest, UpdateTokenRequest, ClearAlerts } from '@medsurf/actions';
/*
import {
  SetIsExpired
} from '../actions/ws.actions';
*/

/**
 * Auth state model
 */
export interface AuthStateModel {
  token: string | null;
  tokenLifetime: number | null;
  expiresIn: number | null;
  isUpdating: boolean;
  user: User | null;
  isAdmin: boolean;
}

/**
 * Auth State
 */
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    tokenLifetime: null,
    expiresIn: null,
    isUpdating: false,
    user: null,
    isAdmin: false
  }
})
@Injectable()
export class AuthState {
  /**
   * Constructor
   * @param store: Store
   * @param messageService: MessageService
   */
  public constructor(protected store: Store,
                     protected messageService: MessageService) {}

  /**
   * Selector token
   * @param state: AuthStateModel
   */
  @Selector()
  public static token(state: AuthStateModel): string | null {
    return state.token;
  }

  /**
   * Selector isAuthenticated
   * @param state: AuthStateModel
   */
  @Selector()
  public static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  /**
   * Selector user
   * @param state: AuthStateModel
   */
  @Selector()
  public static user(state: AuthStateModel): User | null {
    return state.user;
  }

  /**
   * Selector isAdmin
   * @param state: AuthStateModel
   */
  @Selector()
  public static isAdmin(state: AuthStateModel): boolean {
    return state.user && state.user.role === Role.ADMIN;
  }

  /**
   * Login
   * @param state: StateContext<AuthStateModel>
   * @param username: AuthLogin
   * @param password: AuthLogin
   */
  @Action(Login)
  public login({getState}: StateContext<AuthStateModel>, {username, password}: Login) {
    const state = getState();
    return this.messageService.sendMessage(state.token, new LoginRequest(username, password));
  }

  /**
   * Login success
   * @param setState: StateContext<AuthStateModel>
   * @param token: string
   * @param tokenLifetime: number
   * @param expiresIn: number
   * @param user: User
   */
  @Action(LoginSuccess)
  public loginSuccess({setState}: StateContext<AuthStateModel>, {token, tokenLifetime, expiresIn, user}: LoginSuccess) {
    this.store.dispatch(new ClearAlerts());
    setState(
      patch({
        token,
        tokenLifetime,
        expiresIn,
        user
      })
    );
  }

  /**
   * Logout
   * @param getState: StateContext<AuthStateModel>
   */
  @Action(Logout)
  public logout({getState}: StateContext<AuthStateModel>) {
    const state = getState();
    return this.messageService.sendMessage(state.token, new LogoutRequest());
  }

  /**
   * Logout success
   * @param setState: StateContext<AuthStateModel>
   * @param dispatch: StateContext<AuthStateModel>
   */
  @Action(LogoutSuccess)
  public logoutSuccess({setState, dispatch}: StateContext<AuthStateModel>) {
    setState(
      patch({
        token: null,
        tokenLifetime: null,
        expiresIn: null,
        user: null,
      })
    );
  }

  /**
   * Me
   * @param getState: StateContext<AuthStateModel>
   */
  @Action(Me)
  public me({getState}: StateContext<AuthStateModel>) {
    const state = getState();
    return this.messageService.sendMessage(state.token, new MeRequest());
  }

  /**
   * Me success
   * @param setState: StateContext<AuthStateModel>
   * @param user: user
   */
  @Action(MeSuccess)
  public meSuccess({setState}: StateContext<AuthStateModel>, {user}: MeSuccess) {
    setState(
      patch({
        user
      })
    );
  }

  /**
   * Update Token
   * @param getState: StateContext<AuthStateModel>
   * @param patchState: StateContext<AuthStateModel>
   */
  @Action(UpdateToken)
  public updateToken({getState, patchState}: StateContext<AuthStateModel>, {}: UpdateToken) {
    const state = getState();
    const isExpired = (state.expiresIn - state.tokenLifetime) < Math.floor(new Date().getTime() / 1000);
    if (state.token && isExpired) {
      if (!state.isUpdating) {
        patchState({
          isUpdating: true
        });
        return this.messageService.sendMessage(state.token, new UpdateTokenRequest());
      }
    }
  }

  /**
   * Update Token success
   * @param setState: StateContext<AuthStateModel>
   * @param patchState: StateContext<AuthStateModel>
   * @param token: UpdateTokenSuccess
   * @param expiresIn: number
   */
  @Action(UpdateTokenSuccess)
  public updateTokenSuccess({setState, patchState}: StateContext<AuthStateModel>, {token, expiresIn}: UpdateTokenSuccess) {
    patchState({
      token,
      expiresIn,
      isUpdating: false
    });
  }
}
