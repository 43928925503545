import { Injectable } from '@angular/core';
import { Action, Actions, ofActionDispatched, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { AnnotationGroupEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import { AnnotationEntityState, AnnotationLabelEntityState, PageEntityState } from '../../internal';
import { patch } from '@ngxs/store/operators';
import { debounceTime } from 'rxjs';

/**
 * Annotation Group Entity Token
 */
export const ANNOTATION_GROUP_ENTITY_TOKEN = new StateToken<AnnotationGroupEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.ANNOTATION_GROUP);

/**
 * Annotation Group Entity State Model
 */
export interface AnnotationGroupEntityStateModel extends FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.GroupEntityModels.Group> {
  groupId: string | null;
  areaGroupId: string | null;
}

/**
 * Annotation Group Entity State
 */
@State<AnnotationGroupEntityStateModel>({
  name: ANNOTATION_GROUP_ENTITY_TOKEN,
  defaults: {
    groupId: null,
    areaGroupId: null,
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class AnnotationGroupEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector groupId$
   *
   * @param state: AnnotationGroupEntityStateModel
   */
  @Selector([AnnotationGroupEntityState])
  public static groupId$(state: AnnotationGroupEntityStateModel): string | null {
    return state.groupId;
  }

  /**
   * Selector areaGroupId$
   *
   * @param state: AnnotationGroupEntityStateModel
   */
  @Selector([AnnotationGroupEntityState])
  public static areaGroupId$(state: AnnotationGroupEntityStateModel): string | null {
    return state.areaGroupId;
  }
  
  /**
   * Selector typedEntities$
   *
   * @param state: AnnotationGroupEntityStateModel
   */
  @Selector([AnnotationGroupEntityState])
  public static typedEntities$(state: AnnotationGroupEntityStateModel): { [id: string]: FlatModels.GroupEntityModels.Group } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.GroupEntityModels.Group }
   */
  @Selector([AnnotationGroupEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.GroupEntityModels.Group }): FlatModels.GroupEntityModels.Group[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.GroupEntityModels.Group }
   */
  @Selector([AnnotationGroupEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.GroupEntityModels.Group }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.GroupEntityModels.Group[]
   */
  @Selector([AnnotationGroupEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.GroupEntityModels.Group[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  /**
   * Selector currentSelectedSlideGroups$
   *
   * @param entities: FlatModels.LayerEntityModels.Layer[]
   * @param currentSelectedSlidePage: FlatModels.SlideEntityModels.Slide | null
   */
  @Selector([
    AnnotationGroupEntityState.entities$,
    PageEntityState.currentSelectedSlidePage$
  ])
  public static currentSelectedSlideGroups$(entities: FlatModels.GroupEntityModels.Group[],
                                            currentSelectedSlidePage: FlatModels.PageEntityModels.Slide | null) {
    // TODO ATTENTION: Group Description Comes From First Annotation
    return currentSelectedSlidePage && currentSelectedSlidePage.groups && Array.isArray(entities) && entities.filter(e => (currentSelectedSlidePage.groups as unknown as string[]).includes(e.id)) || [];
  }

  /**
   * Selector currentSelectedSlideAreaGroups$
   *
   * @param currentSelectedSlideAnnotations: FlatModels.AnnotationEntityModels.Annotation[]
   * @param annotationLabels: FlatModels.AnnotationLabelEntityModels.AnnotationLabel[]
   */
  @Selector([
    AnnotationEntityState.currentSelectedSlideAnnotations$,
    AnnotationLabelEntityState.entities$,
    AnnotationGroupEntityState.entities$
  ])
  public static currentSelectedSlideAreaGroups$(currentSelectedSlideAnnotations: FlatModels.AnnotationEntityModels.Annotation[],
                                                annotationLabels: FlatModels.AnnotationLabelEntityModels.AnnotationLabel[],
                                                groups: FlatModels.GroupEntityModels.Group[]) {
    // Get Interactive Areas
    const interactiveAreas = currentSelectedSlideAnnotations.filter(a => a.type === FlatModels.AnnotationEntityModels.AnnotationType.FREE_FORM
      && (a as FlatModels.AnnotationEntityModels.FreeForm).shape === FlatModels.AnnotationEntityModels.Shape.INTERACTIVE_AREA);

    // Check Interactive Areas
    if (interactiveAreas.length > 0) {
      // Temporary Groups
      const temporaryGroupsMap = new Map<string, FlatModels.GroupEntityModels.Group>();

      interactiveAreas.forEach(ia => {
        const label = annotationLabels.find(al => al.id === ia.label as unknown as string);
        if ((ia.groups?.length || 0) === 0) {
          const group: FlatModels.GroupEntityModels.Group = {
            id: ia.id,
            annotations: [ia.id as unknown as FlatModels.AnnotationEntityModels.Annotation],
            name: label?.text || '',
            description: label?.description || '',
          };
          temporaryGroupsMap.set(group.id, group);
        } else {
          ia.groups?.forEach((g) => {
            const groupId = g as unknown as string;
            const tempGroup = temporaryGroupsMap.get(groupId);
            if (tempGroup) {
              (tempGroup.annotations as unknown as string[]).push(ia.id);
              if ((tempGroup.description?.length || 0) < (label?.description?.length || 0)) {
                tempGroup.description = label?.description || '';
              }
              temporaryGroupsMap.set(groupId, tempGroup);
            } else {
              const realGroup = groups.find(g => g.id === groupId);
              const group: FlatModels.GroupEntityModels.Group = {
                id: ia.id,
                annotations: [ia.id as unknown as FlatModels.AnnotationEntityModels.Annotation],
                name: realGroup?.name || label?.text || '',
                description: label?.description || '',
              };
              temporaryGroupsMap.set(groupId, group);
            }
          })
        }
      });

      const dedupMap = new Map<string, FlatModels.GroupEntityModels.Group>();
      Array.from(temporaryGroupsMap).forEach((pair) => {
        pair[1].annotations?.sort();
        const ukey = pair[1].annotations?.join(',') || pair[0];
        dedupMap.set(ukey, pair[1]);
      })
      
      return Array.from(dedupMap).map(pair => pair[1]);
    }

    return [];
  }

  /**
   * Selector currentSelectedSlideAreaGroupsMap$
   *
   * @param currentSelectedSlideAreaGroups: FlatModels.GroupEntityModels.Group[]
   */
  @Selector([
    AnnotationGroupEntityState.currentSelectedSlideAreaGroups$,
  ])
  public static currentSelectedSlideAreaGroupsMap$(areaGroups: FlatModels.GroupEntityModels.Group[]) {
    const map = new Map<string, FlatModels.GroupEntityModels.Group>();
    areaGroups.forEach((areaGroup) => {
      areaGroup.annotations?.forEach((annotationId) => {
        map.set(annotationId as unknown as string, areaGroup);
      })
    })
    return map;
  }

  /**
   * Selector currentSelectedSlideGroup$
   *
   * @param currentSelectedSlideGroups: FlatModels.GroupEntityModels.Group[]
   * @param groupId: string | null
   */
  @Selector([
    AnnotationGroupEntityState.currentSelectedSlideGroups$,
    AnnotationGroupEntityState.groupId$
  ])
  public static currentSelectedSlideGroup$(currentSelectedSlideGroups: FlatModels.GroupEntityModels.Group[],
                                           groupId: string | null) {
    return groupId !== null && Array.isArray(currentSelectedSlideGroups) ? currentSelectedSlideGroups.find(sg => sg.id === groupId) || null : null;
  }

  /**
   * Selector currentSelectedSlideAreaGroup$
   *
   * @param currentSelectedSlideAreaGroupsMap: Map<string, FlatModels.GroupEntityModels.Group>,
   * @param areaId: string | null
   */
  @Selector([
    AnnotationGroupEntityState.currentSelectedSlideAreaGroupsMap$,
    AnnotationGroupEntityState.areaGroupId$
  ])
  public static currentSelectedSlideAreaGroup$(currentSelectedSlideAreaGroupsMap: Map<string, FlatModels.GroupEntityModels.Group>,
                                           areaGroupId: string | null) {
    return areaGroupId !== null ? (currentSelectedSlideAreaGroupsMap?.get(areaGroupId) || null) : null;
  }


  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   * @param actions$: Actions
   */
  public constructor(store: Store,
                     actions$: Actions) {
    actions$.pipe(
      ofActionDispatched(AnnotationGroupEntityActions.DebounceSetGroupId),
      debounceTime(20)
    ).subscribe(({groupId}: AnnotationGroupEntityActions.DebounceSetGroupId) => {
      store.dispatch(new AnnotationGroupEntityActions.SetGroupId(groupId));
    });
    actions$.pipe(
      ofActionDispatched(AnnotationGroupEntityActions.DebounceSetAreaGroupId),
      debounceTime(20)
    ).subscribe(({areaGroupId}: AnnotationGroupEntityActions.DebounceSetAreaGroupId) => {
      store.dispatch(new AnnotationGroupEntityActions.SetAreaGroupId(areaGroupId));
    });
  }

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<AnnotationGroupEntityStateModel>
   * @param patchState: StateContext<AnnotationGroupEntityStateModel>
   * @param entityQueryResult: AnnotationGroupEntityActions.GetEntitySuccess
   */
  @Action(AnnotationGroupEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<AnnotationGroupEntityStateModel>,
                          {entityQueryResult}: AnnotationGroupEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<AnnotationGroupEntityStateModel>
   * @param patchState: StateContext<AnnotationGroupEntityStateModel>
   * @param entityQueryResults: AnnotationGroupEntityActions.GetEntitiesSuccess
   */
  @Action(AnnotationGroupEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<AnnotationGroupEntityStateModel>,
                            {entityQueryResults}: AnnotationGroupEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<AnnotationGroupEntityStateModel>
   * @param patchState: StateContext<AnnotationGroupEntityStateModel>
   * @param entity: AnnotationGroupEntityActions.AddEntity
   */
  @Action(AnnotationGroupEntityActions.AddEntity)
  @Undoable(AnnotationGroupEntityActions.AddEntity, ANNOTATION_GROUP_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<AnnotationGroupEntityStateModel>,
                   {entity}: AnnotationGroupEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<AnnotationGroupEntityStateModel>
   * @param patchState: StateContext<AnnotationGroupEntityStateModel>
   * @param entity: AnnotationGroupEntityActions.UpdateEntity
   */
  @Action(AnnotationGroupEntityActions.UpdateEntity)
  @Undoable(AnnotationGroupEntityActions.UpdateEntity, ANNOTATION_GROUP_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<AnnotationGroupEntityStateModel>,
                      {entity}: AnnotationGroupEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<AnnotationGroupEntityStateModel>
   * @param patchState: StateContext<AnnotationGroupEntityStateModel>
   * @param entity: AnnotationGroupEntityActions.RemoveEntity
   */
  @Action(AnnotationGroupEntityActions.RemoveEntity)
  @Undoable(AnnotationGroupEntityActions.RemoveEntity, ANNOTATION_GROUP_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<AnnotationGroupEntityStateModel>,
                      {entity}: AnnotationGroupEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  /**
   * Set Group Id
   *
   * @param setState: StateContext<AnnotationGroupEntityStateModel>
   * @param groupId: AnnotationGroupEntityActions.SetGroupId
   */
  @Action(AnnotationGroupEntityActions.SetGroupId)
  public setGroupId({setState}: StateContext<AnnotationGroupEntityStateModel>,
                    {groupId}: AnnotationGroupEntityActions.SetGroupId) {
    setState(
      patch({
        groupId: existing => (existing === groupId) ? null : groupId
      })
    );
  }

  /**
   * Set Area Group Id
   *
   * @param setState: StateContext<AnnotationGroupEntityStateModel>
   * @param areaGroupId: AnnotationGroupEntityActions.SetAreaGroupId
   */
  @Action(AnnotationGroupEntityActions.SetAreaGroupId)
  public setAreaGroupId({setState}: StateContext<AnnotationGroupEntityStateModel>,
                    {areaGroupId}: AnnotationGroupEntityActions.SetAreaGroupId) {
    setState(
      patch({
        areaGroupId: existing => (existing === areaGroupId) ? null : areaGroupId
      })
    );
  }

  //</editor-fold>
}
