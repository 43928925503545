import { Injectable } from '@angular/core';
import { BaseChainBaseGuard } from '../../internal';

@Injectable({
  providedIn: 'root'
})
export class UserViewerGuard extends BaseChainBaseGuard {
  /**
   * Can Activate
   */
  public override async canActivate(): Promise<boolean> {
    const expiresIn = this.baseChainGuardFacade.snapshot_expiresIn();
    if (!expiresIn || expiresIn < Math.round(Date.now() / 1000)) {
      this.baseChainGuardFacade.requestLogout();
      return Promise.resolve(true);
    } else if (this.baseChainGuardFacade.snapshot_user() === null) {
      return new Promise<boolean>((resolve) => {
        this.baseChainGuardFacade.ofActionDispatchedMeSuccess.subscribe(() => {
          setTimeout(() => {
            resolve(true);
          }, 200);
        });
        this.baseChainGuardFacade.requestMe();
      });
    } else {
      return Promise.resolve(true);
    }
  }
}
