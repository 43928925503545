import { map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import * as FlatActions from '@medsurf/flat-actions';
import * as FlatStates from '@medsurf/flat-states';
import { MenuViewerFacade } from '../viewer/menu.viewer.facade';
/**
 * Quiz Shared Facade
 */
@Injectable()
export class TrainingViewerFacade {
  //<editor-fold desc="Selectors">

  public currentAvailableSlides$ = this.store.select(FlatStates.TrainingViewerState.currentAvailableSlides$);
  public selectedSlides$ = this.store.select(FlatStates.TrainingViewerState.selectedSlides$);
  public selectedSlideNodes$ = this.store.select(FlatStates.TrainingViewerState.selectedSlideNodes$);
  public currentSlideResultShowFeedback$ = this.store.select(FlatStates.TrainingViewerState.currentSlideResultShowFeedback$);
  public currentSlideResultShowExplanation$ = this.store.select(FlatStates.TrainingViewerState.currentSlideResultShowExplanation$);
  public selectedEmptyPercentageSlideResults$ = this.store.select(FlatStates.TrainingViewerState.selectedEmptyPercentageSlideResults$);
  public selectedCorrectPercentageSlideResults$ = this.store.select(FlatStates.TrainingViewerState.selectedCorrectPercentageSlideResults$);
  public selectedWrongPercentageSlideResults$ = this.store.select(FlatStates.TrainingViewerState.selectedWrongPercentageSlideResults$);
  public selectedSlideIndex$ = this.store.select(FlatStates.TrainingViewerState.selectedSlideIndex$);
  public selectedSlidePercentage$ = this.store.select(FlatStates.TrainingViewerState.selectedSlidePercentage$);
  public previousSlideNode$ = this.store.select(FlatStates.TrainingViewerState.previousSlideNode$);
  public previousSlideUrl$ = this.store.select(FlatStates.TrainingViewerState.previousSlideUrl$);
  public nextSlideNode$ = this.store.select(FlatStates.TrainingViewerState.nextSlideNode$);
  public nextSlideUrl$ = this.store.select(FlatStates.TrainingViewerState.nextSlideUrl$);
  public isTrainingSetupCorrectly$ = this.store.select(FlatStates.TrainingViewerState.isTrainingSetupCorrectly$);
  public trainingSetupIncorrectlyUrl$ = this.store.select(FlatStates.TrainingViewerState.trainingSetupIncorrectlyUrl$);
  public slideSelectionAvailable$ = this.store.select(FlatStates.TrainingViewerState.slideSelectionAvailable$);

  public isTraining$ = this.store.select(FlatStates.NodeEntityState.isTraining$);
  public currentSelectedSlidePage$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlidePage$);
  public currentSelectedSlideQuestions$ = this.store.select(FlatStates.QuestionEntityState.currentSelectedSlideQuestions$);

  //</editor-fold>

  //<editor-fold desc="Lazy Selectors">

  public currentAvailableSlidesByParentId$ = (parentId: any) => this.store.select(FlatStates.TrainingViewerState.currentAvailableSlidesByParentId$).pipe(map(filterFn => filterFn(parentId as unknown as string)));
  public currentAvailableSlidesByChapterId$ = (chapterId: any) => this.store.select(FlatStates.TrainingViewerState.currentAvailableSlidesByChapterId$).pipe(map(filterFn => filterFn(chapterId as unknown as string)));

  public node$ = (id: any) => this.store.select(FlatStates.NodeEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));
  public page$ = (id: any) => this.store.select(FlatStates.PageEntityState.entity$).pipe(map(filterFn => filterFn(id as unknown as string)));
  public choicesByIds$ = (ids: any) => this.store.select(FlatStates.ChoiceEntityState.entitiesByIds$).pipe(map(filterFn => filterFn(ids as unknown as string[])));
  public currentSlideResultByQuestionId$ = (questionId: string) => {
    return this.store.select(FlatStates.TrainingViewerState.currentSlideResultByQuestionId$).pipe(map(filterFn => filterFn(questionId)));
  }
  public slideUrlByNode$ = (slideNode: FlatModels.NodeEntityModels.SlideNode) => this.store.select(FlatStates.TrainingViewerState.slideUrlByNode$).pipe(map(filterFn => filterFn(slideNode)));

  public selectedQuestionIndex$ = (questionId: string) => this.store.select(FlatStates.TrainingViewerState.selectedQuestionIndex$).pipe(map(filterFn => filterFn(questionId)));
  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(private store: Store, private menuViewerFacade: MenuViewerFacade) {
  }

  //<editor-fold desc="Helpers">

  /**
   * Helper List Character
   *
   * @param question: FlatModels.QuestionEntityModels.QuestionEntityType
   * @param index: number
   */
  public helperListCharacter(question: FlatModels.QuestionEntityModels.QuestionEntityType,
                             index: number): string {
    switch (question.numberingType) {
      case FlatModels.QuestionEntityModels.NumberingType.LETTER:
        return String.fromCharCode(65 + index);
      case FlatModels.QuestionEntityModels.NumberingType.ROMAN:
        return String.fromCharCode(8544 + index);
      default:
        return (index + 1).toString();
    }
  }

  //</editor-fold>

  //<editor-fold desc="Requests">

  /**
   * Request Training Nodes By Current Subject
   */
  public requestTrainingNodesByCurrentSubject() {
    return this.store.dispatch(new FlatActions.TrainingViewerActions.GetTrainingNodesByCurrentSubject());
  }

  /**
   * Request Training Nodes By Selected Slides
   */
  public requestTrainingNodesBySelectedSlides() {
    return this.store.dispatch(new FlatActions.TrainingViewerActions.GetTrainingNodesBySelectedSlides());
  }

  /**
   * Request Toggle Training Selection
   *
   * @param slides: FlatModels.TrainingViewerModels.AvailableSlide[]
   * @param event: any
   */
  public requestToggleTrainingSelection(slides: FlatModels.TrainingViewerModels.AvailableSlide[], event: any) {
    return this.store.dispatch(new FlatActions.TrainingViewerActions.ToggleTrainingSelection(slides, event.target.checked));
  }

  /**
   * Request Reset
   */
  public requestReset() {
    return this.store.dispatch(new FlatActions.TrainingViewerActions.Reset());
  }

  /**
   * Request Start Training
   */
  public requestStartTraining() {
    return this.store.dispatch(new FlatActions.TrainingViewerActions.SetupTraining());
  }

  /**
   * Request Start Case
   */
  public requestStartCase() {
    return this.store.dispatch(new FlatActions.TrainingViewerActions.SetupCase());
  }

  /**
   * Request Update Slide Result
   *
   * @param slideResult: FlatModels.TrainingViewerModels.TrainingResultType
   */
  public requestUpdateSlideResult(slideResult: FlatModels.TrainingViewerModels.TrainingResultType) {
    return this.store.dispatch(new FlatActions.TrainingViewerActions.UpdateSlideResult(slideResult));
  }

  /**
   * Request Set Show Feedback
   *
   * @param slideResult: FlatModels.TrainingViewerModels.TrainingResultType
   * @param slideId: string
   * @param question: FlatModels.QuestionEntityModels.QuestionEntityType
   * @param isCorrect: boolean
   */
  public requestSetShowFeedback(slideResult: FlatModels.TrainingViewerModels.TrainingResultType,
                                slideId: string,
                                question: FlatModels.QuestionEntityModels.QuestionEntityType,
                                isCorrect: boolean) {
    this.store.dispatch(new FlatActions.TrainingViewerActions.TrackQuizAnswer(slideId, question, isCorrect));
    return this.store.dispatch(new FlatActions.TrainingViewerActions.SetShowFeedback(slideResult, question));
  }

  /**
   * Request Toggle Show Explanation
   *
   * @param slideResult: FlatModels.TrainingViewerModels.TrainingResultType
   */
  public requestToggleShowExplanation(slideResult: FlatModels.TrainingViewerModels.TrainingResultType) {
    return this.store.dispatch(new FlatActions.TrainingViewerActions.ToggleShowExplanation(slideResult));
  }

  /**
   * Request Go To Start
   */
  public requestGoToStart() {
    return this.store.dispatch(new FlatActions.TrainingViewerActions.GoToStart());
  }

  /**
   * Request Go To Slide By Index
   *
   * @param eventTarget: EventTarget
   * @param currentIndex: number
   * @param selectedSlides: string[]
   * @param selectedSlideNodes: FlatModels.NodeEntityModels.SlideNode[]
   * @param slideResultsAvailable: {[id: string]: boolean}
   * @param queryParams: Params
   */
  public requestGoToSlideByIndex(eventTarget: EventTarget,
                                 currentIndex: number,
                                 selectedSlides: string[],
                                 selectedSlideNodes: FlatModels.NodeEntityModels.SlideNode[],
                                 slideResultsAvailable: {[id: string]: boolean},
                                 queryParams: Params) {
    // Get Index
    const target = eventTarget as HTMLInputElement;
    let index = Number.parseInt(target.value);
    if (Number.isInteger(index)) {
      index -= 1;
      if (index >= 0 && index < selectedSlides.length) {
        const selectedSlideId = selectedSlides[index];
        const selectedSlideNode = selectedSlideNodes.find(sn => sn.id === selectedSlideId);
        if (selectedSlideNode) {
          if (slideResultsAvailable[selectedSlideNode.page as unknown as string]) {
            return this.store.dispatch(new FlatActions.TrainingViewerActions.GoToSlideByNode(selectedSlideNode, queryParams));
          }
        } else {
          throw new Error("no_slide_node_found");
        }
      }
    }

    // Reset Index Value
    target.value = (currentIndex + 1).toString();
    return false;
  }

  //</editor-fold>

}
