import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PageControlActions } from '@medsurf/flat-actions';
import * as FlatStates from '@medsurf/flat-states';

/**
 * Page Shared Facade
 */
@Injectable()
export class PageSharedFacade {
  //<editor-fold desc="Selectors">

  public currentSelectedPagePage$ = this.store.select(FlatStates.PageEntityState.currentSelectedPagePage$);

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(private store: Store) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Page By Current Route
   */
  public requestPageByCurrentRoute() {
    return this.store.dispatch(new PageControlActions.GetPageByCurrentRoute());
  }

    /**
   * Request Page By Id
   */
    public requestPageById(id: string) {
      return this.store.dispatch(new PageControlActions.GetPageById(id));
    }

  //</editor-fold>

}
