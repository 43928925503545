import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const animations: AnimationTriggerMetadata[] = [
  trigger('routeAnimations', [
    transition(':increment', [
      style({ transform: `translateX(0)`, opacity: 1 }),
      animate('100ms ease-in', style({ transform: `translateX(-100px)`, opacity: 0 })),
      animate('500ms linear', style({ transform: `translateX(100px)`, opacity: 0 })),
      animate('100ms ease-out', style({ transform: `translateX(0)`, opacity: 1 })),
    ]),
    transition(':decrement', [
      style({ transform: `translateX(0)`, opacity: 1 }),
      animate('100ms ease-in', style({ transform: `translateX(100px)`, opacity: 0 })),
      animate('500ms linear', style({ transform: `translateX(-100px)`, opacity: 0 })),
      animate('100ms ease-out', style({ transform: `translateX(0)`, opacity: 1 })),
    ])
  ])
];
