import { User } from '@medsurf/models';
import { ValidationError } from 'class-validator';

// <editor-fold desc="Action Types">

/**
 * User action types
 */
export enum UserActionTypes {
  /**
   * Store
   */
  GetUsers = '[STORE | USER] Get Users',
  GetUsersSuccess = '[STORE | USER] Get Users Success',
  GetUserById = '[STORE | USER] Get User by id',
  GetUserSuccess = '[STORE | USER] Get User Success',
  CreateNewUser = '[STORE | USER] Create new User',
  UpdateUser = '[STORE | USER] Update User',
  UpdateUserSuccess = '[STORE | USER] Update User Success',
  UpdateUserInvalid = '[STORE | USER] Update User Invalid',
  AddUser = '[STORE | USER] Add User',
  AddUserSuccess = '[STORE | USER] Add User Success',
  DeleteUser = '[STORE | USER] Delete User',
  DeleteUserSuccess = '[STORE | USER] Delete User Success',
  ResetPassword = '[STORE | USER] Reset Password',
  ResetPasswordSuccess = '[STORE | USER] Reset Password Success',
  ResetPasswordInvalid = '[STORE | USER] Reset Password Invalid',
  SetNewPassword = '[STORE | USER] Set New Password',
  SetNewPasswordSuccess = '[STORE | USER] Set New Password Success',
  SetNewPasswordInvalid = '[STORE | USER] Set New Password Invalid',
  ValidateResetToken = '[STORE | USER] Validate Reset Token',
  ValidateResetTokenSuccess = '[STORE | USER] Validate Reset Token Success',
  /**
   * Request
   */
  GetUsersRequest = '[REQUEST | USER] Get Users',
  GetUserByIdRequest = '[REQUEST | USER] Get User by id',
  UpdateUserRequest = '[REQUEST | USER] Update User',
  AddUserRequest = '[REQUEST | USER] Add User',
  DeleteUserRequest = '[REQUEST | USER] Delete User',
  ResetPasswordRequest = '[REQUEST | USER] Reset Password',
  SetNewPasswordRequest = '[REQUEST | USER] Set New Password',
  ValidateResetTokenRequest = '[STORE | USER] Validate Reset Token'
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Users
 */
export class GetUsers {
  public static readonly type = UserActionTypes.GetUsers;

  public constructor() {
  }
}

/**
 * Get Users Success
 */
export class GetUsersSuccess {
  public static readonly type = UserActionTypes.GetUsersSuccess;

  public constructor(public users: User[]) {
  }
}

/**
 * Get User by id
 */
export class GetUserById {
  public static readonly type = UserActionTypes.GetUserById;

  public constructor(public id: string) {
  }
}

/**
 * Get User Success
 */
export class GetUserSuccess {
  public static readonly type = UserActionTypes.GetUserSuccess;

  public constructor(public user: User) {
  }
}

/**
 * Create New User
 */
export class CreateNewUser {
  public static readonly type = UserActionTypes.CreateNewUser;

  public constructor() {
  }
}

/**
 * Delete user
 */
export class DeleteUser {
  public static readonly type = UserActionTypes.DeleteUser;

  public constructor() {
  }
}

/**
 * Delete user success
 */
export class DeleteUserSuccess {
  public static readonly type = UserActionTypes.DeleteUserSuccess;

  public constructor() {
  }
}

/**
 * Update user
 */
export class UpdateUser {
  public static readonly type = UserActionTypes.UpdateUser;

  public constructor() {
  }
}

/**
 * Update user success
 */
export class UpdateUserSuccess {
  public static readonly type = UserActionTypes.UpdateUserSuccess;

  public constructor() {
  }
}

/**
 * Update user invalid
 */
export class UpdateUserInvalid {
  public static readonly type = UserActionTypes.UpdateUserInvalid;

  public constructor(public errors: ValidationError[]) {
  }
}

/**
 * Add user
 */
export class AddUser {
  public static readonly type = UserActionTypes.AddUser;

  public constructor() {
  }
}

/**
 * Add user success
 */
export class AddUserSuccess {
  public static readonly type = UserActionTypes.AddUserSuccess;

  public constructor() {
  }
}

/**
 * Reset Password
 */
export class ResetPassword {
  public static readonly type = UserActionTypes.ResetPassword;

  public constructor(public email: string) {
  }
}

/**
 * Reset Password Success
 */
export class ResetPasswordSuccess {
  public static readonly type = UserActionTypes.ResetPasswordSuccess;

  public constructor() {
  }
}

/**
 * Reset Password Invalid
 */
 export class ResetPasswordInvalid {
  public static readonly type = UserActionTypes.ResetPasswordInvalid;

  public constructor(public errors: ValidationError[]) {
  }
}

/**
 * Set New Password
 */
export class SetNewPassword {
  public static readonly type = UserActionTypes.SetNewPassword;

  public constructor(
    public userId: string,
    public token: string,
    public password: string
  ) {
  }
}

/**
 * Set New Password Success
 */
export class SetNewPasswordSuccess {
  public static readonly type = UserActionTypes.SetNewPasswordSuccess;

  public constructor() {
  }
}

/**
 * Set New Password invalid
 */
export class SetNewPasswordInvalid {
  public static readonly type = UserActionTypes.SetNewPasswordInvalid;

  public constructor(public errors: ValidationError[]) {
  }
}

/**
 * Validate Reset Token
 */
export class ValidateResetToken {
  public static readonly type = UserActionTypes.ValidateResetToken;

  public constructor(
    public id: string, 
    public token: string
  ) {
  }
}

/**
 * Validate Reset Token Success
 */
export class ValidateResetTokenSuccess {
  public static readonly type = UserActionTypes.ValidateResetTokenSuccess;

  public constructor(public valid: boolean) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Users Request
 */
export class GetUsersRequest {
  public static readonly type = UserActionTypes.GetUsersRequest;

  public constructor() {
  }
}

/**
 * Get User by id Request
 */
export class GetUserByIdRequest {
  public static readonly type = UserActionTypes.GetUserByIdRequest;

  public constructor(public id: string) {
  }
}

/**
 * Update user Request
 */
export class UpdateUserRequest {
  public static readonly type = UserActionTypes.UpdateUserRequest;

  public constructor(public user: User) {
  }
}

/**
 * Add user Request
 */
export class AddUserRequest {
  public static readonly type = UserActionTypes.AddUserRequest;

  public constructor(public user: User) {
  }
}

/**
 * Delete user Request
 */
export class DeleteUserRequest {
  public static readonly type = UserActionTypes.DeleteUserRequest;

  public constructor(public id: string) {
  }
}

/**
 * Reset Password Request
 */
export class ResetPasswordRequest {
  public static readonly type = UserActionTypes.ResetPasswordRequest;

  public constructor(public email: string) {
  }
}

/**
 * Set New Password Request
 */
export class SetNewPasswordRequest {
  public static readonly type = UserActionTypes.SetNewPasswordRequest;

  public constructor(
    public userId: string,
    public token: string,
    public password: string
  ) {
  }
}

/**
 * Set New Password Request
 */
export class ValidateResetTokenRequest {
  public static readonly type = UserActionTypes.ValidateResetTokenRequest;

  public constructor(
    public id: string,
    public token: string
  ) {
  }
}

// </editor-fold>
