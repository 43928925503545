// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetRootNodesSuccess = '[STORE | CONTROL | NODE] Get Root Nodes Success',
  GetNodeById = '[STORE | CONTROL | NODE] Get Node By Id',
  GetNodesByParentId = '[STORE | CONTROL | NODE] Get Nodes By ParentId',
  GetNodesByCurrentRoute = '[STORE | CONTROL | NODE] Get Nodes By Current Route',
  GetRouteById = '[STORE | CONTROL | NODE] Get Route By Id',
  GetRouteByIdSuccess = '[STORE | CONTROL | NODE] Get Route By Id Success',
  /**
   * Request
   */
  GetNodeByIdRequest = '[REQUEST | CONTROL | NODE] Get Nodes By Id Request',
  GetNodesByParentIdRequest = '[REQUEST | CONTROL | NODE] Get Nodes By ParentId Request',
  GetNodesByRouteRequest = '[REQUEST | CONTROL | NODE] Get Nodes By Route Request',
  GetRouteByIdRequest = '[REQUEST | CONTROL | NODE] Get Route By Id Request',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Root Nodes Success
 */
export class GetRootNodesSuccess {
  public static readonly type = ActionTypes.GetRootNodesSuccess;
}

/**
 * Get Node By Id
 */
export class GetNodeById {
  public static readonly type = ActionTypes.GetNodeById;

  public constructor(public id: string | null) {
  }
}

/**
 * Get Nodes By Parent Id
 */
export class GetNodesByParentId {
  public static readonly type = ActionTypes.GetNodesByParentId;

  public constructor(public parentId: string | null) {
  }
}

/**
 * Get Nodes By Current Route
 */
export class GetNodesByCurrentRoute {
  public static readonly type = ActionTypes.GetNodesByCurrentRoute;
}

/**
 * Get Route By Id
 */
export class GetRouteById {
  public static readonly type = ActionTypes.GetRouteById;

  public constructor(public id: string) {
  }
}

/**
 * Get Route By Id Success
 */
export class GetRouteByIdSuccess {
  public static readonly type = ActionTypes.GetRouteByIdSuccess;

  public constructor(public route: string) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Node By Id Request
 */
export class GetNodeByIdRequest {
  public static readonly type = ActionTypes.GetNodeByIdRequest;

  public constructor(public id: string | null) {
  }
}

/**
 * Get Nodes By Parent Id Request
 */
export class GetNodesByParentIdRequest {
  public static readonly type = ActionTypes.GetNodesByParentIdRequest;

  public constructor(public parentId: string | null) {
  }
}

/**
 * Get Nodes By Route Request
 */
export class GetNodesByRouteRequest {
  public static readonly type = ActionTypes.GetNodesByRouteRequest;

  public constructor(public route: string) {
  }
}

/**
 * Get Route By Id Request
 */
export class GetRouteByIdRequest {
  public static readonly type = ActionTypes.GetRouteByIdRequest;

  public constructor(public id: string) {
  }
}

// </editor-fold>
