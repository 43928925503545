<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<footer class="sidebar-copyrights" *ngIf="(slideViewerFacade.allCurrentSelectedSlideCopyrights$ | async) as copyrights; else spinner">
  <span class="sidebar-copyright" *ngFor="let copyright of copyrights">
    {{ copyright.text }}
  </span>
</footer>
