import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { FeedbackViewerModels } from '@medsurf/flat-models';
import { FeedbackViewerActions } from '@medsurf/flat-actions';

/**
 * Feedback Control Facade
 */
@Injectable()
export class FeedbackViewerFacade {
  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(public store: Store) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Send Feedback
   *
   * @param feedback: FeedbackViewerModels.FeedbackModel
   */
  public requestSendFeedback(feedback: FeedbackViewerModels.FeedbackModel) {
    return this.store.dispatch(new FeedbackViewerActions.SendFeedback(feedback));
  }

  //</editor-fold>
}
