import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { KeymapColumnEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';

/**
 * Keymap Column Entity Token
 */
export const KEYMAP_COLUMN_ENTITY_TOKEN = new StateToken<KeymapColumnEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.KEYMAP_COLUMN);

/**
 * Keymap Column Entity State Model
 */
export type KeymapColumnEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.KeymapColumnEntityModels.KeymapColumn>;

/**
 * Keymap Column Entity State
 */
@State<KeymapColumnEntityStateModel>({
  name: KEYMAP_COLUMN_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class KeymapColumnEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: KeymapColumnEntityStateModel
   */
  @Selector([KeymapColumnEntityState])
  public static typedEntities$(state: KeymapColumnEntityStateModel): { [id: string]: FlatModels.KeymapColumnEntityModels.KeymapColumn } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.KeymapColumnEntityModels.KeymapColumn }
   */
  @Selector([KeymapColumnEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.KeymapColumnEntityModels.KeymapColumn }): FlatModels.KeymapColumnEntityModels.KeymapColumn[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.KeymapColumnEntityModels.KeymapColumn }
   */
  @Selector([KeymapColumnEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.KeymapColumnEntityModels.KeymapColumn }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.KeymapColumnEntityModels.KeymapColumn[]
   */
  @Selector([KeymapColumnEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.KeymapColumnEntityModels.KeymapColumn[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<KeymapColumnEntityStateModel>
   * @param patchState: StateContext<KeymapColumnEntityStateModel>
   * @param entityQueryResult: KeymapColumnEntityActions.GetEntitySuccess
   */
  @Action(KeymapColumnEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<KeymapColumnEntityStateModel>,
                          {entityQueryResult}: KeymapColumnEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<KeymapColumnEntityStateModel>
   * @param patchState: StateContext<KeymapColumnEntityStateModel>
   * @param entityQueryResults: KeymapColumnEntityActions.GetEntitiesSuccess
   */
  @Action(KeymapColumnEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<KeymapColumnEntityStateModel>,
                            {entityQueryResults}: KeymapColumnEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<KeymapColumnEntityStateModel>
   * @param patchState: StateContext<KeymapColumnEntityStateModel>
   * @param entity: KeymapColumnEntityActions.AddEntity
   */
  @Action(KeymapColumnEntityActions.AddEntity)
  @Undoable(KeymapColumnEntityActions.AddEntity, KEYMAP_COLUMN_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<KeymapColumnEntityStateModel>,
                   {entity}: KeymapColumnEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<KeymapColumnEntityStateModel>
   * @param patchState: StateContext<KeymapColumnEntityStateModel>
   * @param entity: KeymapColumnEntityActions.UpdateEntity
   */
  @Action(KeymapColumnEntityActions.UpdateEntity)
  @Undoable(KeymapColumnEntityActions.UpdateEntity, KEYMAP_COLUMN_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<KeymapColumnEntityStateModel>,
                      {entity}: KeymapColumnEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<KeymapColumnEntityStateModel>
   * @param patchState: StateContext<KeymapColumnEntityStateModel>
   * @param entity: KeymapColumnEntityActions.RemoveEntity
   */
  @Action(KeymapColumnEntityActions.RemoveEntity)
  @Undoable(KeymapColumnEntityActions.RemoveEntity, KEYMAP_COLUMN_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<KeymapColumnEntityStateModel>,
                      {entity}: KeymapColumnEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
