import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackViewerModels } from '@medsurf/flat-models';
import { FeedbackViewerFacade } from '@medsurf/flat-facades';
import { FeedbackControlService } from '@medsurf/flat-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'medsurf-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnDestroy {
  /**
   * Members
   */
  private _destroyed$ = new Subject<boolean>();
  public isSubmitting = false;
  public submitted = false;
  public model: FeedbackViewerModels.FeedbackModel = {};
  public errorMessage: string;
  public successMessage: string;

  /**
   * Constructor
   *
   * @param feedbackViewerFacade: feedbackViewerFacade,
   * @param feedbackControlService: FeedbackControlService,
   * @param router: Router
   */
  constructor(public feedbackViewerFacade: FeedbackViewerFacade,
              public feedbackControlService: FeedbackControlService,
              public router: Router) {
    this.router.events.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      if (location.href !== '') {
        this.model.location = location.href;
      }
    });
  }

  /**
   * Show Feedback Modal
   */
  public get showFeedbackModal(): boolean {
    return this.feedbackControlService.showFeedbackModal;
  }

  /**
   * Close
   */
  public close(): void {
    this.feedbackControlService.closeFeedbackModal();
  }

  /**
   * On Submit
   */
  public async onSubmit() {
    this.errorMessage = '';
    this.successMessage = '';
    this.isSubmitting = true;

    try {
      this.feedbackViewerFacade.requestSendFeedback(this.model);
      this.successMessage = 'feedback_saved';
      this.submitted = true;
    } catch (e) {
      this.errorMessage = e.error.result;
    } finally {
      this.isSubmitting = false;
    }
  }

  /**
   * Ng On Destroy
   */
  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
