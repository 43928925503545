import { Base } from '../base/base.entity.model';
import * as FlatModels from '../../internal';

/**
 * Question Entity Type
 */
export type QuestionEntityType = Question | SingleChoiceQuestion | LongListQuestion | MappingQuestion | KprimeQuestion
  | TrueFalseQuestion | FreeTextQuestion | IndicationQuestion | MultipleChoiceQuestion;

/**
 * Question Entity Query Result
 */
export type QuestionEntityQueryResult = FlatModels.EntityControlModels.EntityQueryResult<QuestionEntityType>;

/**
 * Question Type
 */
export enum QuestionType {
  SINGLE_CHOICE = 'SingleChoiceQuestion',
  LONG_LIST = 'LongListQuestion',
  MAPPING = 'MappingQuestion',
  KPRIME = 'KprimeQuestion',
  TRUE_FALSE = 'TrueFalseQuestion',
  FREE_TEXT = 'FreeTextQuestion',
  INDICATION = 'IndicationQuestion',
  MULTIPLE_CHOICE = 'MultipleChoiceQuestion',
}

/**
 * Numbering Type
 */
export enum NumberingType {
  NUMBER = '1',
  LETTER = 'A',
  ROMAN = 'I',
  NONE = 'None'
}

/**
 * Question
 */
export interface Question extends Base {
  training?: FlatModels.PageEntityModels.Training;
  choices?: FlatModels.ChoiceEntityModels.Choice[];
  order: number;
  content?: string;
  placeholder?: string;
  explanation?: string;
  required?: boolean;
  randomised?: boolean;
  numberingType?: NumberingType;
  type?: QuestionType;
}

/**
 * Single Choice Question
 */
export interface SingleChoiceQuestion extends Question {
  solution?: number;
}

/**
 * Long List Question
 */
export interface LongListQuestion extends Question {
  solution?: number;
}

/**
 * Mapping Question
 */
export interface MappingQuestion extends Question {
  solutions?: string[];
}

/**
 * K Prime Question
 */
export interface KprimeQuestion extends Question {
  solutions?: string[];
}

/**
 * True False Question
 */
export interface TrueFalseQuestion extends Question {
  solutions?: string[];
}

/**
 * Free Text Question
 */
export interface FreeTextQuestion extends Question {
  solutionText?: string;
}

/**
 * Indication Question
 */
export interface IndicationQuestion extends Question {
  solution?: number;
}

/**
 * Multiple Choice Question
 */
export interface MultipleChoiceQuestion extends Question {
  solutions?: string[];
}
