import { EventEmitter } from 'events';

/**
 * Command class
 */
export class Command extends EventEmitter {
  /**
   * Members
   */
  private _currentCommand: any;
  private _historyIndex: number;
  private _currentVersion: any;
  private _history: any[];
  private _cmdPressed: boolean;
  private _ctrlPressed: boolean;
  private command: Command;

  /**
   * Constructor
   *
   * @param $window
   * @param _model
   */
  public constructor(private $window,
                     private _model?) {
    super();
    this._currentCommand = null;
    this._historyIndex = 0;
    this._currentVersion = this._model;
    if (this._model) {
      this._history = [JSON.parse(JSON.stringify(this._model))];
    } else {
      this._history = [];
    }
    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this._cmdPressed = false;
    this._ctrlPressed = false;
    this.$window.command = this;
    this.$window.addEventListener('keydown', this._keyDown, false);
    this.$window.addEventListener('keyup', this._keyUp, false);
  }

  /**
   * Key down event
   *
   * @param event
   * @protected
   */
  protected _keyDown(event) {
    if (event.keyCode === 91) {
      this._cmdPressed = true;
    }
    if (event.keyCode === 17) {
      this._ctrlPressed = true;
    }
    if (event.keyCode === 90 && (this._cmdPressed || event.ctrlKey)) {
      event.stopPropagation();
      event.preventDefault();
      this.command.undo();
    }
    if (event.shiftKey && (event.keyCode === 90 && (this._cmdPressed || event.ctrlKey))) {
      event.stopPropagation();
      event.preventDefault();
      this.command.redo();
    }
  }

  /**
   * Key up event
   *
   * @protected
   */
  protected _keyUp() {
    this._cmdPressed = false;
  }

  /**
   * Add to history
   *
   * @param version
   */
  public addToHistory(version) {
    this._history.push(version);
    this._historyIndex++;
  }

  /**
   * Execute command
   *
   * @param fn
   * @param description
   */
  public doCommand(fn, description) {
    const newVersion = fn(this._model);
    if (newVersion) {
      this._currentVersion = [JSON.parse(JSON.stringify(newVersion))];
    }
    this._currentCommand = description;
  }

  /**
   * Undo
   */
  public undo() {
    if (this._historyIndex > 0) {
      this._historyIndex-- ;
    }
    this._model = this._history[this._historyIndex];
    this.emit('undoredo');
  }

  /**
   * Redo
   */
  public redo() {
    if (this._historyIndex < this._history.length - 1) {
      this._historyIndex++;
    }
    this._model = this._history[this._historyIndex];
    this.emit('undoredo');
  }

  /**
   * Setter currentCommand
   *
   * @param value
   */
  public set currentCommand(value) {
    this._currentCommand = value;
  }

  /**
   * Getter currentCommand
   *
   * @returns {null}
   */
  public get currentCommand() {
    return this._currentCommand;
  }

  /**
   * Setter currentVersion
   *
   * @param value
   */
  public set currentVersion(value) {
    this._currentVersion = value;
  }

  /**
   * Getter currentVersion
   *
   * @returns {*}
   */
  public get currentVersion() {
    return this._currentVersion;
  }

  /**
   * Getter model
   *
   * @returns {*}
   */
  public get model() {
    return this._model;
  }

  /**
   * Clean up
   */
  public cleanUp() {
    this.$window.removeEventListener('keydown');
    this.$window.removeEventListener('keyup');
  }
}
