import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedsurfLibModule } from '@medsurf/module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InteractiveAreaComponent } from './sidebar/interactive-area/interactive-area.component';
import { QuestionsComponent } from './sidebar/questions/questions.component';
import { SliderComponent } from './footer/slider/slider.component';
import { VideoComponent } from './main/video/video.component';
import { AudioComponent } from './main/audio/audio.component';
import { ImageComponent } from './main/image/image.component';
import { PoiComponent } from './sidebar/poi/poi.component';
import { SequenceControlComponent } from './main/sequenceControl/sequenceControl.component';
import { ShortcutsComponent } from './main/shortcuts/shortcuts.component';
import { MedsurfViewerCommonModule } from '../common/medsurf-viewer-common.module';
import { TextComponent } from './sidebar/text/text.component';
import { SlideComponent } from './slide.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrintComponent } from './print/print.component';
import { SlideMainComponent } from './main/slide-main.component';
import { SlideHeaderComponent } from './header/slide-header.component';
import { SlideSidebarComponent } from './sidebar/slide-sidebar.component';
import { CopyrightComponent } from './sidebar/copyright/copyright.component';
import { SlideRoutingModule } from './slide-routing.module';
import { CaseHistoryComponent } from './case-history/case-history.component';
import { FooterComponent } from './footer/footer.component';
import { TrainingSliderComponent } from './footer/training-slider/training-slider.component';
import { MappingQuestionComponent } from './sidebar/questions/question-types/mapping-question/mapping-question.component';
import { SingleChoiceQuestionComponent } from './sidebar/questions/question-types/single-choice-question/single-choice-question.component';
import { KPrimQuestionComponent } from './sidebar/questions/question-types/k-prim-question/k-prim-question.component';
import { FreetextQuestionComponent } from './sidebar/questions/question-types/freetext-question/freetext-question.component';
import { IndicationQuestionComponent } from './sidebar/questions/question-types/indication-question/indication-question.component';
import { QuestionFooterComponent } from './sidebar/questions/question-footer/question-footer.component';
import { QuestionHeaderComponent } from './sidebar/questions/question-header/question-header.component';

@NgModule({
  declarations: [
    QuestionsComponent,
    SliderComponent,
    VideoComponent,
    AudioComponent,
    ImageComponent,
    SequenceControlComponent,
    ShortcutsComponent,
    PoiComponent,
    InteractiveAreaComponent,
    TextComponent,
    SlideComponent,
    PrintComponent,
    SlideMainComponent,
    SlideHeaderComponent,
    SlideSidebarComponent,
    CopyrightComponent,
    CaseHistoryComponent,
    FooterComponent,
    TrainingSliderComponent,
    MappingQuestionComponent,
    SingleChoiceQuestionComponent,
    KPrimQuestionComponent,
    FreetextQuestionComponent,
    IndicationQuestionComponent,
    QuestionFooterComponent,
    QuestionHeaderComponent
  ],
  imports: [
    MedsurfLibModule,
    NgbModule,
    CommonModule,
    MedsurfViewerCommonModule,
    TranslateModule.forChild(),
    FormsModule,
    RouterModule,
    SlideRoutingModule,
  ],
  exports: [
    TextComponent,
    SlideComponent,
  ]
})
export class SlideModule {
}
