<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="question && question.solutions !== undefined && trainingResult && trainingResult.answer !== undefined; else spinner">
  <ng-container *ngIf="question.solutions !== null && trainingResult.answer !== null && question.solutions.length === trainingResult.answer.length">
    <!-- Header -->
    <medsurf-quiz-question-header [index]="(trainingViewerFacade.selectedQuestionIndex$(question.id) | async) || index"
                                  [checkedAndCorrect]="trainingResult.showFeedback && isCorrect()"
                                  [questionType]="question.type">
    </medsurf-quiz-question-header>

    <!-- Question Form -->
    <form name="form"
          class="mdsf-quiz-form">
      <!-- Question Text -->
      <medsurf-text [text]="question.content"></medsurf-text>

      <!-- Choices -->
      <div class="mb-4">
        <!-- TODO sort needed?: | sortBy_shared:'asc':'order' -->
        <ng-container *ngFor="let choice of (trainingViewerFacade.choicesByIds$(question.choices) | async); let j = index">
          <div class="choice"
               [ngStyle]="{'order': choice.order}"
               [class.choice-light]="!showRequired && trainingResult.showFeedback === false && !(j % 2)"
               [class.choice-error]="trainingResult.showFeedback && question.solutions[j] !== trainingResult.answer[j]"
               [class.choice-correct]="trainingResult.showFeedback && question.solutions[j] === trainingResult.answer[j]"
               [class.choice-not-answered]="showRequired && passItemRequired(j)"
               [class.no-hover]="trainingResult.showFeedback">
            <div class="btn-group btn-group-toggle"
                 data-toggle="buttons"
                 ngbRadioGroup
                 [name]="question.id + '-' + j"
                 [class.not-answered]="trainingResult.showFeedback && passItemRequired(j)">
              <label ngbButtonLabel
                     class="radio-custom"
                     [class.radio-custom-black]="trainingResult.answer[j] === '1' || (question.solutions[j].toString() === '1' && (menuViewerFacade.showPrintForAuthor$ | async))"
                     [class.radio-custom-error]="trainingResult.showFeedback && trainingResult.answer[j] === '1' && question.solutions[j].toString() !== trainingResult.answer[j]">
                <input ngbButton
                       type="radio"
                       name="choices{{question.order}}{{j}}"
                       [value]="'1'"
                       (click)="onClick(j, '1')"
                       [checked]="trainingResult.answer[j] === '1'">
                <i class="fa fa-check"></i>
                <i class="fal fa-times cross"
                   *ngIf="trainingResult.showFeedback && trainingResult.answer[j] === '1' && question.solutions[j].toString() !== trainingResult.answer[j]"></i>
              </label>

              <label ngbButtonLabel
                     class="radio-custom"
                     [class.radio-custom-black]="trainingResult.answer[j] === '0' || (question.solutions[j].toString() === '0' && (menuViewerFacade.showPrintForAuthor$ | async))"
                     [class.radio-custom-error]="trainingResult.showFeedback && trainingResult.answer[j] === '0' && question.solutions[j].toString() !== trainingResult.answer[j]">
                <input ngbButton
                       type="radio"
                       name="choices{{question.order}}{{j}}"
                       [value]="'0'"
                       (click)="onClick(j, '0')"
                       [checked]="trainingResult.answer[j] === '0'">
                <i class="fa fa-times"></i>
                <i *ngIf="trainingResult.showFeedback && trainingResult.answer[j] === '0' && question.solutions[j].toString() !== trainingResult.answer[j]"
                   class="fal fa-times cross"></i>
              </label>
            </div>
            <div class="choice-label ml-3">
              <label class="choice-label-character">{{ trainingViewerFacade.helperListCharacter(question, j) }}.</label>
              <label class="choice-label-text">
                <medsurf-text [text]="choice.label"></medsurf-text>
              </label>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Explanation -->
      <div class="form-control-explanation mt-2"
           *ngIf="question.explanation && (trainingResult.showExplanation || (menuViewerFacade.showPrintForAuthor$ | async))">
        <strong>{{ 'explanation' | translate }}</strong>
        <medsurf-text [text]="question.explanation"></medsurf-text>
      </div>

      <!-- Footer -->
      <medsurf-quiz-question-footer #footer
                                    class="mt-2"
                                    *ngIf="(menuViewerFacade.showPrint$ | async) === false && (question.solutions || []).length >= (question.choices || []).length"
                                    [question]="question"
                                    [trainingResult]="trainingResult"
                                    [isCorrect]="isCorrect"
                                    [passRequired]="passRequired"
                                    [(showRequired)]="showRequired"
                                    [ngStyle]="{'order': 100}">
      </medsurf-quiz-question-footer>
    </form>
  </ng-container>
</ng-container>
