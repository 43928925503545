import { Base } from '../base/base.entity.model';
import * as FlatModels from '../../internal';

/**
 * Node Entity Type
 */
export type NodeEntityType = ElearningNode | SubjectNode | ChapterNode | BlockNode | SlideNode;

/**
 * Node Entity Query Result
 */
export type NodeEntityQueryResult = FlatModels.EntityControlModels.EntityQueryResult<NodeEntityType>;

/**
 * Node Settings
 */
export interface NodeSettings {
  hideHomeButton?: boolean;
  hideDownloadPdf?: boolean;
  hideAnnotations?: boolean | null;
  pageTitleColor?: string;
}

/**
 * Node
 */
export interface Node extends Base {
  parentId?: string;
  position?: number;
  page?: FlatModels.PageEntityModels.Page;
  children?: Node[];
  hasChildren?: boolean;
  route?: string;
  hidden?: boolean;
  restricted?: boolean;
  shares?: FlatModels.UserEntityModels.User[];
  settings?: NodeSettings;
  expanded?: boolean;
}

/**
 * Elearning Node
 */
export interface ElearningNode extends Node {
  page?: FlatModels.PageEntityModels.Folder;
  children?: SubjectNode[];
  parent?: Node;
}

/**
 * Subject Node
 */
export interface SubjectNode extends Node {
  page?: FlatModels.PageEntityModels.Folder;
  children?: ChapterNode[];
  parent?: ElearningNode;
}

/**
 * Chapter Node
 */
export interface ChapterNode extends Node {
  page?: FlatModels.PageEntityModels.Folder;
  children?: BlockNode[];
  parent?: SubjectNode;
}

/**
 * Block Node
 */
export interface BlockNode extends Node {
  page?: FlatModels.PageEntityModels.Grid;
  children?: SlideNode[];
  parent?: SubjectNode;
}

/**
 * Slide Node
 */
export interface SlideNode extends Node {
  page?: FlatModels.PageEntityModels.Slide;
  children?: Node[];
  parent?: BlockNode;
}

/**
 * Populate Node Settings
 *
 * @param settings: NodeSettings
 */
export function populateNodeSettings(settings: NodeSettings = {}) : NodeSettings {
  const defaultSettings : NodeSettings = {
    hideHomeButton: false,
    hideDownloadPdf: false,
    hideAnnotations: null,
    pageTitleColor: '#ffffff'
  }

  return Object.assign(defaultSettings, settings);
}
