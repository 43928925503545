import { Annotation, AnnotationTarget, TipType } from './annotation.model';
import { Base } from './base.model';
import { Point } from './point.model';

export interface FreeForm extends Annotation {
  shape?: Shape;
  path?: Point[];
  freeFormStyle?: FreeFormStyle;
  target?: AnnotationTarget;

  // Not yet in database
  linkedSlideId?: string;
  linkedSite?: any;
}

export interface FreeFormStyle extends Base {
  height?: number;
  width?: number;
  border?: boolean;
  strokeWidth?: number;
  closePath?: boolean;
  smooth?: boolean;
  smoothFactor?: number;
  background?: boolean;
  backgroundInBorderColor?: boolean;
  strokeColor?: string;
  opacity?: number;
  startType?: TipType;
  endType?: TipType;
  endRadius?: number;
  startRadius?: number;
  color?: string;
  dash?: string;

  // Not yet in database
  angle?: number;
  skewAngle?: number;
}

export enum Shape {
  FREE = 'free',
  RECTANGLE = 'rectangle',
  ELLIPSE = 'ellipse',
  ARROW = 'arrow',
  INTERACTIVE_AREA = 'interactiveArea',
  LINE = 'line',
  TRIANGLE = 'triangle'
}
