<h1 class="print-title"
    *ngIf="(menuViewerFacade.currentSlideTitle$ | async) as title"
    [innerHTML]="title | safeHtml">
</h1>
<ng-container *ngIf="(slideViewerFacade.currentSelectedSlideMedia$ | async) as media">
  <medsurf-image *ngIf="mediaControlService.isDeepzoom(media) || mediaControlService.isImage(media)">
  </medsurf-image>
</ng-container>
<ng-container *ngIf="(slideViewerFacade.currentSelectedSlidePage$ | async) as slide">
    <h1 class="print-header mt-3"
        *ngIf="slide.header">
      {{ slide.header }}
    </h1>
    <medsurf-text *ngIf="slide.body"
                  [text]="slide.body"
                  [isSlideDescription]="true">
    </medsurf-text>

    <ng-container *ngIf="(slideViewerFacade.currentSelectedSlideImage$ | async) as image">
      <medsurf-viewer-interactive-area-list></medsurf-viewer-interactive-area-list>
      <medsurf-viewer-poi-list></medsurf-viewer-poi-list>
    </ng-container>
    
    <medsurf-questions></medsurf-questions>
    <medsurf-copyright></medsurf-copyright>
</ng-container>
