import { Component, OnInit } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { AppSharedFacade, NodeSharedFacade, TrainingViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-quiz-start',
  templateUrl: './quiz-start.component.html',
  styleUrls: ['./quiz-start.component.scss']
})
export class QuizStartComponent implements OnInit {
  /**
   * Constructor
   *
   * @param trainingViewerFacade: TrainingViewerFacade
   * @param appSharedFacade: AppSharedFacade
   * @param nodeSharedFacade: NodeSharedFacade
   */
  constructor(public trainingViewerFacade: TrainingViewerFacade,
              public appSharedFacade: AppSharedFacade,
              public nodeSharedFacade: NodeSharedFacade) {
  }

  /**
   * Ng On Init
   */
  ngOnInit() {
    this.trainingViewerFacade.requestReset();
    this.trainingViewerFacade.requestTrainingNodesByCurrentSubject();
  }

  /**
   * Toggle Expanded
   *
   * @param chapter: FlatModels.NodeEntityModels.ChapterNode
   * @param event: Event
   */
  public toggleExpanded(chapter: FlatModels.NodeEntityModels.ChapterNode, event: Event) {
    event.stopPropagation();
    chapter.expanded = !chapter.expanded;
  }

  /**
   * Is Checked
   *
   * @param currentAvailableSlides: FlatModels.TrainingViewerModels.AvailableSlide[]
   * @param selectedSlides: string[]
   */
  public isChecked(currentAvailableSlides: FlatModels.TrainingViewerModels.AvailableSlide[],
                   selectedSlides: string[]): boolean {
    return currentAvailableSlides.map(as => as.id).every(slide => selectedSlides.includes(slide as unknown as string));
  }

  /**
   * Is Indeterminate
   *
   * @param currentAvailableSlides: FlatModels.TrainingViewerModels.AvailableSlide[],
   * @param selectedSlides: string[]
   */
  public isIndeterminate(currentAvailableSlides: FlatModels.TrainingViewerModels.AvailableSlide[],
                               selectedSlides: string[]): boolean {
    return currentAvailableSlides.map(as => as.id).some(slides => selectedSlides.includes(slides));
  }
}
