import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { BaseGuardChainFacade } from '@medsurf/flat-facades';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseChainBaseGuard implements CanActivate {
  /**
   * Constructor
   *
   * @param router: Router
   * @param baseChainGuardFacade: BaseGuardChainFacade
   */
  protected constructor(public router: Router,
                        public baseChainGuardFacade: BaseGuardChainFacade) {}

  /**
   * Can Activate
   *
   * @param next: ActivatedRouteSnapshot
   */
  public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    return Promise.resolve(false);
  }
}
