import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { ImageEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import { SlideLayerEntityState } from '../../internal';

/**
 * Image Entity Token
 */
export const IMAGE_ENTITY_TOKEN = new StateToken<ImageEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.IMAGE);

/**
 * Image Entity State Model
 */
export interface ImageEntityStateModel extends FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.ImageEntityModels.Image> {
  imageIndex: number;
}

/**
 * Image Entity State
 */
@State<ImageEntityStateModel>({
  name: IMAGE_ENTITY_TOKEN,
  defaults: {
    imageIndex: 0,
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class ImageEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector imageIndex$
   *
   * @param state: ImageEntityStateModel
   */
  @Selector([ImageEntityState])
  public static imageIndex$(state: ImageEntityStateModel): number {
    return state.imageIndex;
  }

  /**
   * Selector typedEntities$
   *
   * @param state: ImageEntityStateModel
   */
  @Selector([ImageEntityState])
  public static typedEntities$(state: ImageEntityStateModel): { [id: string]: FlatModels.ImageEntityModels.Image } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.ImageEntityModels.Image }
   */
  @Selector([ImageEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.ImageEntityModels.Image }): FlatModels.ImageEntityModels.Image[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.ImageEntityModels.Image }
   */
  @Selector([ImageEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.ImageEntityModels.Image }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.ImageEntityModels.Image[]
   */
  @Selector([ImageEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.ImageEntityModels.Image[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  /**
   * Selector allCurrentSelectedSlideImages$
   *
   * @param entities: FlatModels.ImageEntityModels.Image[]
   * @param currentSelectedSlideLayers: FlatModels.LayerEntityModels.Layer[]
   */
  @Selector([
    ImageEntityState.entities$,
    SlideLayerEntityState.currentSelectedSlideLayers$
  ])
  public static allCurrentSelectedSlideImages$(entities: FlatModels.ImageEntityModels.Image[],
                                               currentSelectedSlideLayers: FlatModels.LayerEntityModels.Layer[]) {
    if (Array.isArray(currentSelectedSlideLayers)) {
      const allCurrentSelectedImages: string[] = currentSelectedSlideLayers.map(sl => sl.images || []).reduce((acc, val) => acc.concat(val), []) as unknown as string[] || [];
      return Array.isArray(currentSelectedSlideLayers) ? entities.filter(e => allCurrentSelectedImages.includes(e.id)) : [];
    }
    return [];
  }

  /**
   * Selector currentSelectedSlideImages$
   *
   * @param entities: FlatModels.ImageEntityModels.Image[]
   * @param currentSelectedSlideLayer: FlatModels.LayerEntityModels.Layer | null
   */
  @Selector([
    ImageEntityState.entities$,
    SlideLayerEntityState.currentSelectedSlideLayer$
  ])
  public static currentSelectedSlideImages$(entities: FlatModels.ImageEntityModels.Image[],
                                            currentSelectedSlideLayer: FlatModels.LayerEntityModels.Layer | null) {
    return currentSelectedSlideLayer && currentSelectedSlideLayer.images && Array.isArray(entities) && entities.filter(e => (currentSelectedSlideLayer.images as unknown as string[]).includes(e.id)) || [];
  }

  /**
   * Selector currentSelectedSlideValidImages$
   *
   * @param currentSelectedSlideImages:
   */
  @Selector([ImageEntityState.currentSelectedSlideImages$])
  public static currentSelectedSlideValidImages$(currentSelectedSlideImages: FlatModels.ImageEntityModels.Image[]) {
    return Array.isArray(currentSelectedSlideImages) && currentSelectedSlideImages.filter(image => !!image.media) || [];
  }

  /**
   * Selector currentSelectedSlideHasSequenceControl$
   *
   * @param currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[]
   */
  @Selector([ImageEntityState.currentSelectedSlideValidImages$])
  public static currentSelectedSlideHasSequenceControl$(currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[]) {
    return currentSelectedSlideValidImages.length > 1;
  }

  /**
   * Selector currentSelectedSlideImagesMap$
   *
   * @param currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[]
   */
  @Selector([ImageEntityState.currentSelectedSlideValidImages$])
  public static currentSelectedSlideImagesMap$(currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[]) {
    return [...currentSelectedSlideValidImages].sort((a, b) => (a.sequenceNumber || 0) - (b.sequenceNumber || 0)).map(i => i.id);
  }

  /**
   * Selector currentSelectedImagesDefaultIndex$
   *
   * @param currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[]
   * @param currentSelectedSlideImagesMap: string[]
   */
  @Selector([ImageEntityState.currentSelectedSlideValidImages$, ImageEntityState.currentSelectedSlideImagesMap$])
  public static currentSelectedImagesDefaultIndex$(currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[], currentSelectedSlideImagesMap: string[]) {
    if (currentSelectedSlideValidImages.length === 0) {
      return -1;
    }
    const defaultImage =  currentSelectedSlideValidImages.find(image => image.default);
    if (!defaultImage) {
      return 0;
    }
    const defaultIndex = currentSelectedSlideImagesMap.indexOf(defaultImage.id);
    return defaultIndex !== -1 ? defaultIndex : 0;
  }

  /**
   * Selector currentSelectedSlideImage$
   *
   * @param currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[]
   * @param currentSelectedSlideImagesMap: string[]
   * @param imageIndex: number
   */
  @Selector([
    ImageEntityState.currentSelectedSlideValidImages$,
    ImageEntityState.currentSelectedSlideImagesMap$,
    ImageEntityState.imageIndex$
  ])
  public static currentSelectedSlideImage$(currentSelectedSlideValidImages: FlatModels.ImageEntityModels.Image[],
                                           currentSelectedSlideImagesMap: string[],
                                           imageIndex: number) {
    return Number.isInteger(imageIndex) && Array.isArray(currentSelectedSlideImagesMap) && Array.isArray(currentSelectedSlideValidImages) ? currentSelectedSlideValidImages.find(si => si.id === currentSelectedSlideImagesMap[imageIndex]) || null : null;
  }

  /**
   * Selector currentSelectedSlideImageHasAnnotations$
   *
   * @param currentSelectedSlideImage: FlatModels.ImageEntityModels.Image
   */
  @Selector([ImageEntityState.currentSelectedSlideImage$])
  public static currentSelectedSlideImageHasAnnotations$(currentSelectedSlideImage: FlatModels.ImageEntityModels.Image) {
    return currentSelectedSlideImage && currentSelectedSlideImage.annotations && currentSelectedSlideImage.annotations.length > 0 || false;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<ImageEntityStateModel>
   * @param patchState: StateContext<ImageEntityStateModel>
   * @param entityQueryResult: ImageEntityActions.GetEntitySuccess
   */
  @Action(ImageEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<ImageEntityStateModel>,
                          {entityQueryResult}: ImageEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<ImageEntityStateModel>
   * @param patchState: StateContext<ImageEntityStateModel>
   * @param entityQueryResults: ImageEntityActions.GetEntitiesSuccess
   */
  @Action(ImageEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<ImageEntityStateModel>,
                            {entityQueryResults}: ImageEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<ImageEntityStateModel>
   * @param patchState: StateContext<ImageEntityStateModel>
   * @param entity: ImageEntityActions.AddEntity
   */
  @Action(ImageEntityActions.AddEntity)
  @Undoable(ImageEntityActions.AddEntity, IMAGE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<ImageEntityStateModel>,
                   {entity}: ImageEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<ImageEntityStateModel>
   * @param patchState: StateContext<ImageEntityStateModel>
   * @param entity: ImageEntityActions.AddEntity
   */
  @Action(ImageEntityActions.UpdateEntity)
  @Undoable(ImageEntityActions.UpdateEntity, IMAGE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<ImageEntityStateModel>,
                      {entity}: ImageEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<ImageEntityStateModel>
   * @param patchState: StateContext<ImageEntityStateModel>
   * @param entity: ImageEntityActions.AddEntity
   */
  @Action(ImageEntityActions.RemoveEntity)
  @Undoable(ImageEntityActions.RemoveEntity, IMAGE_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<ImageEntityStateModel>,
                      {entity}: ImageEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  /**
   * Set Image Index
   *
   * @param patchState: StateContext<ImageEntityStateModel>
   * @param imageIndex: ImageEntityActions.SetImageIndex
   */
  @Action(ImageEntityActions.SetImageIndex)
  public setLayerIndex({patchState}: StateContext<ImageEntityStateModel>,
                       {imageIndex}: ImageEntityActions.SetImageIndex) {
    patchState({
      imageIndex
    });
  }

  //</editor-fold>
}
