import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertControlModels } from '@medsurf/flat-models';
import { AlertControlFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-control-alert',
  templateUrl: './alert.control.component.html',
  styleUrls: ['./alert.control.component.scss']
})
export class AlertControlComponent {
  /**
   * Constructor
   *
   * @param alertControlFacade: AlertControlFacade
   * @param translate: TranslateService
   */
  constructor(public alertControlFacade: AlertControlFacade,
              public translate: TranslateService) {
  }

  /**
   * Dismiss
   *
   * @param alert: AlertControlModels.Alert
   */
  public dismiss(alert: AlertControlModels.Alert) {
    this.alertControlFacade.requestDismissAlert(alert);
  }

  /**
   * Get Alert Class Name
   *
   * @param alertType: AlertControlModels.AlertType
   */
  public getAlertClassName(alertType: AlertControlModels.AlertType) {
    switch (alertType) {
      case AlertControlModels.AlertType.ERROR:
        return 'alert-danger';
      case AlertControlModels.AlertType.WARNING:
        return 'alert-warning';
      case AlertControlModels.AlertType.INFO:
        return 'alert-info';
      case AlertControlModels.AlertType.SUCCESS:
        return 'alert-success';
      default:
        return 'alert-danger';
    }
  }
}
