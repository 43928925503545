import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateOperator, Store } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { RepairType } from '@ngxs/store/operators/utils';
import { MessageService } from '@medsurf/services';
import { Copyright, Modality, Stain } from '@medsurf/models';
import { DeleteCopyright, DeleteCopyrightSuccess, DeleteModality, DeleteModalitySuccess, DeleteStain, DeleteStainSuccess,
  GetCopyrights, GetCopyrightsSuccess, GetModalities, GetModalitiesSuccess, GetStains, GetStainsSuccess, SaveCopyright,
  SaveCopyrightSuccess, SaveModality, SaveModalitySuccess, SaveStain, SaveStainSuccess, DeleteCopyrightRequest,
  DeleteModalityRequest, DeleteStainRequest, GetCopyrightsRequest, GetModalitiesRequest, GetStainsRequest,
  SaveCopyrightRequest, SaveModalityRequest, SaveStainRequest } from '@medsurf/actions';
import { AuthState } from './auth.state';

/**
 * Sort items
 * @param key: string
 */
function sortItems<T extends Modality | Stain | Copyright>(key: string): StateOperator<RepairType<T>[]> {
  return (existing => existing.slice().sort((a, b) => {
    const valueA = a[key].toLowerCase();
    const valueB = b[key].toLowerCase();
    if (valueA > valueB) {
      return 1;
    }
    if (valueA < valueB) {
      return -1;
    }
    return 0;
  }));
}

/**
 * Constant state model
 */
export interface ConstantStateModel {
  modalities: Modality[];
  stains: Stain[];
  copyrights: Copyright[];
}

/**
 * Constant State
 */
@State<ConstantStateModel>({
  name: 'constant',
  defaults: {
    modalities: [],
    stains: [],
    copyrights: []
  }
})
@Injectable()
export class ConstantState {
  /**
   * Constructor
   * @param store: Store
   * @param messageService: MessageService
   */
  constructor(protected store: Store,
              protected messageService: MessageService) {}

  /**
   * Selector modality
   * @param state: ConstantStateModel
   */
  @Selector()
  public static modalities(state: ConstantStateModel): Modality[] {
    return state.modalities;
  }

  /**
   * Selector stain
   * @param state: ConstantStateModel
   */
  @Selector()
  public static stains(state: ConstantStateModel): Stain[] {
    return state.stains;
  }

  /**
   * Selector copyright
   * @param state: ConstantStateModel
   */
  @Selector()
  public static copyrights(state: ConstantStateModel): Copyright[] {
    return state.copyrights;
  }

  /**
   * Get Modalities
   * @param getState: StateContext<ConstantStateModel>
   * @param dispatch: StateContext<ConstantStateModel>
   */
  @Action(GetModalities)
  public getModalities({getState, dispatch}: StateContext<ConstantStateModel>) {
    if (getState().modalities.length === 0) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetModalitiesRequest());
    }
  }

  /**
   * Get Modalities Success
   * @param setState: StateContext<ConstantStateModel>
   * @param modalities: Modality[]
   */
  @Action(GetModalitiesSuccess)
  public getModalitiesSuccess({setState}: StateContext<ConstantStateModel>, {modalities}: GetModalitiesSuccess) {
    setState(
      patch({
        modalities
      }));
  }

  /**
   * Save modality
   * @param dispatch: StateContext<ConstantStateModel>
   * @param modality: SaveModality
   */
  @Action(SaveModality)
  public saveModality({dispatch}: StateContext<ConstantStateModel>, {modality}: SaveModality) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new SaveModalityRequest(modality));
  }

  /**
   * Save modality success
   * @param getState: StateContext<ConstantStateModel>
   * @param setState: StateContext<ConstantStateModel>
   * @param modality: SaveModalitySuccess
   */
  @Action(SaveModalitySuccess)
  public saveModalitySuccess({getState, setState}: StateContext<ConstantStateModel>, {modality}: SaveModalitySuccess) {
    const modalities = getState().modalities;
    if (!modalities.some(existing => existing.id === modality.id)) {
      modalities.push(modality);
    } else {
      modalities[modalities.findIndex(existing => existing.id === modality.id)] = modality;
    }
    setState(
      patch({
        modalities: sortItems('name')
      }));
  }

  /**
   * Delete modality
   * @param dispatch: StateContext<ConstantStateModel>
   * @param modality: DeleteModality
   */
  @Action(DeleteModality)
  public deleteModality({dispatch}: StateContext<ConstantStateModel>, {modality}: DeleteModality) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new DeleteModalityRequest(modality));
  }

  /**
   * Delete modality success
   * @param setState: StateContext<ConstantStateModel>
   * @param modality: DeleteModalitySuccess
   */
  @Action(DeleteModalitySuccess)
  public deleteModalitySuccess({setState}: StateContext<ConstantStateModel>, {modality}: DeleteModalitySuccess) {
    setState(
      patch({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modalities: removeItem(existing => existing.id === modality.id)
      }));
  }

  /**
   * Get Stains
   * @param getState: StateContext<ConstantStateModel>
   * @param dispatch: StateContext<ConstantStateModel>
   */
  @Action(GetStains)
  public getStains({getState, dispatch}: StateContext<ConstantStateModel>) {
    if (getState().stains.length === 0) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetStainsRequest());
    }
  }

  /**
   * Get Stains Success
   * @param setState: StateContext<ConstantStateModel>
   * @param stains: Stain[]
   */
  @Action(GetStainsSuccess)
  public getStainsSuccess({setState}: StateContext<ConstantStateModel>, {stains}: GetStainsSuccess) {
    setState(
      patch({
        stains
      }));
  }

  /**
   * Save stain
   * @param dispatch: StateContext<ConstantStateModel>
   * @param stain: SaveStain
   */
  @Action(SaveStain)
  public saveStain({dispatch}: StateContext<ConstantStateModel>, {stain}: SaveStain) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new SaveStainRequest(stain));
  }

  /**
   * Save stain success
   * @param getState: StateContext<ConstantStateModel>
   * @param setState: StateContext<ConstantStateModel>
   * @param stain: SaveStainSuccess
   */
  @Action(SaveStainSuccess)
  public saveStainSuccess({getState, setState}: StateContext<ConstantStateModel>, {stain}: SaveStainSuccess) {
    const stains = getState().stains;
    if (!stains.some(existing => existing.id === stain.id)) {
      stains.push(stain);
    } else {
      stains[stains.findIndex(existing => existing.id === stain.id)] = stain;
    }
    setState(
      patch({
        stains: sortItems('name')
      }));
  }

  /**
   * Delete stain
   * @param dispatch: StateContext<ConstantStateModel>
   * @param stain: DeleteStain
   */
  @Action(DeleteStain)
  public deleteStain({dispatch}: StateContext<ConstantStateModel>, {stain}: DeleteStain) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new DeleteStainRequest(stain));
  }

  /**
   * Delete stain success
   * @param setState: StateContext<ConstantStateModel>
   * @param stain: DeleteStainSuccess
   */
  @Action(DeleteStainSuccess)
  public deleteStainSuccess({setState}: StateContext<ConstantStateModel>, {stain}: DeleteStainSuccess) {
    setState(
      patch({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        stains: removeItem(existing => existing.id === stain.id)
      }));
  }

  /**
   * Get Copyrights
   * @param getState: StateContext<ConstantStateModel>
   * @param dispatch: StateContext<ConstantStateModel>
   */
  @Action(GetCopyrights)
  public getCopyrights({getState, dispatch}: StateContext<ConstantStateModel>) {
    if (getState().copyrights.length === 0) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetCopyrightsRequest());
    }
  }

  /**
   * Get Copyrights Success
   * @param setState: StateContext<ConstantStateModel>
   * @param copyrights: Copyright[]
   */
  @Action(GetCopyrightsSuccess)
  public getCopyrightsSuccess({setState}: StateContext<ConstantStateModel>, {copyrights}: GetCopyrightsSuccess) {
    setState(
      patch({
        copyrights
      }));
  }

  /**
   * Save copyright
   * @param dispatch: StateContext<ConstantStateModel>
   * @param copyright: SaveCopyright
   */
  @Action(SaveCopyright)
  public saveCopyright({dispatch}: StateContext<ConstantStateModel>, {copyright}: SaveCopyright) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new SaveCopyrightRequest(copyright));
  }

  /**
   * Save copyright success
   * @param getState: StateContext<ConstantStateModel>
   * @param setState: StateContext<ConstantStateModel>
   * @param copyright: SaveCopyrightSuccess
   */
  @Action(SaveCopyrightSuccess)
  public saveCopyrightSuccess({getState, setState}: StateContext<ConstantStateModel>, {copyright}: SaveCopyrightSuccess) {
    const copyrights = getState().copyrights;
    if (!copyrights.some(existing => existing.id === copyright.id)) {
      copyrights.push(copyright);
    } else {
      copyrights[copyrights.findIndex(existing => existing.id === copyright.id)] = copyright;
    }
    setState(
      patch({
        copyrights: sortItems('text')
      }));
  }

  /**
   * Delete copyright
   * @param dispatch: StateContext<ConstantStateModel>
   * @param copyright: DeleteCopyright
   */
  @Action(DeleteCopyright)
  public deleteCopyright({dispatch}: StateContext<ConstantStateModel>, {copyright}: DeleteCopyright) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new DeleteCopyrightRequest(copyright));
  }

  /**
   * Delete copyright success
   * @param setState: StateContext<ConstantStateModel>
   * @param copyright: DeleteCopyrightSuccess
   */
  @Action(DeleteCopyrightSuccess)
  public deleteCopyrightSuccess({setState}: StateContext<ConstantStateModel>, {copyright}: DeleteCopyrightSuccess) {
    setState(
      patch({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        copyrights: removeItem(existing => existing.id === copyright.id)
      }));
  }
}
