import { Component } from '@angular/core';
import { MenuViewerFacade, SlideViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-viewer-poi-list',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss']
})
export class PoiComponent {
  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   */
  constructor(public slideViewerFacade: SlideViewerFacade, 
              public menuViewerFacade: MenuViewerFacade) {
  }
}
