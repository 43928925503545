import { MetadataEntityModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetEntitySuccess = '[STORE | ENTITY | METADATA] Get Entity Success',
  GetEntitiesSuccess = '[STORE | ENTITY | METADATA] Get Entities Success',
  AddEntity = '[STORE | ENTITY | METADATA] Add Entity',
  UpdateEntity = '[STORE | ENTITY | METADATA] Update Entity',
  RemoveEntity = '[STORE | ENTITY | METADATA] Remove Entity',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Entity Success
 */
export class GetEntitySuccess {
  public static readonly type = ActionTypes.GetEntitySuccess;

  public constructor(public entityQueryResult: MetadataEntityModels.MetadataEntityQueryResult) {
  }
}

/**
 * Get Entities Success
 */
export class GetEntitiesSuccess {
  public static readonly type = ActionTypes.GetEntitiesSuccess;

  public constructor(public entityQueryResults: MetadataEntityModels.MetadataEntityQueryResult[]) {
  }
}

/**
 * Add Entity
 */
export class AddEntity {
  public static readonly type = ActionTypes.AddEntity;

  public constructor(public entity: MetadataEntityModels.Metadata) {
  }
}

/**
 * Update Entity
 */
export class UpdateEntity {
  public static readonly type = ActionTypes.UpdateEntity;

  public constructor(public entity: MetadataEntityModels.Metadata) {
  }
}

/**
 * Remove Entity
 */
export class RemoveEntity {
  public static readonly type = ActionTypes.RemoveEntity;

  public constructor(public entity: MetadataEntityModels.Metadata) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>