import { Base } from './base.model';
import { Image } from './image.model';
import { PointOfInterest } from './point-of-interest.model';
import { Point } from './point.model';
import { Link } from './link.model';

export interface Annotation extends Base {
  label?: AnnotationLabel;
  source?: AnnotationSource;
  targets?: AnnotationTarget[];
  poi?: PointOfInterest;
  fork?: Point;
  type?: AnnotationType;
  selfTest?: SelfTest;
  image?: Image;
  link?: Link;

  state?: string;
  _labelVisible?: boolean;
}

export interface SelfTest {
  ignore?: boolean;
}

export enum AnnotationType {
  MARKER = 'Marker',
  FREE_FORM = 'FreeForm',
  TEXT = 'Text',
  KEYMAP = 'Keymap'
}

export interface AnnotationLabel extends Base {
  text?: string;
  description?: string;
  align?: string;
  pivot?: string;
  color?: string;
  border?: boolean;
  hidden?: boolean;
  borderColor?: string;
  borderWidth?: number;
  fontSize?: number;
  background?: MarkerBackground;
}

export interface AnnotationSource extends Base {
  position?: Point;
  zoom?: number;
  startType?: TipType;
}

export interface AnnotationTarget extends Base {
  position?: Point;
  endType?: TipType;
  endRadius?: number;
  color?: string;
}

export enum TipType {
  DOT = 'dot',
  ARROW = 'arrow',
  CIRCLE = 'circle',
  NONE = 'none'
}

export class MarkerBackground {
  color?: string;
  opacity?: number;
}
