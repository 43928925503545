import { Component } from '@angular/core';
import { MatomoControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent {
   /**
   * Constructor
   *
   */
  constructor(
    public matomoService: MatomoControlService
  ) {}

  /**
   * Consent
   */
  public consent() {
    this.matomoService.giveConsent();
  }
}
