<!-- Content -->
<div class="mdsf-container">
  <div class="mdsf-container mdsf-container-center mdsf-container-middle error-message-container">
    <i class="icon-error"></i>
    <section class="error-message">
      <h1 *ngIf="setTitle('upps' | translate)">{{ 'upps' | translate }}</h1>
      <p>{{ 'site_not_found' | translate }}</p>
      <button class="mdsf-btn"
              (click)="goToHomepage()">
        <i class="fa fa-chevron-left"></i> {{ 'homepage' | translate }}
      </button>
    </section>
  </div>
</div>
