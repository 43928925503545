// <editor-fold desc="Action Types">

/**
 * Ws action types
 */
export enum WsActionTypes {
  /**
   * Store
   */
  Connect = '[STORE | WS] Connect',
  Disconnect = '[STORE | WS] Disconnect',
  Ping = '[STORE | WS] Ping',
  Pong = '[STORE | WS] Pong',
  SetIsConnected = '[STORE | WS] Set IsConnected',
  SetIsExpired = '[STORE | WS] Set isExpired'
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Connect
 */
export class Connect {
  public static readonly type = WsActionTypes.Connect;

  public constructor() {
  }
}

/**
 * Disconnect
 */
export class Disconnect {
  public static readonly type = WsActionTypes.Disconnect;

  public constructor() {
  }
}

/**
 * Ping
 */
export class Ping {
  public static readonly type = WsActionTypes.Ping;

  public constructor() {
  }
}

/**
 * Pong
 */
export class Pong {
  public static readonly type = WsActionTypes.Pong;

  public constructor(public apiVersion: string) {
  }
}

/**
 * Set IsConnected
 */
export class SetIsConnected {
  public static readonly type = WsActionTypes.SetIsConnected;

  public constructor(public isConnected: boolean) {
  }
}

/**
 * Set IsExpired
 */
export class SetIsExpired {
  public static readonly type = WsActionTypes.SetIsExpired;

  public constructor(public isExpired: boolean) {
  }
}

// </editor-fold>
