import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { SlideMainComponent } from './slide/main/slide-main.component';
import { SlideComponent } from './slide/slide.component';

@Injectable()
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
  /**
   * Members
   */
  private storedRouteHandles = new Map<string, DetachedRouteHandle>();

  /**
   * Should Reuse Route
   *
   * @param future: ActivatedRouteSnapshot
   * @param curr: ActivatedRouteSnapshot
   */
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig && curr.routeConfig?.component !== SlideMainComponent;
  }

  /**
   * Retrieve
   *
   * @param route: ActivatedRouteSnapshot
   */
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRouteHandles.get(this.path(route)) as DetachedRouteHandle;
  }

  /**
   * Should Attach
   *
   * @param route: ActivatedRouteSnapshot
   */
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.storedRouteHandles.has(this.path(route));
  }

  /**
   * Should Detach
   *
   * @param route: ActivatedRouteSnapshot
   */
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.component !== SlideMainComponent &&
      route.component !== SlideComponent &&
      route.routeConfig.path !== ':eLearningSlug/:subjectSlug/:chapterSlug/:blockSlug/:slideSlug';
  }

  /**
   * Store
   *
   * @param route: ActivatedRouteSnapshot
   * @param detachedTree: DetachedRouteHandle
   */
  public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.path(route), detachedTree);
  }

  /**
   * Path
   *
   * @param route: ActivatedRouteSnapshot
   * @private
   */
  private path(route: ActivatedRouteSnapshot) {
    if (route.url.length > 0) {
      return route.url.map(url => url.path).join('/');
    }
    if (route.parent) {
      return this.path(route.parent);
    }
    return '';
  }
}
