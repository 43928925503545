import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { BaseGuardChainFacade } from '@medsurf/flat-facades';

@Injectable({
  providedIn: 'root'
})
export class NavigationControlGuard implements CanActivate {
  /**
   * Constructor
   *
   * @param baseGuardChainFacade: BaseGuardChainFacade
   */
  constructor(public baseGuardChainFacade: BaseGuardChainFacade) {}

  /**
   * Can Activate
   *
   * @param next: ActivatedRouteSnapshot
   */
  public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    this.baseGuardChainFacade.requestSetActivatedRoute(next);
    return Promise.resolve(true);
  }
}
