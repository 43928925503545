import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as FlatModels from '@medsurf/flat-models';
import { ModalityEntityActions } from '@medsurf/flat-actions';
import { Undoable } from '@medsurf/flat-decorators';
import { PageEntityState } from '../../internal';

/**
 * Modality Entity Token
 */
export const MODALITY_ENTITY_TOKEN = new StateToken<ModalityEntityStateModel>(FlatModels.EntityControlModels.EntityQueryTypes.MODALITY);

/**
 * Modality Entity State Model
 */
export type ModalityEntityStateModel = FlatModels.HistoryControlEntityStatesModels.HistoryControlEntityStateModel<FlatModels.ModalityEntityModels.Modality>;

/**
 * Modality Entity State
 */
@State<ModalityEntityStateModel>({
  name: MODALITY_ENTITY_TOKEN,
  defaults: {
    snapshots: {},
    entities: {},
  }
})
@Injectable()
export class ModalityEntityState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: ModalityEntityStateModel
   */
  @Selector([ModalityEntityState])
  public static typedEntities$(state: ModalityEntityStateModel): { [id: string]: FlatModels.ModalityEntityModels.Modality } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: FlatModels.ModalityEntityModels.Modality }
   */
  @Selector([ModalityEntityState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: FlatModels.ModalityEntityModels.Modality }): FlatModels.ModalityEntityModels.Modality[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: FlatModels.ModalityEntityModels.Modality }
   */
  @Selector([ModalityEntityState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: FlatModels.ModalityEntityModels.Modality }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    };
  }

  /**
   * Lazy Selector entitiesByIds$
   *
   * @param entities: FlatModels.ModalityEntityModels.Modality[]
   */
  @Selector([ModalityEntityState.entities$])
  public static entitiesByIds$(entities: FlatModels.ModalityEntityModels.Modality[]) {
    return (ids: string[]) => {
      return entities ? entities.filter(e => ids.includes(e.id)) : [];
    };
  }

  /**
   * Selector currentSelectedSlideModality$
   *
   * @param entities: FlatModels.ModalityEntityModels.Modality[]
   * @param currentSelectedSlidePage: FlatModels.SlideEntityModels.Slide | null
   */
  @Selector([
    ModalityEntityState.entities$,
    PageEntityState.currentSelectedSlidePage$
  ])
  public static currentSelectedSlideModality$(entities: FlatModels.ModalityEntityModels.Modality[],
                                              currentSelectedSlidePage: FlatModels.PageEntityModels.Slide | null) {
    return currentSelectedSlidePage && currentSelectedSlidePage.modality && Array.isArray(entities) && entities.find(e => e.id === currentSelectedSlidePage.modality as unknown as string) || null;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get Entity Success
   *
   * @param getState: StateContext<ModalityEntityStateModel>
   * @param patchState: StateContext<ModalityEntityStateModel>
   * @param entityQueryResult: ModalityEntityActions.GetEntitySuccess
   */
  @Action(ModalityEntityActions.GetEntitySuccess)
  public getEntitySuccess({getState, patchState}: StateContext<ModalityEntityStateModel>,
                          {entityQueryResult}: ModalityEntityActions.GetEntitySuccess) {
    const entities = Object.assign({}, getState().entities);
    entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    patchState({
      entities
    });
  }

  /**
   * Get Entities Success
   *
   * @param getState: StateContext<ModalityEntityStateModel>
   * @param patchState: StateContext<ModalityEntityStateModel>
   * @param entityQueryResults: ModalityEntityActions.GetEntitiesSuccess
   */
  @Action(ModalityEntityActions.GetEntitiesSuccess)
  public getEntitiesSuccess({getState, patchState}: StateContext<ModalityEntityStateModel>,
                            {entityQueryResults}: ModalityEntityActions.GetEntitiesSuccess) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      entities[entityQueryResult.entity.id] = entityQueryResult.entity;
    }
    patchState({
      entities: entities
    });
  }

  /**
   * Add Entity
   *
   * @param getState: StateContext<ModalityEntityStateModel>
   * @param patchState: StateContext<ModalityEntityStateModel>
   * @param entity: ModalityEntityActions.AddEntity
   */
  @Action(ModalityEntityActions.AddEntity)
  @Undoable(ModalityEntityActions.AddEntity, MODALITY_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.ADD)
  public addEntity({getState, patchState}: StateContext<ModalityEntityStateModel>,
                   {entity}: ModalityEntityActions.AddEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Update Entity
   *
   * @param getState: StateContext<ModalityEntityStateModel>
   * @param patchState: StateContext<ModalityEntityStateModel>
   * @param entity: ModalityEntityActions.UpdateEntity
   */
  @Action(ModalityEntityActions.UpdateEntity)
  @Undoable(ModalityEntityActions.UpdateEntity, MODALITY_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.UPDATE)
  public updateEntity({getState, patchState}: StateContext<ModalityEntityStateModel>,
                      {entity}: ModalityEntityActions.UpdateEntity) {
    const entities = Object.assign({}, getState().entities);
    entities[entity.id] = entity;
    patchState({
      entities
    });
  }

  /**
   * Remove Entity
   *
   * @param getState: StateContext<ModalityEntityStateModel>
   * @param patchState: StateContext<ModalityEntityStateModel>
   * @param entity: ModalityEntityActions.RemoveEntity
   */
  @Action(ModalityEntityActions.RemoveEntity)
  @Undoable(ModalityEntityActions.RemoveEntity, MODALITY_ENTITY_TOKEN, FlatModels.HistoryControlModels.HistorySnapshotItemModifierType.REMOVE)
  public removeEntity({getState, patchState}: StateContext<ModalityEntityStateModel>,
                      {entity}: ModalityEntityActions.RemoveEntity) {
    const entities = Object.assign({}, getState().entities);
    delete entities[entity.id];
    patchState({
      entities
    });
  }

  //</editor-fold>
}
