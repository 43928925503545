import { NodeEntityModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetEntitySuccess = '[STORE | ENTITY | NODE] Get Entity Success',
  GetRootEntitySuccess = '[STORE | ENTITY | NODE] Get Root Entity Success',
  GetEntitiesSuccess = '[STORE | ENTITY | NODE] Get Entities Success',
  GetRootEntitiesSuccess = '[STORE | ENTITY | NODE] Get Root Entities Success',
  AddEntity = '[STORE | ENTITY | NODE] Add Entity',
  UpdateEntity = '[STORE | ENTITY | NODE] Update Entity',
  RemoveEntity = '[STORE | ENTITY | NODE] Remove Entity',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Entity Success
 */
export class GetEntitySuccess {
  public static readonly type = ActionTypes.GetEntitySuccess;

  public constructor(public entityQueryResult: NodeEntityModels.NodeEntityQueryResult) {
  }
}
/**
 * Get Root Entity Success
 */
export class GetRootEntitySuccess {
  public static readonly type = ActionTypes.GetRootEntitySuccess;

  public constructor(public entityQueryResult: NodeEntityModels.NodeEntityQueryResult) {
  }
}

/**
 * Get Entities Success
 */
export class GetEntitiesSuccess {
  public static readonly type = ActionTypes.GetEntitiesSuccess;

  public constructor(public entityQueryResults: NodeEntityModels.NodeEntityQueryResult[]) {
  }
}

/**
 * Get Root Entities Success
 */
export class GetRootEntitiesSuccess {
  public static readonly type = ActionTypes.GetRootEntitiesSuccess;

  public constructor(public entityQueryResults: NodeEntityModels.NodeEntityQueryResult[]) {
  }
}

/**
 * Add Entity
 */
export class AddEntity {
  public static readonly type = ActionTypes.AddEntity;

  public constructor(public entity: NodeEntityModels.Node | NodeEntityModels.ElearningNode | NodeEntityModels.SubjectNode
    | NodeEntityModels.ChapterNode | NodeEntityModels.BlockNode | NodeEntityModels.SlideNode) {
  }
}

/**
 * Update Entity
 */
export class UpdateEntity {
  public static readonly type = ActionTypes.UpdateEntity;

  public constructor(public entity: NodeEntityModels.Node | NodeEntityModels.ElearningNode | NodeEntityModels.SubjectNode
    | NodeEntityModels.ChapterNode | NodeEntityModels.BlockNode | NodeEntityModels.SlideNode) {
  }
}

/**
 * Remove Entity
 */
export class RemoveEntity {
  public static readonly type = ActionTypes.RemoveEntity;

  public constructor(public entity: NodeEntityModels.Node | NodeEntityModels.ElearningNode | NodeEntityModels.SubjectNode
    | NodeEntityModels.ChapterNode | NodeEntityModels.BlockNode | NodeEntityModels.SlideNode) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>