<div class="mdsf-notice"
     *ngIf="showNotice">
  <div class="container">
    <button class="btn"
            (click)="dismissNotice()"
            title="{{ 'close_modal' | translate }}">
      <i class="fa fa-fw fa-close"></i>
    </button>
    <div>
      <h1>Ihr Browser ist veraltet!</h1>
      <p>Bitte aktualisieren Sie Ihren Browser, um diese Website korrekt darzustellen.</p>
    </div>
  </div>
</div>
