import { ChangeDetectorRef, Component, Input } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { VocabularyViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-vocabulary',
  templateUrl: './vocabulary.component.html',
  styleUrls: ['./vocabulary.component.scss']
})
export class VocabularyComponent {
  /**
   * Inputs
   */
  @Input() public vocabulary: FlatModels.PageEntityModels.Vocabulary;

  /**
   * Members
   */
  public currentChar = '';
  public filter = '';

  /**
   * Constructor
   *
   * @param ref: ChangeDetectorRef
   * @param vocabularyViewerFacade: VocabularyViewerFacade
   */
  constructor(public ref: ChangeDetectorRef,
              public vocabularyViewerFacade: VocabularyViewerFacade) {
  }

  /**
   * Get Current Char
   *
   * @param vocabularyWord: FlatModels.VocabularyWordEntityModels.VocabularyWord
   */
  public getCurrentChar(vocabularyWord: FlatModels.VocabularyWordEntityModels.VocabularyWord) {
    const firstLetter = vocabularyWord.key[0].toUpperCase();
    if (firstLetter !== this.currentChar) {
      this.currentChar = firstLetter;
      return firstLetter;
    }
    return undefined;
  }

  /**
   * Set Filter
   *
   * @param filter: string
   */
  public setFilter(filter: string) {
    this.filter = filter.trim().toLowerCase();
    this.ref.detectChanges();
  }

  /**
   * Filter Vocabulary Words
   *
   * @param vocabularyWords: FlatModels.VocabularyWordEntityModels.VocabularyWord[]
   */
  public filterVocabularyWords(vocabularyWords: FlatModels.VocabularyWordEntityModels.VocabularyWord[]) {
    return vocabularyWords.filter(vocabularyWord => `${vocabularyWord.key} ${vocabularyWord.value}`.toLowerCase().includes(this.filter));
  }
}
