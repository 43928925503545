export class Url {
  /**
   * Get Semantic String
   *
   * @param value: string
   */
  public static getSemanticString(value: string) {
    return value
      .replace(/(\s+|\/)/g, '-')
      .replace(/[,.()]/g, '')
      .replace(/[-]{2,}/g, '-')
      .toLowerCase();
  }

  /**
   * Format Url
   *
   * @param url: string
   */
  public static decodeUrl(url: string) {
    return decodeURIComponent(url).replace(/^\//, '').replace(/\/$/, '');
  }
}
