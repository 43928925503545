import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as FlatStates from '@medsurf/flat-states';
import { MediaControlService } from '@medsurf/flat-services';

/**
 * Image Viewer Facade
 */
@Injectable()
export class ImageViewerFacade {
  //<editor-fold desc="Selectors">

  public config$ = this.store.select(FlatStates.ImageViewerState.config$);
  public slide$ = this.store.select(FlatStates.PageEntityState.currentSelectedSlidePage$);
  public image$ = this.store.select(FlatStates.ImageEntityState.currentSelectedSlideImage$);
  public medias$ = this.store.select(FlatStates.MediaEntityState.currentSelectedSlideValidMedias$);
  public media$ = this.store.select(FlatStates.MediaEntityState.currentSelectedSlideMedia$);
  public annotations$ = this.store.select(FlatStates.ImageViewerState.annotations$);

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   * @param mediaControlService: MediaControlService
   */
  constructor(public store: Store,
              public mediaControlService: MediaControlService) {
  }
}
