import { MenuViewerModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action types
 */
export enum ActionTypes {
  SetViewerDisplayMode = '[STORE | VIEWER | MENU] Set Viewer Display Mode',
  SetViewerPrintMode = '[STORE | VIEWER | MENU] Set Viewer Print Mode',
  ToggleVirtualPointer= '[STORE | VIEWER | MENU] Toggle Virtual Pointer',
  ToggleNavigator= '[STORE | VIEWER | MENU] Toggle Navigator',
  ToggleMarker= '[STORE | VIEWER | MENU] Toggle Marker',
  ToggleSelftest= '[STORE | VIEWER | MENU] Toggle Selftest',
  ToggleCaseHistory= '[STORE | VIEWER | MENU] Toggle Case History',
  ToggleQuizFeedback = '[STORE | VIEWER | MENU] Toggle Quiz Feedback',
  ToggleQuizSolution = '[STORE | VIEWER | MENU] Toggle Quiz Solution',
  ToggleQuizExplanation = '[STORE | VIEWER | MENU] Toggle Quiz Explanation',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Set Viewer Display Mode
 */
export class SetViewerDisplayMode {
  public static readonly type = ActionTypes.SetViewerDisplayMode;

  public constructor(public viewerDisplayMode: MenuViewerModels.ViewerDisplayMode) {
  }
}

/**
 * Set Viewer Print Mode
 */
export class SetViewerPrintMode {
  public static readonly type = ActionTypes.SetViewerPrintMode;

  public constructor(public viewerPrintMode: MenuViewerModels.ViewerPrintMode) {
  }
}

/**
 * Toggle Virtual Pointer
 */
export class ToggleVirtualPointer {
  public static readonly type = ActionTypes.ToggleVirtualPointer;
}

/**
 * Toggle Navigator
 */
export class ToggleNavigator {
  public static readonly type = ActionTypes.ToggleNavigator;

  public constructor(public override: boolean | null = null) {
  }
}

/**
 * Toggle Marker
 */
export class ToggleMarker {
  public static readonly type = ActionTypes.ToggleMarker;

  public constructor(public override: boolean | null = null) {
  }
}

/**
 * Toggle Selftest
 */
export class ToggleSelftest {
  public static readonly type = ActionTypes.ToggleSelftest;
}

/**
 * Toggle Case History
 */
export class ToggleCaseHistory {
  public static readonly type = ActionTypes.ToggleCaseHistory;
}

/**
 * Toggle Quiz Feedback
 */
export class ToggleQuizFeedback {
  public static readonly type = ActionTypes.ToggleQuizFeedback;
}

/**
 * Toggle Quiz Solution
 */
export class ToggleQuizSolution {
  public static readonly type = ActionTypes.ToggleQuizSolution;
}

/**
 * Toggle Quiz Explanation
 */
export class ToggleQuizExplanation {
  public static readonly type = ActionTypes.ToggleQuizExplanation;
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
