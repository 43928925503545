<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="{ showSelftest: (menuViewerFacade.showSelftest$ | async), groups: (slideViewerFacade.currentSelectedSlideAreaGroups$ | async) | sortBy_shared:'asc':'name', selectedAreaGroup: (slideViewerFacade.currentSelectedSlideAreaGroup$ | async)} as data; else spinner">
  <ng-container *ngIf="data.groups.length > 0">
    <h3 class="areas-title my-3">
      {{"interactive_areas" | translate}}
      <button class="tooltip-button"
              [ngbTooltip]="('interactive_area_tutorial' | translate)"
              container="body"
              tooltipClass="mdsf-tooltip">
        <i class="far fa-fw fa-circle-question"></i>
      </button>
    </h3>
    <div class="areas-list mt-2 mb-4">
      <ng-container *ngFor="let group of data.groups">
        <div class="card collapsable"
             [class.active]="expandedGroupId === group.id"
             [class.selected]="data.selectedAreaGroup === group"
             (mouseenter)="setAreaGroupId(group.id, data.showSelftest)"
             (mouseleave)="setAreaGroupId(null, data.showSelftest)">
          <div class="card-header"
               (click)="toggleGroup(group.id, data.showSelftest)">
            <h4 class="card-title mb-0"
                [ngClass]="[expandedGroupId === group.id || data.selectedAreaGroup === group ? (slideViewerFacade.themeColor$ | async) : '']">
              {{ group.name }}
            </h4>
          </div>
          <div class="card-body"
               *ngIf="group.description && (expandedGroupId === group.id || (menuViewerFacade.showPrint$ | async))">
            <medsurf-text-format [text]="group.description"></medsurf-text-format>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
