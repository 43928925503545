<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<div class="mdsf-carousel p-0 m-0 w-100" *ngIf="(appSharedFacade.metadata$ | async) as metadata; else spinner">
  <!-- Image -->
  <ng-container *ngIf="metadata.carouselMedia && (appSharedFacade.media$(metadata.carouselMedia) | async) as media">
    <ng-container *ngIf="mediaControlService.getUrl(media) as mediaSrc">
      <img class="mdsf-carousel-image w-100 h-100"
           ngSrc="{{ mediaSrc }}"
           fill="true"
           priority
           alt="decorative Carousel image">
    </ng-container>
  </ng-container>

  <!-- Title -->
  <h1 *ngIf="metadata.carouselTitle"
      class="mdsf-carousel-header text-uppercase"
      [style]="{ color: metadata.carouselTextColor }"
      [innerHTML]="metadata.carouselTitle">
  </h1>

  <!-- Copyright -->
  <p *ngIf="metadata.carouselCopyright"
     class="mdsf-carousel-copyright"
     [style]="{ color: metadata.carouselTextColor }"
     [innerHTML]="metadata.carouselCopyright">
  </p>
</div>
