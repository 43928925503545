import paper from 'paper';
import { ImageViewerModels } from '@medsurf/flat-models';
import { End } from '../marker/pointer/end/end';

export class Free {
  /**
   * Members
   */
  private _element: any;
  private _segments: any[];
  private _startType: any;
  private _endType: any;
  private _start: End;
  private _end: End;

  /**
   * Constructor
   *
   * @param _format
   * @param _marker
   * @param _model
   */
  public constructor(private _format,
                     private _marker,
                     private _model: ImageViewerModels.FreeformModel) {
    this._element = null;
    this._segments = [];
    this._endType = this._model.freeFormStyle.endType;
    this._startType = this._model.freeFormStyle.startType;

    this._init();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
    this.draw();
  }

  /**
   * Draw
   */
  public draw() {
    let start, end;

    if (this._element) {
      this.cleanUp();
    }

    if (this._model.annotation.path && this._model.annotation.path.length > 1) {
      for (const pathPoint of  this._model.annotation.path) {
        this._segments.push(new paper.Point(this._marker.container.getAbsoluteCoords(
          pathPoint.x,
          pathPoint.y,
          this._marker._imageOffset,
          this._marker._imageScale
        )));
      }
      start = new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.annotation.path[0].x,
        this._model.annotation.path[0].y,
        this._marker._imageOffset,
        this._marker._imageScale
      ));
      end = new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.annotation.path[this._model.annotation.path.length - 1].x,
        this._model.annotation.path[this._model.annotation.path.length - 1].y,
        this._marker._imageOffset,
        this._marker._imageScale
      ));
    } else {
      this._segments.push(new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.source.position.x,
        this._model.source.position.y,
        this._marker._imageOffset,
        this._marker._imageScale
      )));
      this._segments.push(end = new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.source.position.x + this._model.freeFormStyle.width,
        this._model.source.position.y + this._model.freeFormStyle.height,
        this._marker._imageOffset,
        this._marker._imageScale
      )));
      start = new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.source.position.x,
        this._model.source.position.y,
        this._marker._imageOffset,
        this._marker._imageScale
      ));
      end = new paper.Point(this._marker.container.getAbsoluteCoords(
        this._model.source.position.x + this._model.freeFormStyle.width,
        this._model.source.position.y + this._model.freeFormStyle.height,
        this._marker._imageOffset,
        this._marker._imageScale
      ));
    }

    this._element = new paper.Path(this._segments);

    this._element.strokeColor = this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor;
    this._element.strokeWidth = this._model.freeFormStyle.strokeWidth || this._format.strokeWidth;
    this._element.dashArray = this._model.freeFormStyle.dash ?
      this._model.freeFormStyle.dash
        .split(',')
        .map((dash) => <any>dash *
          (this._marker && this._marker._model.freeFormStyle && this._marker._model.freeFormStyle.strokeWidth ?
              this._marker._model.freeFormStyle.strokeWidth :
              1
          )) :
      [1, 0];
    this._element._model = this._model;

    if (this._model.freeFormStyle.closePath) {
      this._element.closed = true;
      this._element.fillColor = this._model.freeFormStyle.background ?
        (this._model.freeFormStyle.backgroundInBorderColor ?
          this._model.freeFormStyle.strokeColor || this._model.freeFormStyle.color || this._format.border.strokeColor :
          this._model.freeFormStyle.color || this._format.border.fillColor) :
        undefined;
      if (this._element.fillColor) {
        this._element.fillColor.alpha = this._model.freeFormStyle.opacity;
      }
    }

    if (this._model.freeFormStyle.smooth) {
      this._element.smooth({
        type: 'geometric',
        factor: this._model.freeFormStyle.smoothFactor ?? 0
      });
    }

    this._element.freeform = this._marker;
    this._marker._element.addChild(this._element);

    if (!this._model.freeFormStyle.closePath) {
      this.drawStart(start);
      this.drawEnd(end);
    }
  }

  /**
   * Draw Start
   *
   * @param start
   */
  public drawStart(start) {
    this._format.strokeColor = this._element.strokeColor;
    this._format.circleRadius = this._model.freeFormStyle.startRadius || 10;
    this._start = new End(this._startType, this._format, start, this._marker);

    if (this._startType === 'dot' || this._startType === 'arrow') {
      this._start.element.element.firstChild.freeform = this._marker;
      this._marker._element.addChild(this._start.element.element.firstChild);
      this._start.element.element.radius = this._start.element.element.radius +
        (this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth);
    } else {
      this._start.element.element.freeform = this._marker;
      this._marker._element.addChild(this._start.element.element);
    }

    if (this._startType === 'arrow') {
      this._start.element.path.rotation = 90 + this._start.element.angle(
        this._element.segments[1].point.x,
        this._element.segments[1].point.y,
        this._element.segments[0].point.x,
        this._element.segments[0].point.y
      );
    } else if (this._startType === 'circle') {
      const intersections = this._element.segments[0].path.getIntersections(this._start.element.element);
      if (intersections.length) {
        this._element.segments[0].point = intersections[0].point;
      }
      this._start.element.element.strokeWidth = this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth;
    }
  }

  /**
   * Draw End
   *
   * @param end
   */
  public drawEnd(end) {
    this._format.strokeColor = this._element.strokeColor;
    this._format.circleRadius = this._model.freeFormStyle.endRadius || 10;
    this._end = new End(this._endType, this._format, end, this._marker);

    if (this._endType === 'dot' || this._endType === 'arrow') {
      this._end.element.element.firstChild.freeform = this._marker;
      this._marker._element.addChild(this._end.element.element.firstChild);
      this._end.element.element.radius = this._end.element.element.radius +
        (this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth);
    } else {
      this._end.element.element.freeform = this._marker;
      this._marker._element.addChild(this._end.element.element);
    }

    if (this._endType === 'arrow') {
      this._end.element.path.rotation = 90 + this._end.element.angle(
        this._element.segments[this._element.segments.length - 2].point.x,
        this._element.segments[this._element.segments.length - 2].point.y,
        this._element.segments[this._element.segments.length - 1].point.x,
        this._element.segments[this._element.segments.length - 1].point.y
      );
    } else if (this._endType === 'circle') {
      const intersections = this._element.segments[0].path.getIntersections(this._end.element.element);
      if (intersections.length) {
        this._element.segments[this._element.segments.length - 1].point = intersections[0].point;
      }
      this._end.element.element.strokeWidth = this._model.freeFormStyle.strokeWidth || this._format.border.strokeWidth;
    }
  }

  /**
   * Getter element
   */
  public get element() {
    return this._element;
  }

  /**
   * Set Scale
   */
  public setScale() {
    this.element.strokeWidth = this._format.strokeWidth;
  }

  /**
   * Clean Up
   */
  public cleanUp() {
    if (this._element) {
      this._element.remove();
      this._element = null;
    }
  }
}
