import { Inject, Injectable } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { FLAT_SETTINGS, SettingControlModel } from '../../internal';

@Injectable({
  providedIn: 'root',
})
export class MediaControlService {
  /**
   * Constructor
   *
   * @param settings
   */
  public constructor(@Inject(FLAT_SETTINGS) private settings: SettingControlModel) {
  }

  /**
   * Get Url
   *
   * @param media: FlatModels.MediaEntityModels.MediaEntityType
   * @param fileType: string
   * @param forceApi: boolean
   */
  public getUrl(media: FlatModels.MediaEntityModels.MediaEntityType,
                fileType: 'file' | 'originalFile' | 'thumbnail' | 'tileFolder' | 'poster' = 'file',
                forceApi = false): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const url = media?.[fileType];
    let hash = '';
    if (fileType !== 'tileFolder') {
      if (media.checksum) {
        hash = '?' + media.checksum;
        if (media.copyrights?.length !== 0) {
          hash += '&' + new Date(media.editedAt as Date).getTime();
        }
      }
      if (media.type === FlatModels.MediaEntityModels.MediaType.VIDEO && (fileType === 'poster' || fileType === 'thumbnail') ) {
        hash = '?' + new Date(media.editedAt as Date).getTime();
      }
    }
    if (!url) {
      return '';
    }
    return (media.access === FlatModels.MediaEntityModels.MediaAccess.PRIVATE || forceApi) && fileType !== 'tileFolder' ?
      this.settings.endpointUrl + 'v1/file/' + url + hash :
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.settings.s3endpointUrl + this.settings.s3Bucket + '/' + url + hash;
  }

  /**
   * Is Video
   *
   * @param media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType
   */
  public isVideo(media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType): boolean {
    return (typeof media === 'string' ? media : media?.type) === FlatModels.MediaEntityModels.MediaType.VIDEO;
  }

  /**
   * Is Audio
   *
   * @param media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType
   */
  public isAudio(media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType): boolean {
    return (typeof media === 'string' ? media : media?.type) === FlatModels.MediaEntityModels.MediaType.AUDIO;
  }

  /**
   * Is Document
   *
   * @param media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType
   */
  public isDocument(media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType): boolean {
    return (typeof media === 'string' ? media : media?.type) === FlatModels.MediaEntityModels.MediaType.DOCUMENT;
  }

  /**
   * Is Deepzoom
   *
   * @param media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType
   */
  public isDeepzoom(media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType): boolean {
    return (typeof media === 'string' ? media : media?.type) === FlatModels.MediaEntityModels.MediaType.DEEPZOOM;
  }

  /**
   * Is Image
   *
   * @param media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType
   */
  public isImage(media: FlatModels.MediaEntityModels.MediaEntityType | FlatModels.MediaEntityModels.MediaType): boolean {
    return (typeof media === 'string' ? media : media?.type) === FlatModels.MediaEntityModels.MediaType.IMAGE;
  }

  /**
   * Get Media Type Icon
   *
   * @param media: FlatModels.MediaEntityModels.MediaEntityType
   */
  public getMediaTypeIcon(media: FlatModels.MediaEntityModels.MediaEntityType): string {
    switch (media?.type) {
      case FlatModels.MediaEntityModels.MediaType.DOCUMENT:
        return 'fa-file';
      case FlatModels.MediaEntityModels.MediaType.AUDIO:
        return 'fa-volume-up';
      case FlatModels.MediaEntityModels.MediaType.IMAGE:
        return 'fa-image';
      case FlatModels.MediaEntityModels.MediaType.DEEPZOOM:
        return 'fa-th';
      case FlatModels.MediaEntityModels.MediaType.VIDEO:
        return 'fa-video';
      default:
        return 'fa-text';
    }
  }

  /**
   * Get Media Type Label
   *
   * @param media: FlatModels.MediaEntityModels.MediaEntityType
   */
  public getMediaTypeLabel(media: FlatModels.MediaEntityModels.MediaEntityType): string {
    switch (media?.type) {
      case FlatModels.MediaEntityModels.MediaType.DOCUMENT:
        return 'file';
      case FlatModels.MediaEntityModels.MediaType.AUDIO:
        return 'audio';
      case FlatModels.MediaEntityModels.MediaType.IMAGE:
        return 'image';
      case FlatModels.MediaEntityModels.MediaType.DEEPZOOM:
        return 'deepzoom';
      case FlatModels.MediaEntityModels.MediaType.VIDEO:
        return 'video';
      default:
        return 'text';
    }
  }
}