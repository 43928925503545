/**
 * Error level
 */
export enum ErrorLevel {
  critical,
  error,
  system,
  debug,
  warning,
  notice,
  info
}

/**
 * Error instance
 */
export enum ErrorInstance {
  API,
  AUTHOR,
  VIEWER,
}

/**
 * Error Options
 */
export interface ErrorOptions {
  show?: boolean;
  logout?: boolean;
  redirectToLogin?: boolean;
  redirectToErrorPage?: boolean;
  httpCode?: number;
}

/**
 * Medsurf Error
 */
export class MedsurfError extends Error {
  /**
   * Members
   */
  public timestamp: number;

  /**
   * Constructor
   *
   * @param message: string
   * @param level: ErrorLevel
   * @param instance: ErrorInstance
   * @param errorCode: string
   * @param options: ErrorOptions
   * @param args: any[]
   */
  public constructor(message: string,
                     public level: ErrorLevel,
                     public instance: ErrorInstance,
                     public errorCode: string,
                     public options: ErrorOptions = {},
                     public args: any[] = []) {
    super(message);
    this.name = 'MedsurfError';
    this.timestamp = Date.now();
  }
}


/**
 * Medsurf Unauthorized Error
 */
export class MedsurfUnauthorizedError extends MedsurfError {
  /**
     * Constructor
     *
     * @param message: string
     * @param options: ErrorOptions
     * @param args: any[]
     */
  public constructor(message: string, 
                     options: ErrorOptions = {},
                     public override args: any[] = []) {

    const defaultOptions: ErrorOptions = {
      httpCode: 401,
      redirectToLogin: true
    };
    super(message, ErrorLevel.error, ErrorInstance.API, 'unauthorized', Object.assign(defaultOptions, options));
  }
}


/**
 * Medsurf Unauthorized Error
 */
export class MedsurfNotFoundError extends MedsurfError {
  /**
     * Constructor
     *
     * @param message: string
     * @param options: ErrorOptions
     * @param args: any[]
     */
  public constructor(message: string, 
                     options: ErrorOptions = {},
                     public override args: any[] = []) {

    const defaultOptions: ErrorOptions = {
      httpCode: 404,
      redirectToErrorPage: true
    };
    super(message, ErrorLevel.error, ErrorInstance.API, 'not_found', Object.assign(defaultOptions, options));
  }
}