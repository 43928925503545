<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Breadcrumb Separator -->
<ng-template #breadcrumb_separator_template>
  <div class="mdsf-breadcrum d-none d-md-flex">
    <span class="mdsf-breadcrumb-separator">&nbsp;|&nbsp;</span>
  </div>
</ng-template>

<!-- Breadcrumb Entry -->
<ng-template #breadcrumb_entry_template let-page="page" let-tree="tree" let-themeColor="themeColor">
  <div class="mdsf-breadcrum d-none d-md-flex" [routerLink]="['/' + tree.path]">
    <span><a [routerLink]="['/' + tree.path]" class="{{ themeColor }}">{{ page.title }}</a></span>
  </div>
</ng-template>

<!-- Breadcrumbs -->
<ng-template #breadcrumbs_template>
  <div class="mdsf-title truncate" *ngIf="(appSharedFacade.themeColor$ | async) as themeColor">
    <!-- Elearning -->
    <ng-container *ngIf="(nodeSharedFacade.currentSelectedElearningNode$ | async) as elearning">
      <ng-container *ngTemplateOutlet="breadcrumb_entry_template;context:{
              page: (appSharedFacade.page$(elearning.page) | async),
              tree: (appSharedFacade.tree$(elearning.id) | async),
              themeColor: themeColor}">
      </ng-container>
    </ng-container>
    <!-- Subject -->
    <ng-container *ngIf="(nodeSharedFacade.currentSelectedSubjectNode$ | async) as subject">
      <ng-container *ngTemplateOutlet="breadcrumb_separator_template"></ng-container>
      <ng-container *ngTemplateOutlet="breadcrumb_entry_template;context:{
              page: (appSharedFacade.page$(subject.page) | async),
              tree: (appSharedFacade.tree$(subject.id) | async),
              themeColor: themeColor}">
      </ng-container>
    </ng-container>
    <!-- Chapter -->
    <ng-container *ngIf="(nodeSharedFacade.currentSelectedChapterNode$ | async) as chapter">
      <ng-container *ngTemplateOutlet="breadcrumb_separator_template"></ng-container>
      <ng-container *ngTemplateOutlet="breadcrumb_entry_template;context:{
              page: (appSharedFacade.page$(chapter.page) | async),
              tree: (appSharedFacade.tree$(chapter.id) | async),
              themeColor: themeColor}">
      </ng-container>
    </ng-container>
    <!-- Block -->
    <ng-container *ngIf="(nodeSharedFacade.currentSelectedBlockNode$ | async) as block">
      <ng-container *ngTemplateOutlet="breadcrumb_separator_template"></ng-container>
      <ng-container *ngTemplateOutlet="breadcrumb_entry_template;context:{
              page: (appSharedFacade.page$(block.page) | async),
              tree: (appSharedFacade.tree$(block.id) | async),
              themeColor: themeColor}">
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- Dropdown -->
<ng-template #dropdown_template let-user="user" let-settings="settings" let-page="page">
  <div ngbDropdown
       #menu="ngbDropdown"
       class="left d-flex d-md-none"
       container="body">
    <div ngbDropdownToggle
         class="mdsf-btn fa fa-2x fa-bars"></div>
    <div ngbDropdownMenu
         aria-labelledby="home-menu">
      <button ngbDropdownItem
              [routerLink]="'/'"><i class="fa fa-home-alt"></i> Home</button>
      <ng-container *ngIf="user === null">
        <a [routerLink]="['/' , 'login']" [queryParams]="{r: (navigationControlFacade.currentUrl$ | async) ? '/' + (navigationControlFacade.currentUrl$ | async) : '/'}" ngbDropdownItem>
          <i class="fa fa-sign-in"></i> {{ 'sign_in' | translate }}
        </a>
      </ng-container>
      <button ngbDropdownItem
              *ngIf="settings && !settings.hideDownloadPdf && page && page.pdfMedia && {pdfMedia: (appSharedFacade.media$(page.pdfMedia) | async), pdfMediaAuthor: (appSharedFacade.media$(page.pdfMediaAuthor) | async)} as pdfMetaData"
              (click)="downloadPDF(pdfMetaData.pdfMedia, pdfMetaData.pdfMediaAuthor, user)">
        <i class="fa fa-download"></i> {{ 'show_pdf' | translate }}
      </button>
      <button ngbDropdownItem
              (click)="openModal()"
              *ngIf="(navigationControlFacade.showSearch$ | async)">
        <i class="fa fa-search"></i> {{ 'search' | translate }}
      </button>
      <button ngbDropdownItem
              (click)="logout()"
              *ngIf="user">
        <i class="fa fa-sign-out"></i> {{ 'sign_out' | translate }}
      </button>
      <medsurf-sidebar [showCurrentSubject]="true"
                       (blockSelected)="menu.close()"
                       (specialPageSelected)="menu.close()">
      </medsurf-sidebar>
      <button ngbDropdownItem
              (click)="navigationControlService.selectLink({path: 'seite', params: {pageSlug: 'privacy'}})">
        DATENSCHUTZERKLÄRUNG
      </button>
      <button ngbDropdownItem
              (click)="navigationControlService.selectLink({path: 'seite', params: {pageSlug: 'impressum'}})">
        IMPRESSUM
      </button>
      <button ngbDropdownItem
              (click)="navigationControlService.selectLink({path: 'seite', params: {pageSlug: 'feedback'}})">
        FEEDBACK
      </button>
    </div>
  </div>
</ng-template>

<!-- Right -->
<ng-template #right_template let-user="user" let-settings="settings" let-page="page" let-currentUrl="currentUrl">
  <div class="right">
    <div class="mdsf-btn w-auto mdsf-hitarea"
         ngbDropdown>
      <div class="mdsf-btn mdsf-hitarea d-none d-md-flex"
           ngbDropdownToggle>
        <i class="fa fa-user"
           title="{{ 'user' | translate }}"></i>
      </div>
      <div aria-labelledby="simple-dropdown"
           ngbDropdownMenu>
        <ng-container *ngIf="user">
          <div class="px-4 py-2">
            <strong>{{ 'signed_in_as' | translate }}</strong><br>
            {{ user.email }}
          </div>
          <div class="dropdown-divider"></div>
          <a (click)="editInAuthor(currentUrl)"
             *ngIf="user?.role === 'admin'" ngbDropdownItem>
            <i class="fa fa-edit"></i> {{ 'edit' | translate }}
          </a>
          <a (click)="logout()"
             ngbDropdownItem>
            <i class="fa fa-sign-out"></i> {{ 'sign_out' | translate }}
          </a>
        </ng-container>
        <ng-container *ngIf="user === null">
          <a [routerLink]="['/' , 'login']" [queryParams]="{r: (navigationControlFacade.currentUrl$ | async) ? '/' + (navigationControlFacade.currentUrl$ | async) : '/'}" ngbDropdownItem>
            <i class="fa fa-sign-in"></i> {{ 'sign_in' | translate }}
          </a>
        </ng-container>
      </div>
    </div>
    <div class="mdsf-btn mdsf-hitarea d-none d-md-flex"
         [routerLink]=""
         *ngIf="settings && !settings.hideDownloadPdf && page && (page.pdfMedia || (page.pdfMediaAuthor && user && user?.role !== 'viewer')) && {pdfMedia: (appSharedFacade.media$(page.pdfMedia) | async), pdfMediaAuthor: (appSharedFacade.media$(page.pdfMediaAuthor) | async)} as pdfMetaData"
         (click)="downloadPDF(pdfMetaData.pdfMedia, pdfMetaData.pdfMediaAuthor, user)"
         [class.is-printing]="(navigationControlFacade.isPrinting$ | async)">
      <i class="fa fa-download" title="{{ 'open_print_page' | translate }}"></i>
    </div>
    <div class="mdsf-btn mdsf-hitarea d-none d-md-flex"
         (click)="openModal()"
         *ngIf="(navigationControlFacade.showSearch$ | async)">
      <i class="fa fa-search" title="{{ 'open_modal' | translate }}"></i>
    </div>
    <div class="mdsf-btn mdsf-hitarea"
         (click)="localClose()"
         *ngIf="(navigationControlFacade.showClose$ | async)">
      <i class="fa fa-close"></i>
    </div>
    <!--      <div class="mdsf-dropdown">-->
    <!--        <li ngbDropdown>-->
    <!--          <a id="language-dropdown" ngbDropdownToggle>{{ currentLanguage.toUpperCase() }}<b class="caret"></b></a>-->
    <!--          <ul ngbDropdownMenu aria-labelledby="simple-dropdown">-->
    <!--            <li ngbDropdownItem *ngFor="let langKey of availableLanguages"><a (click)="changeLanguage(langKey)">{{ langKey.toUpperCase() }}</a></li>-->
    <!--          </ul>-->
    <!--        </li>-->
    <!--      </div>-->
  </div>
</ng-template>

<!-- Content -->
<nav class="msf-navbar"
     role="navigation"
     *ngIf="{ themeColor: (appSharedFacade.themeColor$ | async), currentSelectedSlideTitle: (appSharedFacade.currentSelectedSlideTitle$ | async), currentSelectedNode: (nodeSharedFacade.currentSelectedNode$ | async), currentSelectedSettings: (nodeSharedFacade.currentSelectedSettings$ | async), user: (appSharedFacade.user$ | async)} as data">
  <div *ngIf="data.themeColor"
       class="hline {{ data.themeColor }}Background"></div>
  <div class="mdsf-container" *ngIf="setTitle(data.currentSelectedSlideTitle)">
    <div class="left mdsf-btn d-none d-md-flex" [routerLink]="'/'" *ngIf="showHome && !data.currentSelectedSettings?.hideHomeButton">
      <a [routerLink]="'/'"><i class="fa fa-home-alt black"></i></a>
      <!--<i class="icon icon_arrow-left dark"></i>-->
    </div>
    <ng-container *ngTemplateOutlet="dropdown_template;context:{
                  user: data.user,
                  settings: data.currentSelectedSettings,
                  page: (appSharedFacade.page$(data.currentSelectedNode?.page) | async)}">
    </ng-container>
    <div class="mdsf-title truncate" (click)="localTitleClicked()" *ngIf="(navigationControlFacade.elearningUrl$ | async) === null && (appSharedFacade.metadata$ | async) as metadata; else breadcrumbs_template">
      <!-- <span class="{{ data.themeColor }}">{{ (title$ | async) || (metadata$ | async)?.title }}</span> -->
      <span class="{{ data.themeColor }}">{{ metadata.title }}</span>
    </div>
    <ng-container *ngTemplateOutlet="right_template;context:{
                  user: data.user,
                  settings: data.currentSelectedSettings,
                  page: (appSharedFacade.page$(data.currentSelectedNode?.page) | async),
                  currentUrl: (navigationControlFacade.currentUrl$ | async)}">
    </ng-container>
  </div>
</nav>
