import { AnnotationTargetEntityModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetEntitySuccess = '[STORE | ENTITY | ANNOTATION_TARGET] Get Entity Success',
  GetEntitiesSuccess = '[STORE | ENTITY | ANNOTATION_TARGET] Get Entities Success',
  AddEntity = '[STORE | ENTITY | ANNOTATION_TARGET] Add Entity',
  UpdateEntity = '[STORE | ENTITY | ANNOTATION_TARGET] Update Entity',
  RemoveEntity = '[STORE | ENTITY | ANNOTATION_TARGET] Remove Entity',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Entity Success
 */
export class GetEntitySuccess {
  public static readonly type = ActionTypes.GetEntitySuccess;

  public constructor(public entityQueryResult: AnnotationTargetEntityModels.AnnotationTargetEntityQueryResult) {
  }
}

/**
 * Get Entities Success
 */
export class GetEntitiesSuccess {
  public static readonly type = ActionTypes.GetEntitiesSuccess;

  public constructor(public entityQueryResults: AnnotationTargetEntityModels.AnnotationTargetEntityQueryResult[]) {
  }
}

/**
 * Add Entity
 */
export class AddEntity {
  public static readonly type = ActionTypes.AddEntity;

  public constructor(public entity: AnnotationTargetEntityModels.AnnotationTarget) {
  }
}

/**
 * Update Entity
 */
export class UpdateEntity {
  public static readonly type = ActionTypes.UpdateEntity;

  public constructor(public entity: AnnotationTargetEntityModels.AnnotationTarget) {
  }
}

/**
 * Remove Entity
 */
export class RemoveEntity {
  public static readonly type = ActionTypes.RemoveEntity;

  public constructor(public entity: AnnotationTargetEntityModels.AnnotationTarget) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>