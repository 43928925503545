import { Params } from '@angular/router';
import { Node, Slide, SlideNode } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Index action types
 */
export enum IndexActionTypes {
  /**
   * Store
   */
  HandleIndexHistory = '[STORE | INDEX] Handle Index History',
  GetRootNodes = '[STORE | INDEX] Get Root Nodes',
  GetRootNodesSuccess = '[STORE | INDEX] Get Root Nodes Success',
  GetChildNodes = '[STORE | INDEX] Get Child Nodes',
  GetChildNodesSuccess = '[STORE | INDEX] Get Child Nodes Success',
  IndexChanged = '[STORE | INDEX] Index Changed',
  AddRootNode = '[STORE | INDEX] Add Root Node',
  SaveIndex = '[STORE | INDEX] Save Index',
  SaveIndexSuccess = '[STORE | INDEX] Save Index Success',
  SetIndex = '[STORE | INDEX] Set Index',
  GetNodeRoute = '[STORE | INDEX] Get Node Route',
  GetNodeRouteSuccess = '[STORE | INDEX] Get Node Route Success',
  GetNodesBySlideId = '[STORE | INDEX] Get Nodes by Slide Id',
  GetNodesBySlideIdSuccess = '[STORE | INDEX] Get Nodes by Slide Id Success',
  GetAutofill = '[STORE | AUTOFILL] Get autofill',
  GetAutofillSuccess = '[STORE | AUTOFILL] Get autofill success',
  
  /**
   * Request
   */
  GetRootNodesRequest = '[REQUEST | INDEX] Get Root Nodes',
  GetChildNodesRequest = '[REQUEST | INDEX] Get Child Nodes',
  SaveIndexRequest = '[REQUEST | INDEX] Save Index',
  GetNodeRouteRequest = '[REQUEST | INDEX] Get Node Route',
  GetNodesBySlideIdRequest = '[REQUEST | INDEX] Get Nodes by Slide Id',
  GetAutofillRequest = '[REQUEST | AUTOFILL] Get Autofill',

}


// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Handle Index History
 */
export class HandleIndexHistory {
  public static readonly type = IndexActionTypes.HandleIndexHistory;

  public constructor(public stateFragment: any) {}
}

/**
 * Get Root Node
 */
export class GetRootNodes {
  public static readonly type = IndexActionTypes.GetRootNodes;

  public constructor() {
  }
}

/**
 * Get Root Node Success
 */
export class GetRootNodesSuccess {
  public static readonly type = IndexActionTypes.GetRootNodesSuccess;

  public constructor(public nodes: Node[], public lastUpdated: Date) {
  }
}

/**
 * Get Child Nodes
 */
export class GetChildNodes {
  public static readonly type = IndexActionTypes.GetChildNodes;

  public constructor(public parent: Node, public loadTrainings?: boolean, public loadRestrictedAndHidden?: boolean, public queryParams?: Params) {
  }
}

/**
 * Get Child Nodes Success
 */
export class GetChildNodesSuccess {
  public static readonly type = IndexActionTypes.GetChildNodesSuccess;

  public constructor(public node: Node, public children: Node[]) {
  }
}

/**
 * Index Changed
 */
export class IndexChanged {
  public static readonly type = IndexActionTypes.IndexChanged;

  public constructor(public dirtyNodes: Node[]) {
  }
}

/**
 * Add Root Node
 */
export class AddRootNode {
  public static readonly type = IndexActionTypes.AddRootNode;

  public constructor(public rootNode: Node) {
  }
}

/**
 * Save Index
 */
export class SaveIndex {
  public static readonly type = IndexActionTypes.SaveIndex;

  public constructor() {
  }
}

/**
 * Save Index Success
 */
export class SaveIndexSuccess {
  public static readonly type = IndexActionTypes.SaveIndexSuccess;

  public constructor(public nodes: Node[]) {
  }
}

/**
 * Reset Index
 */
export class SetIndex {
  public static readonly type = IndexActionTypes.SetIndex;

  public constructor(public index: Node[]) {
  }
}

/**
 * Get Node Route
 */
 export class GetNodeRoute {
  public static readonly type = IndexActionTypes.GetNodeRoute;

  public constructor(public nodeId: string) {
  }
}

/**
 * Get Route Node Success
 */
export class GetNodeRouteSuccess {
  public static readonly type = IndexActionTypes.GetNodeRouteSuccess;

  public constructor(public path: string) {
  }
}

/**
 * Get Nodes By Slide Id 
 */
export class GetNodesBySlideId {
  public static readonly type = IndexActionTypes.GetNodesBySlideId;

  public constructor(public slideId: number) {
  }
}

/**
 * Get Nodes By Slide Id Success
 */
export class GetNodesBySlideIdSuccess {
  public static readonly type = IndexActionTypes.GetNodesBySlideIdSuccess;

  public constructor(public nodes: Node[], public slide: Slide) {
  }
}

export class GetAutofill {
  public static readonly type = IndexActionTypes.GetAutofill;

  public constructor(public slideId: string) {
  }
}

/**
 * Autofill Success
 */
export class GetAutofillSuccess {
  public static readonly type = IndexActionTypes.GetAutofillSuccess;

  public constructor(public nodes: Node[]) {
  }
}




// </editor-fold>

// <editor-fold desc="Request">


/**
 * Get Autofill Request
 */
export class GetAutofillRequest {
  public static readonly type = IndexActionTypes.GetAutofillRequest;

  public constructor(public slideId: string) {
  }
}


/**
 * Get Root Node Request
 */
export class GetRootNodesRequest {
  public static readonly type = IndexActionTypes.GetRootNodesRequest;

  public constructor() {
  }
}

/**
 * Get Child Nodes Request
 */
export class GetChildNodesRequest {
  public static readonly type = IndexActionTypes.GetChildNodesRequest;
  public constructor(public parent: Node, public loadTrainings?: boolean, public loadRestrictedAndHidden?: boolean, public queryParams?: Params) {
  }
}

/**
 * Save Index Request
 */
export class SaveIndexRequest {
  public static readonly type = IndexActionTypes.SaveIndexRequest;

  public constructor(public nodes: Node[]) {
  }
}

/**
 * Get Node Route Request
 */
export class GetNodeRouteRequest {
  public static readonly type = IndexActionTypes.GetNodeRouteRequest;

  public constructor(public nodeId: string) {
  }
}

/**
 * Get Nodes By Slide Id Request
 */
export class GetNodesBySlideIdRequest {
  public static readonly type = IndexActionTypes.GetNodesBySlideIdRequest;

  public constructor(public slideId: number) {
  }
}

// </editor-fold>
