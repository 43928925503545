import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'scrollTop' })
export class ScrollTopPipe implements PipeTransform {
  transform(value: unknown, ...scrollContainers: HTMLElement[]): unknown {

    scrollContainers?.forEach(container => {
      if(container instanceof HTMLElement && container?.scrollTop){
        container.scrollTop = 0;
      }
    })

    return value;
  }
}