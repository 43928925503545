<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Content -->
<ng-container *ngIf="(trainingViewerFacade.currentSelectedSlideQuestions$ | async) | sortBy_shared:'asc':'order' as questions; else spinner">
  <ng-container *ngIf="questions.length > 0">
    <div class="question-container pt-0"
         *ngFor="let question of questions; let i = index" [ngStyle]="{'order': question.order}">
      <ng-container *ngIf="(trainingViewerFacade.currentSlideResultByQuestionId$(question.id) | async) as trainingResult"
                    [ngSwitch]="question.type">
        <medsurf-quiz-mapping-question *ngSwitchCase="questionType.MAPPING"
                                       [index]="i"
                                       [question]="question"
                                       [trainingResult]="trainingResult"
                                       #questionComponent>
        </medsurf-quiz-mapping-question>
        <medsurf-quiz-single-choice-question *ngSwitchCase="questionType.LONG_LIST"
                                             [index]="i"
                                             [question]="question"
                                             [trainingResult]="trainingResult"
                                             #questionComponent>
        </medsurf-quiz-single-choice-question>
        <medsurf-quiz-single-choice-question *ngSwitchCase="questionType.SINGLE_CHOICE"
                                             [index]="i"
                                             [question]="question"
                                             [trainingResult]="trainingResult"
                                             #questionComponent>
        </medsurf-quiz-single-choice-question>
        <medsurf-quiz-k-prim-question *ngSwitchCase="questionType.TRUE_FALSE"
                                      [index]="i"
                                      [question]="question"
                                      [trainingResult]="trainingResult"
                                      #questionComponent>
        </medsurf-quiz-k-prim-question>
        <medsurf-quiz-k-prim-question *ngSwitchCase="questionType.KPRIME"
                                      [index]="i"
                                      [question]="question"
                                      [trainingResult]="trainingResult"
                                      #questionComponent>
        </medsurf-quiz-k-prim-question>
        <medsurf-quiz-freetext-question *ngSwitchCase="questionType.FREE_TEXT"
                                        [index]="i"
                                        [question]="question"
                                        [trainingResult]="trainingResult"
                                        #questionComponent>
        </medsurf-quiz-freetext-question>
        <medsurf-quiz-indication-question *ngSwitchCase="questionType.INDICATION"
                                          [index]="i"
                                          [question]="question"
                                          [trainingResult]="trainingResult"
                                          #questionComponent>
        </medsurf-quiz-indication-question>
      </ng-container>
    </div>

    <ng-container *ngIf="questions.length > 1 && (menuViewerFacade.showPrint$ | async) === false">
      <ng-container *ngIf="{showFeedback: (trainingViewerFacade.currentSlideResultShowFeedback$ | async), showExplanation: (trainingViewerFacade.currentSlideResultShowExplanation$ | async), trainingPage: (trainingViewerFacade.currentSelectedSlidePage$ | async)} as trainingData">
        <div class="d-flex order-1000" role="group">
          <!-- Show Answer -->
          <button type="button"
                  class="mdsf-btn mdsf-btn-primary"
                  role="button"
                  *ngIf="trainingData.showFeedback === false"
                  (click)="setShowFeedback(trainingData.trainingPage)"
                  translate="check_answer_all">
          </button>
          <button type="button"
                  class="mdsf-btn"
                  role="button"
                  disabled
                  *ngIf="trainingData.showFeedback">
            {{ 'check_answer_all_done' | translate }}  <i class="fa fa-fw fa-times"></i>
          </button>

          <!-- Show Feedback -->
          <button type="button"
                  class="mdsf-btn ml-3 mdsf-btn-primary"
                  role="button"
                  *ngIf="trainingData.showFeedback && trainingData.showExplanation === false"
                  (click)="toggleShowExplanation()"
                  translate="show_explanation_all">
          </button>
          <button type="button"
                  class="mdsf-btn ml-3"
                  role="button"
                  *ngIf="trainingData.showFeedback && trainingData.showExplanation"
                  (click)="toggleShowExplanation()">
            {{ 'show_explanation_all_done' | translate }}  <i class="fa fa-fw fa-times"></i>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
