import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { SubjectsComponent } from './subjects.component';
import { MedsurfViewerCommonModule } from '../common/medsurf-viewer-common.module';
import { RouterModule } from '@angular/router';
import { SubjectsRoutingModule } from './subjects-routing.module';

@NgModule({
  declarations: [SubjectsComponent, CarouselComponent],
  imports: [
    CommonModule,
    MedsurfViewerCommonModule,
    RouterModule,
    SubjectsRoutingModule,
    NgOptimizedImage,
  ]
})
export class SubjectsModule { }
