import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthControlFacade, WsControlFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-control-reconnect',
  templateUrl: './reconnect.control.component.html',
  styleUrls: ['./reconnect.control.component.css']
})
export class ReconnectControlComponent implements OnInit, OnDestroy {
  /**
   * Facades
   */
  public isAuthenticated$ = this.authControlFacade.isAuthenticated$;
  public isConnected$ = this.wsControlFacade.isConnected$;

  /**
   * Inputs
   */
  @Input() public text = '';
  @Input() public isWs = false;

  /**
   * Members
   */
  private _destroyed$ = new Subject<boolean>();
  private pingInterval: number | null;

  /**
   * Constructor
   *
   * @param authControlFacade: AuthControlFacade
   * @param wsControlFacade: WsControlFacade
   */
  constructor(public authControlFacade: AuthControlFacade,
              public wsControlFacade: WsControlFacade) {
    this.pingInterval = null;
  }

  /**
   * Ng On Init
   */
  ngOnInit(): void {
    this.wsControlFacade.ofActionDispatchedWebSocketConnected.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.wsControlFacade.requestSetIsConnected(true);
      this.wsControlFacade.requestPing();
      this.pingInterval = window.setInterval(() => {
        this.wsControlFacade.requestPing();
      }, 60 * 1000);
    });

    this.wsControlFacade.ofActionDispatchedWebSocketDisconnected.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      if (this.pingInterval) {
        window.clearInterval(this.pingInterval);
      }
      if (this.authControlFacade.isAuthenticated$.pipe(tap(isAuthenticated => isAuthenticated)) && this.isWs) {
        window.setTimeout(() => {
          this.wsControlFacade.requestConnect();
        }, 5000);
      }
      this.wsControlFacade.requestSetIsConnected(false);
    });

    this.wsControlFacade.ofActionDispatchedWebSocketMessageError.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.wsControlFacade.requestSetIsConnected(false);
    });
  }

  /**
   * Ng On Destroy
   */
  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
