<ng-container *ngIf="{images: (slideViewerFacade.currentSelectedSlideValidImages$ | async), medias: (slideViewerFacade.currentSelectedSlideValidMedias$ | async), themeColor: (slideViewerFacade.themeColor$ | async), imageIndex: (slideViewerFacade.imageIndex$ | async)} as imageData">
  <div class="sequence-control"
       (wheel)="onWheel($event, imageData.imageIndex)"
       (touchstart)="onTouchStart($event)"
       (touchmove)="handleTouchMove($event, imageData.imageIndex)">
    <!-- Sequence Navigation -->
      <ng-container *ngIf="{sequenceItems: getSequenceItems(imageData.images, imageData.medias, imageData.themeColor, imageData.imageIndex) | sortBy_shared:'asc':'sequenceNumber', selectedImage: (slideViewerFacade.currentSelectedSlideImage$ | async)} as sequenceData">
        <div class="sequence-item-text">{{ getCurrentTitle(sequenceData.sequenceItems) }}</div>
        <div class="sequence-navigation"
           [class.hidden]="imageData.images.length <= 1">
        <ng-container *ngIf="getSequenceControl(sequenceData.sequenceItems) as sequenceControl">
          <!-- Sequence Up -->
          <button class="sequence-arrow arrow-up"
                  *ngIf="sequenceControl"
                  [tabIndex]="sequenceControl.up?.active ? 0 : -1"
                  (click)="slideViewerFacade.requestSetImageIndex(imageData.imageIndex - 1)"
                  [class.active]="sequenceControl.up?.active">
            <svg viewBox="0 0 24 12">
              <path d="M1 11 L23 11 L12 1Z"
                    [ngStyle]="{ fill: (menuViewerFacade.showMarkers$ | async) ? sequenceControl.up?.color : '' }">
              </path>
            </svg>
          </button>
          <!-- Sequence Down -->
          <button class="sequence-arrow arrow-down"
                  *ngIf="sequenceControl"
                  [tabIndex]="sequenceControl.down?.active ? 0 : -1"
                  (click)="slideViewerFacade.requestSetImageIndex(imageData.imageIndex + 1)"
                  [class.active]="sequenceControl.down?.active">
            <svg viewBox="0 0 24 12">
              <path d="M1 1 L23 1 L12 11Z"
                    [ngStyle]="{ fill: (menuViewerFacade.showMarkers$ | async) ? sequenceControl.down?.color : '' }">
              </path>
            </svg>
          </button>
        </ng-container>
        <!-- List -->
        <div class="sequence-list"
             #sequenceList>
          <button type="button"
                  *ngFor="let item of sequenceData.sequenceItems; trackBy: trackBy; let i = index"
                  class="sequence-item"
                  [class.active]="item.active"
                  [class.hidden]="item.hidden"
                  [ngStyle]="{ 'transform': 'rotateX(' + item.angle + 'deg)' }"
                  [title]="item.title"
                  (click)="slideViewerFacade.requestSetImageIndex(i)"
                  [tabIndex]="item.hidden ? -1 : 0">
            <div class="sequence-item-indicator"
                 [ngStyle]="{ background: (menuViewerFacade.showMarkers$ | async) ? item.color : '' }">
            </div>
          </button>
        </div>
      </div>
      </ng-container>
    <!-- Layer Navigation -->
    <div class="layer-navigation"
         *ngIf="{layers: (slideViewerFacade.currentSelectedSlideLayers$ | async) | sortBy_shared:'asc':'position', selectedLayer: (slideViewerFacade.currentSelectedSlideLayer$ | async)} as layerData">
      <ng-container *ngIf="layerData.layers.length > 1">
        <ng-container *ngFor="let layer of layerData.layers; let i = index">
          <button class="layer-item"
                  [ngClass]="{'active': layer === layerData.selectedLayer}"
                  (click)="slideViewerFacade.requestSetLayerIndex(i)">
            {{ layer.window || 'L' + (i + 1) }}
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
