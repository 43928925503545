import { ActivatedRouteSnapshot } from '@angular/router';
import {
  BlockNode,
  ChapterNode,
  ElearningNode,
  Node,
  SlideNode,
  SubjectNode,
} from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Navigation action types
 */
export enum NavigationActionTypes {
  /**
   * Store
   */
  SetActivatedRoute = '[STORE | NAVIGATION] Set Activated Route',
  SetLoadParameters = '[STORE | NAVIGATION] Set Load Parameters',
  GetNodeForUrl = '[STORE | NAVIGATION] Get Node For Url',
  GetNodeForUrlSuccess = '[STORE | NAVIGATION] Get Node For Url Success',
  GetNodeForUrlFail = '[STORE | NAVIGATION] Get Node For Url Fail',
  OverwriteCurrentPath = '[STORE | NAVIGATION] Overwrite current path'
  /**
   * Request
   */
}


// </editor-fold>

// <editor-fold desc="Models">
export enum SlugName {
  ELEARNING = 'eLearning',
  SUBJECT = 'subject',
  CHAPTER = 'chapter',
  BLOCK = 'block',
  SLIDE = 'slide'
}

export interface Nodes {
  eLearning?: any;
  subject?: any;
  chapter?: any;
  block?: any;
  slide?: any;
}

export interface PathModel extends Nodes {
  eLearning?: ElearningNode;
  subject?: SubjectNode;
  chapter?: ChapterNode;
  block?: BlockNode;
  slide?: SlideNode;
}

export interface SlugModel extends Nodes {
  eLearning?: string;
  subject?: string;
  chapter?: string;
  block?: string;
  slide?: string;
}

export interface Breadcrumb {
  title: string;
  route: string;
  params: Array<string>;
}

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Set Activated Route
 */
export class SetActivatedRoute {
  public static readonly type = NavigationActionTypes.SetActivatedRoute;

  public constructor(public activatedRoute: ActivatedRouteSnapshot) {
  }
}

/**
 * Set Load Parameters
 */
export class SetLoadParameters {
  public static readonly type = NavigationActionTypes.SetLoadParameters;

  public constructor(public loadTrainings: boolean, public loadRestrictedAndHidden: boolean) {
  }
}

/**
 * Get Node for Url
 */
export class GetNodeForUrl {
  public static readonly type = NavigationActionTypes.GetNodeForUrl;

  public constructor(public url?: string) {
  }
}

/**
 * Get Node for Url success
 */
export class GetNodeForUrlSuccess {
  public static readonly type = NavigationActionTypes.GetNodeForUrlSuccess;

  public constructor(public path: PathModel) {
  }
}


/**
 * Get Node for Url fail
 */
export class GetNodeForUrlFail {
  public static readonly type = NavigationActionTypes.GetNodeForUrlFail;

  public constructor(public path: PathModel) {
  }
}

export class OverwriteCurrentPath {
  public static readonly type = NavigationActionTypes.OverwriteCurrentPath;

  public constructor(public slugName: SlugName, public  node: Node) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
