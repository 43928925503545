import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NavigationControlState } from '@medsurf/flat-states';
import { Navigate } from '@ngxs/router-plugin';

/**
 * Navigation Control Facade
 */
@Injectable()
export class NavigationControlFacade {
  //<editor-fold desc="Selectors">

  public slugs$ = this.store.select(NavigationControlState.slugs$);
  public currentUrl$ = this.store.select(NavigationControlState.currentUrl$);
  public elearningUrl$ = this.store.select(NavigationControlState.elearningUrl$);
  public subjectUrl$ = this.store.select(NavigationControlState.subjectUrl$);
  public isPrinting$ = this.store.select(NavigationControlState.isPrinting$);
  public showSearch$ = this.store.select(NavigationControlState.showSearch$);
  public showClose$ = this.store.select(NavigationControlState.showClose$);
  public queryParams$ = this.store.select(NavigationControlState.queryParams$);

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(private store: Store) {
  }

  //<editor-fold desc="Requests">

  /**
   * Request Navigate
   *
   * @param path: any[]
   */
  public requestNavigate(path: any[]) {
    return this.store.dispatch(new Navigate(path));
  }

  //</editor-fold>
}
