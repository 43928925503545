import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { AppSharedFacade } from '@medsurf/flat-facades';

@Injectable({
  providedIn: "root"
})
export class MetadataSharedResolver implements Resolve<void> {
  /**
   * Constructor
   *
   * @param appSharedFacade: AppSharedFacade
   */
  constructor(public appSharedFacade: AppSharedFacade) {
  }

  /**
   * Resolve
   */
  resolve() {
    return this.appSharedFacade.requestMetadata();
  }
}