import { Injectable } from '@angular/core';
import { QuestionEntityModels } from '@medsurf/flat-models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MatomoControlService {
  /**
   * Members
   */
  private consentDuration = 365; // days
  private consentStorageKey = 'matomo.consentGiven';

  public hasConsent$ = new BehaviorSubject<boolean>(false);

  /**
   * Constructor
   *
   */
  constructor() {
    if (this._hasConsent()) {
      this.hasConsent$.next(true);
      this._push(['setConsentGiven']);
    }
  }

  /**
   * Enabled
   *
   * @protected
   */
  protected _enabled(): boolean {
    return (typeof (window as any)._paq === 'object');
  }

  /**
   * Push
   *
   * @param args: any[]
   * @protected
   */
  protected _push(args: any[]): void {
    if (this._enabled()) {
      (window as any)._paq.push(args);
    }
  }

  /**
   * Check if consent in localstorage is given and not expired
   *
   * @protected
   */
  protected _hasConsent() {
    const value = localStorage.getItem(this.consentStorageKey);
    const consentTimestamp = value ? parseInt(value) : 0;
    return consentTimestamp > Date.now() - (this.consentDuration * 24 * 60 * 60 * 1000);
  }

  /**
   * Consent to tracking
   *
   */
  public giveConsent() {
    localStorage.setItem(this.consentStorageKey, `${Date.now()}`);
    this._push(['setConsentGiven']);
    this.hasConsent$.next(true);
  }

  /**
   * Track Page View
   *
   * @param url: string
   * @param title: string
   */
  public trackPageView(url: string, title: string): void {
    this._push(['setCustomUrl', url]);
    this._push(['setDocumentTitle', title]);
    this._push(['trackPageView']);
  }

  /**
   * Track Search
   *
   * @param query: string
   * @param count: number | boolean
   */
  public trackSearch(query: string, count: number | boolean = false): void {
    this._push(['trackSiteSearch', query, false, count]);
  }

  /**
   * Track Event
   *
   * @param category: string
   * @param action: string
   * @param name: string
   * @param value: number
   */
  public trackEvent(category: string, action: string, name: string, value: number): void {
    const a = ['trackEvent', category, action, name, value];
    this._push(a);
  }

  /**
   * Track Quiz Start
   *
   * @param slideIds: string[] | number[]
   */
  public trackQuizStart(slideIds: string[] | number[]): void {
    const sorted = [...slideIds].sort();
    this.trackEvent('Quiz', 'started', sorted.join(','), sorted.length);
  }

  /**
   * Track Quiz End
   *
   * @param slideIds: string[] | number[]
   * @param correctPercentage: number
   */
  public trackQuizEnd(slideIds: string[] | number[], correctPercentage: number): void {
    const sorted = [...slideIds].sort();
    this.trackEvent('Quiz', 'finished', sorted.join(','), correctPercentage);
  }

  /**
   * Track Quiz Answer
   *
   * @param slideId: string
   * @param question: QuestionEntityModels.Question
   * @param correct: boolean
   * @param key: string
   */
  public trackQuizAnswer(slideId: string, question: QuestionEntityModels.Question, correct: boolean, key?: string) {
    const el = document.createElement('div');
    el.innerHTML = question.content as string;
    const questionText = el.textContent;
    let name = `${slideId} > ${questionText}`;
    if (key) {
      name += ` > ${key}`;
    }
    this.trackEvent('Question', 'answered ' + (correct ? 'correct' : 'wrong'), name, correct ? 1 : 0);
  }

  /**
   * Track Quiz Slide
   *
   * @param slideId: string
   * @param correctPercentage: number
   */
  public trackQuizSlide(slideId: string, correctPercentage: number) {
    this.trackEvent('Quiz Slide', 'answered', slideId, correctPercentage);
  }
}