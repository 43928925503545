import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { MedsurfViewerCommonModule } from '../common/medsurf-viewer-common.module';
import { PagesComponent } from './pages.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [PagesComponent],
  imports: [
    CommonModule,
    MedsurfViewerCommonModule,
    RouterModule,
    PagesRoutingModule
  ]
})
export class PagesModule { }
