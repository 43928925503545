/**
 * Position
 */
export class Position {
  /**
   * Distance
   *
   * @param pointA
   * @param pointB
   */
  public static distance(pointA,
                         pointB) {
    const a = pointA.x - pointB.x,
      b = pointA.y - pointB.y;
    return Math.sqrt(a * a + b * b);
  }
}
