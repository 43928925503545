// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetPageByCurrentRoute = '[STORE | CONTROL | PAGE] Get Page By Current Route',
  GetPageById = '[STORE | CONTROL | PAGE] Get Page By Id',
  /**
   * Request
   */
  GetPageByTitleRequest = '[REQUEST | CONTROL | PAGE] Get Page By Title Request',
  GetPageByIdRequest = '[REQUEST | CONTROL | PAGE] Get Page By ID Request'
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Page By Current Route
 */
export class GetPageByCurrentRoute {
  public static readonly type = ActionTypes.GetPageByCurrentRoute;
}

/**
 * Get Page By Id
 */
export class GetPageById {
  public static readonly type = ActionTypes.GetPageById;

  public constructor(public id: string) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get Page By Title Request
 */
export class GetPageByTitleRequest {
  public static readonly type = ActionTypes.GetPageByTitleRequest;

  public constructor(public title: string) {
  }
}


/**
 * Get Page By ID Request
 */
export class GetPageByIdRequest {
  public static readonly type = ActionTypes.GetPageByIdRequest;

  public constructor(public id: string) {
  }
}

// </editor-fold>
