import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'sortBy_shared' })
export class SortBySharedPipe implements PipeTransform {
  transform(value: any[], order = '', column = '', caseInsensitive = false): any[] {
    if (!value || order === '' || !order) { return value; } // no array
    if (!column || column === '') {
      if(order==='asc'){return value.sort();}
      else{return value.sort().reverse();}
    } // sort 1d array
    if (value.length <= 1) { return value; } // array with only one item
    if (caseInsensitive) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return orderBy(value, [(val) => val[column].toString().toLowerCase()], [order]);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return orderBy(value, [column], [order]);
    }
  }
}