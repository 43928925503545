import paper from 'paper';
import { Symbol } from './symbol';

export class Arrow extends Symbol {
  /**
   * Members
   */
  private _path: any;

  /**
   * Constructor
   *
   * @param format
   * @param target
   * @param marker
   */
  public constructor(format,
                     target,
                     marker) {
    super(format, target, marker);
  }

  /**
   * Draw
   */
  public draw() {
    if (this._path) {
      this._path.remove();
      this._path = null;
    }
    const factor = (this._marker._model?.freeFormStyle?.strokeWidth || 3) / 3;
    this._path = new paper.Path();
    this._path.add(new paper.Point(6.5 * factor, 2.27 * factor));
    this._path.add(new paper.Point(13 * factor, 16 * factor));
    this._path.add(new paper.Point(6.5 * factor, 11 * factor));
    this._path.add(new paper.Point(0 * factor, 16 * factor));
    this._path.closed = true;
    this._path.style = {
      fillColor: (this._marker && this._marker._model.style && this._marker._model.style.color) ?
        this._marker._model.style.color :
        this._format.strokeColor
    };
    this._element.addChild(this._path);
    this._element.position = this._target;
  }

  /**
   * Set Scale
   */
  public setScale() {
    this.draw();
    const factor = (this._marker && this._marker._model.source && this._marker._model.source.zoom && this._marker._imageScale === 1 ?
      this._marker.container.getStrokeWidth(this._marker._model.source.zoom) || this._format.strokeWidth :
      this._format.strokeWidth)
      / 2 / this._marker._imageScale;

    this._path.scale(factor);
  }

  /**
   * Getter path
   */
  public get path() {
    return this._path;
  }
}
