import { ActionType, getActionTypeFromInstance, StateToken } from '@ngxs/store'
import { HistoryControlModels } from '@medsurf/flat-models';

/**
 * Action Metadata
 */
export interface ActionMetadata {
  ctor: any;
  actions: string[];
  stateTokens: StateToken<any>[];
  types: HistoryControlModels.HistorySnapshotItemModifierType[];
}

/**
 * Actions To Handle
 */
export const actionsToHandle: Record<string, ActionMetadata> = {}

/**
 * Undoable
 *
 * @param action: ActionType
 * @param stateToken: StateToken<any>
 * @param type: HistoryControlModels.HistorySnapshotItemModifierType
 * @constructor
 */
export function Undoable(action: ActionType, stateToken: StateToken<any>, type: HistoryControlModels.HistorySnapshotItemModifierType) {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const actionType = getActionTypeFromInstance(action)
    if (actionType) {
      const ctorName = target.constructor.name
      actionsToHandle[ctorName] = {
        ctor: target.constructor,
        actions: actionsToHandle[ctorName] ? [...actionsToHandle[ctorName].actions, actionType] : [actionType],
        stateTokens: actionsToHandle[ctorName] ? [...actionsToHandle[ctorName].stateTokens, stateToken] : [stateToken],
        types: actionsToHandle[ctorName] ? [...actionsToHandle[ctorName].types, type] : [type],
      }
    }
  }
}