import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { GeneralControlActions } from '@medsurf/flat-actions';
import { MessageControlService } from '@medsurf/flat-services';
import { AuthControlState } from './auth.control.state';

/**
 * General Control State Model
 */
export interface GeneralControlStateModel {
  apiVersion: string | null;
}

/**
 * Ws Control State
 */
@State<GeneralControlStateModel>({
  name: 'generalControl',
  defaults: {
    apiVersion: null,
  }
})
@Injectable()
export class GeneralControlState {
  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   */
  public constructor(public store: Store,
    public messageControlService: MessageControlService) {
  }

  //<editor-fold desc="Selectors">

  /**
   * Selector apiVersion$
   *
   * @param state: WsControlStateModel
   */
  @Selector([GeneralControlState])
  public static apiVersion$(state: GeneralControlStateModel): string | null {
    return state.apiVersion;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Get API Version
   *
   */
  @Action(GeneralControlActions.GetApiVersion)
  public getApiVersion() {
    return this.messageControlService.sendMessage(
        this.store.selectSnapshot(AuthControlState.token$),
        new GeneralControlActions.GetApiVersionRequest()
      );
  }

  /**
   * Get API Version Success
   * 
   */

  @Action(GeneralControlActions.GetApiVersionSuccess)
  public getApiVersionSuccess({ patchState }: StateContext<GeneralControlStateModel>,
                              { version }: GeneralControlActions.GetApiVersionSuccess) {
    patchState({
      apiVersion: version
    });
  }

  //</editor-fold>
}
