import { map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as FlatStates from '@medsurf/flat-states';

/**
 * Vocabulary Shared Facade
 */
@Injectable()
export class VocabularyViewerFacade {
  //<editor-fold desc="Lazy Selectors">

  public vocabularyGroupByIds$ = (id: any) => this.store.select(FlatStates.VocabularyGroupEntityState.entitiesByIds$).pipe(map(filterFn => filterFn(id as unknown as string[])));
  public vocabularyWordByIds$ = (id: any) => this.store.select(FlatStates.VocabularyWordEntityState.entitiesByIds$).pipe(map(filterFn => filterFn(id as unknown as string[])));

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   */
  constructor(private store: Store) {
  }

  //<editor-fold desc="Requests">

  //</editor-fold>

}
