import { Component, Input } from '@angular/core';
import { NodeEntityModels } from '@medsurf/flat-models';
import { MediaControlService, ModalControlService } from '@medsurf/flat-services';
import { SearchControlFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-modal-grid',
  templateUrl: './modal-grid.component.html',
  styleUrls: ['./modal-grid.component.scss']
})
export class ModalGridComponent {
  /**
   * Inputs
   */
  @Input() public slides: NodeEntityModels.SlideNode[];

  /**
   * Members
   */
  public active: string;

  /**
   * Constructor
   *
   * @param searchControlFacade: SearchControlFacade
   * @param modalControlService: ModalControlService
   * @param mediaControlService: MediaControlService
   */
  constructor(public searchControlFacade: SearchControlFacade,
              public modalControlService: ModalControlService,
              public mediaControlService: MediaControlService) {
  }
}
