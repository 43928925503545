import paper from 'paper';
import { EventEmitter } from 'events';

/**
 * Tool class
 */
export class Tool extends EventEmitter {
  /**
   * Members
   */
  protected _tool: paper.Tool;

  /**
   * Constructor
   *
   * @param _id
   * @param _localPaper
   * @param _element
   * @param _canvas
   * @param _layout
   * @param _container
   */
  public constructor(private _id,
                     protected _localPaper,
                     protected _element,
                     protected _canvas,
                     protected _layout,
                     protected _container) {
    super();
  }

  /**
   * Init
   *
   * @protected
   */
  protected _init() {
      this._tool = new paper.Tool();
      this._tool.minDistance = 10;
  }

  /**
   * Clean up
   */
  public cleanUp () {
    if (this._tool) {
      this._tool.remove();
      this._tool = null;
    }
  }

  /**
   * Getter tool
   *
   * @returns {paper.Tool|null}
   */
  public get tool () {
    return this._tool;
  }

  /**
   * Getter id
   *
   * @returns {*}
   */
  public get id() {
    return this._id;
  }
}
