import { QuestionEntityModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  GetEntitySuccess = '[STORE | ENTITY | QUESTION] Get Entity Success',
  GetEntitiesSuccess = '[STORE | ENTITY | QUESTION] Get Entities Success',
  AddEntity = '[STORE | ENTITY | QUESTION] Add Entity',
  UpdateEntity = '[STORE | ENTITY | QUESTION] Update Entity',
  RemoveEntity = '[STORE | ENTITY | QUESTION] Remove Entity',
  /**
   * Request
   */
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get Entity Success
 */
export class GetEntitySuccess {
  public static readonly type = ActionTypes.GetEntitySuccess;

  public constructor(public entityQueryResult: QuestionEntityModels.QuestionEntityQueryResult) {
  }
}

/**
 * Get Entities Success
 */
export class GetEntitiesSuccess {
  public static readonly type = ActionTypes.GetEntitiesSuccess;

  public constructor(public entityQueryResults: QuestionEntityModels.QuestionEntityQueryResult[]) {
  }
}

/**
 * Add Entity
 */
export class AddEntity {
  public static readonly type = ActionTypes.AddEntity;

  public constructor(public entity: QuestionEntityModels.QuestionEntityType) {
  }
}

/**
 * Update Entity
 */
export class UpdateEntity {
  public static readonly type = ActionTypes.UpdateEntity;

  public constructor(public entity: QuestionEntityModels.QuestionEntityType) {
  }
}

/**
 * Remove Entity
 */
export class RemoveEntity {
  public static readonly type = ActionTypes.RemoveEntity;

  public constructor(public entity: QuestionEntityModels.QuestionEntityType) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>