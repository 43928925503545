import { SearchControlModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

/**
 * Action Types
 */
export enum ActionTypes {
  /**
   * Store
   */
  SetSearchString = '[STORE | CONTROL | SEARCH] Set Search String',
  ResetSearch = '[STORE | CONTROL | SEARCH] Reset Search',
  FindSlidesAndMarkers = '[STORE | CONTROL | SEARCH] Find Slides And Markers',
  FindSlideSuccess = '[STORE | CONTROL | SEARCH] Find Slide Success',
  FindSlidesSuccess = '[STORE | CONTROL | SEARCH] Find Slides Success',
  FindMarkerSuccess = '[STORE | CONTROL | SEARCH] Find Marker Success',
  FindMarkersSuccess = '[STORE | CONTROL | SEARCH] Find Markers Success',
  FindSuccess = '[STORE | CONTROL | SEARCH] Find Success',
  FindSuggestions = '[STORE | CONTROL | SEARCH] Find Suggestions',
  FindSuggestionsSuccess = '[STORE | CONTROL | SEARCH] Find Suggestions Success',
  /**
   * Request
   */
  FindSlidesAndMarkersRequest = '[REQUEST | CONTROL | SEARCH] Find Slides And Markers Request',
  FindSuggestionsRequest = '[REQUEST | CONTROL | SEARCH] Find Suggestions Request',
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Set Search String
 */
export class SetSearchString {
  public static readonly type = ActionTypes.SetSearchString;

  public constructor(public searchString: string) {
  }
}

/**
 * Reset Search
 */
export class ResetSearch {
  public static readonly type = ActionTypes.ResetSearch;
}

/**
 * Find Slides And Markers
 */
export class FindSlidesAndMarkers {
  public static readonly type = ActionTypes.FindSlidesAndMarkers;
}

/**
 * Find Slide Success
 */
export class FindSlideSuccess {
  public static readonly type = ActionTypes.FindSlideSuccess;

  public constructor(public entity: SearchControlModels.SearchResult) {
  }
}

/**
 * Find Slides Success
 */
export class FindSlidesSuccess {
  public static readonly type = ActionTypes.FindSlidesSuccess;

  public constructor(public entities: SearchControlModels.SearchResult[]) {
  }
}

/**
 * Find Marker Success
 */
export class FindMarkerSuccess {
  public static readonly type = ActionTypes.FindMarkerSuccess;

  public constructor(public entity: SearchControlModels.SearchResult) {
  }
}

/**
 * Find Markers Success
 */
export class FindMarkersSuccess {
  public static readonly type = ActionTypes.FindMarkersSuccess;

  public constructor(public entities: SearchControlModels.SearchResult[]) {
  }
}

/**
 * Find Success
 */
export class FindSuccess {
  public static readonly type = ActionTypes.FindSuccess;
}

/**
 * Find Suggestions
 */
export class FindSuggestions {
  public static readonly type = ActionTypes.FindSuggestions;
}

/**
 * Find Suggestions Success
 */
export class FindSuggestionsSuccess {
  public static readonly type = ActionTypes.FindSuggestionsSuccess;

  public constructor(public suggestions: string[]) {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

/**
 * Find Slides And Markers Request
 */
export class FindSlidesAndMarkersRequest {
  public static readonly type = ActionTypes.FindSlidesAndMarkersRequest;

  public constructor(public searchString: string) {
  }
}

/**
 * Find Suggestions Request
 */
export class FindSuggestionsRequest {
  public static readonly type = ActionTypes.FindSuggestionsRequest;

  public constructor(public searchString: string) {
  }
}

// </editor-fold>
