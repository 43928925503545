import { Component } from '@angular/core';
import { SlideViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent {
  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   */
  public constructor(public slideViewerFacade: SlideViewerFacade) {
  }
}
