import { Component, QueryList, ViewChildren } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { MenuViewerFacade, TrainingViewerFacade } from '@medsurf/flat-facades';
import { BaseQuestionComponent } from './question-types/base-question.component';

@Component({
  selector: 'medsurf-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent {
  /**
   * View Children
   */
  @ViewChildren('questionComponent') public questionComponents: QueryList<BaseQuestionComponent<FlatModels.QuestionEntityModels.QuestionEntityType, FlatModels.TrainingViewerModels.TrainingResultType>>;

  /**
   * Members
   */
  public questionType = FlatModels.QuestionEntityModels.QuestionType;

  /**
   * Constructor
   *
   * @param menuViewerFacade: MenuViewerFacade
   * @param trainingViewerFacade: TrainingViewerFacade
   */
  constructor(public menuViewerFacade: MenuViewerFacade,
              public trainingViewerFacade: TrainingViewerFacade) {
  }

  /**
   * Set Show Feedback
   *
   * @param trainingPage: FlatModels.PageEntityModels.Training | FlatModels.PageEntityModels.Slide
   */
  public setShowFeedback(trainingPage: FlatModels.PageEntityModels.Training | FlatModels.PageEntityModels.Slide) {
    this.questionComponents.forEach(qc => {
      qc.footer.setShowFeedback(trainingPage);
    });
  }

  /**
   * Toggle Show Explanation
   */
  public toggleShowExplanation() {
    this.questionComponents.forEach(qc => {
      qc.footer.toggleShowExplanation();
    });
  }
}
