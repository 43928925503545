import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InteractiveAreaComponent } from './components/interactive-area/interactive-area.component';
import { PoiComponent } from './components/poi/poi.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ReconnectComponent } from './components/reconnector/reconnect.component';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { AlertComponent } from './components/alert/alert.component';

@NgModule({
  declarations: [
    InteractiveAreaComponent,
    PoiComponent,
    SafeHtmlPipe,
    StripHtmlPipe,
    ReconnectComponent,
    SpinnerComponent,
    AlertComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  exports: [
    InteractiveAreaComponent,
    PoiComponent,
    SafeHtmlPipe,
    StripHtmlPipe,
    ReconnectComponent,
    SpinnerComponent,
    AlertComponent
  ]
})
export class MedsurfLibModule { }
