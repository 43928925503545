import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { WebSocketConnected, WebSocketDisconnected, WebsocketMessageError } from '@ngxs/websocket-plugin';
import { AuthState, WsState } from '@medsurf/state';
import { SetIsConnected, Connect, Ping } from '@medsurf/actions';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'medsurf-reconnect',
  templateUrl: './reconnect.component.html',
  styleUrls: ['./reconnect.component.css']
})
export class ReconnectComponent implements OnInit, OnDestroy {
  @Select(AuthState.isAuthenticated)
  public isAuthenticated$: Observable<boolean>;

  @Select(WsState.isConnected)
  public isConnected$: Observable<boolean>;

  @Input() text: string;

  @Input() isWs: boolean;

  private _destroyed = new Subject<boolean>();
  private pingInterval: number;

  constructor(private store: Store,
              private actions$: Actions) { }

  ngOnInit(): void {
    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(WebSocketConnected)).subscribe(() => {
      this.store.dispatch(new SetIsConnected(true));
      this.store.dispatch(new Ping());
      this.pingInterval = window.setInterval(() => {
        this.store.dispatch(new Ping());
      }, 60 * 1000);
    });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(WebSocketDisconnected)).subscribe(() => {
      window.clearInterval(this.pingInterval);
      if (this.store.selectSnapshot(AuthState.isAuthenticated) && this.isWs) {
        window.setTimeout(() => {
          this.store.dispatch(new Connect());
        }, 5000);
      }
      this.store.dispatch(new SetIsConnected(false));
    });

    this.actions$.pipe(takeUntil(this._destroyed), ofActionDispatched(WebsocketMessageError)).subscribe(() => {
      this.store.dispatch(new SetIsConnected(false));
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
