import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { TreeControlModels } from '@medsurf/flat-models';
import { TreeControlActions } from '@medsurf/flat-actions';
import { NavigationControlState } from '../../internal';

/**
 * Tree Control State Model
 */
export interface TreeControlStateModel {
  entities: { [id: string]: TreeControlModels.TreeElement };
}

/**
 * Tree Control State
 */
@State<TreeControlStateModel>({
  name: 'treeControl',
  defaults: {
    entities: {},
  }
})
@Injectable()
export class TreeControlState {
  /**
   * Constructor
   *
   * @param store: Store
   */
  public constructor(public store: Store) {
  }

  //<editor-fold desc="Selectors">

  /**
   * Selector typedEntities$
   *
   * @param state: TreeControlStateModel
   */
  @Selector([TreeControlState])
  public static typedEntities$(state: TreeControlStateModel): { [id: string]: TreeControlModels.TreeElement } {
    return state.entities;
  }

  /**
   * Selector entities$
   *
   * @param typedEntities: { [id: string]: TreeControlModels.TreeElement }
   */
  @Selector([TreeControlState.typedEntities$])
  public static entities$(typedEntities: { [id: string]: TreeControlModels.TreeElement }): TreeControlModels.TreeElement[] {
    return (Object.entries(typedEntities)).map(([, entity]) => entity);
  }

  /**
   * Lazy Selector entity$
   *
   * @param typedEntities: { [id: string]: TreeControlModels.TreeElement }
   */
  @Selector([TreeControlState.typedEntities$])
  public static entity$(typedEntities: { [id: string]: TreeControlModels.TreeElement }) {
    return (id: string) => {
      return typedEntities && typedEntities[id] !== undefined ? typedEntities[id] : null;
    }
  }

  /**
   * Selector currentElearningTree$
   *
   * @param entities: TreeControlModels.TreeElement[],
   * @param slideElearningUrl: string | null
   */
  @Selector([
    TreeControlState.entities$,
    NavigationControlState.elearningUrl$
  ])
  public static currentElearningTree$(entities: TreeControlModels.TreeElement[],
                                      slideElearningUrl: string | null) {
    return entities && slideElearningUrl && entities.find((e) => e.depth === 0 && e.path === slideElearningUrl) || null;
  }

  /**
   * Selector currentSubjectTree$
   *
   * @param entities: TreeControlModels.TreeElement[],
   * @param slideSubjectUrl: string | null
   */
  @Selector([
    TreeControlState.entities$,
    NavigationControlState.subjectUrl$
  ])
  public static currentSubjectTree$(entities: TreeControlModels.TreeElement[],
                                    slideSubjectUrl: string | null) {
    return entities && slideSubjectUrl && entities.find((e) => e.depth === 1 && e.path === slideSubjectUrl) || null;
  }

  /**
   * Selector currentChapterTree$
   *
   * @param entities: TreeControlModels.TreeElement[],
   * @param slideChapterUrl: string | null
   */
  @Selector([
    TreeControlState.entities$,
    NavigationControlState.chapterUrl$
  ])
  public static currentChapterTree$(entities: TreeControlModels.TreeElement[],
                                    slideChapterUrl: string | null) {
    return entities && slideChapterUrl && entities.find((e) => e.depth === 2 && e.path === slideChapterUrl) || null;
  }

  /**
   * Selector currentBlockTree$
   *
   * @param entities: TreeControlModels.TreeElement[],
   * @param slideBlockUrl: string | null
   */
  @Selector([
    TreeControlState.entities$,
    NavigationControlState.blockUrl$
  ])
  public static currentBlockTree$(entities: TreeControlModels.TreeElement[],
                                  slideBlockUrl: string | null) {
    return entities && slideBlockUrl && entities.find((e) => e.depth === 3 && e.path === slideBlockUrl) || null;
  }

  /**
   * Selector currentBlockTree$
   *
   * @param entities: TreeControlModels.TreeElement[],
   * @param slideSlideUrl: string | null
   */
  @Selector([
    TreeControlState.entities$,
    NavigationControlState.slideUrl$
  ])
  public static currentSlideTree$(entities: TreeControlModels.TreeElement[],
                                  slideSlideUrl: string | null) {
    return entities && slideSlideUrl && entities.find((e) => e.depth === 4 && e.path === slideSlideUrl) || null;
  }

  /**
   * Selector currentSelectedTree
   *
   * @param entities: TreeControlModels.TreeElement[],
   * @param currentUrl: string
   */
  @Selector([
    TreeControlState.entities$,
    NavigationControlState.currentUrl$
  ])
  public static currentSelectedTree$(entities: TreeControlModels.TreeElement[],
                                     currentUrl: string) {
    return entities && currentUrl && entities.find((e) => e.path === currentUrl) || null;
  }

  //</editor-fold>

  //<editor-fold desc="Actions">

  /**
   * Build Tree From Nodes
   *
   * @param getState: StateContext<TreeControlStateModel>
   * @param patchState: StateContext<TreeControlStateModel>
   * @param entityQueryResults: TreeControlActions.BuildTreeFromNodes
   */
  @Action(TreeControlActions.BuildTreeFromNodes)
  public buildTreeFromNodes({getState, patchState}: StateContext<TreeControlStateModel>,
                            {entityQueryResults}: TreeControlActions.BuildTreeFromNodes) {
    const entities = Object.assign({}, getState().entities);
    for (const entityQueryResult of entityQueryResults) {
      if (entityQueryResult.entity.parentId === null) {
        entities[entityQueryResult.entity.id] = {
          id: entityQueryResult.entity.id,
          path: entityQueryResult.entity.route as string,
          depth: 0,
          hasChildren: entityQueryResult.entity?.children?.length || 0,
          sort: entityQueryResult.entity?.position?.toString() || '0',
        };
      } else {
        if (!entityQueryResult.entity.parentId) {
          throw new Error('no_parent_id');
        }
        const parentEntity = entities[entityQueryResult.entity.parentId];
        if (parentEntity) {
          entities[entityQueryResult.entity.id] = {
            id: entityQueryResult.entity.id,
            path: parentEntity.path + '/' + entityQueryResult.entity.route as string,
            depth: parentEntity.depth + 1,
            hasChildren: entityQueryResult.entity?.children?.length || 0,
            sort: parentEntity.sort + '.' + entityQueryResult.entity?.position?.toString() || '0',
          }
        }
      }
    }
    patchState({
      entities: entities
    });
  }

  //</editor-fold>
}
