<!-- Content -->
<div class="header top">
  <h1 class="truncate"
      *ngIf="(menuViewerFacade.currentSlideTitle$ | async) as title"
      [innerHTML]="title | safeHtml">
  </h1>
  <ng-container *ngIf="(slideViewerFacade.currentSelectedSlideSidebarFullSize$ | async) === false">
    <div class="button-group"
         *ngIf="{images: (slideViewerFacade.currentSelectedSlideImages$ | async),
         themeColor: (slideViewerFacade.themeColor$ | async)} as data">
      <!-- Virtual Pointer -->
      <div class="button icon-group"
           *ngIf="data.images.length > 0 && data.images[0].fullSize !== null && 
                (mediaControlService.isDeepzoom(page.mediaType) || mediaControlService.isImage(page.mediaType))"
           (click)="menuViewerFacade.requestToggleVirtualPointer()">
        <ng-container *ngIf="{themeClass: setThemeColor((menuViewerFacade.showVirtualPointer$ | async), data.themeColor)} as virtualPointerData">
          <i class="fa fa-arrow-pointer"
             [ngClass]="virtualPointerData.themeClass"></i>
          <label class="d-none d-md-inline-block"
                 [ngClass]="virtualPointerData.themeClass">
            {{ 'virtual_pointer' | translate }}
          </label>
        </ng-container>
      </div>
      <ng-container *ngIf="{showFullscreen: (menuViewerFacade.showFullscreen$ | async),
      themeClass: setThemeColor((menuViewerFacade.showFullscreen$ | async), data.themeColor)} as fullScreenData">
        <div class="button icon-group"
             *ngIf="data.images.length > 0 && data.images[0].fullSize !== null"
             (click)="menuViewerFacade.requestToggleFullscreen()">
          <i class="fa fa-expand"
             *ngIf="fullScreenData.showFullscreen === false"
             [ngClass]="fullScreenData.themeClass"></i>
          <i class="fa fa-compress"
             *ngIf="fullScreenData.showFullscreen"
             [ngClass]="fullScreenData.themeClass"></i>
          <label class="d-none d-md-inline-block"
                 *ngIf="fullScreenData.showFullscreen === false"
                 [ngClass]="fullScreenData.themeClass">
            {{ 'fullscreen' | translate }}
          </label>
          <label class="d-none d-md-inline-block"
                 *ngIf="fullScreenData.showFullscreen"
                 [ngClass]="fullScreenData.themeClass">
            {{ 'window' | translate }}
          </label>
        </div>
      </ng-container>
      <ng-container *ngIf="(slideViewerFacade.currentSelectedSlideImageHasAnnotations$ | async)">
        <!-- Marker -->
        <div class="button icon-group"
             *ngIf="{themeClass: setThemeColor((menuViewerFacade.showMarkers$ | async), data.themeColor)} as markerData"
             (click)="menuViewerFacade.requestToggleMarker()">
          <i class="fa fa-location-dot"
             [ngClass]="markerData.themeClass"></i>
          <label class="d-none d-md-inline-block"
                 [ngClass]="markerData.themeClass">
            {{ 'toggle_markers' | translate }}
          </label>
        </div>
        <!-- Selftest -->
        <div class="button icon-group"
             *ngIf="{themeClass: setThemeColor((menuViewerFacade.showSelftest$ | async), data.themeColor)} as selftestData"
             (click)="menuViewerFacade.requestToggleSelftest()">
          <i class="fa fa-check"
             [ngClass]="selftestData.themeClass"></i>
          <label class="d-none d-md-inline-block"
                 [ngClass]="selftestData.themeClass">
            {{ 'selftest' | translate }}
          </label>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>