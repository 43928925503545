import { Slide } from '@medsurf/models';

// <editor-fold desc="Action Types">

/**
 * Detached action types
 */
export enum DetachedActionTypes {
  /**
   * Store
   */
  GetDetachedSlides = '[STORE | DETACHED] Get detached slides',
  GetDetachedSlidesSuccess = '[STORE | DETACHED] Get detached slides success',
  DeleteDetachedSlides = '[STORE | DETACHED] Delete Detached Slides',
  DeleteDetachedSlidesSuccess = '[STORE | DETACHED] Delete Detached Slides Success',
  AssignSlideIds = '[STORE | DETACHED] Assign Slide Ids',
  AssignSlideIdsSuccess = '[STORE | DETACHED] Assign Slide Ids Success',
  /**
   * Request
   */
  GetDetachedSlidesRequest = '[REQUEST | DETACHED] Get detached slides',
  DeleteDetachedSlidesRequest = '[REQUEST | DETACHED] Delete Detached Slides',
  AssignSlideIdsRequest = '[REQUEST | DETACHED] Assign Slide Ids'
}


// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

/**
 * Get detached slides
 */
 export class GetDetachedSlides {
  public static readonly type = DetachedActionTypes.GetDetachedSlides;

  public constructor() {
  }
}

/**
 * Get detached pages success
 */
 export class GetDetachedSlidesSuccess {
  public static readonly type = DetachedActionTypes.GetDetachedSlidesSuccess;

  public constructor(public slides: Slide[]) {
  }
}


/**
 * Delete detached slides
 */
 export class DeleteDetachedSlides {
  public static readonly type = DetachedActionTypes.DeleteDetachedSlides;

  public constructor(public slides: Slide[]) {
  }
}

/**
 * Delete detached pages success
 */
 export class DeleteDetachedSlidesSuccess {
  public static readonly type = DetachedActionTypes.DeleteDetachedSlidesSuccess;

  public constructor() {
  }
}

/**
 * Assign Slide Ids
 */
export class AssignSlideIds {
  public static readonly type = DetachedActionTypes.AssignSlideIds;

  public constructor(public slides: Slide[]) {
  }
}

/**
 * Assign Slide Ids Success
 */
export class AssignSlideIdsSuccess {
  public static readonly type = DetachedActionTypes.AssignSlideIdsSuccess;

  public constructor(public slides: Slide[]) {
  }
}


// </editor-fold>

// <editor-fold desc="Request">

/**
 * Get detached slides Request
 */
 export class GetDetachedSlidesRequest {
  public static readonly type = DetachedActionTypes.GetDetachedSlidesRequest;

  public constructor() {

  }
}

/**
 * Delete detached slides Request
 */
export class DeleteDetachedSlidesRequest {
  public static readonly type = DetachedActionTypes.DeleteDetachedSlidesRequest;

  public constructor(public slides: Slide[]) {

  }
}

/**
 * Assign Slide Ids Request
 */
export class AssignSlideIdsRequest {
  public static readonly type = DetachedActionTypes.AssignSlideIdsRequest;

  public constructor(public slides: Slide[]) {

  }
}

// </editor-fold>
