import { pick, cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { AnnotationService, MessageService, NodeService } from '@medsurf/services';
import {
  AnnotationType,
  Image,
  ImagePosition,
  Layer,
  PageType,
  QuestionType,
  Slide,
  Media,
  MediaType,
  Annotation,
  Bounds,
  Group,
  FreeForm,
  Shape,
  PointOfInterest
} from '@medsurf/models';
import {
  AddMarker,
  AddQuestion,
  DeleteMarker,
  GetAllSlideIds,
  GetNextSlideId,
  GetNextSlideIdSuccess,
  SlideChanged,
  UpdateSlides,
  UpdateSlidesSuccess,
  UpdateSlidesRequest,
  DeleteSlide,
  DeleteSlideSuccess,
  GetSlide,
  GetSlideByUrl,
  GetSlideSuccess,
  GetSlideRequest,
  LoadSlideGroup,
  LoadSlideGroupSuccess,
  LoadSlideLayer,
  LoadSlideLayerSuccess,
  NewMarkerAdded,
  SetLayerNumber,
  SetPosition,
  SetSequenceNumber,
  SetSlide,
  GetAllSlideIdsRequest,
  GetNextSlideIdRequest,
  LoadSlideGroupRequest,
  LoadSlideLayerRequest,
  GetLastEditedBySlideId,
  GetLastEditedBySlideIdRequest,
  SharedHistory,
  HandleSlideHistory,
  CopyMarker,
  PasteMarker,
  SetBounds,
  LoadSlideAnnotation,
  LoadSlideAnnotationRequest,
  LoadSlideAnnotationSuccess,
  LoadSlidePoi,
  LoadSlidePoiSuccess,
  LoadSlidePoiRequest,
  SetShowOnlyAnnotationsOfImage,
  SetShowOnlyPoisOfImage
} from '@medsurf/actions';
import { AuthState } from './auth.state';
import { IndexState } from './index.state';
import { NavigationState } from './navigation.state';
import { v4 } from 'uuid';

/**
 * Slide state model
 */
export interface SlideStateModel {
  current: Slide;
  dirtyItems: Slide[];
  layerNumber: number;
  sequenceNumber: number;
  position: ImagePosition;
  bounds: Bounds;
  nextSlideId: number;
  copyStack: Annotation;
  showOnlyAnnotationsOfImage: boolean;
  showOnlyPoisOfImage: boolean;
}

/**
 * Slide state
 */
@State<SlideStateModel>({
  name: 'slide',
  defaults: {
    current: null,
    dirtyItems: [],
    layerNumber: 0,
    sequenceNumber: 0,
    position: null,
    bounds: null,
    nextSlideId: 0,
    copyStack: null,
    showOnlyAnnotationsOfImage: true,
    showOnlyPoisOfImage: true
  }
})
@Injectable()
export class SlideState {
  /**
   * Constructor
   * @param annotationService: AnnotationService
   * @param nodeService: NodeService
   * @param store: Store
   * @param messageService: MessageService
   */
  public constructor(
    private annotationService: AnnotationService,
    private nodeService: NodeService,
    protected store: Store,
    protected messageService: MessageService
  ) {
  }

  // <editor-fold desc="Selectors">

  /**
   * Selector isDirty
   * @param state: SlideStateModel
   */
  @Selector()
  public static isDirty(state: SlideStateModel): boolean {
    return state.dirtyItems.length > 0;
  }

  /**
   * Selector slide
   * @param state: SlideStateModel
   */
  @Selector()
  public static slide(state: SlideStateModel): Slide {
    return state.current;
  }

  /**
   * Selector layerNumber
   * @param state: SlideStateModel
   */
  @Selector()
  public static layerNumber(state: SlideStateModel): number {
    return state.layerNumber;
  }

  /**
   * Selector sequenceNumber
   * @param state: SlideStateModel
   */
  @Selector()
  public static sequenceNumber(state: SlideStateModel): number {
    return state.sequenceNumber;
  }

  /**
   * Selector position
   * @param state: SlideStateModel
   */
  @Selector()
  public static position(state: SlideStateModel): ImagePosition {
    return state.position;
  }

  /**
   * Selector bounds
   * @param state: SlideStateModel
   */
  @Selector()
  public static bounds(state: SlideStateModel): Bounds {
    return state.bounds;
  }

  /**
   * Selector layer
   * @param state: SlideStateModel
   */
  @Selector()
  public static layer(state: SlideStateModel): Layer {
    return state.current.layers[state.layerNumber];
  }

  /**
   * Selector image
   * @param state: SlideStateModel
   */
  @Selector()
  public static image(state: SlideStateModel): Image {
    return state.current.layers[state.layerNumber].images[state.sequenceNumber];
  }

  /**
   * Selector annotations map
   */
  @Selector([SlideState.slide])
  public static annotationsMap(slide: Slide): Map<string, Annotation> {
    return (slide.annotations || []).reduce<Map<string, Annotation>>((map, annotation) => {
      if (annotation.id) map.set(annotation.id, annotation);
      return map;
    }, new Map());
  }

  /**
   * Selector image annotations
   */
  @Selector([SlideState.image, SlideState.annotationsMap])
  public static imageAnnotations(image: Image, annotationMap: Map<string, Annotation>): Annotation[] {
    return (image?.annotations || []).reduce<Annotation[]>((arr, annotation) => {
      const fullAnnotation = annotation.id ? annotationMap.get(annotation.id) : null;
      if (fullAnnotation) {
        arr.push(fullAnnotation);
      }
      return arr;
    }, []);
  }

  @Selector([SlideState.imageAnnotations])
  public static imageInteractiveAreas(annotations: Annotation[]): Annotation[] {
    return annotations.filter(annotation => annotation.type === AnnotationType.FREE_FORM && (annotation as FreeForm).shape === Shape.INTERACTIVE_AREA);
  }

  @Selector()
  public static showOnlyAnnotationsOfImage(state: SlideStateModel) {
    return state.showOnlyAnnotationsOfImage;
  }

  @Selector([SlideState.slide, SlideState.layerNumber, SlideState.sequenceNumber])
  public static imageAnnotationSet(slide: Slide, layerNumber: number, sequenceNumber: number){
    return new Set(slide.layers?.[layerNumber]?.images?.[sequenceNumber]?.annotations?.map(a => a.id) || []);
  }
  
  @Selector([SlideState.showOnlyAnnotationsOfImage, SlideState.imageAnnotationSet, SlideState.slide])
  public static displayedAnnotations(showOnlyAnnotationsOfImage: boolean, imageAnnotationSet: ReturnType<typeof SlideState.imageAnnotationSet>, slide: Slide) {
    return (slide.annotations || []).filter(annotation => {
      return (!annotation.deleted) && (!showOnlyAnnotationsOfImage || imageAnnotationSet.has(annotation.id))
    });
  }
  
  @Selector([SlideState.slide])
  public static annotationToImageMap(slide: Slide) {
    return slide.layers?.reduce<{[key: string]: { [key: string]: { count: number, items: { [key: string]: boolean } } }}>((map, layer) => {
      layer?.images?.forEach((image, index) => {
        image?.annotations?.forEach((a) => {
          if (!a.id || !layer.id || !image.id) return;
          const aCache = map[a.id] || {};
          if (!aCache[layer.id]) aCache[layer.id] = { items: {}, count: 0};
          aCache[layer.id].items[image.id] = true;
          aCache[layer.id].count++;
          map[a.id] = aCache;
        })
      })
      return map
    }, {});
  }

  @Selector([SlideState.slide])
  public static annotationToPoiMap(slide: Slide) {
    return slide.pois?.reduce<{[key: string]: { count: number, items: { [key: string]: boolean } }}>((map, poi) => {
      poi?.annotations?.forEach((a) => {
        if (!a.id || !poi.id) return;
        const aCache = map[a.id] || { items: {}, count: 0 };
        aCache.items[poi.id] = true;
        aCache.count++;
        map[a.id] = aCache;
      })
      return map
    }, {});
  }

  @Selector([SlideState.slide])
  public static annotationToGroupMap(slide: Slide) {
    return slide.groups?.reduce<{[key: string]: { count: number, items: { [key: string]: boolean } }}>((map, group) => {
      group?.annotations?.forEach((a) => {
        if (!a.id || !group.id) return;
        const aCache = map[a.id] || { items: {}, count: 0, };
        aCache.items[group.id] = true;
        aCache.count++;
        map[a.id] = aCache;
      })
      return map
    }, {});
  }

  /**
   * Selector pois map
   */
  @Selector([SlideState.slide])
  public static poisMap(slide: Slide) {
    return (slide.pois || []).reduce<Map<string, PointOfInterest>>((map, poi) => {
      if (poi.id) map.set(poi.id, poi);
      return map;
    }, new Map());
  }

  /**
   * Selector image pois
   */
  @Selector([SlideState.image, SlideState.poisMap])
  public static imagePois(image: Image,  poisMap: Map<string, PointOfInterest>): PointOfInterest[] {
    return (image?.pois || []).reduce<PointOfInterest[]>((arr, poi) => {
      const fullPoi = poi.id ? poisMap.get(poi.id) : null;
      if (fullPoi) {
        arr.push(fullPoi);
      }
      return arr;
    }, []);
  }

  @Selector()
  public static showOnlyPoisOfImage(state: SlideStateModel) {
    return state.showOnlyPoisOfImage;
  }

  @Selector([SlideState.slide, SlideState.layerNumber, SlideState.sequenceNumber])
  public static imagePoiSet(slide: Slide, layerNumber: number, sequenceNumber: number){
    return new Set(slide.layers?.[layerNumber]?.images?.[sequenceNumber]?.pois?.map(a => a.id) || []);
  }
  
  @Selector([SlideState.showOnlyPoisOfImage, SlideState.imagePoiSet, SlideState.slide, SlideState.annotationsMap])
  public static displayedPois(
    showOnlyPoisOfImage: boolean, 
    imagePoiSet: ReturnType<typeof SlideState.imagePoiSet>, 
    slide: Slide, 
    annotationsMap: ReturnType<typeof SlideState.annotationsMap>
  ) {
    return (slide.pois || []).reduce<PointOfInterest[]>((arr, poi) => {
      if ((!poi.deleted) && (!showOnlyPoisOfImage || imagePoiSet.has(poi.id))) {
        poi.annotations = poi.annotations?.reduce<Annotation[]>((annotations, annotation) => {
          const fullAnnotation = annotationsMap.get(annotation.id || '');
          if (fullAnnotation) annotations.push(fullAnnotation);
          return annotations
        }, []);
        arr.push(poi);
      }
      return arr;
    }, []);
  }

  @Selector([SlideState.slide, SlideState.annotationsMap])
  public static displayedGroups(slide: Slide, annotationsMap: ReturnType<typeof SlideState.annotationsMap>) {
    return (slide.groups || []).reduce<Group[]>((arr, group) => {
      if (!group.deleted) {
        group.annotations = group.annotations?.reduce<Annotation[]>((annotations, annotation) => {
          const fullAnnotation = annotationsMap.get(annotation.id || '');
          if (fullAnnotation) annotations.push(fullAnnotation);
          return annotations
        }, []);
        arr.push(group);
      }
      return arr;
    }, []);
  }

  @Selector([SlideState.slide])
  public static poiToImageMap(slide: Slide) {
    return slide.layers?.reduce<{[key: string]: { [key: string]: { count: number, items: {[key: string]: boolean} } }}>((map, layer) => {
      layer?.images?.forEach((image, index) => {
        image?.pois?.forEach((p) => {
          if (!p.id || !layer.id || !image.id) return;
          const aCache = map[p.id] || {};
          if (!aCache[layer.id]) aCache[layer.id] = {count: 0, items: {} };
          aCache[layer.id].items[image.id] = true;
          aCache[layer.id].count++;
          map[p.id] = aCache;
        })
      })
      return map
    }, {});
  }

  /**
   * Selector media
   * @param state: SlideStateModel
   */
  @Selector()
  public static media(state: SlideStateModel): Media {
    return state.current.layers[state.layerNumber].images[state.sequenceNumber].media;
  }

  /**
   * Has Annotations
   * @param state: SlideStateModel
   */
  @Selector()
  public static hasAnnotations(state: SlideStateModel): boolean {
    return state.current.layers[state.layerNumber].images[state.sequenceNumber].annotations?.length > 0;
  }

  /**
   * Copy stack
   * @param state: SlideStateModel
   */
  @Selector()
  public static copyStack(state: SlideStateModel): Annotation {
    return state.copyStack;
  }

  @Selector([SlideState.slide, SlideState.imageInteractiveAreas])
  public static virtualGroups(slide: Slide, interactiveAreas: Annotation[]): Group[] {
    const groups = slide?.groups;
    if (!groups || !interactiveAreas) {
      return [];
    }

    if (interactiveAreas.length === 0) {
      return [];
    }
    
    const virtualGroupsMap = new Map<string, Group>();
    const virtualGroups: Group[] = [];
    groups.forEach(group => {
      group.annotations?.forEach(a => {
        if (a.id) { 
          virtualGroupsMap.set(a.id, group); 
        }
      })
      group.description = group.annotations?.map(a => a?.label?.description || '').sort((a, b) => b.length - a.length)[0];
      virtualGroups.push(group);
    })
    
    interactiveAreas.forEach((area) => {
      if (area.id) {
        const g = virtualGroupsMap.get(area.id);
        if (!g) {
          const group: Group = {
            annotations: [area],
            name: area.label?.text,
            description: area.label?.description,
          }
          virtualGroupsMap.set(area.id, group);
          virtualGroups.push(group);
        }
      }
    });

    virtualGroups.sort((a, b) => (a.name || '').localeCompare((b.name || '')));

    return virtualGroups;
  }

  // </editor-fold>

  /**
   * Handle slide history changes
   * @param getState: StateContext<SlideStateModel>
   * @param getState: StateContext<SlideStateModel>
   * @param dispatch: StateContext<SlideStateModel>
   * @param stateFragment: GetSlideSuccess
   */
  @Action(HandleSlideHistory)
  public slideHandleHistory({getState, setState}: StateContext<any>, {stateFragment}) {
    setState(patch({
      ...stateFragment
    }));
  }

  /**
   * Slide changed
   * @param getState: PageState
   * @param dispatch: PageState
   */
  @Action(SlideChanged)
  public slideChanged({getState, setState, dispatch}: StateContext<SlideStateModel>, {slide}) {
    const {current, dirtyItems} = getState();
    const currentPage = slide || current;

    if (!currentPage) {
      return;
    }
    currentPage.dirty = true;
    if (!!currentPage.id && getState()) {
      const idx = getState().dirtyItems?.findIndex((page) => page.id === currentPage.id);
      if (idx !== -1) {
        dirtyItems[idx] = currentPage;
      } else {
        dirtyItems.push(currentPage);
      }
    } else if (!!currentPage.slideId && getState()) {
      const idx = getState().dirtyItems?.findIndex((page) => page.slideId === currentPage.slideId);
      if (idx !== -1) {
        dirtyItems[idx] = currentPage;
      } else {
        dirtyItems.push(currentPage);
      }
    }
    setState(patch({
      current: {...current},
      dirtyItems: [...dirtyItems]
    }));
    dispatch(new SharedHistory.Changed({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleSlideHistory,
      id: 'slide'
    }));
  }

  /**
   * Update slides
   * @param getState: StateContext<SlideStateModel>
   * @param dispatch: StateContext<SlideStateModel>
   */
  @Action(UpdateSlides)
  public updateSlides({getState, dispatch}: StateContext<SlideStateModel>) {
    const state = getState();
    if (state?.dirtyItems?.length > 0) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new UpdateSlidesRequest(state.dirtyItems));
    }
  }

  /**
   * Update slides success
   * @param getState: StateContext<SlideStateModel>
   * @param setState: StateContext<SlideStateModel>
   * @param dispatch: StateContext<SlideStateModel>
   * @param slides: UpdateSlidesSuccess<T>
   */
  @Action(UpdateSlidesSuccess)
  public updatePagesSuccess({getState, setState, dispatch}: StateContext<SlideStateModel>, {slides}: UpdateSlidesSuccess): void {
    let current = getState().current;
    const index = this.store.selectSnapshot(IndexState.index);
    for (const slide of slides) {
      if (slide.id === current?.id) {
        current = slide;
      }
      this.nodeService.replacePage(slide, index);
    }
    setState(
      patch({
        dirtyItems: [] as Slide[],
        current: {...current} as Slide
      })
    );
    dispatch(new SharedHistory.Init({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleSlideHistory,
      id: 'slide'
    }));
  }

  /**
   * Delete slide
   * @param dispatch: StateContext<SlideStateModel>
   * @param getState: StateContext<SlideStateModel>
   */
  @Action(DeleteSlide)
  public deleteSlide({dispatch, getState}: StateContext<SlideStateModel>) {
    const state = getState();
    state.current.deleted = true;
    dispatch(new SlideChanged());
    dispatch(new DeleteSlideSuccess(state.current));
  }

  /**
   * Delete slide success
   * @param slide: DeleteSlideSuccess
   */
  @Action(DeleteSlideSuccess)
  public deleteSlideSuccess({}: StateContext<SlideStateModel>, {slide}: DeleteSlideSuccess): void {
  }

  // </editor-fold>
  // <editor-fold desc="Get Slide">

  /**
   * Get slide
   * @param options: GetSlide
   */
  @Action(GetSlide)
  public getSlide({}: StateContext<SlideStateModel>, {options}: GetSlide) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetSlideRequest(options));
  }

  /**
   * Get slide by url
   */
  @Action(GetSlideByUrl)
  public getSlideByUrl({}: StateContext<SlideStateModel>) {
    const url = this.store.selectSnapshot(NavigationState.currentURL);
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetSlideRequest({url}));
  }

  /**
   * Get slide success
   * @param slide: GetSlideSuccess
   */
  @Action(GetSlideSuccess)
  public getSlideSuccess({setState, getState, dispatch}: StateContext<SlideStateModel>, {slide, options}: GetSlideSuccess): void {

    if (options?.preventStoreUpdate === true) {
      return;
    }
    if (slide?.type === PageType.SLIDE || slide?.type === PageType.TRAINING) {
      setState(
        patch({
          current: {...slide},
          layerNumber: 0,
          sequenceNumber: 0
        })
      );
      dispatch(new SharedHistory.Init({
        data: pick(getState(), ['current', 'dirtyItems']),
        cb: HandleSlideHistory,
        id: 'slide'
      }));
      if (!slide.annotations) {
        dispatch(new LoadSlideAnnotation())
      }
      if (!slide.pois) {
        dispatch(new LoadSlidePoi())
      }
      if (!slide.layers) {
        dispatch(new LoadSlideLayer());
      }
      if (!slide.groups) {
        dispatch(new LoadSlideGroup());
      }
    }
  }

  @Action(SetSlide)
  public setSlide({setState}: StateContext<SlideStateModel>, {slide}: SetSlide) {
    setState(patch<SlideStateModel>({
      current: {...slide}
    }));
  }

  @Action(GetAllSlideIds)
  public getAllSlideIds({}: StateContext<SlideStateModel>) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetAllSlideIdsRequest());
  }

  @Action(GetLastEditedBySlideId)
  public getLastEditedBySlideId({}: StateContext<SlideStateModel>, {slideId}: GetLastEditedBySlideId) {
    return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetLastEditedBySlideIdRequest(slideId));
  }

  @Action(LoadSlideLayer)
  public loadSlideLayer({dispatch, getState}: StateContext<SlideStateModel>, {options}: LoadSlideLayer) {
    const slide: Slide = getState().current;
    const id: string = options?.id ?? slide.id;
    if (!slide.layers || options?.preventStoreUpdate) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new LoadSlideLayerRequest(id, options));
    }
  }

  @Action(LoadSlideLayerSuccess)
  public loadSlideLayerSuccess({dispatch, getState, setState}: StateContext<SlideStateModel>, {layers, options}: LoadSlideLayerSuccess) {
    if (options?.preventStoreUpdate === true) {
      return;
    }
    const slide = getState().current;
    slide.layers = layers;
    setState(
      patch({
        current: {...slide},
        layerNumber: 0,
        sequenceNumber: 0,
      })
    );
    dispatch(new SharedHistory.Overwrite({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleSlideHistory,
      id: 'slide'
    }));
  }

  @Action(LoadSlideAnnotation)
  public loadSlideAnnotation({dispatch, getState}: StateContext<SlideStateModel>, {options}: LoadSlideAnnotation) {
    const slide: Slide = getState().current;
    const id: string = options?.id ?? slide.id;
    if (!slide.annotations || options?.preventStoreUpdate) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new LoadSlideAnnotationRequest(id, options));
    }
  }

  @Action(LoadSlideAnnotationSuccess)
  public loadSlideAnnotationSuccess({dispatch, getState, setState}: StateContext<SlideStateModel>, {annotations, options}: LoadSlideAnnotationSuccess) {
    if (options?.preventStoreUpdate === true) {
      return;
    }
    const slide = getState().current;
    slide.annotations = annotations;
    setState(
      patch({
        current: {...slide}
      })
    );
    dispatch(new SharedHistory.Overwrite({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleSlideHistory,
      id: 'slide'
    }));
  }

  @Action(LoadSlidePoi)
  public loadSlidePoi({dispatch, getState}: StateContext<SlideStateModel>, {options}: LoadSlidePoi) {
    const slide: Slide = getState().current;
    const id: string = options?.id ?? slide.id;
    if (!slide.pois || options?.preventStoreUpdate) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new LoadSlidePoiRequest(id, options));
    }
  }

  @Action(LoadSlidePoiSuccess)
  public loadSlidePoiSuccess({dispatch, getState, setState}: StateContext<SlideStateModel>, {pois, options}: LoadSlidePoiSuccess) {
    if (options?.preventStoreUpdate === true) {
      return;
    }
    const slide = getState().current;
    slide.pois = pois;
    setState(
      patch({
        current: {...slide}
      })
    );
    dispatch(new SharedHistory.Overwrite({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleSlideHistory,
      id: 'slide'
    }));
  }

  @Action(LoadSlideGroup)
  public loadGroupLayer({dispatch, getState}: StateContext<SlideStateModel>) {
    const slide = getState().current;
    if (!slide.groups) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new LoadSlideGroupRequest(slide.id));
    }
  }

  @Action(LoadSlideGroupSuccess)
  public loadSlideGroupSuccess({dispatch, getState, setState}: StateContext<SlideStateModel>, {groups}: LoadSlideGroupSuccess) {
    const slide = getState().current;
    slide.groups = groups;
    setState(
      patch({
        current: {...slide}
      })
    );
    dispatch(new SharedHistory.Overwrite({
      data: pick(getState(), ['current', 'dirtyItems']),
      cb: HandleSlideHistory,
      id: 'slide'
    }));
  }

  @Action(GetNextSlideId)
  public getNextSlideId({dispatch, getState}: StateContext<SlideStateModel>) {
    if (!getState().nextSlideId) {
      return this.messageService.sendMessage(this.store.selectSnapshot(AuthState.token), new GetNextSlideIdRequest());
    } else {
      dispatch(new GetNextSlideIdSuccess(getState().nextSlideId + 1));
    }
  }

  @Action(GetNextSlideIdSuccess)
  public getNextSlideIdSuccess({getState, setState}: StateContext<SlideStateModel>, {slideId}: GetNextSlideIdSuccess) {
    const state = getState();
    if (state.current) {
      state.current.slideId = slideId;
    }
    setState(
      patch({
        nextSlideId: slideId
      })
    );
  }

  @Action(SetLayerNumber)
  public setLayerNumber({getState, setState}: StateContext<SlideStateModel>, {layerNumber}: SetLayerNumber) {
    setState(
      patch({
        layerNumber
      })
    );
  }

  @Action(SetShowOnlyAnnotationsOfImage)
  public setShowOnlyAnnotationsOfImage({getState, setState}: StateContext<SlideStateModel>, {showOnlyAnnotationsOfImage}: SetShowOnlyAnnotationsOfImage) {
    setState(
      patch({
        showOnlyAnnotationsOfImage
      })
    );
  }

  @Action(SetShowOnlyPoisOfImage)
  public setShowOnlyPoisOfImage({getState, setState}: StateContext<SlideStateModel>, {showOnlyPoisOfImage}: SetShowOnlyPoisOfImage) {
    setState(
      patch({
        showOnlyPoisOfImage
      })
    );
  }

  @Action(SetSequenceNumber)
  public setSequenceNumber({getState, setState}: StateContext<SlideStateModel>, {sequenceNumber}: SetSequenceNumber) {
    setState(
      patch({
        sequenceNumber
      })
    );
  }

  @Action(SetPosition)
  public setCurrentPosition({getState, setState}: StateContext<SlideStateModel>, {position}: SetPosition) {
    setState(
      patch({
        position
      })
    );
  }

  @Action(SetBounds)
  public setCurrentBounds({getState, setState}: StateContext<SlideStateModel>, {bounds}: SetBounds) {
    setState(
      patch({
        bounds
      })
    );
  }

  /**
   * Add Marker
   */
  @Action(AddMarker)
  public addMarker({getState, dispatch}: StateContext<SlideStateModel>, {marker}: AddMarker) {
    const state = getState();

    this.annotationService.adjustPosition(marker, state.position, state.current.layers[state.layerNumber].images[state.sequenceNumber].media.type === MediaType.DEEPZOOM);
    if (!state.current.layers[state.layerNumber].images[state.sequenceNumber].annotations) {
      state.current.layers[state.layerNumber].images[state.sequenceNumber].annotations = [];
    }
    if (!state.current.annotations) {
      state.current.annotations = [];
    }
    state.current.layers[state.layerNumber].images[state.sequenceNumber].annotations.push({id: marker.id});
    state.current.annotations.push(marker);
    dispatch(new NewMarkerAdded(marker));
  }

  /**
   * Delete Marker
   */
  @Action(DeleteMarker)
  deleteMarker({getState, setState}: StateContext<SlideStateModel>, {marker}: DeleteMarker) {
    const state = getState();
    marker.deleted = true;

    state.current?.layers?.forEach(layer => {
      layer?.images?.forEach(image => {
        image.annotations = image?.annotations?.filter(a => a.id !== marker.id) || [];
      })
    })

    state.current?.pois?.forEach(poi => {
      poi.annotations = poi?.annotations?.filter(a => a.id !== marker.id) || [];
    })

    state.current?.groups?.forEach(group => {
      group.annotations = group?.annotations?.filter(a => a.id !== marker.id) || [];
    })

    state.current?.annotations?.forEach(annotation => {
      if (annotation.type === AnnotationType.KEYMAP) {
        (annotation as any).columns.forEach(column => {
          const labels = column.labels.filter(label => label.annotation?.id !== marker.id);
          if (labels.length !== column.labels.length) {
            annotation.dirty = true;
            column.labels = labels;
          }
        });
      }
    });
  }

  /**
   * Add Question
   */
  @Action(AddQuestion)
  public addQuestion({getState}: StateContext<SlideStateModel>, {}: AddQuestion) {
    const slide = getState().current;
    if (!slide.questions) {
      slide.questions = [];
    }
    slide.questions.push({
      id: v4(),
      order: slide.questions.length,
      choices: [],
      content: '',
      placeholder: '',
      type: QuestionType.LONG_LIST,
      required: true,
      randomised: true
    });
    for (let i = 0; i < 5; i++) {
      slide.questions[slide.questions.length - 1].choices.push({
        label: '',
        order: i
      });
    }
  }

  /**
   * Copy Marker
   */
  @Action(CopyMarker)
  public copyMarker({getState, setState}: StateContext<SlideStateModel>, {marker}: CopyMarker) {
    setState(
      patch({
        copyStack: marker
      })
    );
  }

  /**
   * Add Marker
   */
  @Action(PasteMarker)
  public pasteMarker({getState, dispatch}: StateContext<SlideStateModel>) {
    const state = getState();
    const marker = state.copyStack;
    if (!marker) {
      return;
    }

    const filterObject = (obj: object, key: string): object => {
      for (const i in obj) {
        if (!obj.hasOwnProperty(i)) {
          continue;
        }
        if (typeof obj[i] === 'object') {
          filterObject(obj[i], key);
        } else if (i === key) {
          delete obj[key];
        }
      }
      return obj;
    };
    const copyAnnotation = cloneDeep(marker);
    copyAnnotation.dirty = true;
    const filteredAnnotation = filterObject(copyAnnotation, 'id');

    if (!state.current.layers[state.layerNumber].images[state.sequenceNumber].annotations) {
      state.current.layers[state.layerNumber].images[state.sequenceNumber].annotations = [];
    }
    state.current.layers[state.layerNumber].images[state.sequenceNumber].annotations.push(filteredAnnotation);
    dispatch(new NewMarkerAdded(marker));
  }
}
